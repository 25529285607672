import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import StreamsModal from './streamsModal';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';
import styles from './rightPanel.mod.scss';
import { userRoles, jitsiConfig } from '../features/constants';
import ReactTooltip from 'react-tooltip';
import UserAvatar from '@modules/users/components/userAvatar';
import { JitsiMeeting } from '@jitsi/web-sdk';

const RightPanel = (props) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(true);
  const [building, setBuilding] = useState(false);
  const [intervalBuilding, setIntervalBuilding] = useState(null);
  const [streamsMaximized, setStreamsMaximized] = useState(false);
  const [streamSelected, setStreamSelected] = useState(0);
  const [jitsiOwnerId, setJitsiOwnerId] = useState(null);
  
  const jitsiOwnerIdRef = useRef(jitsiOwnerId);
  const jitsiApiRef = useRef();
  const telloStatus = props.telloStatus || '';
  const teamList = props.teamList || [];
  const streamsList = props.streamsList || [];
  const port = props.port || '';
  const userData = props.userData || {};

  useEffect(() => {
    setStreamsMaximized(props.streamsMaximized);
  }, [props.streamsMaximized]);

  const setJitsiOwnerIdState = id => {
    jitsiOwnerIdRef.current = id;
    setJitsiOwnerId(id);
  };

  const onToggleModal = () => {
    setShowModal(!showModal);
  }

  const onMaximizeStreams = () => {
    setStreamsMaximized(true);
  }

  const onMinimizeStreams = () => {
    setStreamsMaximized(false);
    if (props.onStreamsMinimize) {
      props.onStreamsMinimize();
    }
  }

  const onBuild = () => {
    if (!building) {
      setBuilding(true);
      const newIntervalBuilding = setTimeout(() => {
        setBuilding(false);
        setIntervalBuilding(null);
      }, 7000);
      setIntervalBuilding(newIntervalBuilding);

      onMaximizeStreams();
      if (props.buildingFunction) {
        props.buildingFunction();
      }
    }
  }

  const onChangeStream = (index) => {
    if (index >= 0 && index < streamsList.length) {
      setStreamSelected(index);
      setJitsiOwnerIdState(null);
    }
  }

  const youTubeIframe = function (youtubeVideoContent = {}) {
    const getProviderIframe = (rawUrl) => {
      let providerIframe = '';
      if (typeof rawUrl.url !== 'undefined') {
        if (rawUrl.provider && rawUrl.provider === 'vimeo_events') {
          providerIframe = `<iframe src="https://vimeo.com/event/${rawUrl.url}?autoplay=1&controls=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        } else if (rawUrl.provider && rawUrl.provider === 'vimeo') {
          providerIframe = `<iframe src="https://player.vimeo.com/video/${rawUrl.url}?autoplay=1&controls=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        } else {
          providerIframe = `<iframe src="https://www.youtube.com/embed/${rawUrl.url}?controls=0&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        }
      }
      return providerIframe;
    };

    return {
      __html: getProviderIframe(youtubeVideoContent),
    };
  };

  const handleJitsiApiReady = (apiObj, ref) => {
    ref.current = apiObj;

    const findAndSetOwner = (participant) => {
      if (!participant.displayName && participant.id === jitsiOwnerIdRef.current) {
        setJitsiOwnerIdState(null);
        console.log('==================>PARTICIPANT OWNER LEFT', participant);
      } else if (participant.displayName === jitsiConfig.ownerDisplayName) {
        setJitsiOwnerIdState(participant.id);
        console.log('==================>PARTICIPANT OWNER', participant, participant.id, jitsiOwnerIdRef.current);
      } else if (jitsiOwnerIdRef.current) {
        // ref.current.executeCommand("displayName", "");
        ref.current.executeCommand('setParticipantVolume', participant.id, 0);
        ref.current.executeCommand('avatarUrl', 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/maqueta_buenosaires/avatar_createc.svg');
        console.log('==================>PARTICIPANT', participant, jitsiOwnerIdRef.current);
      }
      if (jitsiOwnerIdRef.current){
        ref.current.executeCommand('setLargeVideoParticipant', jitsiOwnerIdRef.current);
        // ref.current.executeCommand('setFollowMe', true);
        // ref.current.executeCommand('grantModerator', jitsiOwnerIdRef.current);
        ref.current.pinParticipant(jitsiOwnerIdRef.current);
      }
    };

    ref.current.addListener('cameraError', (error) => {
      console.log('==================>CAMERA ERROR', error);
    });
    ref.current.addListener('errorOccurred', (error) => {
      console.log('==================>ERROR', error);
    });

    ref.current.addListener('participantJoined', findAndSetOwner);
    ref.current.addListener('participantLeft', findAndSetOwner);
    ref.current.addListener('videoConferenceJoined', findAndSetOwner);
  };

  const jitsiIframe = () => {
    const userInfo = { displayName: (userData.user) ? `${userData.user.firstName} ${userData.user.lastName}` : 'Participante' };

    const jitsiVideoContent = streamsList[streamSelected] || {};
    if (jitsiVideoContent.provider && jitsiVideoContent.provider === 'jitsi') {
      return (
        <JitsiMeeting
          key={jitsiVideoContent.url}
          domain={jitsiConfig.domain}
          roomName={jitsiVideoContent.url}
          onApiReady={externalApi => handleJitsiApiReady(externalApi, jitsiApiRef)}
          configOverwrite={jitsiConfig.configOverwrite}
          interfaceConfigOverwrite={jitsiConfig.interfaceConfigOverwrite}
          userInfo={userInfo}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div id="rightPanel" className={`${styles.rightPanel} ${(showModal) ? styles.active : ''}`}>
      <ReactTooltip backgroundColor="#000" place="top" type="dark" effect="solid" />
      {(streamsList.length > 0) && (
        <StreamsModal
          active={streamsMaximized}
          userData={userData}
          streamsList={streamsList}
          jitsiOwnerId={jitsiOwnerId}
          streamSelected={streamSelected}
          onClose={onMinimizeStreams}
          onAction={(props.validateTutorialFunction) ? props.validateTutorialFunction : null}
          onActionString={intl.formatMessage({ id: 'collaboration.rightPanel.validate' })}
        />
      )}
      <div className={`${styles.content} ${styles.scrolleable} scroll-remote-session`}>
        <div className={styles.panelHeader}>
          <div className={styles.headerIcon}><img alt="time" className={styles.avatar} src="/images/collaboration/experienceIcon.svg" /></div>
          <div>
            <div className={styles.headerTitle}>{intl.formatMessage({ id: 'collaboration.rightPanel.experienceTitle' })}</div>
            <div>{props.title}</div>
          </div>
        </div>
        <div className={styles.panelBody}>
          {(props.tutorialLevel) && (
            <div className={styles.row}>
              <div>{`${props.tutorialLevel.step} - ${props.tutorialLevel.title}`}:</div>
              <div className={styles.tutorialDescription}>{props.tutorialLevel.description}</div>
            </div>
          )}

          {(props.buildingFunction) && (
            <div className={styles.row}>
              <ButtonV2 onClick={onBuild} disabled={!((telloStatus === '' && port !== '') || (telloStatus === 'GOOD'))}>
                {(building) ? (
                  <img height="30" width="30" src="/images/anibot-large-white.gif" class="loader-img" />
                ) : (
                  intl.formatMessage({ id: 'collaboration.rightPanel.buildCode' })
                )}
              </ButtonV2>
            </div>
          )}

          <div className={styles.row}>
            <div>{intl.formatMessage({ id: 'collaboration.rightPanel.selectCamera' })}</div>
            <div>
              {(streamsList.length > 1) && (
                <div className={styles.streamsPagination}>
                  <div className={`${styles.streamsItem} ${(streamSelected === 0) ? styles.disabled : ''}`} onClick={() => onChangeStream(streamSelected - 1)} onKeyPress={() => { }}>&lt;</div>
                  {streamsList.map((s, index) => {
                    return (
                      <div key={`stream-${index}`} data-tip={s.title} className={`${styles.streamsItem} ${(streamSelected === index) ? styles.selected : ''}`} onClick={() => onChangeStream(index)} onKeyPress={() => { }}>{index + 1}</div>
                    )
                  })}
                  <div className={`${styles.streamsItem} ${(streamSelected === streamsList.length - 1) ? styles.disabled : ''}`} onClick={() => onChangeStream(streamSelected + 1)} onKeyPress={() => { }}>&gt;</div>
                </div>
              )}
            </div>
            {(streamsList.length > 0) && (
              <div className={styles.youtubeContainer}>
                <span className={`icon-expand ${styles.iconMaximize}`} onKeyPress={() => { }} onClick={onMaximizeStreams} />
                {(streamsList[streamSelected].provider && streamsList[streamSelected].provider === 'jitsi') ? (
                  <div className={styles.youtubeVideo} id="jitsiParentNode">
                    {(!streamsMaximized) ? jitsiIframe() : null}
                  </div>
                ) : (
                  <div className={styles.youtubeVideo} dangerouslySetInnerHTML={youTubeIframe(streamsList[streamSelected] || {})} />
                )}
                <div className={styles.youtubeHover} />
              </div>
            )}
          </div>

          <div className={`${styles.row} ${styles.myTeam}`}>
            <div>
              <div>{intl.formatMessage({ id: 'collaboration.rightPanel.myTeam' })}</div>
              <div className={styles.avatarContainer}>
                {teamList.map((u, index) => {
                  if (u.userRole === userRoles.STUDENT || u.userRole === userRoles.LEADER) {
                    return (
                      <UserAvatar
                        firstName={u.User.firstName}
                        lastName={u.User.lastName}
                        onLine={u.onLine}
                        color={u.color}
                        id={index}
                        avatarUrl={u.User.avatarUrl}
                      />
                    )
                  }
                })}
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* <div className={styles.toggleModal} onKeyPress={() => { }} role="button" tabIndex="0" onClick={onToggleModal}> */}
      <div className={styles.toggleModal}>
        <span className={styles.rightPanelIcon} />
        <span className={`icon-chevron-left ${styles.iconChevronLeft} ${(showModal) ? styles.rotateArrow : ''}`} />
      </div>
    </div>
  );
};

export default RightPanel;