/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import './Tab.scss';

class Tab extends Component {
  constructor(props) {
    super(props);
    // console.log("props");
    this.state = {
      isActive: (props.isActive) ? props.isActive : false,
    };
  }

  componentWillReceiveProps(newprops) {
    this.setState({ isActive: newprops.isActive });
  }

  render() {
    // console.log("hijo", this.props.children)
    const { children } = this.props;
    const { isActive } = this.state;

    return (
      <div className={`Tab ${(isActive) ? 'active' : ''}`}>
        {children}
      </div>
    );
  }
}

export default Tab;
