/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
/* eslint react/destructuring-assignment: 0 */
/* eslint spaced-comment: 0 */

//////////////////////////////////////////////////
/////////// CODIT ////////////////////////////////
//////////////////////////////////////////////////
export const codit_initial_block = (block, generator) => {
  let addCode = '';
  const children = block.getChildren();
  if (children[0]) {
    addCode = generator.blockToCode(children[0]);
  }

  generator.setups_['main_setup'] = `
${addCode}
`;

  return null;
};

export const codit_wait = (block, generator) => {
  return `
mission+="|1101";`;
};

export const codit_wait_to = (block, generator) => {
  return `
mission+="|1111";`;
};

export const codit_motor = (block, generator) => {
  return `
mission+="|1001";`;
};

export const codit_light = (block, generator) => {
  return `
mission+="|0110";`;
};

export const codit_color = (block, generator) => {
  return `
mission+="|0101";`;
};

export const codit_music = (block, generator) => {
  return `
mission+="|0011";`;
};

export const codit_talk = (block, generator) => {
  return `
mission+="|1110";`;
};

export const codit_forward = (block, generator) => {
  return `
mission+="|1000";`;
};

export const codit_backward = (block, generator) => {
  return `
mission+="|0100";`;
};

export const codit_left = (block, generator) => {
  return `
mission+="|0010";`;
};

export const codit_right = (block, generator) => {
  return `
mission+="|0001";`;
};

export const codit_forward_3 = (block, generator) => {
  return `
mission+="|1100";`;
};

export const codit_shake = (block, generator) => {
  return `
mission+="|1010";`;
};



//////////////////////////////////////////////////
/////////// MICROBIT /////////////////////////////
//////////////////////////////////////////////////
export const new_event_start = (block, generator) => {
  let addCode = '';
  const children = block.getChildren();
  if (children[0]) {
    addCode = generator.blockToCode(children[0]);
  }

  generator.setups_['main_setup'] = `
${addCode}
`;

  return null;
};

export const new_event_repeat = (block, generator) => {
  let branch = generator.statementToCode(block, 'SUBSTACK');
  branch = branch.replace(/&quot;/g, '"');

  const code = `
basic.forever(function () {
  ${branch}
})`;

  return code;
};

export const control_loop_number = (block, generator) => {
  const loopNumber = block.getFieldValue('CHOICE');
  let branch = generator.statementToCode(block, 'SUBSTACK');
  branch = branch.replace(/&quot;/g, '"');

  const code = `
  for (let i = 0; i < ${loopNumber}; i++) {
    ${branch}
  }
`;

  return code;
};

export const on_buton_pressed = (block, generator) => {
  const loopNumber = block.getFieldValue('CHOICE');
  let branch = generator.statementToCode(block, 'SUBSTACK');
  branch = branch.replace(/&quot;/g, '"');

  const code = `
input.onButtonPressed(Button.${loopNumber}, function () {
  ${branch}
}); `;

  return code;
};

export const pause_millis = (block, generator) => {
  const number = block.getFieldValue('CHOICE');

  return `
basic.pause(${number});`;
};

export const show_icon = (block, generator) => {
  const icon = block.getFieldValue('CHOICE');

  return `
basic.showIcon(IconNames.${icon});`;
};

export const show_string = (block, generator) => {
  const text = block.getFieldValue('NUM');

  return `
basic.showString("${text}");`;
};
