import React from 'react';
import { useIntl } from 'react-intl';
import Text from '@components/text';
import styles from './tabBar.mod.scss';

export const TAB_NAMES = {
  CREATE_PROJECT: 'createProject',
  LOAD_PROJECT: 'loadProject',
};

export const defaultTabs = [{
  id: 0,
  name: TAB_NAMES.CREATE_PROJECT,
  enabled: true,
  selected: false,
},
{
  id: 1,
  name: TAB_NAMES.LOAD_PROJECT,
  enabled: true,
  selected: false,
}];

const addIntToTabsNames = (intl, tabs) => tabs.map((tab) => ({
  ...tab,
  name: intl.formatMessage({ id: `blocks.profileSelector.project.tabBar.${tab.name}` }),
}));

function TabBar({ selectedTab, onTabClick }) {
  const intl = useIntl();
  const enabledTabs = defaultTabs.filter((tab) => tab.enabled);
  const tabsWithIntlNames = addIntToTabsNames(intl, enabledTabs);
  return (
    <div className={styles.container}>
      <div className={styles.tabBarContainer} style={{ gridTemplateColumns: `repeat(${enabledTabs.length}, 1fr)` }}>
        {enabledTabs.map((tab) => {
          const intlTab = tabsWithIntlNames[tab.id];
          const isSelected = tab.id === selectedTab.id;
          return (
            <div
              key={tab.id}
              className={styles.tabContainer}
              onClick={() => {
                onTabClick(tab);
              }}
            >
              <div className={styles.tabWrapper}>
                <div className={styles.tabNameWrapper}>
                  <Text className={isSelected ? styles.tabNameSelected : styles.tabName}>
                    {intlTab.name}
                  </Text>
                </div>
                <div className={styles.tabMarkWrapper}>
                  {isSelected && <span className={styles.tabMark} />}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TabBar;
