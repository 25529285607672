import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="BHOOT_initial_block" x="50" y="80" deletable="false" movable="false" />
  </xml>
);

/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <block type="BHOOT_motor_left" />
    <block type="BHOOT_motor_right" />
    <block type="BHOOT_motor_backward" />
    <block type="BHOOT_motor_forward" />
  </React.Fragment>
);
