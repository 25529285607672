import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        movable: false,
        deletable: false,
        type: 'takeoff',
        x: 50,
        y: 50,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <block type="land" />
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">3</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_for">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_doWhile" />
      
    </category>

    <category id="variables" categorystyle="variable_dynamic_category" name="%{BKY_LANG_CATEGORY_VARIABLES}">
      <block type="variables_get_dynamic" />
      <block type="variables_set_dynamic" />
    </category>
    
    <category id="math" categorystyle="math_category" name="%{BKY_LANG_CATEGORY_MATH}">
      <block type="math_number">
        <field name="NUM">20</field>
      </block>
      <block type="math_arithmetic" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="math_distancia_min_max" />


    </category>

    <category id="logic" categorystyle="logic_category" name="%{BKY_LANG_CATEGORY_LOGIC}">
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>

    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_MOTION}">

      <block type="set_speed">
        <value name="speed">
          <shadow type="math_speed_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_forward">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_backward">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_left">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_right">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_up">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_down">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_xyz">
        <value name="xdistance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">20</field>
          </shadow>
        </value>
        <value name="ydistance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">25</field>
          </shadow>
        </value>
        <value name="zdistance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
        <value name="speed">
          <shadow type="math_speed_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="curve">
        <value name="x1distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">20</field>
          </shadow>
        </value>
        <value name="y1distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">25</field>
          </shadow>
        </value>
        <value name="z1distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
        <value name="x2distance">
          <shadow type="math_distancia_500_500">
            <field name="SIDER">20</field>
          </shadow>
        </value>
        <value name="y2distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">25</field>
          </shadow>
        </value>
        <value name="z2distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
        <value name="speed">
          <shadow type="math_speed_10_60">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="yaw_left">
        <value name="angle">
          <shadow type="slider_angle_360">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="yaw_right">
        <value name="angle">
          <shadow type="slider_angle_360">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>


    </category>

    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_PIROUETTES}">
      <block type="flip_backward" />
      <block type="flip_forward" />
      <block type="flip_left" />
      <block type="flip_right" />
    </category>
  </xml>
);
/* eslint-enable */
