/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint  no-param-reassign: 0 */
export const math_number = (block, generator) => {
  const code = parseFloat(block.getField('NUM').getText());

  return [code, code < 0 ? generator.ORDER_UNARY_PREFIX : generator.ORDER_ATOMIC];
};

export const math_arithmetic = (block, generator) => {
  const OPERATORS = {
    ADD: [' + ', generator.ORDER_ADDITIVE],
    MINUS: [' - ', generator.ORDER_ADDITIVE],
    MULTIPLY: [' * ', generator.ORDER_MULTIPLICATIVE],
    DIVIDE: [' / ', generator.ORDER_MULTIPLICATIVE],
    POWER: [null, generator.ORDER_NONE],
  };

  const mode = block.getFieldValue('OP');
  const tuple = OPERATORS[mode];
  const operator = tuple[0];
  const order = tuple[1];

  const argument0 = generator.valueToCode(block, 'A', order) || '0';
  const argument1 = generator.valueToCode(block, 'B', order) || '0';
  let code;
  if (!operator) {
    code = `pow(${argument0}, ${argument1})`;
    return [code, generator.ORDER_UNARY_POSTFIX];
  }
  code = argument0 + operator + argument1;

  return [code, order];
};