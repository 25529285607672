/* eslint-disable */
// TODO: switch out this browser shim for mitt: https://github.com/developit/mitt
const { EventEmitter } = require('events');

class SerialPort extends EventEmitter {
  constructor(port, options) {
    super(options);
    this.options = options || {};

    this.browser = true;
    this.path = this.options.path;
    this.isOpen = false;
    this.port = port || null;
    this.writer = null;
    this.reader = null;
    this.baudRate = this.options.baudRate;
    this.requestOptions = this.options.requestOptions || {};

    navigator.serial.addEventListener("connect", (event) => {
      // console.log('======================CONNECT', event.target);
      this.emit('connect', event.target || event.port);
    });
    
    navigator.serial.addEventListener("disconnect", (event) => {
      // console.log('======================DISCONNECT', event.target);
      this.emit('disconnect', event.target || event.port);
    });

    if (this.options.autoOpen) this.open();
  }

  list(callback) {
    // return navigator.usb.getDevices()
    return navigator.serial.getPorts()
    .then((list) => { if (callback) { return callback(null, list) } })
    .catch((error) => { if (callback) { return callback(error) } });
  }
  
  connectNewBoard(callback) {
    // navigator.usb.requestDevice({ filters: []})
    navigator.serial.requestPort(this.requestOptions)
      .then((serialPort) => { if (callback) { return callback(null, serialPort) } })
      .catch((error) => { if (callback) { return callback(error) } });
  }

  findPorts(callback) {
    // navigator.usb.getDevices()
    navigator.serial.getPorts()
      .then((list) => { if (callback) { return callback(null, list) } })
      .catch((error) => { if (callback) { return callback(error) } });
  }

  open(port, callback) {
    if (port) {
      if (this.isOpen) {
        callback(null);
        return;
      };
      this.port = port;
      this.port.open({ baudRate: this.baudRate || 57600 })
        .then(() => this.writer = this.port.writable.getWriter())
        .then(() => this.reader = this.port.readable.getReader())
        .then(async () => {
          this.emit('open');
          this.isOpen = true;
          callback(null);
          while (this.port?.readable?.locked) {
            try {
              const { value, done } = await this.reader.read();
              if (done) {
                break;
              }
              this.emit('data', Buffer.from(value));
            } catch (error) {
              // console.error('===========ERROR data port', error);
              callback(error);
            }
          }
        })
        .catch((error) => {
          // console.error('===========ERROR open port', error);
          callback(error);
        });
    }
  }

  async close(callback) {
    try {
      await this.reader.releaseLock();
      await this.writer.releaseLock();
      await this.port.close();
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    this.isOpen = false;
    callback && callback(null);
  }

  openMonitor(port, baudios, callback) {
  if (port) {
      // console.log('==================serialport', port);
      port.open({ baudRate: baudios || this.baudRate || 57600 })
        .then(() => this.writer = port.writable.getWriter())
        .then(() => this.reader = port.readable.getReader())
        .then(async () => {
          this.isOpen = true;
          while (port.readable.locked) {
            try {
              const { value, done } = await this.reader.read();
              if (done) {
                await this.reader.releaseLock();
                break;
              }
              callback(null, Buffer.from(value).toString());
            } catch (e) { /** ignore error */ }
          }
        })
        .catch(error => { callback(error) });
    }
  }

  async closeMonitor(port, callback) {
    try {
      this.isOpen = false;
      await this.reader.releaseLock();
      await this.writer.releaseLock();
      await port.close();
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    callback && callback(null);
  }

  send(buffer, callback) {
    this.writer.write(Buffer.from(buffer, 'utf-8'));
    if (callback) return callback(null);
  }

  async set(props = {}, callback) {
    try {
      const signals = {};
      if (Object.prototype.hasOwnProperty.call(props, 'dtr')) {
        signals.dataTerminalReady = props.dtr;
      }
      if (Object.prototype.hasOwnProperty.call(props, 'rts')) {
        signals.requestToSend = props.rts;
      }
      if (Object.prototype.hasOwnProperty.call(props, 'brk')) {
        signals.break = props.brk;
      }
      if (Object.keys(signals).length > 0) {
        await this.port.setSignals(signals);
      }
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    if (callback) return callback(null);
  }

  write(buffer, callback) {
    try {
      // console.log('================write', buffer);
      // this.writer.write(buffer);
      this.writer.write(Buffer.from(buffer, 'utf-8'));
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    if (callback) return callback(null);
  }

  async read(callback) {
    let buffer;
    try {
      buffer = await this.reader.read();
    } catch (error) {
      if (callback) return callback(error);
      throw error;
    }
    if (callback) callback(null, buffer);
  }

  // TODO: is this correct?
  flush(callback) {
    //this.port.flush(); // is this sync or a promise?
    console.warn('flush method is a NOP right now');
    if (callback) return callback(null);
  }

  // TODO: is this correct?
  drain(callback) {
    // this.port.drain(); // is this sync or a promise?
    console.warn('drain method is a NOP right now');
    if (callback) return callback(null);
  }
}

module.exports = SerialPort;
