/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */
/* eslint dot-notation: 0 */
import * as Blockly from 'blockly';
import colors from '../../utils/colors';
import createRenameModal from './createRenameModal';

export const ProceduresDynamicEducabot = {};

Blockly.Blocks.procedures_callnoreturn_custom = {
  ...Blockly.Blocks.procedures_callnoreturn,
  init: function () {
    Blockly.Blocks.procedures_callnoreturn.init.call(this);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.procedures_callreturn_custom = {
  ...Blockly.Blocks.procedures_callreturn,
  init: function () {
    Blockly.Blocks.procedures_callreturn.init.call(this);
    this.setInputsInline(true);
  },
};

// Remove mutator icon from procedure block
Blockly.Blocks.procedures_defnoreturn.loadExtraState = function (state) {
  this.arguments_ = [];
  this.argumentVarModels_ = [];
  if (state['params']) {
    for (let i = 0; i < state['params'].length; i += 1) {
      const param = state['params'][i];
      const variable = Blockly.Variables.getOrCreateVariablePackage(
        this.workspace,
        param['id'],
        param['name'],
        '',
      );
      this.arguments_.push(variable.name);
      this.argumentVarModels_.push(variable);
    }
  }
  this.updateParams_();
  Blockly.Procedures.mutateCallers(this);
  this.setMutator(null);
  this.setStatements_(!state['hasStatements']);
};
Blockly.Blocks.procedures_defreturn.loadExtraState = Blockly.Blocks.procedures_defnoreturn.loadExtraState;

const createModalProcedureButtonHandler = (workspace, optCallback, optType) => {
  // This function needs to be named so it can be called recursively.
  const promptAndCheckWithAlert = (text, number, type, returnType, parameters) => {
    if (text) {
      let existing = Blockly.Variables.nameUsedWithAnyType(text, workspace);
      let existingFunction = Blockly.Procedures.getDefinition(text, workspace);
      if (existing || existingFunction) {
        return { error: 'PROCEDURES_ALREADY_EXISTS' };
      }

      const namesSet = new Set();
      for (let i = 0; i < number; i += 1) {
        const varName = parameters[i].name;
        if (namesSet.has(varName)) {
          return { error: 'PROCEDURES_REPEATED_VAR_NAMES' };
        }
        namesSet.add(varName);

        existing = Blockly.Variables.nameUsedWithAnyType(varName, workspace);
        existingFunction = Blockly.Procedures.getDefinition(varName, workspace);
        if (existing || existingFunction) {
          return { error: 'PROCEDURES_ALREADY_EXISTS' };
        }
      }

      const newBlock = workspace.newBlock(type);
      newBlock.initSvg();
      newBlock.render();
      newBlock.setMutator(null);
      newBlock.moveBy(300, 100);
      newBlock.setFieldValue(text, 'NAME');
      if (type === 'procedures_defreturn') {
        newBlock.removeInput('RETURN');
        newBlock.appendValueInput('RETURN')
          .appendField(Blockly.Msg['PROCEDURES_DEFRETURN_RETURN'])
          .appendField(returnType, 'TYPE');
      }

      for (let i = 0; i < number; i += 1) {
        const varFinalName = parameters[i].name;
        const varType = parameters[i].type;
        const variable = workspace.createVariable(varFinalName, varType, `ARG${varFinalName}${i}`);
        // const variable = workspace.createVariable(varFinalName, '', `${varType}_${i}`);
        newBlock.argumentVarModels_.push(variable);
        newBlock.arguments_.push(varFinalName);
      }
      newBlock.updateParams_();
      const toolbox = workspace.getToolbox();
      toolbox.refreshSelection();

      return { error: false };
    }

    return { error: 'PROCEDURES_NAME_EMPTY' };
  };

  createRenameModal.js(workspace, '', optType, promptAndCheckWithAlert);
};


ProceduresDynamicEducabot.flyoutCategoryBlocks = (workspace) => {
  const xmlList = [];

  const populateProcedures = (
    procedureList,
    templateName,
  ) => {
    for (let i = 0; i < procedureList.length; i += 1) {
      const name = procedureList[i][0];
      const args = procedureList[i][1];
      // <block type="procedures_callnoreturn" gap="16">
      //   <mutation name="do something">
      //     <arg name="x"></arg>
      //   </mutation>
      // </block>
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', templateName);
      block.setAttribute('gap', '16');
      const mutation = Blockly.utils.xml.createElement('mutation');
      mutation.setAttribute('name', name);
      block.appendChild(mutation);
      for (let j = 0; j < args.length; j += 1) {
        const arg = Blockly.utils.xml.createElement('arg');
        arg.setAttribute('name', args[j]);
        mutation.appendChild(arg);
      }
      xmlList.push(block);
    }
  }

  if (Blockly.Blocks['procedures_ifreturn']) {
    const block = Blockly.utils.xml.createElement('block');
    block.setAttribute('type', 'procedures_ifreturn');
    block.setAttribute('gap', '24');
    xmlList.push(block);
  }

  const tuple = Blockly.Procedures.allProcedures(workspace);
  populateProcedures(tuple[0], 'procedures_callnoreturn_custom');
  populateProcedures(tuple[1], 'procedures_callreturn_custom');

  return xmlList;
};

ProceduresDynamicEducabot.onCreateProcedureButtonClick = (button) => {
  createModalProcedureButtonHandler(button.getTargetWorkspace(), null, 'procedures_defnoreturn');
};

ProceduresDynamicEducabot.onCreateProcedureReturnButtonClick = (button) => {
  createModalProcedureButtonHandler(button.getTargetWorkspace(), null, 'procedures_defreturn');
};

ProceduresDynamicEducabot.flyoutCategory = function (workspace) {
  let items = [];

  const buttonNoReturn = document.createElement('button');
  buttonNoReturn.setAttribute('text', Blockly.Msg.PROCEDURES_CREATE_NORETURN_PROCEDURE);
  buttonNoReturn.setAttribute('callbackKey', 'CREATE_PROCEDURE');
  items.push(buttonNoReturn);
  const buttonReturn = document.createElement('button');
  buttonReturn.setAttribute('text', Blockly.Msg.PROCEDURES_CREATE_RETURN_PROCEDURE);
  buttonReturn.setAttribute('callbackKey', 'CREATE_PROCEDURE_RETURN');
  items.push(buttonReturn);

  workspace.registerButtonCallback('CREATE_PROCEDURE', ProceduresDynamicEducabot.onCreateProcedureButtonClick);
  workspace.registerButtonCallback('CREATE_PROCEDURE_RETURN', ProceduresDynamicEducabot.onCreateProcedureReturnButtonClick);
  workspace = ProceduresDynamicEducabot.flyoutCategoryBlocks(workspace);

  items = items.concat(workspace);

  return items;
};
