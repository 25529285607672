import flat from 'flat';
import spanishMessages from '../i18n/es.json';
import englishMessages from '../i18n/en.json';

/**
 * Get custom i18n messages.
 * @param {string} locale
 */
export const getLocale = (locale = 'es') => ({
  es: ({ locale: 'es', intlMessages: flat(spanishMessages) }),
  en: ({ locale: 'en', intlMessages: flat(englishMessages) }),
})[locale];
