import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        type: 'initial_block',
        x: 10,
        y: 10,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="procedures" categorystyle="procedure_category" name="%{BKY_LANG_CATEGORY_PROCEDURES}">
      <label text="Funciones" />

      <block type="funcion_ciudad_luces8"/>
      <block type="funcion_ciudad_luces9"/>
      <block type="funcion_ciudad_luces10"/>
      <block type="otra_funcion8"> </block>
      <block type="otra_funcion9"> </block>
      <block type="otra_funcion10"> </block>


    </category>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <label text="Control" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="base_delay_tren"> </block>
      <block type="ciudad_tiempo"> </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="logic" categorystyle="logic_category" name="%{BKY_LANG_CATEGORY_LOGIC}">
      <label text="Lógica" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>

    <category id="math" categorystyle="math_category" name="%{BKY_LANG_CATEGORY_MATH}">
      <label text="Matemáticas" />
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_array" />
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_pwm">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_minmax" />
    </category>

    <category id="variables" categorystyle="variable_dynamic_category" name="%{BKY_LANG_CATEGORY_VARIABLES}" custom="VARIABLE_DYNAMIC">
      <label text="Variables" />
    </category>

    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_SALIDAS}">
      <label text="Salidas" />
      <block type="ARDUINOUNO_salidas_led_tren" />
      <block type="ARDUINOUNO_salidas_led_brillo">
        <value name="DATOIN">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzer_piano" />
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzerno" />
    </category>
    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_ENTRADAS}">
    <label text="Entradas" />
    <block type="ARDUINOUNO_SALIDAS_read_dht11_tren" />
    <block type="ARDUINOUNO_ENTRADAS_button_TREN" />
    </category>
    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_LCD}">
      <label text="Pantallas" />
      <block type="ARDUINOUNO_lcd_educabot_tren">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_lcd_setBacklight" />
      <block type="ARDUINOUNO_lcd_clear" />
    </category>
    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_SERVO}">
      <label text="Motores" />
      <block type="ARDUINOUNO_SALIDAS_motor_tren_avanzar" />
      <block type="ARDUINOUNO_SALIDAS_motor_tren_frenar" />
      <block type="ARDUINOUNO_servo_move_tren">
        <value name="angulo">
          <shadow type="slider_angle_tren">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
    </category>

  </xml>
);
/* eslint-enable */
