/* eslint-disable max-len */
import React from 'react';

function FileSend() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M24.707,8.793l-6.5-6.5C18.019,2.105,17.765,2,17.5,2H7C5.895,2,5,2.895,5,4v22c0,1.105,0.895,2,2,2h16c1.105,0,2-0.895,2-2 V9.5C25,9.235,24.895,8.981,24.707,8.793z M20.923,18.382c-0.051,0.123-0.125,0.234-0.217,0.326l-3.999,3.999 C16.512,22.902,16.256,23,16,23s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L17.586,19H10c-0.553,0-1-0.447-1-1 s0.447-1,1-1h7.586l-2.293-2.293c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l3.999,3.999 c0.093,0.092,0.166,0.203,0.217,0.327C21.024,17.862,21.024,18.138,20.923,18.382z M18,10c-0.552,0-1-0.448-1-1V3.904L23.096,10H18z" />
    </svg>
  );
}

export default FileSend;
