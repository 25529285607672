/* eslint lines-between-class-members: 0 */
/* eslint no-param-reassign: 0 */
/* eslint camelcase: 0 */
export const MATRIX8X16_ADDR = 0x40;

export class Matrix8x16Controller {
  updated = false;
  connected = false;
  transmission = false;

  bitStart = false;
  bitData = false;
  bitEnd = false;
  byte = 0x00;
  bytePos = 0;
  data = new Uint8Array(16);
  dataBuffer = new Uint8Array(16);
  dataArrayPos = 15;

  scl = 1;
  sda = 1;

  constructor() {
    this.render();
  }

  update = (scl, sda) => {
    this.i2cWriteByte(scl, sda);
    // console.log('====================update', this.updated);
    // if (this.updated) {
    //   this.updated = false;
    //   return this.render();
    // }

    return false;
  }

  render = () => {
    const result = ({ characters: this.data });
    return result;
  }


  writeBit = (bit) => {
    let finished = false;

    const data = bit << this.bytePos;
    this.byte |= data;
    // console.log('================bit', this.bytePos, data.toString(2), this.byte.toString(2));
    this.bytePos += 1;
    if (this.bytePos >= 8) {
      finished = true;
    }

    return finished;
  }

  i2cWriteByte = (scl, sda) => {
    if (!this.bitStart && scl && sda && !this.scl && this.sda) {
      this.bitStart = true;
      // console.log('======================bit start', scl, sda, this.scl, this.sda);
    }
    if (!scl && this.scl) {
      this.bitData = true;
      // console.log('======================bit data', scl, sda, this.scl, this.sda);
    }
    if (scl && !sda && !this.scl && !this.sda) {
      this.bitEnd = true;
      // console.log('======================bit end', scl, sda, this.scl, this.sda);
    }
    if (this.bitStart && scl && !sda && this.scl && this.sda && !this.transmission) {
      this.bitData = false;
      this.bitEnd = false;
      this.transmission = true;
      // console.log('===================start===========', scl, sda, this.scl, this.sda);
      this.byte = 0x00;
      this.bytePos = 0;
      this.dataArrayPos = 15;
      this.dataBuffer = new Uint8Array(16);
    }
    if (scl && sda && this.scl && !this.sda && this.transmission) {
      this.bitStart = false;
      this.bitData = false;
      this.bitEnd = false;
      this.transmission = false;
      // console.log('======================end=======', scl, sda, this.scl, this.sda);
    }
    if (this.bitData && scl && !this.scl && sda === this.sda && this.transmission && this.bytePos < 8) {
      this.bitData = false;
      // console.log('======================bit', scl, sda, this.scl, this.sda);

      const finished = this.writeBit(sda);
      if (finished) {
        // console.log('================byte', this.byte.toString(2));
        if (this.byte === MATRIX8X16_ADDR) { // send 0x40
          // console.log('================address', this.byte.toString(2));
        } else if (this.byte === 0xC0) { // send screen address
          // console.log('================matrix address', this.byte.toString(2));
        } else if (this.byte === 0x8F) { // send end
          // console.log('================end', this.byte.toString(2));
        } else { // actual data
          // console.log('================data', this.dataArrayPos, this.byte.toString(2));
          this.dataBuffer[this.dataArrayPos] = this.byte;
          this.dataArrayPos -= 1;
          if (this.dataArrayPos < 0) {
            this.data = [...this.dataBuffer];
            this.dataBuffer = new Uint8Array(16);
            this.dataArrayPos = 0;
            this.updated = true;
          }
        }
        this.byte = 0x00;
        this.bytePos = 0;
      }
    }

    this.scl = scl;
    this.sda = sda;

    return this.updated;
  }
}
