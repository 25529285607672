import React, { Component } from 'react';
import { MotorDCElement } from '../components/motorDC/motorDC';
import { rotatePort } from '../utils';

export class MotorDC extends Component {
  constructor(hardware, pin, id = 'Led', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;

    this.pinStatePWM = 0;
    this.pinDirection = 0;
    this.pinSpeed = 0;

    this.variant = variant || {};
    this.pinInfo = [
      { name: 'SPEED', x: 29, y: 35, signals: [], description: 'signal' },
    ];
    this.size = {
      width: 113,
      height: 118,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    speed: this.pinSpeed,
    direction: (this.pinDirection) ? 'left' : 'right',
  });

  update = (pinState) => {
    if (this.hardware && this.pin) {
      const pinInfo = this.hardware.getPinInfo(this.pin);
      const pins = this.pin.split(',');
      pins.map((pin, index) => {
        let startPin = 0;
        let pinNumber = parseInt(pin, 10);
        if (pin.toString().includes('A')) {
          pinNumber = parseInt(pin.toString()[1], 10);
        }
        if (pinNumber >= 8) {
          startPin = 8;
        }
        const state = (pinState >> (pinNumber - startPin)) & 0x01;
        if (pinInfo.signals?.[index][0]?.type === 'trigger') {
          this.pinDirection = state;
        } else {
          this.pinStatePWM = state;
        }
        if (!this.pinDirection && !this.pinStatePWM) {
          this.pinSpeed = 0;
        } else {
          this.pinSpeed = 100;
        }
        return true;
      });
    }
  }

  reset = () => {
    this.pinDirection = 0;
    this.pinStatePWM = 0;
    this.pinSpeed = 0;
    this.lastTimestamp = 0;
  }

  render = () => (
    <MotorDCElement
      ref={this.ref}
      id={this.id}
      rotation={this.rotation}
      direction={this.pinDirection}
      speed={this.pinSpeed}
      key={`motorDC-${this.pin}`}
    />
  );
}
