import React from 'react';
import { useIntl } from 'react-intl';
import Text from '@components/text';
import styles from './noProjectsAvailable.mod.scss';

function NoProjectsAvailable() {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <Text className={styles.noProjectText}>
        {intl.formatMessage({ id: 'blocks.profileSelector.project.load.noAvailableProjects' })}
      </Text>
    </div>
  );
}

export default NoProjectsAvailable;
