import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BlocklyContainer from '@modules/blocklyNew/components/blockly';
import { useBlockly } from '../features/hooks';
import { BoardVersionChecker } from '../features/utils/boardVersionChecker';


const Blockly = React.memo((props) => {
  const [blocklyWorkspace, setBlocklyWorkspace] = useState(null);
  const blocklyState = useBlockly();
  const agentState = useSelector((state) => state.bloquesReducer);

  // console.log('=========blocklyState', agentState, blocklyState);
  const boardVersionChecker = new BoardVersionChecker(blocklyWorkspace, agentState.agentPorts);
  useEffect(() => {
    boardVersionChecker.setPort(blocklyState.build?.port || '');
  }, [blocklyState.build?.port, blocklyWorkspace]);

  const updateWorkspace = (workspace) => {
    if (workspace) {
      setBlocklyWorkspace(workspace);
      if (props.setWorkspace) {
        props.setWorkspace(workspace);
      }
    }
  }

  return (
    <BlocklyContainer
      // userData={props.userData || {}}
      tabInfo={props.tabInfo || {}}
      // socket={props.socket}
      // socketAgent={props.socketAgent}
      // buildingFunction={props.buildingFunction}
      // buildAllowed={props.buildAllowed}
      // portChangeAllowed={props.portChangeAllowed}
      // teamList={props.teamList}
      setWorkspace={updateWorkspace}
      // noCodePanel={props.noCodePanel || false}
    />
  );
});

export default Blockly;