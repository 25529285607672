import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 309.156 309.156" {...props}>
      <path
        d="M288.461 64.929L154.589 202.766 20.723 64.94 0 85.07l154.589 159.158L309.156 85.07z"
      />
    </svg>
  );
}

export default SvgComponent;
