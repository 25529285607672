import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import styles from './streamsModal.mod.scss';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';
import TextButton from '../../../resources/components/buttons/textButton';
import { jitsiConfigHD } from '../features/constants';
import { JitsiMeeting } from '@jitsi/web-sdk';

const StreamsModal = (props) => {
  const intl = useIntl();
  const [active, setActive] = useState(props.active || 0);
  const [streamSelected, setStreamSelected] = useState(props.streamSelected || 0);
  const [actionLoading, setActionLoading] = useState(false);
  const [jitsiOwnerId, setJitsiOwnerId] = useState(props.jitsiOwnerId || null);

  const jitsiOwnerIdRef = useRef(jitsiOwnerId);
  const jitsiApiRef = useRef();
  const streamsList = props.streamsList || [];
  const userData = props.userData || {};

  useEffect(() => {
    $('#collaborationStreams').modal((props.active) ? 'show' : 'hide');
    setActive(props.active);
  }, [props.active]);

  useEffect(() => {
    if (props.jitsiOwnerId) {
      setJitsiOwnerId(props.jitsiOwnerId);
    }
  }, [props.jitsiOwnerId]);

  useEffect(() => {
    setStreamSelected(props.streamSelected);
  }, [props.streamSelected]);


  const setJitsiOwnerIdState = id => {
    jitsiOwnerIdRef.current = id;
    setJitsiOwnerId(id);
  };

  const onChangeStream = (index) => {
    if (index >= 0 && index < streamsList.length) {
      setStreamSelected(index);
      setJitsiOwnerIdState(null);
    }
  }

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      $('#collaborationStreams').modal('hide');
    }
    setActionLoading(false);
  }

  const onAction = () => {
    if (props.onAction) {
      props.onAction();
      setActionLoading(true);
    }
    // onClose();
  }

  const youTubeIframe = function (youtubeVideoContent = {}) {
    const getProviderIframe = (rawUrl) => {
      let providerIframe = '';
      if (typeof rawUrl.url !== 'undefined') {
        if (rawUrl.provider && rawUrl.provider === 'vimeo_events') {
          providerIframe = `<iframe src="https://vimeo.com/event/${rawUrl.url}?autoplay=1&controls=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        } else if (rawUrl.provider && rawUrl.provider === 'vimeo') {
          providerIframe = `<iframe src="https://player.vimeo.com/video/${rawUrl.url}?autoplay=1&controls=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        } else {
          providerIframe = `<iframe src="https://www.youtube.com/embed/${rawUrl.url}?controls=1&autoplay=${(active) ? '1' : '0'}" frameborder="0" allow="accelerometer; ${(active) ? 'autoplay' : ''}; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        }
      }
      return providerIframe;
    };

    return {
      __html: getProviderIframe(youtubeVideoContent),
    };
  };


  const handleJitsiApiReady = (apiObj, ref) => {
    ref.current = apiObj;

    const findAndSetOwner = (participant) => {
      if (!participant.displayName && participant.id === jitsiOwnerIdRef.current) {
        setJitsiOwnerIdState(null);
        console.log('==================>PARTICIPANT OWNER LEFT', participant);
      } else if (participant.displayName === jitsiConfigHD.ownerDisplayName) {
        setJitsiOwnerIdState(participant.id);
        console.log('==================>PARTICIPANT OWNER', participant, participant.id, jitsiOwnerIdRef.current);
      } else if (jitsiOwnerIdRef.current) {
        // ref.current.executeCommand("displayName", "");
        ref.current.executeCommand('setParticipantVolume', participant.id, 0);
        ref.current.executeCommand('avatarUrl', 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/maqueta_buenosaires/avatar_createc.svg');
        console.log('==================>PARTICIPANT', participant, jitsiOwnerIdRef.current);
      }
      if (jitsiOwnerIdRef.current){
        ref.current.executeCommand('setLargeVideoParticipant', jitsiOwnerIdRef.current);
        // ref.current.executeCommand('setFollowMe', true);
        // ref.current.executeCommand('grantModerator', jitsiOwnerIdRef.current);
        ref.current.pinParticipant(jitsiOwnerIdRef.current);
      }
    };

    ref.current.addListener('cameraError', (error) => {
      console.log('==================>CAMERA ERROR', error);
    });
    ref.current.addListener('errorOccurred', (error) => {
      console.log('==================>ERROR', error);
    });
    ref.current.addListener('filmstripDisplayChanged', (state) => {
      if (state.visible) {
        ref.current.executeCommand('toggleFilmStrip');
      }
      console.log('==================>FILMSTRIP', state.visible);
    });
    ref.current.addListener('tileViewChanged', (status) => {
      if (status.enabled) {
        ref.current.executeCommand('toggleTileView');
      }
    });
    ref.current.addListener('participantJoined', findAndSetOwner);
    ref.current.addListener('participantLeft', findAndSetOwner);
    ref.current.addListener('videoConferenceJoined', findAndSetOwner);
  };

  const jitsiIframe = () => {
    const userInfo = { displayName: (userData.user) ? `${userData.user.firstName} ${userData.user.lastName}` : 'Participante' };

    const jitsiVideoContent = streamsList[streamSelected] || {};
    if (jitsiVideoContent.provider && jitsiVideoContent.provider === 'jitsi') {
      return (
        <JitsiMeeting
          key={jitsiVideoContent.url}
          domain={jitsiConfigHD.domain}
          roomName={jitsiVideoContent.url}
          onApiReady={externalApi => handleJitsiApiReady(externalApi, jitsiApiRef)}
          configOverwrite={jitsiConfigHD.configOverwrite}
          interfaceConfigOverwrite={jitsiConfigHD.interfaceConfigOverwrite}
          userInfo={userInfo}
          getIFrameRef={(iframeRef) => { iframeRef.style.height = '100%'; }}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div className={`modal fade ${styles.streamsModal}`} data-backdrop="static" data-keyboard="false" id="collaborationStreams" tabIndex="-1" role="dialog" aria-labelledby="collaborationStreams-label" aria-hidden="true">
      <div className={`modal-dialog modal-dialog-centered ${styles.modalDialog}`} role="document">
        <div className={`modal-content ${styles.modalContent}`}>
          <div className={`modal-header ${styles.modalHeader}`}>
            <div className={styles.title}>{intl.formatMessage({ id: 'collaboration.rightPanel.live' })}</div>
            {(streamsList.length > 1) && (
              <div className={styles.streamsPagination}>
                <div className={`${styles.streamsItem} ${(streamSelected === 0) ? styles.disabled : ''}`} onClick={() => onChangeStream(streamSelected - 1)} onKeyPress={() => { }}>&lt;</div>
                {streamsList.map((s, index) => {
                  return (
                    <div key={`stream-${index}`} data-tip={s.title} className={`${styles.streamsItem} ${(streamSelected === index) ? styles.selected : ''}`} onClick={() => onChangeStream(index)} onKeyPress={() => { }}>{index + 1}</div>
                  )
                })}
                <div className={`${styles.streamsItem} ${(streamSelected === streamsList.length - 1) ? styles.disabled : ''}`} onClick={() => onChangeStream(streamSelected + 1)} onKeyPress={() => { }}>&gt;</div>
              </div>
            )}
          </div>
          <div className={`modal-body ${styles.modalBody}`}>
            {(streamsList[streamSelected].provider && streamsList[streamSelected].provider === 'jitsi') ? (
              <div className={styles.youtubeVideo} id="jitsiParentNode">
                {(active) ? jitsiIframe() : null}
              </div>
            ) : (
              <div className={styles.youtubeVideo} dangerouslySetInnerHTML={youTubeIframe(streamsList[streamSelected] || {})} />
            )}
          </div>
          <div className={`modal-footer ${styles.modalFooter}`}>
            <TextButton className={styles.backButton} onClick={onClose}>{intl.formatMessage({ id: 'common.back' })}</TextButton>
            {(props.onAction && !actionLoading) && (
              <ButtonV2 disabled={actionLoading} onClick={onAction}>{props.onActionString || intl.formatMessage({ id: 'common.start' })}</ButtonV2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamsModal;