import axios from 'axios';
import types from './types';

axios.defaults.withCredentials = true;

function actionGetPortsFetchAction(ports) {
  return { type: types.GET_PORTS, payload: { statusPorts: 'fetch', ports } };
}

export function getPorts(ports) {
  return (dispatch) => {
    dispatch(actionGetPortsFetchAction(ports));
  };
}

function actionGetAgentPortsFetchAction(ports) {
  return { type: types.GET_AGENT_PORTS, payload: { statusPorts: 'fetch', ports } };
}

export function getAgentPorts(ports) {
  return (dispatch) => {
    dispatch(actionGetAgentPortsFetchAction(ports));
  };
}

function actionGetMessageFetchAction(msg) {
  return { type: types.GET_NEW_MESSAGE, payload: { statusMsg: 'fetch', msg } };
}

export function getNewMessage(msg) {
  return (dispatch) => {
    const fixedNewLineMsg = msg.replace(/[\r\n]/g, '');
    dispatch(actionGetMessageFetchAction(fixedNewLineMsg));
  };
}

function actionGetMessageProgressFetchAction(stateMsg, progress) {
  return { type: types.GET_NEW_STATE, payload: { statusState: 'fetch', stateMsg, progress } };
}

export function getNewProgress(msg, progress) {
  return (dispatch) => {
    const fixedNewLineMsg = msg.replace(/[\r\n]/g, '');
    dispatch(actionGetMessageProgressFetchAction(fixedNewLineMsg, progress));
  };
}

function actionGetStateFetchAction(stateMsg) {
  return { type: types.GET_NEW_STATE, payload: { statusState: 'fetch', stateMsg } };
}

export function getNewState(msg) {
  return (dispatch) => {
    const fixedNewLineMsg = msg.replace(/[\r\n]/g, '');
    dispatch(actionGetStateFetchAction(fixedNewLineMsg));
  };
}

export const setNewMessage = (dispatch) => (message) => {
  const fixedNewLineMsg = message.replace(/[\r\n]/g, '');
  dispatch(actionGetStateFetchAction(fixedNewLineMsg));
};


function actionGetMonitorMessageFetchAction(monitorMsgs) {
  return { type: types.GET_NEW_MONITOR_MESSAGE, payload: { statusMonitorMsg: 'fetch', monitorMsgs } };
}

export function getNewMonitorMessage(msg) {
  return (dispatch) => {
    const fixedNewLineMsg = msg;
    dispatch(actionGetMonitorMessageFetchAction(fixedNewLineMsg));
  };
}

function actionResetStatusFetch(ObjectToAssign) {
  return { type: types.BLOQUES_RESET_STATUS, payload: ObjectToAssign };
}

export function actionResetStatus(ObjectToAssign) {
  return (dispatch) => {
    dispatch(actionResetStatusFetch(ObjectToAssign));
  };
}
