/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint quote-props: 0 */
import * as Blockly from 'blockly';
import colors from './colors';


Blockly.Themes.Educabot = Blockly.Theme.defineTheme('Zelos', {
  'base': Blockly.Themes.Classic,
  'categoryStyles': {
    'procedure_category': {
      'colour': colors.COLOUR_PROCEDURES,
    },
    'variable_dynamic_category': {
      'colour': colors.COLOUR_VARIABLES,
    },
    'control_category': {
      'colour': colors.COLOUR_CONTROL,
    },
    'logic_category': {
      'colour': colors.COLOUR_LOGIC,
    },
    'math_category': {
      'colour': colors.COLOUR_MATH,
    },
    'text_category': {
      'colour': colors.COLOUR_TEXT,
    },
    'communication_category': {
      'colour': colors.COLOUR_COMMUNICATION,
    },
    'inputs_category': {
      'colour': colors.COLOUR_ENTRADAS,
    },
    'digital_inputs_category': {
      'colour': colors.COLOUR_ENTRADAS,
    },
    'analogue_inputs_category': {
      'colour': colors.COLOUR_ENTRADAS,
    },
    'outputs_category': {
      'colour': colors.COLOUR_SALIDAS,
    },
    'screens_category': {
      'colour': colors.COLOUR_LCD,
    },
    'motors_category': {
      'colour': colors.COLOUR_SERVO,
    },
    'special_category': {
      'colour': colors.COLOUR_MORSE,
    },
  },
  'blockStyles': {
    'colour_blocks': {
      'colourPrimary': '#CF63CF',
      'colourSecondary': '#C94FC9',
      'colourTertiary': '#BD42BD',
    },
    'control_blocks': {
      'colourPrimary': colors.COLOUR_CONTROL,
    },
    'procedure_blocks': {
      'colourPrimary': colors.COLOUR_PROCEDURES,
    },
    'variable_dynamic_blocks': {
      'colourPrimary': colors.COLOUR_VARIABLES,
    },
    'logic_blocks': {
      'colourPrimary': colors.COLOUR_LOGIC,
    },
    'text_blocks': {
      'colourPrimary': colors.COLOUR_TEXT,
    },
  },
  'componentStyles': {
    'workspaceBackgroundColour': '#0d1824',
    'toolboxBackgroundColour': '#1e2e46',
    'toolboxForegroundColour': '#fff',
    'flyoutBackgroundColour': '#212c4c',
    'flyoutForegroundColour': '#fff',
    'flyoutOpacity': 1,
    'scrollbarOpacity': 0.4,
    'blackBackground': '#333',
  },
});
