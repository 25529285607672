import { useEffect, useRef } from 'react';
import * as collaborationSlice from '@sections/collaboration/features/collaborationSlice';
import * as collaborationSessionSlice from '@sections/collaboration/features/collaborationSessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jwtSecret } from '@utils';
import * as jwt from 'jsonwebtoken';
import * as agentSlice from '../../../actions/bloquesAction';

export const useCollaboration = () => useSelector(collaborationSlice.selectCollaboration);


export const useGetCollaboration = (collaborationId) => {
  const dispatch = useDispatch();

  const collabState = useSelector(collaborationSlice.selectCollaboration);
  useEffect(() => {
    if (collaborationId) {
      dispatch(collaborationSlice.getCollaboration(collaborationId));
    }
  }, [collaborationId]);

  return collabState;
};


export const useGetCollaborationSession = (userId) => {
  const dispatch = useDispatch();

  const collabSessionState = useSelector(collaborationSessionSlice.selectCollaborationSession);
  useEffect(() => {
    if (userId) {
      dispatch(collaborationSessionSlice.getCollaborationSession(userId));
    }
  }, [userId]);

  return collabSessionState;
};

export const useGetCollaborationSessionUsers = (projectIds = []) => {
  const dispatch = useDispatch();

  const collabSessionUsersState = useSelector(collaborationSessionSlice.selectCollaborationSessionUsers);
  useEffect(() => {
    if (projectIds.length > 0) {
      dispatch(collaborationSessionSlice.getCollaborationSessionUsers(projectIds));
    }
  }, [projectIds]);

  return collabSessionUsersState;
};

export const useGetCompilationList = (userId, agentPorts, socket, amITheOwner) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && agentPorts.length > 0) {
      dispatch(collaborationSlice.getCompilations(userId));
    }
    if (userId && socket && amITheOwner) {
      socket.on('refreshCompilations', collaborationSlice.refreshCompilations(dispatch, userId));
      socket.on('authenticated', collaborationSlice.refreshCompilations(dispatch, userId));
    }
  }, [userId, agentPorts, socket, amITheOwner]);
};


export const useRefreshCompilationsInterval = (userId, amITheOwner, delay) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && amITheOwner) {
      const id = setInterval(() => {
        dispatch(collaborationSlice.getCompilations(userId));
      }, delay);
      return () => clearInterval(id);
    }
    return () => { };
  }, [userId, amITheOwner]);
};


export const useGetCollaborationLevels = (projectId, boardId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId && boardId !== null && typeof boardId !== 'undefined') {
      dispatch(collaborationSlice.getLevels(boardId));
    }
  }, [projectId, boardId]);
};


export const useSetSocketListeners = (socket) => {
  const dispatch = useDispatch();
  const collabState = useSelector(collaborationSlice.selectCollaboration);
  useEffect(() => {
    if (socket) {
      socket.on('broadcastCollabData', collaborationSlice.updateCollaborationState(dispatch, collabState));
      socket.on('notifyUsers', collaborationSlice.updateCollaborationNotificationsState(dispatch));
    }
  }, [socket]);
};


export const useAgentGetNewMessage = (message) => {
  const dispatch = useDispatch();
  const setNewAgentMessage = agentSlice.setNewMessage(dispatch);
  return {
    setNewAgentMessage,
  };
};


export const usePostCompilation = () => {
  const dispatch = useDispatch();
  const postCompilation = collaborationSlice.postCompilation(dispatch);
  return {
    postCompilation,
  };
};


export const usePutCompilation = () => {
  const dispatch = useDispatch();
  const putCompilation = collaborationSlice.putCompilation(dispatch);
  return {
    putCompilation,
  };
};


export const useUpdateCollaborationCompilationState = () => {
  const dispatch = useDispatch();
  const updateCollaborationCompilationState = collaborationSlice.updateCollaborationCompilationState(dispatch);
  return {
    updateCollaborationCompilationState,
  };
};

export const useUpdateCollaborationTutorialState = () => {
  const dispatch = useDispatch();
  const updateCollaborationTutorialState = collaborationSlice.updateCollaborationTutorialState(dispatch);
  return {
    updateCollaborationTutorialState,
  };
};


export const useUpdateCollaborationNotificationsState = () => {
  const dispatch = useDispatch();
  const updateCollaborationNotificationsState = collaborationSlice.updateCollaborationNotificationsState(dispatch);
  return {
    updateCollaborationNotificationsState,
  };
};


export const useUpdateSessionProjectState = () => {
  const dispatch = useDispatch();
  const updateSessionProjectState = collaborationSessionSlice.updateSessionProjectState(dispatch);
  return {
    updateSessionProjectState,
  };
};


export const useRefreshOnwerSession = (userId = null, amITheOwner = false, delay = 3600000) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && amITheOwner) {
      const id = setInterval(() => {
        const token = jwt.sign({
          exp: Math.floor(Date.now() / 1000) + 10, // expirates in 10 seconds
          data: { userId },
        }, jwtSecret());
        dispatch(collaborationSessionSlice.refreshOnwerSession(token));
      }, delay);
      return () => clearInterval(id);
    }
    return () => { };
  }, [userId, amITheOwner]);
};


export const useUpdateProjectInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, []);
};


export const useUpdateTurnsInterval = (userId, delay) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      const id = setInterval(() => {
        dispatch(collaborationSessionSlice.getCollaborationSessionTurns(userId));
      }, delay);
      return () => clearInterval(id);
    }
    return () => { };
  }, [userId]);
};


export const useCalcTurnsTime = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, []);
};
