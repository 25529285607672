/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Switch from 'react-switch';

class SaveCollaborationProjectFormComponent extends Component {
  constructor(props) {
    super(props);
    const { changeField, form } = this.props;
    this.state = {
      changeField,
      form,
    };
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      changeField: newprops.changeField,
      form: newprops.form,
    });
  }

  render() {
    const { changeField, form } = this.state;
    const { intl } = this.props;
    return (
      <React.Fragment>
        <div className="remote-session">
          <Switch className="pp-switch" onChange={() => changeField('collaborationProject', true)} checked={form.collaborationProject} />
          <div className={`with-remote-session ${form.collaborationProject ? 'green' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.collaboration' })}</div>
        </div>
        <div className="explainer">
          <div className={`without-remote-session-explainer ${form.collaborationProject ? 'hidden' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.collaborationNo' })}</div>
          <div className={`with-remote-session-explainer ${!form.collaborationProject ? 'hidden' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.collaborationYes' })}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SaveCollaborationProjectFormComponent);
