import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Skeleton,
  Typography,
} from '@mui/material';
import { getProjectCountByUser } from '@services/rest/creabots';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export const ConfirmDeleteLabsModal = (props) => {
  const {
    isOpen,
    isLoadingDeleteAction,
    handleClose,
    title,
    name,
    avatarUrl,
    isProject,
    onConfirmAction,
    id,
  } = props;
  const intl = useIntl();
  const [loadingProjectsCount, setLoadingProjectsCount] = useState(true);
  const [count, setCount] = useState(null)

  useEffect(() => {
    const req = async () => {
      const { data } = await getProjectCountByUser({ userId: id })
      setCount(data)
      setLoadingProjectsCount(false)
    }
    req()
  }, [id]);

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      disableEscapeKeyDown={isLoadingDeleteAction}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.common.white,
          paddingX: 5,
          paddingY: 6,
          width: '480px',
          borderRadius: 2,
          gap: 4,
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <DialogTitle
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: '1.25rem',
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightMedium,
              fontFamily: theme.typography.fontFamily,
              letterSpacing: '-0.015rem',
              padding: 0,
            })}
          >
            {isProject
              ? intl.formatMessage(
                { id: 'creabots.profiles.confirmDeleteProject' },
                { project: name },
              )
              : intl.formatMessage(
                { id: 'creabots.profiles.confirmDeleteProfile' },
                { name },
              )}
            {title}
          </DialogTitle>
          <DialogContentText
            id="alert-dialog-description"
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: '1rem',
              color: theme.palette.text.primary,
              fontWeight: theme.typography.fontWeightRegular,
              fontFamily: theme.typography.fontFamily,
            })}
          >
            {intl.formatMessage({ id: 'common.actionCannotBeUndone' })}
          </DialogContentText>
        </Box>
        <DialogContent
          sx={{ padding: 0, display: 'flex', flexDirection: 'column', gap: 4 }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              paddingX: 2,
              width: '100%',
            }}
          >
            <img
              src={avatarUrl}
              alt={`Avatar of ${name}`}
              style={{
                width: !isProject ? '64px' : '64px',
                height: !isProject ? '64px' : '112px',
                borderRadius: !isProject ? '50%' : '2px',
                objectFit: 'contain',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={(theme) => ({
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '1rem',
                  color: theme.palette.text.primary,
                })}
              >
                {name}
              </Typography>
              <Typography
                sx={(theme) => ({
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: theme.typography.fontWeightMedium,
                  fontSize: '.875rem',
                  color: theme.palette.text.secondary,
                  display: 'inline-flex',
                  gap: 1,
                })}
              >
                {isProject
                  ? intl.formatMessage({ id: 'creabots.profiles.created' })
                  : intl.formatMessage({
                    id: 'creabots.profiles.personalProjects',
                  })}
                :{' '}
                {loadingProjectsCount ? (<Skeleton width="1.5rem" height="20px" />) : (
                  <Typography
                    sx={(theme) => ({
                      fontFamily: theme.typography.fontFamily,
                      fontWeight: theme.typography.fontWeightMedium,
                      fontSize: '.875rem',
                      color: theme.palette.text.primary,
                    })}
                  >
                    {count}
                  </Typography>
                )}

              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
            }}
          >
            <Button
              variant="secondary"
              size="small"
              onClick={handleClose}
              disabled={isLoadingDeleteAction}
            >
              {intl.formatMessage({ id: 'common.cancel' })}
            </Button>
            <Button
              variant="destructive"
              size="small"
              onClick={onConfirmAction}
              disabled={isLoadingDeleteAction}
            >
              {intl.formatMessage({ id: 'common.delete' })}
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
