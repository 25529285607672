import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './turnsHeader.mod.scss';
import * as collaborationSessionSlice from '@sections/collaboration/features/collaborationSessionSlice';
import { differenceInSeconds, addMinutes, addSeconds, parseISO, format } from 'date-fns';
import { useCalcTurnsTime } from '@services/rest/collaboration/hooks';
const TurnsHeader = (props) => {
  const [now, setNow] = useState(new Date());
  const [finishTime, setFinishTime] = useState(null);
  const [currentTurn, setCurrentTurn] = useState(0);
  const [turnduration, setTurnduration] = useState(45);

  const turns = useSelector(collaborationSessionSlice.selectCollaborationSessionTurns);

  useEffect(() => {
    if (props.sessions.length > 0) {
      if (props.sessions[currentTurn].collaborationSessionDuration) {
        setTurnduration(parseInt(props.sessions[currentTurn].collaborationSessionDuration, 10));
      }
    }
  }, [props.sessions]);

  const calcFinishTime = () => {
    setNow(new Date());
    let tempFinishTime = null;
    let tempCurrentTurn = 0;
    for (let i = 0; i < 5; i += 1) {
      if (turns[i]) {
        const reservationDate = new Date(parseISO(turns[i].reservation_date_time || ''));
        const diffSeconds = differenceInSeconds(reservationDate, now);
        if (diffSeconds < 0 && diffSeconds > turnduration * -60) {
          tempFinishTime = addMinutes(reservationDate, turnduration);
          tempCurrentTurn = i;
        }
        // console.log('===================', i, diffSeconds, tempFinishTime);
      }
    }
    setCurrentTurn(tempCurrentTurn);
    setFinishTime(tempFinishTime);
  };

  useCalcTurnsTime(calcFinishTime, 1000);

  const getElapsedTime = () => {
    let remaining = "00:00";
    const difference = differenceInSeconds(finishTime, now);
    if (finishTime >= now) {
      remaining = format(addSeconds(new Date(0), difference), 'mm:ss');
    } else {
      setFinishTime(null);
    }
    return remaining;
  }

  const getDate = (id) => {
    return format(new Date(parseISO(turns[id].reservation_date_time || '')), 'dd/MM');
  };
  const getHour = (id) => {
    return format(new Date(parseISO(turns[id].reservation_date_time || '')), 'HH:mm');
  };
  const getNextTurn = (id) => {
    const maxTitleLength = 18;

    return (turns[id]) ? (
      <div className={`${styles.columnsSix} ${styles.canvas}`}>
        <div className={styles.title}>{`${turns[id].organization_name.substring(0, maxTitleLength)}${(turns[id].organization_name.length > maxTitleLength) ? '...' : ''}`}</div>
        <div className={styles.box}>
          <img src="/images/cosmos/createc/icon_calendar.png" />
          <div>{getDate(id)}</div>
          <img src="/images/cosmos/createc/icon_clock.png" />
          <div>{getHour(id)}</div>
        </div>
      </div>
    ) : ('')
  };

  return (
    <>
      <div className={styles.turnsHeader}>
        <div className={`${styles.columns} ${styles.logo}`}><img src="/images/cosmos/createc/logo_createc.png" height="140" /></div>
        {turns[currentTurn]?.organization_id ? (
          <React.Fragment>
            <div className={`${styles.columns} ${styles.box}`}>
              <div className={styles.headerIcon}><img alt="time" src="/images/cosmos/createc/institution.png" /></div>
              <div>
                <div className={styles.headerSubTitle}>Institución en <img src="/images/cosmos/createc/live.png" /></div>
                <div className={styles.headerTitle}>{turns[currentTurn].organization_name}</div>
                <div className={styles.headerSubTitle2}>Reserva: {getHour(currentTurn)}</div>
              </div>
            </div>
            <div className={`${styles.columns} ${styles.box}`}>
              {(finishTime) ? (
                <>
                  <div className={styles.headerIcon}><img alt="time" src="/images/cosmos/createc/sand_clock.png" /></div>
                  <div>
                    <div className={styles.headerHourRemaining}>Tiempo restante del turno en vivo:</div>
                    <div className={styles.headerHour}>{getElapsedTime()}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.headerIcon}><img alt="time" src="/images/cosmos/createc/clock.png" /></div>
                  <div>
                    <div className={styles.headerHourTitle}>Horario de la reserva</div>
                    <div className={styles.headerHour}>{getHour(currentTurn)}</div>
                  </div>
                </>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className={`${styles.columns} ${styles.box}`}>
            <div className={styles.headerIcon}><img alt="time" src="/images/cosmos/createc/institution.png" /></div>
            <div>
              <div className={styles.headerSubTitle}>Institución en <img src="/images/cosmos/createc/live.png" /></div>
              <div className={styles.headerTitle}>Ministerio de Educación CABA</div>
              <div className={styles.headerSubTitle2}></div>
            </div>
          </div>
        )}
      </div >
      {(turns.length > 1) ? (
        <div className={styles.rowSix}>
          <div className={`${styles.columnsSix} ${styles.logo}`}><img src="/images/cosmos/createc/next_turns.png" height="78" /></div>
          {getNextTurn(currentTurn + 1)}
          {getNextTurn(currentTurn + 2)}
          {getNextTurn(currentTurn + 3)}
          {getNextTurn(currentTurn + 4)}
          {getNextTurn(currentTurn + 5)}
        </div>
      ) : ('')}
    </>
  );
};

export default TurnsHeader;