import React from 'react';
import clsx from 'clsx';
import styles from './textField.mod.scss';

const TextField = ({
  className,
  classNames = { },
  type = 'text',
  ...props }) => (
    <input
      {...props}
      type={type}
      className={clsx(className, styles.container, classNames.container)}
    />
);

export default TextField;
