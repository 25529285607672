import { Box, Stack } from '@mui/material';
import React from 'react';
import { TextBodyEmphasized, TextBodyRegular } from '../ui/typographies';
import { useIntl } from 'react-intl';
import * as routes from '@educabot/educablocks-cosmos';

export const CreateProjectEmptyState: React.FC = () => {
  const intl = useIntl();

  return (
    <Stack direction="column" gap={2} alignItems="center">
      <Box width={360} height={360}>
        <img
          src={`${routes.bloquesUri}/images/creabots/create_project_empty_state.png`}
          alt="empty state"
          height="100%"
          width="100%"
          style={{
            objectFit: 'contain',
          }}
        />
      </Box>
      <TextBodyEmphasized sx={{
        textAlign: 'center',
      }}>
        {intl.formatMessage({
          id: 'creabots.dashboard.projectContainer.startToCreate',
        })}
      </TextBodyEmphasized>
      <TextBodyRegular sx={{
        textAlign: 'center',
        width: '35ch',
      }}>
        {intl.formatMessage({
          id: 'creabots.dashboard.projectContainer.startToCreateDescription',
        })}
      </TextBodyRegular>
    </Stack>
  );
};
