import React, { Component } from 'react';
import { ColorElement } from '../components/color/color';
import { rotatePort, cpuMillis } from '../utils';
import { ColorController, COLOR_ADDR } from '../libs/color';

export class Color extends Component {
  constructor(hardware, pin, id = 'Button', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.defaultColor = {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    };
    this.pinState = this.defaultColor;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'O', x: -3, y: 18, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 87,
      height: 35,
    };
    this.rotation = rotation;
    this.lastTimestamp = 0;

    this.i2cBus = null;
    this.colorController = null;

    this.ref = React.createRef();

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.stateHandler = this.stateHandler.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runMicrosecond = (cpuCycles) => {
    if (this.hardware) {
      const milliseconds = cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      const delta = milliseconds - this.lastTimestamp;
      if (!this.i2cBus) {
        this.i2cBus = this.hardware.i2cBus;
        this.colorController = new ColorController(cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz));
        this.i2cBus.registerDevice(COLOR_ADDR, this.colorController);
      }
      if (delta > 50) {
        if (this.ref.current) {
          this.pinState = this.ref.current.state?.color || this.defaultColor;
          this.colorController.update(1, this.pinState.r, this.pinState.g, this.pinState.b);
        }
        // switch (this.pinState) {
        //   default:
        //   case 'red':
        //     this.colorController.update(1, 1023, 0, 0);
        //     break;
        //   case 'green':
        //     this.colorController.update(1, 0, 1023, 0);
        //     break;
        //   case 'blue':
        //     this.colorController.update(1, 0, 0, 1023);
        //     break;
        // }
        this.lastTimestamp = milliseconds;
      }
    }
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => this.pinState;

  update = (newPinState) => {
    // console.log('================update', this.ref.current);
  }

  stateHandler = (pinState) => {
    // console.log('===================', pinState);
    this.pinState = pinState;
  }

  reset = () => {
    this.pinState = this.defaultColor;
  }

  render = () => (
    <ColorElement
      ref={this.ref}
      id={this.id}
      key={`color-${this.pin}`}
      rotation={this.rotation}
      onClickHandler={this.stateHandler}
    />
  );
}
