import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authAction from '../../actions/authAction';

export const useIsAuthenticated = () => {
  const userData = useSelector((state) => state.authReducer.userData);
  return userData.message === 'authenticated';
};

export const useCheckLogged = (withRedirect = true, to = 'login') => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer.userData);
  useEffect(() => {
    dispatch(authAction.checkAuthActionExternal(withRedirect, to));
  }, []);

  return userData;
};
