import React, { Component } from 'react';
import { LCDElement } from '../components/lcd/lcd';
import { rotatePort, cpuMillis } from '../utils';
import { LCD1602Controller, LCD1602_ADDR, getTextFromI2CCommand } from '../libs/lcd1602';

export class LCD extends Component {
  constructor(hardware, pin, id = 'LCD', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.hardware = hardware;
    this.id = id;
    this.pin = pin;
    this.pinState = 'pantalla';
    this.variant = variant || {
      color: 'white',
      background: 'blue',
    };
    this.pinInfo = [
      { name: 'i2c', x: -3, y: 36, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 204,
      height: 71,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.lastTimestamp = 0;
    this.i2cBus = null;
    this.lcd1602Controller = null;
    this.lcd = null;
    this.characters = null;

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runMicrosecond = (cpuCycles) => {
    if (this.hardware && this.pin) {
      const microseconds = cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      const delta = microseconds - this.lastTimestamp;
      if (!this.i2cBus) {
        this.i2cBus = this.hardware.i2cBus;
        this.lcd1602Controller = new LCD1602Controller(cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz));
        this.i2cBus.registerDevice(LCD1602_ADDR, this.lcd1602Controller);
      }
      // if (delta > 31) { // this is approximately the frequency which runner executes
      if (delta > 400) {
        this.lcd = this.lcd1602Controller.update();
        if (this.lcd.characters) {
          this.characters = this.lcd.characters;
        }
        this.lastTimestamp = microseconds;
      }
    }
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => {
    const value = getTextFromI2CCommand(this.characters);
    return {
      row0: value[0],
      row1: value[1],
    };
  }

  update = (pinState) => {
    // this.pinState = pinState;
  }

  reset = () => {
    this.pinState = false;
  }

  render = () => (
    <LCDElement
      ref={this.ref}
      id={this.id}
      key={`lcd-${this.pin}`}
      rotation={this.rotation}
      lcd={this.lcd}
      color={this.variant.color}
      background={this.variant.background}
    />
  );
}
