import React, { Component } from 'react';
import { LightElement } from '../components/light/light';
import { rotatePort, cpuMicros } from '../utils';

export class Light extends Component {
  constructor(hardware, pin, id = 'Button', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.pinState = 0;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'T', x: -3, y: 18, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 57,
      height: 34,
    };
    this.rotation = rotation;
    this.intensity = 0;
    this.lastTimestamp = 0;
    this.ref = React.createRef();

    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
    this.runMicrosecond = this.runMicrosecond.bind(this);
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => this.intensity;

  runMicrosecond = (cpuCycles) => {
    if (this.hardware && this.pin) {
      const microseconds = cpuMicros(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      const delta = microseconds - this.lastTimestamp;
      if (delta > 50000) {
        // console.log('================read', this.pin, this.intensity);
        this.hardware.writeAnalogPin(this.pin, this.intensity);
        this.lastTimestamp = microseconds;
      }
    }
  }

  update = (newPinState) => {
    // console.log('================update', this.ref.current);
    this.pinState = newPinState;
    this.intensity = this.ref.current?.state?.intensity || 0;
  }

  reset = () => {
    this.pinState = 0;
    this.intensity = 0;
  }

  render = () => <LightElement id={this.id} rotation={this.rotation} ref={this.ref} key={`light-${this.pin}`} />;
}
