import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Funciones" name="%{BKY_LANG_CATEGORY_PROCEDURES}" iconclass="fx" expandedclass="fx-extended" custom="PROCEDURE">
      <label text="%{BKY_LANG_CATEGORY_PROCEDURES}" />
    </category>
    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_whileUntil" />
      <block type="controls_for">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_flow_statements" />
      <block type="base_millis" />
      <block type="controls_doWhile" />
      <block type="controls_execute" />
    </category>
    <category id="Lógica" name="%{BKY_LANG_CATEGORY_LOGIC}" iconclass="logics" expandedclass="logics-extended">
      <label text="%{BKY_LANG_CATEGORY_LOGIC}" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>
    <category id="Matemáticas" name="%{BKY_LANG_CATEGORY_MATH}" iconclass="math" expandedclass="math-extended">
      <label text="%{BKY_LANG_CATEGORY_MATH}" />
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_array" />
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_pwm">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_minmax" />
    </category>
    <category id="Variables" name="%{BKY_LANG_CATEGORY_VARIABLES}" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>
    <category id="Texto" name="%{BKY_LANG_CATEGORY_TEXT}" iconclass="txt" expandedclass="txt-extended">
      <label text="%{BKY_LANG_CATEGORY_TEXT}" />
      <block type="text" />
      <block type="text_unir" />
      <block type="text_append">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_length">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_equalsIgnoreCase">
        <value name="STRING2">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="text_substring">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">5</field>
          </shadow>
        </value>
      </block>
      <block type="text_printcode">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="Comunicación" name="%{BKY_LANG_CATEGORY_COMMUNICATION}" iconclass="comm" expandedclass="comm-extended">
      <label text="%{BKY_LANG_CATEGORY_COMMUNICATION}" />
      <block type="serial_println">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="serial_print">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_conversion">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">5</field>
          </shadow>
        </value>
      </block>
      <block type="SALIDAS_scan_def" />
      <block type="serial_available" />
      <block type="serial_special" />
      <block type="blue_read" />
      <block type="serial_read" />
      <block type="serial_blue">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="bt_serial_available" />
      <block type="SALIDAS_buttons" />
      <block type="bluetooth_name">
        <value name="DATOIN">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>
    <category name="%{BKY_LANG_CATEGORY_ENTRADAS}" iconclass="mixedInputs" expandedclass="mixedInputs-extended" expanded="true">
      <category id="Digitales" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}" iconclass="inputsDigi" expandedclass="inputsDigi-extended">
        <label text="%{BKY_LANG_SUBCATEGORY_DIGITALES}" />
        <block type="ARDUINOUNO_ENTRADAS_button" />
        <block type="ARDUINOUNO_entradas_ultrasonido" />
        <block type="ARDUINOUNO_ENTRADAS_button_touch" />
        <block type="ARDUINOUNO_SALIDAS_read_dht11" />
        <block type="ARDUINOUNO_ENTRADAS_obstaculo" />
        <block type="ARDUINOUNO_entradas_hall" />
        <block type="ARDUINOUNO_ENTRADAS_infrared" />
        <block type="ARDUINOUNO_ENTRADAS_temperature" />
        <block type="ARDUINOUNO_SALIDAS_IR" />
        <block type="ARDUINOUNO_ENTRADAS_VIBRATION" />
        <block type="ARDUINOUNO_ENTRADAS_TILT" />
        <block type="ARDUINOUNO_ENTRADAS_CHOQUE" />
        <block type="ARDUINOUNO_ENTRADAS_follower" />
        <block type="ARDUINOUNO_ENTRADAS_GOLPE" />
        <block type="ARDUINOUNO_ENTRADAS_INTERRUPTOR_OPTICO" />
        <block type="ARDUINOUNO_ENTRADAS_ENCODER" />
        <block type="inout_digital_read" />
      </category>
      <category id="Analogicas" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" iconclass="inputsAnal" expandedclass="inputsAnal-extended">
        <label text="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" />
        <block type="ARDUINOUNO_entradas_suelo" />
        <block type="ARDUINOUNO_entradas_lluvia" />
        <block type="ARDUINOUNO_ENTRADAS_potentiometer" />
        <block type="ARDUINOUNO_ENTRADAS_photoresistor" />
        <block type="ARDUINOUNO_entradas_sonido" />
        <block type="ARDUINOUNO_ENTRADAS_FUEGO" />
        <block type="ARDUINOUNO_ENTRADAS_TEMPERATURA" />
        <block type="ARDUINOUNO_ENTRADAS_JOYSTICK" />
        <block type="ARDUINOUNO_ENTRADAS_PULSO" />
        <block type="ARDUINOUNO_ENTRADAS_MAGNETICO" />
        <block type="inout_analog_read" />
      </category>
    </category>
    <category id="Salidas" name="%{BKY_LANG_CATEGORY_SALIDAS}" iconclass="outputs" expandedclass="outputs-extended">
      <label text="%{BKY_LANG_CATEGORY_SALIDAS}" />
      <block type="ARDUINOUNO_salidas_led" />
      <block type="ARDUINOUNO_salidas_led_brillo">
        <value name="DATOIN">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzer_piano" />
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzerno" />
      <block type="ARDUINOUNO_salidas_zumbador_activo" />
      <block type="ARDUINOUNO_salidas_laser" />
      <block type="ARDUINOUNO_salidas_led_bicolor" />
      <block type="ARDUINOUNO_salidas_led_bicolor_apagar" />
      <block type="ARDUINOUNO_salidas_ledRGB">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_salidas_rele" />
    </category>
    <category id="Pantallas" name="%{BKY_LANG_CATEGORY_LCD}" iconclass="screens" expandedclass="screens-extended">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="ARDUINOUNO_lcd_educabot">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_lcd_setBacklight" />
      <block type="ARDUINOUNO_lcd_clear" />
    </category>
    <category id="Motores" name="%{BKY_LANG_CATEGORY_SERVO}" iconclass="motors" expandedclass="motors-extended">
      <label text="%{BKY_LANG_CATEGORY_SERVO}" />
      <block type="ARDUINOUNO_servo_move">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_servo_read" />
      <block type="ARDUINOUNO_SALIDAS_motor" />
      <block type="ARDUINOUNO_SALIDAS_motor_pwm">
        <value name="DATOIN">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_SALIDAS_motor_doble" />
      <block type="ARDUINOUNO_SALIDAS_motor_doble_pwm">
        <value name="DATOIN">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
    </category>
  </React.Fragment>
);
/* eslint-enable */
