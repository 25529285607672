import { useEffect, useState } from 'react';
import { useUpdateCollaborationTutorialState, useCollaboration } from '@services/rest/collaboration/hooks';
import { tutorialEvaluationTypes } from './constants';


const Tutorial = (props) => {
  const [tutorialStep, setTutorialStep] = useState(-1);
  const [socketClient, setSocketClient] = useState(null);
  const [projectId, setProjectId] = useState(null);

  const [userDataState, setUserDataState] = useState({});
  const collabState = useCollaboration();
  const { updateCollaborationTutorialState } = useUpdateCollaborationTutorialState();


  const notifySocketUsers = (message = {}) => {
    if (socketClient?.socket) {
      const userMessage = message;
      if (!userMessage.notifierUserId) {
        userMessage.notifierUserId = userDataState?.data?.userId || 0;
      }
      socketClient.socket.emit('notifyUsers', collabState.collaboration.id, userMessage);
    }
  };

  const sanitizeCodeString = (code) => {
    // TODO: these are rule to normalize Arduino's generated code for comparison.
    // In the future they could change and this function should be written in a better way
    let sanitizedCode = code.replace(/\n|\s/gi, '');
    sanitizedCode = sanitizedCode.replace(/bitmap_[0-9]{1,6}/gi, 'bitmapMatrix');
    sanitizedCode = sanitizedCode.replace(/cuentaRegresiva_[0-9]{1,6}/gi, 'cuentaRegresiva');

    return sanitizedCode;
  };

  const checkTutorialCompletion = (correctCode = '') => {
    if (tutorialStep >= 0) {
      let evaluation = tutorialEvaluationTypes.ERROR;
      const correctCodeParsed = sanitizeCodeString(correctCode);
      const testCodeParsed = sanitizeCodeString(collabState.tutorial.tutorialCurrentStep?.canvasCode || '');
      if (correctCodeParsed === testCodeParsed) {
        evaluation = tutorialEvaluationTypes.SUCCESS;
      }
      notifySocketUsers({ tutorialStepEvaluation: evaluation });
    }
    updateCollaborationTutorialState({ checkCode: '' });
  };


  const getCompletionProgress = (currentStep = 0) => {
    let steps = 0;
    for (let i = 0; i < collabState.levels.length; i += 1) {
      if (collabState.levels[i].canvasCode && collabState.levels[i].canvasCode !== 'null') {
        steps += 1;
      }
    }
    const progress = parseInt((100 / steps) * currentStep, 10);
    return progress;
  };


  // UPDATE LOCAL TUTORIAL STATE
  useEffect(() => {
    if (projectId) {
      if (
        typeof collabState.tutorial.tutorialStep !== 'undefined'
        && collabState.tutorial.tutorialStep !== null
        && collabState.tutorial.tutorialStep > tutorialStep
      ) {
        setTutorialStep(collabState.tutorial.tutorialStep);
      }
      if (typeof collabState.tutorial.checkCode !== 'undefined' && collabState.tutorial.checkCode !== '') {
        checkTutorialCompletion(collabState.tutorial.checkCode);
      }
    }
  }, [collabState.tutorial, collabState.levels]);


  // CHECK NOTIFICATIONS RECEIVED
  useEffect(() => {
    if (projectId && collabState.notifications.tutorialStepEvaluation) {
      updateCollaborationTutorialState({ tutorialStepEvaluation: collabState.notifications.tutorialStepEvaluation });
    }
    if (
      projectId
      && typeof collabState.notifications.tutorialStep !== 'undefined'
      && collabState.notifications.tutorialStep > collabState.collaboration?.collaborationTutorialStep
      && collabState.notifications.tutorialStep > tutorialStep
      // && collabState.notifications.tutorialStep !== collabState.collaboration?.collaborationTutorialStep
      // && collabState.notifications.notifierUserId !== userDataState?.data?.userId
    ) {
      // NOTIFIED ABOUT NEXT TUTORIAL STEP
      const progress = getCompletionProgress(collabState.notifications.tutorialStep);
      updateCollaborationTutorialState({
        tutorialStep: collabState.notifications.tutorialStep,
        tutorialCurrentStep: collabState.levels[collabState.notifications.tutorialStep] || null,
        tutorialProgress: progress,
        tutorialStepEvaluation: tutorialEvaluationTypes.START,
      });
    }
  }, [collabState.notifications]);


  useEffect(() => {
    if (
      projectId
      && socketClient
      && collabState.collaboration?.collaborationTutorial
      && collabState.levels.length > 0
      && collabState.collaboration?.collaborationTutorialStep < collabState.levels.length
    ) {
      if (
        collabState.collaboration?.collaborationTutorialStep > -1
        && collabState.collaboration?.collaborationTutorialStep > tutorialStep
      ) {
        // NEXT TUTORIAL STEP
        const progress = getCompletionProgress(collabState.collaboration.collaborationTutorialStep);
        updateCollaborationTutorialState({
          tutorialStep: collabState.collaboration.collaborationTutorialStep,
          tutorialCurrentStep: collabState.levels[collabState.collaboration.collaborationTutorialStep] || null,
          tutorialProgress: progress,
          tutorialStepEvaluation: tutorialEvaluationTypes.START,
        });
        notifySocketUsers({ tutorialStep: collabState.collaboration.collaborationTutorialStep });
      } else if (tutorialStep === -1) {
        notifySocketUsers({ tutorialStep: 0 });
      }
    }
  }, [socketClient, collabState.levels, collabState.collaboration?.collaborationTutorialStep]);


  useEffect(() => {
    setSocketClient(props.socket);
  }, [props.socket]);

  useEffect(() => {
    setProjectId(props.projectId);
  }, [props.projectId]);

  useEffect(() => {
    setUserDataState(props.userDataState);
  }, [props.userDataState]);
};

export default Tutorial;
