import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="new_event_start" x="50" y="100" deletable="false" movable="false" />
  </xml>
);

export const categoryXml = (
  <React.Fragment>

    {/* <category name="Inicio" iconclass="start"> */}
    <block type="new_event_repeat" />
    {/* </category> */}

    {/* <category name="Control" iconclass="control"> */}
    <block type="control_loop_number">
      <value name="CHOICE">
        <shadow type="dropdown_1_9_numbers">
          <field name="CHOICE">1</field>
        </shadow>
      </value>
    </block>
    <block type="on_buton_pressed">
      <value name="CHOICE">
        <shadow type="dropdown_on_button_pressed_event">
          <field name="CHOICE">A</field>
        </shadow>
      </value>
    </block>
    {/* </category> */}

    {/* <category name="Básico" iconclass="basic"> */}
    <block type="pause_millis">
      <value name="CHOICE">
        <shadow type="dropdown_1_9_seconds">
          <field name="CHOICE">1000</field>
        </shadow>
      </value>
    </block>
    <block type="show_icon">
      <value name="CHOICE">
        <shadow type="dropdown_show_icon_event">
          <field name="CHOICE">Heart</field>
        </shadow>
      </value>
    </block>
    <block type="show_string">
      <value name="STR" type="input_value">
        <shadow type="show_string_event">
          <field name="STR">TEXTO</field>
        </shadow>
      </value>
    </block>
    {/* </category> */}

  </React.Fragment>
);
