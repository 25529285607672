import React from 'react';

export const initXml = {
  // blocks: {
  //   blocks: [
  //     {
  //       deletable: false,
  //       type: 'new_event_repeat',
  //       x: 50,
  //       y: 50,
  //     },
  //   ],
  // },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="procedures" categorystyle="procedure_category" name="%{BKY_LANG_CATEGORY_PROCEDURES}" custom="PROCEDURES_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_PROCEDURES}" />
    </category>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_BASIC}">
      <block type="microbit_start" />
      <block type="microbit_forever" />
      <block type="microbit_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">100</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_button_pressed" />
      <block type="microbit_pin_pressed" />
      <block type="microbit_pin_released" />
      <block type="microbit_gesture" />
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="microbit_repeat">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_whileUntil" />
    </category>
    <category id="logic" categorystyle="logic_category" name="%{BKY_LANG_CATEGORY_LOGIC}">
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
      <block type="button_boolean" />
      <block type="pin_boolean" />
    </category>
    <category id="math" categorystyle="math_category" name="%{BKY_LANG_CATEGORY_MATH}">
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_array" />
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="variables" categorystyle="variable_dynamic_category" name="%{BKY_LANG_CATEGORY_VARIABLES}" custom="VARIABLE_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>
    <category id="text" categorystyle="text_category" name="%{BKY_LANG_CATEGORY_TEXT}">
      <block type="text" />
      <block type="text_unir" />
    </category>
    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_ENTRADAS}">
      <category id="inputs_digital" categorystyle="digital_inputs_category" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}">
        <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
        <block type="microbit_digital_read" />
        <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
        <block type="microbit_button_read" />
        <block type="microbit_touch_read" />
        <block type="microbit_limit_switch_read" />
        <block type="microbit_hall_effect" />
        <block type="microbit_tilt" />
        <block type="microbit_ultrasonic" />
        <block type="microbit_ir_switch" />
        <block type="microbit_fire_read" />
        <block type="microbit_pir" />
        <block type="microbit_line_follower" />
      </category>
      <category id="inputs_analogue" categorystyle="analogue_inputs_category" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}">
        <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
        <block type="input_temperature" />
        <block type="input_light_level" />
        <block type="input_acceleration" />
        <block type="input_compass" />
        <block type="input_rotation" />
        <block type="input_magnetic_force" />
        <block type="microbit_analog_read" />
        {/* <block type="input_gesture" /> */}
        <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
        <block type="microbit_potentiometer_read" />
        <block type="microbit_temperature_read" />
        <block type="microbit_ldr_read" />
        <block type="microbit_sound_read" />
        <block type="microbit_soil_read" />
        <block type="microbit_rain_read" />
        <block type="microbit_vibration" />
        <block type="microbit_gas_read" />
        <block type="microbit_alcohol_read" />
        <block type="microbit_steam_read" />
        <block type="microbit_pressure_read" />
        {/* <block type="input_running_time" />
        <block type="input_running_time_micros" />
        <block type="input_accelerometer_range" /> */}
      </category>
    </category>
    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_SALIDAS}">
      <block type="microbit_play_tone">
        <value name="TONE">
          <block type="microbit_tones">
            <field name="TONE">131</field>
          </block>
        </value>
        <value name="BEAT">
          <block type="microbit_beats">
            <field name="BEAT">Whole</field>
          </block>
        </value>
      </block>
      <block type="microbit_play_melody" />
      <block type="microbit_led" />
      <block type="microbit_led_brillo">
        <value name="DATOIN">
          <shadow type="math_number_minmax_analog">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_led_rgb">
        <value name="COLOUR">
          <shadow type="microbit_colour">
            <field name="COLOUR">#ff0000</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_relay" />
    </category>
    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_LCD}">
      <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
      <block type="microbit_show_arrow" />
      <block type="microbit_show_number">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_show_icon">
        <value name="CHOICE">
          <shadow type="dropdown_show_icon_event">
            <field name="CHOICE">Heart</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_show_string">
        <value name="STR">
          <shadow type="text">
            <field name="STR">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_clear_screen" />
      <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
      <block type="microbit_lcd_i2c">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_lcd_i2c_clear" />
    </category>
    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_SERVO}">
      <block type="microbit_servo_180">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
    </category>
  </xml>
);
/* eslint-enable */
