import React, { useEffect, useState } from 'react';
import styles from './firmwareModals.mod.scss';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';
import Modal from '../../../resources/components/modals/modal'

const FirmwareModals = (props) => {
  const title = props.title || '';
  const actionMessage = props.actionMessage || 'Instalar';
  const id = (props.id) ? props.id : 'firmwareModal';
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive((props.active));
  }, [props.active]);

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setActive(false);
  }

  const onActionClick = (event) => {
    if (props.action) {
      props.action(event);
    }
    onClose();
  };

  return (
    <Modal open={active}>
      <div id={id} className={`${styles.modalContent} ${(props.className) ? styles[props.className] : ''}`}>
        <div className={styles.modalBody}>
          <h5>{title}</h5>
          <div className={styles.message}>
            {(props.manifest?.name) ? (
              `${props.manifest.name}, Versión ${props.manifest.version}`
            ) : ('')}
          </div>
        </div>
        <div className={styles.modalFooter}>
          <ButtonV2 onClick={onActionClick}>{actionMessage}</ButtonV2>
          <ButtonV2 className={styles.closeButton} onClick={onClose}>Cerrar</ButtonV2>
        </div>
      </div>
    </Modal>
  );
};

export default FirmwareModals;