import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="createc_madero_initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="controls_if_no_else" />
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>


    <category id="Entradas" name="%{BKY_LANG_CATEGORY_COMPARISON}" iconclass="inputsDigi" expandedclass="inputsDigi-extended">
      <label text="%{BKY_LANG_CATEGORY_COMPARISON}" />
      <block type="createc_logic_compare_ldr">
        <value name="A">
          <block type="createc_ciudad_ldr" />
        </value>
      </block>
      <block type="createc_logic_compare_ship">
        <value name="A">
          <block type="createc_ship" />
        </value>
      </block>
      <block type="createc_logic_compare_bridge">
        <value name="A">
          <block type="createc_bridge" />
        </value>
      </block>
    </category>
  
    <category id="Salidas" name="%{BKY_LANG_CATEGORY_LIGHTNING}" iconclass="outputs" expandedclass="outputs-extended">
      <label text="%{BKY_LANG_CATEGORY_LIGHTNING}" />
      <block type="madero_createc_restore_electricity" />
      <block type="createc_luminaria_zona1" />
      <block type="createc_luces_dique3">
        <value name="COLOUR">
          <shadow type="colour_createc_number">
            <field name="COLOUR">#00ff00</field>
          </shadow>
        </value>
      </block>
      <block type="createc_luces_dique3_off" />
      <block type="createc_puente_mujer">
        <value name="COLOUR">
          <shadow type="colour_createc_number">
            <field name="COLOUR">#00ff00</field>
          </shadow>
        </value>
      </block>
      <block type="createc_puente_mujer_off" />
      <block type="createc_senda_peatonal">
        <value name="COLOUR">
          <shadow type="colour_createc_number">
            <field name="COLOUR">#00ff00</field>
          </shadow>
        </value>
      </block>
      <block type="createc_senda_peatonal_off" />
    </category>

    <category id="Motores" name="%{BKY_LANG_CATEGORY_MOTION}" iconclass="motors" expandedclass="motors-extended">
      <label text="%{BKY_LANG_CATEGORY_MOTION}" />
      <block type="createc_open_puente_mujer" />
      {/* <block type="createc_ship_ok_signal" /> */}
    </category>
  </React.Fragment>
);
/* eslint-enable */
