import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './MLPanel.mod.scss';
import useBluetooth from '@utils/hooks/bluetooth'
import useMachineLearning from '@utils/hooks/machineLearning'
import LinearProgress from '@mui/material/LinearProgress';

const ARDUINO_CONNECTION_SERVICE = '0000ffe0-0000-1000-8000-00805f9b34fb'
const ARDUINO_CONNECTION_CHARACTERISTIC = '0000ffe1-0000-1000-8000-00805f9b34fb'

const MLPanel = React.memo((props) => {
  const location = useLocation();
  const DebounceState = useRef(0)
  const lastSentCommand = useRef('')
  const mlDataSet = useRef([])

  const [commandClass, setCommandClass] = useState('MLClass')
  const [stopCommandClass, setStopCommandClass] = useState('MLC_Stop')
  const [videoWrapper, setVideoWrapper] = useState('canvas-wrapper')
  const [confidenceLevel, setConfidenceLevel] = useState(70)
  const [messageDelay, setMessageDelay] = useState(300)

  const [step, setStep] = useState('train')
  const [cameraAllowed, setCameraAllowed] = useState(false)
  const [samples, setSamples] = useState({})
  const [confidences, setConfidences] = useState({})
  const [toggleVideo, setToggleVideo] = useState(true)
  const [importedDataSet, setImportedDataSet] = useState(null)
  const [loading, setLoading] = useState(false)

  const { requestConnection, sendMessage, connected } = useBluetooth({
    serviceID: ARDUINO_CONNECTION_SERVICE,
    characteristicID: ARDUINO_CONNECTION_CHARACTERISTIC,
  });

  const getPrediction = (detectionConfidences) => {
    setConfidences(detectionConfidences);
    if (Object.keys(detectionConfidences).length > 0) {
      mlDataSet.current.map((data) => {
        if (detectionConfidences[data[0]] && (detectionConfidences[data[0]] || 0) * 100 >= confidenceLevel) {
          if (Date.now() - DebounceState.current > messageDelay) {
            DebounceState.current = Date.now();
            console.log('================message', data[1]);
            sendMessage(data[1]);
            lastSentCommand.current = data[1];
          }
        }
      });
    } else if (lastSentCommand.current !== stopCommandClass) {
      console.log('================message', stopCommandClass);
      sendMessage(stopCommandClass);
      lastSentCommand.current = stopCommandClass;
    }
  }
  const { app, stopVideo, addSample, clearAllSamples, startClassifying, askForCameraPermission, addDataset } = useMachineLearning({
    wrapperClass: videoWrapper,
    getPredictionCallback: getPrediction,
  });

  const onToggleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  }

  const changeStep = (newStep) => {
    setStep(newStep);
    if (newStep !== 'train') {
      if (!connected) {
        // TODO: 'requestDevice' on 'Bluetooth': Must be handling a user gesture to show a permission request
        requestConnection()
      }
      startClassifying();
    } else {
      clearAllSamples();
      setSamples({});
    }
  }

  const takeSample = (className) => {
    const nSamples = { ...samples };
    addSample(className).then((count) => {
      nSamples[className] = count;
      setSamples(nSamples);
    });
  }

  const renderClassContainers = () => {
    const containers = [];
    mlDataSet.current.map((data) => {
      containers.push(
        <div key={data[1]} className={styles.classContainer}>
          <div className={styles.label}>
            <label>{data[0]}</label>
          </div>
          <button onClick={() => takeSample(data[0])}>Tomar muestra ({samples[data[0]] || 0})</button>
        </div>
      );
    });
    return containers;
  };

  const renderPredictionContainers = () => {
    const containers = [];
    mlDataSet.current.map((data) => {
      containers.push(
        <div key={data[1]} className={`${styles.classContainer} ${styles.predictionContainer}`}>
          <div className={styles.label}>
            <label>{data[0]}</label>
            <span>{(confidences[data[0]] || 0) * 100 || 0} %</span>
          </div>
          <LinearProgress className={styles.progress} variant="determinate" value={(confidences[data[0]] || 0) * 100 || 0} />
        </div>
      );
    });
    return containers;
  };

  const handleToggleVideo = () => {
    setToggleVideo(!toggleVideo);
    if (toggleVideo) {
      stopVideo();
    } else {
      askForCameraPermission()
        .then(() => {
          startDataSet();
        })
        .catch((e) => {
          console.error('=============', e);
          setCameraAllowed(false);
          setToggleVideo(false);
        });
    }
  }

  const handleAskCameraPermissions = () => {
    askForCameraPermission().then(() => {
      setCameraAllowed(true);
      setToggleVideo(true);
    })
      .catch((e) => {
        console.error('=============', e);
        setCameraAllowed(false);
      });
  }

  const startDataSet = async () => {
    if (importedDataSet) {
      mlDataSet.current = [];
      const nSamples = {};
      const classes = Object.keys(importedDataSet);
      for (let i = 0; i < classes.length; i++) {
        mlDataSet.current.push([classes[i], `${commandClass}${i + 1}`]);
        nSamples[classes[i]] = importedDataSet[classes[i]].length;
      }
      setSamples(nSamples);
      await addDataset(importedDataSet);
      changeStep('classify');
    }
    setCameraAllowed(true);
    setToggleVideo(true);
  };

  useEffect(() => {
    app()
      .then(() => {
        startDataSet();
      })
      .catch((e) => {
        console.error('=============', e);
        setCameraAllowed(false);
      });

    return () => {
      stopVideo();
      setToggleVideo(false);
    }
  }, [props.active, importedDataSet])

  useEffect(() => {
    if (props.workspace) {
      const classes = {};
      mlDataSet.current.map((data) => {
        classes[data[1]] = data[0];
      });
      classes[stopCommandClass] = 'No reconocido';
      props.workspace.setMLClasses(classes);
    }
  }, [props.workspace, mlDataSet.current])

  useEffect(() => {
    const dataSet = location
      .search
      .split('=')
      .filter(Boolean)[1] || '';

    const loadData = async () => {
      try {
        // http://dataset-storer.educabot.workers.dev/v1/datasets/71a0e50a-c4dc-48f5-9b0c-e0d5aeda6726
        const response = await fetch(`https://dataset-storer.educabot.workers.dev/v1/datasets/${dataSet}`);
        const jsonifiedModel = await response.json();
        console.log('==================dataset', jsonifiedModel.dataset);
        setImportedDataSet(jsonifiedModel.dataset);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error loading the dataset:', error);
      }
    };

    if (dataSet) {
      setLoading(true);
      loadData();
    } else {
      const count = 5;
      mlDataSet.current = [];
      for (let i = 1; i <= count; i++) {
        mlDataSet.current.push([`Clase ${i}`, `${commandClass}${i}`]);
      }
    }

    // console.log('==================', dataSet);
  }, [])

  return (
    <React.Fragment>
      <div className={`col-12 p-0 codeBox ${(props.active) ? 'codeBox-active' : ''} ${styles.codeMLBox}`}>
        <div className={styles.contentCodeBox}>
          {loading ? (
            <div className={styles.loading}>Cargando modelo ...</div>
          ) : (
            <div class={videoWrapper}>
              <canvas id="output"></canvas>
              <video id="video" playsinline style={{
                transform: 'scaleX(-1)',
                visibility: 'hidden',
                display: 'none',
                width: 'auto',
                height: 'auto',
              }}>
              </video>
            </div>
          )}
          {step === 'train' ? (
            <>
              {renderClassContainers()}
              <div className={styles.trainActions}>
                {Object.values(samples).filter(sample => sample > 0).length > 1 ? (
                  <button onClick={() => changeStep('classify')}><img src="/images/ai/play.svg" />Iniciar análisis</button>
                ) : (null)}
                {!cameraAllowed ? (
                  <div>No has dado permisos para que usemos la cámara <button onClick={handleAskCameraPermissions}>Permitir</button></div>
                ) : (
                  <button className={styles.cancel} onClick={handleToggleVideo}>{toggleVideo ? 'Apagar' : 'Encender'} cámara</button>
                )}
              </div>
            </>
          ) : (
            <>
              {renderPredictionContainers()}
              <div className={styles.classifyActions}>
                {!importedDataSet ? (
                  <button onClick={() => changeStep('train')}>Volver a Entrenar</button>
                ) : (null)}
                {!connected ? (
                  <button className={styles.cancel} onClick={requestConnection}>Conectar bluetooth</button>
                ) : (null)}
                <button className={styles.cancel} onClick={handleToggleVideo}>{toggleVideo ? 'Apagar' : 'Encender'} cámara</button>
              </div>
            </>
          )}
        </div>
        <div className="toggle-codeBox toggle-MLPanel" role="button" tabIndex="0" onClick={onToggleChange}>
          <span className="curly-braces"><img src="/images/ai/toggleAiPanel.svg" /></span>
          <span className={`icon-chevron-left ${(props.active) ? 'rotate-arrow' : ''}`} />
        </div>
      </div>
    </React.Fragment >
  );
});

export default MLPanel;