import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import MUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  Button: ({ size }) => ({
    boxSizing: 'border-box',
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: size === 'large' ? 16 : 14,
    lineHeight: size === 'large' ? '21px' : '18px',
    textTransform: 'none',
    padding: 0,
    color: theme.palette.primary.dark,
    background: 'rgba(0,0,0,0)',
    '& span span svg path': {
      fill: theme.palette.primary.dark,
    },
    '&:hover': {
      background: 'rgba(0,0,0,0)', // Overwrite default behavior
      color: theme.palette.primary.main,
      '& span span svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:active': {
      background: 'rgba(0,0,0,0)', // Overwrite default behavior
      color: theme.palette.primary.dark,
      textShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
      '& span span svg': {
        filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25))',
      },
      '& span span svg path': {
        fill: `${theme.palette.primary.dark}!important`,
      },
    },
  }),
  disabled: {
    opacity: 0.3,
    border: 'none',
    color: `${theme.palette.primary.main} !important`,
  },
  iconLeft: {
    justifyItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
  loadingContainer: {
    height: 21,
    display: 'flex',
    alignItems: 'center',
  },
}));

const TextButton = ({ children, size = 'large', disabled = false, onClick, loading, className, IconLeft, iconLeftClassname, IconRight, iconRightClassname, ...props }) => {
  const classes = useStyles({ size });
  return (
    <MUIButton
      {...props}
      onClick={onClick}
      disabled={disabled || loading}
      disableRipple
      className={clsx(
        classes.Button,
        { [classes.disabled]: disabled },
        className,
      )}
    >
      {IconLeft && !loading && <span className={classes.iconLeft}><IconLeft className={iconLeftClassname} /></span>}
      {loading ? <div className={classes.loadingContainer}><CircularProgress color="primary" size={18} /></div> : children}
      {IconRight && !loading && <span className={classes.iconRight}><IconRight className={iconRightClassname} /></span>}
    </MUIButton>
  );
};

export default TextButton;
