import { boardsService } from '@services/rest';
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from '@utils/redux';
import { getBoardsByCosmos } from '../../../cosmos';


const GET_ALL_BOARDS = 'BOARDS/GET_ALL';
const GET_ALL_BOARDS_FAIL = 'BOARDS/GET_ALL_FAIL';
const GET_ALL_BOARDS_SUCCESS = 'BOARDS/GET_ALL_SUCCESS';

const initialState = ({
  boards: [],
  error: null,
  status: IDLE_STATUS,
});

export default function boardsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BOARDS: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case GET_ALL_BOARDS_SUCCESS: {
      const { boards } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        boards,
      };
    }
    case GET_ALL_BOARDS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    default: return state;
  }
}

// selectors
export const selectBoards = (state) => state.boards;

// actions
const getAllBoardsSuccess = (dispatch) => (boards) => dispatch({
  type: GET_ALL_BOARDS_SUCCESS,
  payload: {
    boards,
  },
});

const getAllBoardsFail = (dispatch) => (error) => dispatch({
  type: GET_ALL_BOARDS_FAIL,
  payload: {
    error,
  },
});

export const getAllBoards = (offline) => (dispatch) => {
  dispatch({ type: GET_ALL_BOARDS });
  dispatch({
    type: GET_ALL_BOARDS_SUCCESS,
    payload: {
      boards: getBoardsByCosmos('all'),
    },
  });
  // boardsService
  //   .getAll(offline)
  //   .then(getAllBoardsSuccess(dispatch))
  //   .catch(getAllBoardsFail(dispatch));
};
