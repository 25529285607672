export const installState = {
  IDLE: 'Preparado',
  INITIALIZING: 'Inicializando...',
  PREPARING: 'Preparando...',
  ERASING: 'Borrando...',
  WRITING: 'Escribiendo...',
  FINISHED: 'Finalizado',
  ERROR: 'ERROR',
};

export default installState;
