import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from '@utils/redux';

const BLOCKLY_LOAD_START = 'BLOCKLY/LOAD_START';
const BLOCKLY_LOAD_FINISH = 'BLOCKLY/LOAD_FINISH';

const UPDATE_BLOCKLY = 'BLOCKLY/UPDATE';
const BLOCKLY_BUILD = 'BLOCKLY/BUILD';
const BLOCKLY_BUILD_PORT = 'BLOCKLY/BUILD_PORT';
const BLOCKLY_BUILD_MODAL_OPEN = 'BLOCKLY/BUILD_MODAL_OPEN';
const BLOCKLY_BUILD_MODAL_CLOSE = 'BLOCKLY/BUILD_MODAL_CLOSE';
const BLOCKLY_BUILD_PROGRESS = 'BLOCKLY/BUILD_PROGRESS';
const BLOCKLY_STOP_BUILD = 'BLOCKLY/STOP_BUILD';

const WEBSERIAL_OPEN = 'WEBSERIAL/OPEN';
const WEBSERIAL_CLOSE = 'WEBSERIAL/CLOSE';
const WEBSERIAL_CHANGE_STEP = 'WEBSERIAL/CHANGE_STEP';
const WEBSERIAL_CHANGE_STATE = 'WEBSERIAL/CHANGE_STATE';


export const initialState = {
  project: {},
  loading: false,
  error: null,
  status: IDLE_STATUS,
  webSerialModal: {
    open: false,
    step: 0,
    connected: false,
    error: null,
  },
  build: {
    building: false,
    modalOpen: false,
    progress: 0,
    microbitForceDownload: false,
    compileFromQueueId: null,
    remoteProjectId: null,
    port: '',
  },
};

export default function blocklyReducer(state = initialState, action) {
  switch (action.type) {
    case BLOCKLY_LOAD_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case BLOCKLY_LOAD_FINISH: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_BLOCKLY: {
      const { project } = action.payload;
      return {
        ...state,
        project: { ...state.project, ...project },
        status: SUCCESS_STATUS,
      };
    }
    case WEBSERIAL_OPEN: {
      return {
        ...state,
        webSerialModal: { ...state.webSerialModal, open: true },
      };
    }
    case BLOCKLY_BUILD: {
      const { params } = action.payload;
      return {
        ...state,
        build: {
          ...state.build,
          ...params,
          building: true,
        },
      };
    }
    case BLOCKLY_BUILD_MODAL_OPEN: {
      return {
        ...state,
        build: {
          ...state.build,
          modalOpen: true,
        },
      };
    }
    case BLOCKLY_BUILD_MODAL_CLOSE: {
      return {
        ...state,
        build: {
          ...state.build,
          modalOpen: false,
        },
      };
    }
    case BLOCKLY_STOP_BUILD: {
      return {
        ...state,
        build: {
          ...state.build,
          building: false,
          microbitForceDownload: false,
        },
      };
    }
    case BLOCKLY_BUILD_PROGRESS: {
      const { progress } = action.payload;
      return {
        ...state,
        build: {
          ...state.build,
          progress,
        },
      };
    }
    case BLOCKLY_BUILD_PORT: {
      const { port } = action.payload;
      return {
        ...state,
        build: {
          ...state.build,
          port,
        },
      };
    }
    case WEBSERIAL_CLOSE: {
      return {
        ...state,
        webSerialModal: { ...state.webSerialModal, open: false },
      };
    }
    case WEBSERIAL_CHANGE_STEP: {
      const { step } = action.payload;
      return {
        ...state,
        webSerialModal: { ...state.webSerialModal, step },
      };
    }
    case WEBSERIAL_CHANGE_STATE: {
      const { connected, error } = action.payload;
      return {
        ...state,
        webSerialModal: { ...state.webSerialModal, connected, error },
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectBlockly = (state) => state.blockly;

// actions
export const startLoading = () => (dispatch) => {
  dispatch({
    type: BLOCKLY_LOAD_START,
    payload: {},
  });
};

export const finishLoading = () => (dispatch) => {
  dispatch({
    type: BLOCKLY_LOAD_FINISH,
    payload: {},
  });
};

export const updateBlockly = (project) => (dispatch) => {
  dispatch({
    type: UPDATE_BLOCKLY,
    payload: {
      project,
    },
  });
};

export const build = (params = {}) => (dispatch) => {
  const newParams = {};
  if (typeof params.compileFromQueueId !== 'undefined') newParams.compileFromQueueId = params.compileFromQueueId;
  if (typeof params.remoteProjectId !== 'undefined') newParams.remoteProjectId = params.remoteProjectId;
  if (typeof params.port !== 'undefined') newParams.port = params.port;
  if (typeof params.microbitForceDownload !== 'undefined') newParams.microbitForceDownload = params.microbitForceDownload;
  dispatch({
    type: BLOCKLY_BUILD,
    payload: {
      params: newParams,
    },
  });
};

export const openBuildProgressModal = () => (dispatch) => {
  dispatch({
    type: BLOCKLY_BUILD_MODAL_OPEN,
    payload: {},
  });
};

export const closeBuildProgressModal = () => (dispatch) => {
  dispatch({
    type: BLOCKLY_BUILD_MODAL_CLOSE,
    payload: {},
  });
};

export const stopBuild = () => (dispatch) => {
  dispatch({
    type: BLOCKLY_STOP_BUILD,
    payload: {},
  });
};

export const setBuildProgress = (progress) => (dispatch) => {
  dispatch({
    type: BLOCKLY_BUILD_PROGRESS,
    payload: { progress },
  });
};

export const setBuildPort = (port) => (dispatch) => {
  dispatch({
    type: BLOCKLY_BUILD_PORT,
    payload: { port },
  });
};

export const webSerialModalOpen = () => (dispatch) => {
  dispatch({
    type: WEBSERIAL_OPEN,
    payload: {},
  });
};

export const webSerialModalClose = () => (dispatch) => {
  dispatch({
    type: WEBSERIAL_CLOSE,
    payload: {},
  });
};

export const webSerialModalChangeStep = (step) => (dispatch) => {
  dispatch({
    type: WEBSERIAL_CHANGE_STEP,
    payload: { step },
  });
};

export const webSerialModalChangeState = (connected, error) => (dispatch) => {
  dispatch({
    type: WEBSERIAL_CHANGE_STATE,
    payload: { connected, error },
  });
};
