import React from 'react';
import clsx from 'clsx';
import ButtonBase from './button';
import styles from './buttonIcon.mod.scss';

const ButtonIcon = ({ clasess = {}, icon = null, children, ...props }) => (
  <ButtonBase {...props}>
    <div className={clsx(styles.labelContainer)}>
      {children}
    </div>
    <div className={clsx(styles.iconContainer, clasess.iconContainer)}>
      {icon}
    </div>
  </ButtonBase>
);

export default ButtonIcon;
