/* eslint-disable max-len */
import React from 'react';

function RemoteDesktop() {
  return (
    <svg viewBox="0 0 48 48">
      <path d="M 8.5 5 C 6.019 5 4 7.019 4 9.5 L 4 30.5 C 4 32.981 6.019 35 8.5 35 L 17 35 L 17 40 L 13.5 40 C 12.671 40 12 40.672 12 41.5 C 12 42.328 12.671 43 13.5 43 L 18.253906 43 A 1.50015 1.50015 0 0 0 18.740234 43 L 24.767578 43 C 24.053578 42.088 23.452 41.084 23 40 L 20 40 L 20 35 L 22 35 C 22 33.966 22.133328 32.965 22.361328 32 L 18.746094 32 A 1.50015 1.50015 0 0 0 18.476562 31.978516 A 1.50015 1.50015 0 0 0 18.257812 32 L 8.5 32 C 7.673 32 7 31.327 7 30.5 L 7 9.5 C 7 8.673 7.673 8 8.5 8 L 39.5 8 C 40.327 8 41 8.673 41 9.5 L 41 23.474609 C 42.103 24.049609 43.111 24.778813 44 25.632812 L 44 9.5 C 44 7.019 41.981 5 39.5 5 L 8.5 5 z M 35 24 C 28.925 24 24 28.925 24 35 C 24 41.075 28.925 46 35 46 C 41.075 46 46 41.075 46 35 C 46 28.925 41.075 24 35 24 z M 39 28 C 39.25575 28 39.511531 28.097469 39.707031 28.292969 C 40.098031 28.683969 40.098031 29.316031 39.707031 29.707031 L 37.414062 32 L 39.707031 34.292969 C 40.098031 34.683969 40.098031 35.316031 39.707031 35.707031 C 39.512031 35.902031 39.256 36 39 36 C 38.744 36 38.487969 35.902031 38.292969 35.707031 L 35.292969 32.707031 C 34.901969 32.316031 34.901969 31.683969 35.292969 31.292969 L 38.292969 28.292969 C 38.488469 28.097469 38.74425 28 39 28 z M 31 34 C 31.256 34 31.512031 34.097969 31.707031 34.292969 L 34.707031 37.292969 C 35.098031 37.683969 35.098031 38.316031 34.707031 38.707031 L 31.707031 41.707031 C 31.316031 42.098031 30.683969 42.098031 30.292969 41.707031 C 29.901969 41.316031 29.901969 40.683969 30.292969 40.292969 L 32.585938 38 L 30.292969 35.707031 C 29.901969 35.316031 29.901969 34.683969 30.292969 34.292969 C 30.487969 34.097969 30.744 34 31 34 z" />
    </svg>
  );
}

export default RemoteDesktop;
