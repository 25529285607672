import React from 'react';
import clsx from 'clsx';
import styles from './menuSelect.mod.scss';

const MenuSelect = ({
  className,
  classNames = {},
  ...props }) => (
    <div className={styles.selectWrapper}>
      <select
        onChange={props.onChange || null}
        className={clsx(className, styles.container, classNames.container)}
      >
        {props.options.map((o) => (
          <option
            key={`option-${(o.value) ? o.value : o}`}
            label={(typeof o.label !== 'undefined') ? o.label : o}
            value={(typeof o.value !== 'undefined') ? o.value : o}
            selected={!!((
              typeof props.defaultValue !== 'undefined'
              && ((typeof o.value !== 'undefined' && props.defaultValue === o.value) || props.defaultValue === o)
            ))}
          />
        ))}
      </select>
      <div className={styles.arrow} />
    </div>
);

export default MenuSelect;
