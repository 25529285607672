import React from 'react';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from './appearanceSettings.mod.scss'
import { styled } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import useCreabotsTheme from '@sections/creabots/hooks/useCreabotsTheme';

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 32,
  padding: 0,
  borderRadius: '18px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#42B3FF',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#42B3FF',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
    backgroundColor: '#CBCBCB',
  },
  '& .MuiSwitch-track': {
    borderRadius: '18px',
    backgroundColor: '#FFF',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25) inset',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
  },
}));

const AppearanceSettings = () => {

  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { isDarkMode } = useCreabotsTheme()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const isDarkMode = event.target.value === 'dark';
    const body = document.body;

    (isDarkMode)
      ? body.classList.add('theme-labs-dark')
      : body.classList.remove('theme-labs-dark');
  };
  
  return (
    <div className={styles.container}>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.button}
      >
        <PaletteOutlinedIcon className={styles.icon} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        sx={{
          marginTop: '18px',
          marginLeft: '6px',
          padding: 0,
          '& .MuiMenu-list': {
            padding: 0,
            margin: 0,
          },
          '& .MuiMenu-paper': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <Box className={styles.menu}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px' }}>
              <PaletteOutlinedIcon />
              <Typography sx={{fontSize: '18px', fontWeight: 500}}>
              {intl.formatMessage({ id: 'creabots.appearanceSettings.title' })}
              </Typography>
            </Box>

            <ClearIcon onClick={handleClose} sx={{cursor: 'pointer'}} className={styles.clearIcon} />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{fontSize: '16px', fontWeight: 500}}>
            {intl.formatMessage({ id: 'creabots.appearanceSettings.mode.label' })}
            </Typography>

            <Box sx={{ minWidth: 170 }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  size='small'
                  sx={{ borderRadius: '3px', padding: '7px' }}
                  defaultValue={isDarkMode ? 'dark' : 'light'}
                  className={styles.select}
                >
                  <MenuItem value={'light'}>{intl.formatMessage({ id: 'creabots.appearanceSettings.mode.light' })}</MenuItem>
                  <MenuItem value={'dark'}>{intl.formatMessage({ id: 'creabots.appearanceSettings.mode.dark' })}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Menu>
    </div>
  )

}

export default AppearanceSettings