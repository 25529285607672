/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-useless-constructor: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-else-return: 0 */
/* eslint dot-notation: 0 */
import * as Blockly from 'blockly';
import createRenameModal from './createRenameModal';

export class CustomFieldVariable extends Blockly.FieldVariable {
  constructor(varName, validator, variableTypes, defaultType, config) {
    super(varName, validator, variableTypes, defaultType, config);
    this.SERIALIZABLE = true;
  }

  renameVariable(workspace, variable, optCallback) {
    // This function needs to be named so it can be called recursively.
    const promptAndCheckWithAlert = (defaultName) => {
      if (defaultName) {
        const existing = Blockly.Variables.nameUsedWithAnyType(defaultName, workspace);
        const existingFunction = Blockly.Procedures.getDefinition(defaultName, workspace);
        if (existing || existingFunction) {
          return { error: 'Variable exists' };
        } else {
          workspace.renameVariableById(variable.getId(), defaultName);
          if (optCallback) {
            optCallback(defaultName);
          }
          return { error: false };
        }
      } else {
        // User canceled prompt.
        if (optCallback) {
          optCallback(null);
        }
        return { error: false };
      }
    };

    createRenameModal.js(workspace, variable.name, promptAndCheckWithAlert);
  }

  onItemSelected_(menu, menuItem) {
    const id = menuItem.getValue();
    // Handle special cases.
    if (this.sourceBlock_ && !this.sourceBlock_.isDeadOrDying()) {
      if (id === 'RENAME_VARIABLE_ID') {
        // Rename variable.
        this.renameVariable(
          this.sourceBlock_.workspace,
          this.variable,
        );
        return;
      } else {
        super.onItemSelected_(menu, menuItem);
      }
    }
    // Handle unspecial case.
    this.setValue(id);
  }

  static fromJson(options) {
    const varName = Blockly.utils.parsing.replaceMessageReferences(options.variable);
    return new CustomFieldVariable(varName, undefined, undefined, undefined, options);
  }
}

Blockly.fieldRegistry.register('custom_field_variable', CustomFieldVariable);