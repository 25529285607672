/* eslint react/prop-types: 0 */

import React, { Component } from 'react';
import './TabsManager.scss';
import Tab from './Tab';
import styles from './TabsManager.mod.scss';

class TabsManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: (props.tabs) ? props.tabs : [],
      tabActive: 0,
      onChange: (props.onChange) ? props.onChange : null,
    };

    this.toggleActive = this.toggleActive.bind(this);
  }

  componentWillReceiveProps(newprops) {
    const { tabs, tabActive } = this.state;
    this.setState({
      tabs: (newprops.tabs) ? newprops.tabs : tabs,
      tabActive,
    });
  }

  toggleActive(index) {
    const { onChange } = this.state;

    this.setState({ tabActive: index });
    if (onChange) {
      onChange(index);
    }
  }

  render() {
    const { children } = this.props;
    const { tabs, tabActive } = this.state;
    return (
      <div className={styles.TabsManager}>
        <div className={styles.color}>
          <div className={`${styles.content} mb-4`}>
            {tabs.map((i, index) => (
              <div className={`${styles.TabSelection} ${(tabActive === index) ? styles.active : ''}`} onKeyPress="" onClick={() => { this.toggleActive(index); }}>
                <p>{i}</p>
              </div>
            ))}
          </div>
        </div>
        {children.map((i, index) => (
          <Tab parent={this.state} isActive={(index === tabActive)}>{i}</Tab>
        ))}
      </div>
    );
  }
}

export default TabsManager;
