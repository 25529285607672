/* eslint no-underscore-dangle: 0 */
/* eslint-disable no-await-in-loop */
/**
 * @fileoverview Class for managing client-server communication and event
 * resolution.
 * @author navil@google.com (Navil Perez)
 */

import EventEmitter from 'events';
import WorkSpaceEvents from './workSpaceEvents';
import Position from './position';
import { userRoles } from '../../../sections/collaboration/features/constants';

/**
 * Class for managing events between the workspace and the server.
 * @param {string} workspaceId The userId of the Blockly.Workspace instance this
 * client corresponds to.
 */

export default class WorkSpaceClient {
  constructor(socket, Blockly, workspace, workspaceId, projectId) {
    this.blockly = Blockly;
    this.workspace = workspace;
    this.projectId = projectId;
    this.workspaceId = workspaceId;
    this.collabEvents = new WorkSpaceEvents(socket, this.blockly, this.workspace, this.workspaceId, projectId);
    this.lastSync = 0;
    this.inProgress = [];
    this.notSent = [];
    this.activeChanges = [];
    this.writeInProgress = false;
    this.counter = 0;
    this.serverEvents = [];
    this.updateInProgress = false;
    this.listener = new EventEmitter();
    // this.workspace.clear();
    this.usersList = [];
    this.socket = socket;
  }

  setUsersList(usersList) {
    this.usersList = usersList;
  }

  /**
   * Initiate the workspace by loading the current workspace and activating the
   * handlers for sending and recieving events between the client and server.
   * @public
   */
  start() {
    return new Promise((resolve) => {
      this.socket.on('authenticated', () => {
        // this.blockly.Events.disable();
        this.getSnapshot().then(() => {
          // this.blockly.Events.enable();
          console.log('=================SNAPSHOT FROM AUTHENTICATED');
        }).catch(err => this.blockly.Events.enable());
      });

      this.socket.on('managementIsBack', (role) => {
        if (role === userRoles.LEADER) {
          setTimeout(() => {
            this.getSnapshot().then(() => {
              console.log('=================SNAPSHOT FROM LEADER IS BACK');
            }).catch(err => this.blockly.Events.enable());
          }, 2000);
        }
      });

      this.getSnapshot().then(() => {
        console.log('=================SNAPSHOT FROM START');
        resolve();
      }).catch(err => this.blockly.Events.enable());
    }).catch(err => console.log('================START SESSION ERROR', err));
  }

  getSnapshot() {
    return new Promise((resolve, reject) => {
      this.lastSync = 0;
      this.inProgress = [];
      this.notSent = [];
      this.activeChanges = [];
      this.writeInProgress = false;
      this.counter = 0;
      this.serverEvents = [];
      this.updateInProgress = false;

      this.blockly.Events.disable();
      this.collabEvents.getSnapshot().then((snapshot) => {
        if (snapshot.xml) {
          this.workspace.clear();
          const xml = this.blockly.Xml.textToDom(snapshot.xml);
          this.blockly.Xml.domToWorkspace(xml, this.workspace);
        }
        // if (snapshot.serverId) {
        //   this.lastSync = snapshot.serverId;
        // }
        this.blockly.Events.enable();
        // this.collabEvents.getEvents(this.lastSync).then((events) => {
        //   this.addServerEvents_(events);
        //   // Enable handlers.
        // });
        console.log('=================SUBSCRIBED TO BROADCAST', snapshot);
        this.collabEvents.getBroadcast(this.addServerEvents_.bind(this));
        resolve();
      }).catch(err => reject(err));
    });
  }

  /**
   * Add an event to activeChanges.
   * @param {!Object} event The Blockly.Event JSON created by the client.
   * @public
   */
  addEvent(event) {
    this.activeChanges.push(event);
  }

  /**
   * Add the events in activeChanges to notSent. Initiates process for sending
   * local changes to the database.
   * @public
   */
  flushEvents() {
    this.notSent = this.notSent.concat(this.activeChanges);
    this.activeChanges = [];
    this.updateServer_();
  }

  /**
   * Send local changes to the server. Continuously runs until all local changes
   * have been sent.
   * @private
   */
  async updateServer_() {
    // console.log('=======updateServer_writeInProgress_notSent', this.writeInProgress, this.notSent);

    if (this.writeInProgress || this.notSent.length === 0) {
      return;
    }
    this.writeInProgress = true;
    while (this.notSent.length > 0) {
      await this.writeToDatabase_();
    }
    this.writeInProgress = false;
  }

  /**
   * Trigger an API call to write events to the database.
   * @throws Throws an error if the write was not successful.
   * @private
   */
  async writeToDatabase_() {
    this.beginWrite_();
    try {
      await this.collabEvents.writeEvents(this.inProgress[this.inProgress.length - 1]);
      this.endWrite_(true);
    } catch {
      this.endWrite_(false);
      throw Error('Failed to write to database.');
    }
  }


  /**
   * Change status of WorkspaceClient in preparation for the network call.
   * Set writeInProgress to true, adds a LocalEntry to inProgress based on
   * the events that were notSent, and clears the notSent array.
   * @private
   */
  beginWrite_() {
    // console.log('=========== server events sending', this.counter, this.lastSync, this.blockly.Events.filter(this.notSent, true));
    this.writeInProgress = true;
    this.inProgress.push({
      workspaceId: this.workspaceId,
      entryNumber: this.counter,
      events: this.blockly.Events.filter(this.notSent, true),
    });
    this.counter += 1;
    this.notSent = [];
  }

  /**
   * Change status of WorkspaceClient once network call completes.
   * Change writeInProgress to true. If write was successful, the LocalEntry
   * remains in inProgress, otherwise, the LocalEntry is deleted and the
   * events move back to the front of notSent.
   * @param {boolean} success Indicates the success of the database write.
   * @private
   */
  endWrite_(success) {
    if (!success) {
      this.notSent = this.inProgress[0].events.concat(this.notSent);
      this.inProgress = [];
      this.counter -= 1;
    }
    this.writeInProgress = false;
  }

  /**
   * Periodically query the database for new server events and add them to
   * serverEvents.
   * @private
   */
  async pollServer_() {
    const entries = await this.queryDatabase_();
    await this.addServerEvents_(entries);
    setTimeout(() => { this.pollServer_(); }, 5000);
  }

  /**
   * Trigger an API call to query events from the database.
   * @returns {<!Array.<!Entry>>} The result of the query.
   * @public
   */
  async queryDatabase_() {
    try {
      return await this.collabEvents.getEvents(this.lastSync);
    } catch {
      return [];
    }
  }

  /**
   * Add newServerEvents to the end of this.serverEvents and initiate process of
   * applying server events to the workspace if the newServerEvents are recieved
   * in the correct order.
   * @param {<!Array.<!Entry>>} newServerEvents The events recieved from the
   * server.
   * @throws Throws an error if newServerEvents are not recieved in the correct
   * order.
   * @private
   */
  async addServerEvents_(newServerEventsParam) {
    const newServerEvents = newServerEventsParam;
    if (newServerEvents.length === 0) {
      return;
    }
    // console.log('=========== server events received', newServerEvents, this.lastSync);
    // if (newServerEvents[0].serverId !== this.lastSync + 1) {
    //   newServerEvents = await this.queryDatabase_();
    // }
    if (newServerEvents.length > 0) {
      // const blockKeys = Object.keys(this.workspace.blockDB_);
      // // console.log('=============this.workspace', this.workspace.blockDB_, blockKeys);
      // blockKeys.forEach((id) => {
      //   console.log('=============this.workspace', id, this.workspace.blockDB_[id].type, newServerEvents);
      // });
      this.lastSync = newServerEvents[newServerEvents.length - 1].serverId;
      this.serverEvents.push(...newServerEvents);
      this.updateWorkspace_();
    }
  }

  /**
   * Send server events to the local workspace. Continuously runs until all
   * server events have been sent.
   * @private
   */
  async updateWorkspace_() {
    if (this.updateInProgress || this.serverEvents === 0) {
      return;
    }
    this.updateInProgress = true;
    while (this.serverEvents.length > 0) {
      const newServerEvents = this.serverEvents;
      this.serverEvents = [];
      const eventQueue = await this.processQueryResults_(newServerEvents);
      this.updateInProgress = false;
      // this.listener.emit('runEvents', eventQueue);

      eventQueue.forEach((event) => {
        // if (this.workspaceId !== event.event.position.id) {
        //   this.blockly.Events.fire(this.blockly.Events.fromJson(event.event, this.workspace));
        // }
        this.blockly.Events.disable();
        // if (event.event.type !== this.blockly.Events.END_DRAG) {
        //   this.workspace.undoStack_.push(event.event);
        // }
        if (!event.event.xml?.outerHTML?.includes('initial_block')) {
          event.event.run(event.forward);
        }
        this.blockly.Events.enable();
        this.updateMarkerPositions_(event.event.position);
        // console.log('============== UNDO', this.workspaceId, event.event.xml?.outerHTML || '', this.workspace.undoStack_);
      });
      this.workspace.fireChangeListener('resize');
    }
  }

  updateMarkerPositions_(positionUpdate) {
    // console.log('============== RUNEVENT updateMarkerPositions_', positionUpdate);
    if (positionUpdate && positionUpdate.position && positionUpdate.id && positionUpdate.id !== this.workspaceId) {
      const color = this.usersList.filter(u => u.User.id === positionUpdate.id)[0]?.color || '#FFF200';
      this.workspace.markBlock(positionUpdate.id, positionUpdate.position.blockId, true, color);
    }
  }

  handleUnmarkPosition(event) {
    const position = Position.fromEvent(event, false);
    // console.log('============== UNMARK POSITION', position, event);
    if (position) {
      this.workspace.markBlock(this.workspaceId, position.blockId, false);
    }
  }

  /**
   * Compare the order of events in the entries retrieved from the database to
   * the stacks of local-only changes and provide a series of steps that
   * will allow the server and local workspace to converge.
   * @param {<!Array.<!Entry>>} entries Entries retrieved from the database.
   * @returns {!Array.<!WorkspaceAction>>} eventQueue An array of events and the
   * direction they should be run.
   * @private
   */

  processQueryResults_(entries) {
    const eventQueue = [];

    if (entries.length === 0) {
      return eventQueue;
    }

    // console.log('////////////// process results', entries, this.notSent, this.inProgress);
    this.lastSync = entries[entries.length - 1].serverId;

    // No local changes.
    if (this.notSent.length === 0 && this.inProgress.length === 0) {
      entries.forEach((entry) => {
        eventQueue.push(...this.createWorkspaceActions_(entry.events, true));
      });
      // console.log('////////////// process results ENTRIES', eventQueue, this.notSent, this.inProgress);
      return eventQueue;
    }

    // console.log('////////////// process results ENTRIES ZERO', entries[0], this.workspaceId, this.inProgress[0].entryNumber);
    // Common root, remove common events from server events.
    if (this.inProgress.length > 0
      && entries[0].workspaceId === this.workspaceId
      && entries[0].entryNumber === this.inProgress[0].entryNumber) {
      entries.shift();
      this.inProgress = [];
    }

    if (entries.length > 0) {
      // Undo local events.
      eventQueue.push(...this.createWorkspaceActions_(this.notSent.slice().reverse(), false));

      if (this.inProgress.length > 0) {
        this.inProgress.slice().reverse().forEach((entry) => {
          eventQueue.push(...this.createWorkspaceActions_(entry.events.slice().reverse(), false));
        });
      }
      // console.log('////////////// process results UNDO', eventQueue, this.notSent, this.inProgress);
      // Apply server events.
      entries.forEach((entry) => {
        if (this.inProgress.length > 0
          && entry.workspaceId === this.inProgress[0].workspaceId
          && entry.entryNumber === this.inProgress[0].entryNumber) {
          eventQueue.push(...this.createWorkspaceActions_(this.inProgress[0].events, true));
          this.inProgress.shift();
        } else {
          eventQueue.push(...this.createWorkspaceActions_(entry.events, true));
        }
      });
      // console.log('////////////// process results APPLY', eventQueue, this.notSent, this.inProgress);
      // Reapply remaining local changes.
      if (this.inProgress.length > 0) {
        eventQueue.push(...this.createWorkspaceActions_(this.inProgress[0].events, true));
      }
      // console.log('////////////// process results ReAPPLY', eventQueue, this.notSent, this.inProgress);
      eventQueue.push(...this.createWorkspaceActions_(this.notSent, true));
    }
    return eventQueue;
  }

  /**
   * Create WorkspaceActions from a list of events.
   * @param {<!Array.<!Object>>} events An array of Blockly Events in JSON format.
   * @param {boolean} forward Indicates the direction to run an event.
   * @returns {<Array.<!WorkspaceEvent>>} An array of actions to be performed
   * on the workspace.
   * @private
   */
  createWorkspaceActions_(events, forward) {
    const eventQueue = [];
    events.forEach((event) => {
      eventQueue.push({
        event,
        forward,
      });
    });
    return eventQueue;
  }
}
