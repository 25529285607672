import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBoards, selectBoards } from '@modules/boards/features/boardsSlice';
import { isElectron } from '@utils';
import { SUCCESS_STATUS } from '@utils/redux';
import BoardList from './boardList';
import BoardNav from './boardNav';
import styles from './createProject.mod.scss';

function CreateProject({ showProjectScrollbar, onBoardItemClick }) {
  const offline = isElectron();
  const boards = useSelector(selectBoards);
  const dispatch = useDispatch();
  const [displayPageBack, setDisplayPageBack] = useState(false);

  useEffect(() => {
    showProjectScrollbar();
    if (boards.status === SUCCESS_STATUS) return;
    dispatch(getAllBoards(offline));
  }, []);

  const showPageBack = () => {
    setDisplayPageBack(true);
  };

  const hidePageBack = () => {
    setDisplayPageBack(false);
  };

  return (
    <div className={styles.container}>
      <BoardNav
        hidePageBack={hidePageBack}
        displayPageBack={displayPageBack}
      />
      <BoardList
        showPageBack={showPageBack}
        displayPageBack={displayPageBack}
        onBoardItemClick={onBoardItemClick}
      />
    </div>
  );
}

export default CreateProject;
