/* eslint lines-between-class-members: 0 */
/* eslint no-param-reassign: 0 */
/* eslint camelcase: 0 */
export const COLOR_ADDR = 0x29;
export const TCS34725_COMMAND_BIT = 0x80;
export const TCS34725_ID = TCS34725_COMMAND_BIT | 0x12;
export const TCS34725_ACK = 0x44;
export const TCS34725_CDATAL = TCS34725_COMMAND_BIT | 0x14; // Clear channel data
export const TCS34725_RDATAL = TCS34725_COMMAND_BIT | 0x16; // Red channel data
export const TCS34725_GDATAL = TCS34725_COMMAND_BIT | 0x18; // Green channel data
export const TCS34725_BDATAL = TCS34725_COMMAND_BIT | 0x1A; // Blue channel data

export class ColorController {
  dataResponse = 0x00;
  c = 1;
  r = 0;
  g = 0;
  b = 0;

  update(c, r, g, b) {
    this.c = c;
    this.r = (r / 256) * c * 10;
    this.g = (g / 256) * c * 10;
    this.b = (b / 256) * c * 10;
    // console.log('===================', this.r, this.g, this.b);

    return false;
  }

  i2cConnect() {
    return true;
  }

  i2cDisconnect() { }

  i2cReadByte(value) {
    // console.log('======================readbyte', value);
    return this.dataRegister;
  }

  i2cWriteByte(value) {
    if (value === TCS34725_ID) {
      this.dataRegister = TCS34725_ACK;
    } else if (value === TCS34725_CDATAL) {
      this.dataRegister = this.c;
      // console.log('======================clear', value, value.toString(2), this.c.toString(2));
    } else if (value === TCS34725_RDATAL) {
      this.dataRegister = this.r;
      // console.log('======================red', value, value.toString(2), this.r.toString(2));
    } else if (value === TCS34725_GDATAL) {
      this.dataRegister = this.g;
      // console.log('======================green', value, value.toString(2), this.g.toString(2));
    } else if (value === TCS34725_BDATAL) {
      this.dataRegister = this.b;
      // console.log('======================blue', value, value.toString(2), this.b.toString(2));
    } else {
      this.dataRegister = 0x00;
    }

    return true;
  }
}
