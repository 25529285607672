import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './tutorial1Step10.mod.scss';
import { useIntl } from 'react-intl';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

const Tutorial1Step10 = ({ currentStep, totalSteps }) => {
  const intl = useIntl();

  const handleBack = () => {
    window.location = "/kits"
  };

  return (
    <div className={styles.tutorial1Step10}>
      <div className={styles.titleContainer}>
        <div className={styles.title}><AssignmentOutlinedIcon /> {intl.formatMessage({ id: 'creabots.tutorialTitle' })}</div>
        <div className={styles.progress}><strong>{currentStep + 1}</strong>/{totalSteps}</div>
      </div>
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'creabots.tutorials.initiation.projects.firstSteps.step10' }) }} />
      <div className={styles.buttons}>
        <button type="button" onClick={handleBack} className={clsx('btn', 'btn-primary', 'main-btn', styles.primaryButton)}>
          {intl.formatMessage({ id: 'creabots.tutorials.initiation.projects.firstSteps.continue' })}
        </button>
      </div>
    </div>
  );
};


export default Tutorial1Step10;