import React, { useState } from 'react';
import styles from './userAvatar.mod.scss';


const UserAvatar = React.memo((props) => {
  const offLineColor = '#D1D1D1';

  return (props.avatarUrl) ? (
    <img
      data-tip={`${props.firstName} ${props.lastName}`}
      alt={`${props.firstName} ${props.lastName}`}
      style={{border: `2.5px solid ${(props.onLine) ? props.color : offLineColor}`}}
      key={`user-${props.id}`}
      className={`${styles.avatar} ${(props.style) ? props.style : ''}`}
      src={props.avatarUrl}
    />
  ) : (
    <div className={`${styles.avatarBackground} ${(props.style) ? props.style : ''}`} data-tip={`${props.firstName} ${props.lastName}`}>
      <div
        className={styles.avatarChar}
        key={`user-${props.id}`}
        style={{border: `2.5px solid ${(props.onLine) ? props.color : offLineColor}`, backgroundColor: `${(props.onLine) ? props.color : offLineColor}A6`}}
      >
        {`${props.firstName.substring(0, 1)}${props.lastName.substring(0, 1)}`}
      </div>
    </div>
  );
});

export default UserAvatar;