import React, { Component } from 'react';
import './buzzer.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class BuzzerElement extends Component {
  constructor(props) {
    super(props);
    this.buzzerOn = props.buzzerOn || false;
    this.state = {
      // buzzerOn: props.buzzerOn || false,
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.buzzerOn = newProps.buzzerOn;
    // this.setState({ buzzerOn: newProps.buzzerOn });
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    return (
      <div className="buzzer" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 143.05 56.97"
          width="15mm"
          height="9mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >

          <g id="capa-buzzer" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g id="LINE">
              <path class="cls-4" d="m138.67.14H63.83c-.78,0-1.42.63-1.42,1.42v2.81s0,0,0,0c0,2.5-2.03,4.53-4.54,4.53s-4.53-2.03-4.53-4.54V1.58c-.01-.8-.66-1.45-1.46-1.45H5.45c-2.35,0-4.25,1.9-4.25,4.25,0,0,0,0,0,0v48.19c0,2.35,1.9,4.25,4.25,4.25h46.45c.8,0,1.45-.64,1.45-1.44,0,0,0,0,0,0v-2.78c0-2.5,2.03-4.53,4.54-4.54s4.54,2.03,4.54,4.54v2.81c0,.78.63,1.42,1.41,1.42h74.84c2.35,0,4.25-1.89,4.25-4.24,0,0,0,0,0,0V4.39c0-2.35-1.9-4.25-4.25-4.25" />
            </g>
            <g id="CIRCLE">
              <path class="cls-5" d="m57.92,34.2c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
            </g>
            <g id="LINE-2" data-name="LINE">
              <rect class="cls-8" x="1.5" y="11.08" width="42.52" height="34.58" />
            </g>
            <g>
              <g id="LINE-3" data-name="LINE">
                <path class="cls-15" d="m31.06,4.19h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04" />
              </g>
              <rect class="cls-14" x="27.66" y="3.99" width="2.27" height="2.67" transform="translate(23.47 34.11) rotate(-90)" />
            </g>
            <g>
              <rect class="cls-11" x="72.07" y="40.26" width="4.54" height="2.27" />
              <rect class="cls-6" x="72.92" y="40.26" width="2.83" height="2.27" />
            </g>
            <g>
              <rect class="cls-11" x="72.07" y="21.4" width="4.54" height="2.27" />
              <rect class="cls-6" x="72.92" y="21.4" width="2.83" height="2.27" />
            </g>
            <g>
              <g id="LINE-4" data-name="LINE">
                <rect class="cls-6" x="72.35" y="28.33" width="3.7" height="8.23" />
              </g>
              <g id="LINE-5" data-name="LINE">
                <polyline class="cls-12" points="76.05 34.5 76.21 34.5 76.77 34.5 76.84 34.5 76.98 34.5 77.61 34.5 77.61 35.77 76.98 35.77 76.84 35.77 76.77 35.77 76.21 35.77 76.05 35.77" />
              </g>
              <g id="LINE-6" data-name="LINE">
                <polyline class="cls-12" points="76.05 30.39 76.21 30.39 76.77 30.39 76.84 30.39 76.98 30.39 77.61 30.39 77.61 29.11 76.98 29.11 76.84 29.11 76.77 29.11 76.21 29.11 76.05 29.11" />
              </g>
              <g id="LINE-7" data-name="LINE">
                <polyline class="cls-12" points="72.35 33.08 72.19 33.08 71.63 33.08 71.57 33.08 71.43 33.08 70.8 33.08 70.8 31.81 71.43 31.81 71.57 31.81 71.63 31.81 72.19 31.81 72.35 31.81" />
              </g>
            </g>
            <g>
              <g id="LINE-8" data-name="LINE">
                <polyline class="cls-13" points="71.79 15.84 71.79 14.42 76.89 14.42 76.89 15.84 76.89 16.83 76.89 18.25 71.79 18.25 71.79 16.83" />
              </g>
              <g id="LINE-9" data-name="LINE">
                <path class="cls-11" d="m70.57,15.84v.99h.18s.03,0,.05,0h.04s.02,0,.03,0h.02s.3,0,.3,0h.08s.08,0,.14,0h.18c.07,0,.14,0,.21,0v-.99h-.21c-.07,0-.13,0-.18,0h-.14s-.06,0-.08,0h-.3s0,0-.02,0h-.03s-.03,0-.04,0h-.05s-.18,0-.18,0Z" />
              </g>
              <g id="LINE-10" data-name="LINE">
                <path class="cls-11" d="m78.11,16.83v-.99h-.18s-.03,0-.05,0h-.04s-.02,0-.03,0h-.02s-.3,0-.3,0h-.08s-.08,0-.14,0h-.18c-.07,0-.14,0-.21,0v.99h.21c.07,0,.13,0,.18,0h.14s.06,0,.08,0h.3s0,0,.02,0h.03s.03,0,.04,0h.05s.18,0,.18,0Z" />
              </g>
            </g>
            <g id="ARC">
              <circle class="cls-1" cx="105.68" cy="28.49" r="16.87" />
            </g>
            <g id="ARC-2" data-name="ARC">
              <circle class="cls-3" cx="105.68" cy="28.49" r="3.26" />
            </g>
            <g>
              <polygon class="cls-9" points="53.93 .02 53.93 5.18 53.93 5.51 54.06 6.15 54.33 6.76 54.71 7.31 54.96 7.55 55.18 7.76 55.72 8.11 56.32 8.35 56.96 8.47 57.28 8.47 57.33 8.47 57.67 8.46 58.33 8.34 58.95 8.09 59.5 7.73 59.74 7.51 59.97 7.28 60.34 6.76 60.59 6.17 60.72 5.55 60.72 5.23 60.72 .02 67.18 .02 67.18 15.77 62.92 19.85 51.67 19.85 47.41 15.77 47.41 .02 53.93 .02" />
              <polygon class="cls-9" points="51.79 37.14 63.03 37.14 67.3 41.22 67.3 56.96 60.77 56.96 60.77 51.8 60.77 51.48 60.64 50.83 60.37 50.22 59.99 49.67 59.75 49.44 59.52 49.22 58.98 48.87 58.38 48.64 57.75 48.52 57.42 48.52 57.37 48.52 57.37 48.52 57.03 48.52 56.38 48.64 55.76 48.89 55.2 49.25 54.96 49.48 54.96 49.48 54.73 49.7 54.36 50.23 54.11 50.81 53.98 51.43 53.98 51.75 53.98 56.96 47.52 56.96 47.52 41.22 51.79 37.14" />
              <line class="cls-7" x1="32.01" y1="54.9" x2="32.01" y2="54.02" />
              <line class="cls-7" x1="32.72" y1="54.9" x2="32.72" y2="54.02" />
              <line class="cls-7" x1="32.65" y1="54.9" x2="32.65" y2="54.02" />
              <line class="cls-7" x1="32.58" y1="54.9" x2="32.58" y2="54.02" />
              <line class="cls-7" x1="32.51" y1="54.9" x2="32.51" y2="54.02" />
              <line class="cls-7" x1="32.43" y1="54.9" x2="32.43" y2="54.02" />
              <line class="cls-7" x1="32.36" y1="54.9" x2="32.36" y2="54.02" />
              <line class="cls-7" x1="32.29" y1="54.9" x2="32.29" y2="54.02" />
              <line class="cls-7" x1="32.22" y1="54.9" x2="32.22" y2="54.02" />
              <line class="cls-7" x1="32.15" y1="54.9" x2="32.15" y2="54.02" />
              <line class="cls-7" x1="32.08" y1="54.9" x2="32.08" y2="54.02" />
              <line class="cls-7" x1="31.94" y1="54.9" x2="31.94" y2="54.02" />
              <line class="cls-7" x1="33.64" y1="54.02" x2="33.64" y2="50.64" />
              <line class="cls-7" x1="33.57" y1="54.02" x2="33.57" y2="50.64" />
              <line class="cls-7" x1="33.5" y1="54.02" x2="33.5" y2="50.64" />
              <line class="cls-7" x1="33.43" y1="54.02" x2="33.43" y2="50.64" />
              <line class="cls-7" x1="33.35" y1="54.02" x2="33.35" y2="50.64" />
              <line class="cls-7" x1="33.28" y1="54.02" x2="33.28" y2="50.64" />
              <line class="cls-7" x1="33.21" y1="54.02" x2="33.21" y2="50.64" />
              <line class="cls-7" x1="33.14" y1="54.02" x2="33.14" y2="50.64" />
              <line class="cls-7" x1="33.07" y1="54.02" x2="33.07" y2="50.64" />
              <line class="cls-7" x1="33" y1="54.02" x2="33" y2="50.64" />
              <line class="cls-7" x1="32.93" y1="54.02" x2="32.93" y2="50.64" />
              <line class="cls-7" x1="32.86" y1="54.02" x2="32.86" y2="50.64" />
              <line class="cls-7" x1="25.36" y1="53.21" x2="25.36" y2="51.45" />
              <line class="cls-7" x1="25.29" y1="53.21" x2="25.29" y2="51.45" />
              <line class="cls-7" x1="25.22" y1="53.21" x2="25.22" y2="51.45" />
              <line class="cls-7" x1="25.15" y1="53.21" x2="25.15" y2="51.45" />
              <line class="cls-7" x1="25.08" y1="53.21" x2="25.08" y2="51.45" />
              <line class="cls-7" x1="25" y1="53.21" x2="25" y2="51.45" />
              <line class="cls-7" x1="24.93" y1="53.21" x2="24.93" y2="51.45" />
              <line class="cls-7" x1="24.86" y1="53.21" x2="24.86" y2="51.45" />
              <line class="cls-7" x1="24.79" y1="53.21" x2="24.79" y2="51.45" />
              <line class="cls-7" x1="24.72" y1="53.21" x2="24.72" y2="51.45" />
              <line class="cls-7" x1="24.65" y1="53.21" x2="24.65" y2="51.45" />
              <line class="cls-7" x1="24.58" y1="53.21" x2="24.58" y2="51.45" />
              <line class="cls-7" x1="24.51" y1="53.21" x2="24.51" y2="51.45" />
              <line class="cls-7" x1="26.35" y1="54.02" x2="26.35" y2="50.64" />
              <line class="cls-7" x1="26.49" y1="54.02" x2="26.49" y2="50.64" />
              <line class="cls-7" x1="26.42" y1="54.02" x2="26.42" y2="50.64" />
              <line class="cls-7" x1="26.28" y1="54.02" x2="26.28" y2="50.64" />
              <line class="cls-7" x1="26.21" y1="54.02" x2="26.21" y2="50.64" />
              <line class="cls-7" x1="26.14" y1="54.02" x2="26.14" y2="50.64" />
              <line class="cls-7" x1="26.07" y1="54.02" x2="26.07" y2="50.64" />
              <line class="cls-7" x1="26" y1="54.02" x2="26" y2="50.64" />
              <line class="cls-7" x1="25.92" y1="54.02" x2="25.92" y2="50.64" />
              <line class="cls-7" x1="25.85" y1="54.02" x2="25.85" y2="50.64" />
              <line class="cls-7" x1="25.78" y1="54.02" x2="25.78" y2="50.64" />
              <line class="cls-7" x1="25.71" y1="54.02" x2="25.71" y2="50.64" />
              <line class="cls-7" x1="25.64" y1="53.21" x2="25.64" y2="51.45" />
              <line class="cls-7" x1="25.57" y1="53.21" x2="25.57" y2="51.45" />
              <line class="cls-7" x1="25.5" y1="53.21" x2="25.5" y2="51.45" />
              <line class="cls-7" x1="25.43" y1="53.21" x2="25.43" y2="51.45" />
              <line class="cls-7" x1="24.37" y1="53.21" x2="24.37" y2="51.45" />
              <line class="cls-7" x1="24.3" y1="53.21" x2="24.3" y2="51.45" />
              <line class="cls-7" x1="24.23" y1="53.21" x2="24.23" y2="51.45" />
              <line class="cls-7" x1="24.16" y1="53.21" x2="24.16" y2="51.45" />
              <line class="cls-7" x1="24.08" y1="53.21" x2="24.08" y2="51.45" />
              <line class="cls-7" x1="24.01" y1="53.21" x2="24.01" y2="51.45" />
              <line class="cls-7" x1="23.94" y1="53.21" x2="23.94" y2="51.45" />
              <line class="cls-7" x1="31.02" y1="49.82" x2="31.02" y2="48.95" />
              <line class="cls-7" x1="30.95" y1="49.82" x2="30.95" y2="48.95" />
              <line class="cls-7" x1="30.88" y1="49.82" x2="30.88" y2="48.95" />
              <line class="cls-7" x1="30.81" y1="49.82" x2="30.81" y2="48.95" />
              <line class="cls-7" x1="30.74" y1="49.82" x2="30.74" y2="48.95" />
              <line class="cls-7" x1="30.67" y1="49.82" x2="30.67" y2="48.95" />
              <line class="cls-7" x1="30.59" y1="49.82" x2="30.59" y2="48.95" />
              <line class="cls-7" x1="30.52" y1="49.82" x2="30.52" y2="48.95" />
              <line class="cls-7" x1="30.45" y1="49.82" x2="30.45" y2="48.95" />
              <line class="cls-7" x1="30.38" y1="49.82" x2="30.38" y2="48.95" />
              <line class="cls-7" x1="30.31" y1="49.82" x2="30.31" y2="48.95" />
              <line class="cls-7" x1="30.24" y1="49.82" x2="30.24" y2="48.95" />
              <line class="cls-7" x1="30.17" y1="49.82" x2="30.17" y2="48.95" />
              <line class="cls-7" x1="31.8" y1="49.82" x2="31.8" y2="48.95" />
              <line class="cls-7" x1="31.73" y1="49.82" x2="31.73" y2="48.95" />
              <line class="cls-7" x1="31.66" y1="49.82" x2="31.66" y2="48.95" />
              <line class="cls-7" x1="31.59" y1="49.82" x2="31.59" y2="48.95" />
              <line class="cls-7" x1="31.51" y1="49.82" x2="31.51" y2="48.95" />
              <line class="cls-7" x1="31.44" y1="49.82" x2="31.44" y2="48.95" />
              <line class="cls-7" x1="31.37" y1="49.82" x2="31.37" y2="48.95" />
              <line class="cls-7" x1="31.3" y1="49.82" x2="31.3" y2="48.95" />
              <line class="cls-7" x1="31.23" y1="49.82" x2="31.23" y2="48.95" />
              <line class="cls-7" x1="31.16" y1="49.82" x2="31.16" y2="48.95" />
              <line class="cls-7" x1="31.09" y1="49.82" x2="31.09" y2="48.95" />
              <line class="cls-7" x1="30.03" y1="49.82" x2="30.03" y2="48.95" />
              <line class="cls-7" x1="29.96" y1="49.82" x2="29.96" y2="48.95" />
              <line class="cls-7" x1="29.89" y1="49.82" x2="29.89" y2="48.95" />
              <line class="cls-7" x1="29.82" y1="49.82" x2="29.82" y2="48.95" />
              <line class="cls-7" x1="29.75" y1="49.82" x2="29.75" y2="48.95" />
              <line class="cls-7" x1="29.67" y1="49.82" x2="29.67" y2="48.95" />
              <line class="cls-7" x1="29.6" y1="49.82" x2="29.6" y2="48.95" />
              <line class="cls-7" x1="29.53" y1="49.82" x2="29.53" y2="48.95" />
              <line class="cls-7" x1="29.46" y1="49.82" x2="29.46" y2="48.95" />
              <line class="cls-7" x1="29.39" y1="49.82" x2="29.39" y2="48.95" />
              <line class="cls-7" x1="29.32" y1="49.82" x2="29.32" y2="48.95" />
              <line class="cls-7" x1="24.44" y1="53.21" x2="24.44" y2="51.45" />
              <line class="cls-7" x1="30.1" y1="49.82" x2="30.1" y2="48.95" />
              <line class="cls-7" x1="31.02" y1="55.71" x2="31.02" y2="54.83" />
              <line class="cls-7" x1="30.95" y1="55.71" x2="30.95" y2="54.83" />
              <line class="cls-7" x1="30.88" y1="55.71" x2="30.88" y2="54.83" />
              <line class="cls-7" x1="30.81" y1="55.71" x2="30.81" y2="54.83" />
              <line class="cls-7" x1="30.74" y1="55.71" x2="30.74" y2="54.83" />
              <line class="cls-7" x1="30.67" y1="55.71" x2="30.67" y2="54.83" />
              <line class="cls-7" x1="30.59" y1="55.71" x2="30.59" y2="54.83" />
              <line class="cls-7" x1="30.52" y1="55.71" x2="30.52" y2="54.83" />
              <line class="cls-7" x1="30.45" y1="55.71" x2="30.45" y2="54.83" />
              <line class="cls-7" x1="30.38" y1="55.71" x2="30.38" y2="54.83" />
              <line class="cls-7" x1="30.31" y1="55.71" x2="30.31" y2="54.83" />
              <line class="cls-7" x1="30.24" y1="55.71" x2="30.24" y2="54.83" />
              <line class="cls-7" x1="30.17" y1="55.71" x2="30.17" y2="54.83" />
              <line class="cls-7" x1="31.87" y1="55.71" x2="31.87" y2="54.02" />
              <line class="cls-7" x1="31.8" y1="55.71" x2="31.8" y2="54.83" />
              <line class="cls-7" x1="31.73" y1="55.71" x2="31.73" y2="54.83" />
              <line class="cls-7" x1="31.66" y1="55.71" x2="31.66" y2="54.83" />
              <line class="cls-7" x1="31.59" y1="55.71" x2="31.59" y2="54.83" />
              <line class="cls-7" x1="31.51" y1="55.71" x2="31.51" y2="54.83" />
              <line class="cls-7" x1="31.44" y1="55.71" x2="31.44" y2="54.83" />
              <line class="cls-7" x1="31.37" y1="55.71" x2="31.37" y2="54.83" />
              <line class="cls-7" x1="31.3" y1="55.71" x2="31.3" y2="54.83" />
              <line class="cls-7" x1="31.23" y1="55.71" x2="31.23" y2="54.83" />
              <line class="cls-7" x1="31.16" y1="55.71" x2="31.16" y2="54.83" />
              <line class="cls-7" x1="31.09" y1="55.71" x2="31.09" y2="54.83" />
              <line class="cls-7" x1="30.03" y1="55.71" x2="30.03" y2="54.83" />
              <line class="cls-7" x1="29.96" y1="55.71" x2="29.96" y2="54.83" />
              <line class="cls-7" x1="29.89" y1="55.71" x2="29.89" y2="54.83" />
              <line class="cls-7" x1="29.82" y1="55.71" x2="29.82" y2="54.83" />
              <line class="cls-7" x1="29.75" y1="55.71" x2="29.75" y2="54.83" />
              <line class="cls-7" x1="29.67" y1="55.71" x2="29.67" y2="54.83" />
              <line class="cls-7" x1="29.6" y1="55.71" x2="29.6" y2="54.83" />
              <line class="cls-7" x1="29.53" y1="55.71" x2="29.53" y2="54.83" />
              <line class="cls-7" x1="29.46" y1="55.71" x2="29.46" y2="54.83" />
              <line class="cls-7" x1="29.39" y1="55.71" x2="29.39" y2="54.83" />
              <line class="cls-7" x1="29.32" y1="55.71" x2="29.32" y2="54.83" />
              <line class="cls-7" x1="32.01" y1="50.64" x2="32.01" y2="49.76" />
              <line class="cls-7" x1="32.72" y1="50.64" x2="32.72" y2="49.76" />
              <line class="cls-7" x1="32.65" y1="50.64" x2="32.65" y2="49.76" />
              <line class="cls-7" x1="32.58" y1="50.64" x2="32.58" y2="49.76" />
              <line class="cls-7" x1="32.51" y1="50.64" x2="32.51" y2="49.76" />
              <line class="cls-7" x1="32.43" y1="50.64" x2="32.43" y2="49.76" />
              <line class="cls-7" x1="32.36" y1="50.64" x2="32.36" y2="49.76" />
              <line class="cls-7" x1="32.29" y1="50.64" x2="32.29" y2="49.76" />
              <line class="cls-7" x1="32.22" y1="50.64" x2="32.22" y2="49.76" />
              <line class="cls-7" x1="32.15" y1="50.64" x2="32.15" y2="49.76" />
              <line class="cls-7" x1="32.08" y1="50.64" x2="32.08" y2="49.76" />
              <line class="cls-7" x1="31.94" y1="50.64" x2="31.94" y2="49.76" />
              <line class="cls-7" x1="31.87" y1="50.64" x2="31.87" y2="48.95" />
              <line class="cls-7" x1="30.1" y1="55.71" x2="30.1" y2="54.83" />
              <line class="cls-7" x1="32.79" y1="54.9" x2="32.79" y2="49.76" />
              <line class="cls-7" x1="29.18" y1="55.71" x2="29.18" y2="48.95" />
              <line class="cls-7" x1="29.11" y1="55.71" x2="29.11" y2="48.95" />
              <line class="cls-7" x1="29.04" y1="55.71" x2="29.04" y2="48.95" />
              <line class="cls-7" x1="28.97" y1="55.71" x2="28.97" y2="48.95" />
              <line class="cls-7" x1="28.9" y1="55.71" x2="28.9" y2="48.95" />
              <line class="cls-7" x1="28.83" y1="55.71" x2="28.83" y2="48.95" />
              <line class="cls-7" x1="28.76" y1="55.71" x2="28.76" y2="48.95" />
              <line class="cls-7" x1="28.68" y1="55.71" x2="28.68" y2="48.95" />
              <line class="cls-7" x1="28.54" y1="55.71" x2="28.54" y2="48.95" />
              <line class="cls-7" x1="28.47" y1="55.71" x2="28.47" y2="48.95" />
              <line class="cls-7" x1="28.4" y1="55.71" x2="28.4" y2="48.95" />
              <line class="cls-7" x1="28.33" y1="55.71" x2="28.33" y2="48.95" />
              <line class="cls-7" x1="28.61" y1="55.71" x2="28.61" y2="48.95" />
              <line class="cls-7" x1="28.26" y1="54.9" x2="28.26" y2="49.76" />
              <line class="cls-7" x1="28.19" y1="54.9" x2="28.19" y2="49.76" />
              <line class="cls-7" x1="28.12" y1="54.9" x2="28.12" y2="49.76" />
              <line class="cls-7" x1="28.05" y1="54.9" x2="28.05" y2="49.76" />
              <line class="cls-7" x1="27.98" y1="54.9" x2="27.98" y2="49.76" />
              <line class="cls-7" x1="27.91" y1="54.9" x2="27.91" y2="49.76" />
              <line class="cls-7" x1="27.84" y1="54.9" x2="27.84" y2="49.76" />
              <line class="cls-7" x1="27.76" y1="54.9" x2="27.76" y2="49.76" />
              <line class="cls-7" x1="27.69" y1="54.9" x2="27.69" y2="49.76" />
              <line class="cls-7" x1="27.62" y1="54.9" x2="27.62" y2="49.76" />
              <line class="cls-7" x1="27.55" y1="54.9" x2="27.55" y2="49.76" />
              <line class="cls-7" x1="27.48" y1="54.9" x2="27.48" y2="49.76" />
              <line class="cls-7" x1="27.41" y1="54.9" x2="27.41" y2="49.76" />
              <line class="cls-7" x1="27.34" y1="54.9" x2="27.34" y2="49.76" />
              <line class="cls-7" x1="27.27" y1="54.9" x2="27.27" y2="49.76" />
              <line class="cls-7" x1="27.2" y1="54.9" x2="27.2" y2="49.76" />
              <line class="cls-7" x1="27.13" y1="54.9" x2="27.13" y2="49.76" />
              <line class="cls-7" x1="27.06" y1="54.9" x2="27.06" y2="49.76" />
              <line class="cls-7" x1="26.99" y1="54.9" x2="26.99" y2="49.76" />
              <line class="cls-7" x1="26.92" y1="54.9" x2="26.92" y2="49.76" />
              <line class="cls-7" x1="26.84" y1="54.9" x2="26.84" y2="49.76" />
              <line class="cls-7" x1="26.77" y1="54.9" x2="26.77" y2="49.76" />
              <line class="cls-7" x1="26.7" y1="54.9" x2="26.7" y2="49.76" />
              <line class="cls-7" x1="26.63" y1="54.9" x2="26.63" y2="49.76" />
              <line class="cls-7" x1="26.56" y1="54.9" x2="26.56" y2="49.76" />
              <line class="cls-7" x1="29.25" y1="55.71" x2="29.25" y2="48.95" />
              <polygon class="cls-9" points="120.28 8.37 120.28 8.31 120.28 8.24 120.35 8.03 120.42 7.83 120.49 7.63 120.63 7.43 120.7 7.36 120.77 7.22 120.77 7.15 120.85 7.15 120.92 7.09 121.06 7.02 121.13 6.95 121.2 6.95 121.27 6.88 121.41 6.82 121.55 6.82 121.69 6.75 121.84 6.75 121.98 6.75 122.12 6.75 122.19 6.75 122.33 6.75 122.47 6.75 122.61 6.82 122.83 6.82 122.97 6.88 123.18 6.95 123.18 7.02 123.25 7.02 123.39 7.09 123.53 7.15 123.68 7.29 123.82 7.36 124.03 7.49 124.17 7.63 124.24 7.7 124.38 7.76 124.45 7.9 124.6 8.03 124.74 8.17 124.88 8.31 125.09 8.44 125.09 8.51 125.16 8.51 125.23 8.64 125.3 8.71 125.37 8.85 125.52 8.98 125.59 9.12 125.66 9.25 125.66 6.55 126.44 6.55 126.44 10.4 125.8 10.4 125.73 10.33 125.66 10.27 125.66 10.2 125.52 10.06 125.37 9.86 125.16 9.66 124.81 9.32 124.81 9.25 124.74 9.18 124.67 9.12 124.52 8.98 124.38 8.85 124.24 8.71 124.1 8.58 123.89 8.37 123.82 8.31 123.75 8.24 123.6 8.17 123.46 8.1 123.32 7.97 123.18 7.9 122.97 7.76 122.9 7.76 122.83 7.7 122.76 7.7 122.61 7.63 122.47 7.63 122.33 7.56 122.12 7.56 122.05 7.56 121.98 7.56 121.91 7.56 121.77 7.63 121.62 7.63 121.55 7.7 121.48 7.76 121.41 7.76 121.34 7.83 121.27 7.9 121.2 7.97 121.13 8.03 121.13 8.1 121.13 8.17 121.06 8.31 121.06 8.37 121.06 8.51 121.06 8.58 121.06 8.64 121.06 8.71 121.13 8.85 121.13 8.91 121.2 8.98 121.2 9.12 121.27 9.12 121.27 9.18 121.34 9.25 121.41 9.25 121.48 9.32 121.55 9.39 121.62 9.46 121.69 9.46 121.77 9.46 121.84 9.46 121.91 9.52 122.05 9.52 122.12 9.52 122.26 9.52 122.26 10.33 122.19 10.33 122.12 10.33 122.05 10.33 121.91 10.33 121.69 10.33 121.55 10.27 121.34 10.2 121.2 10.13 121.13 10.13 121.13 10.06 121.06 10 120.92 9.93 120.85 9.86 120.7 9.73 120.63 9.59 120.49 9.46 120.49 9.39 120.42 9.32 120.42 9.18 120.35 9.05 120.35 8.91 120.28 8.71 120.28 8.51 120.28 8.44 120.28 8.37" />
              <path class="cls-9" d="m120.28,3.3v-.14l.07-.14v-.27l.07-.2.14-.2.14-.2v-.07l.07-.07.07-.07.14-.14.14-.14.21-.14.21-.14.21-.14h.07v-.07h.14l.07-.07h.21l.07-.07h.14l.28-.07h.35l.28-.07h.14l.14.07h.28l.21.07h.28l.28.07.28.14h.07l.07.07h.07v.07l.21.07.14.14.21.14.14.2.21.2v.07l.07.07v.07l.07.07v.07l.07.2.07.2.07.27v.54l-.07.2v.2l-.07.27-.14.2-.07.2h-.07v.07l-.14.14-.07.14-.21.14-.14.14-.21.14-.28.14h-.14l-.07.07h-.14l-.07.07h-.14l-.14.07h-.28l-.28.07h-.78l-.14-.07h-.21l-.28-.07-.28-.07-.28-.07v-.07h-.07l-.07-.07h-.07l-.21-.14-.14-.07-.14-.2-.21-.14-.14-.2-.07-.07v-.07h-.07v-.14l-.07-.14-.07-.2-.07-.2-.07-.27v-.41Zm.78.14v.07l.07.14v.2l.07.14.07.2.07.14.07.07.07.07.07.07.14.07.14.07.14.14.14.07h.07l.07.07h.07l.14.07h.21l.21.07h1.13l.21-.07.21-.07.21-.07h.07l.14-.07.07-.07.14-.07.14-.14.14-.07.07-.14v-.07h.07l.07-.07v-.14l.07-.14.07-.14v-.61l-.07-.14v-.14l-.07-.14-.14-.14v-.07l-.07-.07-.14-.07-.07-.14-.14-.07-.14-.07-.21-.14h-.14l-.07-.07h-.14l-.21-.07h-.21l-.21-.07h-.5l-.21.07h-.42l-.21.07-.14.07h-.07v.07h-.07l-.07.07-.14.07-.07.07-.14.07-.14.14-.14.14v.07l-.07.07-.07.14v.07l-.07.14v.2l-.07.14Z" />
            </g>
            <path class="cls-9" d="m107.48,21.41h56.97v9.88c0,2.35-1.91,4.26-4.26,4.26h-48.4c-2.38,0-4.3-1.93-4.3-4.3v-9.84h0Z" transform="translate(107.48 164.45) rotate(-90)" />
            <polygon class="cls-10" points="134.05 42.85 137.73 45.08 137.73 42.85 138.37 42.85 138.37 45.82 137.88 45.82 134.2 43.66 134.2 45.82 133.56 45.82 133.56 42.85 134.05 42.85" />
            <polygon class="cls-10" points="136.6 38.79 136.6 38.79 136.67 38.79 136.74 38.79 136.81 38.79 136.96 38.79 137.1 38.86 137.31 38.86 137.45 38.92 137.66 38.99 137.66 39.06 137.8 39.13 137.88 39.19 137.95 39.26 138.09 39.4 138.16 39.53 138.23 39.67 138.3 39.67 138.3 39.74 138.3 39.8 138.37 39.94 138.37 40.07 138.44 40.21 138.44 40.34 138.44 40.55 138.44 40.61 138.44 40.68 138.44 40.82 138.37 40.95 138.37 41.09 138.3 41.22 138.23 41.43 138.23 41.49 138.16 41.56 138.09 41.63 138.02 41.7 137.95 41.83 137.8 41.9 137.66 42.04 137.59 42.04 137.52 42.1 137.45 42.1 137.38 42.17 137.17 42.17 137.03 42.24 136.81 42.24 136.6 42.24 133.56 42.24 133.56 41.56 136.6 41.56 136.67 41.56 136.74 41.56 136.81 41.56 136.96 41.56 137.1 41.49 137.24 41.49 137.38 41.36 137.45 41.36 137.52 41.29 137.59 41.22 137.59 41.16 137.66 41.09 137.73 40.95 137.73 40.82 137.8 40.68 137.8 40.55 137.8 40.48 137.8 40.41 137.8 40.34 137.8 40.28 137.73 40.14 137.66 40.01 137.59 39.87 137.52 39.74 137.45 39.74 137.38 39.67 137.31 39.6 137.17 39.53 137.03 39.53 136.88 39.46 136.81 39.46 136.67 39.46 136.6 39.46 133.56 39.46 133.56 38.79 136.6 38.79" />
            <polygon class="cls-10" points="138.37 33.38 138.37 34.05 134.69 34.05 137.66 35.47 137.66 35.95 134.69 37.37 138.37 37.37 138.37 37.98 133.56 37.98 133.56 37.3 136.81 35.68 133.56 34.12 133.56 33.38 138.37 33.38" />
            <path class="cls-10" d="m133.56,30.74v-.2l.07-.14v-.14l.07-.14v-.07h.07v-.07l.07-.07.07-.07.07-.07.07-.14.14-.07h.07l.07-.07h.14l.07-.07h.5l.14.07h.14l.14.07.07.14h.07l.07.07v.07l.07.07.07.14v.07l.07.14v-.2l.07-.07v-.07l.07-.14h.07v-.07h.07l.07-.07.07-.07.07-.07.07-.07h.14v-.07h.28l.14-.07h.07l.07.07h.21l.07.07h.14l.14.07v.07h.07l.07.07.07.07.07.07.07.14.07.14h.07v.14l.07.07v.14l.07.14v2.03h-4.81v-1.83Zm2.62.2v.95h1.63v-1.01l-.07-.07v-.2l-.07-.14v-.14l-.07-.07-.07-.07h-.07l-.07-.07h-.07l-.07-.07h-.57l-.07.07-.14.07-.07.07v.07l-.07.07v.07l-.07.07v.14l-.07.14v.14Zm-2.05.07v.88h1.49v-1.08l-.07-.07v-.2l-.07-.14-.07-.07-.07-.07h-.07l-.07-.07h-.07l-.14-.07h-.28l-.07.07h-.14l-.07.07-.07.07v.07h-.07v.14l-.07.07v.07l-.07.14v.2Z" />
            <path class="cls-10" d="m138.37,24.99v.68l-1.2.41v1.83l1.2.41v.68l-4.81-1.69v-.68l4.81-1.62Zm-3.96,1.96l2.19.74v-1.42l-2.19.68Z" />
            <path class="cls-10" d="m133.56,22.82v-.27l.07-.14v-.27l.07-.14.07-.14.07-.14.07-.14.07-.14.07-.14.14-.14h.07v-.07h.07l.07-.07.07-.07.07-.07h.14l.07-.07.14-.07.14-.07h.21l.14-.07h.21l.14-.07h.42l.07.07h.35l.14.07h.14l.14.07.14.07.14.07.14.07.14.07.14.14.07.07v.07l.07.07.07.07.07.07v.07l.07.14.07.14.07.14.07.2v.14l.07.2v1.89h-4.81v-1.62Zm.57.2v.74h3.68v-.88l-.07-.07v-.34l-.07-.2v-.14l-.07-.14h-.07v-.07l-.07-.07-.07-.07-.07-.14-.07-.07-.14-.07-.14-.07h-.07l-.07-.07h-.14l-.14-.07h-1.06l-.14.07h-.21l-.14.07h-.07l-.07.07-.07.07-.14.07-.07.07-.07.14-.14.14v.07l-.07.07v.14l-.07.14v.14l-.07.2v.27Z" />
            <path class="cls-10" d="m133.49,17.75v-.27l.07-.2.07-.2.07-.2.07-.2.07-.07.07-.14.07-.14.14-.07.14-.14.21-.14.21-.14.07-.07h.14l.07-.07h.14l.14-.07h.21l.21-.07h.71l.21.07.21.07.21.07.21.07h.07l.07.07.07.07.14.07.14.14.14.14.14.14.14.2v.07l.07.07v.14l.07.07v.07l.07.07v.27l.07.2v.54l-.07.14v.14l-.07.2-.07.2-.07.2-.07.07v.07l-.07.07-.14.14-.07.07-.14.14-.21.14-.21.14-.07.07h-.14l-.07.07h-.14l-.14.07-.21.07h-.92l-.21-.07-.21-.07-.21-.07-.21-.07h-.07l-.07-.07-.07-.07-.14-.07-.14-.14-.14-.14-.14-.14-.14-.2v-.07l-.07-.07v-.07l-.07-.07v-.14l-.07-.07-.07-.2v-.61Zm.64.14v.41l.07.14.07.14.07.14v.07l.07.07.07.07.07.07.14.14.14.07.14.07.07.07h.07l.14.07h.14l.14.07h.78l.14-.07h.14l.21-.07.14-.07h.07l.07-.07.14-.07.07-.07.14-.14.07-.07.07-.14.07-.07v-.07l.07-.14v-.07l.07-.14v-.68l-.07-.14v-.14l-.07-.14-.07-.14v-.07l-.07-.07-.07-.07-.14-.07-.07-.07-.14-.14-.14-.07h-.07l-.07-.07-.14-.07h-.14l-.14-.07h-.78l-.14.07h-.21l-.14.07-.14.07-.07.07h-.07l-.07.07-.14.07-.14.14-.07.07-.07.14v.07h-.07v.07l-.07.14v.14l-.07.14v.34Z" />
            <polyline class="cls-10" points="138.37 12.54 136.46 13.42 136.46 14.23 138.37 14.23 138.37 14.9 133.56 14.9 133.56 13.35 133.56 13.28 133.56 13.21 133.56 13.08 133.56 12.94 133.63 12.81 133.7 12.67 133.77 12.54 133.77 12.47 133.84 12.4 133.84 12.33 133.91 12.2 134.05 12.13 134.13 12.06 134.27 12 134.34 12 134.41 11.93 134.48 11.93 134.55 11.86 134.69 11.86 134.83 11.86 134.97 11.86 135.04 11.86 135.12 11.86 135.26 11.86 135.4 11.86 135.54 11.93 135.68 12 135.82 12.06 135.89 12.13 135.96 12.2 136.04 12.27 136.11 12.33 136.18 12.47 136.25 12.6 136.32 12.74 138.37 11.79" />
            <polygon class="cls-9" points="134.13 13.48 134.13 14.23 135.82 14.23 135.82 13.35 135.82 13.28 135.82 13.15 135.82 13.08 135.75 12.94 135.68 12.81 135.61 12.74 135.61 12.67 135.54 12.67 135.47 12.6 135.33 12.6 135.26 12.54 135.12 12.54 134.97 12.54 134.9 12.54 134.83 12.54 134.76 12.54 134.69 12.6 134.55 12.6 134.48 12.67 134.41 12.74 134.34 12.81 134.27 12.87 134.2 13.01 134.2 13.15 134.13 13.28 134.13 13.48" />
            <path class="cls-2" d="m89.07,49.31c-1.71,1.32-4.21,1.32-5.93,0" />
            <path class="cls-2" d="m81.66,42.89c2.57-1.99,6.32-1.99,8.89,0" />
            <path class="cls-2" d="m86.11,46.41c-.38,0-.69.29-.69.64s.31.64.69.64.69-.29.69-.64-.31-.64-.69-.64" />
            <path class="cls-2" d="m90.55,51.2c-2.57,1.99-6.32,1.99-8.89,0" />
            <path class="cls-2" d="m83.14,44.79c1.71-1.32,4.21-1.32,5.93,0" />
          </g>
        </svg>

        <svg
          class="music-note"
          style={{ visibility: (this.buzzerOn) ? '' : 'hidden' }}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="9"
          // viewBox="0 0 8 8"
          viewBox="0 0 143.05 56.97"
          >
          <path
            transform={`translate(10 0) scale(8)`}
            d="M8 0c-5 0-6 1-6 1v4.09c-.15-.05-.33-.09-.5-.09-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5v-3.97c.73-.23 1.99-.44 4-.5v2.06c-.15-.05-.33-.09-.5-.09-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5v-5.5z"
          />
        </svg>
      </div>
    );
  }
}
