
const convertColorsToHashMap = (colors) => colors.reduce((obj, { id, value }) => ({
  ...obj,
  [id]: value,
}), {});

const getColors = (styles, colors) => colors.map(({ id, name }) => ({
  id,
  value: styles.getPropertyValue(name).trim(),
}));

export const getThemeColors = (element) => {
  const styles = getComputedStyle(element);
  const colorsVariables = [
    { id: 'a1', name: '--color-alternative-1' },
    { id: 'a2', name: '--color-alternative-2' },
    { id: 'a3', name: '--color-alternative-3' },
    { id: 'a4', name: '--color-alternative-4' },
    { id: 'a5', name: '--color-alternative-5' },
    { id: 'a6', name: '--color-alternative-6' },
    { id: 'a7', name: '--color-alternative-7' },
    { id: 'a8', name: '--color-alternative-8' },
  ];

  return convertColorsToHashMap(getColors(styles, colorsVariables));
};

export const getBoardCompanyColors = (theme, companyName) => ({
  educabot: theme.a1,
  arduino: theme.a2,
  microsoft: theme.a3,
  dji: theme.a4,
  makeblock: theme.a5,
  robobloq: theme.a6,
  createc: theme.a7,
  creabots: theme.a4,
})[companyName] || theme.a6;
