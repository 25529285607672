/* eslint operator-assignment: 0 */
import { Led } from './hardware/led';
import { Servo } from './hardware/servo';
import { Button } from './hardware/button';
import { ButtonTouch } from './hardware/buttonTouch';
import { DHT11 } from './hardware/dht11';
import { Soil } from './hardware/soil';
import { LCD } from './hardware/lcd';
import { Matrix8x8 } from './hardware/matrix8x8';
import { Matrix8x16 } from './hardware/matrix8x16';
import { MotorPWM } from './hardware/motorPWM';
import { MotorDC } from './hardware/motorDC';
import { Buzzer } from './hardware/buzzer';
import { Ultrasonic } from './hardware/ultrasonic';
import { UltrasonicButy } from './hardware/ultrasonicButy';
import { Light } from './hardware/light';
import { Potentiometer } from './hardware/potentiometer';
import { LedRGB } from './hardware/ledRGB';
import { LedRGBStrip } from './hardware/ledRGBStrip';
import { Joystick } from './hardware/joystick';
import { Rain } from './hardware/rain';
import { Sound } from './hardware/sound';
import { Obstacle } from './hardware/obstacle';
import { Infrared } from './hardware/infrared';
import { Color } from './hardware/color';
import { LineFollower } from './hardware/lineFollower';
import { Buty } from './hardware/buty/buty';
import { FlowLabelPrefix } from './constants';

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      red: parseInt(result[1], 16),
      green: parseInt(result[2], 16),
      blue: parseInt(result[3], 16),
    }
    : null;
}

export function zeroPad(value, length) {
  let sval = value.toString();
  while (sval.length < length) {
    sval = `0${sval}`;
  }
  return sval;
}

export function formatTime(seconds) {
  const ms = Math.floor(seconds * 1000) % 1000;
  const secs = Math.floor(seconds % 60);
  const mins = Math.floor(seconds / 60);
  return `${zeroPad(mins, 2)}:${zeroPad(secs, 2)}.${zeroPad(ms, 3)}`;
}

export const cpuNanos = (cycles, frequency) => Math.round((cycles / frequency) * 1000000000);
export const cpuMicros = (cycles, frequency) => Math.round((cycles / frequency) * 1000000);
export const cpuMillis = (cycles, frequency) => Math.round((cycles / frequency) * 1000);

export function getMilliSecconds(seconds) {
  const ms = Math.floor(seconds * 10000) % 10000;
  return ms / 10;
}

export function getMicroSeconds(seconds) {
  return Math.floor(seconds * 1000000) % 1000000;
}

export function getComponentClass(componentType, hardware = null, pin = null, id = null, variant = null, rotation = null) {
  let component = null;
  // Boards
  if (componentType === 'buty') {
    component = new Buty();
  }
  // Components
  if (componentType === 'led_NPIN') {
    component = new Led(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'servo_NPIN') {
    component = new Servo(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'button_NPIN') {
    component = new Button(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'touch_NPIN') {
    component = new ButtonTouch(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'dht11_NPIN') {
    component = new DHT11(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'soil_NPIN') {
    component = new Soil(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'I2C_16x9PIN') {
    component = new LCD(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'I2C_M8x8PIN') {
    component = new Matrix8x8(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'I2C_M16x8PIN') {
    component = new Matrix8x16(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'motor_NPIN') {
    component = new MotorPWM(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'motordc_NPIN') {
    component = new MotorDC(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'buzzer_NPIN') {
    component = new Buzzer(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'ultrasonic_NPIN') {
    component = new Ultrasonic(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'ultrasonicButy_NPIN') {
    component = new UltrasonicButy(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'photoresistor_NPIN') {
    component = new Light(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'potentiometer_NPIN') {
    component = new Potentiometer(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'ledRGB_NPIN') {
    component = new LedRGB(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'ledRGBStrip_NPIN') {
    component = new LedRGBStrip(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'joystick_NPIN') {
    component = new Joystick(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'rain_NPIN') {
    component = new Rain(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'sound_NPIN') {
    component = new Sound(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'obstacle_NPIN') {
    component = new Obstacle(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'infrared_NPIN' || componentType === 'follower_NPIN' || componentType === 'follower_NPIN2') {
    component = new Infrared(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'color_NPIN') {
    component = new Color(hardware, pin, id, variant, rotation);
  }
  if (componentType === 'lineFollower_NPIN') {
    component = new LineFollower(hardware, pin, id, variant, rotation);
  }

  return component;
}

function formatTwoWireBlocks(pinDB = {}) {
  let prePinDB = {};
  Object.entries(pinDB).map(([pin, pinObj], i) => {
    if (pin !== '' && pin !== 'No disponible') {
      Object.entries(pinObj).map(([pinType, pinId], j) => {
        pinId.map((pId) => {
          if (!prePinDB[pinType] || !prePinDB[pinType].pinPort.includes(pin)) {
            prePinDB = {
              ...prePinDB,
              [pId]: {
                pinType,
                pinPort: [
                  ...(prePinDB[pId]) ? prePinDB[pId].pinPort : [],
                  pin,
                ],
              },
            };
          }
          return true;
        });
        return true;
      });
    }
    return true;
  });
  return prePinDB;
}

function formatFourWireBlocks(pinDB = {}) {
  let prePinDB = {};
  Object.entries(pinDB).map(([id, pinObj], i) => {
    if (pinObj.pinPort.length === 4) {
      prePinDB = {
        ...prePinDB,
        [`${id}-1`]: {
          pinType: pinObj.pinType,
          pinPort: pinObj.pinPort.slice(0, 2),
        },
        [`${id}-2`]: {
          pinType: pinObj.pinType,
          pinPort: pinObj.pinPort.slice(2),
        },
      };
    } else {
      prePinDB = {
        ...prePinDB,
        [id]: pinObj,
      };
    }
    return true;
  });
  return prePinDB;
}

function preFormatPinDB(pinDB = {}) {
  let newPinDB = {};
  let prePinDB = {};

  prePinDB = formatTwoWireBlocks(pinDB);
  prePinDB = formatFourWireBlocks(prePinDB);
  // console.log('==================', pinDB, prePinDB);

  Object.entries(prePinDB).map(([id, pinObj], i) => {
    newPinDB = {
      ...newPinDB,
      [pinObj.pinPort.join(',')]: {
        ...(newPinDB[pinObj.pinPort.join(',')]) ? newPinDB[pinObj.pinPort.join(',')] : {},
        [pinObj.pinType]: [],
      },
    };
    return true;
  });
  // console.log('==================', pinDB, prePinDB, newPinDB);
  return newPinDB;
}

export function formatSimulatorParts(savedState = {}, boardType = '', pinDB = {}) {
  const newPinDB = preFormatPinDB({ ...pinDB });
  const board = getComponentClass(boardType);
  const boardNodeId = `${board.type}1`;
  const position = savedState.nodes && savedState.nodes[boardNodeId]?.position ? savedState.nodes[boardNodeId].position : null;
  const circuit = {
    offset: savedState.offset || {
      x: 40,
      y: -100,
    },
    scale: savedState.scale || 1.2,
    nodes: {
      [boardNodeId]: {
        id: boardNodeId,
        type: 'output',
        component: `${board.type}:0`,
        position: position || {
          y: 150,
          x: 20,
        },
      },
    },
    links: {},
  };
  Object.entries(newPinDB).map(([pin, pinObj], index) => {
    Object.keys(pinObj).map((type) => {
      // const type = Object.keys(pinObj)[0] || '';
      const componentId = `${type}${index + 1}`;
      const componentPin = pin; // TODO: Find out what pin to use according to which component is this
      const componentPosition = savedState.nodes && savedState.nodes[componentId]?.position ? savedState.nodes[componentId]?.position : null;
      circuit.nodes = {
        ...circuit.nodes,
        [componentId]: {
          id: componentId,
          type: 'input',
          component: `${type}:0`,
          position: componentPosition || {
            y: 50,
            x: 20 + (index * 80),
          },
        },
      };

      const linkId = `${FlowLabelPrefix.LINK}${componentId}`;
      circuit.links = {
        ...circuit.links,
        [linkId]: {
          id: linkId,
          from: {
            nodeId: boardNodeId,
            portId: componentPin,
          },
          to: {
            nodeId: componentId,
            portId: '',
          },
        },
      };
      return true;
    });
    return true;
  });

  // console.log('==================', circuit, newPinDB, pinDB);
  return circuit;
}

export function findPinPosition(n) {
  return n === 0 ? 0 : Math.log2(n & -n);
}

export function rotatePort(angle, port, width, height) {
  const radians = (Math.PI / 180) * angle;
  const centerX = (port.x + (width - port.x)) / 2;
  const centerY = (port.y + (height - port.y)) / 2;
  const x = ((port.x - centerX) * Math.cos(radians)) - ((port.y - centerY) * Math.sin(radians)) + centerX;
  const y = ((port.x - centerX) * Math.sin(radians)) + ((port.y - centerY) * Math.cos(radians)) + centerY;
  const position = {
    x: Math.round(x),
    y: Math.round(y),
  };

  return position;
}

export function deepEqual(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object')
    ? (Object.keys(x).length === Object.keys(y).length)
    && Object.keys(x).reduce((isEqual, key) => isEqual && deepEqual(x[key], y[key]), true) : (x === y);
}

export function castValue(castFrom, castTo) {
  let value = castTo;
  if (typeof castFrom === 'string') {
    value = String(value);
  } else if (typeof castFrom === 'number') {
    value = Number(value);
  } else if (typeof castFrom === 'boolean') {
    value = Boolean(value);
  }

  return value;
}
