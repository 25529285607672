import React, { useState } from 'react';
import styles from './toolsPanel.mod.scss';
import clsx from 'clsx';

const CreabotsToolsPanel = (props) => {

  const {
    setZoom,
    setCenter,
    setUndo,
    settingActive,
    toggleSettings,
    changeToolboxToggle,
    toolboxToggle,
    toggleDarkTheme,
    darkthemeToggle
  } = props;
  
  return (
    <div className={clsx(styles.toolsPanel, 'blocklyToolsPanel')}>
      <div className={styles.buttons}>
          <div className={styles.inlineBlock}>
            <button type="button" className="undo2" onKeyPress={() => {}} onClick={() => setUndo(false)}>
              <img src='/images/creabots/toolsPanel/prev.png' />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="undo2" onKeyPress={() => {}} onClick={() => setUndo(true)}>
              <img src='/images/creabots/toolsPanel/next.png' />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="zoom-out" onKeyPress={() => {}} onClick={() => setZoom('down')}>
              <img src='/images/creabots/toolsPanel/zoomOut.png' />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="zoom-in" onKeyPress={() => {}} onClick={() => setZoom('up')}>
              <img src='/images/creabots/toolsPanel/zoomIn.png' />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="center" onKeyPress={() => {}} onClick={setCenter}>
              <img src='/images/creabots/toolsPanel/focus.png' />
            </button>
          </div>
      </div>
    </div>
  );
  
};

const ToolsPanel = React.memo((props) => {
  const [settingActive, setSettingActive] = useState(false);
  const toolboxToggle = props.toolboxToggle;
  const darkthemeToggle = props.darkthemeToggle;
  const { isCreabots } = props;

  const toggleSettings = () => {
    setSettingActive(!settingActive);
  }

  const setZoom = (direction = 'up') => {
    if (props.onSetZoom) {
      props.onSetZoom(direction);
    }
  }

  const setUndo = (direction = 'up') => {
    if (props.onSetUndo) {
      props.onSetUndo(direction);
    }
  }

  const setCenter = () => {
    if (props.onSetCenter) {
      props.onSetCenter();
    }
  }

  const changeToolboxToggle = () => {
    if (props.onChangeToolboxToggle) {
      props.onChangeToolboxToggle();
    }
  }

  const toggleDarkTheme = () => {
    if (props.onToggleDarkTheme) {
      props.onToggleDarkTheme();
    }
  }

  const creabotsToolsPanelProps = {
    setZoom,
    setCenter,
    setUndo,
    settingActive,
    toggleSettings,
    changeToolboxToggle,
    toolboxToggle,
    toggleDarkTheme,
    darkthemeToggle
  }
  
  return isCreabots ? <CreabotsToolsPanel {...creabotsToolsPanelProps} /> : (
    <div className={clsx(styles.toolsPanel, 'blocklyToolsPanel')}>
      <div className={styles.buttons}>
          <div className={styles.inlineBlock}>
            <button type="button" className="zoom-in" onKeyPress={() => {}} onClick={() => setZoom('up')}>
              <span className="icon-zoom-in" />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="zoom-out" onKeyPress={() => {}} onClick={() => setZoom('down')}>
              <span className="icon-zoom-out" />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="center" onKeyPress={() => {}} onClick={setCenter}>
              <span className="icon-radio-checked" />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="undo2" onKeyPress={() => {}} onClick={() => setUndo(false)}>
              <span className="icon-undo2" />
            </button>
          </div>
          <div className={styles.inlineBlock}>
            <button type="button" className="undo2 inverted" onKeyPress={() => {}} onClick={() => setUndo(true)}>
              <span className="icon-undo2" />
            </button>
          </div>
          <div className={`${styles.inlineBlock} ${(settingActive) ? styles.active : ''}`}>
            <button type="button" className="zoom-in" onKeyPress={() => {}} onClick={toggleSettings}>
              <span className="icon-cog1" />
            </button>
            {(settingActive) && (
              <div className="settings tab-dropdown" onKeyPress={() => {}} onClick={toggleSettings}>
                <div className="tab-dropdown-close-clickability" />
                <ul className="tab-dropdown-options">
                  <li className="tab-save" onKeyPress={() => {}} onClick={changeToolboxToggle}>
                    <span className="">
                      {(toolboxToggle) && ('Modo TXT')}
                      {(!toolboxToggle) && ('Modo ICO')}
                    </span>
                  </li>
                  <li className="tab-save" onKeyPress={() => {}} onClick={toggleDarkTheme}>
                    <span className="">
                      {(darkthemeToggle) && ('Modo claro')}
                      {(!darkthemeToggle) && ('Modo oscuro')}
                    </span>
                  </li>
                  {/* <li className="tab-save" onKeyPress={() => {}} onClick={() => { this.showHelpAgain(); }}>
                    <span className="">
                      Mostrar Ayuda
                    </span>
                  </li> */}
                </ul>
              </div>
            )}
          </div>
      </div>
    </div>
  );
});

export default ToolsPanel;