/* eslint arrow-body-style: 0 */
import * as React from 'react';
import styled from 'styled-components';

const Outer = styled.div`
  padding: 40px 30px;
`;

export const NodeInnerDefault = ({ node, children, className }) => {
  return (
    <Outer className={className}>
      {children}
    </Outer>
  );
};
