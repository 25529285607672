import toast from './toast';
import { SHOW_TOAST, HIDE_TOAST, HIDE_ALL_TOAST } from './toastsSlice';

const toastsMiddleware = () => (next) => (action) => {
  if (action.type === SHOW_TOAST) {
    const { ref, type, title, message, options } = action.payload;
    toast.create({
      ref,
      type,
      title,
      message,
      options,
    });
  }

  if (action.type === HIDE_TOAST) {
    const { ref } = action.payload;
    toast.remove(ref);
  }

  if (action.type === HIDE_ALL_TOAST) {
    toast.removeAll();
  }

  next(action);
};

export default toastsMiddleware;
