import types from '../actions/types';

export const bloquesInitialState = {
  statusPorts: '',
  statusMsg: '',
  statusMonitorMsg: '',
  statusState: '',
  statusWifi: '',
  wifiList: [],
  ports: [],
  agentPorts: [],
  msgs: [],
  monitorMsgs: [],
  state: {
    msgs: [],
    ebBuild: 'ERROR',
    agent: '',
    progress: 0,
  },
};

export default function (state = bloquesInitialState, action) {
  switch (action.type) {
    case types.GET_PORTS:
      return Object.assign({}, state, action.payload);
    case types.GET_AGENT_PORTS:
      return Object.assign({}, state, { agentPorts: action.payload.ports });
    case types.GET_NEW_MESSAGE:
      const msgs = state.msgs; // eslint-disable-line
      msgs.push(action.payload.msg);
      return Object.assign({}, state, { statusMsg: action.payload.statusMsg, msgs });
    case types.GET_NEW_STATE:
      switch (action.payload.stateMsg) {
        case '#### UPLOAD PROGRESS':
          state.state.progress = action.payload.progress || 0; // eslint-disable-line
          break;
        case '#### BOARD CONNECTED':
          state.state.agent = 'GOOD'; // eslint-disable-line
          break;
        case '#### BOARD NOT CONNECTED':
          state.state.agent = 'ERROR'; // eslint-disable-line
          break;
        case '#### PORT CONNECTED':
          state.state.ebBuild = 'GOOD'; // eslint-disable-line
          break;
        case '#### PORT NOT CONNECTED':
          state.state.ebBuild = 'ERROR'; // eslint-disable-line
          break;
        default:
          break;
      }
      state.state.msgs.push(action.payload.stateMsg);
      return Object.assign({}, state, { statusState: action.payload.statusState, state: state.state });
    case types.GET_NEW_MONITOR_MESSAGE:
    {
      const monitorMsgs = action.payload.monitorMsgs;
      return Object.assign({}, state, { statusMonitorMsg: action.payload.statusMonitorMsg, monitorMsgs });
    }
    case types.BLOQUES_RESET_STATUS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
