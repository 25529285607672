import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        movable: false,
        type: 'BHOOT_initial_block',
        x: 50,
        y: 50,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="control" name="%{BKY_LANG_CATEGORY_CONTROLS}" categorystyle="control_category">
      <block type="BHOOT_repeat_forever" />
      <block type="pause_millis" />
      <block type="BHOOT_pause_smart" />
      <block type="BHOOT_control_loop_number" />

    </category>

    <category id="outputs" name="%{BKY_LANG_CATEGORY_ACTIONS}" categorystyle="outputs_category">
      <block type="BHOOT_play_tone" />
      <block type="BHOOT_led" />
      <block type="BHOOT_led_off" />
    </category>

    <category id="motors" name="%{BKY_LANG_CATEGORY_MOTION}" categorystyle="motors_category">
      <block type="BHOOT_motor_forward" />
      <block type="BHOOT_motor_backward" />
      <block type="BHOOT_motor_right" />
      <block type="BHOOT_motor_left" />
      <block type="BHOOT_line_follower_jr" />
    </category>

    <category id="screens" name="%{BKY_LANG_CATEGORY_LCD}" categorystyle="screens_category">
      <block type="BHOOT_matrix_8x8_junior" />
      <block type="show_string" />
    </category>

  </xml>
);
/* eslint-enable */
