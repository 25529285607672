/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable dot-notation */
// import { strict } from "assert";

const $ = window.$;

export default {

  buildModal(event, msgs) {
    return `
    <div class="popup-modal-parent create-variable-modal-parent">
      <div class="popup-modal-under direction-ltr">
        <div class="middle-container">
          <div class="white-back">
            <div class="input-custom">
              <input type="text" value="${event.oldText}" placeholder="${msgs.IOS_VARIABLES_VARIABLE_NAME}" id="create-variable-field">
              ${(event.oldText !== '') ? (
    `<span class="c-red">${msgs.VARIABLE_ALREADY_EXISTS_NONAME}</span>`
  ) : ''}
            </div>
            <div class="new-buttons">
              <div class="create-new disabled">${msgs.IOS_OK}</div>
              <div class="desist-new">${msgs.IOS_CANCEL}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    `;
  },

  js(event) {
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildModal(event, window.Blockly.Msg);
    window.Blockly.WidgetDiv.show(event, true);
    let value = '';
    $('.blocklyWidgetDiv').on('keyup', 'input', (e) => {
      if (e.target.value.length > 0) {
        value = e.target.value;
        $('.blocklyWidgetDiv .create-new').removeClass('disabled');
      } else {
        $('.blocklyWidgetDiv .create-new').addClass('disabled');
      }
    });
    $('.blocklyWidgetDiv').on('click', '.create-new', () => {
      event.callBackFunction(value);
      $('.blocklyWidgetDiv').unbind('click');
      window.Blockly.WidgetDiv.hide();
    });

    $('.blocklyWidgetDiv').on('click', '.desist-new', () => {
      $('.blocklyWidgetDiv').unbind('click');
      window.Blockly.WidgetDiv.hide();
    });
  },
};
