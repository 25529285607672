/* eslint import/no-extraneous-dependencies: 0 */
import * as Blockly from 'blockly';

const constants = new Blockly.zelos.ConstantProvider();

export default {
  imgBase: process.env.IS_ELECTRON ? './images' : '/images',
  zoom: 0.5,
  blockWidth: 60,
  blockHeight: 40,
  jrBlockWidth: 40,
  jrBlockHeight: 40,
  SHAPES: {
    ROUND: constants.SHAPES.ROUND,
    HEXAGONAL: constants.SHAPES.HEXAGONAL,
    SQUARE: constants.SHAPES.SQUARE,
  },
}