import React, { useState } from 'react';
import styles from './tutorialBuildInstructions.mod.scss';
import { useIntl } from 'react-intl';
import TutorialProgress from '@sections/creabots/components/tutorialProgress';


const TutorialBuildInstructions = ({
  onNextStep,
  onPrevStep,
  currentStep,
  totalSteps,
  images,
}) => {
  const intl = useIntl();
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = () => {
    if (onNextStep) {
      onNextStep();
    }
  };

  const handlePrev = () => {
    if (onPrevStep) {
      onPrevStep();
    }
  };

  const handleNextImage = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      handleNext();
    }
  };

  const handlePrevImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    } else {
      handlePrev();
    }
  };

  return (
    <div className={styles.tutorialBuildInstructions}>
      <TutorialProgress customStyle={styles.disabledProgress} showStepArrows={false} showStepsCount={true} currentStep={currentStep + 1} totalStep={totalSteps} onNextStep={handleNext} onPrevStep={handlePrev} />
      <div className={styles.content}>
        <div className={styles.prevStep} onClick={handlePrevImage}>&lt;</div>
        <div className={styles.image}>
          {images[currentImage].text ? (
            <div className={styles.text}>{`${currentImage + 1}. ${intl.formatMessage({ id: images[currentImage].text })}`}</div>
          ) : ('')}
          {images[currentImage].src ? (
            <img src={images[currentImage].src} />
          ) : ('')}
        </div>
        <div className={styles.nextStep} onClick={handleNextImage}>&gt;</div>
      </div>
      <div className={styles.progressText}>{`${currentImage + 1}/${images.length}`}</div>
      <TutorialProgress showStepArrows={false} currentStep={currentImage + 1} totalStep={images.length} />
    </div>
  );
};


export default TutorialBuildInstructions;