/* eslint no-multi-spaces: 0 */
import React from 'react';
// import { CPUPerformance } from './cpuPerformance';
import { portBConfig, portCConfig, portDConfig } from 'avr8js';
import { cpuMicros, cpuMillis, cpuNanos, getMilliSecconds, rotatePort } from '../../utils';
import { AVRRunner } from './execute';
import { WS2812Controller } from '../../libs/ws2812';
import { BuzzerController } from '../../libs/buzzer';
import '../../components/buty/buty';
import { analog, i2c, spi, usart } from './pins';
import { I2CBus } from '../../libs/I2CBus';

export class Buty {
  constructor() {
    this.id = 'buty';
    this.type = 'buty';
    this.mhz = 16000000;
    this.cpuCycles = 0;
    this.runner = null;
    this.pinConnections = [];
    this.serialOutput = '';
    this.i2cBus = null;
    this.onBoardComponents = {
      buzzerPin: '12',
      buzzerFrequency: 0,
      ledPin: '13',
      ledLeft: false,
      ledLeftColor: '#FFFFFF',
      ledLeftValue: '',
      ledRight: false,
      ledRightColor: '#FFFFFF',
      ledRightValue: '',
    };
    this.newPixelController = null;
    this.BuzzerController = null;
    this.rotation = 0;
    this.size = {
      width: 280, // 280
      height: 140,
    };
    this.lastTimestamp = 0;
    this.setupRun = false;
    this.pinInfo = [
      // { name: 'A5.2', x: 87, y: 9, signals: [[analog(5), i2c('SCL')]] },
      // { name: 'A4.2', x: 97, y: 9, signals: [[analog(4), i2c('SDA')]] },
      // { name: 'AREF', x: 106, y: 9, signals: [] },
      // { name: 'GND.1', x: 115, y: 9, signals: [[{ type: 'power', signal: 'GND' }]] },
      // { name: '13', x: 125, y: 9, signals: [[spi('SCK')]] },
      // { name: '12', x: 134, y: 9, signals: [[spi('MISO')]] },
      { name: '11', x: 230, y: -3, signals: [[spi('MOSI'), { type: 'pwm' }]] },
      { name: '10', x: 230, y: 144, signals: [[spi('SS'), { type: 'pwm' }]] },
      // { name: '9', x: 163, y: 9, signals: [[{ type: 'pwm' }]] },
      // { name: '8', x: 173, y: 9, signals: [] },
      // { name: '7', x: 189, y: 9, signals: [] },
      // { name: '6', x: 198, y: 9, signals: [[{ type: 'pwm' }]] },
      // { name: '5', x: 208, y: 9, signals: [[{ type: 'pwm' }]] },
      // { name: '4', x: 217, y: 9, signals: [] },
      { name: '3', x: 196, y: 144, signals: [[{ type: 'pwm' }]] },
      // { name: '2', x: 236, y: 9, signals: [] },
      // { name: '1', x: 246, y: 9, signals: [[usart('TX')]] },
      // { name: '0', x: 255, y: 9, signals: [[usart('RX')]] },
      // { name: 'IOREF', x: 131, y: 191, signals: [] },
      // { name: 'RESET', x: 140, y: 191, signals: [] },
      // { name: '3.3V', x: 150, y: 191, signals: [[{ type: 'power', signal: 'VCC', voltage: 3.3 }]] },
      // { name: '5V', x: 160, y: 191, signals: [[{ type: 'power', signal: 'VCC', voltage: 5 }]] },
      // { name: 'GND.2', x: 169, y: 191, signals: [[{ type: 'power', signal: 'GND' }]] },
      // { name: 'GND.3', x: 179, y: 191, signals: [[{ type: 'power', signal: 'GND' }]] },
      // { name: 'VIN', x: 188, y: 191, signals: [[{ type: 'power', signal: 'VCC' }]] },
      { name: 'A0', x: 125, y: 144, signals: [[analog(0)]] },
      { name: 'A0,A1', x: 125, y: 144, signals: [[{ type: 'trigger' }], [analog(1)]] },
      { name: 'A0,A1,A7', x: 125, y: 144, signals: [[{ type: 'Y' }], [{ type: 'X' }, analog(1)], [{ type: 'button' }, analog(7)]] },
      // { name: 'A1', x: 217, y: 191, signals: [[analog(1)]] },
      // { name: 'A2', x: 227, y: 191, signals: [[analog(2)]] },
      { name: 'A3', x: 161, y: 144, signals: [[analog(3)]] },
      { name: 'A3,A2', x: 161, y: 144, signals: [[{ type: 'trigger' }], [analog(2)]] },
      // { name: 'A4', x: 125, y: -3, signals: [[analog(4), i2c('SDA')]] },
      // { name: 'A5', x: 125, y: -3, signals: [[analog(5), i2c('SCL')]] },
      { name: 'A6', x: 196, y: -3, signals: [[analog(6)]] },
      { name: 'A4,A5', x: 125, y: -3, signals: [[i2c('SDA')], [i2c('SCL')]] },
      { name: 'A4.2,A5.2', x: 161, y: -3, signals: [[i2c('SDA')], [i2c('SCL')]] },
      { name: '4,5', x: 283, y: 87, signals: [[{ type: 'digital' }], [{ type: 'pwm' }]] },
      { name: '6,7', x: 283, y: 53, signals: [[{ type: 'pwm' }], [{ type: 'digital' }]] },
    ];

    this.setPinConnections = this.setPinConnections.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getSerialOutput = this.getSerialOutput.bind(this);
    this.clearNeoPixelLeds = this.clearNeoPixelLeds.bind(this);
    this.redrawNeoPixelLeds = this.redrawNeoPixelLeds.bind(this);
    this.getPinStates = this.getPinStates.bind(this);
    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.updateComponents = this.updateComponents.bind(this);
    this.executeProgram = this.executeProgram.bind(this);
    this.runComponentSetup = this.runComponentSetup.bind(this);
    this.stopExecute = this.stopExecute.bind(this);
    this.writeDigitalPin = this.writeDigitalPin.bind(this);
    this.writeAnalogPin = this.writeAnalogPin.bind(this);
    this.render = this.render.bind(this);
  }

  setPinConnections = (pinConnections = []) => {
    this.pinConnections = pinConnections;
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getSerialOutput = () => this.serialOutput;

  clearNeoPixelLeds = () => {
    this.onBoardComponents.ledLeft = false;
    this.onBoardComponents.ledRight = false;
  }

  redrawNeoPixelLeds = (cycles) => {
    const pixels = new Uint32Array(this.newPixelController.update(cpuNanos(cycles, this.mhz)));
    if (pixels) {
      // left LED
      let value = pixels[0];
      if (typeof value !== 'undefined') {
        if (value) {
          this.onBoardComponents.ledLeft = true;
          this.onBoardComponents.ledLeftColor = this.newPixelController.getRGBValue(value);
          this.onBoardComponents.ledLeftValue = this.newPixelController.getRGBPlainValue(value);
        } else {
          this.onBoardComponents.ledLeft = false;
          this.onBoardComponents.ledLeftValue = '';
        }
      }
      // right LED
      value = pixels[1];
      if (typeof value !== 'undefined') {
        if (value) {
          this.onBoardComponents.ledRight = true;
          this.onBoardComponents.ledRightColor = this.newPixelController.getRGBValue(value);
          this.onBoardComponents.ledRightValue = this.newPixelController.getRGBPlainValue(value);
        } else {
          this.onBoardComponents.ledRight = false;
          this.onBoardComponents.ledRightValue = '';
        }
      }
    }
  }

  getPinStates = (cpuCycles) => {
    const pinStates = [
      {
        pin: this.onBoardComponents.ledPin,
        value: {
          left: this.onBoardComponents.ledLeftValue,
          right: this.onBoardComponents.ledRightValue,
        },
      },
      {
        pin: this.onBoardComponents.buzzerPin,
        value: this.onBoardComponents.buzzerFrequency,
      },
    ];
    if (getMilliSecconds((cpuCycles - this.cpuCycles) / this.mhz) > 10) {
      this.cpuCycles = cpuCycles;
      this.pinConnections.map((component) => {
        pinStates.push({
          pin: component.pin,
          // value: component.value,
          value: component.component.getValue(),
        });
        return true;
      });
    }
    return pinStates;
  }

  runMicrosecond = (cpuCycles) => {
    if (this.onBoardComponents.buzzerPin) {
      this.onBoardComponents.buzzerFrequency = this.BuzzerController.play(cpuMicros(cpuCycles, this.mhz));
    }
  }

  updateComponents = (value, portType = 'D', cpuCycles) => {
    // ONBOARD COMPONENTS
    if (this.onBoardComponents.ledPin >= 8 && this.onBoardComponents.ledPin <= 16) {
      const ledValue = this.runner.portB.pinState(this.onBoardComponents.ledPin - 8);
      this.newPixelController.feedValue(ledValue, cpuNanos(cpuCycles, this.mhz));
      this.redrawNeoPixelLeds(cpuCycles);
    }
    if (this.onBoardComponents.buzzerPin >= 8 && this.onBoardComponents.buzzerPin <= 16) {
      const buzzerValue = this.runner.portB.pinState(this.onBoardComponents.buzzerPin - 8);
      this.BuzzerController.feedValue(buzzerValue, cpuMicros(cpuCycles, this.mhz));
    }

    // console.log('=============updatecopmonent', portType, value.toString(2));
    const startPin = (portType === 'B') ? 8 : 0;

    for (let i = 0; i <= this.pinConnections.length; i += 1) {
      const pin = this.pinConnections[i] ? this.pinConnections[i].pin : null;
      if (pin !== null && ((portType !== 'C' && !pin.toString().includes('A')) || (portType === 'C' && pin.toString().includes('A')))) {
        let pinNumber = parseInt(pin, 10);
        if (pin.toString().includes('A') && startPin === 0) { // Check if portC
          pinNumber = parseInt(pin.toString()[1], 10);
          // console.log('=============', pin, pinNumber, portType);
        }
        if (pinNumber >= startPin && pinNumber <= startPin + 8) {
          const state = (value >> (pinNumber - startPin)) & 0x01;
          // const state = (value >> (pinNumber - startPin)) & ((1 << (pinNumber - startPin)) >> (pinNumber - startPin));
          if (pin.split(',').length > 1) {
            this.pinConnections[i].component.update(value);
          } else {
            this.pinConnections[i].component.update(state);
          }
          // this.pinConnections[i].value = this.pinConnections[i].component.getValue();
          // if (pinNumber === 0 || pinNumber === 3) {
          // console.log('=============', this.pinConnections[i].pin, this.pinConnections[i].value, pinNumber, value.toString(2), state);
          // }
          // console.log('=============', pinNumber, this.pinConnections[i].value);
        }
      }
    }
    // console.log('=============', value, startPin, this.pinConnections);
  }

  executeProgram = (hex, callback = () => { }) => {
    this.stopExecute();
    this.newPixelController = new WS2812Controller(2);
    this.BuzzerController = new BuzzerController();
    this.runner = new AVRRunner(hex);
    this.i2cBus = new I2CBus(this.runner.twi);
    this.cpuCycles = 0;
    this.setupRun = false;

    this.runner.portD.addListener((value) => {
      if (this.runner) {
        this.updateComponents(value, 'D', this.runner.cpu.cycles);
      }
    });

    this.runner.portC.addListener((value) => {
      if (this.runner) {
        this.updateComponents(value, 'C', this.runner.cpu.cycles);
      }
    });

    this.runner.portB.addListener((value) => {
      if (this.runner) {
        this.updateComponents(value, 'B', this.runner.cpu.cycles);
      }
    });

    this.runner.usart.onByteTransmit = (value) => {
      const char = String.fromCharCode(value);
      this.serialOutput += char;
      if (char === '\n') {
        console.log('[SERIAL MONITOR]: ', this.serialOutput);
        this.serialOutput = '';
      }
    };

    this.pinConnections.map((connection) => {
      if (connection.component && typeof connection.component?.runMicrosecond === 'function') {
        this.runner.addCPUEventMicrosecond({
          period: 1,
          eventCall: connection.component.runMicrosecond,
        });
      }
      return true;
    });
    if (typeof this.runMicrosecond === 'function') {
      this.runner.addCPUEventMicrosecond({
        period: 1,
        eventCall: this.runMicrosecond,
      });
    }

    // const cpuPerf = new CPUPerformance(this.runner.cpu, this.mhz);
    this.runner.execute((cpu) => {
      if (!this.setupRun) {
        this.runComponentSetup();
        this.setupRun = true;
      }
      const pinStates = this.getPinStates(cpu.cycles);
      // const speed = (cpuPerf.update() * 100).toFixed(0);
      callback({
        pinStates,
        simulationTime: cpu.cycles / this.mhz,
        // speed: `${speed}`,
      });
    });
  }

  runComponentSetup = () => {
    if (this.runner) {
      this.pinConnections.map((connection) => {
        if (connection.component && typeof connection.component?.runSetup === 'function') {
          connection.component.runSetup();
        }
        return true;
      });
    }
  }

  stopExecute = () => {
    if (this.runner) {
      this.BuzzerController.stop();
      this.runner.stop();
      this.runner = null;

      for (let i = 0; i <= this.pinConnections.length; i += 1) {
        if (this.pinConnections[i]) {
          this.pinConnections[i].component.reset();
        }
      }
      this.clearNeoPixelLeds();
    }
  }

  writeDigitalPin = (pin, pinState) => {
    if (this.runner) {
      let pinType = 'D';
      let portConfig = portDConfig.PIN;
      let runnerPort = null;
      let pinIndex = 0;
      let pinNumber = parseInt(pin, 10);
      let startPin = 0;
      if (pin.toString().includes('A')) { // Check if portC
        pinNumber = parseInt(pin.toString()[1], 10);
        pinType = 'C';
      }
      if (pinNumber >= 0 && pinNumber < 8) {
        if (pinType === 'C') {
          runnerPort = this.runner.portC;
          portConfig = portCConfig.PIN;
        } else {
          runnerPort = this.runner.portD;
          portConfig = portDConfig.PIN;
        }
        pinIndex = pinNumber;
      } else if (pinNumber <= 13) {
        runnerPort = this.runner.portB;
        pinType = 'B';
        portConfig = portBConfig.PIN;
        pinIndex = pinNumber - 8;
        startPin = 8;
      } else {
        return false;
      }
      if (runnerPort) {
        let newValue = 0;
        if (!pinState) {
          newValue = this.runner.cpu.data[portConfig] & ~(1 << pinIndex);
        } else {
          newValue = this.runner.cpu.data[portConfig] | (1 << pinIndex);
        }

        runnerPort.setPin(pinIndex, pinState);
        this.updateComponents(newValue, pinType, this.runner.cpu.cycles);
      }
    }
    return false;
  }

  writeAnalogPin = (pin, pinValue) => {
    if (this.runner) {
      // console.log('================analogue write', pin, pinValue);
      const pinNumber = parseInt(pin.toString()[1], 10);
      this.runner.adc.channelValues[pinNumber] = (pinValue * 5) / 1024; // turn analog value to voltage
      const newValue = this.runner.cpu.data[portCConfig.PIN];
      this.updateComponents(newValue, 'C', this.runner.cpu.cycles);
    }
  }

  render = () => (
    <educabot-buty
      id={this.id}
      rotation={this.rotation}
      ledLeft={this.onBoardComponents.ledLeft}
      ledLeftColor={this.onBoardComponents.ledLeftColor}
      ledRight={this.onBoardComponents.ledRight}
      ledRightColor={this.onBoardComponents.ledRightColor}
      buzzerOn={(this.BuzzerController) ? this.BuzzerController.isPlaying() : false}
    />
  );
}
