/* eslint-disable */
import * as Blockly from 'blockly';
import path from 'path';


class ConstantProvider extends Blockly.blockRendering.ConstantProvider {
  constructor() {
    super();
    this.NOTCH_HEIGHT = 12;
    this.NOTCH_WIDTH = 4;
    this.NOTCH_OFFSET_LEFT = 0;

    this.CORNER_RADIUS = 4;

    this.DUMMY_INPUT_SHADOW_MIN_WIDTH = 60;
    this.DUMMY_INPUT_SHADOW_MIN_HEIGHT = 52;
    this.DUMMY_INPUT_MIN_WIDTH = 60;
    this.DUMMY_INPUT_MIN_HEIGHT = 52;

    this.EMPTY_STATEMENT_INPUT_WIDTH = 24;

    this.EXTRA_HEIGHT_CUSTOM_BLOCKS = 20;

    this.FIELD_TEXT_FONTWEIGHT = 'bold';
    this.FIELD_BORDER_RECT_RADIUS = 16;
    this.FIELD_BORDER_RECT_RADIUS_TEXT = 6;
    this.FIELD_TEXT_HEIGHT = 32;
    this.FIELD_TEXT_BASELINE_CENTER = 32;
    this.FIELD_BORDER_RECT_RADIUS = 16;
    this.FIELD_BORDER_RECT_HEIGHT = 32;

    // const radius = this.MIN_BLOCK_HEIGHT / 2;
    // this.topLeftFullRadius =
    //   Blockly.utils.svgPaths.moveBy(0, radius) +
    //   Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(radius, -radius));
    // this.bottomLeftFullRadius =
    //   Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(-radius, -radius));
  }

  init() {
    super.init.call(this);
    this.NOTCH = this.makeNotch();
    this.INSIDE_CORNERS = this.makeInsideCorners();
  }

  shapeFor(connection) {
    switch (connection.type) {
      case Blockly.PREVIOUS_STATEMENT:
      case Blockly.NEXT_STATEMENT:
        return this.NOTCH;
      default:
        throw Error('Unknown connection type');
    }
  }

  makeOutsideCorners(aa) {
    const halfRadius = 33; //this.MIN_BLOCK_HEIGHT;
    const topLeftFullRadius =
      Blockly.utils.svgPaths.moveBy(0, halfRadius) +
      Blockly.utils.svgPaths.arc('a', '0 0,1', halfRadius, Blockly.utils.svgPaths.point(halfRadius, -halfRadius));
    const bottomLeftFullRadius =
      Blockly.utils.svgPaths.arc('a', '0 0,1', halfRadius, Blockly.utils.svgPaths.point(-halfRadius, -halfRadius));

    const radius = this.CORNER_RADIUS;

    const topLeft =
      Blockly.utils.svgPaths.moveBy(0, radius) +
      Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(radius, -radius));
    const topRight = Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(radius, radius));
    const bottomLeft = Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(-radius, -radius));
    const bottomRight = Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(-radius, radius));

    return {
      topLeft: topLeft,
      topRight: topRight,
      bottomRight: bottomRight,
      bottomLeft: bottomLeft,
      topLeftFullRadius: topLeftFullRadius,
      bottomLeftFullRadius: bottomLeftFullRadius,
      fullRadiusLength: halfRadius,
    };
  }

  makeInsideCorners() {
    const radius = this.CORNER_RADIUS;

    const innerTopLeft = Blockly.utils.svgPaths.arc('a', '0 0,0', radius, Blockly.utils.svgPaths.point(-radius, radius));
    const innerTopRight = Blockly.utils.svgPaths.arc('a', '0 0,0', radius, Blockly.utils.svgPaths.point(-radius, -radius));
    const innerBottomLeft = Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(-radius, radius));
    const innerBottomRight = Blockly.utils.svgPaths.arc('a', '0 0,1', radius, Blockly.utils.svgPaths.point(-radius, -radius));

    return {
      width: radius,
      height: radius,
      pathTopLeft: innerTopLeft,
      pathBottomLeft: innerBottomLeft,
      rightWidth: radius,
      rightHeight: radius,
      pathTopRight: innerTopRight,
      pathBottomRight: innerBottomRight,
    };
  }

  makeNotch() {
    const width = this.NOTCH_HEIGHT;
    const height = this.NOTCH_WIDTH;

    const innerWidth = width;
    const curveWidth = innerWidth / 3;

    const halfHeight = height;
    const quarterHeight = halfHeight / 2;

    function makeMainPath(dir) {
      return (
        Blockly.utils.svgPaths.curve('c', [
          Blockly.utils.svgPaths.point(0, (dir * curveWidth) / 2),
          Blockly.utils.svgPaths.point(quarterHeight / 2, (dir * curveWidth * 3) / 4),
          Blockly.utils.svgPaths.point(quarterHeight, dir * curveWidth),
        ]) +
        Blockly.utils.svgPaths.line([Blockly.utils.svgPaths.point(halfHeight, dir * curveWidth)]) +
        Blockly.utils.svgPaths.curve('c', [
          Blockly.utils.svgPaths.point(quarterHeight / 2, (dir * curveWidth) / 4),
          Blockly.utils.svgPaths.point(quarterHeight, (dir * curveWidth) / 2),
          Blockly.utils.svgPaths.point(quarterHeight, dir * curveWidth),
        ]) +
        Blockly.utils.svgPaths.lineOnAxis('v', dir * innerWidth) +
        Blockly.utils.svgPaths.curve('c', [
          Blockly.utils.svgPaths.point(0, (dir * curveWidth) / 2),
          Blockly.utils.svgPaths.point(-(quarterHeight / 2), (dir * curveWidth * 3) / 4),
          Blockly.utils.svgPaths.point(-quarterHeight, dir * curveWidth),
        ]) +
        Blockly.utils.svgPaths.line([Blockly.utils.svgPaths.point(-halfHeight, dir * curveWidth)]) +
        Blockly.utils.svgPaths.curve('c', [
          Blockly.utils.svgPaths.point(-(quarterHeight / 2), (dir * curveWidth) / 4),
          Blockly.utils.svgPaths.point(-quarterHeight, (dir * curveWidth) / 2),
          Blockly.utils.svgPaths.point(-quarterHeight, dir * curveWidth),
        ])
      );
    }

    const pathLeft = makeMainPath(-1);
    const pathRight = makeMainPath(1);

    return {
      type: this.SHAPES.NOTCH,
      width: this.NOTCH_WIDTH * 3,
      height: this.NOTCH_HEIGHT * 3,
      pathLeft,
      pathRight,
    };
  }
}

export default ConstantProvider;
