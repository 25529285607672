import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        type: 'microbit_initial_block',
        x: 10,
        y: 10,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <block type="microbit_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="microbit_repeat">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_button_pressed_2" />
      <block type="microbit_gesture_2" />
      <block type="microbit_chronometer" />
      <block type="microbit_chronometer_state" />
    </category>
    <category id="logic" categorystyle="logic_category" name="%{BKY_LANG_CATEGORY_LOGIC}">
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
    </category>
    <category id="math" categorystyle="math_category" name="%{BKY_LANG_CATEGORY_MATH}">
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="variables" categorystyle="variable_dynamic_category" name="%{BKY_LANG_CATEGORY_VARIABLES}" custom="VARIABLE_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>
    <category id="text" categorystyle="text_category" name="%{BKY_LANG_CATEGORY_TEXT}">
      <block type="text" />
      <block type="text_unir" />
      <block type="microbit_compare_strings">
        <value name="B">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="communication" categorystyle="communication_category" name="%{BKY_LANG_CATEGORY_COMMUNICATION}">
      <label text="%{BKY_LANG_CATEGORY_COMMUNICATION}" />
      <block type="serial_println">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="serial_print">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_bluetooth_read" />
      <block type="microbit_bluetooth_send">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_bluetooth_if" />
      <block type="microbit_bluetooth_control" />
    </category>
    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_ENTRADAS}">
      <category id="inputs_digital" categorystyle="digital_inputs_category" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}">
        
        <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
        <block type="microbit_button_logic" />
        <block type="microbit_logo" />

        <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
        <block type="ENTRADAS_button" />
        <block type="ENTRADAS_button_touch" />
        <block type="microbit_temperature_humidity_read" />
        <block type="microbit_ultrasonic_2" />
        <block type="ENTRADAS_obstaculo" />
        <block type="microbit_color_i2c" />
        <block type="microbit_color_i2c_get">
          <value name="COLOR">
            <shadow type="colour_bhoot">
              <field name="VAL">#ff0000</field>
            </shadow>
          </value>
        </block>
        <block type="microbit_line_follower_3" />
      </category>
      <category id="inputs_analogue" categorystyle="analogue_inputs_category" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}">
        <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
        <block type="microbit_internal_light_level" />
        <block type="microbit_internal_temperature" />
        <block type="microbit_internal_acceleration" />
        <block type="microbit_hall_effect_3" />
        <block type="microbit_internal_sound" />
        <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
        <block type="ENTRADAS_photoresistor" />
        <block type="microbit_soil_read_2" />
        <block type="ENTRADAS_potentiometer" />
        <block type="microbit_joystick" />
      </category>
    </category>
    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_SALIDAS}">
      <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
      <block type="microbit_led_2" />
      <block type="microbit_led_brillo_2">
        <value name="DATOIN">
          <shadow type="math_number_minmax_analog">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_rgb">
        <value name="COLOUR1">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR2">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR3">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR4">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR5">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR6">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_salidas_led_rgb">
        <value name="DATOINR">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOING">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOINB">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>

      <block type="BHOOT_piezo_buzzer" />
      <block type="BHOOT_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_play_melody_2" />
      <block type="BHOOT_piezo_buzzerno" />
    </category>
    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_LCD}">
      <block type="microbit_lcd_i2c_2">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_clear" />
      <block type="microbit_matrix8x8" />
      <block type="microbit_matrix8x8_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_matrix8x8_draw">
        <value name="POSX1">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
        <value name="POSY1">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
        <value name="POSX2">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">2</field>
          </shadow>
        </value>
        <value name="POSY2">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_matrix8x8_rotate" />
      <block type="microbit_matrix8x8_invert" />
      <block type="microbit_matrix8x8_clear" />
    </category>
    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_MOTORS}">
      <block type="microbit_servo_180_2">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_servo_move_millis">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
        <value name="millis">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_motor" />
      <block type="microbit_motor_doble" />
      <block type="microbit_motor_doble_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed_1023">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_motor_single" />
      <block type="microbit_motor_single_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed_1023">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
    </category>
  </xml>
);
/* eslint-enable */
