const { useRef, useEffect } = require('react');

export function useFile({ accept = [] }) {
  const input = useRef();

  useEffect(() => {
    input.current = document.createElement('input');
    input.current.setAttribute('type', 'file');
    input.current.reader = new FileReader();
    input.current.file = null;
    accept.forEach((extension) => {
      input.current.setAttribute('accept', extension);
    });
  }, []);

  const onLoadend = (callback) => {
    input.current.reader.onloadend = () => {
      callback(input.current.reader);
    };
  };

  const onChange = (callback) => {
    input.current.onchange = (event) => {
      input.current.file = event.target.files[0];
      callback(input.current.file, input.current.reader);
    };
  };

  const onClick = () => {
    input.current.value = '';
    input.current.click();
  };

  return {
    onClick,
    onChange,
    onLoadend,
  };
}
