import React, { Component } from 'react';
import { InfraredElement } from '../components/infrared/infrared';
import { rotatePort } from '../utils';

export class Infrared extends Component {
  constructor(hardware, pin, id = 'Infrared', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.pinState = 1;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'O', x: -3, y: 27, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 102,
      height: 54,
    };
    this.rotation = rotation;
    this.lastTimestamp = 0;
    this.ref = React.createRef();

    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.stateHandler = this.stateHandler.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runSetup = () => {
    if (this.ref.current) {
      this.pinState = this.ref.current.state?.color || 1;
      if (this.hardware && this.pin) {
        this.hardware.writeDigitalPin(this.pin, this.pinState);
      }
    }
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => this.pinState;

  update = (newPinState) => {
    // console.log('================update', this.ref.current);
  }

  stateHandler = (pinState) => {
    // console.log('===================', pinState);
    this.pinState = pinState;
    if (this.hardware && this.pin) {
      this.hardware.writeDigitalPin(this.pin, pinState);
    }
  }

  reset = () => {
    this.pinState = 1;
  }

  render = () => (
    <InfraredElement
      ref={this.ref}
      id={this.id}
      key={`Infrared-${this.pin}`}
      rotation={this.rotation}
      onClickHandler={this.stateHandler}
    />
  );
}
