/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint  no-param-reassign: 0 */
export const BHOOT_motor_single = (block, generator) => {
  let direccion1 = 0;
  let direccion2 = 0;

  const doublePin = block.getFieldValue('NPIN').split(',');
  const dropdown_pin1 = doublePin[0];
  const dropdown_pin2 = doublePin[1];

  switch (block.getFieldValue('DIR')) {
    default:
    case '3':
      direccion1 = (block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH';
      direccion2 = 'HIGH';
      break;
    case '4':
      direccion1 = (block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW';
      direccion2 = (block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH';
      break;
    case '6':
      direccion1 = 'LOW';
      direccion2 = 'LOW';
      break;
  }

  generator.setups_[`setup_motors_single_${dropdown_pin1}`] = `
pinMode(${dropdown_pin1}, OUTPUT);
pinMode(${dropdown_pin2}, OUTPUT);
`;

  const code = `
  digitalWrite(${dropdown_pin1}, ${direccion1});
  digitalWrite(${dropdown_pin2}, ${direccion2});
`;

  return code;
};