/* eslint lines-between-class-members: 0 */
/* eslint no-param-reassign: 0 */
/* eslint camelcase: 0 */
export const MATRIX8X8_ADDR = 0x70;

export class Matrix8x8Controller {
  updated = false;
  bitRegister = false;
  dataRegister = false;

  data = new Uint8Array(8);
  dataBuffer = new Uint8Array(8);
  dataAddr = 0;

  constructor(cpuMillis) {
    this.render();
  }

  update() {
    if (this.updated) {
      this.updated = false;
      return this.render();
    }

    return false;
  }

  render() {
    // this.data.forEach((element, i) => {
    //   console.log('======================render', i, element, element.toString(2));
    // });
    const result = ({ characters: this.data });

    return result;
  }

  backlightOn(value) {
    if (this.backlight !== value) {
      this.backlight = value;
    }
  }

  i2cConnect() {
    return true;
  }

  i2cDisconnect() { }

  i2cReadByte() {
    return 0xff;
  }

  i2cWriteByte(value) {
    // console.log('======================render', value, value.toString(2));
    this.writeData(value);
    this.updated = true;
    return this.updated;
  }

  writeData(value) {
    // Check register
    if (!this.dataRegister) {
      if (value === 0xEF) { // start of transmission
        // console.log('======================render', value, value.toString(2));
        this.dataRegister = true;
        this.bitRegister = false;
        this.data = new Uint8Array(8);
        this.dataBuffer = new Uint8Array(8);
        this.dataAddr = 0;
        return false;
      }
    } else if (!this.bitRegister) {
      if (value === 0) { // bit 0 before every data bit
        if (this.dataAddr < 8) {
          this.bitRegister = true;
        } else {
          this.dataAddr = 0;
        }
        return false;
      }
    } else {
      let data = 0;
      data = (value & ~0x80) << 1;
      data |= (value & 0x80) ? 1 : 0;

      // console.log('======================render', data.toString(2), value.toString(2));
      for (let i = 0; i < 7; i += 1) {
        this.dataBuffer[i] = this.dataBuffer[i + 1];
      }
      this.dataBuffer[7] = data;

      if (this.dataAddr === 7) {
        this.data = [...this.dataBuffer];
        this.dataBuffer = new Uint8Array(8);
      }

      this.bitRegister = false;
      this.dataAddr += 1;
    }

    this.updated = true;

    return true;
  }
}
