import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styles from './collabModals.mod.scss';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';
import Modal from '../../../resources/components/modals/modal'

const CollabModals = (props) => {
  const intl = useIntl();
  const message = props.message || '';
  const title = props.title || '';
  const image = props.image || '';
  const actionMessage = props.actionMessage || intl.formatMessage({ id: 'common.continue' });
  const id = (props.id) ? props.id : 'collaborationModal';
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive((props.active));
  }, [props.active]);

  const onActionClick = (event) => {
    if (props.action) {
      props.action(event);
    }
  };

  return (
    <Modal open={active}>
      <div id={id} className={`${styles.modalContent} ${(props.className) ? styles[props.className] : ''}`}>
        <div className={styles.modalBody}>
          {(image) ? (
            <img alt="" src={image} />
          ) : ('')}
          <h5>{title}</h5>
          <div className={styles.message}>{message}</div>
          <ButtonV2 onClick={onActionClick}>{actionMessage}</ButtonV2>
        </div>
      </div>
    </Modal>
  );
};

export default CollabModals;