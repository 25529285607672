import { Kit, CREABOTS_KIT_ID_TYPE, BOARD_ID_TYPE } from '@sections/creabots/types/kits';
import { automaticLamp } from './projects/automaticLamp';
import { intruderAlarm } from './projects/intruderAlarm';
import { automaticBarrier } from './projects/automaticBarrier';
import { classifier } from './projects/classifier';
import { automaticFan } from './projects/automaticFan';
import { smartAccess } from './projects/smartAccess';
import * as routes from '@educabot/educablocks-cosmos';

const automationKit: Kit = {
  id: CREABOTS_KIT_ID_TYPE.AUTOMATION,
  name: 'creabots.tutorials.automation.name',
  urlName: 'automatizacion',
  boardId: BOARD_ID_TYPE.AUTOMATION,
  imageUrl: `${routes.bloquesUri}/images/creabots/automation/packaging.png`,
  projects: [
    automaticLamp,
    intruderAlarm,
    automaticBarrier,
    automaticFan,
    classifier,
    smartAccess
  ],
};

export default automationKit;
