import React from 'react';
import { useGetProjectsBySeniority } from '@modules/projects/features/hooks';
import { useIsAuthenticated } from '@utils/hooks/auth';
import StoredProjectList from './storedProjectList';
import NoProjectsAvailable from './noProjectsAvailable';
import styles from './storedProjects.mod.scss';

function StoredProjects({ onStoredProjectClick }) {
  const userIsAuthenticated = useIsAuthenticated();
  const { projects } = useGetProjectsBySeniority();
  return (
    <div className={styles.container}>
      <div className={styles.projectsContainer}>
        {userIsAuthenticated
          ? (
            <StoredProjectList
              projects={projects}
              onStoredProjectClick={onStoredProjectClick}
            />
          )
          : <NoProjectsAvailable />}
      </div>
    </div>
  );
}

export default StoredProjects;
