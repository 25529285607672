import React, { useEffect, useState, useRef } from 'react';
import { Player, ControlBar } from 'video-react';
import { useIntl } from 'react-intl';
import './video-react.scss';
import styles from './introVideoModal.mod.scss';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';

const IntroVideoModal = (props) => {
  const intl = useIntl();
  const player = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [isFinished, setIsFinished] = useState(false);

  const onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    setVideoUrl('');
  }

  const handleStateChange = (state) => {
    if (state.duration - state.currentTime <= 0.5) {
      setIsFinished(true);
    }
  }


  useEffect(() => {
    setVideoUrl((props.videoUrl) ? props.videoUrl : '');
  }, [props.videoUrl]);

  useEffect(() => {
    if (player) {
      player.current.subscribeToStateChange(handleStateChange.bind(this));
    }
  }, []);

  return (
    <div className={styles.introVideoModal}>
      <div className={styles.body}>
        <div className={styles.video}>
          <Player
            ref={player}
            autoPlay
            width="100%"
            height="100%"
            fluid={false}
            preload="auto"
          >
            <source src={videoUrl} />
            <ControlBar autoHide={false} />
            <BigPlayButton position="center" />
          </Player>
        </div>
      </div>
      {(isFinished) && (
        <div className={styles.actions}>
          <ButtonV2 onClick={onClose}>{intl.formatMessage({ id: 'common.continue' })}</ButtonV2>
        </div>
      )}
    </div>
  );
};

export default IntroVideoModal;