import React, { Component } from 'react';
import './ledRGBStrip.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class LedRGBStripElement extends Component {
  constructor(props) {
    super(props);
    this.value = props.value || [false, false, false, false, false, false];
    this.color = props.color || ['', '', '', '', '', ''];
    this.state = {
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.value = newProps.value;
    this.color = newProps.color;
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    return (
      <div className="ledRGBStrip" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 255.46 101.13"
          width="41mm"
          height="17mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <defs>
            <style>
              {this.value.map((value, index) => (
                `.cls-led-color-${this.id}-${index} { fill:${this.color[index]}; opacity:0.7; }
                .cls-led-glow-${this.id}-${index} { ${value ? '' : 'display: none'} }`
              ))}
            </style>
          </defs>

          <filter id="ledFilter" x="-0.7" y="-0.7" height="2" width="3">
            <feGaussianBlur stdDeviation="8" />
          </filter>

          <g id="capa-led" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <line className="cls-2" x1="204.62" y1="56.82" x2="250.99" y2="56.82" />
              <path className="cls-5" d="m250.99,56.82c2.35,0,4.25-1.9,4.25-4.25,0,0,0,0,0,0V4.38c0-2.35-1.9-4.25-4.25-4.25,0,0,0,0,0,0H4.38C2.03.13.13,2.03.12,4.38v48.19c0,2.35,1.9,4.25,4.25,4.25h46.38c.8,0,1.45-.65,1.45-1.45v-2.78s0,0,0,0c0-2.5,2.03-4.53,4.54-4.53,2.5,0,4.53,2.03,4.53,4.54v2.81c0,.78.64,1.42,1.42,1.42h22.47c1.26,0,2.45.56,3.26,1.52l17.84,42.49h42.84l17.84-42.49c.81-.96,2-1.52,3.26-1.52h22.48c.78,0,1.42-.64,1.42-1.42h0s0-2.81,0-2.81c0-2.5,2.03-4.53,4.54-4.54s4.54,2.03,4.54,4.54v2.78c0,.8.65,1.45,1.45,1.45" />
              <polygon className="cls-7" points="66.81 41.22 66.81 56.98 60.24 56.98 60.24 51.82 60.24 51.82 60.24 51.49 60.1 50.84 59.84 50.23 59.45 49.68 59.2 49.45 58.97 49.23 58.43 48.88 57.83 48.64 57.19 48.52 56.86 48.52 56.81 48.52 56.47 48.52 55.81 48.65 55.18 48.9 54.62 49.26 54.38 49.49 54.15 49.71 53.78 50.24 53.52 50.82 53.39 51.44 53.39 51.76 53.39 56.98 46.88 56.98 46.88 41.22 51.18 37.13 62.51 37.13 66.81 41.22" />
              <polygon className="cls-7" points="208.29 41.21 208.29 56.97 201.72 56.97 201.72 51.81 201.72 51.81 201.72 51.48 201.58 50.83 201.31 50.22 200.93 49.67 200.68 49.44 200.45 49.22 199.91 48.87 199.31 48.64 198.67 48.52 198.34 48.52 198.28 48.52 197.95 48.52 197.28 48.64 196.66 48.89 196.1 49.25 195.86 49.48 195.62 49.7 195.25 50.23 195 50.81 194.87 51.43 194.87 51.75 194.87 56.97 188.36 56.97 188.36 41.21 192.66 37.12 203.99 37.12 208.29 41.21" />
              <path className="cls-7" d="m77.7,18.87v-.26h1.88v-.87h.23v1.13h-2.11Z" />
              <path className="cls-7" d="m78.82,16.17v.79h.76v-.88h.23v1.15h-2.11v-1.1h.23v.84h.67v-.79h.23Z" />
              <path className="cls-7" d="m77.73,15.43c-.03-.16-.04-.35-.04-.56,0-.38.09-.65.26-.82.17-.18.42-.29.76-.29s.63.1.82.29c.2.19.3.5.3.9,0,.19,0,.34-.02.48h-2.07Zm1.86-.26c.01-.07.02-.16.02-.27,0-.56-.33-.87-.89-.87-.5,0-.81.27-.81.82,0,.14.01.24.03.31h1.66Z" />
              <path className="cls-7" d="m78.03,12.75h0s.19.35.19.35l-.21.05-.24-.43v-.23h2.03v.26h-1.78Z" />
              <path className="cls-7" d="m110.08,18.87v-.26h1.88v-.87h.23v1.13h-2.11Z" />
              <path className="cls-7" d="m111.2,16.17v.79h.76v-.88h.23v1.15h-2.11v-1.1h.23v.84h.67v-.79h.23Z" />
              <path className="cls-7" d="m110.11,15.43c-.03-.16-.04-.35-.04-.56,0-.38.09-.65.26-.82.17-.18.42-.29.76-.29s.63.1.82.29c.2.19.3.5.3.9,0,.19,0,.34-.02.48h-2.07Zm1.86-.26c.01-.07.02-.16.02-.27,0-.56-.33-.87-.89-.87-.5,0-.81.27-.81.82,0,.14.01.24.03.31h1.66Z" />
              <path className="cls-7" d="m112.19,13.33h-.17l-.21-.21c-.49-.5-.76-.73-1.06-.73-.21,0-.4.1-.4.39,0,.18.09.33.17.42l-.19.08c-.12-.14-.21-.33-.21-.56,0-.42.3-.6.59-.6.38,0,.68.26,1.09.68l.15.16h0v-.88h.23v1.25Z" />
              <path className="cls-7" d="m174.69,18.87v-.26h1.88v-.87h.23v1.13h-2.11Z" />
              <path className="cls-7" d="m175.82,16.17v.79h.76v-.88h.23v1.15h-2.11v-1.1h.23v.84h.67v-.79h.23Z" />
              <path className="cls-7" d="m174.72,15.43c-.03-.16-.04-.35-.04-.56,0-.38.09-.65.26-.82.17-.18.42-.29.76-.29s.63.1.82.29c.2.19.3.5.3.9,0,.19,0,.34-.02.48h-2.07Zm1.86-.26c.01-.07.02-.16.02-.27,0-.56-.33-.87-.89-.87-.5,0-.81.27-.81.82,0,.14.01.24.03.31h1.66Z" />
              <path className="cls-7" d="m176.8,12.51h-.55v.91h-.18l-1.3-.88v-.29h1.26v-.27h.22v.27h.55v.25Zm-.77,0h-.68c-.11,0-.21,0-.32,0h0c.12.07.21.12.3.17l.69.48h0v-.64Z" />
              <path className="cls-7" d="m207.08,18.87v-.26h1.88v-.87h.23v1.13h-2.11Z" />
              <path className="cls-7" d="m208.2,16.17v.79h.76v-.88h.23v1.15h-2.11v-1.1h.23v.84h.67v-.79h.23Z" />
              <path className="cls-7" d="m207.1,15.43c-.03-.16-.04-.35-.04-.56,0-.38.09-.65.26-.82.17-.18.42-.29.76-.29s.63.1.82.29c.2.19.3.5.3.9,0,.19,0,.34-.02.48h-2.07Zm1.86-.26c.01-.07.02-.16.02-.27,0-.56-.33-.87-.89-.87-.5,0-.81.27-.81.82,0,.14.01.24.03.31h1.66Z" />
              <path className="cls-7" d="m207.38,12.16v.75l.52.08s-.01-.09-.01-.16c0-.15.03-.3.11-.42.09-.15.27-.28.52-.28.4,0,.69.31.69.73,0,.21-.06.4-.12.49l-.21-.07c.05-.08.11-.24.11-.42,0-.25-.17-.47-.44-.47-.26,0-.45.17-.45.56,0,.11.01.2.02.27l-.98-.13v-.94h.23Z" />
              <path className="cls-7" d="m45.53,18.87v-.26h1.88v-.87h.23v1.13h-2.11Z" />
              <path className="cls-7" d="m46.65,16.17v.79h.76v-.88h.23v1.15h-2.11v-1.1h.23v.84h.67v-.79h.23Z" />
              <path className="cls-7" d="m45.56,15.43c-.03-.16-.04-.35-.04-.56,0-.38.09-.65.26-.82.17-.18.42-.29.76-.29s.63.1.82.29c.2.19.3.5.3.9,0,.19,0,.34-.02.48h-2.07Zm1.86-.26c.01-.07.02-.16.02-.27,0-.56-.33-.87-.89-.87-.5,0-.81.27-.81.82,0,.14.01.24.03.31h1.66Z" />
              <path className="cls-7" d="m46.6,12.02c.69,0,1.07.25,1.07.68,0,.38-.37.64-1.04.65-.68,0-1.06-.28-1.06-.68s.38-.65,1.03-.65Zm.03,1.07c.53,0,.83-.16.83-.4,0-.27-.33-.4-.85-.4s-.83.12-.83.4c0,.23.29.4.85.4Z" />
              <path className="cls-7" d="m142.39,18.87v-.26h1.88v-.87h.23v1.13h-2.11Z" />
              <path className="cls-7" d="m143.51,16.17v.79h.76v-.88h.23v1.15h-2.11v-1.1h.23v.84h.67v-.79h.23Z" />
              <path className="cls-7" d="m142.41,15.43c-.03-.16-.04-.35-.04-.56,0-.38.09-.65.26-.82.17-.18.42-.29.76-.29s.63.1.82.29c.2.19.3.5.3.9,0,.19,0,.34-.02.48h-2.07Zm1.86-.26c.01-.07.02-.16.02-.27,0-.56-.33-.87-.89-.87-.5,0-.81.27-.81.82,0,.14.01.24.03.31h1.66Z" />
              <path className="cls-7" d="m144.18,13.27c.05-.08.13-.25.13-.44,0-.34-.23-.45-.39-.44-.28,0-.41.25-.41.51v.15h-.21v-.15c0-.19-.1-.44-.34-.44-.16,0-.31.1-.31.34,0,.16.07.31.13.39l-.2.07c-.08-.1-.16-.3-.16-.51,0-.39.24-.56.48-.56.21,0,.39.12.48.36h0c.05-.24.24-.44.52-.44.33,0,.61.24.61.72,0,.22-.07.41-.14.51l-.21-.07Z" />
              <path className="cls-7" d="m239.19,11.49h-.45l-.56-.56c-1.32-1.34-2.02-1.95-2.84-1.95-.55,0-1.06.26-1.06,1.04,0,.48.25.87.46,1.11l-.52.23c-.32-.36-.55-.88-.55-1.49,0-1.13.8-1.61,1.58-1.61,1,0,1.82.7,2.92,1.81l.4.42h.02v-2.36h.61v3.35Z" />
              <path className="cls-7" d="m234.44,5.13v.02l.51.91-.56.14-.64-1.15v-.61h5.44v.69h-4.75Z" />
              <g>
                <rect className="cls-7" x="20.61" y="43.06" width=".85" height=".85" />
                <rect className="cls-7" x="20.61" y="47.29" width=".85" height=".85" />
                <polygon className="cls-7" points="17.22 43.06 16.37 43.06 15.53 43.06 15.53 43.9 14.68 43.9 14.68 44.75 13.83 44.75 12.98 44.75 12.98 46.44 13.83 46.44 14.68 46.44 14.68 47.29 15.53 47.29 15.53 48.13 16.37 48.13 17.22 48.13 17.22 48.98 18.07 48.98 20.61 48.98 20.61 48.13 18.07 48.13 18.07 43.06 20.61 43.06 20.61 42.21 18.07 42.21 17.22 42.21 17.22 43.06" />
                <rect className="cls-7" x="21.46" y="43.9" width=".85" height="3.38" />
              </g>
              <rect className="cls-12" x="59.17" y="25.91" width="2.27" height="4.54" />
              <rect className="cls-12" x="64.01" y="25.91" width="2.27" height="4.54" />
              <rect className="cls-12" x="124.18" y="25.91" width="2.27" height="4.54" />
              <rect className="cls-12" x="129.11" y="25.91" width="2.27" height="4.54" />
              <rect className="cls-12" x="189.39" y="25.91" width="2.27" height="4.54" />
              <rect className="cls-12" x="194.37" y="25.91" width="2.27" height="4.54" />
              <rect className="cls-12" x="113.35" y="48.1" width="2.27" height="4.54" transform="translate(64.11 164.85) rotate(-90)" />
              <rect className="cls-12" x="140.42" y="48.1" width="2.27" height="4.54" transform="translate(91.18 191.92) rotate(-90)" />
              <rect className="cls-8" x="201.94" y="21.5" width="14.17" height="14.17" />
              <g id="ARC">
                <circle className={`cls-10 cls-led-color-${this.id}-5`} cx="209.02" cy="28.57" r="5.67" />
                <circle className={`cls-led-glow-${this.id}-5`} cx="209.02" cy="28.57" r="20" fill={this.color[5]} filter="url(#ledFilter)" />
              </g>
              <rect className="cls-8" x="169.49" y="21.48" width="14.17" height="14.17" />
              <g id="ARC-2" data-name="ARC">
                <circle className={`cls-10 cls-led-color-${this.id}-4`} cx="176.58" cy="28.57" r="5.67" />
                <circle className={`cls-led-glow-${this.id}-4`} cx="176.58" cy="28.57" r="20" fill={this.color[4]} filter="url(#ledFilter)" />
              </g>
              <rect className="cls-8" x="136.73" y="21.48" width="14.17" height="14.17" />
              <g id="ARC-3" data-name="ARC">
                <circle className={`cls-10 cls-led-color-${this.id}-3`} cx="143.82" cy="28.57" r="5.67" />
                <circle className={`cls-led-glow-${this.id}-3`} cx="143.82" cy="28.57" r="20" fill={this.color[3]} filter="url(#ledFilter)" />
              </g>
              <rect className="cls-8" x="104.31" y="21.33" width="14.17" height="14.17" />
              <g id="ARC-4" data-name="ARC">
                <circle className={`cls-10 cls-led-color-${this.id}-2`} cx="111.4" cy="28.57" r="5.67" />
                <circle className={`cls-led-glow-${this.id}-2`} cx="111.4" cy="28.57" r="20" fill={this.color[2]} filter="url(#ledFilter)" />
              </g>
              <rect className="cls-8" x="72.23" y="20.9" width="14.17" height="14.17" />
              <g id="ARC-5" data-name="ARC">
                <circle className={`cls-10 cls-led-color-${this.id}-1`} cx="79.32" cy="28.57" r="5.67" />
                <circle className={`cls-led-glow-${this.id}-1`} cx="79.32" cy="28.57" r="20" fill={this.color[1]} filter="url(#ledFilter)" />
              </g>
              <rect className="cls-8" x="39.57" y="21.16" width="14.17" height="14.17" />
              <g id="ARC-6" data-name="ARC">
                <circle className={`cls-10 cls-led-color-${this.id}-0`} cx="46.66" cy="28.57" r="5.67" />
                <circle className={`cls-led-glow-${this.id}-0`} cx="46.66" cy="28.57" r="20" fill={this.color[0]} filter="url(#ledFilter)" />
              </g>
              <rect className="cls-8" x="59.17" y="26.9" width="2.27" height="2.55" />
              <rect className="cls-8" x="64.01" y="26.9" width="2.27" height="2.55" />
              <rect className="cls-8" x="124.18" y="26.9" width="2.27" height="2.55" />
              <rect className="cls-8" x="129.11" y="26.9" width="2.27" height="2.55" />
              <rect className="cls-8" x="189.39" y="26.9" width="2.27" height="2.55" />
              <rect className="cls-8" x="194.37" y="26.9" width="2.27" height="2.55" />
              <rect className="cls-8" x="113.35" y="49.09" width="2.27" height="2.55" transform="translate(64.11 164.85) rotate(-90)" />
              <rect className="cls-8" x="140.42" y="49.09" width="2.27" height="2.55" transform="translate(91.18 191.92) rotate(-90)" />
              <g id="CIRCLE">
                <path className="cls-1" d="m23.15,28.64c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-2" data-name="CIRCLE">
                <path className="cls-1" d="m221.58,28.57c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g>
                <path className="cls-11" d="m104.46,72.17v-2.65.19s0-1.13,0-1.13h-.57c0,.07-.02.15-.04.22s-.07.13-.12.18-.12.1-.18.12-.14.03-.22.04c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22h-.57v1.13s0,2.27,0,2.27v1.13h.57c0-.31.25-.57.57-.57s.57.25.57.57h.57v-1.13s0,.19,0,.19Z" />
                <rect className="cls-6" x="102.19" y="69.52" width="2.27" height="2.65" />
              </g>
              <rect className="cls-3" x="110.74" y="57.11" width="34.58" height="42.52" />
              <path className="cls-7" d="m241.44,0h9.77c2.35,0,4.25,1.91,4.25,4.25v48.45c0,2.35-1.91,4.26-4.26,4.26h-9.77V0h0Z" />
              <g>
                <path className="cls-9" d="m245.65,42.62v-.54h3.91v-1.8h.47v2.34h-4.38Z" />
                <path className="cls-9" d="m247.97,37.49v1.64h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.64h.47Z" />
                <path className="cls-9" d="m245.71,36.6c-.05-.33-.09-.72-.09-1.15,0-.78.19-1.34.55-1.7.36-.37.86-.59,1.57-.59s1.3.21,1.7.6c.41.39.63,1.04.63,1.86,0,.39-.02.71-.05.99h-4.31Zm3.87-.54c.03-.14.03-.34.03-.55,0-1.16-.68-1.79-1.86-1.79-1.03,0-1.69.56-1.69,1.7,0,.28.03.49.06.64h3.46Z" />
                <path className="cls-9" d="m249.44,32.53c.11-.16.23-.45.23-.72,0-.4-.21-.59-.47-.59-.27,0-.42.16-.58.56-.2.54-.51.8-.89.8-.51,0-.92-.39-.92-1.04,0-.31.09-.57.2-.74l.42.14c-.08.12-.18.34-.18.62,0,.32.2.51.43.51.26,0,.38-.18.53-.57.21-.52.48-.79.95-.79.55,0,.94.41.94,1.13,0,.33-.08.64-.21.85l-.44-.14Z" />
                <path className="cls-9" d="m245.71,28.65c-.06-.27-.09-.67-.09-1.04,0-.58.11-.95.36-1.22.2-.21.49-.33.83-.33.58,0,.96.35,1.12.79h.02c.12-.32.43-.52.88-.62.61-.14,1.03-.24,1.2-.32v.56c-.12.07-.5.16-1.05.28-.6.12-.83.35-.85.84v.51h1.9v.54h-4.32Zm2-.54v-.56c0-.58-.33-.95-.83-.95-.57,0-.81.39-.82.97,0,.26.03.45.05.54h1.6Z" />
                <path className="cls-9" d="m249.83,22.07c.09.24.24.72.24,1.29,0,.64-.17,1.16-.58,1.57-.36.36-.95.59-1.63.59-1.31,0-2.26-.87-2.26-2.28,0-.49.11-.87.2-1.05l.46.13c-.1.22-.19.51-.19.93,0,1.02.66,1.69,1.76,1.69s1.77-.64,1.77-1.62c0-.36-.05-.6-.12-.72h-1.31v.85h-.46v-1.39h2.11Z" />
                <path className="cls-9" d="m245.71,21.24c-.05-.24-.09-.61-.09-.99,0-.54.1-.89.32-1.15.17-.22.43-.35.77-.35.42,0,.79.27.96.71h.01c.1-.4.45-.87,1.1-.87.38,0,.66.14.88.36.28.29.41.77.41,1.45,0,.37-.03.66-.05.84h-4.31Zm1.79-.54v-.49c0-.57-.31-.91-.73-.91-.51,0-.72.37-.72.92,0,.25.02.39.04.48h1.41Zm2.1,0c.02-.11.03-.26.03-.46,0-.56-.21-1.08-.85-1.08-.6,0-.84.49-.84,1.09v.45h1.67Z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
