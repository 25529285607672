/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
/* eslint prefer-template: 0 */
export const variables_get_dynamic = (block, generator) => {
  const code = generator.getVariableName(block.getFieldValue('VAR'));
  return [code, generator.ORDER_ATOMIC];
};

export const variables_set_dynamic = (block, generator) => {
  const name = generator.getVariableName(block.getFieldValue('VAR'));
  const num = generator.valueToCode(block, 'VALUE', generator.ORDER_ASSIGNMENT) || '0';

  return `${name} = ${num};
`;
};

export const variables_declare_global_dynamic = (block, generator) => {
  const name = generator.getVariableName(block.getFieldValue('VAR'));
  const type = block.getFieldValue('TYPE') || 'String';
  const value = generator.valueToCode(block, 'VALUE', generator.ORDER_ASSIGNMENT) || '0';

  generator.definitions_[`declare_var${name}`] = `${type} ${name};
`;
  generator.setups_[`define_var${name}`] = `${name}=${value};
`;

  return `//global_var_${name}
`;
};

export const variables_declare_local_dynamic = (block, generator) => {
  const name = generator.getVariableName(block.getFieldValue('VAR'));
  const type = block.getFieldValue('TYPE') || 'String';
  const value = generator.valueToCode(block, 'VALUE', generator.ORDER_ASSIGNMENT) || '0';

  return `${type} ${name}=${value};
`;
};

export const variables_declare_array_dynamic = (block, generator) => {
  const name = generator.getVariableName(block.getFieldValue('VAR'));
  const type = block.getFieldValue('TYPE') || 'String';
  const value = generator.valueToCode(block, 'VALUE', generator.ORDER_ASSIGNMENT) || '';

  generator.definitions_[`declare_var${name}`] = `${type} ${name}[${value}];
`;

  return `//global_array_${name}
`;
};

export const variables_set_array_dynamic = (block, generator) => {
  const name = generator.getVariableName(block.getFieldValue('VAR'));
  const index = generator.valueToCode(block, 'INDEX', generator.ORDER_ASSIGNMENT) || '';
  const value = generator.valueToCode(block, 'VALUE', generator.ORDER_ASSIGNMENT) || '';

  return `${name}[${index}] = ${value};
`;
};

export const variables_get_array_dynamic = (block, generator) => {
  const name = generator.getVariableName(block.getFieldValue('VAR'));
  const index = generator.valueToCode(block, 'INDEX', generator.ORDER_ASSIGNMENT) || '';

  return [`${name}[${index}]`, generator.ORDER_ATOMIC];
};
