import React from 'react';
import { useBoardsByGroups } from '@modules/boards/features/hooks';
import styles from './boardList.mod.scss';
import BoardGrid from './boardGrid';

function BoardList({ displayPageBack, showPageBack, onBoardItemClick }) {
  const { boards } = useBoardsByGroups();
  return (
    <div className={styles.container}>
      <div className={styles.boardGridContainer}>
        <BoardGrid
          boards={boards}
          displayPageBack={displayPageBack}
          showPageBack={showPageBack}
          onBoardItemClick={onBoardItemClick}
        />
      </div>
    </div>
  );
}

export default BoardList;
