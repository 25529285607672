import { styled, Typography } from '@mui/material';

export const TextHeadlineEmphasized = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.text.primary,
  letterSpacing: '-0.015rem',
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  fontFamily: theme.typography.fontFamily,
}));

//Titles
export const TextTitleEmphasized = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.75rem',
  letterSpacing: '-0.01rem',
  lineHeight: '2.25rem',
  color: theme.palette.common.black,
}));

export const TextTitleRegular = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.25rem',
  letterSpacing: '-0.015rem',
  lineHeight: '1.75rem',
  color: theme.palette.text.primary,
}));

export const TextTitleThreeEmphasized = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  fontSize: '1.25rem',
  letterSpacing: '-0.015rem',
  lineHeight: '1.75rem',
  color: theme.palette.text.primary,
}));

// Body
export const TextBodyEmphasized = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.primary,
}));

export const TextBodyRegular = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.secondary,
}));

export const TextBodySmallRegular = styled(Typography)(({ theme, itemType = 'info' }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  color: itemType === 'info' ? theme.palette.text.primary : theme.palette.error.main,
}));


export const TextBodySmallEmphasized = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  color: theme.palette.primary.main,
}));


// Caption
export const TextCaptionRegular = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
}));
