/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
// import { strict } from "assert";

const $ = window.$;

const dataTypes = [
  { value: 'String', name: 'VARIABLE_TYPE_STRING' },
  { value: 'int', name: 'VARIABLE_TYPE_INT' },
  { value: 'long', name: 'VARIABLE_TYPE_LONG' },
  { value: 'byte', name: 'VARIABLE_TYPE_BYTE' },
  { value: 'float', name: 'VARIABLE_TYPE_FLOAT' },
  { value: 'char', name: 'VARIABLE_TYPE_CHAR' },
];

export default {

  buildModal(event, msgs) {
    function drawItems() {
      let string = '';
      dataTypes.map((item) => {
        string += `<div class="box ${(event.returnType === item.value) ? 'active' : ''}" value="${item.value}">${msgs[item.name]}</div>`;
        return item;
      });
      return string;
    }

    return `
    <div class="popup-modal-parent create-variable-modal-parent create-function ${(event.number === 0) ? 'without-variables' : ''}">
      <div class="popup-modal-under direction-ltr">
        <div class="middle-container">
          <div class="white-back">
            <div class="input-custom">
              <input type="text" value="${event.oldText}" placeholder="${msgs.PROCEDURES_NAME}" id="create-variable-field">
              ${(event.oldText !== '') ? (
    `<span class="c-red">${msgs.PROCEDURES_ALREADY_EXISTS}</span>`
  ) : ''}
            </div>
            <div class="new-buttons new-attr-selector">
              <label>${msgs.PROCEDURES_PARAMETERS}</label>
              <div class="remove-one">-</div>
              <div class="number">${event.number}</div>
              <div class="add-one">+</div>
            </div>
            ${(event.returnType && event.type === 'procedures_defreturn') ? (
    `
              <div class="new-selector">
                <div class="box-content">
                  ${drawItems()}
                </div>
              </div>
              `
  ) : ''}
            <div class="new-buttons">
              <div class="create-new">${msgs.IOS_OK}</div>
              <div class="desist-new">${msgs.IOS_CANCEL}</div>
          </div>
        </div>
        <div class="white-back box-shadow-none" id="dynamic-content">
        </div>
      </div>
    </div>

    `;
  },

  js(event) {
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildModal(event, window.Blockly.Msg);
    window.Blockly.WidgetDiv.show(event, true);
    let functionName = event.oldText || '';
    let number = event.number || 0;
    const type = event.type;
    let returnType = event.returnType;
    let parameters = event.parameters;
    const hideParametersType = (typeof event.hideParametersType !== 'undefined') ? (event.hideParametersType) : false;

    $('.blocklyWidgetDiv').on('keyup', '#create-variable-field', (e) => {
      if (e.target.value.length > 0) {
        functionName = e.target.value;
        // $('.blocklyWidgetDiv .create-new').removeClass('disabled');
      } else {
        // $('.blocklyWidgetDiv .create-new').addClass('disabled');
      }
    });

    $('.blocklyWidgetDiv').on('click', '.create-new', () => {
      parameters = (number > 0) ? $('.blocklyWidgetDiv #dynamic-content .d-block').toArray().map((item) => {
        const newName = $(item).find('input').val();
        const newType = $(item).find('.select-type-dynamic').attr('value');
        return { name: newName, type: newType };
      }) : [];
      event.callBackFunction(functionName, number, type, returnType, parameters);

      $('.blocklyWidgetDiv').unbind('click');
      window.Blockly.WidgetDiv.hide();
    });

    $('.blocklyWidgetDiv').on('click', '.remove-one', () => {
      if (number > 0) number -= 1;
      parameters = (number > 0) ? $('.blocklyWidgetDiv #dynamic-content .d-block').toArray().map((item) => {
        const newName = $(item).find('input').val();
        const newType = $(item).find('.select-type-dynamic').attr('value');
        return { name: newName, type: newType };
      }) : [];
      $('.blocklyWidgetDiv .new-attr-selector .number').html(number);
      dynamicContent(number); // eslint-disable-line
      if (number === 0) $('.create-function').addClass('without-variables');
    });
    $('.blocklyWidgetDiv').on('click', '.add-one', () => {
      if (number < 6) number += 1;
      parameters = (number > 0) ? $('.blocklyWidgetDiv #dynamic-content .d-block').toArray().map((item) => {
        const newName = $(item).find('input').val();
        const newType = $(item).find('.select-type-dynamic').attr('value');
        return { name: newName, type: newType };
      }) : [];
      $('.blocklyWidgetDiv .new-attr-selector .number').html(number);
      dynamicContent(number);  // eslint-disable-line
      $('.create-function').removeClass('without-variables');
    });

    $('.blocklyWidgetDiv').on('click', '.desist-new', () => {
      $('.blocklyWidgetDiv').unbind('click');
      window.Blockly.WidgetDiv.hide();
    });

    if (event.type === 'procedures_defreturn') {
      $('.blocklyWidgetDiv').on('click', '.box', (e) => {
        $('.blocklyWidgetDiv .box').removeClass('active');
        $(e.target).addClass('active');
        returnType = $(e.target).attr('value');
      });
    }

    $('.blocklyWidgetDiv').on('click', '#dynamic-content .select-type-dynamic', (e) => {
      if (!$(e.currentTarget).hasClass('active')) $(e.currentTarget).addClass('active');
    });

    $('.blocklyWidgetDiv').on('click', '#dynamic-content .content-to-show .dynamic-select-box', (e) => {
      e.stopPropagation();
      e.preventDefault();
      $(e.currentTarget).closest('.select-type-dynamic').attr('value', $(e.currentTarget).attr('value'));
      $(e.currentTarget).closest('.select-type-dynamic').children('span').html(window.Blockly.Msg[$(e.currentTarget).attr('valuename')]);
      $(e.currentTarget).closest('.select-type-dynamic').removeClass('active');
    });

    function dynamicContent() {
      $('.blocklyWidgetDiv #dynamic-content').html('');
      for (let i = 0; i < number; i += 1) {
        let string = '';
        dataTypes.map((item) => {
          string += `<div class="dynamic-select-box" value="${item.value}" valuename="${item.name}">${window.Blockly.Msg[item.name]}</div>`;
          return item;
        });
        /* eslint-disable */
        $('.blocklyWidgetDiv #dynamic-content').append(`
          <div class="d-block">
            <div>
              <input type="text" value="${(parameters[i]) ? parameters[i].name : `var${i}`}" placeholder="${window.Blockly.Msg.PROCEDURES_NAME}">
            </div>
            ${(!hideParametersType) ?
              `<div class="select-type-dynamic" value="${(parameters[i]) ? parameters[i].type : 'String'}">
            <span>${(parameters[i]) ? window.Blockly.Msg[dataTypes.filter(dataI => dataI.value === parameters[i].type)[0].name] : window.Blockly.Msg[dataTypes[0].name]}</span>
                <div class="content-to-show">
                  ${string}
                </div>
              </div>`
              :
              ''
              }
          </div>
        `);
        /* eslint-enable */
      }
    }
    dynamicContent(number);
  },
};
