/* eslint no-restricted-syntax: 0 */
/* eslint no-prototype-builtins: 0 */
import React from 'react';
import './ArduinoFlow.scss';
import { FlowChart, actions } from '@mrblenny/react-flow-chart';
import { DefaultComponents } from './defaultFlowComponent';

class ArduinoFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.flowChart || {};
    this.handleUpdate = props.handleUpdate || null;
  }

  componentWillReceiveProps(newProps) {
    this.setState(newProps.flowChart || {});
  }

  mapValues(o, func) {
    const res = {};
    for (const key in o) {
      if (o.hasOwnProperty(key)) {
        res[key] = func(o[key]);
      }
    }
    return res;
  }

  stateActions = this.mapValues(actions, (func) => (...args) => this.setState(func(...args)))

  render() {
    const { flowChart, customNode } = this.props;

    if (this.handleUpdate) {
      this.handleUpdate(this.state);
    }
    return (
      <div className="arduinoFlow">
        {flowChart.nodes ? (
          <FlowChart
            chart={this.state}
            callbacks={this.stateActions}
            config={{ smartRouting: false, readonly: false, selectable: false }}
            Components={{
              ...DefaultComponents,
              Node: customNode,
            }}
          />
        ) : ''}
      </div>
    );
  }
}

export default ArduinoFlow;
