/**
 * I2CBus
 * Part of AVR8js
 *
 * Copyright (C) 2019, Uri Shaked
 */
/* eslint no-param-reassign: 0 */
/* eslint lines-between-class-members: 0 */
export class I2CBus {
  devices = {};
  activeDevice = null;
  writeMode = false;
  twi = null;

  constructor(twi) {
    this.twi = twi;
    this.twi.eventHandler = this;
  }

  registerDevice(addr, device) {
    this.devices[addr] = device;
    // console.log('===================registerDevice', this.devices);
  }

  start() {
    // console.log('===================i2c start');
    this.twi.completeStart();
  }

  stop() {
    // console.log('===================i2c stop');
    if (this.activeDevice) {
      this.activeDevice.i2cDisconnect();
      this.activeDevice = null;
    }
    this.twi.completeStop();
  }

  connectToSlave(addr, write) {
    let result = false;
    const device = this.devices[addr];
    if (device) {
      result = device.i2cConnect(addr, write);
      // console.log('===================connectToSlave', result, addr);
      if (result) {
        this.activeDevice = device;
        this.writeMode = write;
      }
    }
    this.twi.completeConnect(result);
  }

  writeByte(value) {
    // console.log('===================writeByte', value, value.toString(2));
    if (this.activeDevice && this.writeMode) {
      this.twi.completeWrite(this.activeDevice.i2cWriteByte(value));
    } else {
      this.twi.completeWrite(false);
    }
  }

  readByte(ack) {
    // console.log('===================readByte', ack, ack.toString(2));
    if (this.activeDevice && !this.writeMode) {
      this.twi.completeRead(this.activeDevice.i2cReadByte(ack));
    } else {
      this.twi.completeRead(0xff);
    }
  }
}
