import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionProjectsGetOne } from '../../../actions/projectsAction';

export const useProjects = () => useSelector((state) => state.projectsReducer);


export const useGetProject = (projectId) => {
  const dispatch = useDispatch();

  const projectsState = useSelector((state) => state.projectsReducer);
  useEffect(() => {
    if (projectId) {
      dispatch(actionProjectsGetOne(projectId));
    }
  }, [projectId]);

  return projectsState;
};
