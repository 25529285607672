/* eslint no-restricted-syntax: 0 */
/* eslint guard-for-in: 0 */
/* eslint no-await-in-loop: 0 */
/* eslint no-else-return: 0 */
import { useEffect, useState, useRef } from 'react'
import * as tf from '@tensorflow/tfjs-core';
import * as mpHands from '@mediapipe/hands';
import * as handdetection from '@tensorflow-models/hand-pose-detection';
import * as knnClassifier from '@tensorflow-models/knn-classifier';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import { STATE, MODEL_BACKEND_MAP, MEDIAPIPE_HANDS_CONFIG, BACKEND_FLAGS_MAP } from './machineLearning/params';
import { setBackendAndEnvFlags } from './machineLearning/util';
import { Camera } from './machineLearning/camera';

tfjsWasm.setWasmPaths(`https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`);


export default function useMachineLearning({ wrapperClass = 'canvas-wrapper', getPredictionCallback = null }) {
  const MLState = useRef(null)

  const normalize = (hand) => {
    return hand.map(obj => [obj.x, obj.y, obj.z]);
  }

  const classify = async () => {
    if (MLState.current.classifier.getNumClasses() > 0 && MLState.current.predictionCallback) {
      if (MLState.current.hands.length > 0) {
        const results = await MLState.current.classifier.predictClass(tf.tensor(normalize(MLState.current.hands[0].keypoints3D)));
        MLState.current.predictionCallback(results.confidences);
      } else {
        MLState.current.predictionCallback({});
      }
    }
  };

  const renderResult = async () => {
    const camera = MLState.current.camera;
    if (camera.video.readyState < 2) {
      await new Promise((resolve) => {
        camera.video.onloadeddata = () => {
          // resolve(video);
          resolve(true);
        };
      });
    }
    MLState.current.hands = null;

    // Detector can be null if initialization failed (for example when loading
    // from a URL that does not exist).
    if (MLState.current.detector != null) {
      // Detectors can throw errors, for example when using custom URLs that
      // contain a model that doesn't provide the expected output.
      try {
        MLState.current.hands = await MLState.current.detector.estimateHands(camera.video, { flipHorizontal: false });
        if (MLState.current.classify) {
          await classify();
        }
      } catch (error) {
        MLState.current.detector.dispose();
        MLState.current.detector = null;
        console.error('================', error);
      }
    }

    camera.drawCtx();

    // The null check makes sure the UI is not in the middle of changing to a
    // different model. If during model change, the result is from an old model,
    // which shouldn't be rendered.
    if (MLState.current.hands && MLState.current.hands.length > 0 && MLState.current.state.drawEstimation) {
      camera.drawResults(MLState.current.hands);
    }
  };

  const renderPrediction = async () => {
    // await this.checkGuiUpdate();
    await renderResult();
    MLState.current.rafId = requestAnimationFrame(renderPrediction);
    return null;
  }

  const initDefaultValueMap = async () => {
    const newState = { ...STATE };
    newState.model = handdetection.SupportedModels.MediaPipeHands;
    const backends = MODEL_BACKEND_MAP[newState.model];
    newState.backend = backends[0];
    newState.modelConfig = MEDIAPIPE_HANDS_CONFIG;
    newState.modelConfig.type = 'full';
    newState.modelConfig.maxNumHands = 1;

    // Clean up the cache to query tunable flags' default values.
    const TUNABLE_FLAG_DEFAULT_VALUE_MAP = {};
    newState.flags = {};
    for (let index = 0; index < BACKEND_FLAGS_MAP[newState.backend].length; index += 1) {
      const flag = BACKEND_FLAGS_MAP[newState.backend][index];
      TUNABLE_FLAG_DEFAULT_VALUE_MAP[flag] = await tf.env().getAsync(flag);
    }

    // Initialize newState.flags with tunable flags' default values.
    for (const flag in TUNABLE_FLAG_DEFAULT_VALUE_MAP) {
      if (BACKEND_FLAGS_MAP[newState.backend].indexOf(flag) > -1) {
        newState.flags[flag] = TUNABLE_FLAG_DEFAULT_VALUE_MAP[flag];
      }
    }

    MLState.current.state = newState;
  };

  const createDetector = async () => {
    const state = MLState.current.state;
    const runtime = state.backend.split('-')[0];
    switch (state.model) {
      default:
      case handdetection.SupportedModels.MediaPipeHands:
        if (runtime === 'mediapipe') {
          return handdetection.createDetector(state.model, {
            runtime,
            modelType: state.modelConfig.type,
            maxHands: state.modelConfig.maxNumHands,
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/hands@${mpHands.VERSION}`,
          });
        } else if (runtime === 'tfjs') {
          return handdetection.createDetector(state.model, {
            runtime,
            modelType: state.modelConfig.type,
            maxHands: state.modelConfig.maxNumHands,
          });
        }
    }
    return null;
  };

  const startVideo = async () => {
    MLState.current.detector = await createDetector();
  }

  const stopVideo = () => {
    window.cancelAnimationFrame(MLState.current.rafId);
    MLState.current.rafId = null;

    if (MLState.current.detector != null) {
      MLState.current.detector.dispose();
      MLState.current.detector = null;
    }

    if (MLState.current.camera != null) {
      MLState.current.camera.video.srcObject.getVideoTracks().forEach((track) => track.stop());
      MLState.current.camera.clearCtx();
    }

    if (MLState.current.predictionCallback && MLState.current.classify) {
      MLState.current.predictionCallback({});
    }
  };

  const app = async () => {
    await initDefaultValueMap();
    MLState.current.camera = await Camera.setupCamera(MLState.current.state.camera, MLState.current.wrapper);
    await setBackendAndEnvFlags(MLState.current.state.flags, MLState.current.state.backend);
    startVideo();
    renderPrediction();
  };

  const askForCameraPermission = async () => {
    MLState.current.camera = await Camera.setupCamera(MLState.current.state.camera, MLState.current.wrapper);
    startVideo();
    renderPrediction();
  }

  const clearAllSamples = () => {
    MLState.current.classifier.clearAllClasses();
    MLState.current.classify = false;
  }

  const startClassifying = () => {
    MLState.current.classify = true;
  }

  const addDataset = async (jsonifiedModel) => {
    await tf.ready();
    const convertedDataset = Object.entries(jsonifiedModel).reduce((dataset, [label, values]) => {
      return { ...dataset, [label]: tf.tensor(values) };
    }, {});
    MLState.current.classifier.setClassifierDataset(convertedDataset);
  }

  const addSample = async (className) => {
    if (MLState.current.detector && MLState.current.hands && MLState.current.hands.length > 0 && className) {
      await tf.ready();
      const tensors = tf.tensor(normalize(MLState.current.hands[0].keypoints3D));
      MLState.current.classifier.addExample(tensors, className);
    }
    const counts = MLState.current.classifier.getClassExampleCount();
    return counts[className] || 0;
  }

  useEffect(() => {
    MLState.current = {
      classifier: knnClassifier.create(),
      classify: false,
      camera: null,
      detector: null,
      rafId: null,
      state: null,
      wrapper: wrapperClass,
      hands: null,
      predictionCallback: getPredictionCallback,
    }

    return () => {
      stopVideo();
    }
  }, []);

  return { app, stopVideo, clearAllSamples, addSample, startClassifying, askForCameraPermission, addDataset };
}
