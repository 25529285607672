import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="createc_obelisco_initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="controls_if_no_else" />
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="Entradas" name="%{BKY_LANG_CATEGORY_COMPARISON}" iconclass="inputsDigi" expandedclass="inputsDigi-extended">
      <label text="%{BKY_LANG_CATEGORY_COMPARISON}" />
      <block type="createc_logic_compare_ldr">
        <value name="A">
          <block type="ovelisco_createc_ciudad_ldr" />
        </value>
      </block>
    </category>

    <category id="Salidas" name="%{BKY_LANG_CATEGORY_LIGHTNING}" iconclass="outputs" expandedclass="outputs-extended">
      <label text="%{BKY_LANG_CATEGORY_LIGHTNING}" />
      <block type="obelisco_createc_restore_electricity" />
      <block type="createc_obelisco_luminaria_zona5" />
      <block type="createc_colon_lights" />
      <block type="createc_colon_lights_off" />
      <block type="createc_obelisco_lights">
        <value name="COLOUR">
          <shadow type="colour_createc_number">
            <field name="COLOUR">#00ff00</field>
          </shadow>
        </value>
      </block>
      <block type="createc_obelisco_lights_off" />
    </category>

    <category id="Pantallas" name="%{BKY_LANG_CATEGORY_LCD}" iconclass="screens" expandedclass="screens-extended">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="createc_obelisco_screen" />
    </category>

  </React.Fragment>
);
/* eslint-enable */
