import React, { Component } from 'react';
import { UltrasonicButyElement } from '../components/ultrasonicButy/ultrasonicButy';
import { rotatePort, cpuMicros } from '../utils';

export class UltrasonicButy extends Component {
  constructor(hardware, pin, id = 'ultrasonicButy', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'A', x: 42, y: 110, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 83,
      height: 110,
    };
    this.rotation = rotation;

    this.distance = 0;
    this.isTriggered = false;
    this.startingTimeOfTrigger = 0;
    this.startingTimeOfEcho = 0;
    this.startingCpuCyclesOfPulse = -1;
    this.triggerPin = null;
    this.triggerPinState = false;
    this.triggerPinNumber = 0;
    this.echoPin = null;
    this.echoPinState = false;

    this.ref = React.createRef();

    this.mapPins();

    this.mapPins = this.mapPins.bind(this);
    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  mapPins = () => {
    const pinInfo = this.hardware.getPinInfo(this.pin);
    const pins = this.pin.split(',');
    pins.map((pin, index) => {
      if (pinInfo.signals?.[index][0]?.type === 'trigger') {
        this.triggerPin = pin;

        let startPin = 0;
        let pinNumber = parseInt(pin, 10);
        if (pin.toString().includes('A')) {
          pinNumber = parseInt(pin.toString()[1], 10);
        }
        if (pinNumber >= 8) {
          startPin = 8;
        }
        this.triggerPinNumber = pinNumber - startPin;
      } else {
        this.echoPin = pin;
      }
      return true;
    });
  }

  runMicrosecond = (cpuCycles) => {
    if (this.hardware && this.echoPin) {
      this.hardware.writeDigitalPin(this.echoPin, this.getEchoPinState(cpuCycles));
    }
  }

  getEchoPinState = (cpuCycles) => {
    const cpuMicroseconds = cpuMicros(cpuCycles, this.hardware.mhz);
    if (this.echoPinState) {
      // const targetDuration = (this.distance * 2) / 0.0343;
      const targetDuration = (this.distance * 292 * 2) / 10;
      const pulseDuration = cpuMicroseconds - this.startingTimeOfEcho;
      if (pulseDuration >= targetDuration) {
        // flip the trigger down
        this.echoPinState = false;
      }
    } else if (this.isTriggered && cpuMicroseconds > this.startingTimeOfTrigger + 30000) {
      // wait few milliseconds after the trigger for the echos to be sent
      // which gives enough time for pulseIn to get called, as it waits for the moment it turns HIGH
      // if the flip is immidiate, pulseIn will keep on waiting for the pin to go LOW and then HIGH or until it times out
      this.echoPinState = true;
      this.startingTimeOfEcho = cpuMicroseconds;
      this.isTriggered = false;
    }
    return this.echoPinState;
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => (this.distance);

  update = (pinState) => {
    const state = (pinState >> this.triggerPinNumber) & 0x01;
    if (state !== this.triggerPinState) {
      const cpuCycles = this.hardware.runner.cpu.cycles;
      if (!state) {
        this.startingCpuCyclesOfPulse = cpuCycles;
      } else {
        this.distance = this.ref.current?.state?.distance || 0;
        const widthOfLastPulse = cpuMicros((cpuCycles - this.startingCpuCyclesOfPulse), this.hardware.mhz);
        if (widthOfLastPulse >= 4 && widthOfLastPulse <= 12) {
          // 10 micros to trigger the echo + 10 error
          if (!this.echoPinState) {
            this.isTriggered = true;
            this.startingTimeOfTrigger = cpuMicros(cpuCycles, this.hardware.mhz);
          }
        }
      }
    }
    this.triggerPinState = state;
  }

  reset = () => {
    this.distance = 0;
    this.isTriggered = false;
    this.startingTimeOfTrigger = 0;
    this.startingTimeOfEcho = 0;
    this.startingCpuCyclesOfPulse = -1;
    this.triggerPin = null;
    this.triggerPinState = false;
    this.echoPin = null;
    this.echoPinState = false;
  }

  render = () => (
    <UltrasonicButyElement
      ref={this.ref}
      key={`ultrasonicButy-${this.pin}`}
      id={this.id}
      rotation={this.rotation}
    />
  );
}
