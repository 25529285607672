import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { formatRelative, parseISO } from 'date-fns';
import { es } from 'date-fns/esm/locale';
import { isElectron } from '@utils';
import { useBoards } from '@modules/boards/features/hooks';
import { getBoardCompanyColors } from '@utils/theme';
import Text from '@components/text';
import LabelChip from '@components/chips/labelChip';
import { useTheme } from '@utils/hooks/theme';
import { useOnClickOutside } from '@utils/hooks/general';
import { addTabInfoToProjects } from '@modules/projects/features/operations';
import styles from './storedProjectList.mod.scss';

function StoredProjectList({ onStoredProjectClick, projects }) {
  const intl = useIntl();
  const theme = useTheme();
  const { boards } = useBoards();
  const [projectSelectedId, setProjectSelectedId] = useState(-1);
  const [projectItemMouseOverdId, setprojectItemMouseOverdId] = useState(-1);
  const ref = useOnClickOutside(() => {
    setProjectSelectedId(-1);
  });

  const onProjectItemClick = (key) => {
    onStoredProjectClick(key, false, addTabInfoToProjects(projects));
    setProjectSelectedId(key);
  };

  const onProjectItemMouseOver = (key) => {
    setprojectItemMouseOverdId(key);
  };

  const onProjectItemMouseLeave = () => {
    setprojectItemMouseOverdId(-1);
  };

  return (
    <div className={styles.container}>
      {projects.map((project, key) => {
        const boardId = project.jsonBody.board.id || 0;
        const isSelected = key === projectSelectedId;
        const isMouseOver = key === projectItemMouseOverdId;
        const isSelectOrIsHover = isSelected || isMouseOver;
        const projectBoard = boards.find((board) => board.id === boardId) || boards[0];
        const boardCompanyNameLower = projectBoard.company.toLocaleLowerCase();
        const absoluteBoardImagesUrl = `images/profiles/${projectBoard.image}`;
        const boardImageUrl = isElectron() ? `./${absoluteBoardImagesUrl}` : `/${absoluteBoardImagesUrl}`;
        const projectLastUpdated = parseISO(project.updatedAt);
        return (
          <div
            ref={ref}
            key={project.id}
            className={isSelected
              ? styles.projectContainerSelected
              : styles.projectContainer}
            onClick={() => onProjectItemClick(key)}
            onMouseOver={() => onProjectItemMouseOver(key)}
            onMouseLeave={() => onProjectItemMouseLeave()}
            style={{ background: isSelectOrIsHover
              ? getBoardCompanyColors(theme, boardCompanyNameLower)
              : '' }}
          >
            <div className={styles.projectWrapper}>
              <div className={styles.projectImageCotaniner}>
                <div className={styles.projectImageWrapper}>
                  <img src={boardImageUrl} alt="board_image" />
                </div>
              </div>
              <div className={styles.projectDetailsContainer}>
                <div className={styles.projectDetailsWrapper}>
                  <div className={styles.projectBoardContainer}>
                    <div className={styles.projectBoardWrapper}>
                      <div className={styles.boardNameContainer}>
                        <Text className={styles.boardName}>
                          {projectBoard.name}
                        </Text>
                      </div>
                      <div className={styles.boardCompanyContainer}>
                        <LabelChip
                          classNames={{
                            container: isSelectOrIsHover
                              ? styles.boardCompanyChipOver
                              : styles.boardCompanyChip,
                            label: isSelectOrIsHover
                              ? styles.boardCompanyChipLabel
                              : '',
                          }}
                          color={isSelectOrIsHover
                            ? 'black'
                            : getBoardCompanyColors(theme, boardCompanyNameLower)}
                          label={projectBoard.company}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.projectTitleContainer}>
                    <Text className={styles.projectTitle}>
                      {project.title}
                    </Text>
                  </div>
                </div>
              </div>
              <div className={styles.projectMetadataContainer}>
                <Text className={styles.projectDateMessage}>
                  {intl.formatMessage({ id: 'blocks.profileSelector.project.load.lastModification' })}
                </Text>
                <Text className={styles.projectDate}>
                  {formatRelative(projectLastUpdated, new Date(), { locale: es })}
                </Text>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default StoredProjectList;
