/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint-disable */
import * as Blockly from 'blockly';
import colors from '../../utils/colors';
import createRenameModal from './createRenameModal';
import './customFieldVariable';

export const VariablesDynamicEducabot = {};

Blockly.defineBlocksWithJsonArray(
  [
    {
      type: "variables_get_dynamic",
      message0: "%1",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR",
          variable: "%{BKY_VARIABLES_DEFAULT_NAME}"
        }
      ],
      output: null,
      outputShape: Blockly.OUTPUT_SHAPE_ROUND,
      colour: colors.COLOUR_VARIABLES,
      helpUrl: "%{BKY_VARIABLES_GET_HELPURL}",
      tooltip: "%{BKY_VARIABLES_GET_TOOLTIP}",
    },
    {
      type: "variables_set_dynamic",
      message0: "%{BKY_VARIABLE_STATEMENT} %1 %2",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR",
          variable: "%{BKY_VARIABLES_DEFAULT_NAME}"
        },
        {
          type: "input_value",
          name: "VALUE"
        }
      ],
      previousStatement: null,
      nextStatement: null,
      colour: colors.COLOUR_VARIABLES,
      tooltip: "%{BKY_VARIABLES_SET_TOOLTIP}",
      helpUrl: "%{BKY_VARIABLES_SET_HELPURL}",
    },
    {
      type: "variables_declare_global_dynamic",
      message0: "%{BKY_NEW_GLOBAL_VARIABLE} %1 %{BKY_NEW_GLOBAL_VARIABLE_TYPE} %2 = %3",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR",
          variable: ""
        },
        {
          type: "field_dropdown",
          name: "TYPE",
          options: [["%{BKY_VARIABLE_TYPE_STRING}", "String"], ["%{BKY_VARIABLE_TYPE_INT}", "int"], ["%{BKY_VARIABLE_TYPE_LONG}", "long"], ["%{BKY_VARIABLE_TYPE_BYTE}", "byte"], ["%{BKY_VARIABLE_TYPE_FLOAT}", "float"], ["%{BKY_VARIABLE_TYPE_CHAR}", "char"]]
        },
        {
          type: "input_value",
          name: "VALUE"
        }
      ],
      previousStatement: null,
      nextStatement: null,
      colour: colors.COLOUR_VARIABLES,
      tooltip: "%{BKY_VARIABLES_SET_TOOLTIP}",
      helpUrl: "%{BKY_VARIABLES_SET_HELPURL}",
    },
    {
      type: "variables_declare_local_dynamic",
      message0: "%{BKY_NEW_LOCAL_VARIABLE} %1 %{BKY_NEW_LOCAL_VARIABLE_TYPE} %2 = %3",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR", variable: ""
        },
        {
          type: "field_dropdown",
          name: "TYPE",
          options: [["%{BKY_VARIABLE_TYPE_STRING}", "String"], ["%{BKY_VARIABLE_TYPE_INT}", "int"], ["%{BKY_VARIABLE_TYPE_LONG}", "long"], ["%{BKY_VARIABLE_TYPE_BYTE}", "byte"], ["%{BKY_VARIABLE_TYPE_FLOAT}", "float"], ["%{BKY_VARIABLE_TYPE_CHAR}", "char"]]
        },
        {
          type: "input_value",
          name: "VALUE"
        }
      ],
      previousStatement: null,
      nextStatement: null,
      colour: colors.COLOUR_VARIABLES,
      tooltip: "%{BKY_VARIABLES_SET_TOOLTIP}",
      helpUrl: "%{BKY_VARIABLES_SET_HELPURL}",
    },
    {
      type: "variables_declare_array_dynamic",
      message0: "%{BKY_NEW_ARRAY_VARIABLE} %1 %{BKY_NEW_ARRAY_VARIABLE_TYPE} %2 [%3]",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR",
          variable: ""
        },
        {
          type: "field_dropdown",
          name: "TYPE",
          options: [["%{BKY_VARIABLE_TYPE_STRING}", "String"], ["%{BKY_VARIABLE_TYPE_INT}", "int"], ["%{BKY_VARIABLE_TYPE_LONG}", "long"], ["%{BKY_VARIABLE_TYPE_BYTE}", "byte"], ["%{BKY_VARIABLE_TYPE_FLOAT}", "float"], ["%{BKY_VARIABLE_TYPE_CHAR}", "char"]]
        },
        {
          type: "input_value",
          name: "VALUE"
        }
      ],
      previousStatement: null,
      nextStatement: null,
      colour: colors.COLOUR_VARIABLES,
      tooltip: "%{BKY_VARIABLES_SET_TOOLTIP}",
      helpUrl: "%{BKY_VARIABLES_SET_HELPURL}",
    },
    {
      type: "variables_set_array_dynamic",
      message0: "%{BKY_ARRAY_STATEMENT} %1 [%2] = %3",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR",
          variable: ""
        },
        {
          type: "input_value",
          name: "INDEX"
        },
        {
          type: "input_value",
          name: "VALUE"
        },
      ],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: colors.COLOUR_VARIABLES,
      tooltip: "%{BKY_VARIABLES_SET_TOOLTIP}",
      helpUrl: "%{BKY_VARIABLES_SET_HELPURL}",
    },
    {
      type: "variables_get_array_dynamic",
      message0: "%1 [%2]",
      args0: [
        {
          type: "custom_field_variable",
          name: "VAR",
          variable: ""
        },
        {
          type: "input_value",
          name: "INDEX"
        }
      ],
      output: null,
      outputShape: Blockly.OUTPUT_SHAPE_ROUND,
      colour: colors.COLOUR_VARIABLES,
      helpUrl: "%{BKY_VARIABLES_GET_HELPURL}",
      tooltip: "%{BKY_VARIABLES_GET_TOOLTIP}",
    },
  ]
);


VariablesDynamicEducabot.onCreateVariableButtonClick_String = (button) => {
  createModalVariableButtonHandler(button.getTargetWorkspace(), null, "String");
};

VariablesDynamicEducabot.flyoutCategory = function (a) {
  var b = [], c = document.createElement("button");
  c.setAttribute("text", Blockly.Msg.NEW_VARIABLE);
  c.setAttribute("callbackKey", "CREATE_VARIABLE_STRING");
  b.push(c);
  a.registerButtonCallback("CREATE_VARIABLE_STRING", VariablesDynamicEducabot.onCreateVariableButtonClick_String);
  a = VariablesDynamicEducabot.flyoutCategoryBlocks(a);
  return b = b.concat(a)
};

VariablesDynamicEducabot.flyoutCategoryBlocks = (workspace) => {
  const variableModelList = workspace.getAllVariables();

  const xmlList = [];
  if (variableModelList.length > 0) {
    variableModelList.sort(Blockly.VariableModel.compareByName);
    if (Blockly.Blocks['variables_set_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', 'variables_set_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Blockly.Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }
    if (Blockly.Blocks['variables_declare_global_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', 'variables_declare_global_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Blockly.Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }
    if (Blockly.Blocks['variables_declare_local_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', 'variables_declare_local_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Blockly.Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }

    if (Blockly.Blocks['variables_get_dynamic']) {
      for (let i = 0, variable; (variable = variableModelList[i]); i++) {
        const block = Blockly.utils.xml.createElement('block');
        block.setAttribute('type', 'variables_get_dynamic');
        block.setAttribute('gap', '24');
        block.appendChild(Blockly.Variables.generateVariableFieldDom(variable));
        xmlList.push(block);
      }
    }
    if (Blockly.Blocks['variables_declare_array_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', 'variables_declare_array_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Blockly.Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }
    if (Blockly.Blocks['variables_set_array_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', 'variables_set_array_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Blockly.Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }
    if (Blockly.Blocks['variables_get_array_dynamic']) {
      const firstVariable = variableModelList[variableModelList.length - 1];
      const block = Blockly.utils.xml.createElement('block');
      block.setAttribute('type', 'variables_get_array_dynamic');
      block.setAttribute('gap', '24');
      block.appendChild(Blockly.Variables.generateVariableFieldDom(firstVariable));
      xmlList.push(block);
    }
  }
  return xmlList;
};


const createModalVariableButtonHandler = (workspace, opt_callback, opt_type) => {
  const type = opt_type || '';
  // This function needs to be named so it can be called recursively.
  const promptAndCheckWithAlert = (text) => {
    if (text) {
      const existing = Blockly.Variables.nameUsedWithAnyType(text, workspace);
      const existingFunction = Blockly.Procedures.getDefinition(text, workspace);
      if (existing || existingFunction) {
        return { error: 'VARIABLE_ALREADY_EXISTS_NONAME' };
      } else {
        workspace.createVariable(text, type);
        return { error: false };
      }
    }
    return { error: 'VARIABLE_NAME_EMPTY' };
  };

  createRenameModal.js(workspace, '', promptAndCheckWithAlert);
};
