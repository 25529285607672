import { remoteSessionsService } from '@services/rest/remoteSessions';
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from '@utils/redux';

const GET_ALL_REMOTE_SESSIONS = 'REMOTE_SESSIONS/GET_ALL';
const GET_ALL_REMOTE_SESSIONS_FAIL = 'REMOTE_SESSIONS/GET_ALL_FAIL';
const GET_ALL_REMOTE_SESSIONS_SUCCESS = 'REMOTE_SESSIONS/GET_ALL_SUCCESS';

export const initialState = {
  sessions: [],
  error: null,
  status: IDLE_STATUS,
};

export default function sessionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_REMOTE_SESSIONS: {
      return {
        ...state,
        status: LOADING_STATUS,
      };
    }
    case GET_ALL_REMOTE_SESSIONS_SUCCESS: {
      const { sessions } = action.payload;
      return {
        ...state,
        status: SUCCESS_STATUS,
        sessions,
      };
    }
    case GET_ALL_REMOTE_SESSIONS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        status: FAILURE_STATUS,
        error,
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectRemoteSessions = (state) => state.remoteSessions;

// actions
const getAllRemoteSessionsSuccess = (dispatch) => (sessions) => dispatch({
  type: GET_ALL_REMOTE_SESSIONS_SUCCESS,
  payload: {
    sessions,
  },
});

const getAllRemoteSessionsFail = (dispatch) => (error) => dispatch({
  type: GET_ALL_REMOTE_SESSIONS_FAIL,
  payload: {
    error,
  },
});

export const getAllRemoteSessions = () => (dispatch) => {
  dispatch({ type: GET_ALL_REMOTE_SESSIONS });
  remoteSessionsService
    .getAll()
    .then(getAllRemoteSessionsSuccess(dispatch))
    .catch(getAllRemoteSessionsFail(dispatch));
};



