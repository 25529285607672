/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import './Selector.scss';

class Selector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: (props.items) ? props.items : [],
      selectedItems: props.selectedItems || [],
      withSearch: props.withSearch || false,
      query: '',
      showItems: false,
      placeholder: props.placeholder || '',
    };
    this.queryFilter = this.queryFilter.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.showItemsToggle = this.showItemsToggle.bind(this);
    this.checkIfIsInSelected = this.checkIfIsInSelected.bind(this);
    this.addItem = this.addItem.bind(this);
  }

  componentDidMount() {
    const self = this;
    window.addEventListener('click', (e) => {
      if (Array.from(document.querySelectorAll('.Selector')).filter(item => item.contains(e.target)).length >= 1) {
      // Clicked in box
      } else {
        self.setState({ showItems: false });
      }
      // Clicked outside the box
    });
  }

  componentWillReceiveProps(newprops) {
    this.setState({ items: newprops.items, selectedItems: newprops.selectedItems });
  }

  onQueryChange(e) {
    this.setState({ query: e.target.value });
  }

  queryFilter(item) {
    const { query, showItems } = this.state;
    console.log(showItems);
    // if ((query !== '') && !showItems) this.showItemsToggle();
    return (((query !== '') && (item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0)) || (query === ''));
  }

  addItem(item) {
    const { selectedItems } = this.state;
    const { fireWhenUpdate } = this.props;
    if (!this.checkIfIsInSelected(item)) {
      selectedItems.push(item);
      this.setState({ selectedItems }, () => { fireWhenUpdate(this.state); });
    }
  }

  checkIfIsInSelected(item) {
    const { selectedItems } = this.state;
    return (selectedItems.filter(i => i.name === item.name).length >= 1);
  }

  removeItem(item) {
    const { selectedItems } = this.state;
    const { fireWhenUpdate } = this.props;
    const newSelectedItems = selectedItems.filter(i => (!(i.name === item.name)));
    this.setState({ selectedItems: newSelectedItems }, () => { fireWhenUpdate(this.state); });
  }

  showItemsToggle() {
    const { showItems } = this.state;
    this.setState({ showItems: !showItems });
  }

  render() {
    const { withSearch, items, query, selectedItems, placeholder, showItems } = this.state;
    const itemsFiltered = items.filter(this.queryFilter);
    return (
      <div className="Selector" id="Selector">
        {(withSearch) && (
          <input type="text" placeholder={placeholder} onClick={this.showItemsToggle} value={query} onChange={this.onQueryChange} />
        )}
        <div className={`${(showItems) ? 'show' : ''} items`}>
          {((itemsFiltered.length === 0) && (
            <div>
              No hay items para mostrar
            </div>
          ))}
          {itemsFiltered.map(i => (
            <React.Fragment>
              {(this.checkIfIsInSelected(i) && (
                <div className="redBackground" onKeyPress={() => {}} onClick={() => { this.removeItem(i); }}>
                  <span>x</span>
                  {i.name}
                </div>
              ))}
              {(!this.checkIfIsInSelected(i) && (
                <div onKeyPress={() => {}} onClick={() => { this.addItem(i); }}>
                  {i.name}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
        <div className="selected-items">
          {selectedItems.map(i => (
            <div className={`${i.selectedClass || ''} redBackground`} onKeyPress={() => {}} onClick={() => { this.removeItem(i); }}>
              <span>x</span>
              {i.name}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Selector;
