/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
/* eslint prefer-template: 0 */
export const procedures_defreturn = (block, generator) => {
  // Define a procedure with a return value.
  const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
  let xfix1 = '';
  if (generator.STATEMENT_PREFIX) {
    xfix1 += generator.injectId(generator.STATEMENT_PREFIX, block);
  }
  if (generator.STATEMENT_SUFFIX) {
    xfix1 += generator.injectId(generator.STATEMENT_SUFFIX, block);
  }
  if (xfix1) {
    xfix1 = generator.prefixLines(xfix1, generator.INDENT);
  }
  let loopTrap = '';
  if (generator.INFINITE_LOOP_TRAP) {
    loopTrap = generator.prefixLines(
      generator.injectId(generator.INFINITE_LOOP_TRAP, block),
      generator.INDENT,
    );
  }
  let branch = '';
  if (block.getInput('STACK')) {
    // The 'procedures_defreturn' block might not have a STACK input.
    branch = generator.statementToCode(block, 'STACK');
  }
  let returnValue = '';
  if (block.getInput('RETURN')) {
    // The 'procedures_defnoreturn' block (which shares this code)
    // does not have a RETURN input.
    returnValue = generator.valueToCode(block, 'RETURN', generator.ORDER_NONE) || '';
  }
  let xfix2 = '';
  if (branch && returnValue) {
    // After executing the function body, revisit this block for the return.
    xfix2 = xfix1;
  }
  if (returnValue) {
    returnValue = generator.INDENT + 'return ' + returnValue + ';\n';
  }
  const args = [];
  for (let i = 0; i < block.argumentVarModels_.length; i += 1) {
    const varType = block.argumentVarModels_[i].type;
    // const varType = block.argumentVarModels_[i].id_.split('_')[0];
    const varName = block.argumentVarModels_[i].name;
    args[i] = `${varType} ${varName}`;
  }

  const procType = block.getFieldValue('TYPE');
  let code = `
${procType || 'void'} ${funcName}(${args.join(', ')}) {
  ${xfix1}
  ${loopTrap}
  ${branch}
  ${xfix2}
  ${returnValue}
}
`;
  code = generator.scrub_(block, code);
  // Add % so as not to collide with helper functions in definitions list.
  // TODO(#7600): find better approach than casting to any to override
  // CodeGenerator declaring .definitions protected.
  generator.definitions_['%' + funcName] = code;
  return null;
}

// Defining a procedure without a return value uses the same generator as
// a procedure with a return value.
export const procedures_defnoreturn = procedures_defreturn;

export const procedures_callreturn_custom = (block, generator) => {
  const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
  const args = [];
  for (let i = 0; i < block.argumentVarModels_.length; i += 1) {
    args[i] = generator.valueToCode(block, 'ARG' + i, generator.ORDER_NONE) || 'null';
  }
  const code = `${funcName}(${args.join(', ')})`;
  return [code, generator.ORDER_UNARY_POSTFIX];
}

export const procedures_callnoreturn_custom = (block, generator) => {
  const funcName = generator.getProcedureName(block.getFieldValue('NAME'));
  const args = [];
  for (let i = 0; i < block.argumentVarModels_.length; i += 1) {
    args[i] = generator.valueToCode(block, 'ARG' + i, generator.ORDER_NONE) || 'null';
  }
  const code = `${funcName}(${args.join(', ')});`;
  return `${code}
`;
}

export const procedures_ifreturn = (block, generator) => {
  // Conditionally return value from a procedure.
  const condition = generator.valueToCode(block, 'CONDITION', generator.ORDER_NONE) || 'false';
  let code = `if (${condition}) {
`;
  if (generator.STATEMENT_SUFFIX) {
    // Inject any statement suffix here since the regular one at the end
    // will not get executed if the return is triggered.
    code += generator.prefixLines(
      generator.injectId(generator.STATEMENT_SUFFIX, block),
      generator.INDENT,
    );
  }
  if (block.hasReturnValue_) {
    const value = generator.valueToCode(block, 'VALUE', generator.ORDER_NONE) || 'null';
    code += `${generator.INDENT}${generator.INDENT}return ${value};
`;
  } else {
    code += `${generator.INDENT}${generator.INDENT}return;
`;
  }
  code += `${generator.INDENT}}
`;
  return code;
}