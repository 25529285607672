/* eslint new-cap: 0 */
import { PinState } from 'avr8js';

export class BuzzerController {
  constructor() {
    this.lastState = PinState.Low;
    this.timeHigh = 0;
    this.timeLow = 0;
    this.frequency = 0;
    this.lastTimestamp = 0;
    this.audioCtx = (window.AudioContext) ? new window.AudioContext() : new window.webkitAudioContext();
    this.oscillator = null;
    this.playingFrequency = 0;
  }

  feedValue = (pinState, cpuMicros) => {
    if (pinState !== this.lastState) {
      if (pinState === PinState.High) {
        this.timeHigh = cpuMicros;
        this.timeLow = 0;
      }

      if (pinState === PinState.Low) {
        const timeLapse = cpuMicros - this.timeHigh;
        this.timeHigh = 0;
        this.timeLow = cpuMicros;
        this.frequency = Math.round(500000 / timeLapse);
      }

      this.lastState = pinState;
    }
  }

  isPlaying = () => Boolean(this.frequency > 0);

  playNote = (frequency) => {
    if (!this.playingFrequency || frequency !== this.playingFrequency) {
      this.stopNote();
      this.oscillator = this.audioCtx.createOscillator();

      this.oscillator.type = 'square';
      this.oscillator.frequency.value = frequency; // value in hertz
      this.oscillator.connect(this.audioCtx.destination);
      this.oscillator.start();
      this.playingFrequency = frequency;
    }
  }

  play = (cpuMicros) => {
    const delta = cpuMicros - this.lastTimestamp;
    if (delta > 1000) {
      const timeLapseHigh = Math.round(500000 / (cpuMicros - this.timeHigh));
      const timeLapseLow = Math.round(500000 / (cpuMicros - this.timeLow));
      if (this.frequency) {
        if (timeLapseHigh < 100 && timeLapseLow < 100) {
          // console.log('=============stop', timeLapseLow, timeLapseHigh);
          this.frequency = 0;
          this.lastState = PinState.Low;
          this.timeHigh = 0;
          this.timeLow = 0;
          this.lastTimestamp = 0;
          this.stopNote();
        } else {
          // console.log('=============play', this.frequency, timeLapseLow, timeLapseHigh);
          this.playNote(this.frequency);
        }
      }
      this.lastTimestamp = cpuMicros;
    }
    return this.frequency;
  }

  stopNote = () => {
    if (this.oscillator) {
      this.oscillator.stop();
      this.playingFrequency = 0;
    }
  }

  stop = () => {
    this.frequency = 0;
    this.stopNote();
  }
}
