import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="new_event_start" x="50" y="50" deleteable="false" />
    <block type="new_event_repeat" x="50" y="150" deleteable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category name="Drive" colour="#F2f2f2" iconclass="fx">
      <block type="drive_forwards_time">
        <value name="DURATION" type="input_value">
          <shadow type="math_positive_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="drive_backwards_time">
        <value name="DURATION">
          <shadow type="math_positive_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="drive_left_time">
        <value name="DURATION">
          <shadow type="math_positive_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="drive_right_time">
        <value name="DURATION">
          <shadow type="math_positive_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="drive_forwards_event">
        <value name="CHOICE">
          <shadow type="dropdown_drive_setforwards_event">
            <field name="CHOICE">white</field>
          </shadow>
        </value>
      </block>
      <block type="drive_backwards_event">
        <value name="CHOICE">
          <shadow type="dropdown_drive_setbackwards_event">
            <field name="CHOICE">white</field>
          </shadow>
        </value>
      </block>
      <block type="drive_left_event">
        <value name="CHOICE">
          <shadow type="dropdown_drive_setleft_event">
            <field name="CHOICE">white</field>
          </shadow>
        </value>
      </block>
      <block type="drive_right_event">
        <value name="CHOICE">
          <shadow type="dropdown_drive_setright_event">
            <field name="CHOICE">white</field>
          </shadow>
        </value>
      </block>
      <block type="drive_stop" />
      <block type="drive_line_tracking_time">
        <value name="DURATION">
          <shadow type="math_positive_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="drive_line_tracking_obstacle" />
      <block type="drive_line_tracking_infinite" />
      <block type="drive_speed">
        <value name="CHOICE">
          <shadow type="dropdown_drive_setspeed">
            <field name="CHOICE">7</field>
          </shadow>
        </value>
      </block>
    </category>

    <category name="Outputs" iconclass="outputs">
      <block type="light_sound_LED">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_LED">
            <field name="CHOICE">bothOn</field>
          </shadow>
        </value>
      </block>
      <block type="light_sound_beep" />
      <block type="light_sound_play_whole">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_play_whole">
            <field name="CHOICE">A</field>
          </shadow>
        </value>
      </block>
      <block type="light_sound_play_half">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_play_half">
            <field name="CHOICE">A</field>
          </shadow>
        </value>
      </block>
      <block type="light_sound_play_quarter">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_play_quarter">
            <field name="CHOICE">A</field>
          </shadow>
        </value>
      </block>
      <block type="light_sound_play_eighth">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_play_eighth">
            <field name="CHOICE">A</field>
          </shadow>
        </value>
      </block>
      <block type="light_sound_rest">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_rest">
            <field name="CHOICE">25</field>
          </shadow>
        </value>
      </block>
      <block type="light_sound_note_sharp" />
      <block type="light_sound_note_tempo">
        <value name="CHOICE">
          <shadow type="dropdown_light_sound_note_tempo">
            <field name="CHOICE">2</field>
          </shadow>
        </value>
      </block>
      <block type="control_send_message">
        <value name="CHOICE">
          <shadow type="dropdown_control_set_message">
            <field name="CHOICE">1</field>
          </shadow>
        </value>
      </block>
    </category>

    <category name="Wait Until" colour="#F2f2f2" iconclass="logics">
      <block type="control_wait_time">
        <value name="DURATION">
          <shadow type="math_positive_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="control_wait_event">
        <value name="CHOICE">
          <shadow type="dropdown_control_setwait_event">
            <field name="CHOICE">clap</field>
          </shadow>
        </value>
      </block>
      <block type="control_wait_event_obstacle" />
      <block type="control_wait_message">
        <value name="CHOICE">
          <shadow type="dropdown_control_setwait_message">
            <field name="CHOICE">1</field>
          </shadow>
        </value>
      </block>
    </category>

    <category name="Control" colour="#F2f2f2" iconclass="control">
      <block type="control_loop_forever" />
      <block type="control_loop_number">
        <value name="TIMES">
          <shadow type="math_whole_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="control_loop_event">
        <value name="CHOICE">
          <shadow type="dropdown_control_setloop_event">
            <field name="CHOICE">triangle</field>
          </shadow>
        </value>
      </block>
      <block type="control_loop_event_obstacle" />
      <block type="control_stop" />
    </category>

    <category name="Start Events" colour="#F2f2f2" iconclass="comm">

      <block type="new_event_obstacle" />
      <block type="new_event_clap_one" />
      <block type="new_event_clap_two" />
      <block type="new_event_key_triangle" />
      <block type="new_event_key_circle" />
      <block type="new_event_line_on_white" />
      <block type="new_event_line_on_black" />
      <block type="new_event_message_1" />
      <block type="new_event_message_2" />
      <block type="new_event_message_3" />
      <block type="new_event_message_4" />
      <block type="new_event_message_5" />
      <block type="new_event_message_6" />
      <block type="new_event_remote_code_1" />
      <block type="new_event_remote_code_2" />
      <block type="new_event_remote_code_3" />
      <block type="new_event_remote_code_4" />
      <block type="new_event_remote_code_5" />
      <block type="new_event_remote_code_6" />
    </category>

  </React.Fragment>
);
/* eslint-enable */
