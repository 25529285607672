import { BasicBlock, Block } from '@sections/creabots/types/kits'

const imgBaseUrl = '/images/creabots/buty/blocks'

const getBlocksInfoSchema = (blocks: BasicBlock[], folderKey: string): Block[] => {
  return blocks.map(({ id, name }) => ({
    id,
    className: `.${name}`,
    image: `${imgBaseUrl}/${folderKey}/${name}.png`,
  }))
}

export default getBlocksInfoSchema