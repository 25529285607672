/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const getFirstBoardOfEachGroup = (boardsByGroups) => Object
  .entries(boardsByGroups)
  .map(([_, board]) => [...board].shift());

export const groupBoardsByGroupName = (boards) => {
  const groups = {};
  for (const key in boards) {
    const board = boards[key];
    const boardGroup = board.groupName;
    if (groups[boardGroup] === undefined) {
      groups[boardGroup] = [];
    }

    groups[boardGroup].push(board);
  }

  return groups;
};

export const getBoardsByGroupName = (groupName = '', boards) => {
  if (boards[groupName] && boards[groupName].length) {
    return boards[groupName];
  }

  return [];
};

export const filterBoardsBySeniority = ({
  type,
  boards,
  premium = true,
  disabled = true,
  hidden = false }) => {
  switch (type) {
    case 'senior':
      return boards.filter((board) => board.type === 'senior'
                && (hidden || board.show === 1)
                && (premium || !board.premium)
                && (disabled || board.enabled));
    case 'junior':
      return boards.filter((board) => board.type === 'junior'
                && (hidden || board.show === 1)
                && (premium || !board.premium)
                && (disabled || board.enabled));
    case 'code':
      return boards.filter((board) => board.type === 'senior'
                && board.enabledForCode > 0
                && (hidden || board.show === 1)
                && (premium || !board.premium)
                && (disabled || board.enabled));
    case 'aula':
      return boards.filter((board) => (hidden || board.showOnAula === 1)
                && (disabled || board.enabled));
    case 'all':
      return boards.filter((board) => (hidden || board.show === 1)
                && (premium || !board.premium)
                && (disabled || board.enabled));
    default: return [];
  }
};

export const filtreBoardsByGroups = ({ type, boards }) => {
  const filtredBoards = filterBoardsBySeniority({ type, boards });
  const boardsGroupedByGroups = groupBoardsByGroupName(filtredBoards);
  return getFirstBoardOfEachGroup(boardsGroupedByGroups);
};


