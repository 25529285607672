/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint spaced-comment: 0 */
import * as Blockly from 'blockly';
import { JuniorFieldGridDropdown } from './customBlocks/juniorFieldGridDropdown';
import { JuniorFieldNumber } from './customBlocks/juniorFieldNumber';
import { JuniorFieldTextInput } from './customBlocks/juniorFieldTextInput';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';


Blockly.Blocks.BHOOT_initial_block = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/edison/play.png`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.pause_millis = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar1.png`,
          value: '1000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_1,
          selected: true,
        }, '1000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar2.png`,
          value: '2000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_2,
        }, '2000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar3.png`,
          value: '3000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_3,
        }, '3000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar4.png`,
          value: '4000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_4,
        }, '4000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar5.png`,
          value: '5000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_5,
        }, '5000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar6.png`,
          value: '6000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_6,
        }, '6000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar7.png`,
          value: '7000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_7,
        }, '7000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar8.png`,
          value: '8000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_8,
        }, '8000'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/Esperar9.png`,
          value: '9000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_9,
        }, '9000'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_pause_smart = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/BhootJR/esperar_corto.png`,
          value: '500',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_1,
        }, '500'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/esperar_largo.png`,
          value: '1000',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PAUSE_MILLIS_2,
          selected: true,
        }, '1000'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_control_loop_number = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendStatementInput('SUBSTACK');
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir2.png`,
          value: '2',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_2,
          selected: true,
        }, '2'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir3.png`,
          value: '3',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_3,
        }, '3'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir4.png`,
          value: '4',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_4,
        }, '4'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir5.png`,
          value: '5',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_5,
        }, '5'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir6.png`,
          value: '6',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_6,
        }, '6'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir7.png`,
          value: '7',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_7,
        }, '7'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir8.png`,
          value: '8',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_8,
        }, '8'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir9.png`,
          value: '9',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_9,
        }, '9'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/repetir10.png`,
          value: '10',
          width: options.jrBlockWidth * 0.9,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_10,
        }, '10'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_repeat_forever = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendStatementInput('SUBSTACK');
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/Repetir.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.show_string = {
  init: function () {
    this.setColour(colors.COLOUR_LCD);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/escribir.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.appendDummyInput()
      .appendField(new JuniorFieldTextInput('TEXTO'), 'NUM');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.BHOOT_led_off = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/apagar.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_play_tone = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/BhootJR/do.png`,
          value: '261',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_DO,
        }, '261'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/re.png`,
          value: '293',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_RE,
        }, '293'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/mi.png`,
          value: '329',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_MI,
        }, '329'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/fa.png`,
          value: '349',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_FA,
        }, '349'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/sol.png`,
          value: '392',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_SOL,
        }, '392'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/la.png`,
          value: '440',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_LA,
        }, '440'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/si.png`,
          value: '494',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_PLAY_TONE_SI,
        }, '494'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_led = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/BhootJR/rojo.png`,
          value: '255, 0, 0',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_4,
        }, '255, 0, 0'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/naranja.png`,
          value: '255, 102, 0',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_2,
        }, '255, 102, 0'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/amarillo.png`,
          value: '255, 255, 0',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_1,
          selected: true,
        }, '255, 255, 0'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/verde.png`,
          value: '0, 255, 0',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_6,
        }, '0, 255, 0'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/celeste.png`,
          value: '51, 204, 255',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_7,
        }, '51, 204, 255'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/azul.png`,
          value: '0, 0, 255',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_8,
        }, '0, 0, 255'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/violeta.png`,
          value: '102, 0, 204',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_9,
        }, '102, 0, 204'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/rosa.png`,
          value: '204, 51, 204',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_3,
        }, '204, 51, 204'],
        [{
          src: `${options.imgBase}/blocks/BhootJR/blanco.png`,
          value: '255, 255, 255',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_LED_COLOUR_5,
        }, '255, 255, 255'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_motor_forward = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/Bloque_avanzar.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.appendDummyInput()
      .appendField(new JuniorFieldNumber(1, 0, Infinity, 1), 'NUM');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_motor_backward = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/Bloque_retroceder.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.appendDummyInput()
      .appendField(new JuniorFieldNumber(1, 0, Infinity, 1), 'NUM');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_motor_right = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/Bloque_girar_derecha.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.appendDummyInput()
      .appendField(new JuniorFieldNumber(1, 0, Infinity, 1), 'NUM');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_motor_left = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/Bloque_girar_izquierda.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.appendDummyInput()
      .appendField(new JuniorFieldNumber(1, 0, Infinity, 1), 'NUM');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.BHOOT_line_follower_jr = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/seguidor.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


// TODO: do the matrix modal functionality
Blockly.Blocks.BHOOT_matrix_8x8_junior = {
  init: function () {
    this.setColour(colors.COLOUR_LCD);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/BhootJR/Bloque_matriz_9.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};




//////////////////////////////////////////////////
/////////// CODIT ////////////////////////////////
//////////////////////////////////////////////////
Blockly.Blocks.codit_initial_block = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/edison/play.png`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_wait = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/12-Esperar.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_wait_to = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/13-EsperarHasta.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_motor = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/07-Motor.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_light = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/08-Luz.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_color = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/09-Color.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_music = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/10-Musica.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_talk = {
  init: function () {
    this.setColour(colors.COLOUR_SALIDAS);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/11-Hablar.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_forward = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/01-Avanzar.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_backward = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/02-Retroceder.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_left = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/03-Girar izquierda.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_right = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/04-Girar derecha.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_forward_3 = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/05-Avanzar3.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.codit_shake = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/codit/06-Sacudirse.svg`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};



//////////////////////////////////////////////////
/////////// MICROBIT /////////////////////////////
//////////////////////////////////////////////////
Blockly.Blocks.new_event_start = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/edison/play.png`, options.jrBlockWidth, options.jrBlockHeight))
    this.setPreviousStatement(false);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};


Blockly.Blocks.new_event_repeat = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendStatementInput('SUBSTACK');
    this.appendDummyInput()
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/edison/flecha0.png`, options.jrBlockWidth, options.jrBlockHeight));
    this.setPreviousStatement(true);
    this.setNextStatement(false);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.control_loop_number = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendStatementInput('SUBSTACK');
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/edison/flecha1.png`,
          value: '1',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_1,
        }, '1'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha2.png`,
          value: '2',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_2,
        }, '2'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha3.png`,
          value: '3',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_3,
        }, '3'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha4.png`,
          value: '4',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_4,
        }, '4'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha5.png`,
          value: '5',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_5,
        }, '5'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha6.png`,
          value: '6',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_6,
        }, '6'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha7.png`,
          value: '7',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_7,
        }, '7'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha8.png`,
          value: '8',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_8,
        }, '8'],
        [{
          src: `${options.imgBase}/blocks/edison/flecha9.png`,
          value: '9',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_CONTROL_LOOP_NUMBER_9,
        }, '9'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.on_buton_pressed = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendStatementInput('SUBSTACK');
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/edison/a.svg`,
          value: 'A',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_BUTTON_A,
        }, 'A'],
        [{
          src: `${options.imgBase}/blocks/edison/b.svg`,
          value: 'B',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_BUTTON_B,
        }, 'B'],
        [{
          src: `${options.imgBase}/blocks/edison/a+b.svg`,
          value: 'AB',
          width: options.jrBlockWidth,
          height: options.jrBlockHeight,
          alt: Blockly.Msg.TOOLTIP_BUTTON_AB,
        }, 'AB'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};

Blockly.Blocks.show_icon = {
  init: function () {
    this.setColour(colors.COLOUR_LCD);
    this.appendDummyInput()
      .appendField(new JuniorFieldGridDropdown([
        [{
          src: `${options.imgBase}/blocks/edison/icon_heart.png`,
          value: 'Heart',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_HEART,
        }, 'Heart'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_heart_small.png`,
          value: 'SmallHeart',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_HEART_SMALL,
        }, 'SmallHeart'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_yes.png`,
          value: 'Yes',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_YES,
        }, 'Yes'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_no.png`,
          value: 'No',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_NO,
        }, 'No'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_happy.png`,
          value: 'Happy',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_HAPPY_FACE,
        }, 'Happy'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_sad.png`,
          value: 'Sad',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_SAD_FACE,
        }, 'Sad'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_confused.png`,
          value: 'Confused',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_CONFUSED,
        }, 'Confused'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_angry.png`,
          value: 'Angry',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_ANGRY,
        }, 'Angry'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_asleep.png`,
          value: 'Asleep',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_ASLEEP,
        }, 'Asleep'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_surprised.png`,
          value: 'Surprised',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_SURPRISED,
        }, 'Surprised'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_silly.png`,
          value: 'Silly',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_SILLY,
        }, 'Silly'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_fabulous.png`,
          value: 'Fabulous',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_FABULOUS,
        }, 'Fabulous'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_meh.png`,
          value: 'Meh',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_MEH,
        }, 'Meh'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_tshirt.png`,
          value: 'TShirt',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_TSHIRT,
        }, 'TShirt'],
        [{
          src: `${options.imgBase}/blocks/edison/icon_rollerskate.png`,
          value: 'Rollerskate',
          width: options.jrBlockWidth * 0.8,
          height: options.jrBlockHeight * 0.8,
          alt: Blockly.Msg.TOOLTIP_ICON_ROLLERSKATE,
        }, 'Rollerskate'],
      ], undefined, { columns: 3 }), 'CHOICE');
    this.setPreviousStatement(true);
    this.setNextStatement(true);
    this.setInputsInline(true);
  },
};
