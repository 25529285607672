import React, { Component } from 'react';
import { Matrix8x16Element } from '../components/matrix8x16/matrix8x16';
import { rotatePort, cpuMillis } from '../utils';
import { Matrix8x16Controller } from '../libs/matrix8x16';

export class Matrix8x16 extends Component {
  constructor(hardware, pin, id = 'Matrix8x16', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.hardware = hardware;
    this.id = id;
    this.pin = pin;
    this.pinStateSCL = false;
    this.pinStateSDA = false;
    this.variant = variant || {
      color: '#0f6efb',
    };
    this.pinInfo = [
      { name: 'i2c', x: 102, y: -3, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 204,
      height: 203,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.lastTimestamp = 0;
    this.matrix8x16Controller = new Matrix8x16Controller();
    this.lcd = null;
    this.characters = null;

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runMicrosecond = (cpuCycles) => {
    if (this.hardware && this.pin) {
      const microseconds = cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      const delta = microseconds - this.lastTimestamp;
      if (delta > 30) {
        this.lcd = this.matrix8x16Controller.render();
        this.lastTimestamp = microseconds;
      }
    }
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => null;

  update = (pinState) => {
    if (this.hardware && this.pin) {
      const pinInfo = this.hardware.getPinInfo(this.pin);
      const pins = this.pin.split(',');
      pins.map((pin, index) => {
        let startPin = 0;
        let pinNumber = parseInt(pin, 10);
        if (pin.toString().includes('A')) {
          pinNumber = parseInt(pin.toString()[1], 10);
        }
        if (pinNumber >= 8) {
          startPin = 8;
        }
        const state = (pinState >> (pinNumber - startPin)) & 0x01;
        if (pinInfo.signals?.[index][0]?.signal === 'SDA') {
          this.pinStateSDA = state;
          // console.log('======================update', this.pinStateSDA, pinState.toString(2));
        } else {
          this.pinStateSCL = state;
        }
        return true;
      });

      this.matrix8x16Controller.update(this.pinStateSCL, this.pinStateSDA);
    }
  }

  reset = () => {
    this.pinStateSCL = false;
    this.pinStateSDA = false;
  }

  render = () => (
    <Matrix8x16Element
      ref={this.ref}
      id={this.id}
      key={`matrix8x16-${this.pin}`}
      rotation={this.rotation}
      lcd={this.lcd}
      color={this.variant.color}
    />
  );
}
