export default {
  COLOUR_PROCEDURES: '#651FFF',
  COLOUR_CONTROL: '#FF9800',
  COLOUR_LOGIC: '#40BF4A',
  COLOUR_VARIABLES: '#E91E63',
  COLOUR_MATH: '#00BCD4',
  COLOUR_TEXT: '#00958A',
  COLOUR_COMMUNICATION: '#00BFA5',
  COLOUR_SALIDAS: '#F44336',
  COLOUR_ENTRADAS: '#9829B1',
  COLOUR_LCD: '#3F51B5',
  COLOUR_SERVO: '#4C97FF',
  COLOUR_MORSE: '#FDC300',
}