import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        movable: false,
        deletable: false,
        type: 'BHOOT_initial_block',
        x: 30,
        y: 30,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <block type="BHOOT_motor_left" />
    <block type="BHOOT_motor_right" />
    <block type="BHOOT_motor_backward" />
    <block type="BHOOT_motor_forward" />
    <block type="BHOOT_matrix_8x8_junior" />
  </xml>
);
