/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
// import { strict } from "assert";

import { getAudioFromTag, loadAudioExt } from '../LoadAndKillerScripts';


const $ = window.$;

export default {

  normalizeBlocklyIds(id) {
    return id.replace(/[^a-zA-Z0-9]+/g, '');
  },

  buildBigBlock(block) {
    console.log('1111', Object.assign({}, block));
    // function getDigit(digit, segment) {
    //   let duration;
    //   if (typeof block.duration === 'undefined') {
    //     block.duration = 1000;
    //   }
    //   switch (block.duration.toString().length) {
    //     case 4:
    //       duration = block.duration.toString();
    //       break;
    //     case 3:
    //       duration = `0${block.duration.toString()}`;
    //       break;
    //     case 2:
    //       duration = `00${block.duration.toString()}`;
    //       break;
    //     case 1:
    //       duration = `000${block.duration.toString()}`;
    //       break;
    //     default:
    //       duration = block.duration.toString();
    //   }

    //   let segmentsToTurnOff = [];

    //   if (duration[digit] === '9') segmentsToTurnOff = ['E'];
    //   else if (duration[digit] === '7') segmentsToTurnOff = ['D', 'E', 'F', 'G'];
    //   else if (duration[digit] === '6') segmentsToTurnOff = ['B'];
    //   else if (duration[digit] === '5') segmentsToTurnOff = ['B', 'E'];
    //   else if (duration[digit] === '4') segmentsToTurnOff = ['A', 'E', 'G'];
    //   else if (duration[digit] === '3') segmentsToTurnOff = ['E', 'F'];
    //   else if (duration[digit] === '2') segmentsToTurnOff = ['C', 'F'];
    //   else if (duration[digit] === '1') segmentsToTurnOff = ['A', 'D', 'E', 'F', 'G'];
    //   else if (duration[digit] === '0') segmentsToTurnOff = ['D'];

    //   return segmentsToTurnOff.indexOf(segment) !== -1;
    // }
    const availablePins = [];
    let options = [];
    if (block.getField('NPIN')) {
      options = block.getField('NPIN').getOptions();
    } else {
      options.push([block.pin, block.pin]);
    }
    for (let i = 0; i < options.length; i += 1) {
      availablePins.push(options[i][1]);
    }


    // function createPinsHtml(min, max) {
    //   let html = '';
    //   for (let i = min; i <= max; i += 1) {
    //     let disabled = 'disabled';
    //     if (availablePins.indexOf(i.toString()) !== -1) {
    //       disabled = '';
    //     }
    //     html += `<div class="pin pin-${i} ${i === Number(block.pin) ? 'selected' : ''} ${disabled}" data-pin="${i}">${i}</div>`;
    //   }
    //   html += '</div>';
    //   return html;
    // }

    function createDynamicPinsHtml() {
      let html = '';
      const maxCols = 6;
      for (let j = 0; j < (options.length / maxCols); j += 1) {
        html += `<div class="${(j > 0) ? 'lower' : 'upper'}">`;
        for (let i = j * maxCols; i < (j * maxCols) + maxCols; i += 1) {
          if (typeof options[i] !== 'undefined') {
            html += `<div class="pin pin-${options[i][1]} ${options[i][1] === block.pin ? 'selected' : ''}" data-pin="${options[i][1]}">${options[i][0]}</div>`;
          }
        }
        html += '</div>';
      }
      return html;
    }

    return `
    <div class="popup-modal-parent piano-modal-parent">
      <div class="popup-modal-under">
      </div>
      <div class="piano-modal initial-popup">
        <div class="piano-panel">
          <div class="octave-screen">
            <div class="segment-octave">${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_PIANO_OCTAVE || 'OCTAVA'}</div>
            <div class="segment segment-A ${block.octave === 2 || block.octave === 3 ? '' : 'turned-off'}"></div>
            <div class="segment segment-B"></div>
            <div class="segment segment-C ${block.octave === 1 || block.octave === 3 ? '' : 'turned-off'}"></div>
            <div class="segment segment-D ${block.octave === 2 || block.octave === 3 ? '' : 'turned-off'}"></div>
            <div class="segment segment-E ${block.octave === 2 ? '' : 'turned-off'}"></div>
            <div class="segment segment-F turned-off"></div>
            <div class="segment segment-G ${block.octave === 2 || block.octave === 3 ? '' : 'turned-off'}"></div>
            <div class="octave-button octave-up"></div>
            <div class="octave-button octave-down"></div>
          </div>
          <div class="pin-selector">
            ${createDynamicPinsHtml()}
          </div>
        </div>
        <div class="piano-keys">
          <div class="key white-key ${block.basePitch === 131 ? 'selected' : ''} c-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_DO || 'DO'}</span></div>
          <div class="key black-key ${block.basePitch === 139 ? 'selected' : ''} c-sharp"><span>${`${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_DO}#` || 'DO'}</span></div>
          <div class="key white-key ${block.basePitch === 147 ? 'selected' : ''} d-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_RE || 'RE'}</span></div>
          <div class="key black-key ${block.basePitch === 156 ? 'selected' : ''} d-sharp"><span>${`${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_RE}#` || 'RE#'}</span></div>
          <div class="key white-key ${block.basePitch === 165 ? 'selected' : ''} e-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_MI || 'MI'}</span></div>
          <div class="key white-key ${block.basePitch === 175 ? 'selected' : ''} f-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_FA || 'FA'}</span></div>
          <div class="key black-key ${block.basePitch === 185 ? 'selected' : ''} f-sharp"><span>${`${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_FA}#` || 'FA#'}</span></div>
          <div class="key white-key ${block.basePitch === 196 ? 'selected' : ''} g-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_SOL || 'SOL'}</span></div>
          <div class="key black-key ${block.basePitch === 208 ? 'selected' : ''} g-sharp"><span>${`${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_SOL}#` || 'SOL#'}</span></div>
          <div class="key white-key ${block.basePitch === 220 ? 'selected' : ''} a-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_LA || 'LA'}</span></div>
          <div class="key black-key ${block.basePitch === 233 ? 'selected' : ''} a-sharp"><span>${`${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_LA}#` || 'LA#'}</span></div>
          <div class="key white-key ${block.basePitch === 247 ? 'selected' : ''} b-natural"><span>${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_SI || 'SI'}</span></div>
        </div>
      </div>
      <div class="popup-modal-close piano-modal-close">
        <span class="icon-cross11"></span>
      </div>
    </div>`;
  },

  buildMiniBlock(block, startUp = false) {
    console.log('1111', block);
    const ele = document.querySelector(`[data-id='${block.id}']`);
    const eleText = document.querySelector(`[data-id='${block.id}'] text:nth-child(5)`);
    let note = '';
    if (typeof block.duration === 'undefined') {
      block.duration = 1000;
    }

    const audioList = [
      '/audio/square_wave_samples/square-131-C.ogg',
      '/audio/square_wave_samples/square-139-Csharp.ogg',
      '/audio/square_wave_samples/square-147-D.ogg',
      '/audio/square_wave_samples/square-156-Dsharp.ogg',
      '/audio/square_wave_samples/square-165-E.ogg',
      '/audio/square_wave_samples/square-175-F.ogg',
      '/audio/square_wave_samples/square-185-Fsharp.ogg',
      '/audio/square_wave_samples/square-196-G.ogg',
      '/audio/square_wave_samples/square-208-Gsharp.ogg',
      '/audio/square_wave_samples/square-220-A.ogg',
      '/audio/square_wave_samples/square-233-Asharp.ogg',
      '/audio/square_wave_samples/square-247-B.ogg',
      '/audio/square_wave_samples/square-262-C.ogg',
      '/audio/square_wave_samples/square-277-Csharp.ogg',
      '/audio/square_wave_samples/square-294-D.ogg',
      '/audio/square_wave_samples/square-311-Dsharp.ogg',
      '/audio/square_wave_samples/square-330-E.ogg',
      '/audio/square_wave_samples/square-349-F.ogg',
      '/audio/square_wave_samples/square-370-Fsharp.ogg',
      '/audio/square_wave_samples/square-392-G.ogg',
      '/audio/square_wave_samples/square-415-Gsharp.ogg',
      '/audio/square_wave_samples/square-440-A.ogg',
      '/audio/square_wave_samples/square-464-Asharp.ogg',
      '/audio/square_wave_samples/square-494-B.ogg',
      '/audio/square_wave_samples/square-523-C.ogg',
      '/audio/square_wave_samples/square-554-Csharp.ogg',
      '/audio/square_wave_samples/square-587-D.ogg',
      '/audio/square_wave_samples/square-622-Dsharp.ogg',
      '/audio/square_wave_samples/square-659-E.ogg',
      '/audio/square_wave_samples/square-698-F.ogg',
      '/audio/square_wave_samples/square-740-Fsharp.ogg',
      '/audio/square_wave_samples/square-784-G.ogg',
      '/audio/square_wave_samples/square-831-Gsharp.ogg',
      '/audio/square_wave_samples/square-880-A.ogg',
      '/audio/square_wave_samples/square-932-Asharp.ogg',
      '/audio/square_wave_samples/square-988-B.ogg',
    ];

    if (!document.querySelector('audio#square-131-C')) loadAudioExt(audioList);

    switch (block.basePitch) {
      case 131:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_DO || 'DO';
        break;
      case 139:
        note = `${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_DO}#` || 'DO#';
        break;
      case 147:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_RE || 'RE';
        break;
      case 156:
        note = `${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_RE}#` || 'RE#';
        break;
      case 165:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_MI || 'MI';
        break;
      case 175:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_FA || 'FA';
        break;
      case 185:
        note = `${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_FA}#` || 'FA#';
        break;
      case 196:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_SOL || 'SOL';
        break;
      case 208:
        note = `${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_SOL}#` || 'SOL#';
        break;
      case 220:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_LA || 'LA';
        break;
      case 233:
        note = `${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_LA}#` || 'LA#';
        break;
      case 247:
        note = window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_SI || 'SI';
        break;
      default:
        note = '--';
    }

    const miniPiano = `
      <div class=mini-piano-keys>
        <div class="key white-key ${block.basePitch === 131 ? 'selected' : ''} c-natural"></div>
        <div class="key black-key ${block.basePitch === 139 ? 'selected' : ''} c-sharp"></div>
        <div class="key white-key ${block.basePitch === 147 ? 'selected' : ''} d-natural"></div>
        <div class="key black-key ${block.basePitch === 156 ? 'selected' : ''} d-sharp"></div>
        <div class="key white-key ${block.basePitch === 165 ? 'selected' : ''} e-natural"></div>
        <div class="key white-key ${block.basePitch === 175 ? 'selected' : ''} f-natural"></div>
        <div class="key black-key ${block.basePitch === 185 ? 'selected' : ''} f-sharp"></div>
        <div class="key white-key ${block.basePitch === 196 ? 'selected' : ''} g-natural"></div>
        <div class="key black-key ${block.basePitch === 208 ? 'selected' : ''} g-sharp"></div>
        <div class="key white-key ${block.basePitch === 220 ? 'selected' : ''} a-natural"></div>
        <div class="key black-key ${block.basePitch === 233 ? 'selected' : ''} a-sharp"></div>
        <div class="key white-key ${block.basePitch === 247 ? 'selected' : ''} b-natural"></div>
      </div>
    `;

    if (document.getElementById(`piano-${block.id}`)) {
      const foreignObject = document.getElementById(`piano-${block.id}`);
      foreignObject.innerHTML = `
        <div class="piano-mini-modal piano-mini-modal-${block.id}">
          <div class="${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_PIANO_SPACE_CSS || 'pseudoBlocklyEmptyText'}"></div>
          <div class="pseudoBlocklyText">${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_PIANO_NOTE}</div>
          ${miniPiano}
          <div class="note">${note}<div class="octave">${block.octave}</div></div>
        </div>
      `;
    } else {
      const ns = 'http://www.w3.org/2000/svg';
      // const svg = document.querySelector('svg');
      const foreignObject = document.createElementNS(ns, 'foreignObject');
      foreignObject.setAttribute('class', 'piano');
      foreignObject.setAttribute('id', `piano-${block.id}`);
      foreignObject.innerHTML = `
        <div class="piano-mini-modal piano-mini-modal-${block.id}">
          <div class="${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_PIANO_SPACE_CSS || 'pseudoBlocklyEmptyText'}"></div>
          <div class="pseudoBlocklyText">${window.Blockly?.Msg?.LANG_SALIDAS_PIEZO_BUZZER_PIANO_NOTE}</div>
          ${miniPiano}
          <div class="note">${note}<div class="octave">${block.octave}</div></div>
        </div>
      `;
      ele.insertBefore(foreignObject, eleText);
    }
    // block.workspace.fireChangeListener('resize');
    if (!startUp) {
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
    }
  },

  js(block) {
    const self = this;

    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildBigBlock(block);
    window.Blockly.WidgetDiv.show(block, true);

    // audio player

    $('.blocklyWidgetDiv').unbind('click');
    $('.blocklyWidgetDiv').unbind('mousedown mouseover');
    $('.blocklyWidgetDiv').unbind('mousedown');
    $('.blocklyWidgetDiv').unbind('mouseup mouseout');

    $('.blocklyWidgetDiv').on('mousedown mouseover', '.piano-modal-parent .key', function (e) { // eslint-disable-line
      if (e.buttons === 1 || e.buttons === 3) {
        $('.piano-modal-parent .key').removeClass('selected');
        $(e.currentTarget).addClass('selected');

        if ($(e.currentTarget).hasClass('c-natural')) {
          block.basePitch = 131;
          if (block.octave === 1) getAudioFromTag('#square-131-C').play();
          else if (block.octave === 2) getAudioFromTag('#square-262-C').play();
          else if (block.octave === 3) getAudioFromTag('#square-523-C').play();
        } else if ($(e.currentTarget).hasClass('c-sharp')) {
          block.basePitch = 139;
          if (block.octave === 1) getAudioFromTag('#square-139-Csharp').play();
          else if (block.octave === 2) getAudioFromTag('#square-277-Csharp').play();
          else if (block.octave === 3) getAudioFromTag('#square-554-Csharp').play();
        } else if ($(e.currentTarget).hasClass('d-natural')) {
          block.basePitch = 147;
          if (block.octave === 1) getAudioFromTag('#square-147-D').play();
          else if (block.octave === 2) getAudioFromTag('#square-294-D').play();
          else if (block.octave === 3) getAudioFromTag('#square-587-D').play();
        } else if ($(e.currentTarget).hasClass('d-sharp')) {
          block.basePitch = 156;
          if (block.octave === 1) getAudioFromTag('#square-156-Dsharp').play();
          else if (block.octave === 2) getAudioFromTag('#square-311-Dsharp').play();
          else if (block.octave === 3) getAudioFromTag('#square-622-Dsharp').play();
        } else if ($(e.currentTarget).hasClass('e-natural')) {
          block.basePitch = 165;
          if (block.octave === 1) getAudioFromTag('#square-165-E').play();
          else if (block.octave === 2) getAudioFromTag('#square-330-E').play();
          else if (block.octave === 3) getAudioFromTag('#square-659-E').play();
        } else if ($(e.currentTarget).hasClass('f-natural')) {
          block.basePitch = 175;
          if (block.octave === 1) getAudioFromTag('#square-175-F').play();
          else if (block.octave === 2) getAudioFromTag('#square-349-F').play();
          else if (block.octave === 3) getAudioFromTag('#square-698-F').play();
        } else if ($(e.currentTarget).hasClass('f-sharp')) {
          block.basePitch = 185;
          if (block.octave === 1) getAudioFromTag('#square-185-Fsharp').play();
          else if (block.octave === 2) getAudioFromTag('#square-370-Fsharp').play();
          else if (block.octave === 3) getAudioFromTag('#square-740-Fsharp').play();
        } else if ($(e.currentTarget).hasClass('g-natural')) {
          block.basePitch = 196;
          if (block.octave === 1) getAudioFromTag('#square-196-G').play();
          else if (block.octave === 2) getAudioFromTag('#square-392-G').play();
          else if (block.octave === 3) getAudioFromTag('#square-784-G').play();
        } else if ($(e.currentTarget).hasClass('g-sharp')) {
          block.basePitch = 208;
          if (block.octave === 1) getAudioFromTag('#square-208-Gsharp').play();
          else if (block.octave === 2) getAudioFromTag('#square-415-Gsharp').play();
          else if (block.octave === 3) getAudioFromTag('#square-831-Gsharp').play();
        } else if ($(e.currentTarget).hasClass('a-natural')) {
          block.basePitch = 220;
          if (block.octave === 1) getAudioFromTag('#square-220-A').play();
          else if (block.octave === 2) getAudioFromTag('#square-440-A').play();
          else if (block.octave === 3) getAudioFromTag('#square-880-A').play();
        } else if ($(e.currentTarget).hasClass('a-sharp')) {
          block.basePitch = 233;
          if (block.octave === 1) getAudioFromTag('#square-233-Asharp').play();
          else if (block.octave === 2) getAudioFromTag('#square-464-Asharp').play();
          else if (block.octave === 3) getAudioFromTag('#square-932-Asharp').play();
        } else if ($(e.currentTarget).hasClass('b-natural')) {
          block.basePitch = 247;
          if (block.octave === 1) getAudioFromTag('#square-247-B').play();
          else if (block.octave === 2) getAudioFromTag('#square-494-B').play();
          else if (block.octave === 3) getAudioFromTag('#square-988-B').play();
        }

        self.buildMiniBlock(block);
      }
    });
    $('.blocklyWidgetDiv').on('mousedown', '.piano-modal-parent .octave-button', function (e) { // eslint-disable-line
      if ($(e.target).hasClass('octave-up')) block.octave = (block.octave < 3) ? block.octave + 1 : block.octave;
      else if ($(e.target).hasClass('octave-down')) block.octave = (block.octave > 1) ? block.octave - 1 : block.octave;

      self.buildMiniBlock(block);

      $('.blocklyWidgetDiv .octave-screen .segment').each((index, ele) => {
        $(ele).removeClass('turned-off');
        if (block.octave === 3 && ($(ele).attr('class')).match(/segment-(E|F)/g)) $(ele).addClass('turned-off');
        else if (block.octave === 2 && ($(ele).attr('class')).match(/segment-(C|F)/g)) $(ele).addClass('turned-off');
        else if (block.octave === 1 && ($(ele).attr('class')).match(/segment-(A|D|E|F|G)/g)) $(ele).addClass('turned-off');
      });
    });

    // CLOSING
    $('.blocklyWidgetDiv').on('click', '.piano-modal-parent .piano-modal-close, .piano-modal-parent .popup-modal-under', () => {
      $('.piano-modal').addClass('popup-hide');
      // $('.matrix-modal-parent').addClass('popup-hide');
      $('.piano-modal-close').addClass('popup-hide');
      setTimeout(() => {
        $('.blocklyWidgetDiv').unbind('click');
        window.Blockly.WidgetDiv.hide();
      }, 200);
    });

    // PINS
    $('.blocklyWidgetDiv').on('click', '.piano-modal-parent .pin', (e) => {
      if (!$(e.target).hasClass('disabled')) {
        $('.piano-modal-parent .pin').removeClass('selected');
        $(e.target).addClass('selected');

        block.pin = parseInt($(e.target).attr('data-pin'), 10);
        if (block.getField('NPIN')) {
          block.getField('NPIN').setValue($(e.target).attr('data-pin'));
        }
        self.buildMiniBlock(block);
      }
    });


    // DURATION SCREEN
    let fireScreen;
    function stopFire() { clearInterval(fireScreen); }

    function updateSegments() {
      $('.blocklyWidgetDiv .duration-screen .digit').each((index, item) => {
        const segments = $(item).find('.segment');

        $(segments).each((x, segment) => {
          let duration;
          if (typeof block.duration === 'undefined') {
            block.duration = 1000;
          }
          switch (block.duration.toString().length) {
            case 4:
              duration = block.duration.toString();
              break;
            case 3:
              duration = `0${block.duration.toString()}`;
              break;
            case 2:
              duration = `00${block.duration.toString()}`;
              break;
            case 1:
              duration = `000${block.duration.toString()}`;
              break;
            default:
              duration = block.duration.toString();
          }
          $(segment).removeClass('turned-off');

          if (duration[index] === '9' && ($(segment).attr('class')).match(/segment-(E)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '7' && ($(segment).attr('class')).match(/segment-(D|E|F|G)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '6' && ($(segment).attr('class')).match(/segment-(B)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '5' && ($(segment).attr('class')).match(/segment-(B|E)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '4' && ($(segment).attr('class')).match(/segment-(A|E|G)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '3' && ($(segment).attr('class')).match(/segment-(E|F)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '2' && ($(segment).attr('class')).match(/segment-(C|F)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '1' && ($(segment).attr('class')).match(/segment-(A|D|E|F|G)/g)) $(segment).addClass('turned-off');
          else if (duration[index] === '0' && ($(segment).attr('class')).match(/segment-(D)/g)) $(segment).addClass('turned-off');
        });
      });
    }

    $('.blocklyWidgetDiv').on('mousedown', '.piano-modal-parent .duration-button', function (e) { // eslint-disable-line
      fireScreen = setInterval(() => {
        if ($(e.target).hasClass('duration-up')) block.duration = (block.duration < 10000) ? block.duration + 1 : block.duration;
        else if ($(e.target).hasClass('duration-down')) block.duration = (block.duration > 1) ? block.duration - 1 : block.duration;

        self.buildMiniBlock(block);
        updateSegments();
      }, 50);
    });
    $('.blocklyWidgetDiv').on('mouseup mouseout', '.piano-modal-parent .duration-button', function (e) { // eslint-disable-line
      stopFire();
    });

    const normalizedBlockId = this.normalizeBlocklyIds(block.id);

    // DURATION INPUT
    $('.blocklyWidgetDiv').on('blur', `#${normalizedBlockId}`, function (e) { // eslint-disable-line
      $(`#${normalizedBlockId}`).val(block.duration);
      $('.piano-modal-parent input.direct-input').css('opacity', 0);
      $('.piano-modal-parent .duration-button').css('pointer-events', 'auto');
      $('.piano-modal-parent .duration-button').css('opacity', 1);
    });


    $('.blocklyWidgetDiv').on('click', `#${normalizedBlockId}`, function (e) { // eslint-disable-line
      $(`#${normalizedBlockId}`).val(block.duration);
      $('.piano-modal-parent input.direct-input').css('opacity', 1);
      $('.piano-modal-parent .duration-button').css('pointer-events', 'none');
      $('.piano-modal-parent .duration-button').css('opacity', 0);

      $(document).on('keyup', (ev) => {
        if (ev.which === 13) {
          if ($(`#${normalizedBlockId}`).val() && /^[0-9]*$/.test($(`#${normalizedBlockId}`).val())) {
            block.duration = ($(`#${normalizedBlockId}`).val()) ? parseInt($(`#${normalizedBlockId}`).val(), 10) : block.duration;
            self.buildMiniBlock(block);
            updateSegments();
            $('.piano-modal-parent input.direct-input').blur();
            $('.piano-modal-parent input.direct-input').css('opacity', 0);
            $('.piano-modal-parent .duration-button').css('pointer-events', 'auto');
            $('.piano-modal-parent .duration-button').css('opacity', 1);
          } else { $('.piano-modal-parent input.direct-input').css('border-color', 'red'); }
        } else if (ev.which === 27) {
          $('.piano-modal-parent input.direct-input').blur();
          $('.piano-modal-parent input.direct-input').css('opacity', 0);
          $(`#${normalizedBlockId}`).val(block.duration);
          $('.piano-modal-parent .duration-button').css('pointer-events', 'auto');
          $('.piano-modal-parent .duration-button').css('opacity', 1);
        }
      });
    });
  },
};
