/* eslint import/no-extraneous-dependencies: 0 */
/* eslint dot-notation: 0 */
/* eslint quotes: 0 */
/* eslint no-multi-spaces: 0 */
/* eslint spaced-comment: 0 */
import * as localeEs from 'blockly/msg/es';

export const es = {
  ...localeEs,
};

es["ADD_COMMENT"] = "Añadir comentario";
es["CANNOT_DELETE_VARIABLE_PROCEDURE"] = "No se puede borrar la variable '%1' porque es parte de la definición de la función '%2'";
es["CHANGE_VALUE_TITLE"] = "Cambiar el valor:";
es["CLEAN_UP"] = "Limpiar los bloques";
es["COLLAPSE_ALL"] = "Contraer bloques";
es["COLLAPSE_BLOCK"] = "Contraer bloque";
es["COLOUR_BLEND_COLOUR1"] = "color 1";
es["COLOUR_BLEND_COLOUR2"] = "color 2";
es["COLOUR_BLEND_HELPURL"] = "http://meyerweb.com/eric/tools/color-blend/";  // untranslated
es["COLOUR_BLEND_RATIO"] = "proporción";
es["COLOUR_BLEND_TITLE"] = "combinar";
es["COLOUR_BLEND_TOOLTIP"] = "Combina dos colores con una proporción determinada (0,0–1,0).";
es["COLOUR_PICKER_HELPURL"] = "https://es.wikipedia.org/wiki/Color";
es["COLOUR_PICKER_TOOLTIP"] = "Elige un color de la paleta.";
es["COLOUR_RANDOM_HELPURL"] = "http://randomcolour.com";  // untranslated
es["COLOUR_RANDOM_TITLE"] = "color aleatorio";
es["COLOUR_RANDOM_TOOLTIP"] = "Elige un color al azar.";
es["COLOUR_RGB_BLUE"] = "azul";
es["COLOUR_RGB_GREEN"] = "verde";
es["COLOUR_RGB_HELPURL"] = "http://www.december.com/html/spec/colorper.html";  // untranslated
es["COLOUR_RGB_RED"] = "rojo";
es["COLOUR_RGB_TITLE"] = "colorear con";
es["COLOUR_RGB_TOOLTIP"] = "Crea un color con cantidades específicas de rojo, verde y azul. Todos los valores deben encontrarse entre 0 y 100.";
es["CONTROLS_FLOW_STATEMENTS_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#loop-termination-blocks";  // untranslated
es["CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK"] = "romper el bucle";
es["CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE"] = "continuar con la siguiente iteración del bucle";
es["CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK"] = "Romper el bucle que lo contiene.";
es["CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE"] = "Saltar el resto de este bucle, y continuar con la siguiente iteración.";
es["CONTROLS_FLOW_STATEMENTS_WARNING"] = "Atención: este bloque puede usarse solamente dentro de un bucle.";
es["CONTROLS_FOREACH_HELPURL"] = "https://es.wikipedia.org/wiki/Foreach";
es["CONTROLS_FOREACH_TITLE"] = "para cada elemento %1 en la lista %2";
es["CONTROLS_FOREACH_TOOLTIP"] = "Para cada elemento en una lista, establecer la variable '%1' al elemento y luego hacer algunas declaraciones.";
es["CONTROLS_FOR_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#count-with";  // untranslated
es["CONTROLS_FOR_TITLE"] = "contar con %1 desde %2 hasta %3 de a %4";
es["CONTROLS_FOR_TOOLTIP"] = "Hacer que la variable \"%1\" tome los valores desde el número de inicio hasta el número final, contando con el intervalo especificado, y hacer los bloques especificados.";
es["CONTROLS_IF_ELSEIF_TOOLTIP"] = "Agregar una condición a este bloque.";
es["CONTROLS_IF_ELSE_TOOLTIP"] = "Agregar una condición general final a este bloque.";
es["CONTROLS_IF_HELPURL"] = "https://github.com/google/blockly/wiki/IfElse";  // untranslated
es["CONTROLS_IF_IF_TOOLTIP"] = "Agregar, eliminar o reordenar las secciones para reconfigurar este bloque.";
es["CONTROLS_IF_MSG_ELSE"] = "si no";
es["CONTROLS_IF_MSG_ELSEIF"] = "si no, si";
es["CONTROLS_IF_MSG_IF"] = "si";
es["CONTROLS_IF_TOOLTIP_1"] = "Si un valor es verdadero, entonces hacer algunas declaraciones.";
es["CONTROLS_IF_TOOLTIP_2"] = "Si un valor es verdadero, entonces hacer el primer bloque de declaraciones.  De lo contrario, hacer el segundo bloque de declaraciones.";
es["CONTROLS_IF_TOOLTIP_3"] = "Si el primer valor es verdadero, entonces hacer el primer bloque de declaraciones. De lo contrario, si el segundo valor es verdadero, hacer el segundo bloque de declaraciones.";
es["CONTROLS_IF_TOOLTIP_4"] = "Si el primer valor es verdadero, entonces hacer el primer bloque de declaraciones. De lo contrario, si el segundo valor es verdadero, hacer el segundo bloque de declaraciones. Si ninguno de los valores son verdaderos, hacer el último bloque de declaraciones.";
es["CONTROLS_REPEAT_HELPURL"] = "https://es.wikipedia.org/wiki/Bucle_for";
es["CONTROLS_REPEAT_INPUT_DO"] = ", entonces";
es["CONTROLS_REPEAT_TITLE"] = "repetir %1 veces";
es["CONTROLS_REPEAT_TOOLTIP"] = "Hacer algunas declaraciones varias veces.";
es["CONTROLS_WHILEUNTIL_HELPURL"] = "https://github.com/google/blockly/wiki/Loops#repeat";  // untranslated
es["CONTROLS_WHILEUNTIL_OPERATOR_UNTIL"] = "repetir hasta";
es["CONTROLS_WHILEUNTIL_OPERATOR_WHILE"] = "repetir mientras";
es["CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL"] = "Mientras un valor sea falso, entonces hacer algunas declaraciones.";
es["CONTROLS_WHILEUNTIL_TOOLTIP_WHILE"] = "Mientras un valor sea verdadero, entonces hacer algunas declaraciones.";
es["DELETE_ALL_BLOCKS"] = "¿Eliminar todos los %1 bloques?";
es["DELETE_BLOCK"] = "Eliminar bloque";
es["DELETE_VARIABLE"] = "Borrar la variable \"%1\"";
es["DELETE_VARIABLE_CONFIRMATION"] = "¿Borrar %1 usos de la variable \"%2\"?";
es["DELETE_X_BLOCKS"] = "Eliminar %1 bloques";
es["DISABLE_BLOCK"] = "Desactivar bloque";
es["DUPLICATE_BLOCK"] = "Duplicar";
es["DUPLICATE_COMMENT"] = "Duplicate Comment";  // untranslated
es["ENABLE_BLOCK"] = "Activar bloque";
es["EXPAND_ALL"] = "Expandir bloques";
es["EXPAND_BLOCK"] = "Expandir bloque";
es["EXTERNAL_INPUTS"] = "Entradas externas";
es["FUNCTIONS_CALL_TITLE"] = "call";  // untranslated
es["FUNCTIONS_DEFAULT_BOOLEAN_ARG_NAME"] = "bool";  // untranslated
es["FUNCTIONS_DEFAULT_CUSTOM_ARG_NAME"] = "arg";  // untranslated
es["FUNCTIONS_DEFAULT_FUNCTION_NAME"] = "do_something";  // untranslated
es["FUNCTIONS_DEFAULT_NUMBER_ARG_NAME"] = "num";  // untranslated
es["FUNCTIONS_DEFAULT_STRING_ARG_NAME"] = "text";  // untranslated
es["FUNCTIONS_EDIT_OPTION"] = "Edit";  // untranslated
es["FUNCTION_CALL_TOOLTIP"] = "Call a function that was previously defined.";  // untranslated
es["FUNCTION_CREATE_NEW"] = "Create a Function";  // untranslated
es["FUNCTION_WARNING_ARG_NAME_IS_FUNCTION_NAME"] = "Argument names must not be the same as the function name.";  // untranslated
es["FUNCTION_WARNING_DUPLICATE_ARG"] = "Functions cannot use the same argument name more than once.";  // untranslated
es["FUNCTION_WARNING_EMPTY_NAME"] = "Function and argument names cannot be empty.";  // untranslated
es["HELP"] = "Ayuda";
es["INLINE_INPUTS"] = "Entradas en línea";
es["IOS_CANCEL"] = "Cancelar";
es["IOS_ERROR"] = "Error";
es["IOS_OK"] = "Crear";
es["IOS_EDIT"] = "Cambiar";
es["IOS_PROCEDURES_ADD_INPUT"] = "+ Añadir entrada";
es["IOS_PROCEDURES_ALLOW_STATEMENTS"] = "permite sentencias";
es["IOS_PROCEDURES_DUPLICATE_INPUTS_ERROR"] = "Esta función tiene entradas duplicadas.";
es["IOS_PROCEDURES_INPUTS"] = "ENTRADAS";
es["IOS_VARIABLES_ADD_BUTTON"] = "Añadir";
es["IOS_VARIABLES_ADD_VARIABLE"] = "+ Añadir variable";
es["IOS_VARIABLES_DELETE_BUTTON"] = "Borrar";
es["IOS_VARIABLES_EMPTY_NAME_ERROR"] = "No puedes usar un nombre de variable vacío.";
es["IOS_VARIABLES_RENAME_BUTTON"] = "Renombrar";
es["IOS_VARIABLES_VARIABLE_NAME"] = "Nombre de la variable";
es["LISTS_CREATE_EMPTY_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#create-empty-list";  // untranslated
es["LISTS_CREATE_EMPTY_TITLE"] = "crear lista vacía";
es["LISTS_CREATE_EMPTY_TOOLTIP"] = "Devuelve una lista, de longitud 0, sin ningún dato";
es["LISTS_CREATE_WITH_CONTAINER_TITLE_ADD"] = "lista";
es["LISTS_CREATE_WITH_CONTAINER_TOOLTIP"] = "Agregar, eliminar o reorganizar las secciones para reconfigurar este bloque de lista.";
es["LISTS_CREATE_WITH_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#create-list-with";
es["LISTS_CREATE_WITH_INPUT_WITH"] = "crear lista con";
es["LISTS_CREATE_WITH_ITEM_TOOLTIP"] = "Agregar un elemento a la lista.";
es["LISTS_CREATE_WITH_TOOLTIP"] = "Crear una lista con cualquier número de elementos.";
es["LISTS_GET_INDEX_FIRST"] = "primero";
es["LISTS_GET_INDEX_FROM_END"] = "# del final";
es["LISTS_GET_INDEX_FROM_START"] = "#";  // untranslated
es["LISTS_GET_INDEX_GET"] = "obtener";
es["LISTS_GET_INDEX_GET_REMOVE"] = "obtener y eliminar";
es["LISTS_GET_INDEX_LAST"] = "último";
es["LISTS_GET_INDEX_RANDOM"] = "aleatorio";
es["LISTS_GET_INDEX_REMOVE"] = "eliminar";
es["LISTS_GET_INDEX_TAIL"] = "";  // untranslated
es["LISTS_GET_INDEX_TOOLTIP_GET_FIRST"] = "Devuelve el primer elemento de una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_FROM"] = "Devuelve el elemento en la posición especificada en una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_LAST"] = "Devuelve el último elemento de una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_RANDOM"] = "Devuelve un elemento aleatorio en una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FIRST"] = "Elimina y devuelve el primer elemento de una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_FROM"] = "Elimina y devuelve el elemento en la posición especificada en una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_LAST"] = "Elimina y devuelve el último elemento de una lista.";
es["LISTS_GET_INDEX_TOOLTIP_GET_REMOVE_RANDOM"] = "Elimina y devuelve un elemento aleatorio en una lista.";
es["LISTS_GET_INDEX_TOOLTIP_REMOVE_FIRST"] = "Elimina el primer elemento de una lista.";
es["LISTS_GET_INDEX_TOOLTIP_REMOVE_FROM"] = "Elimina el elemento en la posición especificada en una lista.";
es["LISTS_GET_INDEX_TOOLTIP_REMOVE_LAST"] = "Elimina el último elemento de una lista.";
es["LISTS_GET_INDEX_TOOLTIP_REMOVE_RANDOM"] = "Elimina un elemento aleatorio en una lista.";
es["LISTS_GET_SUBLIST_END_FROM_END"] = "hasta # del final";
es["LISTS_GET_SUBLIST_END_FROM_START"] = "hasta #";
es["LISTS_GET_SUBLIST_END_LAST"] = "hasta el último";
es["LISTS_GET_SUBLIST_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#getting-a-sublist";  // untranslated
es["LISTS_GET_SUBLIST_START_FIRST"] = "obtener sublista desde el primero";
es["LISTS_GET_SUBLIST_START_FROM_END"] = "obtener sublista desde # del final";
es["LISTS_GET_SUBLIST_START_FROM_START"] = "obtener sublista desde #";
es["LISTS_GET_SUBLIST_TAIL"] = "";  // untranslated
es["LISTS_GET_SUBLIST_TOOLTIP"] = "Crea una copia de la parte especificada de una lista.";
es["LISTS_INDEX_FROM_END_TOOLTIP"] = "%1 es el último elemento.";
es["LISTS_INDEX_FROM_START_TOOLTIP"] = "%1 es el primer elemento.";
es["LISTS_INDEX_OF_FIRST"] = "encontrar la primera aparición del elemento";
es["LISTS_INDEX_OF_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#getting-items-from-a-list";  // untranslated
es["LISTS_INDEX_OF_LAST"] = "encontrar la última aparición del elemento";
es["LISTS_INDEX_OF_TOOLTIP"] = "Devuelve el índice de la primera/última aparición del elemento en la lista. Devuelve %1 si el elemento no se encuentra.";
es["LISTS_INLIST"] = "en la lista";
es["LISTS_ISEMPTY_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#is-empty";  // untranslated
es["LISTS_ISEMPTY_TITLE"] = "%1 está vacía";
es["LISTS_ISEMPTY_TOOLTIP"] = "Devuelve verdadero si la lista está vacía.";
es["LISTS_LENGTH_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#length-of";  // untranslated
es["LISTS_LENGTH_TITLE"] = "longitud de %1";
es["LISTS_LENGTH_TOOLTIP"] = "Devuelve la longitud de una lista.";
es["LISTS_REPEAT_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#create-list-with";  // untranslated
es["LISTS_REPEAT_TITLE"] = "crear lista con el elemento %1 repetido %2 veces";
es["LISTS_REPEAT_TOOLTIP"] = "Crea una lista que consta de un valor dado repetido el número de veces especificado.";
es["LISTS_REVERSE_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#reversing-a-list";
es["LISTS_REVERSE_MESSAGE0"] = "invertir %1";
es["LISTS_REVERSE_TOOLTIP"] = "Invertir una copia de una lista.";
es["LISTS_SET_INDEX_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#in-list--set";  // untranslated
es["LISTS_SET_INDEX_INPUT_TO"] = "como";
es["LISTS_SET_INDEX_INSERT"] = "insertar en";
es["LISTS_SET_INDEX_SET"] = "establecer";
es["LISTS_SET_INDEX_TOOLTIP_INSERT_FIRST"] = "Inserta el elemento al inicio de una lista.";
es["LISTS_SET_INDEX_TOOLTIP_INSERT_FROM"] = "Inserta el elemento en la posición especificada en una lista.";
es["LISTS_SET_INDEX_TOOLTIP_INSERT_LAST"] = "Añade el elemento al final de una lista.";
es["LISTS_SET_INDEX_TOOLTIP_INSERT_RANDOM"] = "Inserta el elemento aleatoriamente en una lista.";
es["LISTS_SET_INDEX_TOOLTIP_SET_FIRST"] = "Establece el primer elemento de una lista.";
es["LISTS_SET_INDEX_TOOLTIP_SET_FROM"] = "Establece el elemento en la posición especificada en una lista.";
es["LISTS_SET_INDEX_TOOLTIP_SET_LAST"] = "Establece el último elemento de una lista.";
es["LISTS_SET_INDEX_TOOLTIP_SET_RANDOM"] = "Establece un elemento aleatorio en una lista.";
es["LISTS_SORT_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#sorting-a-list";
es["LISTS_SORT_ORDER_ASCENDING"] = "ascendente";
es["LISTS_SORT_ORDER_DESCENDING"] = "descendente";
es["LISTS_SORT_TITLE"] = "orden %1 %2 %3";
es["LISTS_SORT_TOOLTIP"] = "Ordenar una copia de una lista.";
es["LISTS_SORT_TYPE_IGNORECASE"] = "alfabético, ignorar mayúscula/minúscula";
es["LISTS_SORT_TYPE_NUMERIC"] = "numérico";
es["LISTS_SORT_TYPE_TEXT"] = "alfabético";
es["LISTS_SPLIT_HELPURL"] = "https://github.com/google/blockly/wiki/Lists#splitting-strings-and-joining-lists";
es["LISTS_SPLIT_LIST_FROM_TEXT"] = "hacer lista a partir de texto";
es["LISTS_SPLIT_TEXT_FROM_LIST"] = "hacer texto a partir de lista";
es["LISTS_SPLIT_TOOLTIP_JOIN"] = "Unir una lista de textos en un solo texto, separado por un delimitador.";
es["LISTS_SPLIT_TOOLTIP_SPLIT"] = "Dividir el texto en una lista de textos, separando en cada delimitador.";
es["LISTS_SPLIT_WITH_DELIMITER"] = "con delimitador";
es["LOGIC_BOOLEAN_FALSE"] = "falso";
es["LOGIC_BOOLEAN_HELPURL"] = "https://github.com/google/blockly/wiki/Logic#values";  // untranslated
es["LOGIC_BOOLEAN_TOOLTIP"] = "Devuelve verdadero o falso.";
es["LOGIC_BOOLEAN_TRUE"] = "verdadero";
es["LOGIC_COMPARE_HELPURL"] = "https://es.wikipedia.org/wiki/Desigualdad_matemática";
es["LOGIC_COMPARE_TOOLTIP_EQ"] = "Devuelve verdadero si ambas entradas son iguales.";
es["LOGIC_COMPARE_TOOLTIP_GT"] = "Devuelve verdadero si la primera entrada es mayor que la segunda entrada.";
es["LOGIC_COMPARE_TOOLTIP_GTE"] = "Devuelve verdadero si la primera entrada es mayor o igual a la segunda entrada.";
es["LOGIC_COMPARE_TOOLTIP_LT"] = "Devuelve verdadero si la primera entrada es menor que la segunda entrada.";
es["LOGIC_COMPARE_TOOLTIP_LTE"] = "Devuelve verdadero si la primera entrada es menor que o igual a la segunda entrada.";
es["LOGIC_COMPARE_TOOLTIP_NEQ"] = "Devuelve verdadero si ambas entradas son distintas.";
es["LOGIC_NEGATE_HELPURL"] = "https://github.com/google/blockly/wiki/Logic#not";  // untranslated
es["LOGIC_NEGATE_TITLE"] = "no %1";
es["LOGIC_NEGATE_TOOLTIP"] = "Devuelve verdadero si la entrada es falsa. Devuelve falso si la entrada es verdadera.";
es["LOGIC_NULL"] = "nulo";
es["LOGIC_NULL_HELPURL"] = "https://en.wikipedia.org/wiki/Nullable_type";  // untranslated
es["LOGIC_NULL_TOOLTIP"] = "Devuelve nulo.";
es["LOGIC_OPERATION_AND"] = "y";
es["LOGIC_OPERATION_HELPURL"] = "https://github.com/google/blockly/wiki/Logic#logical-operations";  // untranslated
es["LOGIC_OPERATION_OR"] = "o";
es["LOGIC_OPERATION_TOOLTIP_AND"] = "Devuelve verdadero si ambas entradas son verdaderas.";
es["LOGIC_OPERATION_TOOLTIP_OR"] = "Devuelve verdadero si al menos una de las entradas es verdadera.";
es["LOGIC_TERNARY_CONDITION"] = "prueba";
es["LOGIC_TERNARY_HELPURL"] = "https://en.wikipedia.org/wiki/%3F:";  // untranslated
es["LOGIC_TERNARY_IF_FALSE"] = "si es falso";
es["LOGIC_TERNARY_IF_TRUE"] = "si es verdadero";
es["LOGIC_TERNARY_TOOLTIP"] = "Comprueba la condición en \"prueba\". Si la condición es verdadera, devuelve el valor \"si es verdadero\"; de lo contrario, devuelve el valor \"si es falso\".";
es["MATH_ADDITION_SYMBOL"] = "+";  // untranslated
es["MATH_ARITHMETIC_HELPURL"] = "https://es.wikipedia.org/wiki/Aritmética";
es["MATH_ARITHMETIC_TOOLTIP_ADD"] = "Devuelve la suma de ambos números.";
es["MATH_ARITHMETIC_TOOLTIP_DIVIDE"] = "Devuelve el cociente de ambos números.";
es["MATH_ARITHMETIC_TOOLTIP_MINUS"] = "Devuelve la diferencia de ambos números.";
es["MATH_ARITHMETIC_TOOLTIP_MULTIPLY"] = "Devuelve el producto de ambos números.";
es["MATH_ARITHMETIC_TOOLTIP_POWER"] = "Devuelve el primer número elevado a la potencia del segundo.";
es["MATH_CHANGE_HELPURL"] = "https://en.wikipedia.org/wiki/Programming_idiom#Incrementing_a_counter";
es["MATH_CHANGE_TITLE"] = "añadir %2 a %1";
es["MATH_CHANGE_TOOLTIP"] = "Añadir un número a la variable «%1».";
es["MATH_CONSTANT_HELPURL"] = "https://es.wikipedia.org/wiki/Anexo:Constantes_matemáticas";
es["MATH_CONSTANT_TOOLTIP"] = "Devuelve una de las constantes comunes: π (3.141…), e (2.718…), φ (1.618…), sqrt(2) (1.414…), sqrt(½) (0.707…) o ∞ (infinito).";
es["MATH_CONSTRAIN_HELPURL"] = "https://en.wikipedia.org/wiki/Clamping_(graphics)";  // untranslated
es["MATH_CONSTRAIN_TITLE"] = "limitar %1 entre %2 y %3";
es["MATH_CONSTRAIN_TOOLTIP"] = "Limitar un número entre los límites especificados (inclusive).";
es["MATH_DIVISION_SYMBOL"] = "÷";  // untranslated
es["MATH_IS_DIVISIBLE_BY"] = "es divisible por";
es["MATH_IS_EVEN"] = "es par";
es["MATH_IS_NEGATIVE"] = "es negativo";
es["MATH_IS_ODD"] = "es impar";
es["MATH_IS_POSITIVE"] = "es positivo";
es["MATH_IS_PRIME"] = "es primo";
es["MATH_IS_TOOLTIP"] = "Comprueba si un número es par, impar, primo, entero, positivo, negativo, o si es divisible por un número determinado. Devuelve verdadero o falso.";
es["MATH_IS_WHOLE"] = "es entero";
es["MATH_MODULO_HELPURL"] = "https://en.wikipedia.org/wiki/Modulo_operation";
es["MATH_MODULO_TITLE"] = "resto de %1 ÷ %2";
es["MATH_MODULO_TOOLTIP"] = "Devuelve el resto al dividir los dos números.";
es["MATH_MULTIPLICATION_SYMBOL"] = "×";  // untranslated
es["MATH_NUMBER_HELPURL"] = "https://es.wikipedia.org/wiki/Número";
es["MATH_NUMBER_TOOLTIP"] = "Un número.";
es["MATH_ONLIST_HELPURL"] = "";  // untranslated
es["MATH_ONLIST_OPERATOR_AVERAGE"] = "promedio de la lista";
es["MATH_ONLIST_OPERATOR_MAX"] = "máximo de la lista";
es["MATH_ONLIST_OPERATOR_MEDIAN"] = "mediana de la lista";
es["MATH_ONLIST_OPERATOR_MIN"] = "mínimo de la lista";
es["MATH_ONLIST_OPERATOR_MODE"] = "modas de la lista";
es["MATH_ONLIST_OPERATOR_RANDOM"] = "elemento aleatorio de la lista";
es["MATH_ONLIST_OPERATOR_STD_DEV"] = "desviación estándar de la lista";
es["MATH_ONLIST_OPERATOR_SUM"] = "suma de la lista";
es["MATH_ONLIST_TOOLTIP_AVERAGE"] = "Devuelve el promedio (media aritmética) de los valores numéricos en la lista.";
es["MATH_ONLIST_TOOLTIP_MAX"] = "Devuelve el número más grande en la lista.";
es["MATH_ONLIST_TOOLTIP_MEDIAN"] = "Devuelve la mediana en la lista.";
es["MATH_ONLIST_TOOLTIP_MIN"] = "Devuelve el número más pequeño en la lista.";
es["MATH_ONLIST_TOOLTIP_MODE"] = "Devuelve una lista de los elementos más comunes en la lista.";
es["MATH_ONLIST_TOOLTIP_RANDOM"] = "Devuelve un elemento aleatorio de la lista.";
es["MATH_ONLIST_TOOLTIP_STD_DEV"] = "Devuelve la desviación estándar de la lista.";
es["MATH_ONLIST_TOOLTIP_SUM"] = "Devuelve la suma de todos los números en la lista.";
es["MATH_POWER_SYMBOL"] = "^";  // untranslated
es["MATH_RANDOM_FLOAT_HELPURL"] = "https://es.wikipedia.org/wiki/Generador_de_números_aleatorios";
es["MATH_RANDOM_FLOAT_TITLE_RANDOM"] = "fracción aleatoria";
es["MATH_RANDOM_FLOAT_TOOLTIP"] = "Devuelve una fracción aleatoria entre 0,0 (ambos inclusive) y 1.0 (exclusivo).";
es["MATH_RANDOM_INT_HELPURL"] = "https://es.wikipedia.org/wiki/Generador_de_números_aleatorios";
es["MATH_RANDOM_INT_TITLE"] = "entero aleatorio de %1 a %2";
es["MATH_RANDOM_INT_TOOLTIP"] = "Devuelve un entero aleatorio entre los dos límites especificados, inclusive.";
es["MATH_ROUND_HELPURL"] = "https://es.wikipedia.org/wiki/Redondeo";
es["MATH_ROUND_OPERATOR_ROUND"] = "redondear";
es["MATH_ROUND_OPERATOR_ROUNDDOWN"] = "redondear a la baja";
es["MATH_ROUND_OPERATOR_ROUNDUP"] = "redondear al alza";
es["MATH_ROUND_TOOLTIP"] = "Redondear un número al alza o a la baja.";
es["MATH_SINGLE_HELPURL"] = "https://es.wikipedia.org/wiki/Ra%C3%ADz_cuadrada";
es["MATH_SINGLE_OP_ABSOLUTE"] = "absoluto";
es["MATH_SINGLE_OP_ROOT"] = "raíz cuadrada";
es["MATH_SINGLE_TOOLTIP_ABS"] = "Devuelve el valor absoluto de un número.";
es["MATH_SINGLE_TOOLTIP_EXP"] = "Devuelve e a la potencia de un número.";
es["MATH_SINGLE_TOOLTIP_LN"] = "Devuelve el logaritmo natural de un número.";
es["MATH_SINGLE_TOOLTIP_LOG10"] = "Devuelve el logaritmo base 10 de un número.";
es["MATH_SINGLE_TOOLTIP_NEG"] = "Devuelve la negación de un número.";
es["MATH_SINGLE_TOOLTIP_POW10"] = "Devuelve 10 a la potencia de un número.";
es["MATH_SINGLE_TOOLTIP_ROOT"] = "Devuelve la raíz cuadrada de un número.";
es["MATH_SUBTRACTION_SYMBOL"] = "-";  // untranslated
es["MATH_TRIG_ACOS"] = "acos";  // untranslated
es["MATH_TRIG_ASIN"] = "asin";  // untranslated
es["MATH_TRIG_ATAN"] = "atan";  // untranslated
es["MATH_TRIG_COS"] = "cos";  // untranslated
es["MATH_TRIG_HELPURL"] = "https://es.wikipedia.org/wiki/Función_trigonométrica";
es["MATH_TRIG_SIN"] = "sin";  // untranslated
es["MATH_TRIG_TAN"] = "tan";  // untranslated
es["MATH_TRIG_TOOLTIP_ACOS"] = "Devuelve el arcocoseno de un número.";
es["MATH_TRIG_TOOLTIP_ASIN"] = "Devuelve el arcoseno de un número.";
es["MATH_TRIG_TOOLTIP_ATAN"] = "Devuelve el arcotangente de un número.";
es["MATH_TRIG_TOOLTIP_COS"] = "Devuelve el coseno de un grado (no radián).";
es["MATH_TRIG_TOOLTIP_SIN"] = "Devuelve el seno de un grado (no radián).";
es["MATH_TRIG_TOOLTIP_TAN"] = "Devuelve la tangente de un grado (no radián).";
es["NEW_COLOUR_VARIABLE"] = "Create colour variable...";  // untranslated
es["NEW_NUMBER_VARIABLE"] = "Create number variable...";  // untranslated
es["NEW_STRING_VARIABLE"] = "Create string variable...";  // untranslated
es["NEW_VARIABLE"] = "Crear variable…";
es["NEW_VARIABLE_DROPDOWN"] = "New variable...";  // untranslated
es["NEW_VARIABLE_TITLE"] = "Nombre de variable nueva:";
es["NEW_VARIABLE_TYPE_DROPDOWN"] = "New %1 variable...";  // untranslated
es["NEW_VARIABLE_TYPE_TITLE"] = "New variable type:";  // untranslated
es["ORDINAL_NUMBER_SUFFIX"] = "";  // untranslated
es["PROCEDURES_ALLOW_STATEMENTS"] = "permitir declaraciones";
es["PROCEDURES_BEFORE_PARAMS"] = "con: ";
es["PROCEDURES_CALLNORETURN_HELPURL"] = "https://es.wikipedia.org/wiki/Subrutina";
es["PROCEDURES_CALLNORETURN_TOOLTIP"] = "Ejecutar la función '%1' definida por el usuario.";
es["PROCEDURES_CALLRETURN_HELPURL"] = "https://es.wikipedia.org/wiki/Subrutina";
es["PROCEDURES_CALLRETURN_TOOLTIP"] = "Ejecutar la función '%1' definida por el usuario y usar su retorno.";
es["PROCEDURES_CALL_BEFORE_PARAMS"] = "con: ";
es["PROCEDURES_CREATE_DO"] = "Crear '%1'";
es["PROCEDURES_DEFNORETURN_COMMENT"] = "Describe esta función...";
es["PROCEDURES_DEFNORETURN_DO"] = "";  // untranslated
es["PROCEDURES_DEFNORETURN_HELPURL"] = "https://en.wikipedia.org/wiki/Subroutine";  // untranslated
es["PROCEDURES_DEFNORETURN_PROCEDURE"] = "hacer algo";
es["PROCEDURES_DEFNORETURN_TITLE"] = "hacer";
es["PROCEDURES_DEFNORETURN_TOOLTIP"] = "Crea una función sin retorno.";
es["PROCEDURES_DEFRETURN_HELPURL"] = "https://en.wikipedia.org/wiki/Subroutine";  // untranslated
es["PROCEDURES_DEFRETURN_RETURN"] = "devuelve";
es["PROCEDURES_DEFRETURN_TOOLTIP"] = "Crea una función con retorno.";
es["PROCEDURES_DEF_DUPLICATE_WARNING"] = "Advertencia: Esta función tiene parámetros duplicados.";
es["PROCEDURES_HIGHLIGHT_DEF"] = "Destacar la definición de la función";
es["PROCEDURES_IFRETURN_HELPURL"] = "http://c2.com/cgi/wiki?GuardClause";  // untranslated
es["PROCEDURES_IFRETURN_TOOLTIP"] = "Si un valor es verdadero, entonces devolver un segundo valor.";
es["PROCEDURES_IFRETURN_WARNING"] = "Advertencia: Este bloque solo se puede usar dentro de la definición de una función.";
es["PROCEDURES_MUTATORARG_TITLE"] = "nombre de entrada:";
es["PROCEDURES_MUTATORARG_TOOLTIP"] = "Agregar una entrada a la función.";
es["PROCEDURES_MUTATORCONTAINER_TITLE"] = "entradas";
es["PROCEDURES_MUTATORCONTAINER_TOOLTIP"] = "Agregar, quitar, o reordenar entradas para esta función.";
es["REDO"] = "Rehacer";
es["REMOVE_COMMENT"] = "Eliminar comentario";
es["RENAME_VARIABLE"] = "Renombrar la variable…";
es["RENAME_VARIABLE_TITLE"] = "Renombrar todas las variables «%1» a:";
es["TEXT_APPEND_HELPURL"] = "https://github.com/google/blockly/wiki/Text#text-modification";  // untranslated
es["TEXT_APPEND_TITLE"] = "a %1 añade el texto %2";
es["TEXT_APPEND_TOOLTIP"] = "Añadir texto a la variable '%1'.";
es["TEXT_CHANGECASE_HELPURL"] = "https://github.com/google/blockly/wiki/Text#adjusting-text-case";  // untranslated
es["TEXT_CHANGECASE_OPERATOR_LOWERCASE"] = "a minúsculas";
es["TEXT_CHANGECASE_OPERATOR_TITLECASE"] = "a Mayúsculas Cada Palabra";
es["TEXT_CHANGECASE_OPERATOR_UPPERCASE"] = "a MAYÚSCULAS";
es["TEXT_CHANGECASE_TOOLTIP"] = "Devuelve una copia del texto en un tamaño diferente.";
es["TEXT_CHARAT_FIRST"] = "obtener la primera letra";
es["TEXT_CHARAT_FROM_END"] = "obtener la letra # del final";
es["TEXT_CHARAT_FROM_START"] = "obtener la letra #";
es["TEXT_CHARAT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#extracting-text";  // untranslated
es["TEXT_CHARAT_LAST"] = "obtener la última letra";
es["TEXT_CHARAT_RANDOM"] = "obtener letra aleatoria";
es["TEXT_CHARAT_TAIL"] = "";  // untranslated
es["TEXT_CHARAT_TITLE"] = "en el texto %1 %2";
es["TEXT_CHARAT_TOOLTIP"] = "Devuelve la letra en la posición especificada.";
es["TEXT_COUNT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#counting-substrings";
es["TEXT_COUNT_MESSAGE0"] = "contar %1 en %2";
es["TEXT_COUNT_TOOLTIP"] = "Cuantas veces aparece un texto dentro de otro texto.";
es["TEXT_CREATE_JOIN_ITEM_TOOLTIP"] = "Agregar un elemento al texto.";
es["TEXT_CREATE_JOIN_TITLE_JOIN"] = "unir";
es["TEXT_CREATE_JOIN_TOOLTIP"] = "Agregar, eliminar o reordenar las secciones para reconfigurar este bloque de texto.";
es["TEXT_GET_SUBSTRING_END_FROM_END"] = "hasta la letra # del final";
es["TEXT_GET_SUBSTRING_END_FROM_START"] = "hasta la letra #";
es["TEXT_GET_SUBSTRING_END_LAST"] = "hasta la última letra";
es["TEXT_GET_SUBSTRING_HELPURL"] = "https://github.com/google/blockly/wiki/Text#extracting-a-region-of-text";  // untranslated
es["TEXT_GET_SUBSTRING_INPUT_IN_TEXT"] = "en el texto";
es["TEXT_GET_SUBSTRING_START_FIRST"] = "obtener subcadena desde la primera letra";
es["TEXT_GET_SUBSTRING_START_FROM_END"] = "obtener subcadena desde la letra # del final";
es["TEXT_GET_SUBSTRING_START_FROM_START"] = "obtener subcadena desde la letra #";
es["TEXT_GET_SUBSTRING_TAIL"] = "";  // untranslated
es["TEXT_GET_SUBSTRING_TOOLTIP"] = "Devuelve una porción determinada del texto.";
es["TEXT_INDEXOF_HELPURL"] = "https://github.com/google/blockly/wiki/Text#finding-text";  // untranslated
es["TEXT_INDEXOF_OPERATOR_FIRST"] = "encontrar la primera aparición del texto";
es["TEXT_INDEXOF_OPERATOR_LAST"] = "encontrar la última aparición del texto";
es["TEXT_INDEXOF_TITLE"] = "en el texto %1 %2 %3";
es["TEXT_INDEXOF_TOOLTIP"] = "Devuelve el índice de la primera/última aparición del primer texto en el segundo texto. Devuelve %1 si el texto no se encuentra.";
es["TEXT_ISEMPTY_HELPURL"] = "https://github.com/google/blockly/wiki/Text#checking-for-empty-text";  // untranslated
es["TEXT_ISEMPTY_TITLE"] = "%1 está vacío";
es["TEXT_ISEMPTY_TOOLTIP"] = "Devuelve verdadero si el texto proporcionado está vacío.";
es["TEXT_JOIN_HELPURL"] = "https://github.com/google/blockly/wiki/Text#text-creation";  // untranslated
es["TEXT_JOIN_TITLE_CREATEWITH"] = "crear texto con";
es["TEXT_JOIN_TOOLTIP"] = "Crear un fragmento de texto al unir cualquier número de elementos.";
es["TEXT_LENGTH_HELPURL"] = "https://github.com/google/blockly/wiki/Text#text-modification";  // untranslated
es["TEXT_LENGTH_TITLE"] = "longitud de %1";
es["TEXT_LENGTH_TOOLTIP"] = "Devuelve el número de letras (espacios incluidos) del texto proporcionado.";
es["TEXT_PRINT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#printing-text";  // untranslated
es["TEXT_PRINT_TITLE"] = "imprimir %1";
es["TEXT_PRINT_TOOLTIP"] = "Imprimir el texto, número u otro valor especificado.";
es["TEXT_PROMPT_HELPURL"] = "https://github.com/google/blockly/wiki/Text#getting-input-from-the-user";  // untranslated
es["TEXT_PROMPT_TOOLTIP_NUMBER"] = "Solicitar al usuario un número.";
es["TEXT_PROMPT_TOOLTIP_TEXT"] = "Solicitar al usuario un texto.";
es["TEXT_PROMPT_TYPE_NUMBER"] = "solicitar número con el mensaje";
es["TEXT_PROMPT_TYPE_TEXT"] = "solicitar texto con el mensaje";
es["TEXT_REPLACE_HELPURL"] = "https://github.com/google/blockly/wiki/Text#replacing-substrings";
es["TEXT_REPLACE_MESSAGE0"] = "reemplazar %1 con %2 en %3";
es["TEXT_REPLACE_TOOLTIP"] = "Reemplazar todas las veces que un texto dentro de otro texto.";
es["TEXT_REVERSE_HELPURL"] = "https://github.com/google/blockly/wiki/Text#reversing-text";
es["TEXT_REVERSE_MESSAGE0"] = "invertir %1";
es["TEXT_REVERSE_TOOLTIP"] = "Invierte el orden de los caracteres en el texto.";
es["TEXT_TEXT_HELPURL"] = "https://es.wikipedia.org/wiki/Cadena_de_caracteres";
es["TEXT_TEXT_TOOLTIP"] = "Una letra, palabra o línea de texto.";
es["TEXT_TRIM_HELPURL"] = "https://github.com/google/blockly/wiki/Text#trimming-removing-spaces";  // untranslated
es["TEXT_TRIM_OPERATOR_BOTH"] = "quitar espacios de ambos lados de";
es["TEXT_TRIM_OPERATOR_LEFT"] = "quitar espacios iniciales de";
es["TEXT_TRIM_OPERATOR_RIGHT"] = "quitar espacios finales de";
es["TEXT_TRIM_TOOLTIP"] = "Devuelve una copia del texto sin los espacios de uno o ambos extremos.";
es["TODAY"] = "Hoy";
es["UNDO"] = "Deshacer";
es["VARIABLES_DEFAULT_NAME"] = "elemento";
es["VARIABLES_GET_CREATE_SET"] = "Crear 'establecer %1'";
es["VARIABLES_GET_HELPURL"] = "https://github.com/google/blockly/wiki/Variables#get";  // untranslated
es["VARIABLES_GET_TOOLTIP"] = "Devuelve el valor de esta variable.";
es["VARIABLES_SET"] = "establecer %1 a %2";
es["VARIABLES_SET_CREATE_GET"] = "Crear 'obtener %1'";
es["VARIABLES_SET_HELPURL"] = "https://github.com/google/blockly/wiki/Variables#set";  // untranslated
es["VARIABLES_SET_TOOLTIP"] = "Establece esta variable para que sea igual a la entrada.";
es["VARIABLE_ALREADY_EXISTS_NONAME"] = "La variable ya existe";
es["VARIABLE_ALREADY_EXISTS"] = 'Ya existe una variable o función llamada "%1"';
es["VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE"] = 'Ya existe una variable llamada "%1" de tipo "%2"';
es["WORKSPACE_COMMENT_DEFAULT_TEXT"] = "Say something...";  // untranslated
es["PROCEDURES_DEFRETURN_TITLE"] = es["PROCEDURES_DEFNORETURN_TITLE"];
es["CONTROLS_IF_IF_TITLE_IF"] = es["CONTROLS_IF_MSG_IF"];
es["CONTROLS_WHILEUNTIL_INPUT_DO"] = es["CONTROLS_REPEAT_INPUT_DO"];
es["CONTROLS_IF_MSG_THEN"] = es["CONTROLS_REPEAT_INPUT_DO"];
es["CONTROLS_IF_ELSE_TITLE_ELSE"] = es["CONTROLS_IF_MSG_ELSE"];
es["PROCEDURES_DEFRETURN_PROCEDURE"] = es["PROCEDURES_DEFNORETURN_PROCEDURE"];
es["LISTS_GET_SUBLIST_INPUT_IN_LIST"] = es["LISTS_INLIST"];
es["LISTS_GET_INDEX_INPUT_IN_LIST"] = es["LISTS_INLIST"];
es["MATH_CHANGE_TITLE_ITEM"] = es["VARIABLES_DEFAULT_NAME"];
es["PROCEDURES_DEFRETURN_DO"] = es["PROCEDURES_DEFNORETURN_DO"];
es["CONTROLS_IF_ELSEIF_TITLE_ELSEIF"] = es["CONTROLS_IF_MSG_ELSEIF"];
es["LISTS_GET_INDEX_HELPURL"] = es["LISTS_INDEX_OF_HELPURL"];
es["CONTROLS_FOREACH_INPUT_DO"] = es["CONTROLS_REPEAT_INPUT_DO"];
es["LISTS_SET_INDEX_INPUT_IN_LIST"] = es["LISTS_INLIST"];
es["CONTROLS_FOR_INPUT_DO"] = es["CONTROLS_REPEAT_INPUT_DO"];
es["LISTS_CREATE_WITH_ITEM_TITLE"] = es["VARIABLES_DEFAULT_NAME"];
es["TEXT_APPEND_VARIABLE"] = es["VARIABLES_DEFAULT_NAME"];
es["TEXT_CREATE_JOIN_ITEM_TITLE_ITEM"] = es["VARIABLES_DEFAULT_NAME"];
es["LISTS_INDEX_OF_INPUT_IN_LIST"] = es["LISTS_INLIST"];
es["PROCEDURES_DEFRETURN_COMMENT"] = es["PROCEDURES_DEFNORETURN_COMMENT"];

es["MATH_HUE"] = "230";
es["LOOPS_HUE"] = "120";
es["LISTS_HUE"] = "260";
es["LOGIC_HUE"] = "210";
es["VARIABLES_HUE"] = "330";
es["TEXTS_HUE"] = "160";
es["REPORTERS_HUE"] = "290";
es["PROCEDURES_HUE"] = "290";
es["COLOUR_HUE"] = "20";
es["VARIABLES_DYNAMIC_HUE"] = "310";



///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////////////////////////
es["TEXT_PRINTCODE_TITLE"] = 'Línea de código %1';
es["PROCEDURES_PARAMETERS"] = 'Cant. parametros';
es["PROCEDURES_ALREADY_EXISTS"] = 'La funcion o una de sus variables ya existe';
es["PROCEDURES_REPEATED_VAR_NAMES"] = 'Los parámetros de la función deben tener nombres diferentes';
es["PROCEDURES_NAME_EMPTY"] = 'El nombre de la función no puede estar vacío';
es["PROCEDURES_NAME"] = 'Nombre de la función';
es["PROCEDURES_CREATE_NORETURN_PROCEDURE"] = 'Crear función sin retorno';
es["PROCEDURES_CREATE_RETURN_PROCEDURE"] = 'Crear función con retorno';
es["PROCEDURES_CREATE_PROCEDURE"] = 'Crear una función';
es["PROCEDURES_MODIFY_DO"] = "Modificar '%1'";
es["NEW_GLOBAL_VARIABLE"] = "Declarar variable GLOBAL";
es["NEW_GLOBAL_VARIABLE_TYPE"] = "de tipo";
es["NEW_LOCAL_VARIABLE"] = "Declarar variable LOCAL";
es["NEW_LOCAL_VARIABLE_TYPE"] = "de tipo";
es["VARIABLE_STATEMENT"] = "Var";
es["VARIABLE_NAME_EMPTY"] = "El nombre de la variable no puede estar vacío";
es["VARIABLE_STATEMENT_DEF"] = "Definir";
es["VARIABLE_STATEMENT_DEF_TO"] = "como";
es["VARIABLE_STATEMENT_CHANGE"] = "Cambiar";
es["VARIABLE_STATEMENT_CHANGE_BY"] = "en";
es["VARIABLE_TYPE_STRING"] = "Texto";
es["VARIABLE_TYPE_INT"] = "Entero";
es["VARIABLE_TYPE_LONG"] = "Entero largo";
es["VARIABLE_TYPE_BYTE"] = "Octeto";
es["VARIABLE_TYPE_FLOAT"] = "Decimal";
es["VARIABLE_TYPE_CHAR"] = "Caracter";
es["NEW_ARRAY_VARIABLE"] = "Declarar lista";
es["NEW_ARRAY_VARIABLE_TYPE"] = "de tipo";
es["ARRAY_STATEMENT"] = "Lista";
es["CONTROLS_IF_MSG_ELSEIF"] = "si no, si";


// BLOCKS
es["BLOCKLY_MSG_ADD_COMMENT"] = "Agregar Comentario";
es["BLOCKLY_MSG_COLLAPSE_ALL"] = "Colapsar bloques";
es["BLOCKLY_MSG_COLLAPSE_BLOCK"] = "Colapsar bloques";
es["BLOCKLY_MSG_DELETE_BLOCK"] = "Eliminar bloque";
es["BLOCKLY_MSG_DELETE_X_BLOCKS"] = "Eliminar %1 Bloque";
es["BLOCKLY_MSG_DISABLE_BLOCK"] = "Deshablilitar bloques";
es["BLOCKLY_MSG_DUPLICATE_BLOCK"] = "Duplicar";
es["BLOCKLY_MSG_ENABLE_BLOCK"] = "Habilitar bloques";
es["BLOCKLY_MSG_EXPAND_ALL"] = "Expandir bloques";
es["BLOCKLY_MSG_EXPAND_BLOCK"] = "Expandir bloques";
es["BLOCKLY_MSG_EXTERNAL_INPUTS"] = "Entradas externas";
es["BLOCKLY_MSG_HELP"] = "Ayuda";
es["BLOCKLY_MSG_INLINE_INPUTS"] = "Entradas en línea";
es["BLOCKLY_MSG_REMOVE_COMMENT"] = "Remover Comentario";
es["LANG_ADVANCED_BLUE_READ"] = "Leer desde el bluetooth";
es["LANG_ADVANCED_BT_SERIAL_AVAILABLE"] = "Bluetooth: Puerto Serie Disponible";
es["LANG_ADVANCED_BT_SERIAL_AVAILABLE_TOOLTIP"] = "Comprueba si el módulo Bluetooth está disponible o no";
es["LANG_BHOOT_BT_SERIAL_AVAILABLE"] = "Si recibe un mensaje por bluetooth, leer";
es["LANG_BHOOT_BT_SERIAL_AVAILABLE_TOOLTIP"] = "Comprueba si se recibe un mensaje desde el módulo Bluetooth";
es["LANG_ADVANCED_BUILTIN_LED"] = "Led en la placa";
es["LANG_ADVANCED_BUILTIN_LED_OFF"] = "Apagado";
es["LANG_ADVANCED_BUILTIN_LED_ON"] = "Prendido";
es["LANG_ADVANCED_BUILTIN_LED_TOOLTIP"] = "LED integrado en la placa que está internamente conectado al pin 13.";
es["LANG_ADVANCED_CONVERSION_BINARY"] = "Binario";
es["LANG_ADVANCED_CONVERSION_CONVERT"] = "Convertir";
es["LANG_ADVANCED_CONVERSION_DECIMAL"] = "Decimal";
es["LANG_ADVANCED_CONVERSION_HEXADECIMAL"] = "Hexadecimal";
es["LANG_ADVANCED_CONVERSION_OCTAL"] = "Octal";
es["LANG_ADVANCED_CONVERSION_TOOLTIP"] = "Convertir la base de un número.";
es["LANG_ADVANCED_CONVERSION_VALUE"] = "valor";
es["LANG_ADVANCED_HIGHLOW_HIGH"] = "Prendido";
es["LANG_ADVANCED_HIGHLOW_LOW"] = "Apagado";
es["LANG_ADVANCED_HIGHLOW_TOOLTIP"] = "Escribe 'Prendido o 'Apagado' en función de lo seleccionado.";
es["LANG_ADVANCED_INOUT_ANALOG_READ"] = "Leer el pin analógico";
es["LANG_ADVANCED_INOUT_ANALOG_READ_TOOLTIP"] = "Lee el valor de un pin analógico específico.";
es["LANG_ADVANCED_INOUT_ANALOG_WRITE"] = "Escribir en pin analógico";
es["LANG_ADVANCED_INOUT_ANALOG_WRITE_TOOLTIP"] = "Escribe un valor entre 0 y 255 en un pin analógico específico.";
es["LANG_ADVANCED_INOUT_ANALOG_WRITE_VALUE"] = "el valor analógico";
es["LANG_ADVANCED_INOUT_DIGITAL_READ"] = "Leer el pin digital";
es["LANG_ADVANCED_INOUT_DIGITAL_READ_TOOLTIP"] = "Lee el valor desde un pin digital específico.";
es["LANG_ADVANCED_INOUT_DIGITAL_WRITE"] = "Escribir";
es["LANG_ADVANCED_INOUT_DIGITAL_WRITE_HIGH"] = "Prendido";
es["LANG_ADVANCED_INOUT_DIGITAL_WRITE_LOW"] = "Apagado";
es["LANG_ADVANCED_INOUT_DIGITAL_WRITE_STATE"] = "estado";
es["LANG_ADVANCED_INOUT_DIGITAL_WRITE_TOOLTIP"] = "Escribe un valor en el pin digital específico.";
es["LANG_ADVANCED_MATH_RANDOM"] = "Aleatorio entre";
es["LANG_ADVANCED_MATH_RANDOM_AND"] = " y ";

es["LANG_ADVANCED_MATH_RANDOM_TOOLTIP"] = "Crea un número aleatorio entre los dos límites introducidos.";
es["LANG_ADVANCED_SERIAL_AVAILABLE"] = "Puerto Serie Disponible";
es["LANG_ADVANCED_SERIAL_AVAILABLE_TOOLTIP"] = "Comprueba si el puerto serie está disponible o no";
es["LANG_ADVANCED_SERIAL_BLUE_TAG"] = "Enviar mensaje";
es["LANG_ADVANCED_SERIAL_BLUE_TAG_WITHTAG"] = "con etiqueta";
es["LANG_ADVANCED_SERIAL_BLUE_TAG_BLUETOOTH"] = "por bluetooth";
es["LANG_ADVANCED_SERIAL_BLUE"] = "Escribir vía bluetooth";
es["LANG_ADVANCED_SERIAL_PARSEINT"] = "Leer entero por el puerto serie";
es["LANG_ADVANCED_SERIAL_PARSEINT_TOOLTIP"] = "Devuelve el primer número entero (largo) desde el puerto serie";
es["LANG_ADVANCED_SERIAL_PRINT"] = "Escribir por puerto serie";
es["LANG_ADVANCED_SERIAL_PRINTLN"] = "Escribir por puerto serie con salto de línea";
es["LANG_ADVANCED_SERIAL_PRINTLN_TOOLTIP"] = "Imprime los datos como texto ASCII y con retorno de carro (RC).";
es["LANG_ADVANCED_SERIAL_PRINT_TOOLTIP"] = "Imprime los datos como texto ASCII.";
es["LANG_ADVANCED_SERIAL_READ"] = "Leer desde el puerto serie";
es["LANG_ADVANCED_SERIAL_READSTRING"] = "Leer cadena desde el puerto serie";
es["LANG_ADVANCED_SERIAL_READSTRING_TOOLTIP"] = "Lee los datos que se reciben por el puerto serie como texto ASCII.";
es["LANG_ADVANCED_SERIAL_READ_TOOLTIP"] = "Lee los datos que se reciben por el puerto serie como texto bytes.";
es["LANG_ADVANCED_SERIAL_SPECIAL"] = "Caracteres especiales";
es["LANG_ADVANCED_SERIAL_SPECIAL_CARRIAGE_RETURN"] = "Retorno de carro";
es["LANG_ADVANCED_SERIAL_SPECIAL_LINE_FEED"] = "Salto de línea";
es["LANG_ADVANCED_SERIAL_SPECIAL_TAB"] = "Tabulador";
es["LANG_ADVANCED_SERIAL_SPECIAL_TOOLTIP"] = "Escribe caracteres especiales.";
es["LANG_ARRAY_GET_BRACKET1"] = "[";
es["LANG_ARRAY_GET_BRACKET2"] = "]";
es["LANG_ARRAY_GET_TOOLTIP"] = "Devuelve el valor de un elemento concreto del vector.";

es["LANG_CATEGORY_PROCEDURES"] = "Funciones";
es["LANG_CATEGORY_CONTROLS"] = "Control";
es["LANG_CATEGORY_LOGIC"] = "Lógica";
es["LANG_CATEGORY_MATH"] = "Matemáticas";
es["LANG_CATEGORY_VARIABLES"] = "Variables";
es["LANG_CATEGORY_TEXT"] = "Texto";
es["LANG_CATEGORY_COMMUNICATION"] = "Comunicación";
es["LANG_CATEGORY_ENTRADAS"] = "Entradas";
es["LANG_SUBCATEGORY_DIGITALES"] = "Digitales";
es["LANG_SUBCATEGORY_ANALOGICAS"] = "Analógicas";
es["LANG_CATEGORY_SALIDAS"] = "Salidas";
es["LANG_CATEGORY_LCD"] = "Pantallas";
es["LANG_CATEGORY_SERVO"] = "Motores";
es["LANG_CATEGORY_MOTORS"] = "Motores";

es["LANG_CATEGORY_SPECIALS"] = "Especiales";
es["LANG_CATEGORY_EXTERNALS"] = "Externos";
es["LANG_CATEGORY_MICROBIT"] = "Microbit";
es["LANG_CATEGORY_BASIC"] = "Básico";
es["LANG_CATEGORY_PIROUETTES"] = "Piruetas";
es["LANG_CATEGORY_LIGHTNING"] = "Iluminación";
es["LANG_CATEGORY_COMPARISON"] = "Comparación";
es["LANG_CATEGORY_ACTION"] = "Acción";
es["LANG_CATEGORY_ACTIONS"] = "Acciones";
es["LANG_CATEGORY_MOTION"] = "Movimiento";

es["LANG_SUBCATEGORY_MOTORDOBLEPWM"] = "Motor Doble PWM";
es["LANG_CATEGORY_ADVANCED"] = "Funciones Pin";
es["LANG_CONTROLS_BASE_DELAY_TOOLTIP"] = "Espera el tiempo especificado en milisegundos (ms)";
es["LANG_CONTROLS_BASE_DELAY_WAIT"] = "Esperar [ms]";
es["LANG_CONTROLS_BASE_MILLIS"] = "Tiempo desde el arranque (ms)";
es["LANG_CONTROLS_BASE_MILLIS_TOOLTIP"] = "Número de milisegundos desde que se inició el programa (entero largo)";
es["LANG_CONTROLS_DOWHILE_OPERATOR_DO"] = "Hacer";
es["LANG_CONTROLS_DOWHILE_OPERATOR_WHILE"] = "mientras";
es["LANG_CONTROLS_DOWHILE_TOOLTIP"] = "Mientras la condición sea verdadera, continúa ejecutando las acciones del bloque.";
es["LANG_CONTROLS_EXECUTE"] = "Ejecutar";
es["LANG_CONTROLS_EXECUTE_TOOLTIP"] = "Ejecuta código C/C++. Utilizar con preucación, ya que puede romper fácilmente el programa e impedir su correcta compilación.";
es["LANG_CONTROLS_FLOW_STATEMENTS_HELPURL"] = "";
es["LANG_CONTROLS_FLOW_STATEMENTS_INPUT_OFLOOP"] = "el bucle";
es["LANG_CONTROLS_FLOW_STATEMENTS_OPERATOR_BREAK"] = "interrumpir";
es["LANG_CONTROLS_FLOW_STATEMENTS_OPERATOR_CONTINUE"] = "continuar con la siguiente iteración";
es["LANG_CONTROLS_FLOW_STATEMENTS_TOOLTIP_BREAK"] = "Interrumpir el bucle que contiene esta instrucción.";
es["LANG_CONTROLS_FLOW_STATEMENTS_TOOLTIP_CONTINUE"] = "Saltarse el resto de acciones de esta iteración y continuar con la siguiente iteración.";
es["LANG_CONTROLS_FLOW_STATEMENTS_WARNING"] = "Atención: Este bloque sólo puede ser usado dentro de un bucle.";
es["LANG_CONTROLS_FOR"] = "Repetir";
es["LANG_CONTROLS_FOR_FROM_WARNING"] = "No puedes asignar una variable al valor inicial del for";
es["LANG_CONTROLS_FOR_INPUT"] = "x";
es["LANG_CONTROLS_FOR_INPUT_DO"] = "hacer";
es["LANG_CONTROLS_FOR_INPUT_FROM"] = "desde";
es["LANG_CONTROLS_FOR_INPUT_TO"] = "hasta";
es["LANG_CONTROLS_FOR_INPUT_VAR"] = "x";
es["LANG_CONTROLS_FOR_INPUT_WITH"] = "Crear bucle con";
es["LANG_CONTROLS_FOR_TOOLTIP"] = "Contar desde un número de inicio hasta uno final. Cada vez que se incrementa en uno la cuenta, la variable toma ese valor y se ejecutan las acciones.";
es["LANG_CONTROLS_FOR_TO_WARNING"] = "No puedes asignar una variable al valor final del for";
es["LANG_CONTROLS_IF_ELSEIF_Field_ELSEIF"] = "en cambio, si";
es["LANG_CONTROLS_IF_ELSEIF_TOOLTIP"] = "Añade una condición al bloque 'si'.";
es["LANG_CONTROLS_IF_ELSE_Field_ELSE"] = "de lo contrario";
es["LANG_CONTROLS_IF_ELSE_TOOLTIP"] = "Añade una condición final al bloque 'si' para capturar el resto de opciones.";
es["LANG_CONTROLS_IF_IF_Field_IF"] = "si";
es["LANG_CONTROLS_IF_IF_TOOLTIP"] = "Añadir, eliminar o reordenar secciones para reconfigurar este bloque 'si'.";
es["LANG_CONTROLS_IF_MSG_ELSE"] = "de lo contrario";
es["LANG_CONTROLS_IF_MSG_ELSEIF"] = "en cambio, si";
es["LANG_CONTROLS_IF_MSG_IF"] = "si";
es["LANG_CONTROLS_IF_MSG_THEN"] = "ejecutar";
es["LANG_CONTROLS_IF_TOOLTIP_1"] = "Si la condición es verdadera, ejecuta las acciones dentro del bloque.";
es["LANG_CONTROLS_IF_TOOLTIP_2"] = "Si la condición es verdadera, se ejecuta el primer bloque de comandos. Si no lo es, se ejecuta el segundo bloque de comandos.";
es["LANG_CONTROLS_IF_TOOLTIP_3"] = "Si el primer valor es verdadero, se ejecuta el primer bloque de comandos. Si no, si el segundo valor es verdadero, se ejecuta el segundo bloque de comandos.";
es["LANG_CONTROLS_IF_TOOLTIP_4"] = "Si el primer valor es verdadero, se ejecuta el primer bloque de comandos. Si no, si el segundo valor es verdadero, se ejecuta el segundo bloque de comandos. Si ninguno de los valores es verdadero, se ejecuta el último bloque de comandos";
es["LANG_CONTROLS_REPEAT_INPUT_DO"] = "ejecutar";
es["LANG_CONTROLS_REPEAT_TITLE_REPEAT"] = "Repetir";
es["LANG_CONTROLS_REPEAT_TITLE_TIMES"] = "veces";
es["LANG_CONTROLS_REPEAT_TOOLTIP"] = "Ejecutar las instrucciones un número concreto de veces.";
es["LANG_CONTROLS_CICLO_TITLE_REPEAT"] = "Ejecutar secuencia";
es["LANG_CONTROLS_CICLO_TITLE_TIMES"] = "durante";
es["LANG_CONTROLS_CICLO_MS"] = "ms";
es["LANG_CONTROLS_CICLO_TOOLTIP"] = "Ejecutar las instrucciones en secuencia.";
es["LANG_CONTROLS_SETUP_LOOP_LOOP_TITLE"] = "Repetir";
es["LANG_CONTROLS_SETUP_LOOP_SETUP_TITLE"] = "Inicio";
es["LANG_CONTROLS_SETUP_LOOP_TOOLTIP"] = "Los bloques en Inicio se ejecutarán una sola vez al arranque, mientras que los bloques en Repetir se ejecutarán de forma repetida.";
es["LANG_CONTROLS_SWITCH"] = "Cambiar";
es["LANG_CONTROLS_SWITCH_CASE"] = "caso ";
es["LANG_CONTROLS_SWITCH_CASE_TOOLTIP"] = "caso ";
es["LANG_CONTROLS_SWITCH_COLON"] = ": ";
es["LANG_CONTROLS_SWITCH_DEFAULT"] = "en otro caso";
es["LANG_CONTROLS_SWITCH_DEFAULT_TOOLTIP"] = "El bloque 'default' especifica la acción que se va a ejecutar si no se han cumplido ninguno de los casos anteriores.";
es["LANG_CONTROLS_SWITCH_DO"] = "ejecutar";
es["LANG_CONTROLS_SWITCH_IS"] = "es: ";
es["LANG_CONTROLS_SWITCH_SWITCH_TOOLTIP"] = "Este bloque compara de uno en uno si se cumplen los distintos casos.";
es["LANG_CONTROLS_SWITCH_TOOLTIP_1"] = "Ejecuta las acciones del caso que se cumpla.";
es["LANG_CONTROLS_SWITCH_TOOLTIP_2"] = "Ejecuta las acciones del caso que se cumpla.";
es["LANG_CONTROLS_SWITCH_TOOLTIP_3"] = "Ejecuta las acciones del caso que se cumpla.";
es["LANG_CONTROLS_SWITCH_TOOLTIP_4"] = "Ejecuta las acciones del caso que se cumpla.";
es["LANG_CONTROLS_WHILEUNTIL_OPERATOR_UNTIL"] = "hasta";
es["LANG_CONTROLS_WHILEUNTIL_OPERATOR_WHILE"] = "mientras";
es["LANG_CONTROLS_WHILEUNTIL_TOOLTIP_UNTIL"] = "Mientras la condición sea falsa, ejecutar las instrucciones.";
es["LANG_CONTROLS_WHILEUNTIL_TOOLTIP_WHILE"] = "Mientras la condición sea verdadera, ejecutar las instrucciones.";
es["LANG_CONTROL_CHRONOMETER"] = "Iniciar cronómetro";
es["LANG_CONTROL_CHRONOMETER_TOOLTIP"] = "Iniciar cronómetro";
es["LANG_CONTROL_CHRONOMETER_MILLIS"] = "Cronómetro";
es["LANG_CONTROL_CHRONOMETER_MILLIS_TOOLTIP"] = "Cronómetro";
es["LANG_Color"] = "Color";
es["LANG_ENTRADAS_BUTTON"] = "Botón";
es["LANG_ENTRADAS_BUTTON_PIN"] = "BOTÓN en el pin";
es["LANG_ENTRADAS_BUTTON_TOOLTIP"] = "Botón ";
es["LANG_ENTRADAS_CHOQUE"] = "CHOQUE en el pin";
es["LANG_ENTRADAS_CHOQUE_PIN"] = "Pin";
es["LANG_ENTRADAS_CHOQUE_TOOLTIP"] = "Devuelve el valor digital medido por el sensor choque";
es["LANG_ENTRADAS_GOLPE"] = "GOLPE en el pin";
es["LANG_ENTRADAS_GOLPE_PIN"] = "Pin";
es["LANG_ENTRADAS_GOLPE_TOOLTIP"] = "Devuelve el valor digital medido por el sensor golpe";
es["LANG_ENTRADAS_INTERRUPTOR_OPTICO"] = "INTERRUPTOR ÓPTICO en el pin";
es["LANG_ENTRADAS_INTERRUPTOR_OPTICO_PIN"] = "Pin";
es["LANG_ENTRADAS_INTERRUPTOR_OPTICO_TOOLTIP"] = "Devuelve el valor digital medido por el interruptor óptico";
es["LANG_ENTRADAS_FUEGO"] = "FUEGO en el pin";
es["LANG_ENTRADAS_FUEGO_TOOLTIP"] = "Devuelve el valor analógico medido por el sensor de fuego";
es["LANG_ENTRADAS_PULSO"] = "PULSO en el pin";
es["LANG_ENTRADAS_PULSO_TOOLTIP"] = "Devuelve el valor analógico medido por el sensor de pulso";
es["LANG_ENTRADAS_MAGNETICO"] = "MAGNÉTICO en el pin";
es["LANG_ENTRADAS_MAGNETICO_TOOLTIP"] = "Devuelve el valor analógico medido por el sensor magnético";
es["LANG_ENTRADAS_TEMPERATURA_ANALOGICO"] = "TEMPERATURA en el pin";
es["LANG_ENTRADAS_TEMPERATURA_ANALOGICO_TOOLTIP"] = "Devuelve el valor analógico medido por el sensor de temperatura";
es["LANG_ENTRADAS_JOYSTICK"] = "del JOYSTICK en el pin";
es["LANG_ENTRADAS_JOYSTICK_X"] = "Eje X";
es["LANG_ENTRADAS_JOYSTICK_Y"] = "Eje Y";
es["LANG_ENTRADAS_JOYSTICK_A"] = "Botón";
es["LANG_ENTRADAS_JOYSTICK_TOOLTIP"] = "Devuelve el valor analógico medido por el sensor de temperatura";
es["LANG_ENTRADAS_ENCODER"] = "ROTACIÓN en el pin dt";
es["LANG_ENTRADAS_ENCODER_CLK"] = "y el pin clk";
es["LANG_ENTRADAS_ENCODER_PIN"] = "Pin";
es["LANG_ENTRADAS_ENCODER_TOOLTIP"] = "Devuelve el valor digital del encoder de rotación";
es["LANG_ENTRADAS_DHT11"] = "DHT11";
es["LANG_ENTRADAS_DHT11_PIN"] = "en el pin";
es["LANG_ENTRADAS_DHT11_TYPE"] = "Tipo";
es["LANG_ENTRADAS_DHT11_TOOLTIP"] = "Inicia la librería del DHT11";
es["LANG_ENTRADAS_READ_DHT11_TOOLTIP"] = "Devuelve el valor digital medido por el sensor de temperatura y humedad DHT11";
es["LANG_ENTRADAS_DS18B20"] = "DS18B20";
es["LANG_ENTRADAS_DS18B20_PIN"] = "TEMPERATURA en el pin";
es["LANG_ENTRADAS_DS18B20_TOOLTIP"] = "Inicia la librería del DS18B20";
es["LANG_ENTRADAS_FOLLOWER"] = "Seguidor de líneas";
es["LANG_ENTRADAS_FOLLOWER_LEFT"] = "Izquierda";
es["LANG_ENTRADAS_FOLLOWER_NINGUNO"] = "Ninguno";
es["LANG_ENTRADAS_FOLLOWER_PIN_LEFT"] = "Pin izquierda";
es["LANG_ENTRADAS_FOLLOWER_PIN_RIGHT"] = "Pin derecha";
es["LANG_ENTRADAS_FOLLOWER_RIGHT"] = "Derecha";
es["LANG_ENTRADAS_FOLLOWER_TOOLTIP"] = "Devuelve el valor digital de los sensores ópticos y crea un condicional para cada uno";
es["LANG_ENTRADAS_Frecuencia"] = "Frecuencia";
es["LANG_ENTRADAS_HALL"] = "MAGNÉTICO en el pin";
es["LANG_ENTRADAS_HALL_PIN"] = "Pin";
es["LANG_ENTRADAS_HALL_TOOLTIP"] = "Devuelve el valor digital medido por el sensor magnético";
es["LANG_ENTRADAS_HUMEDAD"] = "HUMEDAD";
es["LANG_ENTRADAS_HUMEDAD_TOOLTIP"] = "Devuelve el porcentaje de humedad";
es["LANG_ENTRADAS_INFRARED"] = "ÓPTICO en el pin";
es["LANG_ENTRADAS_INFRARED_PIN"] = "Pin";
es["LANG_ENTRADAS_INFRARED_DETECT"] = "detecta";
es["LANG_ENTRADAS_INFRARED_TOOLTIP"] = "Devuelve el valor digital del sensor óptico";
es["LANG_ENTRADAS_IR"] = "IR en el pin";
es["LANG_ENTRADAS_IRCONTROL"] = "IR disponible";
es["LANG_ENTRADAS_IRCONTROL2"] = "recibe";
es["LANG_ENTRADAS_IRCONTROL2_PIN"] = "botón";
es["LANG_ENTRADAS_IRCONTROL2_TOOLTIP"] = "";
es["LANG_ENTRADAS_IRCONTROL_TOOLTIP"] = "";
es["LANG_ENTRADAS_IR_PIN"] = "Pin";
es["LANG_ENTRADAS_IR_TOOLTIP"] = "Inicia la librería del control IR ";
es["LANG_ENTRADAS_LLUVIA"] = "LLUVIA en el pin";
es["LANG_ENTRADAS_LLUVIA_PIN"] = "Pin";
es["LANG_ENTRADAS_LLUVIA_TOOLTIP"] = "Devuelve el valor analógico del sensor";
es["LANG_ENTRADAS_OBSTACULO"] = "OBSTÁCULO en el pin";
es["LANG_ENTRADAS_OBSTACULO_PIN"] = "Pin";
es["LANG_ENTRADAS_OBSTACULO_TOOLTIP"] = "Devuelve el valor digital del sensor obstáculo";
es["LANG_ENTRADAS_PHOTORESISTOR"] = "LUZ en el pin";
es["LANG_ENTRADAS_PHOTORESISTOR_PIN"] = "Pin";
es["LANG_ENTRADAS_PHOTORESISTOR_TOOLTIP"] = "Devuelve el valor analógico del sensor de luz (fotorresistencia).";
es["LANG_ENTRADAS_POTENTIOMETER"] = "POTENCIÓMETRO en el pin";
es["LANG_ENTRADAS_POTENTIOMETER_PIN"] = "Pin";
es["LANG_ENTRADAS_POTENTIOMETER_TOOLTIP"] = "Devuelve el valor analógico del potenciómetro";
es["LANG_ENTRADAS_SONIDO"] = "SONIDO en el pin";
es["LANG_ENTRADAS_SONIDO_PIN"] = "Pin";
es["LANG_ENTRADAS_SONIDO_TOOLTIP"] = "Devuelve el valor analógico medido por el sensor de sonido.";
es["LANG_ENTRADAS_SUELO"] = "SUELO en el pin";
es["LANG_ENTRADAS_SUELO_PIN"] = "Pin";
es["LANG_ENTRADAS_SUELO_TOOLTIP"] = "Devuelve el valor analógico del sensor";
es["LANG_ENTRADAS_SensorColor_TOOLTIP"] = "Retorna verdadero si el color indicado es detectado, de lo contrario retorna falso";
es["LANG_ENTRADAS_TEMPERATURA"] = "TEMPERATURA";
es["LANG_ENTRADAS_TEMPERATURA_DS18B20"] = "TEMPERATURA";
es["LANG_ENTRADAS_TEMPERATURA_DS18B20_PIN"] = "en el pin";
es["LANG_ENTRADAS_TEMPERATURA_DS18B20_TOOLTIP"] = "Devuelve la temperatura en grados celcius o la humedad en porcentaje";
es["LANG_ENTRADAS_TEMPERATURA_TOOLTIP"] = "Devuelve la temperatura en grados celcius";
es["LANG_ENTRADAS_TILT"] = "INCLINACIÓN en el pin";
es["LANG_ENTRADAS_TILT_PIN"] = "Pin";
es["LANG_ENTRADAS_TILT_TOOLTIP"] = "Devuelve el valor digital medido por el sensor inclinación";
es["LANG_ENTRADAS_TOUCH"] = "TÁCTIL";
es["LANG_ENTRADAS_TOUCH_Boton"] = "Botón";
es["LANG_ENTRADAS_TOUCH_PIN"] = "en el pin";
es["LANG_ENTRADAS_TOUCH_TOOLTIP"] = "Devuelve el valor digital medido por el sensor táctil";
es["LANG_ENTRADAS_TecladoTactil"] = "Teclado tactil";
es["LANG_ENTRADAS_ULTRASONIDO"] = "ULTRASONIDO";
es["LANG_ENTRADAS_ULTRASONIDO_RED_PIN"] = "en el pin";
es["LANG_ENTRADAS_ULTRASONIDO_PIN"] = "en el pin";
es["LANG_ENTRADAS_ULTRASONIDO_TRIGGER_PIN"] = "en el pin trigger";
es["LANG_ENTRADAS_ULTRASONIDO_ECHO_PIN"] = "y el pin echo";
es["LANG_ENTRADAS_ULTRASONIDO_TOOLTIP"] = "Devuelve la distancia medida por el sensor ultrasonido";
es["LANG_ENTRADAS_ULTRASONIDO_CLOSE"] = "cerca";
es["LANG_ENTRADAS_ULTRASONIDO_FAR"] = "lejos";
es["LANG_ENTRADAS_VIBRATION"] = "VIBRACIÓN en el pin";
es["LANG_ENTRADAS_VIBRATION_PIN"] = "Pin";
es["LANG_ENTRADAS_VIBRATION_TOOLTIP"] = "Devuelve el valor digital medido por el sensor de vibración";
es["LANG_Gesto"] = "Gesto";
es["LANG_LCD_ADVANCED_COLUMNS"] = "y columna";
es["LANG_LCD_ADVANCED_DEF"] = "LCD avanzado";
es["LANG_LCD_ADVANCED_ROWS"] = "en la fila";
es["LANG_LCD_CLEAR"] = "Borrar textos del LCD";
es["LANG_LCD_CLEAR_TOOLTIP"] = "Borra el contenido de la pantalla LCD";
es["LANG_LCD_DEF"] = "LCD (2x16)";
es["LANG_LCD_DEF_ADVANCED_TOOLTIP"] = "Bloque que define el LCD avanzado";
es["LANG_LCD_DEF_TOOLTIP"] = "Escribir en la pantalla LCD un texto";
es["LANG_LCD_DIRECCION"] = "Dirección I2C";
es["LANG_LCD_ADVANCED_COLUMNS_I2C"] = "y columna";
es["LANG_LCD_DIRECCION_I2C"] = "del LCD en la dirección I2C";
es["LANG_LCD_DIRECCION_I2C_2"] = "en la dirección I2C";
es["LANG_LCD_DIRECCION_I2C_3"] = "en el pin";
es["LANG_LCD_I2C"] = "Escribir";
es["LANG_LCD_I2C_POSITIONX1"] = "línea desde x";
es["LANG_LCD_I2C_POSITIONY1"] = ", y";
es["LANG_LCD_I2C_POSITIONX2"] = "hasta x";
es["LANG_LCD_I2C_POSITIONY2"] = ", y";
es["LANG_LCD_PINS"] = "del LCD en el pin";
es["LANG_LCD_PRINT"] = "LCD: Escribir ";
es["LANG_LCD_PRINT_POSITION"] = "¿Fijar posición del texto?";
es["LANG_LCD_PRINT_TOOLTIP"] = "Imprime un string en la pantalla LCD en la posición específicada o en la siguiente disponible.";
es["LANG_LCD_PRINT_columna"] = "columna";
es["LANG_LCD_PRINT_fila"] = "fila";
es["LANG_LCD_SETBACKLIGHT"] = "la iluminación del LCD";
es["LANG_LCD_SETBACKLIGHT_CLOSE"] = "";
es["LANG_LCD_SETBACKLIGHT_TOOLTIP"] = "Ajusta la retroiluminación de la pantalla LCD";
es["LANG_LCD_scroll"] = "Texto con desplazamiento";
es["LANG_LIBRERIA"] = "Librería";
es["LANG_LOGIC_BOOLEAN_FALSE"] = "falso";
es["LANG_LOGIC_BOOLEAN_TOOLTIP"] = "Devuelve verdadero o falso en función de lo seleccionado.";
es["LANG_LOGIC_BOOLEAN_TRUE"] = "verdadero";
es["LANG_COMPARE_ULTRASONIC"] = "detecta objeto";
es["LANG_COMPARE_OBSTACLE"] = "detecta obstáculo";
es["LANG_COMPARE_HUMIDITY"] = "está";
es["LANG_COMPARE_HUMIDITY_wet"] = "húmedo";
es["LANG_COMPARE_HUMIDITY_dry"] = "seco";
es["LANG_COMPARE_NOISE"] = "detecta";
es["LANG_COMPARE"] = "es";
es["LANG_COMPARE_TO"] = "a";
es["LANG_LOGIC_COMPARE_TOOLTIP_EQ"] = "Compara si las dos entradas son iguales.";
es["LANG_LOGIC_COMPARE_TOOLTIP_GT"] = "Compara si la primera entrada es mayor que la segunda entrada.";
es["LANG_LOGIC_COMPARE_TOOLTIP_GTE"] = "Compara si la primera entrada es mayor o igual que la segunda entrada.";
es["LANG_LOGIC_COMPARE_TOOLTIP_LT"] = "Compara si la primera entrada es menor que la segunda entrada.";
es["LANG_LOGIC_COMPARE_TOOLTIP_LTE"] = "Compara si la primera entrada es menor o igual que la segunda entrada.";
es["LANG_LOGIC_COMPARE_TOOLTIP_NEQ"] = "Compara si las dos entradas no son iguales entre sí.";
es["LANG_LOGIC_NEGATE_HELPURL"] = "";
es["LANG_LOGIC_NEGATE_INPUT_NOT"] = "no";
es["LANG_LOGIC_NEGATE_TOOLTIP"] = "Devuelve lo contrario de la entrada.";
es["LANG_LOGIC_OPERATION_AND"] = "y";
es["LANG_LOGIC_OPERATION_OR"] = "o";
es["LANG_LOGIC_OPERATION_TOOLTIP_AND"] = "Compara si ambas entradas son verdaderas.";
es["LANG_LOGIC_OPERATION_TOOLTIP_OR"] = "Compara si alguna de las entradas son verdaderas.";
es["LANG_MATH_ADVANCED_MAP_BRACKET"] = "]";
es["LANG_MATH_ADVANCED_MAP_FROM"] = "De [";
es["LANG_MATH_ADVANCED_MAP_HYPHEN"] = "-";
es["LANG_MATH_ADVANCED_MAP_MAP"] = "Mapear ";
es["LANG_MATH_ADVANCED_MAP_TO"] = "a [";
es["LANG_MATH_ADVANCED_MAP_TOOLTIP"] = "Mapea la entrada desde un rango de valores iniciales a otro rango distinto.";
es["LANG_MATH_ARRAY_ARRAY3"] = "[3]";
es["LANG_MATH_ARRAY_BRACKET3"] = "{";
es["LANG_MATH_ARRAY_BRACKET4"] = "}";
es["LANG_MATH_ARRAY_COMMA"] = ",";
es["LANG_MATH_ARRAY_TOOLTIP"] = "Vector de tres enteros";
es["LANG_MATH_BASE_MAP"] = "Mapear ";
es["LANG_MATH_BASE_MAP_BRACKET"] = "]";
es["LANG_MATH_BASE_MAP_TOOLTIP"] = "Mapea la entrada desde el rango [0-1023] a otro rango de valores.";
es["LANG_MATH_BASE_MAP_VALUE_TO"] = "Valor entre [0-";
es["LANG_MATH_MODULO_TOOLTIP"] = "Devuelve el resto de la división de las dos entradas.";
es["LANG_MATH_NUMBER_TOOLTIP"] = "Número entero";
es["LANG_MATH_PWM"] = "PWM";
es["LANG_MATH_COUNTER"] = "Cuenta regresiva de";
es["LANG_MATH_COUNTER_SECONDS"] = "segundos";
es["LANG_MATH_COUNTER_TOOLTIP"] = "Genera un contador regresivo";
es["LANG_MATH_SINGLE_OP_ABSOLUTE"] = "valor absoluto";
es["LANG_MATH_SINGLE_OP_ROOT"] = "raíz cuadrada";
es["LANG_MATH_SINGLE_TOOLTIP_ABS"] = "Devuelve el valor absoluto de un número.";
es["LANG_MATH_SINGLE_TOOLTIP_EXP"] = "Devuelve el exponencial de un número.";
es["LANG_MATH_SINGLE_TOOLTIP_LN"] = "Devuelve el logaritmo neperiano de un número.";
es["LANG_MATH_SINGLE_TOOLTIP_LOG10"] = "Devuelve el logaritmo en base 10 de un número.";
es["LANG_MATH_SINGLE_TOOLTIP_NEG"] = "Devuelve el número cambiado de signo.";
es["LANG_MATH_SINGLE_TOOLTIP_POW10"] = "Devuelve 10 elevado a una potencia.";
es["LANG_MATH_SINGLE_TOOLTIP_ROOT"] = "Devuelve la raíz cuadrada de un número.";
es["LANG_PROCEDURES_CALLNORETURN_CALL"] = "ejecutar";
es["LANG_PROCEDURES_CALLNORETURN_HELPURL"] = "";
es["LANG_PROCEDURES_CALLNORETURN_PROCEDURE"] = "func_sin_retorno";
es["LANG_PROCEDURES_CALLNORETURN_TOOLTIP"] = "Ejecuta esta función.";
es["LANG_PROCEDURES_CALLRETURN_CALL"] = "ejecutar";
es["LANG_PROCEDURES_CALLRETURN_HELPURL"] = "";
es["LANG_PROCEDURES_CALLRETURN_PROCEDURE"] = "func_con_retorno";
es["LANG_PROCEDURES_CALLRETURN_TOOLTIP"] = "Ejecuta esta función que tiene valor de retorno.";
es["LANG_PROCEDURES_CALL_WITHOUT_DEFINITION"] = "Llamada a una función sin definición previa.";
es["LANG_PROCEDURES_DEFNORETURN_DO"] = "ejecutar";
es["LANG_PROCEDURES_DEFNORETURN_HELPURL"] = "";
es["LANG_PROCEDURES_DEFNORETURN_PROCEDURE"] = "func_sin_retorno";
es["LANG_PROCEDURES_DEFNORETURN_TOOLTIP"] = "Función que se ejecutará sin devolver nada.";
es["LANG_PROCEDURES_DEFRETURN_DO"] = "ejecutar";
es["LANG_PROCEDURES_DEFRETURN_HELPURL"] = "";
es["LANG_PROCEDURES_DEFRETURN_PROCEDURE"] = "func_con_retorno";
es["LANG_PROCEDURES_DEFRETURN_RETURN"] = "devuelve";
es["LANG_PROCEDURES_DEFRETURN_TOOLTIP"] = "Función con valor de retorno.";
es["LANG_PROCEDURES_DEF_DUPLICATE_WARNING"] = "Atención: Esta función tiene parámetros duplicados.";
es["LANG_PROCEDURES_HIGHLIGHT_DEF"] = "Subraya la función";
es["LANG_PROCEDURES_IFRETURN_TOOLTIP"] = "Si la condición es verdadera, la función devolverá este valor.";
es["LANG_PROCEDURES_IFRETURN_WARNING"] = "Atención: Este bloque sólo puede ser usado dentro de una función que tenga valor de retorno.";
es["LANG_PROCEDURES_MUTATORARG_Field"] = "variable:";
es["LANG_PROCEDURES_MUTATORCONTAINER_Field"] = "parámetros";
es["LANG_PROCEDURES_RETURN"] = "devuelve";
es["LANG_PROCEDURES_RETURN_TOOLTIP"] = "Devuelve un valor";
es["LANG_RESERVED_WORDS"] = "Palabra reservada: este nombre no está permitido.";
es["LANG_SALIDAS_BLUETOOTH_DEF"] = "Bluetooth";
es["LANG_SALIDAS_BLUETOOTH_DEF_BAUD_RATE"] = "Tasa de baudios";
es["LANG_SALIDAS_BLUETOOTH_DEF_NAME"] = "Nombre";
es["LANG_SALIDAS_BLUETOOTH_DEF_PIN1"] = "RX";
es["LANG_SALIDAS_BLUETOOTH_DEF_PIN2"] = "TX";
es["LANG_SALIDAS_BLUETOOTH_DEF_PINCODE"] = "Código PIN";
es["LANG_SALIDAS_BLUETOOTH_DEF_RECEIVE"] = "Recibir";
es["LANG_SALIDAS_BLUETOOTH_DEF_SEND"] = "Enviar";
es["LANG_SALIDAS_BLUETOOTH_DEF_TOOLTIP"] = "Definición del módulo Bluetooth";
es["LANG_SALIDAS_BLUETOOTH_NAME"] = "Renombrar módulo bluetooth";
es["LANG_SALIDAS_BLUETOOTH_NAME_TOOLTIP"] = "Cambiar el nombre del módulo bluetooth";
es["LANG_SALIDAS_BLUETOOTH_RECEIVE"] = "Bluetooth: recibir ";
es["LANG_SALIDAS_BLUETOOTH_RECEIVE_BLUETOOTH"] = "Bluetooth";
es["LANG_SALIDAS_BLUETOOTH_RECEIVE_TOOLTIP"] = "Devuelve los datos recibidos por el módulo Bluetooth";
es["LANG_SALIDAS_BLUETOOTH_SEND"] = "Bluetooth: Enviar";
es["LANG_SALIDAS_BLUETOOTH_SEND_SEND"] = "Enviar datos";
es["LANG_SALIDAS_BLUETOOTH_SEND_TOOLTIP"] = "Toma los datos de la entrada y los envía usando el módulo Bluetooth";
es["LANG_SALIDAS_BUTTONS"] = "Robot Control";
es["LANG_SALIDAS_BUTTONS_SPACE"] = "         ";
es["LANG_SALIDAS_BUTTONS_BUTTON_A"] = "Botón        A";
es["LANG_SALIDAS_BUTTONS_BUTTON_B"] = "Botón        B";
es["LANG_SALIDAS_BUTTONS_BUTTON_D"] = "Joystick  ";
es["LANG_SALIDAS_BUTTONS_BUTTON_L"] = "Joystick  ";
es["LANG_SALIDAS_BUTTONS_BUTTON_R"] = "Joystick  ";
es["LANG_SALIDAS_BUTTONS_BUTTON_S"] = "Joystick  ";
es["LANG_SALIDAS_BUTTONS_BUTTON_U"] = "Joystick  ";
es["LANG_SALIDAS_BUTTONS_TOOLTIP"] = "Controla tu placa vía bluetooth";
es["LANG_SALIDAS_INOUT_TONE"] = "Tono en pin #";
es["LANG_SALIDAS_INOUT_TONE_TOOLTIP"] = "Genera tonos de audio en un pin";
es["LANG_SALIDAS_INOUT_NOTONE"] = "Sin tono en pin #";
es["LANG_SALIDAS_INOUT_NOTONE_TOOLTIP"] = "Detiene la generación de tonos de audio en un pin";
es["LANG_SALIDAS_RAYITA"] = "|";
es["LANG_SALIDAS_ZUMBADOR_ACTIVO"] = "el ZUMBADOR ACTIVO en el pin";
es["LANG_SALIDAS_ZUMBADOR_ACTIVO_TOOLTIP"] = "Controla el zumbador activo";
es["LANG_SALIDAS_LASER"] = "el LÁSER en el pin";
es["LANG_SALIDAS_LASER_TOOLTIP"] = "Controla el láser";
es["LANG_SALIDAS_RELE_UNOMEGA"] = "el RELÉ en el pin";
es["LANG_SALIDAS_RELE_UNOMEGA_TOOLTIP"] = "Controla el relé";
es["LANG_SALIDAS_LED_BICOLOR_ELEGIR"] = "Elegir el color";
es["LANG_SALIDAS_LED_BICOLOR_APAGAR"] = "Apagar el LED BICOLOR en el pin R";
es["LANG_SALIDAS_LED_BICOLOR_PIN_R"] = "del LED BICOLOR en el pin R";
es["LANG_SALIDAS_LED_BICOLOR_PIN_Y"] = "y el pin Y";
es["LANG_SALIDAS_LED_BICOLOR_TOOLTIP"] = "Controla el LED bicolor";
es["LANG_SALIDAS_LEDRGB_UNOMEGA"] = "Elegir el color";
es["LANG_SALIDAS_LEDRGB_UNOMEGA_PIN_R"] = "del LED RGB en el pin R";
es["LANG_SALIDAS_LEDRGB_UNOMEGA_PIN_G"] = "G";
es["LANG_SALIDAS_LEDRGB_UNOMEGA_PIN_B"] = "B";
es["LANG_SALIDAS_LEDRGB_UNOMEGA_TOOLTIP"] = "Controla el LED RGB";
es["LANG_SALIDAS_LED_NEW"] = "el LED en el pin";
es["LANG_SALIDAS_LED"] = "Led";
es["LANG_SALIDAS_LED_PWM"] = "del LED en el pin";
es["LANG_SALIDAS_LED_RGB_PWM"] = "del módulo de LED RGB en el pin";
es["LANG_SALIDAS_LEDRGB"] = "Led RGB";
es["LANG_SALIDAS_LEDRGB_AMARILLO"] = "amarillo";
es["LANG_SALIDAS_LEDRGB_APAGADO"] = "Apagado";
es["LANG_SALIDAS_LEDRGB_AZUL"] = "azul";
es["LANG_SALIDAS_LEDRGB_COLOR"] = "Color";
es["LANG_SALIDAS_LEDRGB_DURATION"] = "Duración [ms]";
es["LANG_SALIDAS_LEDRGB_NARANJA"] = "naranja";
es["LANG_SALIDAS_LEDRGB_PINAZUL"] = "azul";
es["LANG_SALIDAS_LEDRGB_PINROJO"] = "rojo";
es["LANG_SALIDAS_LEDRGB_PINVERDE"] = "verde";
es["LANG_SALIDAS_LEDRGB_ROJO"] = "rojo";
es["LANG_SALIDAS_LEDRGB_TOOLTIP"] = "Led RGB";
es["LANG_SALIDAS_LEDRGB_VERDE"] = "verde";
es["LANG_SALIDAS_LEDRGB_VIOLETA"] = "violeta";
es["LANG_SALIDAS_LED_RGB_COLOR"] = "Ajustar el color a R";
es["LANG_SALIDAS_LED_RGB_COLOR_G"] = "G";
es["LANG_SALIDAS_LED_RGB_COLOR_B"] = "B";
es["LANG_SALIDAS_LED_INTENSIDAD"] = "Ajustar el brillo a";
es["LANG_SALIDAS_LED_OFF"] = "Apagar";
es["LANG_SALIDAS_LED_ON"] = "Prender";
es["LANG_SALIDAS_LED_PIN"] = "Pin";
es["LANG_SALIDAS_LED_STATE"] = "Estado";
es["LANG_SALIDAS_LED_TOOLTIP"] = "Controla el LED";
es["LANG_SALIDAS_LED_RGB_TOOLTIP"] = "Controla el LED RGB";
es["LANG_BHOOT_LED_RGB"] = "Prender de color";
es["LANG_BHOOT_LED_RGB_PIN"] = "los LEDs RGB en el pin";
es["LANG_BHOOT_LED_RGB_PIN_SINGLE"] = "el LED RGB en el pin";
es["LANG_BHOOT_LED_RGB_TOOLTIP"] = "Cambia el color de los LEDs RGB";
es["LANG_BHOOT_LED_NEOPIXEL"] = "Elegir el color";
es["LANG_BHOOT_LED_NEOPIXEL_LED"] = "en el LED";
es["LANG_BHOOT_LED_NEOPIXEL_LED_BOTH"] = "ambos";
es["LANG_BHOOT_LED_NEOPIXEL_LED_RIGHT"] = "derecho";
es["LANG_BHOOT_LED_NEOPIXEL_LED_LEFT"] = "izquierdo";
es["LANG_BHOOT_LIGHT_INTENSITY"] = "INTENSIDAD DE COLOR";
es["LANG_BHOOT_LIGHT_INTENSITY_PIN"] = "en el pin";
es["LANG_BHOOT_LIGHT_INTENSITY_TOOLTIP"] = "Obtiene la intensidad de un color";
es["LANG_BHOOT_LIGHT_COLOR"] = "COLOR en el pin";
es["LANG_BHOOT_LIGHT_COLOR_IS"] = "es";
es["LANG_BHOOT_LIGHT_COLOR_TOOLTIP"] = "Obtiene la intensidad de un color";
es["LANG_PEATON_NEOPIXEL_LED"] = "en la luz peatonal";
es["LANG_PEATON_NEOPIXEL_LED_TOOLTIP"] = "Enciende la luz peatonal";
es["LANG_PEATON_OFF_NEOPIXEL_LED_TOOLTIP"] = "Apaga la luz peatonal";
es["LANG_PEATON_OFF_NEOPIXEL"] = "Apagar la luz peatonal";
es["LANG_BHOOT_LED_NEOPIXEL_CLEAR"] = "Apagar el LED";
es["LANG_BHOOT_LED_NEOPIXEL_SHOW"] = "Enviar colores al LED";
es["LANG_BHOOT_JOYSTICK"] = "del JOYSTICK en el pin";
es["LANG_BHOOT_JOYSTICK_X"] = "Eje X";
es["LANG_BHOOT_JOYSTICK_Y"] = "Eje Y";
es["LANG_BHOOT_JOYSTICK_BUTTON"] = "Botón";
es["LANG_BHOOT_JOYSTICK_TOOLTIP"] = "Devuelve la posición del JOYSTICK";
es["LANG_SALIDAS_SEMAFORO"] = "del semáforo";
es["LANG_SALIDAS_SEMAFORO_LED"] = "el LED";
es["LANG_SALIDAS_SEMAFORO_TOOLTIP"] = "Enciende un color en el semáforo";
es["LANG_SALIDAS_SEMAFORO_GIRO"] = "de la luz de giro";
es["LANG_SALIDAS_SEMAFORO_GIRO_TOOLTIP"] = "Enciende la luz de giro";
es["LANG_SALIDAS_LUCES_CIUDAD"] = "las luces de";
es["LANG_SALIDAS_LUCES_CIUDAD_TOOLTIP"] = "Enciende las luces de la zona seleccionada";
es["MATRIZ_CIUDAD_TOOLTIP"] = "Escribe un texto en la matriz de la ciudad";
es["MATRIZ_CIUDAD_ESCRIBIR"] = "Escribir";
es["MATRIZ_CIUDAD_VELOCIDAD"] = "en la matriz con velocidad";
es["LANG_ENTRADAS_CIUDAD_LDR"] = "Nivel de luz";
es["LANG_ENTRADAS_CIUDAD_LDR_TOOLTIP"] = "";
es["LANG_SALIDAS_MOTOR"] = "Motor DC";
es["LANG_SALIDAS_MOTOR2_DIRECCION"] = "Dirección";
es["LANG_SALIDAS_MOTOR_DIRECCION"] = "Dirección";
es["LANG_SALIDAS_MOTOR_SINGLE_TOOLTIP"] = "Controla motores DC individualmente";
es["LANG_SALIDAS_MOTOR_SINGLE_PIN"] = "el MOTOR en el pin";
es["LANG_SALIDAS_MOTOR_DOBLE"] = "los MOTORES en los pines";
es["LANG_SALIDAS_MOTOR_DOBLE_PIND"] = "MD";
es["LANG_SALIDAS_MOTOR_DOBLE_PINI"] = "y MI";
es["LANG_SALIDAS_MOTOR_DOBLE_TOOLTIP"] = "Controla motores DC";
es["LANG_SALIDAS_MOTOR_DOBLE_PWM"] = "Motores DC";
es["LANG_SALIDAS_MOTOR_DOBLE_PWM_PIND"] = "MD";
es["LANG_SALIDAS_MOTOR_DOBLE_PWM_PINI"] = "y MI";
es["LANG_SALIDAS_MOTOR_DOBLE_PWM_SPEED"] = "con velocidad";
es["LANG_SALIDAS_MOTOR_DOBLE_PWM_TOOLTIP"] = "Controla motores DC con velocidad";
es["LANG_SALIDAS_MOTOR_OFF"] = "OFF";
es["LANG_SALIDAS_MOTOR_ON"] = "ON";
es["LANG_SALIDAS_MOTOR_PIN"] = "el MOTOR DC en el pin";
es["LANG_SALIDAS_MOTOR_PIN_PWM"] = "Girar el MOTOR DC en el pin";
es["LANG_SALIDAS_MOTOR_DC_PIN"] = "Mover el MOTOR DC en el pin";
es["LANG_SALIDAS_MOTOR_STATE"] = "";
es["LANG_SALIDAS_MOTOR_TOOLTIP"] = "Controla el motor DC";
es["LANG_CIUDAD_REESTABLECER"] = "Restablecer servicio";
es["LANG_CIUDAD_REESTABLECER_TOOLTIP"] = "";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO"] = "| Reproducir";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO_NOTE"] = "nota";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO_DURATION"] = "con duración [ms]";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO_PIN"] = "en el pin";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO_SPACE"] = "                                                                                        ";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO_SPACE_CSS"] = "pseudoBlocklyEmptyText-ES";
es["LANG_SALIDAS_PIEZO_BUZZER_PIANO_OCTAVE"] = "octava";
es["LANG_BHOOT_PIEZO_BUZZER_PIANO_SPACE"] = "                                            ";
es["LANG_BHOOT_PIEZO_BUZZER_PIANO"] = "Reproducir";
es["LANG_SALIDAS_PIEZO_BUZZER"] = "Zumbador";
es["LANG_SALIDAS_PIEZO_BUZZERNO"] = "Apagar ZUMBADOR";
es["LANG_BHOOT_PIEZO_BUZZERNO"] = "| Apagar ZUMBADOR integrado";
es["LANG_BHOOT_PIEZO_BUZZER_PIN"] = "en el ZUMBADOR integrado";
es["LANG_SALIDAS_PIEZO_BUZZERNO_PIN"] = "Pin";
es["LANG_SALIDAS_PIEZO_BUZZER_DO"] = "DO";
es["LANG_SALIDAS_PIEZO_BUZZER_DURATION"] = "Duración [ms]";
es["LANG_BHOOT_PIEZO_BUZZER_DURATION"] = "con duración [ms]";
es["LANG_SALIDAS_PIEZO_BUZZER_FA"] = "FA";
es["LANG_SALIDAS_PIEZO_BUZZER_LA"] = "LA";
es["LANG_SALIDAS_PIEZO_BUZZER_MI"] = "MI";
es["LANG_SALIDAS_PIEZO_BUZZER_PIN"] = "en el pin";
es["LANG_SALIDAS_PIEZO_BUZZER_RE"] = "RE";
es["LANG_SALIDAS_PIEZO_BUZZER_SI"] = "SI";
es["LANG_SALIDAS_PIEZO_BUZZER_SOL"] = "SOL";
es["LANG_SALIDAS_PIEZO_BUZZER_TONE"] = "Tono";
es["LANG_SALIDAS_PIEZO_BUZZER_TONE_LC"] = "tono";
es["LANG_SALIDAS_PIEZO_BUZZER_NOTE"] = "nota";
es["LANG_SALIDAS_PIEZO_BUZZER_TOOLTIP"] = "Zumbador piezoeléctrico";
es["LANG_SALIDAS_RELE"] = "Relé (invertido)";
es["LANG_SALIDAS_RELE_OFF"] = "Encender";
es["LANG_SALIDAS_RELE_ON"] = "Apagar";
es["LANG_SALIDAS_RELE_PIN"] = "Pin";
es["LANG_SALIDAS_RELE_TOOLTIP"] = "Controla el relé";
es["LANG_SALIDAS_SCAN_DEF"] = "Escaner I2C";
es["LANG_SALIDAS_DISPLAY_7_SEGMENTS"] = "en el display de 7 segmentos";
es["LANG_SALIDAS_DISPLAY_7_SEGMENTS_ESCRIBIR"] = "Escribir";
es["LANG_SALIDAS_DISPLAY_7_SEGMENTS_TOOLTIP"] = "Muestra un número en el display de 7 segmentos";
es["LANG_SALIDAS_DISPLAY_7_SEGMENTS_OFF"] = "Apagar el display de 7 segmentos";
es["LANG_SALIDAS_DISPLAY_7_SEGMENTS_OFF_TOOLTIP"] = "Apaga el display de 7 segmentos";
es["LANG_SERVO"] = "Servo";
es["LANG_SERVO_CONT"] = "Servo 360";
es["LANG_SERVO_CONT_DELAY"] = "Pausa [ms]";
es["LANG_SERVO_CONT_PIN"] = "Pin";
es["LANG_SERVO_CONT_ROT"] = "Rotación";
es["LANG_SERVO_CONT_STOPPED"] = "Detener";
es["LANG_SERVO_CONT_TOOLTIP"] = "Servo de rotación continua.";
es["LANG_SERVO_CONT_TURN_CLOCKWISE"] = "Horario";
es["LANG_SERVO_CONT_TURN_COUNTERCLOCKWISE"] = "Antihorario";
es["LANG_SERVO_MOVE"] = "el SERVO en el pin";
es["LANG_SERVO_MOVE_DEGREES"] = "Posicionar en el grado";
es["LANG_SERVO_MILLIS_MOVE_MILLIS"] = "cada";
es["LANG_SERVO_MILLIS_MOVE_PIN"] = "[ms] el SERVO en el pin";
es["LANG_SERVO_MILLIS_MOVE_DEGREES"] = "Mover gradualmente al grado";
es["LANG_SERVO_MOVE_DELAY"] = "Pausa [ms]";
es["LANG_SERVO_MOVE_PIN"] = "Pin";
es["LANG_SERVO_MOVE_TOOLTIP"] = "Mover el servo entre 0 y 180 grados.";
es["LANG_SERVO_READ"] = "SERVO en el pin";
es["LANG_SERVO_WARNING"] = "No puedes asignar una variable al pin del servo";
es["LANG_COPA_NANOBOT_DIRECTION"] = "grados el NANOROBOT en sentido";
es["LANG_COPA_NANOBOT_TURN"] = "Girar";
es["LANG_COPA_NANOBOT_TURN_TOOLTIP"] = "Girar el nanorobot";
es["LANG_COPA_SHOOT_ANTIGEN"] = "Disparar ANTÍGENO al AGENTE VIRAL";
es["LANG_COPA_SHOOT_ANTIGEN_TOOLTIP"] = "Disparar ANTÍGENO";
es["LANG_COPA_IDENTIFY_AGENT"] = "Identificar AGENTE VIRAL";
es["LANG_COPA_IDENTIFY_AGENT_TOOLTIP"] = "Identificar AGENTE VIRAL";
es["LANG_COPA_EVALUATE_POSITION"] = "Evaluar posición con respecto al AGENTE VIRAL";
es["LANG_COPA_EVALUATE_POSITION_TOOLTIP"] = "Evaluar posición con respecto al AGENTE VIRAL";
es["LANG_COPA_SHOOT_SPECIAL_ANTIGEN"] = "Disparar antígeno al AGENTE VIRAL ESPECIAL";
es["LANG_COPA_SHOOT_SPECIAL_ANTIGEN_POSITION"] = "en la posición";
es["LANG_COPA_SHOOT_SPECIAL_ANTIGEN_TOOLTIP"] = "Disparar antígeno al AGENTE VIRAL ESPECIAL";
es["LANG_SensorFrecuencia"] = "Frecuencia";
es["LANG_SensorFrecuenciaInit_TOOLTIP"] = "Inicia la libreria del sensor frecuencia";
es["LANG_SensorFrecuenciaSalida_TOOLTIP"] = "Devuelve el valor de frecuencia medido en HZ";
es["LANG_SensorGesto"] = "Gestos";
es["LANG_SensorGesto_BUTTON_A"] = "Cerca";
es["LANG_SensorGesto_BUTTON_B"] = "Lejos";
es["LANG_SensorGesto_BUTTON_D"] = "Abajo";
es["LANG_SensorGesto_BUTTON_L"] = "Izquierda";
es["LANG_SensorGesto_BUTTON_R"] = "Derecha";
es["LANG_SensorGesto_BUTTON_U"] = "Arriba";
es["LANG_SensorGesto_TOOLTIP"] = "Ejecuta una acción dependiendo del gesto leído";
es["LANG_TEXT_APPEND_APPENDTEXT"] = "añadirle texto";
es["LANG_TEXT_APPEND_HELPURL"] = "";
es["LANG_TEXT_APPEND_TO"] = "a";
es["LANG_TEXT_APPEND_TOOLTIP"] = "Añadir texto a la variable %1.";
es["LANG_TEXT_APPEND_VARIABLE"] = "elemento";
es["LANG_TEXT_CODE"] = "Línea de código:";
es["LANG_TEXT_CODE_TOOLTIP"] = "Escribir línea de código";
es["LANG_TEXT_CREATE_JOIN_Field_JOIN"] = "unir";
es["LANG_TEXT_CREATE_JOIN_ITEM_Field_ITEM"] = "elemento";
es["LANG_TEXT_CREATE_JOIN_ITEM_TITLE_ITEM"] = "elemento";
es["LANG_TEXT_CREATE_JOIN_ITEM_TOOLTIP"] = "Añadir un elemento al texto.";
es["LANG_TEXT_CREATE_JOIN_TITLE_JOIN"] = "unir";
es["LANG_TEXT_CREATE_JOIN_TOOLTIP"] = "Añadir, eliminar o reordenar secciones para reconfigurar este bloque de texto.";
es["LANG_TEXT_EQUALSIGNORECASE_EQUAL"] = " =";
es["LANG_TEXT_EQUALSIGNORECASE_IS"] = "";
es["LANG_TEXT_EQUALSIGNORECASE_QUESTION"] = "";
es["LANG_TEXT_EQUALSIGNORECASE_TOOLTIP"] = "Compara si los dos textos introducidos son iguales, independientemente de que tengan letras mayúsculas o minúsculas.";
es["LANG_TEXT_JOIN_Field_CREATEWITH"] = "crear texto con";
es["LANG_TEXT_JOIN_HELPURL"] = "";
es["LANG_TEXT_JOIN_TOOLTIP"] = "Crea texto juntando cualquier número de elementos.";
es["LANG_TEXT_LENGTH_HELPURL"] = "";
es["LANG_TEXT_LENGTH_INPUT_LENGTH"] = "longitud";
es["LANG_TEXT_LENGTH_TOOLTIP"] = "Devuelve el número de letras (incluyendo los espacios) en el texto introducido.";
es["LANG_TEXT_SUBSTRING"] = "Recortar ";
es["LANG_TEXT_SUBSTRING_FROM"] = "desde";
es["LANG_TEXT_SUBSTRING_TO"] = "hasta";
es["LANG_TEXT_SUBSTRING_TOOLTIP"] = "Recorta los caracteres del texto introducido que se encuentren entre los dos índices y crea con ellos un nuevo texto.";
es["LANG_TEXT_TEXT_HELPURL"] = "";
es["LANG_TEXT_TEXT_TOOLTIP"] = "Una letra, una palabra o una línea de texto.";
es["LANG_VARIABLES_CALL_WITHOUT_DEFINITION"] = "Esta variable no está declarada.";
es["LANG_VARIABLES_DEFINE"] = "Definir variable ";
es["LANG_VARIABLES_DEFINE_AS"] = "como";
es["LANG_VARIABLES_DEFINE_TOOLTIP"] = "Definir el valor de una variable.";
es["LANG_VARIABLES_GET"] = "Var";
es["LANG_VARIABLES_GET_TOOLTIP"] = "Devuelve el valor de una variable";
es["LANG_VARIABLES_GLOBAL"] = "Declarar variable GLOBAL";
es["LANG_VARIABLES_GLOBAL_EQUALS"] = "=";
es["LANG_VARIABLES_GLOBAL_TOOLTIP"] = "Declara y define una variable GLOBAL de tipo entero (int) o texto (String).";
es["LANG_VARIABLES_GLOBAL_TYPE"] = "de tipo ";
es["LANG_VARIABLES_LOCAL"] = "Declarar variable";
es["LANG_VARIABLES_LOCAL_EQUALS"] = "=";
es["LANG_VARIABLES_LOCAL_TOOLTIP"] = "Declara y define una variable LOCAL de tipo entero (int) o texto (String).";
es["LANG_VARIABLES_LOCAL_TYPE"] = "de tipo ";
es["LANG_VARIABLES_PIN_ANALOG"] = "Pin analógico";
es["LANG_VARIABLES_PIN_DIGITAL"] = "Pin digital";
es["LANG_VARIABLES_PIN_DIGITAL0"] = "CUIDADO: el pin digital 0 (pin Rx) es usado para escribir un programa en la placa desde el ordenador. Usarlo para conectar componentes puede dar problemas al programarla.";
es["LANG_VARIABLES_PIN_TOOLTIP"] = "Selecciona el Pin deseado.";
es["LANG_VARIABLES_SET"] = "Var";
es["LANG_VARIABLES_SET_AS"] = "=";
es["LANG_VARIABLES_SET_ITEM"] = "elemento";
es["LANG_VARIABLES_SET_TOOLTIP"] = "Cambia el valor de una variable.";
es["LANG_VARIABLES_TYPE_CHAR"] = "Caracter";
es["LANG_VARIABLES_TYPE_BYTE"] = "Octeto";
es["LANG_VARIABLES_TYPE_FLOAT"] = "Decimal";
es["LANG_VARIABLES_TYPE_INTEGER"] = "Entero";
es["LANG_VARIABLES_TYPE_INTEGER_LONG"] = "Entero largo";
es["LANG_VARIABLES_TYPE_STRING"] = "Texto";
es["LANG_VARIABLES_SET_VALUE"] = "Establecer Cantidad a";
es["LANG_VARIABLES_SET_VALUE_TOOLTIP"] = "Inicializa la variable Cantidad con un valor";
es["LANG_VARIABLES_GET_VALUE"] = "Cantidad";
es["LANG_VARIABLES_GET_VALUE_TOOLTIP"] = "Obtiene el valor de la variable Cantidad";
es["LANG_VARIABLES_ADD_ONE"] = "Sumar 1";
es["LANG_VARIABLES_ADD_ONE_TOOLTIP"] = "Suma 1 a la variable Cantidad";
es["LANG_VARIABLES_SUBSTRACT_ONE"] = "Restar 1";
es["LANG_VARIABLES_SUBSTRACT_ONE_TOOLTIP"] = "Resta 1 a la variable Cantidad";
es["LANG_MORSE_SAVE_MESSAGE"] = "Guardar mensaje";
es["LANG_MORSE_SAVE_MESSAGE_TOOLTIP"] = "Guardar mensaje";
es["LANG_MORSE_DECODE_MESSAGE"] = "Decodificar mensaje";
es["LANG_MORSE_DECODE_MESSAGE_TOOLTIP"] = "Decodificar mensaje";
es["LANG_MORSE_GET_MESSAGE"] = "Mensaje decodificado";
es["LANG_MORSE_GET_MESSAGE_TOOLTIP"] = "Mensaje decodificado";
es["LANG_gestos_DEF"] = "Gestos y color";
es["LANG_gestos_DEF_TOOLTIP"] = "Inicia la libreria del sensor gestos y color";
es["LANG_matriz_DEF"] = "Matriz";
es["LANG_matriz_DEF_TOOLTIP"] = "Define la Matriz";
es["LANG_matriz_PRINT"] = "Matriz: Escribir ";
es["LANG_matriz_PRINT_TOOLTIP"] = "Imprime texto en la matriz";
es["LANG_matriz_RGB"] = "Matriz RGB";
es["LANG_matriz_caras_enojado"] = "Enojado";
es["LANG_matriz_caras_estado"] = "Matriz: Cara";
es["LANG_matriz_caras_feliz"] = "Feliz";
es["LANG_matriz_caras_sorprendido"] = "Sorprendido";
es["LANG_matriz_dados_estado"] = "Matriz: Dados";
es["LANG_MATRIZ_TEXT_ON"] = "en la matriz 8x8 en el pin";
es["LANG_MATRIZ_TEXT_PIN"] = "";
es["LANG_MATRIZ_TEXT_PIN_TOOLTIP"] = "Escribe en la matriz 8x8 un texto";
es["LANG_MATRIZ_8x16_TEXT"] = "en la matriz 8x16 con velocidad";
es["LANG_MATRIZ_8x16_TEXT_SLOW"] = "lenta";
es["LANG_MATRIZ_8x16_TEXT_FAST"] = "rápida";
es["LANG_MATRIZ_8x16_TEXT_DRAW"] = "Dibujar";
es["LANG_MATRIZ_8x16_TEXT_CLEAR"] = "Borrar";
es["LANG_MATRIZ_8x16_TEXT_PIN"] = "en el pin";
es["LANG_MATRIZ_8x16_TEXT_PIN_TOOLTIP"] = "Escribe en la matriz 8x16 un texto";
es["LANG_ENTRADAS_LINE_FOLLOWER"] = "Seguidor de líneas";
es["LANG_ENTRADAS_LINE_FOLLOWER_PIN"] = "en el pin";
es["LANG_ENTRADAS_LINE_FOLLOWER_TOOLTIP"] = "Obtiene los valores del sensor de línea para programar un robot seguidor";
es["LANG_ENTRADAS_LINE_FOLLOWER_DERECHA"] = "Derecha";
es["LANG_ENTRADAS_LINE_FOLLOWER_CENTRO"] = "Centro";
es["LANG_ENTRADAS_LINE_FOLLOWER_IZQUIERDA"] = "Izquierda";
es["LANG_ENTRADAS_HUMEDAD_TREN"] = "HUMEDAD";
es["LANG_ENTRADAS_HUMEDAD_TREN_TOOLTIP"] = "Devuelve el porcentaje de humedad";
es["LANG_COMPARE_TRAIN"] = "detecta que el tren";
es["LANG_COMPARE_TRAIN_COMMING"] = "está viniendo";
es["LANG_COMPARE_TRAIN_PASSING"] = "pasó";
es["LANG_COMPARE_LUMINOSITY"] = "es";
es["LANG_COMPARE_LUMINOSITY_HIGH"] = "alto";
es["LANG_COMPARE_LUMINOSITY_LOW"] = "bajo";
es["LANG_COMPARE_SHIP"] = "está";
es["LANG_COMPARE_SHIP_WAITING"] = "esperando";
es["LANG_COMPARE_SHIP_PASSED"] = "ya cruzó";
es["LANG_COMPARE_SHIP_PASSING"] = "moviéndose";
es["LANG_SHIP"] = "Embarcación";
es["LANG_SHIP_TOOLTIP"] = "Estado de la embarcación";
es["LANG_BRIDGE"] = "Puente";
es["LANG_BRIDGE_TOOLTIP"] = "Puente";
es["LANG_COMPARE_BRIDGE"] = "está";
es["LANG_COMPARE_BRIDGE_CLOSED"] = "cerrado";
es["LANG_COMPARE_BRIDGE_OPEN"] = "abierto";
es["LANG_COMPARE_BRIDGE_MOVING"] = "moviéndose";
es["LANG_COMPARE_BRIDGE_TOOLTIP"] = "Estado del puente";
es["LANG_RESTORE_ELECTRICITY"] = "Restablecer el servicio de electricidad";
es["LANG_RESTORE_ELECTRICITY_TOOLTIP"] = "Restablecer el servicio de electricidad";
es["LANG_LUMINARIA_ZONA3"] = "luminaria zona 3";
es["LANG_LUMINARIA_ZONA3_TOOLTIP"] = "Enciende las luces en la zona 3";
es["LANG_LUMINARIA_ZONA1"] = "luminaria zona 1";
es["LANG_LUMINARIA_ZONA1_TOOLTIP"] = "Enciende las luces en la zona 1";
es["LANG_LUMINARIA_ZONA5"] = "luminaria zona 5";
es["LANG_LUMINARIA_ZONA5_TOOLTIP"] = "Enciende las luces en la zona 5";
es["LANG_LUCES_DIQUE3"] = "las luces del dique 3";
es["LANG_LUCES_DIQUE3_TOOLTIP"] = "Enciende las luces en el dique 3";
es["LANG_LUCES_DIQUE3_OFF"] = "Apagar las luces del dique 3";
es["LANG_LUCES_DIQUE3_OFF_TOOLTIP"] = "Apaga las luces en el dique 3";
es["LANG_PUENTE_MUJER"] = "la luz del Puente de la Mujer";
es["LANG_PUENTE_MUJER_TOOLTIP"] = "Enciende las luces en el Puente de la Mujer";
es["LANG_PUENTE_MUJER_OFF"] = "Apagar la luz del Puente de la Mujer";
es["LANG_PUENTE_MUJER_OFF_TOOLTIP"] = "Apaga las luces del Puente de la Mujer";
es["LANG_SENDA_PEATONAL"] = "las luces de la senda peatonal";
es["LANG_SENDA_PEATONAL_TOOLTIP"] = "Enciende las luces en la senda peatonal";
es["LANG_SENDA_PEATONAL_OFF"] = "Apagar las luces de la senda peatonal";
es["LANG_SENDA_PEATONAL_OFF_TOOLTIP"] = "Apaga las luces de la senda peatonal";
es["LANG_OPEN_PUENTE_MUJER"] = "el Puente de la Mujer";
es["LANG_OPEN_PUENTE_MUJER_ON"] = "Abrir";
es["LANG_OPEN_PUENTE_MUJER_OFF"] = "Cerrar";
es["LANG_OPEN_PUENTE_MUJER_TOOLTIP"] = "Mueve el Puente de la Mujer";
es["LANG_SHIP_OK_SIGNAL"] = "Dar señal de OK al barco para que pase";
es["LANG_SHIP_OK_SIGNAL_TOOLTIP"] = "Da señal de OK al barco para pasar";
es["LANG_PLANETARIO_LIGHTS_EFFECT"] = "Elegir efecto";
es["LANG_PLANETARIO_LIGHTS_DOME"] = "en las luces de la cúpula";
es["LANG_PLANETARIO_LIGHTS_TOOLTIP"] = "Enciende las luces del planetario con el efecto seleccionado";
es["LANG_PLANETARIO_LIGHTS_EFFECT_RAINBOW"] = "arcoiris";
es["LANG_PLANETARIO_LIGHTS_EFFECT_CASCADE"] = "cascada";
es["LANG_PLANETARIO_LIGHTS_EFFECT_TRANSITION"] = "transición";
es["LANG_PLANETARIO_LIGHTS_EFFECT_OFF"] = "Apagar las luces de la cúpula";
es["LANG_PLANETARIO_LIGHTS_OFF_TOOLTIP"] = "Apaga las luces de la cúpula";
es["LANG_PLANETARIO_REFLECTOR"] = "en los reflectores";
es["LANG_PLANETARIO_REFLECTOR_TOOLTIP"] = "Enciende los reflectores del planetario";
es["LANG_PLANETARIO_REFLECTOR_OFF"] = "Apagar los reflectores";
es["LANG_PLANETARIO_REFLECTOR_OFF_TOOLTIP"] = "Apaga los reflectores del planetario";
es["LANG_PLANETARIO_SCREEN_MESSAGE_SHOW"] = "Mostrar el mensaje";
es["LANG_PLANETARIO_SCREEN_MESSAGE_0"] = "VIAJE POR EL SISTEMA SOLAR";
es["LANG_PLANETARIO_SCREEN_MESSAGE_1"] = "ASTRONOMÍA EN VIVO";
es["LANG_PLANETARIO_SCREEN_MESSAGE_2"] = "PLANETARIO DE LA CIUDAD DE BS. AS.";
es["LANG_PLANETARIO_SCREEN_MESSAGE_3"] = "DÍA NACIONAL DE LA ASTRONOMÍA";
es["LANG_PLANETARIO_SCREEN"] = "en la pantalla";
es["LANG_PLANETARIO_SCREEN_TOOLTIP"] = "Muestra en la pantalla el mensaje seleccionado";
es["LANG_PLANETARIO_TELESCOPE_AIM"] = "Apuntar en dirección";
es["LANG_PLANETARIO_TELESCOPE_BASE"] = "la base del telescopio";
es["LANG_PLANETARIO_TELESCOPE_NORTE"] = "norte";
es["LANG_PLANETARIO_TELESCOPE_NORESTE"] = "noreste";
es["LANG_PLANETARIO_TELESCOPE_ESTE"] = "este";
es["LANG_PLANETARIO_TELESCOPE_SURESTE"] = "sureste";
es["LANG_PLANETARIO_TELESCOPE_SUR"] = "sur";
es["LANG_PLANETARIO_TELESCOPE_SUROESTE"] = "suroeste";
es["LANG_PLANETARIO_TELESCOPE_OESTE"] = "oeste";
es["LANG_PLANETARIO_TELESCOPE_NOROESTE"] = "noroeste";
es["LANG_PLANETARIO_TELESCOPE"] = "el lente del telescopio";
es["LANG_PLANETARIO_TELESCOPE_TOOLTIP"] = "Mueve el telescopio";
es["LANG_COLON_LIGHTS_EFFECT"] = "Prender con efecto";
es["LANG_COLON_LIGHTS_EFFECT_TRANSITION"] = "transición";
es["LANG_COLON_LIGHTS_EFFECT_FIXED"] = "fijo";
es["LANG_COLON_LIGHTS_EFFECT_ATTENUATION"] = "atenuación";
es["LANG_COLON_LIGHTS"] = "las luces del Teatro Colón";
es["LANG_COLON_LIGHTS_TOOLTIP"] = "Enciende las luces del Teatro Colón con el efecto seleccionado";
es["LANG_COLON_LIGHTS_OFF"] = "Apagar las luces del Teatro Colón";
es["LANG_COLON_LIGHTS_OFF_TOOLTIP"] = "Apaga las luces del Teatro Colón";
es["LANG_OBELISCO_LIGHTS"] = "las luces del Obelisco";
es["LANG_OBELISCO_LIGHTS_TOOLTIP"] = "Enciende las luces del Obelisco con el color elegido";
es["LANG_OBELISCO_LIGHTS_OFF"] = "Apagar las luces del Obelisco";
es["LANG_OBELISCO_LIGHTS_OFF_TOOLTIP"] = "Apaga las luces del Obelisco";
es["LANG_OBELISCO_SCREEN_MESSAGE_SHOW"] = "Mostrar el mensaje";
es["LANG_OBELISCO_SCREEN_MESSAGE_0"] = "RESPETE LA VELOCIDAD MÁXIMA";
es["LANG_OBELISCO_SCREEN_MESSAGE_1"] = "MANEJE CON PRECAUCIÓN";
es["LANG_OBELISCO_SCREEN_MESSAGE_2"] = "NO UTILIZAR EL CELULAR MIENTRAS CONDUCE";
es["LANG_OBELISCO_SCREEN_MESSAGE_3"] = "UTILICE SU CINTURÓN DE SEGURIDAD";
es["LANG_OBELISCO_SCREEN"] = "en la pantalla";
es["LANG_OBELISCO_SCREEN_TOOLTIP"] = "Muestra en la pantalla el mensaje seleccionado";
es["LANG_LUMINARIA_ZONA2"] = "luminaria zona 2";
es["LANG_LUMINARIA_ZONA2_TOOLTIP"] = "Enciende las luces en la zona 2";
es["LANG_LUMINARIA_ZONA4"] = "luminaria zona 4";
es["LANG_LUMINARIA_ZONA4_TOOLTIP"] = "Enciende las luces en la zona 4";
es["LANG_FLORALIS_LIGHTS"] = "las luces de la Floralis Genérica";
es["LANG_FLORALIS_LIGHTS_TOOLTIP"] = "Enciende las luces de la Floralis Genérica con el color elegido";
es["LANG_FLORALIS_LIGHTS_OFF"] = "Apagar las luces de la Floralis Genérica";
es["LANG_FLORALIS_LIGHTS_OFF_TOOLTIP"] = "Apaga las luces de la Floralis Genérica";
es["LANG_FLORALIS_REFLECTOR"] = "los reflectores";
es["LANG_FLORALIS_REFLECTOR_TOOLTIP"] = "Enciende los reflectores de la Floralis Genérica";
es["LANG_FLORALIS_REFLECTOR_OFF"] = "Apagar los reflectores";
es["LANG_FLORALIS_REFLECTOR_OFF_TOOLTIP"] = "Apaga los reflectores de la Floralis Genérica";
es["LANG_RESTORE_PETAL"] = "Reparar pétalo de la Floralis Genérica";
es["LANG_RESTORE_PETAL_TOOLTIP"] = "Repara los pétalos de la Floralis Genérica";
es["LANG_FLORALIS_OPEN_PETAL"] = "la Floralis Genérica";
es["LANG_FLORALIS_ACTION_OPEN"] = "Abrir";
es["LANG_FLORALIS_ACTION_CLOSE"] = "Cerrar";
es["LANG_FLORALIS_OPEN_PETAL_TOOLTIP"] = "Abre los pétalos de la Floralis Genérica";
es["LANG_BHOOT_LED_NEOPIXEL2"] = "Prender de color";
es["LANG_ENTRADAS_TRAIN_PASSING"] = "Sensor de paso";
es["LANG_ENTRADAS_TRAIN_PASSING_TOOLTIP"] = "";
es["LANG_RETIRO_BLINKING_LIGHTS"] = "luces intermitentes";
es["LANG_RETIRO_BLINKING_LIGHTS_SECONDS"] = "segundos";
es["LANG_RETIRO_BLINKING_LIGHTS_TOOLTIP"] = "Enciende las luces intermitentes";
es["LANG_RETIRO_TRAIN_SERVICE"] = "servicio del ferrocarril";
es["LANG_RETIRO_TRAIN_SERVICE_SANMARTIN"] = "servicio del ferrocarril San Martín";
es["LANG_RETIRO_TRAIN_SERVICE_MITRE"] = "servicio del ferrocarril Mitre";
es["LANG_RETIRO_TRAIN_SERVICE_ON"] = "Iniciar";
es["LANG_RETIRO_TRAIN_SERVICE_OFF"] = "Detener";
es["LANG_RETIRO_TRAIN_SERVICE_TOOLTIP"] = "Inicia el servicio del ferrocarril";
es["LANG_RETIRO_TRAIN_FREQUENCY"] = "Establecer servicio del ferrocarril";
es["LANG_RETIRO_TRAIN_FREQUENCY_NAME_SANMARTIN"] = "San Martín";
es["LANG_RETIRO_TRAIN_FREQUENCY_NAME_MITRE"] = "Mitre";
es["LANG_RETIRO_TRAIN_FREQUENCY_EVERY"] = "cada";
es["LANG_RETIRO_TRAIN_FREQUENCY_SANMARTIN"] = "Establecer servicio del ferrocarril San Martín cada";
es["LANG_RETIRO_TRAIN_FREQUENCY_MITRE"] = "Establecer servicio del ferrocarril Mitre cada";
es["LANG_RETIRO_TRAIN_FREQUENCY_SECONDS"] = "segundos";
es["LANG_RETIRO_TRAIN_FREQUENCY_TOOLTIP"] = "Establece la frecuencia del servicio del ferrocarril";
es["LANG_RETIRO_BARRIERS"] = "barrera de paso a nivel";
es["LANG_RETIRO_BARRIERS_UP"] = "Subir";
es["LANG_RETIRO_BARRIERS_DOWN"] = "Bajar";
es["LANG_RETIRO_BARRIERS_TOOLTIP"] = "Sube o baja la barrera de paso a nivel";


// MICROBIT
es["LANG_RAYITA"] = '|';
es["LANG_START"] = 'Al iniciar';
es["LANG_START_2"] = 'Inicio';
es["LANG_FOREVER"] = 'Para siempre';
es["LANG_REPEAT"] = 'Repetir';
es["LANG_DELAY"] = 'Esperar (ms)';
es["LANG_DELAY_MS"] = ' ';
es["LANG_DELAY_TOOLTIP"] = 'Pausa la ejecución durante el tiempo indicado en milisegundos';
es["LANG_SHOW_NUMBER"] = 'Mostrar número';
es["LANG_SHOW_NUMBER_TOOLTIP"] = 'Muestra un número en la matriz de leds. Si no cabe, el número se desplaza';
es["LANG_SHOW_STRING"] = 'Mostrar texto';
es["LANG_SHOW_STRING_TOOLTIP"] = 'Muestra texto en la matriz de leds. Si no cabe, el texto se desplaza';
es["LANG_SHOW_ICON"] = 'Mostrar ícono';
es["LANG_SHOW_ICON_TOOLTIP"] = 'Dibuja el ícono seleccionado en la pantalla de LED';
es["LANG_SHOW_ICON_HEART_TOOLTIP"] = "Corazón";
es["LANG_SHOW_ICON_HEART_SMALL_TOOLTIP"] = "Corazón pequeño";
es["LANG_SHOW_ICON_YES_TOOLTIP"] = "Sí";
es["LANG_SHOW_ICON_NO_TOOLTIP"] = "No";
es["LANG_SHOW_ICON_HAPPY_FACE_TOOLTIP"] = "Felíz";
es["LANG_SHOW_ICON_SAD_FACE_TOOLTIP"] = "Triste";
es["LANG_SHOW_ICON_CONFUSED_TOOLTIP"] = "Confundido";
es["LANG_SHOW_ICON_ANGRY_TOOLTIP"] = "Enojado";
es["LANG_SHOW_ICON_ASLEEP_TOOLTIP"] = "Dormido";
es["LANG_SHOW_ICON_SURPRISED_TOOLTIP"] = "Sorprendido";
es["LANG_SHOW_ICON_SILLY_TOOLTIP"] = "Gracioso";
es["LANG_SHOW_ICON_FABULOUS_TOOLTIP"] = "Fabuloso";
es["LANG_SHOW_ICON_MEH_TOOLTIP"] = "Meh";
es["LANG_SHOW_ICON_TSHIRT_TOOLTIP"] = "Camiseta";
es["LANG_SHOW_ICON_ROLLERSKATE_TOOLTIP"] = "Patines";
es["LANG_CLEAR_SCREEN"] = "Borrar la pantalla";
es["LANG_CLEAR_SCREEN_TOOLTIP"] = "Apagar todos los LED";
es["LANG_SHOW_ARROW"] = "Mostrar flecha";
es["LANG_SHOW_ARROW_TOOLTIP"] = "Dibuja una flecha en los LED";
es["LANG_SHOW_ARROW_NORTH"] = "Norte";
es["LANG_SHOW_ARROW_NORTHEAST"] = "Noreste";
es["LANG_SHOW_ARROW_EAST"] = "Este";
es["LANG_SHOW_ARROW_SOUTHEAST"] = "Sureste";
es["LANG_SHOW_ARROW_SOUTH"] = "Sur";
es["LANG_SHOW_ARROW_SOUTHWEST"] = "Suroeste";
es["LANG_SHOW_ARROW_WEST"] = "Oeste";
es["LANG_SHOW_ARROW_NORTHWEST"] = "Noroeste";
es["LANG_BUTTON_PRESSED"] = "Al presionar el botón";
es["LANG_BUTTON_PRESSED_TOOLTIP"] = "Hacer algo cuando un botón (A, B o ambos A+B) se presiona y se suelta de nuevo";
es["LANG_IF"] = "Si";
es["LANG_IF_2"] = "Al";
es["LANG_GESTURE"] = "gesto";
es["LANG_GESTURE_TOOLTIP"] = "Hacer algo cuando se hace un gesto (como sacudir el micro:bit)";
es["LANG_GESTURE_EVENT_TOOLTIP"] = "Obtener el estado de un gesto";
es["LANG_GESTURE_SHAKE_TOOLTIP"] = "agitado";
es["LANG_GESTURE_SHAKE_TOOLTIP_2"] = "agitar";
es["LANG_GESTURE_LOGOUP_TOOLTIP"] = "logotipo hacia arriba";
es["LANG_GESTURE_LOGODOWN_TOOLTIP"] = "logotipo hacia abajo";
es["LANG_GESTURE_SCREENUP_TOOLTIP"] = "pantalla hacia arriba";
es["LANG_GESTURE_SCREENDOWN_TOOLTIP"] = "pantalla hacia abajo";
es["LANG_GESTURE_TILTLEFT_TOOLTIP"] = "inclinación hacia la izquierda";
es["LANG_GESTURE_TILTLEFT_TOOLTIP_2"] = "inclinar a la izquierda";
es["LANG_GESTURE_TILTRIGHT_TOOLTIP"] = "inclinación hacia la derecha";
es["LANG_GESTURE_TILTRIGHT_TOOLTIP_2"] = "inclinar a la derecha";
es["LANG_GESTURE_FREEFALL_TOOLTIP"] = "caída libre";
es["LANG_GESTURE_THREEG_TOOLTIP"] = "3g";
es["LANG_GESTURE_SIXG_TOOLTIP"] = "6g";
es["LANG_GESTURE_EIGHTG_TOOLTIP"] = "8g";
es["LANG_PIN_PRESSED"] = "Al presionarse el pin";
es["LANG_PIN_PRESSED_TOOLTIP"] = "Hacer algo cuando un pin se toca y se suelta de nuevo (tocando a la vez el pin GND)";
es["LANG_PIN_RELEASED"] = "Al liberarse el pin";
es["LANG_PIN_RELEASED_TOOLTIP"] = "Hacer algo cuando un pin se suelta";
es["LANG_BUTTON"] = "botón";
es["LANG_BUTTON_2"] = "Botón";
es["LANG_PIN"] = "en el pin";
es["LANG_IN"] = "en";
es["LANG_PRESSED"] = "presionado";
es["LANG_IS_PRESSED"] = "está presionado";
es["LANG_BUTTON_BOOLEAN_TOOLTIP"] = "Obtener el estado del botón (presionado o no) para 'A' y 'B'";
es["LANG_PIN_BOOLEAN"] = "pin";
es["LANG_PIN_BOOLEAN_TOOLTIP"] = "Obtener el estado del botón (presionado o no) para 'A' y 'B'";
es["LANG_ACCELERATION"] = "aceleración (mg)";
es["LANG_ACCELERATION_TOOLTIP"] = "Obtiene el valor de la aceleración en miligravedades (cuando el tablero está en posición horizontal con la pantalla hacia arriba: x = 0, y = 0, z = -1024)";
es["LANG_LIGHT_LEVEL"] = "nivel de luz";
es["LANG_LIGHT_LEVEL_TOOLTIP"] = "Lee el nivel de luz aplicado a la pantalla LED en un rango de '0' (oscuro) a '255' (brillante)";
es["LANG_COMPASS"] = "dirección de la brújula (°)";
es["LANG_COMPASS_TOOLTIP"] = "Obtener rumbo de la brújula en grados";
es["LANG_TEMPERATURE"] = "temperatura (°C)";
es["LANG_TEMPERATURE_2"] = "TEMPERATURA";
es["LANG_HUMIDITY"] = "HUMEDAD";
es["LANG_TEMPERATURE_TOOLTIP"] = "Obtiene la temperatura en grados Celsius (°C)";
es["LANG_ROTATION"] = "rotación (°)";
es["LANG_ROTATION_PITCH"] = "pitch";
es["LANG_ROTATION_ROLL"] = "roll";
es["LANG_ROTATION_TOOLTIP"] = "El tono o giro del dispositivo, rotación alrededor de 'eje X' o 'eje Y' en grados";
es["LANG_FORCE"] = "fuerza";
es["LANG_MAGNETIC_FORCE"] = "fuerza magnética (uT)";
es["LANG_MAGNETIC_FORCE_TOOLTIP"] = "Obtener el valor de la fuerza magnética en 'microteslas' (uT)";
es["LANG_RUNNING_TIME"] = "tiempo desde el arranque (ms)";
es["LANG_RUNNING_TIME_TOOLTIP"] = "Obtener el número de milisegundos (ms) transcurridos desde el encendido";
es["LANG_RUNNING_TIME_MICROS"] = "tiempo desde el arranque (us)";
es["LANG_RUNNING_TIME_MICROS_TOOLTIP"] = "Obtener el número de microsegundos (us) transcurridos desde el encendido";
es["LANG_ACCELEROMETER_RANGE"] = "ajustar rango de acelerómetro";
es["LANG_ACCELEROMETER_RANGE_TOOLTIP"] = "Establece el intervalo de muestra del acelerómetro en gravedades";
es["LANG_PLAY_TONE"] = "reproducir tono";
es["LANG_PLAY_TONE_TOOLTIP"] = "Reproduce un tono a través del pin 'P0' durante el tiempo indicado";
es["LANG_RINGTONE_BY"] = "con duración de";
es["LANG_RINGTONE"] = "Reproducir nota";
es["LANG_RINGTONE_TOOLTIP"] = "Reproduce un tono a través del pin 'P0'";
es["LANG_PLAY_REST"] = "Silencio (ms)";
es["LANG_PLAY_REST_TOOLTIP"] = "Silencio (no ejecuta nada) durante un tiempo especificado a través del pin 'P0'";
es["LANG_PLAY_MELODY"] = "Comenzar melodía";
es["LANG_PLAY_MELODY_TOOLTIP"] = "Comenzar a reproducir una melodía. Las notas se expresan como una cadena de caracteres con este formato: NOTA[octava][:duración]";
es["LANG_REPEATING"] = "repitiendo";
es["LANG_PLAY_MELODY_DADADADUM"] = "dadadadum";
es["LANG_PLAY_MELODY_ENTERTAINER"] = "animador";
es["LANG_PLAY_MELODY_PRELUDE"] = "preludio";
es["LANG_PLAY_MELODY_ODE"] = "oda";
es["LANG_PLAY_MELODY_NYAN"] = "nyan";
es["LANG_PLAY_MELODY_RINGTONE"] = "tono de llamada";
es["LANG_PLAY_MELODY_FUNK"] = "funk";
es["LANG_PLAY_MELODY_BLUES"] = "blues";
es["LANG_PLAY_MELODY_BIRTHDAY"] = "cumpleaños";
es["LANG_PLAY_MELODY_WEDDING"] = "boda";
es["LANG_PLAY_MELODY_FUNERAL"] = "fúnebre";
es["LANG_PLAY_MELODY_PUNCHLINE"] = "punchline";
es["LANG_PLAY_MELODY_BADDY"] = "baddy";
es["LANG_PLAY_MELODY_CHASE"] = "perseguir";
es["LANG_PLAY_MELODY_BADING"] = "ba ding";
es["LANG_PLAY_MELODY_WAWAWAWAA"] = "wawawawaa";
es["LANG_PLAY_MELODY_JUMPUP"] = "jump up";
es["LANG_PLAY_MELODY_JUMPDOWN"] = "jump down";
es["LANG_PLAY_MELODY_POWERUP"] = "encender";
es["LANG_PLAY_MELODY_POWERDOWN"] = "apagar";
es["LANG_PLAY_MELODY_ONCE"] = "una vez";
es["LANG_PLAY_MELODY_FOREVER"] = "para siempre";
es["LANG_PLAY_MELODY_ONCEBACKGROUND"] = "una vez en segundo plano";
es["LANG_PLAY_MELODY_FOREVERBACKGROUND"] = "siempre en segundo plano";
es["LANG_PLAY_MELODY_UNTILDONE"] = "hasta el final";
es["LANG_PLAY_MELODY_INBACKGROUND"] = "en segundo plano";
es["LANG_MUSIC_EVENT"] = "Música en";
es["LANG_MUSIC_EVENT_TOOLTIP"] = "Registra el código a ejecutar en varios eventos de melodía";
es["LANG_MUSIC_EVENT_MELODYNOTEPLAYED"] = "nota de melodía reproducida";
es["LANG_MUSIC_EVENT_MELODYSTARTED"] = "melodía comenzada";
es["LANG_MUSIC_EVENT_MELODYENDED"] = "melodía finalizada";
es["LANG_MUSIC_EVENT_MELODYREPEATED"] = "melodía repetida";
es["LANG_MUSIC_EVENT_BACKGROUNDMELODYNOTEPLAYED"] = "nota de melodía de fondo reproducida";
es["LANG_MUSIC_EVENT_BACKGROUNDMELODYSTARTED"] = "melodía de fondo iniciada";
es["LANG_MUSIC_EVENT_BACKGROUNDMELODYENDED"] = "melodía de fondo terminada";
es["LANG_MUSIC_EVENT_BACKGROUNDMELODYREPEATED"] = "melodía de fondo repetida";
es["LANG_MUSIC_EVENT_BACKGROUNDMELODYPAUSED"] = "melodía de fondo en pausa";
es["LANG_MUSIC_EVENT_BACKGROUNDMELODYRESUMED"] = "melodía de fondo reiniciada";
es["LANG_STOP_MELODY"] = "Detener melodía";
es["LANG_STOP_MELODY_TOOLTIP"] = "Detener las melodías";
es["LANG_STOP_MELODY_ALL"] = "todas";
es["LANG_STOP_MELODY_FOREGROUND"] = "de primer plano";
es["LANG_STOP_MELODY_BACKGROUND"] = "de fondo";
es["LANG_TONES_TOOLTIP"] = "Obtener el valor de un tono";
es["LANG_TONES_C_LOW"] = "Do bajo";
es["LANG_TONES_CSHARP_LOW"] = "Do# bajo";
es["LANG_TONES_D_LOW"] = "Re bajo";
es["LANG_TONES_DSHARP_LOW"] = "Re# bajo";
es["LANG_BEATS_TOOLTIP"] = "Obtener el tiempo de un tono";
es["LANG_BEATS_BEAT_WHOLE"] = "1 pulso";
es["LANG_BEATS_BEAT_HALF"] = "1/2 pulso";
es["LANG_BEATS_BEAT_QUARTER"] = "1/4 pulso";
es["LANG_BEATS_BEAT_EIGHTH"] = "1/8 pulso";
es["LANG_BEATS_BEAT_SEXTEENTH"] = "1/16 pulso";
es["LANG_BEATS_BEAT_DOUBLE"] = "2 pulsos";
es["LANG_BEATS_BEAT_BREVE"] = "4 pulsos";
es["LANG_TEMPO"] = "tempo (bpm)";
es["LANG_TEMPO_TOOLTIP"] = "Devuelve el tempo en pulsaciones por minuto. El tempo es la velocidad (bpm = beats per minute) a la que suenan las notas. Cuanto mayor sea el valor del tempo, más rápido suenan las notas";
es["LANG_CHANGE_TEMPO"] = "Cambiar tempo en (bpm)";
es["LANG_CHANGE_TEMPO_TOOLTIP"] = "Cambiar el tempo en la cantidad especificada";
es["LANG_SET_TEMPO"] = "Ajustar tempo a (bpm)";
es["LANG_SET_TEMPO_TOOLTIP"] = "Establece el tempo en una cantidad especificada";
es["LANG_PLOT"] = "Graficar";
es["LANG_PLOT_TOOLTIP"] = "Enciende el LED especificado usando coordenadas x, y (x es horizontal, y es vertical). (0,0) es la esquina superior izquierda";
es["LANG_UNPLOT"] = "Ocultar";
es["LANG_UNPLOT_TOOLTIP"] = "Apaga el LED en las coordenadas x, y (x es horizontal, y es vertical). (0,0) es la esquina superior izquierda";
es["LANG_TOGGLE"] = "Invertir";
es["LANG_TOGGLE_TOOLTIP"] = "Apaga o enciende un píxel específico";
es["LANG_POINT"] = "punto";
es["LANG_POINT_TOOLTIP"] = "Obtener el estado de encendido/apagado de un LED específico usando coordenadas x, y. (0,0) es la esquina superior izquierda";
es["LANG_MATRIX8X8_PIN"] = "en el pin";
es["LANG_MATRIX8X8_ROTATE"] = "Rotar a la posición";
es["LANG_MATRIX8X8_ROTATE_MATRIX"] = "la matriz 8x8";
es["LANG_MATRIX8X8_ROTATE_TOOLTIP"] = "Cambia la orientación de la pantalla";
es["LANG_MATRIX8X8_CLEAR"] = "Borrar la matriz 8x8";
es["LANG_MATRIX8X8_CLEAR_TOOLTIP"] = "Borra todo lo dibujado en la pantalla";
es["LANG_MATRIX8X8_INVERT"] = "Invertir colores de la matriz 8x8";
es["LANG_MATRIX8X8_INVERT_TOOLTIP"] = "Invierte los colores de frente y fondo";
es["LANG_MATRIX8X8_DRAW"] = "en la matriz 8x8";
es["LANG_MATRIX8X8_DRAW_DRAW"] = "Dibujar";
es["LANG_MATRIX8X8_DRAW_ERASE"] = "Borrar";
es["LANG_MATRIX8X8_DRAW_TOOLTIP"] = "Dibuja una línea en la matriz 8x8";
es["LANG_PLOT_BARGRAPH"] = "Gráfico de barras";
es["LANG_PLOT_BARGRAPH_TOOLTIP"] = "Muestra un gráfico de barras vertical basado en los valores 'valor' y 'alto'. Si 'alto' es 0, el gráfico se ajusta automáticamente";
es["LANG_PLOT_BARGRAPH_OF"] = "de";
es["LANG_PLOT_BARGRAPH_UPTO"] = "hasta";
es["LANG_PLOT_BRIGHTNESS"] = "Graficar";
es["LANG_PLOT_BRIGHTNESS_TOOLTIP"] = "Enciende el LED especificado usando coordenadas x, y (x es horizontal, y es vertical) con el brillo especificado. (0,0) es la esquina superior izquierda";
es["LANG_PLOT_BRIGHTNESS_WARNING"] = "Debes ingresar un número entre 0 y 255";
es["LANG_BRIGHTNESS"] = "brillo";
es["LANG_DIGITAL_READ"] = "Leer el pin digital";
es["LANG_DIGITAL_READ_TOOLTIP"] = "Leer el pin o conector especificado como 0 o 1";
es["LANG_ANALOG_READ"] = "Leer el pin analógico";
es["LANG_ANALOG_READ_TOOLTIP"] = "Leer el valor del conector como analógico, es decir, como un valor entre 0 y 1023";
es["LANG_BUTTON_READ"] = "BOTÓN";
es["LANG_BUTTON_READ_TOOLTIP"] = "leer el estado del pulsador como 0 o 1";
es["LANG_TOUCH_READ"] = "TÁCTIL";
es["LANG_TOUCH_READ_TOOLTIP"] = "leer el estado del botón táctil como 0 o 1";
es["LANG_LIMIT_SWITCH_READ"] = "CHOQUE";
es["LANG_LIMIT_SWITCH_READ_TOOLTIP"] = "leer el estado del sensor de choque como 0 o 1";
es["LANG_POTENTIOMETER_READ"] = "POTENCIÓMETRO";
es["LANG_POTENTIOMETER_READ_TOOLTIP"] = "leer el estado del potenciómetro como un valor entre 0 y 1023";
es["LANG_TEMPERATURE_READ"] = "TEMPERATURA";
es["LANG_TEMPERATURE_READ_TOOLTIP"] = "leer el estado del sensor de temperatura como un valor entre 0 y 1023";
es["LANG_LCD"] = "LCD";
es["LANG_LCD_INIT"] = "LCD inicializar";
es["LANG_LCD_ADDRESS"] = "del LCD";
es["LANG_LCD_ADDRESS_2"] = "en la dirección";
es["LANG_LCD_INIT_TOOLTIP"] = "indica la dirección I2C de la pantalla LCD. El valor '0' significa 'automatico'";
es["LANG_LCD_SHOW"] = "LCD: mostrar texto";
es["LANG_LCD_SHOW_TOOLTIP"] = "muestra el texto ingresado en la posición especificada de la pantalla LCD";
es["LANG_LCD_I2C"] = "Escribir";
es["LANG_LCD_COLUMN"] = "y columna";
es["LANG_LCD_ROW"] = "en la fila";
es["LANG_LCD_SETBACKLIGHT"] = "la iluminación del LCD";
es["LANG_LCD_SETBACKLIGHT_TOOLTIP"] = "Apaga o Enciende la iluminación del LCD";
es["LANG_MOTOR_TURN_RIGHT"] = "Girar a la derecha";
es["LANG_MOTOR_TURN_LEFT"] = "Girar a la izquierda";
es["LANG_MOTOR_TURN_BRAKE"] = "Frenar";
es["LANG_MOTOR_SINGLE_TOOLTIP"] = "Controla motores DC individualmente";
es["LANG_MOTOR_SINGLE_SPEED"] = "con velocidad";
es["LANG_MOTOR_SINGLE_PIN"] = "el MOTOR en el pin";
es["LANG_MOTOR_DOUBLE_FORWARD"] = "Mover hacia adelante";
es["LANG_MOTOR_DOUBLE_BACKWARD"] = "Mover hacia atrás";
es["LANG_MOTOR_DOUBLE_TURN_LEFT"] = "Girar a la izquierda";
es["LANG_MOTOR_DOUBLE_TURN_RIGHT"] = "Girar a la derecha";
es["LANG_MOTOR_DOUBLE_BRAKE"] = "Frenar";
es["LANG_MOTOR_DOUBLE_FORWARD_SHORT"] = "Avanzar";
es["LANG_MOTOR_DOUBLE_BACKWARD_SHORT"] = "Retroceder";
es["LANG_MOTOR_DOUBLE_TURN_LEFT_SHORT"] = "Girar a la izquierda";
es["LANG_MOTOR_DOUBLE_TURN_RIGHT_SHORT"] = "Girar a la derecha";
es["LANG_MOTOR_DOUBLE_BRAKE_SHORT"] = "Frenar";
es["LANG_MOTOR_DOUBLE_FORWARD_MICROBIT"] = "Avanzar";
es["LANG_MOTOR_DOUBLE_BACKWARD_MICROBIT"] = "Retroceder";
es["LANG_MOTOR_DOUBLE_TURN_LEFT_MICROBIT"] = "Girar a la izquierda";
es["LANG_MOTOR_DOUBLE_TURN_RIGHT_MICROBIT"] = "Girar a la derecha";
es["LANG_MOTOR_DOUBLE_BRAKE_MICROBIT"] = "Frenar";
es["LANG_MOTOR_DOUBLE_TOOLTIP"] = "Controla motores DC";
es["LANG_SERVO_180"] = "el SERVO";
es["LANG_SERVO_180_TOOLTIP"] = "hace girar el servo la cantidad definida de grados";
es["LANG_SERVO_180_DEGREES"] = "Posicionar en el grado";
es["LANG_TILT"] = "INCLINACIÓN";
es["LANG_TILT_TOOLTIP"] = "devuelve el valor digital medido por el sensor de inclinación";
es["LANG_VIBRATION"] = "VIBRACIÓN";
es["LANG_VIBRATION_TOOLTIP"] = "devuelve el valor digital medido por el sensor de vibración";
es["LANG_HALL_EFFECT"] = "MAGNETISMO";
es["LANG_HALL_EFFECT_2"] = "Fuerza magnética";
es["LANG_HALL_EFFECT_TOOLTIP"] = "devuelve el valor digital medido por el sensor de campo magnético";
es["LANG_PIR"] = "PIR";
es["LANG_PIR_TOOLTIP"] = "devuelve el valor digital medido por el sensor PIR";
es["LANG_IR_SWITCH"] = "FOTOINTERRUPTOR";
es["LANG_IR_SWITCH_TOOLTIP"] = "devuelve el valor digital medido por el sensor fotointerruptor";
es["LANG_LDR_READ"] = "LUZ";
es["LANG_LDR_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de luz (fotorresistencia)";
es["LANG_SOIL_READ"] = "SUELO";
es["LANG_SOIL_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de humedad";
es["LANG_SOUND_READ"] = "SONIDO";
es["LANG_SOUND_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de sonido";
es["LANG_FIRE_READ"] = "FUEGO";
es["LANG_FIRE_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de fuego";
es["LANG_ALCOHOL_READ"] = "ALCOHOL";
es["LANG_ALCOHOL_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de alcohol";
es["LANG_GAS_READ"] = "GAS";
es["LANG_GAS_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de gas";
es["LANG_SOIL_READ"] = "SUELO";
es["LANG_SOIL_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de humedad de suelo";
es["LANG_STEAM_READ"] = "VAPOR";
es["LANG_STEAM_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de vapor";
es["LANG_PRESSURE_READ"] = "PRESIÓN";
es["LANG_PRESSURE_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de presión";
es["LANG_RAIN_READ"] = "LLUVIA";
es["LANG_RAIN_READ_TOOLTIP"] = "devuelve el valor analógico medido por el sensor de lluvia";
es["LANG_WRITE_DIGITAL"] = "Escribir en pin digital";
es["LANG_WRITE_DIGITAL_STATE"] = "estado";
es["LANG_WRITE_DIGITAL_TOOLTIP"] = "cambia el estado (encendido/apagado) del pin digital especificado";
es["LANG_COLOUR"] = "Color";
es["LANG_COLOUR_I2C"] = "COLOR";
es["LANG_COLOUR_I2C_PIN"] = "en el pin";
es["LANG_COLOUR_I2C_TOOLTIP"] = "Obtiene el valor de color del LED rgb";
es["LANG_LEDRGB"] = "LED RGB";
es["LANG_LEDRGB_RED"] = "rojo";
es["LANG_LEDRGB_GREEN"] = "verde";
es["LANG_LEDRGB_BLUE"] = "azul";
es["LANG_LEDRGB_TOOLTIP"] = "enciende el led RGB con el color especificado";
es["LANG_ENTRADAS_ULTRASONIDO_TRIGGER"] = "trigger";
es["LANG_ENTRADAS_ULTRASONIDO_ECHO"] = "echo";
es["LANG_ENTRADAS_ULTRASONIDO_UNIT"] = ", en";
es["LANG_ENTRADAS_ULTRASONIDO_UNIT_CM"] = "centímetros";
es["LANG_ENTRADAS_ULTRASONIDO_UNIT_IN"] = "pulgadas";
es["LANG_LED"] = "el LED";
es["LANG_LED_TOOLTIP"] = "Controla el estado del LED";
es["LANG_LED_BRILLO"] = "del LED";
es["LANG_LED_BRILLO_INTENSIDAD"] = "Ajustar el brillo a";
es["LANG_LED_BRILLO_TOOLTIP"] = "Controla el brillo del LED";
es["LANG_RELAY"] = "el RELÉ";
es["LANG_RELAY_TOOLTIP"] = "Controla el estado del relé";
es["LANG_CHONOMETER"] = "Iniciar cronómetro";
es["LANG_CHONOMETER_TOOLTIP"] = "Inicia un cronómetro";
es["LANG_COMPARE_STRINGS_TOOLTIP"] = "Compara dos cadenas de texto";
es["LANG_BUTTON_BOTH"] = "Ambos";
es["LANG_LOGO_PRESSED"] = "LOGO está presionado";
es["LANG_ENTRADAS_LINE_FOLLOWER_3"] = "Seguidor de líneas";
es["LANG_MICROBIT_LIGHT_LEVEL"] = "NIVEL DE LUZ en el sensor de microbit";
es["LANG_MICROBIT_LIGHT_LEVEL_TOOLTIP"] = "Obtiene el nivel de luz en el sensor de microbit";
es["LANG_HALL_EFFECT_3"] = "Fuerza magnética en el pin";
es["LANG_MICROBIT_TEMPERATURE"] = "Temperatura en el pin";
es["LANG_MICROBIT_TEMPERATURE_TOOLTIP"] = "Obtiene la temperatura en el sensor de microbit";
es["LANG_MICROBIT_ACCELERATION"] = "Aceleración en el eje";
es["LANG_MICROBIT_ACCELERATION_TOOLTIP"] = "devuelve el valor digital medido por el sensor de aceleración";
es["LANG_MICROBIT_SOUND"] = "Nivel de sonido en el sensor microbit";
es["LANG_MICROBIT_SOUND_TOOLTIP"] = "Obtiene el nivel de sonido en el sensor de microbit";
es["LANG_PLAY_MELODY_2"] = "Comenzar melodía";
es["LANG_MATRIX_8X8"] = "Matriz 8x8";
es["LANG_BLUETOOTH_READ_TOOLTIP"] = "";
es["LANG_WITH_LABEL"] = "con etiqueta";
es["LANG_NUMBER"] = "Número";
es["LANG_STRING"] = "Texto";
es["LANG_BLUETOOTH_SEND_MESSAGE"] = "Enviar por bluetooth el mensaje";
es["LANG_BLUETOOTH_SEND_MESSAGE_TOOLTIP"] = "";
es["LANG_BLUETOOTH_IF"] = "Si se recibe un mensaje por bluetooth, leer";
es["LANG_BLUETOOTH_IF_TOOLTIP"] = "";
es["LANG_BLUETOOTH_CONTROL"] = "Control del Robot         ";
es["LANG_BLUETOOTH_CONTROL_JOYSTICK"] = "Joystick    ";
es["LANG_BLUETOOTH_CONTROL_BUTTON"] = "Botón        ";
es["LANG_BLUETOOTH_CONTROL_TOOLTIP"] = "";

///////////////////////////////////////////////////////
////////////////  JUNIOR  /////////////////////////////
///////////////////////////////////////////////////////
es['TOOLTIP_NEW_EVENT_REPEAT'] = "Repite el código indefinidamente";
es['TOOLTIP_NEW_EVENT_START'] = "Ejecuta el código cuando el programa inicia";
es['TOOLTIP_PAUSE_MILLIS'] = "Pausa la ejecución durante el tiempo especificado en milisegundos";
es['TOOLTIP_PAUSE_SECONDS'] = "Pausa la ejecución durante el tiempo especificado en segundos";
es['TOOLTIP_SHOW_STRING'] = "Muestra texto en la pantalla. Si el texto no encaja, se desplazará automáticamente";
es['TOOLTIP_SHOW_NUMBER'] = "Muestra números en la pantalla. Si el número no encaja, se desplazará automáticamente";
es['TOOLTIP_ICON'] = "Dibuja el icono seleccionado en la pantalla de LED";
es['TOOLTIP_ICON_HEART'] = "Corazón";
es['TOOLTIP_ICON_HEART_SMALL'] = "Corazón pequeño";
es['TOOLTIP_ICON_YES'] = "Sí";
es['TOOLTIP_ICON_NO'] = "No";
es['TOOLTIP_ICON_HAPPY_FACE'] = "Felíz";
es['TOOLTIP_ICON_SAD_FACE'] = "Triste";
es['TOOLTIP_ICON_CONFUSED'] = "Confundido";
es['TOOLTIP_ICON_ANGRY'] = "Enojado";
es['TOOLTIP_ICON_ASLEEP'] = "Dormido";
es['TOOLTIP_ICON_SURPRISED'] = "Sorprendido";
es['TOOLTIP_ICON_SILLY'] = "Gracioso";
es['TOOLTIP_ICON_FABULOUS'] = "Fabuloso";
es['TOOLTIP_ICON_MEH'] = "Meh";
es['TOOLTIP_ICON_TSHIRT'] = "Camiseta";
es['TOOLTIP_ICON_ROLLERSKATE'] = "Patines";
es['TOOLTIP_BUTTON_A_PRESSED'] = "Hacer algo cuando un botón (A, B o ambos A+B) se presiona y se suelta de nuevo";
es['TOOLTIP_BUTTON_A'] = "Botón A";
es['TOOLTIP_BUTTON_B'] = "Botón B";
es['TOOLTIP_BUTTON_AB'] = "Botón A+B";
es['TOOLTIP_PAUSE_MILLIS_1'] = "1 segundo";
es['TOOLTIP_PAUSE_MILLIS_2'] = "2 segundos";
es['TOOLTIP_PAUSE_MILLIS_3'] = "3 segundos";
es['TOOLTIP_PAUSE_MILLIS_4'] = "4 segundos";
es['TOOLTIP_PAUSE_MILLIS_5'] = "5 segundos";
es['TOOLTIP_PAUSE_MILLIS_6'] = "6 segundos";
es['TOOLTIP_PAUSE_MILLIS_7'] = "7 segundos";
es['TOOLTIP_PAUSE_MILLIS_8'] = "8 segundos";
es['TOOLTIP_PAUSE_MILLIS_9'] = "9 segundos";
es['TOOLTIP_CONTROL_LOOP_FOREVER'] = "Repetir la secuencia indefinidamente";
es['TOOLTIP_CONTROL_LOOP_NUMBER'] = "Repetir una secuencia la cantidad de veces indicada";
es['TOOLTIP_CONTROL_LOOP_NUMBER_1'] = "1 vez";
es['TOOLTIP_CONTROL_LOOP_NUMBER_3'] = "2 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_4'] = "3 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_2'] = "4 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_5'] = "5 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_6'] = "6 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_7'] = "7 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_8'] = "8 veces";
es['TOOLTIP_CONTROL_LOOP_NUMBER_9'] = "9 veces";
es['TOOLTIP_BHOOT_MOTOR_FORWARD'] = "Avanzar el robot durante la cantidad de segundos ingresada";
es['TOOLTIP_BHOOT_MOTOR_BACKWARD'] = "Retrocede el robot durante la cantidad de segundos ingresada";
es['TOOLTIP_BHOOT_MOTOR_RIGHT'] = "Gira el robot hacia la derecha durante la cantidad de segundos ingresada";
es['TOOLTIP_BHOOT_MOTOR_LEFT'] = "Gira el robot hacia la izquierda durante la cantidad de segundos ingresada";
es['TOOLTIP_BHOOT_LINE_FOLLOWER'] = "Mueve el robot siguiendo una línea dibujada en el piso";
es['TOOLTIP_BHOOT_REPEAT_FOREVER'] = "Repite el código indefinidamente";
es['TOOLTIP_OBSTACLE_AVOIDANCE'] = "Mueve el robot evitando los obstáculos encontrados en el camino";
es['TOOLTIP_OBSTACLE_AVOIDANCE_NUMBER_5'] = "5 centímetros";
es['TOOLTIP_OBSTACLE_AVOIDANCE_NUMBER_10'] = "10 centímetros";
es['TOOLTIP_OBSTACLE_AVOIDANCE_NUMBER_20'] = "20 centímetros";
es['TOOLTIP_PLAY_TONE'] = "Toca una nota en el zumbador";
es['TOOLTIP_PLAY_TONE_DO'] = "Do";
es['TOOLTIP_PLAY_TONE_RE'] = "Re";
es['TOOLTIP_PLAY_TONE_MI'] = "Mi";
es['TOOLTIP_PLAY_TONE_FA'] = "Fa";
es['TOOLTIP_PLAY_TONE_SOL'] = "Sol";
es['TOOLTIP_PLAY_TONE_LA'] = "La";
es['TOOLTIP_PLAY_TONE_SI'] = "Si";
es['TOOLTIP_LED'] = "Enciende las luces del robot con el color seleccionado";
es['TOOLTIP_LED_OFF'] = "Apaga las luces del robot";
es['TOOLTIP_LED_COLOUR_1'] = "Amarillo";
es['TOOLTIP_LED_COLOUR_2'] = "Naranja";
es['TOOLTIP_LED_COLOUR_3'] = "Rosa";
es['TOOLTIP_LED_COLOUR_4'] = "Rojo";
es['TOOLTIP_LED_COLOUR_5'] = "Blanco";
es['TOOLTIP_LED_COLOUR_6'] = "Verde";
es['TOOLTIP_LED_COLOUR_7'] = "Celeste";
es['TOOLTIP_LED_COLOUR_8'] = "Azul";
es['TOOLTIP_LED_COLOUR_9'] = "Violeta";
es['TOOLTIP_MATRIX_8X8'] = "Dibuja una cara en la matriz de LEDs";
es['TOOLTIP_MATRIX_8X8_OFF'] = "Apaga la matriz de LEDs";
es['TOOLTIP_MATRIX_8X8_FACE_1'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_2'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_3'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_4'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_5'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_6'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_7'] = "";
es['TOOLTIP_MATRIX_8X8_FACE_8'] = "";
es["LANG_PIN_NOT_AVAILABLE"] = "No disponible";