/* eslint no-loop-func: 0 */
import * as Blockly from 'blockly';


export class PinsDB {
  workspace = null;

  board = null;

  /**
   * {
   *   {PIN_NUMBER}: {
   *     {BLOCK_COMPONENT}_{PIN_NAME}: [{BLOCK_ID}, ...]
   *   },
   * }
   */
  pins = {};

  constructor(workspace, board) {
    this.workspace = workspace;
    this.board = board;
    this.workspace.addChangeListener(this.listenToPinEvents);
    this.workspace.getHardwarePinsCombo = this.getHardwarePinsCombo;
  }

  getHardwarePinsCombo = (pinType, blockComponent = '') => {
    let pins = [];
    if (pinType !== '') {
      pins = this.board.pins[pinType] || [[Blockly.Msg.LANG_PIN_NOT_AVAILABLE, `${Blockly.Msg.LANG_PIN_NOT_AVAILABLE},,`]];
    }

    const newPins = [];
    for (let i = 0; i < pins.length; i += 1) {
      const singlePin = pins[i][1].split(',');
      let occupied = false;
      singlePin.map((pin) => {
        if (blockComponent !== '' && this.pinUsed(pin, blockComponent)) {
          occupied = true;
        }
        return true;
      });
      if (!occupied) {
        newPins.push(pins[i]);
      }
    }
    // It can't be an empty array
    if (newPins.length <= 0) {
      newPins.push([Blockly.Msg.LANG_PIN_NOT_AVAILABLE, `${Blockly.Msg.LANG_PIN_NOT_AVAILABLE},,`]);
    }
    return newPins;
  };

  pinUsed = (pin, component) => {
    if (Object.prototype.hasOwnProperty.call(this.pins, pin) || component === '') {
      if (Object.prototype.hasOwnProperty.call(this.pins[pin], component)) {
        return false;
      }
      return true;
    }
    return false;
  };

  addPin = (pin, component, blockId) => {
    if (!this.pins[pin]) {
      this.pins[pin] = {};
      this.pins[pin][component] = [blockId];
    } else if (typeof this.pins[pin][component] !== 'undefined' && this.pins[pin][component].indexOf(blockId) === -1) {
      this.pins[pin][component].push(blockId);
    }
  };

  deletePin = (blockId) => {
    let deleted = false;
    Object.keys(this.pins).forEach(pin => {
      if (Object.prototype.hasOwnProperty.call(this.pins, pin)) {
        Object.keys(this.pins[pin]).forEach(component => {
          if (Object.prototype.hasOwnProperty.call(this.pins[pin], component)) {
            if (this.pins[pin][component].length > 1) {
              for (let i = 0; i < this.pins[pin][component].length; i += 1) {
                if (this.pins[pin][component][i] === blockId) {
                  this.pins[pin][component].splice(i, 1);
                  deleted = true;
                }
              }
            } else if (this.pins[pin][component][0] === blockId) {
              delete this.pins[pin];
              deleted = true;
            }
          }
        });
      }
    });
    return deleted;
  };

  addPinCheckingUsed = (blockId) => {
    const block = this.workspace.getBlockById(blockId);
    if (typeof block.getPins === 'function' && !block.visuallyDisabled) {
      const pinCombos = block.getPins();
      pinCombos.map((pinCombo) => {
        let occupied = false;
        // Inter-pin check. If a dropdown pin on the same Block is used, select the next one available.
        const selectedPinGroup = block.getFieldValue(pinCombo);
        const component = `${block.component}_${pinCombo}`;
        const selectedPins = selectedPinGroup.split(',');
        selectedPins.map((pin) => {
          if (this.pinUsed(pin, component)) {
            occupied = true;
          }
          return true;
        });
        let j = 0;
        while (occupied) {
          if (typeof block.getField(pinCombo).getOptions()[j] !== 'undefined') {
            const nextOption = block.getField(pinCombo).getOptions()[j];
            block.getField(pinCombo).setValue(nextOption[1]);

            occupied = false;
            selectedPins.map((pin) => {
              if (this.pinUsed(pin, component)) {
                occupied = true;
              }
              return true;
            });
            j += 1;
          } else {
            occupied = false;
          }
        }

        selectedPins.map((pin) => {
          this.addPin(
            pin,
            component,
            blockId,
          );
          return true;
        });
        return true;
      });
    }
  };

  listenToPinEvents = (event) => {
    /// CREATE EVENT ////////////////////////////////
    if (event.type === Blockly.Events.BLOCK_CREATE) {
      let blockIds = [];
      blockIds.push(event.blockId);
      if (typeof event.ids !== 'undefined') {
        blockIds = event.ids;
      }
      blockIds.map((blockId) => {
        this.addPinCheckingUsed(blockId);
        return true;
      });
      this.workspace.markFocused();
    }

    /// CHANGE EVENT ////////////////////////////////
    if (event.type === Blockly.Events.BLOCK_CHANGE
      && typeof this.workspace.getBlockById(event.blockId).getPins === 'function') {
      this.deletePin(event.blockId);
      this.addPinCheckingUsed(event.blockId);
      this.workspace.markFocused();
    }

    /// DELETE EVENT ////////////////////////////////
    if (event.type === Blockly.Events.BLOCK_DELETE) {
      let deleted = false;
      for (let i = 0; i < event.ids.length; i += 1) {
        deleted = this.deletePin(event.ids[i]);
      }
      if (deleted) {
        this.workspace.markFocused();
      }
    }
  };
}
