import React, { Component } from 'react';
import { LedRGBStripElement } from '../components/ledRGBStrip/ledRGBStrip';
import { rotatePort, cpuNanos } from '../utils';
import { WS2812Controller } from '../libs/ws2812';

export class LedRGBStrip extends Component {
  constructor(hardware, pin, id = 'LedRGBStrip', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;
    this.pinState = [false, false, false, false, false, false];
    this.pinColor = ['', '', '', '', '', ''];
    this.pinValue = ['', '', '', '', '', ''];
    this.newPixelController = new WS2812Controller(this.pinState.length);

    this.variant = variant || {};
    this.pinInfo = [
      { name: 'A', x: 77, y: 69, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 155,
      height: 69,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.redrawNeoPixelLeds = this.redrawNeoPixelLeds.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    value: this.pinState,
    color: this.pinValue,
  });

  redrawNeoPixelLeds = (cpuNanoseconds) => {
    const pixels = new Uint32Array(this.newPixelController.update(cpuNanoseconds));
    if (pixels) {
      this.pinState.map((_, index) => {
        const value = pixels[index];
        if (typeof value !== 'undefined') {
          if (value) {
            this.pinState[index] = true;
            this.pinColor[index] = this.newPixelController.getRGBValue(value);
            this.pinValue[index] = this.newPixelController.getRGBPlainValue(value);
          } else {
            this.pinState[index] = false;
            this.pinValue[index] = '';
          }
        }
        return true;
      });
    }
  }

  update = (pinState) => {
    if (this.hardware) {
      const cpuNanoseconds = cpuNanos(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      this.newPixelController.feedValue(pinState, cpuNanoseconds);
      this.redrawNeoPixelLeds(cpuNanoseconds);
    }
  }

  reset = () => {
    this.pinState = [false, false, false, false, false, false];
    this.pinColor = ['', '', '', '', '', ''];
    this.pinValue = ['', '', '', '', '', ''];
  }

  render = () => (
    <LedRGBStripElement
      ref={this.ref}
      id={this.id}
      key={`ledRGBStrip-${this.pin}`}
      rotation={this.rotation}
      color={this.pinColor}
      value={this.pinState}
    />
  );
}
