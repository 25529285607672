/* eslint max-classes-per-file: 0 */
/* eslint no-restricted-syntax: 0 */
import {
  avrInstruction,
  AVRTimer,
  CPU,
  timer0Config,
  timer1Config,
  timer2Config,
  AVRIOPort,
  AVRUSART,
  portBConfig,
  portCConfig,
  portDConfig,
  usart0Config,
  AVRADC,
  adcConfig,
  AVRTWI,
  twiConfig,
} from 'avr8js';
import { parse } from 'intel-hex';

// ATmega328p params
const FLASH = 0x8000;


/**
 *  MicroTaskScheduler
*/
export class MicroTaskScheduler {
  channel = new MessageChannel();

  executionQueue = [];

  stopped = true;

  start() {
    if (this.stopped) {
      this.stopped = false;
      this.channel.port2.onmessage = this.handleMessage;
    }
  }

  stop() {
    this.stopped = true;
    this.executionQueue.splice(0, this.executionQueue.length);
    this.channel.port2.onmessage = null;
  }

  postTask(fn) {
    if (!this.stopped) {
      this.executionQueue.push(fn);
      this.channel.port1.postMessage(null);
    }
  }

  handleMessage = () => {
    const executeJob = this.executionQueue.shift();
    if (executeJob !== undefined) {
      executeJob();
    }
  };
}


/**
 *  AVRRunner
*/

export class AVRRunner {
  cpu = null;

  cpuEvents = null;

  cpuEventsMicrosecond = null;

  cpuTimeMS = null;

  cpuTimeMicroS = null;

  timer0 = null;

  timer1 = null;

  timer2 = null;

  portB = null;

  portC = null;

  portD = null;

  usart = null;

  adc = null;

  speed = 16e6; // 16 MHZ

  workUnitCycles = 500000;

  taskScheduler = new MicroTaskScheduler();

  constructor(hex = '') {
    const { data } = parse(atob(hex));
    const progData = new Uint8Array(data);
    this.cpu = new CPU(new Uint16Array(progData.buffer));
    this.cpuEvents = [];
    this.cpuEventsMicrosecond = [];
    this.cpuTimeMS = 0;
    this.cpuTimeMicroS = 0;

    this.timer0 = new AVRTimer(this.cpu, timer0Config);
    this.timer1 = new AVRTimer(this.cpu, timer1Config);
    this.timer2 = new AVRTimer(this.cpu, timer2Config);
    this.portB = new AVRIOPort(this.cpu, portBConfig);
    this.portC = new AVRIOPort(this.cpu, portCConfig);
    this.portD = new AVRIOPort(this.cpu, portDConfig);
    this.usart = new AVRUSART(this.cpu, usart0Config, this.speed);
    this.adc = new AVRADC(this.cpu, adcConfig);
    this.twi = new AVRTWI(this.cpu, twiConfig, this.speed);

    this.taskScheduler.start();
  }

  // CPU main loop
  execute(callback) {
    const cyclesToRun = this.cpu.cycles + this.workUnitCycles;
    while (this.cpu.cycles < cyclesToRun) {
      avrInstruction(this.cpu);
      this.cpu.tick();

      if (Math.floor((this.cpu.cycles * 1000) / this.speed) !== this.cpuTimeMS) {
        this.cpuTimeMS = Math.floor((this.cpu.cycles * 1000) / this.speed);
        for (const event of this.cpuEvents) {
          if (Math.floor((this.cpu.cycles * 1000) / this.speed) % event.period === 0) {
            event.eventCall(this.cpu.cycles);
          }
        }
      }
      if (Math.floor((this.cpu.cycles * 1000000) / this.speed) !== this.cpuTimeMicroS) {
        this.cpuTimeMicroS = Math.floor((this.cpu.cycles * 1000000) / this.speed);
        for (const event of this.cpuEventsMicrosecond) {
          if (Math.floor((this.cpu.cycles * 1000000) / this.speed) % event.period === 0) {
            event.eventCall(this.cpu.cycles);
          }
        }
      }
    }

    callback(this.cpu);
    this.taskScheduler.postTask(() => this.execute(callback));
  }

  addCPUEvent(cpuEvent) {
    this.cpuEvents.push(cpuEvent);
  }

  addCPUEventMicrosecond(cpuEvent) {
    this.cpuEventsMicrosecond.push(cpuEvent);
  }

  stop() {
    this.taskScheduler.stop();
  }
}
