import React from 'react';
import TutorialBuildInstructions from '@sections/creabots/components/steps/TutorialBuildInstructions';

interface TutorialSliderProps {
  onNextStep: () => void,
  onPrevStep: () => void,
  currentStep: number,
  totalSteps: number,
  images: {text: string, src: string}[]
}

const TutorialSlider = ({
  onNextStep,
  onPrevStep,
  currentStep,
  totalSteps,
  images,
}: TutorialSliderProps) => {
  return (
    <TutorialBuildInstructions
      onNextStep={onNextStep}
      onPrevStep={onPrevStep}
      currentStep={currentStep}
      totalSteps={totalSteps}
      images={images}
    />
  );
};


export default TutorialSlider;