import { selectBoards } from '@modules/boards/features/boardsSlice';
import { useUserSeniority } from '@utils/hooks';
import { SUCCESS_STATUS } from '@utils/redux';
import { useSelector } from 'react-redux';
import { filtreBoardsByGroups, filterBoardsBySeniority } from './operations';

export const useBoards = () => useSelector(selectBoards);

export const useBoardsBySeniority = () => {
  const boards = useSelector(selectBoards);
  const type = useUserSeniority();
  return boards.status === SUCCESS_STATUS
    ? { ...boards, boards: filterBoardsBySeniority({ type, boards: boards.boards }) }
    : boards;
};

export const useBoardsByGroups = () => {
  const boards = useSelector(selectBoards);
  const type = useUserSeniority();
  return boards.status === SUCCESS_STATUS
    ? { ...boards, boards: filtreBoardsByGroups({ type, boards: boards.boards }) }
    : boards;
};
