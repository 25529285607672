import React from 'react'
import Blocks from './containers/blocks';


const BlocksWrapper = (props) => {
    return (
        <Blocks />
    );
}

export default BlocksWrapper;
