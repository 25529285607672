/* eslint-disable */
import * as Blockly from 'blockly';
import { HorizontalStatementInputMeasurable } from './statement_input';
import { HorizontalInputConnection } from './inline_input';


class horizontalBlockMainRow extends Blockly.blockRendering.Row {
  constructor(constants) {
    super(constants);
    this.type = Blockly.blockRendering.Types.getType('HORIZONTAL_BLOCK_MAIN_ROW');
  }


  measure() {
    this.width = 0;
    this.height = this.minHeight;
    let connectedBlockWidths = 0;
    let connectedBlockHeight = 0;
    for (let i = 0; i < this.elements.length; i++) {
      const elem = this.elements[i];
      // this.width += elem.width;
      this.height = Math.max(this.height, elem.height);
      if (Blockly.blockRendering.Types.isStatementInput(elem) && elem instanceof HorizontalStatementInputMeasurable) {
        this.height = Math.max(this.height, this.constants_.DUMMY_INPUT_MIN_HEIGHT + this.constants_.NOTCH_HEIGHT);
        connectedBlockWidths += elem.connectedBlockWidth;
        connectedBlockHeight = elem.connectedBlockHeight;
        // console.log('================measure isStatementInput', this.width, elem, this);
      } else if (
        Blockly.blockRendering.Types.isExternalInput(elem) &&
        elem instanceof Blockly.blockRendering.ExternalValueInput &&
        elem.connectedBlockWidth !== 0
      ) {
        // console.log('================measure isExternalInput', this.width, elem, this);
        connectedBlockWidths += elem.connectedBlockWidth - elem.connectionWidth;
        this.width += elem.width;
      } else {
        // this.width = Math.max(this.width, elem.width);
        this.width += elem.width;
        // console.log('================measure isSpacer', this.width, elem, this);
      }
    }
    // this.centerline = 60;
    this.connectedBlockHeight = connectedBlockHeight;
    this.connectedBlockWidths = connectedBlockWidths;
    this.widthWithConnectedBlocks = this.width + connectedBlockWidths;
    // console.log('================measure', this);
  }
}



class horizontalPreviousConnection extends Blockly.blockRendering.Connection {
  constructor(constants, connectionModel) {
    super(constants, connectionModel);
    this.type = Blockly.blockRendering.Types.HORIZONTAL_BLOCK_PREVIOUS;
    this.height = this.shape.height;
    this.width = this.shape.width;
  }
}



class horizontalNextConnection extends Blockly.blockRendering.Connection {
  constructor(constants, connectionModel) {
    super(constants, connectionModel);
    this.type = Blockly.blockRendering.Types.HORIZONTAL_BLOCK_NEXT;
    this.height = this.shape.height;
    this.width = this.shape.width;
  }
}



class InRowSpacer extends Blockly.blockRendering.Measurable {
  constructor(constants, width) {
    super(constants, width);
    this.type = Blockly.blockRendering.Types.SPACER | Blockly.blockRendering.Types.IN_ROW_SPACER;
    this.width = width;
    this.height = this.constants_.SPACER_DEFAULT_HEIGHT;
  }
}



export {
  horizontalBlockMainRow,
  horizontalPreviousConnection,
  horizontalNextConnection,
  InRowSpacer,
}
