import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Funciones" name="Funciones" iconclass="fx" expandedclass="fx-extended">
      <label text="Funciones" />

      <block type="funcion_ciudad_luces8"/>
      <block type="funcion_ciudad_luces9"/>
      <block type="funcion_ciudad_luces10"/>
      <block type="otra_funcion8"> </block>
      <block type="otra_funcion9"> </block>
      <block type="otra_funcion10"> </block>


    </category>
    <category id="Control" name="Control" iconclass="control" expandedclass="control-extended">
      <label text="Control" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="base_delay_tren"> </block>
      <block type="ciudad_tiempo"> </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="Lógica" name="Lógica" iconclass="logics" expandedclass="logics-extended">
      <label text="Lógica" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>

    <category id="Matemáticas" name="Matemáticas" iconclass="math" expandedclass="math-extended">
      <label text="Matemáticas" />
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_array" />
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_pwm">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_minmax" />
    </category>

    <category id="Variables" name="Variables" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="Variables" />
    </category>

    <category id="Salidas" name="Salidas" iconclass="outputs" expandedclass="outputs-extended">
      <label text="Salidas" />
      <block type="ARDUINOUNO_salidas_led_tren" />
      <block type="ARDUINOUNO_salidas_led_brillo">
        <value name="DATOIN">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzer_piano" />
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_ENTRADAS_piezo_buzzerno" />
    </category>
    <category name="Entradas" iconclass="inputsDigi" expandedclass="outputs-extended">
    <label text="Entradas" />
    <block type="ARDUINOUNO_SALIDAS_read_dht11_tren" />
    <block type="ARDUINOUNO_ENTRADAS_button_TREN" />
    </category>
    <category id="Pantallas" name="Pantallas" iconclass="screens" expandedclass="screens-extended">
      <label text="Pantallas" />
      <block type="ARDUINOUNO_lcd_educabot_tren">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="ARDUINOUNO_lcd_setBacklight" />
      <block type="ARDUINOUNO_lcd_clear" />
    </category>
    <category id="Motores" name="Motores" iconclass="motors" expandedclass="motors-extended">
      <label text="Motores" />
      <block type="ARDUINOUNO_SALIDAS_motor_tren_avanzar" />
      <block type="ARDUINOUNO_SALIDAS_motor_tren_frenar" />
      <block type="ARDUINOUNO_servo_move_tren">
        <value name="angulo">
          <shadow type="slider_angle_tren">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
    </category>

  </React.Fragment>
);
/* eslint-enable */
