/* eslint import/no-extraneous-dependencies: 0 */
import * as Blockly from 'blockly';


export default {

  buildModal(id, oldText) {
    return `
    <div id="widget-${id}" class="popup-modal-parent create-variable-modal-parent" style="position: fixed; top: 0; left: 0;">
      <div class="popup-modal-under direction-ltr">
        <div class="middle-container">
          <div class="white-back">
            <div class="input-custom">
              <input type="text" value="${oldText}" placeholder="${Blockly.Msg.IOS_VARIABLES_VARIABLE_NAME}" id="create-variable-field-${id}">
              <span id="error-div-${id}" class="c-red" style="display: none">${Blockly.Msg.VARIABLE_ALREADY_EXISTS_NONAME}</span>
            </div>
            <div class="new-buttons">
              <div id="btn-create-${id}" class="create-new disabled">${(oldText) ? Blockly.Msg.IOS_EDIT : Blockly.Msg.IOS_OK}</div>
              <div id="btn-cancel-${id}" class="desist-new">${Blockly.Msg.IOS_CANCEL}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  },

  js(workspace, oldText, callback) {
    const id = workspace.id;
    let value = '';

    Blockly.WidgetDiv.getDiv().innerHTML = this.buildModal(id, oldText);
    Blockly.WidgetDiv.show(workspace.getParentSvg());

    const input = document.getElementById(`create-variable-field-${id}`);
    const errorDiv = document.getElementById(`error-div-${id}`);
    const btnCreate = document.getElementById(`btn-create-${id}`);
    const btnCancel = document.getElementById(`btn-cancel-${id}`);

    input.addEventListener('keyup', (e) => {
      if (e.target.value.length > 0) {
        value = e.target.value;
        btnCreate.classList.remove('disabled');
      } else {
        btnCreate.classList.add('disabled');
      }
    });

    btnCreate.addEventListener('click', (e) => {
      let resp = null;
      if (callback) {
        resp = callback(value);
      }
      if (!resp.error) {
        Blockly.WidgetDiv.hide();
      } else {
        if (Blockly.Msg[resp.error]) {
          errorDiv.innerHTML = Blockly.Msg[resp.error];
        }
        errorDiv.style.display = 'block';
      }
    });

    btnCancel.addEventListener('click', (e) => {
      Blockly.WidgetDiv.hide();
    });
  },
};
