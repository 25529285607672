import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Blockly from '@modules/blocklyNew/containers/blockly';
import styles from './blocks.mod.scss';
import { boards as Boards, getAllBoards } from '@educabot/educablocks-boards';
import { useGetProject } from '@sections/blocks/features/hooks';
import { v1 as uuidv1 } from 'uuid';
import { getUserAgent } from '../../../helpers/browserAgent';
import Agent from '../../../helpers/agent';
import { store } from '../../../state/store';
import { build } from '@modules/blockly/features/blocklySlice';
import Bottom from '@sections/creabots/components/bottom';
import BlocklyBuild from '@modules/blockly/components/blocklyBuild';
import boardTypes from '@modules/boards/features/constants';


const Blocks = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [id, setId] = useState(0);
  const [profile, setProfile] = useState('aNewBlocklyTest');
  const [availableBoards, setAvailableBoards] = useState(getAllBoards({
    boards: Boards,
    disabled: false,
  }));
  const [board, setBoard] = useState(availableBoards[0]);
  const [tabInfo, setTabInfo] = useState({});
  const [blocklyId, setBlocklyId] = useState(uuidv1());
  const [projectId, setProjectId] = useState(null);
  const projectState = useGetProject(projectId || 0);
  const [socket, setSocket] = useState(null);
  const [userAgent, setUserAgent] = useState({});
  const [intervalPortListing, setIntervalPortListing] = useState(null);

  // console.log('=========projectState', projectId, tabInfo);
  // console.log('=========board, availableBoards', board, availableBoards);

  const changeBoard = (event) => {
    const selectedBoard = availableBoards.find((item) => item.id === parseInt(event.target.value, 10));
    setBoard(selectedBoard);
    setTabInfo({
      ...tabInfo,
      board: selectedBoard,
      boardName: selectedBoard.name || '',
      className: selectedBoard.class || '',
      boardType: selectedBoard.type || 'senior',
    });
  };

  const startSocket = () => {
    const newSocket = (board.profile !== 'codit' && board.profile !== 'drone')
      ? new Agent(store, board)
      : null

    if (newSocket?.agentType) {
      getUserAgent().then((ua) => {
        setUserAgent(ua);
        newSocket.setPlatform(ua.platform, 4000);
        // Hack to force redraw in order to update socket.agentType property
        // setTimeout(() => {
        //   setId(1);
        // }, 4000);
      });
    }

    setSocket(newSocket);
  }

  const setAgent = (type = 'serial') => {
    clearInterval(intervalPortListing);
    setIntervalPortListing(null);
    socket.forceAgent(type);
  }

  const buildBlockly = (params) => {
    dispatch(build(params));
  }

  useEffect(() => {
    setProjectId(parseInt(location
      .pathname
      .split('/')
      .filter(Boolean)[0]
      .split('-')
      .filter(Boolean)[1], 10) || 0);

    return () => {
      setProjectId(null);
      // setId(0);
      setBlocklyId(uuidv1());
      setSocket(null);
    };
  }, []);

  useEffect(() => {
    if (socket && !intervalPortListing) {
      const intervalPortListing = setInterval(() => {
        socket.findPorts();
      }, 1000);
      setIntervalPortListing(intervalPortListing);
    }

    return () => {
      clearInterval(intervalPortListing);
      setIntervalPortListing(null);
    }
  }, [socket?.agentType]);

  useEffect(() => {
    if (projectState?.dataGetOne?.id) {
      const project = projectState.dataGetOne;
      setTabInfo({
        tagsSelected: [],
        projectId: project.id || 0,
        id: blocklyId,
        name: project.title || '',
        description: project.description || '',
        userId: project.userId || 0,
        board: project.jsonBody?.board || {},
        port: project.jsonBody?.port || '',
        agentPorts: [],
        boardName: project.jsonBody?.board?.name || '',
        className: project.jsonBody?.board?.class || '',
        portOpen: false,
        active: true,
        disabled: false,
        withToolTip: false,
        withWorkspace: project.jsonBody?.withWorkspace || false,
        rightPanel: '',
        diagramImageUrl: project.imageUrl || '',

        boardType: project.jsonBody?.board?.type || 'senior',
        code: project.jsonBody?.code || '',
      });
    } else {
      if (board.name) {
        setTabInfo({
          board,
        });
      }
    }
    startSocket(true);
  }, [board, projectState?.dataGetOne?.id]);

  return (
    <div className={`App ${styles.blocks}`}>
      <div className={styles.menu}>
        {!projectId ? (
          <>
            <span>Menu: &nbsp;</span>
            <select onChange={changeBoard} defaultValue={board.id}>
              {availableBoards.map((item) => (
                <option key={`${item.id} - ${item.name}`} value={item.id}>{`${item.type}: ${item.groupName}${(item.groupName !== item.name) ? ` - ${item.name}` : ''} (${item.company})`}</option>
              ))}
            </select>
          </>
        ) : ('')}
      </div>
      <div className={styles.content}>
        {tabInfo.board ? (
          <>
            <Blockly
              tabInfo={tabInfo}
            />
            {socket ? (
              <Bottom
                buildingFunction={buildBlockly}
                board={tabInfo.board}
                socketAgent={socket}
              />
            ) : ('')}
            <BlocklyBuild
              board={tabInfo.board}
              socketAgent={socket}
              id={tabInfo?.id || ''}
              compilerBoard={tabInfo?.board?.compilerBoard || ''}
              userAgent={userAgent}
              // userData={userDataState?.data || {}}
              setAgent={setAgent}
              // changeBuildPort={changeBuildPort}
              // mainModal={ConnectionModal}
              // progressModal={BuildProgressModal}
              buildingFunction={buildBlockly}
            />
          </>
        ) : ('')}
      </div>
    </div>
  );
}


export default Blocks;