/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint  no-param-reassign: 0 */
export const microbit_initial_block = (block, generator) => {
  let code = '';

  let branchDo = generator.statementToCode(block, 'DO');
  branchDo = branchDo.replace(/&quot;/g, '"');
  if (typeof block !== 'undefined' && block.id) {
    generator.setups_[block.id] = branchDo;
  }

  let branch = generator.statementToCode(block, 'REPEAT');
  branch = branch.replace(/&quot;/g, '"');

  code += `
led.enable(false)
basic.forever(function () {
  ${branch}
})
`;

  generator.loop_['forever'] = code;

  return '';
}
