/* eslint-disable func-names */
// import { strict } from "assert";

const $ = window.$;

export default {

  buildBigBlock(html, cols) {
    // <span class="rotate-button button"><span class="icon-rotate-left"></span></span> NO BORRAR
    return `
    <div class="popup-modal-parent matrix-modal-parent matrix-modal-parent-${cols}">
      <div class="popup-modal-under matrix-modal-under">
      </div>
      <div class="matrix-modal matrix-modal-${cols} rotate-0 initial-popup">
        ${html}
      </div>
      <div class="popup-modal-close matrix-modal-close">
        <span class="icon-cross11"></span>
      </div>
      <div class="modal-sidebar icon-modal-16">
        <div class="icon-modal-16 modal-sidebar-main-panel"></div>
        <div class="modal-sidebar-buttons">
          <span class="emotions-picker picker"></span>
          <span class="icon-picker picker"></span>
          <span class="ruler-button button"><span class="icon-grid"></span></span>
          <span class="erase-button button"><span class="icon-delete"></span></span>
        </div>
      </div>
    </div>`;
  },

  buildMiniBlock(block, ledsPerRow, startUp = false) {
    const ele = document.querySelector(`[data-id='${block.id}']`);
    const eleText = document.querySelector(`[data-id='${block.id}'] text:nth-child(2)`);

    let ledDivs = '';

    for (let i = 0; i < Object.keys(block.leds).length; i += 1) {
      const rowStart = ((i / ledsPerRow) % 1 === 0) ? '<div class="led-row">' : '';
      const rowEnd = (((i + 1) / ledsPerRow) % 1 === 0) ? '</div>' : '';
      const ledDiv = `<div class='led ${(block.leds[i]) ? 'led-on' : ''}'></div>`;

      ledDivs += rowStart + ledDiv + rowEnd;
    }


    if (document.getElementById(`matrix-${block.id}`)) {
      const foreignObject = document.getElementById(`matrix-${block.id}`);
      foreignObject.innerHTML = `
        <div class="matrix-mini-modal-horizontal matrix-mini-modal-${ledsPerRow} matrix-mini-modal-${block.id}">
          <div class="mini-modal-leds-container">
            ${ledDivs}
          </div>
        </div>
      `;
    } else {
      const ns = 'http://www.w3.org/2000/svg';
      // const svg = document.querySelector('svg');
      const foreignObject = document.createElementNS(ns, 'foreignObject');
      foreignObject.setAttribute('class', 'matrix-horizontal');
      foreignObject.setAttribute('id', `matrix-${block.id}`);
      foreignObject.innerHTML = `
        <div class="matrix-mini-modal-horizontal matrix-mini-modal-${ledsPerRow} matrix-mini-modal-${block.id}">
          <div class="mini-modal-leds-container">
            ${ledDivs}
          </div>
        </div>
      `;
      if (ele) {
        ele.insertBefore(foreignObject, eleText);
      }
    }
    block.workspace.fireChangeListener('resize');
  },

  js(block) {
    const self = this;
    const rows = block.rows;
    const ledsPerRow = block.cols;
    let ledsHtml = '';

    for (let i = 0; i < rows; i += 1) {
      ledsHtml += '<div class="led-row">';
      for (let j = 0; j < ledsPerRow; j += 1) {
        ledsHtml += '<div class="led"></div>';
      }
      ledsHtml += '</div>';
    }
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildBigBlock(ledsHtml, ledsPerRow);

    $('.matrix-modal div.led').each(function (index) {
      if (block.leds[index]) $(this).addClass('led-on'); // eslint-disable-line
    });
    window.Blockly.WidgetDiv.show(block, true);
    setTimeout(() => {
      $('.matrix-modal-parent .modal-sidebar').toggleClass('start');
      $('.matrix-modal-parent .matrix-modal').removeClass('initial-popup');
    }, 600);

    // UNBIND AND BIND
    $('.blocklyWidgetDiv').unbind('click');
    $('.blocklyWidgetDiv').unbind('mousedown mouseover');
    $('.blocklyWidgetDiv').on('mousedown mouseover', '.matrix-modal div.led', function (e) {
      if (e.buttons === 1 || e.buttons === 3) {
        if ($(this).hasClass('led-on')) {
          $(this).removeClass('led-on');
        } else {
          $(this).addClass('led-on');
        }
      }

      if (e.buttons === 1) {
        $('.matrix-modal div.led').each(function (index) {
          block.leds[index] = $(this).hasClass('led-on'); // eslint-disable-line
        });
        self.buildMiniBlock(block, ledsPerRow);
      }
      // block.workspace.fireChangeListener('resize');
    });

    let currentDrawer = '';

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .modal-sidebar .modal-sidebar-buttons span.picker', function (e) { // eslint-disable-line

      function fillSidebar() {
        if ($(e.target).hasClass('color-picker')) {
          currentDrawer = 'color-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <span class="color-circle bg-white"></span>
          <span class="color-circle bg-red"></span>
          <span class="color-circle bg-pink"></span>
          <span class="color-circle bg-fuscia"></span>
          <span class="color-circle bg-orange"></span>
          <span class="color-circle bg-copper"></span>
          <span class="color-circle bg-yellow"></span>
          <span class="color-circle bg-gold"></span>
          <span class="color-circle bg-blue"></span>
          <span class="color-circle bg-dark-blue"></span>
          <span class="color-circle bg-light-blue"></span>
          <span class="color-circle bg-green"></span>
          <span class="color-circle bg-dark-green"></span>
          <span class="color-circle bg-teal"></span>
          `;
        } else if ($(e.target).hasClass('emotions-picker')) {
          currentDrawer = 'emotions-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller emotions-scroller">
            <div><span class="emotions-rectangle emotion1"></span></div>
            <div><span class="emotions-rectangle emotion2"></span></div>
            <div><span class="emotions-rectangle emotion3"></span></div>
            <div><span class="emotions-rectangle emotion4"></span></div>
            <div><span class="emotions-rectangle emotion5"></span></div>
            <div><span class="emotions-rectangle emotion6"></span></div>
            <div><span class="emotions-rectangle emotion7"></span></div>
            <div><span class="emotions-rectangle emotion8"></span></div>
            <div><span class="emotions-rectangle emotion9"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('icon-picker')) {
          currentDrawer = 'icon-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller icon-scroller">
            <div><span class="icon-rectangle icon1"></span></div>
            <div><span class="icon-rectangle icon2"></span></div>
            <div><span class="icon-rectangle icon3"></span></div>
            <div><span class="icon-rectangle icon4"></span></div>
            <div><span class="icon-rectangle icon5"></span></div>
            <div><span class="icon-rectangle icon6"></span></div>
            <div><span class="icon-rectangle icon7"></span></div>
            <div><span class="icon-rectangle icon8"></span></div>
            <div><span class="icon-rectangle icon9"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('dice-picker')) {
          currentDrawer = 'dice-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller dice-scroller">
            <div><span class="dice-square dice-1"></span></div>
            <div><span class="dice-square dice-2"></span></div>
            <div><span class="dice-square dice-3"></span></div>
            <div><span class="dice-square dice-4"></span></div>
            <div><span class="dice-square dice-5"></span></div>
            <div><span class="dice-square dice-6"></span></div>
          </div>
          `;
        }

        $('.sidebar-scroller').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        });
      }

      // is the drawer open?
      if ($('.blocklyWidgetDiv .modal-sidebar').hasClass('open')) {
        $('.blocklyWidgetDiv .modal-sidebar').removeClass('open');
        // was it not I who opened it?
        if ($(e.target).attr('class').indexOf(currentDrawer) === -1) {
          $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
          $(e.target).addClass('selected');
          setTimeout(() => {
            fillSidebar();
            $('.matrix-modal-parent .modal-sidebar').addClass('open');
          }, 300);
        } else { $(e.target).removeClass('selected'); }
      } else {
        $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
        $(e.target).addClass('selected');
        fillSidebar();
        $('.matrix-modal-parent .modal-sidebar').addClass('open');
      }
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .modal-sidebar .modal-sidebar-main-panel span.color-circle', function (e) { // eslint-disable-line
      $('.matrix-modal-parent .modal-sidebar .modal-sidebar-buttons .color-picker').css('background-color', $(e.target).css('background-color'));
      $('head').append('<style type="text/css"></style>');
      const newStyleElement = $('head').children(':last');
      newStyleElement.html(`.matrix-mini-modal-${block.id} .led-on, .color-picker {background-color: ${$(e.target).css('background-color')} !important;}`);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .matrix-modal-close, .matrix-modal-parent .popup-modal-under', () => {
      $('.matrix-modal').addClass('popup-hide');
      // $('.matrix-modal-parent').addClass('popup-hide');
      $('.modal-sidebar').addClass('popup-hide');
      $('.matrix-modal-close').addClass('popup-hide');
      setTimeout(() => {
        $('.blocklyWidgetDiv').unbind('click');
        window.Blockly.WidgetDiv.hide();
      }, 200);
    });

    // erase functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .erase-button', () => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        // eslint-disable-next-line no-param-reassign
        block.leds[i] = false;
      }
      self.buildMiniBlock(block, ledsPerRow);
    });

    // grid numbers functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .ruler-button', (ele) => {
      if ($(ele.target).hasClass('ruler-on')) {
        $(ele.target).removeClass('ruler-on');
        $('.matrix-modal .led-row').each((index, e) => {
          $(e).attr('data-content', '');
        });
        $('.matrix-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', '');
        });
      } else {
        $(ele.target).addClass('ruler-on');
        $('.matrix-modal .led-row').each((index, e) => {
          $(e).attr('data-content', index);
        });
        $('.matrix-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', index);
        });
      }
    });

    // rotate functionality

    let currentRotationValue = 0;

    function turnValuesIntoLeds(ledArray) {
      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        if (ledArray.indexOf(i) !== -1) {
          // eslint-disable-next-line no-param-reassign
          block.leds[i] = true;

          const row = Math.ceil((i + 1) / ledsPerRow);
          const led = Math.ceil((i) % ledsPerRow) + 1;


          $(`.matrix-modal .led-row:nth-child(${row}) div.led:nth-child(${led})`).addClass('led-on');
        } else {
          // eslint-disable-next-line no-param-reassign
          block.leds[i] = false;
        }
      }
      self.buildMiniBlock(block, ledsPerRow);
    }

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .rotate-button', () => {
      const matrixModal = $('.matrix-modal-parent .matrix-modal');
      currentRotationValue = parseInt(matrixModal.attr('class').replace(`matrix-modal matrix-modal-${ledsPerRow} rotate-`, ''), 10);

      $('.blocklyWidgetDiv .modal-sidebar').addClass('transitioning');

      const newRotationValue = (currentRotationValue < 270) ? currentRotationValue + 90 : 0;

      setTimeout(() => {
        matrixModal.removeClass(`rotate-${currentRotationValue}`);
        matrixModal.addClass(`rotate-${newRotationValue}`);
        setTimeout(() => {
          $('.blocklyWidgetDiv .modal-sidebar').removeClass('transitioning');
        }, (currentRotationValue === 270) ? 500 : 200);
      }, 300);
    });

    // dice functionality
    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-16 .dice-square', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      const n = $(e.target).attr('class').replace('dice-square dice-', '');

      let ledArray = [];

      switch (n) {
        case '1':
          ledArray = [26, 28, 35, 36];
          break;
        case '2':
          ledArray = [6, 7, 14, 15, 48, 49, 56, 57];
          break;
        case '3':
          ledArray = [6, 7, 14, 15, 27, 28, 35, 36, 48, 49, 56, 57];
          break;
        case '4':
          ledArray = [0, 1, 6, 7, 8, 9, 14, 15, 48, 49, 54, 55, 56, 57, 62, 63];
          break;
        case '5':
          ledArray = [0, 1, 6, 7, 8, 9, 14, 15, 27, 28, 35, 36, 48, 49, 54, 55, 56, 57, 62, 63];
          break;
        case '6':
          ledArray = [0, 1, 6, 7, 8, 9, 14, 15, 24, 25, 30, 31, 32, 33, 38, 39, 48, 49, 54, 55, 56, 57, 62, 63];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-16 .emotions-rectangle', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      const n = $(e.target).attr('class').replace('emotions-rectangle emotion', '');

      let ledArray = [];

      switch (n) {
        case '1':
          ledArray = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 18, 29, 34, 37, 38, 41, 42, 45, 50, 53, 54, 57, 58, 61, 66, 77, 82, 93, 99, 108, 116, 117, 118, 119, 120, 121, 122, 123];
          break;
        case '2':
          ledArray = [17, 22, 25, 30, 34, 37, 42, 45, 51, 52, 59, 60, 67, 68, 75, 76, 82, 85, 90, 93, 97, 102, 105, 110];
          break;
        case '3':
          ledArray = [3, 12, 20, 27, 37, 42, 100, 101, 102, 103, 104, 105, 106, 107, 116, 117, 118, 119, 120, 121, 122, 123];
          break;
        case '4':
          ledArray = [19, 20, 34, 37, 42, 43, 44, 45, 83, 92, 100, 107, 117, 118, 119, 120, 121, 122];
          break;
        case '5':
          ledArray = [18, 19, 20, 21, 26, 27, 28, 29, 32, 33, 38, 39, 40, 41, 46, 47, 49, 54, 57, 62, 65, 70, 73, 78, 82, 83, 84, 85, 90, 91, 92, 93];
          break;
        case '6':
          ledArray = [2, 3, 4, 11, 12, 13, 17, 19, 20, 21, 26, 28, 29, 30, 33, 35, 36, 37, 42, 44, 45, 46, 49, 53, 58, 62, 65, 69, 74, 78, 81, 85, 90, 94, 97, 101, 106, 110, 114, 115, 116, 123, 124, 125, 135];
          break;
        case '7':
          ledArray = [1, 2, 3, 4, 11, 12, 13, 14, 16, 21, 26, 31, 32, 37, 42, 47, 48, 50, 51, 53, 58, 60, 61, 63, 64, 66, 67, 69, 74, 76, 77, 79, 80, 85, 90, 95, 96, 101, 106, 113, 111, 114, 115, 116, 123, 124, 125, 126];
          break;
        case '8':
          ledArray = [4, 5, 10, 11, 20, 21, 26, 27, 71, 72, 86, 89, 102, 105, 119, 120];
          break;
        case '9':
          ledArray = [5, 6, 9, 10, 21, 22, 25, 26, 68, 75, 85, 86, 87, 88, 89, 90, 103, 104, 119, 120];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-16 .icon-rectangle', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      const n = $(e.target).attr('class').replace('icon-rectangle icon', '');

      let ledArray = [];

      switch (n) {
        case '1':
          ledArray = [6, 7, 8, 9, 21, 26, 36, 43, 52, 59, 68, 75, 83, 84, 85, 90, 91, 92, 99, 100, 101, 106, 107, 108, 115, 116, 117, 122, 123, 124];
          break;
        case '2':
          ledArray = [4, 5, 10, 11, 19, 20, 21, 22, 25, 26, 27, 28, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 68, 69, 69, 70, 71, 72, 73, 74, 75, 85, 86, 87, 88, 89, 90, 102, 103, 104, 105, 119, 120];
          break;
        case '3':
          ledArray = [17, 18, 20, 21, 26, 27, 29, 30, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 57, 58, 59, 60, 61, 62, 63, 65, 66, 67, 68, 69, 74, 75, 76, 77, 78, 82, 83, 84, 91, 92, 93, 99, 108];
          break;
        case '4':
          ledArray = [6, 7, 8, 9, 22, 23, 24, 25, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 102, 103, 104, 105, 118, 119, 120, 121];
          break;
        case '5':
          ledArray = [33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 65, 66, 67, 68, 69, 70, 73, 74, 75, 76, 77, 78, 81, 82, 83, 84, 85, 86, 89, 90, 91, 92, 93, 94, 98, 99, 100, 101, 106, 107, 108, 109];
          break;
        case '6':
          ledArray = [18, 19, 20, 21, 26, 27, 28, 29, 32, 33, 38, 39, 40, 41, 46, 47, 49, 54, 57, 62, 65, 70, 73, 78, 82, 83, 84, 85, 90, 91, 92, 93];
          break;
        case '7':
          ledArray = [10, 11, 12, 22, 26, 27, 28, 37, 42, 43, 44, 52, 58, 59, 60, 61, 62, 66, 67, 68, 69, 74, 75, 76, 78, 82, 83, 84, 85, 90, 91, 92, 93, 94, 98, 99, 100, 101, 106, 107, 108, 115, 116, 122, 123, 124];
          break;
        case '8':
          ledArray = [7, 8, 9, 22, 23, 24, 25, 26, 37, 38, 39, 40, 41, 42, 52, 53, 54, 55, 67, 68, 69, 70, 71, 82, 83, 84, 85, 86, 87, 88, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127];
          break;
        case '9':
          ledArray = [11, 12, 26, 27, 28, 29, 37, 38, 42, 43, 44, 45, 48, 49, 53, 54, 57, 58, 59, 60, 61, 62, 65, 66, 67, 68, 72, 73, 74, 75, 76, 77, 78, 79, 81, 82, 83, 84, 88, 89, 90, 91, 92, 93, 94, 95, 97, 100, 106, 107, 108, 109, 113, 116, 122, 123, 124, 125];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray);
    });
  },
};
