/* eslint-disable */
import * as Blockly from 'blockly';
import { JuniorFieldGridDropdown } from '../../blocks/customBlocks/juniorFieldGridDropdown';
import { JuniorFieldNumber } from '../../blocks/customBlocks/juniorFieldNumber';
import { JuniorFieldTextInput } from '../../blocks/customBlocks/juniorFieldTextInput';


class HorizontalInputConnection extends Blockly.blockRendering.Connection {
  constructor(constants, input) {
    super(constants, input.connection);
    this.type = Blockly.blockRendering.Types.getType('INPUT');

    this.align = input.align;

    this.connectedBlock =
      input.connection && input.connection.targetBlock()
        ? (input.connection.targetBlock())
        : null;

    if (this.connectedBlock) {
      const bBox = this.getHeightWidth(this.connectedBlock);
      this.connectedBlockWidth = bBox.width;
      this.connectedBlockHeight = bBox.height;
    } else {
      this.connectedBlockWidth = this.constants_.EMPTY_STATEMENT_INPUT_WIDTH;
      this.connectedBlockHeight = 0;
    }
    // set the statement connection position
    var connY = Math.max(this.connectedBlockHeight, this.constants_.DUMMY_INPUT_MIN_HEIGHT + this.constants_.NOTCH_HEIGHT) - this.shape.height - 1;
    this.connectionModel.setOffsetInBlock(this.shape.width + 2 * this.constants_.NOTCH_WIDTH, connY);
    // console.log('================HorizontalInputConnection', connY, input, this);
  }

  getHeightWidth(connectedBlock) {
    // compensate extra height added to enlarge toolbox menu
    let offset = 0;
    for (var i = 0, input; (input = connectedBlock.inputList[i]); i++) {
      for (var j = 0, field; (field = input.fieldRow[j]); j++) {
        offset = (field instanceof JuniorFieldGridDropdown || field instanceof JuniorFieldNumber || field instanceof JuniorFieldTextInput) ? this.constants_.EXTRA_HEIGHT_CUSTOM_BLOCKS : 0;
      }
    }
    let height = connectedBlock.height - offset;
    let width = connectedBlock.width;
    const nextBlock = connectedBlock.getNextBlock();
    if (nextBlock) {
      const nextHeightWidth = this.getHeightWidth(nextBlock);
      height = Math.max(height, nextHeightWidth.height);
      width += nextHeightWidth.width - 2 * this.constants_.CORNER_RADIUS;
      // compensate extra space added to enlarge toolbox menu
      width -= 2 * this.constants_.CORNER_RADIUS;
    }
    return { height, width };
  }
}

class HorizontalInlineInput extends HorizontalInputConnection {
  constructor(constants, input) {
    super(constants, input);
    this.type = Blockly.blockRendering.Types.getType('INLINE_INPUT');

    if (!this.connectedBlock) {
      this.height = this.constants_.EMPTY_INLINE_INPUT_HEIGHT;
      this.width = this.constants_.EMPTY_INLINE_INPUT_PADDING;
    } else {
      // We allow the dark path to show on the parent block so that the child
      // block looks embossed.  This takes up an extra pixel in both x and y.
      this.width = this.connectedBlockWidth;
      this.height = this.connectedBlockHeight;
    }

    this.connectionHeight = !this.isDynamicShape
      ? this.shape.height
      : (this.shape.height)(this.height);

    this.connectionWidth = !this.isDynamicShape
      ? this.shape.width
      : (this.shape.width)(this.width);
    if (!this.connectedBlock) {
      this.width += this.connectionWidth * (this.isDynamicShape ? 2 : 1);
    }

    this.connectionOffsetY =
      'connectionOffsetY' in this.shape
        ? this.shape.connectionOffsetY(this.connectionHeight)
        : this.constants_.TAB_OFFSET_FROM_TOP;

    this.connectionOffsetX =
      'connectionOffsetX' in this.shape
        ? this.shape.connectionOffsetX(this.connectionWidth)
        : 0;
  }
}


export { HorizontalInlineInput, HorizontalInputConnection };
