import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        type: 'createc_planetario_initial_block',
        x: 10,
        y: 10,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="controls_if_no_else" />
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_COMPARISON}">
      <label text="%{BKY_LANG_CATEGORY_COMPARISON}" />
      <block type="createc_logic_compare_ldr">
        <value name="A">
          <block type="planetario_createc_ciudad_ldr" />
        </value>
      </block>
    </category>

    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_LIGHTNING}">
      <label text="%{BKY_LANG_CATEGORY_LIGHTNING}" />
      <block type="planetario_createc_restore_electricity" />
      <block type="createc_planetario_luminaria_zona3" />
      <block type="createc_planetario_luminaria_cupula" />
      <block type="createc_planetario_luminaria_cupula_off" />
      <block type="createc_planetario_reflector">
        <value name="COLOUR">
          <shadow type="colour_createc_number">
            <field name="COLOUR">#00ff00</field>
          </shadow>
        </value>
      </block>
      <block type="createc_planetario_reflector_off" />
    </category>

    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_LCD}">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="createc_planetario_screen" />
    </category>

    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_MOTION}">
      <label text="%{BKY_LANG_CATEGORY_MOTION}" />
      <block type="createc_planetario_telescope_degrees">
        <value name="angulo">
          <shadow type="slider_angle_telescopio">
            <field name="angulo">10</field>
          </shadow>
        </value>
      </block>
      <block type="createc_planetario_telescope_direction" />
    </category>
  </xml>
);
/* eslint-enable */
