import {
  CanvasInnerDefault,
  CanvasOuterDefault,
  NodeDefault,
  NodeInnerDefault,
  PortsGroupDefault,
  PortDefault,
} from '.';

export const DefaultComponents = {
  CanvasOuter: CanvasOuterDefault,
  CanvasInner: CanvasInnerDefault,
  NodeInner: NodeInnerDefault,
  Port: PortDefault,
  PortsGroup: PortsGroupDefault,
  Node: NodeDefault,
};
