/* eslint-disable */
import * as Blockly from 'blockly';


class Drawer extends Blockly.blockRendering.Drawer {
  constructor(block, info) {
    super(block, info);
    // console.log('================Drawer', block, this);
  }

  drawOutline_() {
    // console.log('================drawOutline_', this.info_.rows);
    this.drawTop_();
    this.drawRight_(this.info_.rows[2]);
    this.drawBottom_();
    this.drawLeft_(this.info_.rows[2]);
  }

  drawRight_(row) {
    var nextConnection = this.info_.nextConnection;
    if (nextConnection) {
      var extraHeight = row.height - nextConnection.height;
      this.outlinePath_ +=
        Blockly.utils.svgPaths.lineOnAxis('V', extraHeight) +
        nextConnection.shape.pathRight +
        Blockly.utils.svgPaths.lineOnAxis('v', 5);
      var connInfo = nextConnection;
      var x = connInfo.xPos + this.constants_.CORNER_RADIUS;
      // var x = connInfo.xPos;
      // var x = connInfo.xPos - connInfo.width;
      var connX = (this.info_.RTL ? -x : x);
      var connY = row.height - nextConnection.height - 1;
      connInfo.connectionModel.setOffsetInBlock(connX, connY);
      // console.log('========================connInfo drawRight_', row, nextConnection);
      // connInfo.connectionModel.setOffsetInBlock(connX, (connInfo.centerline - connInfo.height / 2));
    }
    this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('V', row.yPos + row.height - this.constants_.CORNER_RADIUS);
    // this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('V', row.height);
  }

  drawLeft_(row) {
    var previousConnection = this.info_.previousConnection;
    // console.log('================drawLeft_', row, previousConnection, this);
    if (previousConnection) {
      var up = 5 + this.constants_.CORNER_RADIUS;
      var extraHeight = row.height - previousConnection.height - up;
      this.outlinePath_ +=
        Blockly.utils.svgPaths.lineOnAxis('v', -up) +
        previousConnection.shape.pathLeft +
        Blockly.utils.svgPaths.lineOnAxis('v', -extraHeight);

      var connInfo = previousConnection;
      var x = connInfo.xPos;
      var connX = (this.info_.RTL ? -x : x);
      var connY = row.height - previousConnection.height - 1;
      connInfo.connectionModel.setOffsetInBlock(connX, connY);
      // connInfo.connectionModel.setOffsetInBlock(connX, (connInfo.centerline - connInfo.height / 3));
    }
    this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('V', this.constants_.CORNER_RADIUS + (!this.info_.previousConnection ? this.constants_.OUTSIDE_CORNERS.fullRadiusLength - this.constants_.CORNER_RADIUS : 0));
    // this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('V', row.height);
  }


  drawTop_() {
    var topRow = this.info_.topRow;
    var elements = topRow.elements;

    this.outlinePath_ += Blockly.utils.svgPaths.moveBy(topRow.xPos, this.info_.startY);
    for (var i = 0, elem; (elem = elements[i]); i++) {
      if (Blockly.blockRendering.Types.isLeftRoundedCorner(elem)) {
        if (this.info_.previousConnection) {
          this.outlinePath_ += this.constants_.OUTSIDE_CORNERS.topLeft;
        } else {
          this.outlinePath_ += this.constants_.OUTSIDE_CORNERS.topLeftFullRadius;
        }
      } else if (Blockly.blockRendering.Types.isRightRoundedCorner(elem)) {
        this.outlinePath_ += this.constants_.OUTSIDE_CORNERS.topRight;
      } else if (Blockly.blockRendering.Types.isSpacer(elem)) {
        // let width = elem.width;
        if (this.info_.rows[2].hasStatement) {
          const row = this.info_.rows[2];
          // console.log('================drawTop_', elem, elements, row);
          this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('h', row.widthWithConnectedBlocks);
        } else {
          // this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('h', elem.width);
          const horizontal = elem.xPos + elem.width - (!this.info_.previousConnection ? this.constants_.OUTSIDE_CORNERS.fullRadiusLength - this.constants_.CORNER_RADIUS: 0);
          this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('h', horizontal);
        }
      }
      // No branch for a square corner because it's a no-op.
    }
    this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('v', topRow.height);
  }

  drawBottom_() {
    var bottomRow = this.info_.bottomRow;
    var elems = bottomRow.elements;

    var outlinePath = '';
    for (var i = elems.length - 1, elem; (elem = elems[i]); i--) {
      if (Blockly.blockRendering.Types.isLeftRoundedCorner(elem)) {
        if (this.info_.previousConnection) {
          outlinePath += this.constants_.OUTSIDE_CORNERS.bottomLeft;
        } else {
          outlinePath += this.constants_.OUTSIDE_CORNERS.bottomLeftFullRadius;
        }
      } else if (Blockly.blockRendering.Types.isRightRoundedCorner(elem)) {
        outlinePath += this.constants_.OUTSIDE_CORNERS.bottomRight;
      } else if (Blockly.blockRendering.Types.isSpacer(elem)) {
        if (this.info_.rows[2].hasStatement) {
          outlinePath += this.drawStatementInput_(this.info_.rows[2]);
        } else {
          // outlinePath += Blockly.utils.svgPaths.lineOnAxis('h', -1 * elem.width);}
          const horizontal = elem.xPos + elem.width - (!this.info_.previousConnection ? this.constants_.OUTSIDE_CORNERS.fullRadiusLength - this.constants_.CORNER_RADIUS : 0);
          outlinePath += Blockly.utils.svgPaths.lineOnAxis('h', -1 * horizontal);
        }
      }
    }

    const vertical = bottomRow.yPos - (this.constants_.CORNER_RADIUS / 2);
    this.outlinePath_ += Blockly.utils.svgPaths.lineOnAxis('V', vertical);
    this.outlinePath_ += outlinePath;
  }

  getLastInput(row) {
    // TODO: Consider moving this to InputRow, if possible.
    for (let i = row.elements.length - 1; i >= 0; i--) {
      const elem = row.elements[i];
      // if (Blockly.blockRendering.Types.isInput(elem)) {
      if (Blockly.blockRendering.Types.isStatementInput(elem)) {
        return elem;
      }
    }
    return null;
  }

  drawStatementInput_(row) {
    const input = this.getLastInput(row);
    // Where to start drawing the notch, which is on the right side in LTR.
    const insideCorners = this.constants_.INSIDE_CORNERS;

    const statementWidthEmpty = this.constants_.EMPTY_STATEMENT_INPUT_WIDTH - 2 * this.constants_.CORNER_RADIUS;
    const statementWidth = Math.max(input.connectedBlockWidth - statementWidthEmpty - 2 * this.constants_.CORNER_RADIUS, statementWidthEmpty);
    const paddingLeft = input.shape.width;
    const paddingRight = row.widthWithConnectedBlocks - statementWidth - paddingLeft - (4 * this.constants_.CORNER_RADIUS);
    const notchPadding = 5 + this.constants_.CORNER_RADIUS;
    const extraHeight = Math.max(input.connectedBlockHeight, this.constants_.DUMMY_INPUT_MIN_HEIGHT + this.constants_.NOTCH_HEIGHT) - input.shape.height - 5 - this.constants_.NOTCH_HEIGHT;
    // console.log('================drawStatementInput_', input, row);
    const outlinePath_ =
      Blockly.utils.svgPaths.lineOnAxis('h', -paddingRight) +
      insideCorners.pathBottomRight +
      Blockly.utils.svgPaths.lineOnAxis('v', -notchPadding) +
      input.shape.pathLeft +
      Blockly.utils.svgPaths.lineOnAxis('v', -extraHeight) +
      insideCorners.pathTopRight +
      Blockly.utils.svgPaths.lineOnAxis('h', -statementWidth) +
      insideCorners.pathTopLeft +
      Blockly.utils.svgPaths.lineOnAxis('v', extraHeight) +
      input.shape.pathRight +
      Blockly.utils.svgPaths.lineOnAxis('v', notchPadding) +
      insideCorners.pathBottomLeft +
      Blockly.utils.svgPaths.lineOnAxis('h', -paddingLeft)
      ;

    // var up = 5 + this.constants_.CORNER_RADIUS;
    // var extraHeight = row.height - previousConnection.height - up;
    this.positionStatementInputConnection_(row);
    return outlinePath_;
  }

  layoutField_(fieldInfo) {
    const yPos = fieldInfo.centerline - fieldInfo.height / 2;
    let xPos = fieldInfo.xPos + (!this.info_.previousConnection ? 5 : 0);
    let scale = '';
    if (this.info_.RTL) {
      xPos = -(xPos + fieldInfo.width);
      if (fieldInfo.flipRtl) {
        xPos += fieldInfo.width;
        scale = 'scale(-1 1)';
      }
    }

    if (Blockly.blockRendering.Types.isIcon(fieldInfo)) {
      const icon = fieldInfo.icon;
      icon.setOffsetInBlock(new Coordinate(xPos, yPos));
      if (this.info_.isInsertionMarker) {
        icon.hideForInsertionMarker();
      }
    } else {
      const svgGroup = fieldInfo.field.getSvgRoot() || null;
      // console.log('================layoutField_', fieldInfo, this);
      svgGroup.setAttribute(
        'transform',
        'translate(' + xPos + ',' + yPos + ')' + scale,
      );
      // svgGroup.setAttribute('fill', '#000000');
      if (this.info_.isInsertionMarker) {
        svgGroup.setAttribute('display', 'none');
      }
    }
  }

  drawConnectionHighlightPath(measurable) {
    // leave this empty to avoid drawing the connection highlight path
  }
}

export default Drawer;
