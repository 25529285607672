/** Helper function for creating PinSignalInfo objects */
export const analog = (channel) => ({ type: 'analog', channel });

// signal: 'SCL' | 'SDA'
export const i2c = (signal = 'SCL', bus = 0) => ({
  type: 'i2c',
  signal,
  bus,
});

// signal: 'SCK' | 'MOSI' | 'MISO' | 'SS'
export const spi = (signal = 'SCK', bus = 0) => ({
  type: 'spi',
  signal,
  bus,
});

// signal: 'RX' | 'TX'
export const usart = (signal = 'RX', bus = 0) => ({
  type: 'usart',
  signal,
  bus,
});

export const GND = () => ({ type: 'power', signal: 'GND' });
export const VCC = (voltage) => ({ type: 'power', signal: 'VCC', voltage });