import React, { useEffect, useState } from 'react';
import styles from './turnsFooter.mod.scss';

const TurnsFooter = (props) => {

  return (
    <div className={styles.turnsFooter}>
      <div className={styles.logos}>
        <div><img src="/images/cosmos/createc/footer_createc_1.png" height={35} /></div>
        {/* <div><img src="/images/cosmos/createc/footer_createc_2.png" /></div> */}
      </div>
    </div>
  );
};

export default TurnsFooter;