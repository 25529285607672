import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBlockly, selectBlockly } from '@modules/blocklyNew/features/blocklySlice';

export const useBlockly = () => useSelector(selectBlockly);

export const useUpdateBlockly = (project) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (project) {
      dispatch(updateBlockly(project));
    }
  }, [project]);
};
