/* eslint-disable no-nested-ternary */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
// import './CardList.scss';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VizSensor from 'react-visibility-sensor';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'react-intl';
import CanvasSVG from '../CanvasSVG';
import { ModalCard } from '../Header/style';
import styles from './CardList.mod.scss';

class CardList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      darkTheme: true,
      showType: props.viewType ? props.viewType : 'card', // 'list'
      onToggleView: props.onToggleView ? props.onToggleView : null,
      type: props.type,
      menuOptions: { anchorEl: null, anchorId: undefined },
      activeDeleteId: props.activeDeleteId,
      deleteFetching: props.deleteFetching,
      items: (props.items) ? props.items : [
        // {
        //   img: 'imgUrl',
        //   title: 'Matriz de leds',
        //   description: 'Con esta matriz de leds podés hacer que el mundo sea feliz',
        //   boardName: 'Uno Rev. 3',
        //   className: 'arduino-genuino-uno',
        // },
      ],
    };
    this.toggleView = this.toggleView.bind(this);
    this.parseItems = this.parseItems.bind(this);
  }

  componentWillMount() {
    const { items } = this.state;
    this.parseItems(items);
  }

  componentWillReceiveProps(newprops) {
    if (newprops.items) this.parseItems(newprops.items);
    if (newprops.viewType) this.setState({ showType: newprops.viewType });

    this.setState({ activeDeleteId: newprops.activeDeleteId, deleteFetching: newprops.deleteFetching });
  }

  setVisible(id) {
    const { items } = this.state;

    items.forEach((i) => {
      const newItem = i;
      if (i.id === id) newItem.visible = true;
      return newItem;
    });

    this.setState({ items });
  }

  parseItems(itemsToParse) {
    const parsedItems = itemsToParse.map((i) => {
      const newI = i;
      newI.visible = false;
      return newI;
    });

    this.setState({ items: parsedItems });
  }

  toggleView() {
    const { showType, onToggleView } = this.state;
    const viewType = (showType === 'card') ? 'list' : 'card';
    this.setState({ showType: viewType });
    if (onToggleView) {
      onToggleView(viewType);
    }
  }

  handleClick(e, id) {
    this.setState({ menuOptions: { anchorEl: e.currentTarget, anchorId: id } });
  }

  handleClose() {
    this.setState({ menuOptions: { anchorEl: null, anchorId: undefined } });
  }

  render() {
    const { items, showType, type, activeDeleteId, deleteFetching } = this.state;
    const { onEditClick, onDeleteTrigger, onNewProjectsClick } = this.props;

    const { menuOptions } = this.state;
    const { anchorEl, anchorId } = menuOptions;

    const prependCSS = (canvas, css) => {
      let prependedCanvas = canvas;
      if (css && css !== '') {
        prependedCanvas = `${css}">${canvas}`;
      }
      return prependedCanvas;
    };

    const toggleTheme = () => {
      let { darkTheme } = this.state;
      darkTheme = !darkTheme;

      if (!darkTheme) window.document.querySelector('body').classList.add('theme-aula');
      else window.document.querySelector('body').classList.remove('theme-aula');

      this.setState({ darkTheme });
    };

    return (
      <div className={styles.CardList}>
        <>
          <div className={styles.menu}>
            <div className={styles.switcher} onClick={this.toggleView} onKeyDown={() => { }}>
              {showType === 'card' ? (
                <span className={`${styles.iconMenu} icon-menu`} />
              ) : (
                <span className={`${styles.iconGrid} icon-grid`} />
              )}
            </div>
          </div>
          <div className={showType === 'card' ? styles.cardItems : styles.listItems}>
            {type === 'private' && (
              showType === 'card' ? (
                <div className={`${styles.item} ${styles.itemCard} ${styles.bloquesPrompt}`} onClick={() => onNewProjectsClick()} onKeyDown={() => { }}>
                  <span className={styles.plusIcon}><span>+</span></span>
                  <span><FormattedMessage id="projects.create" /></span>
                  <div className={`${styles.container}`} style={{ width: '80%', textAlign: 'center' }}><FormattedMessage id="projects.createDescription" /></div>
                </div>
              ) : (
                <div className={`${styles.item} ${styles.itemList} ${styles.bloquesPrompt}`} onClick={() => onNewProjectsClick()} onKeyDown={() => { }}>
                  <span className={`${styles.plusIcon} mr-2`}><span>+</span></span>
                  <span><FormattedMessage id="projects.create" /></span>
                </div>
              )
            )}
            {items.map((i, index) => (
              showType === 'card' ? (
                <div className={`${styles.item} ${styles.itemCard}`} key={`card-${i.id}`}>
                  {(i.canvas) ? (
                    <VizSensor
                      onChange={(isVisible) => {
                        if (i.visible === false && isVisible) { this.setVisible(i.id); }
                      }}
                    >
                      <div className={styles.img}>
                        {(i.visible || index < 5) && (
                          <CanvasSVG
                            content={prependCSS(i.canvas, i.canvasCss)}
                            svgX={i.canvasBBoxX || 10}
                            svgY={i.canvasBBoxY || 10}
                            svgWidth={i.canvasBBoxWidth || 800}
                            svgHeight={i.canvasBBoxHeight || 500}
                            width="100%"
                            height="100%"
                          />
                        )}
                      </div>
                    </VizSensor>
                  ) : (
                    i.isCodeOnly ? (
                      <div className={`${styles.img} ${styles.codeImg}`}>
                        <div className={`${styles.actualCode}`}>
                          {i.code && i.code.split(/(?:\r\n|\r|\n)/g).map((item) => (
                            <div key={`codeItem-${Math.random()}`} className={`${styles.codeLine}`}>{(item === '') ? '\xa0' : item.replace(/\s/g, '\xa0')}</div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className={`${styles.img} ${styles.noImg}`}><FormattedMessage id="projects.noImg" /></div>
                    )
                  )}
                  <div className={`${styles.cardHeader}`}>
                    <div>
                      <div className={`${styles.board} ${i.className}`}>{i.boardName}</div>
                      {(i.isCodeOnly) ? (
                        <div className={`${styles.board} isCode`}><FormattedMessage id="projects.types.code" /></div>
                      ) : (
                        <div className={`${styles.board} isBlocks`}><FormattedMessage id="projects.types.blocks" /></div>
                      )}
                    </div>
                    {type === 'private' && (
                      <div className={styles.contenedor}>
                        <MoreVertIcon aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClick(e, i.id)} />
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={anchorId === i.id}
                          onClose={() => this.handleClose()}
                        >
                          <MenuItem onClick={(e) => { onEditClick(e, i.id, type, 'edit'); this.handleClose(); }}><FormattedMessage id="projects.edit" /></MenuItem>
                          <MenuItem onClick={(e) => { onEditClick(e, i.id, type, 'delete'); this.handleClose(); }}><FormattedMessage id="projects.delete" /></MenuItem>
                        </Menu>
                      </div>
                    )}
                  </div>
                  <div className={`${styles.cardBody}`}>
                    <div className={`${styles.title}`}>{i.title}</div>
                    <div className={`${styles.description}`}>{i.description}</div>
                    {activeDeleteId === i.id ? (
                      <div className={`${styles.deletePrompt} ${(deleteFetching) ? 'fetching' : ''}`}>
                        <div className={styles.modal}>
                          <div className={styles.modalInfo}>
                            <span className="mb-4"><FormattedMessage id="projects.deleteConfirm" /></span>
                            <div className={styles.buttons}>
                              <button type="button" className={`${styles.buttonDelete} mr-3`} onClick={(e) => { onDeleteTrigger(e, i.id); }} onKeyDown={() => { }}><FormattedMessage id="projects.delete" /></button>
                              <button type="button" className={styles.buttonCancel} onClick={(e) => { onEditClick(e, i.id, type, 'cancel'); this.handleClose(); }}><FormattedMessage id="projects.cancel" /></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className={`${styles.actions} ${(activeDeleteId === i.id) ? 'actions-hide' : ''}`}>
                        <div className={styles.infoUser}>
                          <div className="avatar-img"><img alt="Avatar" className="avatar" src="/images/avatar_square_purple.png" /></div>
                          <div className={styles.roleUser}>
                            <span className={styles.fullName}>
                              {i.user.firstName === null ? 'Usuario' : `${i.user.firstName} `}
                              {i.user.lastName === null ? ' Invitado' : i.user.lastName}
                            </span>
                            <span className={styles.role}>{i.user.Roles[0].roleName}</span>
                          </div>
                        </div>
                        <div className={`ml-2 d-flex align-items-center ${styles.actionsText}`} onKeyPress={() => { }} onClick={i.onClick}>
                          <FormattedMessage id="projects.open" />
                          <ChevronRightIcon />
                        </div>
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <div className={`${styles.item} ${styles.itemList} ${type === 'public' ? 'isPublic' : ''}`} onKeyPress={() => { }}>
                  <div className={styles.listBody}>
                    <div className={styles.title}>{i.title}</div>
                    <div className={styles.description}>{i.description}</div>
                    <div className="d-flex">
                      <div className={styles.rectangles} onKeyDown={() => { }} onClick={i.onClick}>
                        {(i.isCodeOnly) ? (
                          <div className={`${styles.board} isCode`}><FormattedMessage id="projects.types.code" /></div>
                        ) : (
                          <div className={`${styles.board} isBlocks`}><FormattedMessage id="projects.types.blocks" /></div>
                        )}
                        <div className={`${styles.board} ${i.className}`}>{i.boardName}</div>
                      </div>
                      {type === 'private' && (
                        <div className={styles.contenedor}>
                          <MoreVertIcon aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClick(e, i.id)} />
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={anchorId === i.id}
                            onClose={() => this.handleClose()}
                          >
                            <MenuItem onClick={(e) => { onEditClick(e, i.id, type, 'edit'); this.handleClose(); }}><FormattedMessage id="projects.edit" /></MenuItem>
                            <MenuItem onClick={(e) => { onEditClick(e, i.id, type, 'delete'); this.handleClose(); }}><FormattedMessage id="projects.delete" /></MenuItem>
                          </Menu>
                        </div>
                      )}
                      {activeDeleteId === i.id && (
                        <div className={styles.modalContainer}>
                          <ModalCard className={styles.modal} modalBackground="#fff" openDropdown={true}>
                            <div className={styles.modalInfo}>
                              <span className="mb-4"><FormattedMessage id="projects.deleteConfirm" /></span>
                              <div className={styles.buttons}>
                                <button type="button" className={`${styles.buttonDelete} mr-3`} onClick={(e) => { onDeleteTrigger(e, i.id); }} onKeyDown={() => { }}><FormattedMessage id="projects.delete" /></button>
                                <button type="button" className={styles.buttonCancel} onClick={(e) => { onEditClick(e, i.id, type, 'cancel'); this.handleClose(); }}><FormattedMessage id="projects.cancel" /></button>
                              </div>
                            </div>
                          </ModalCard>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            ))}
          </div>
        </>
      </div>
    );
  }
}

export default CardList;
