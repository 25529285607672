import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        movable: false,
        deletable: false,
        type: 'new_event_start',
        x: 30,
        y: 30,
      },
    ],
  },
};

export const categoryXml = (
  <xml>
    <block type="new_event_repeat" />
    <block type="control_loop_number" />
    <block type="on_buton_pressed" />
    <block type="pause_millis" />
    <block type="show_icon" />
    <block type="show_string" />
  </xml>
);
