import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, DRIVING_PROJECTS_TYPE, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getDrivingConfigForBlock, getDrivingElementForBlock } from '../config';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.driving.projects.paperCollector';
const imgBaseUrl = '/images/creabots/driving/projects/paperCollector';

export const paperCollector: Project = {
  id: 3,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.MOVEMENT,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/recolector-de-papeles.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.DRIVING, projectName: DRIVING_PROJECTS_TYPE.PAPER_COLLECTOR, totalSteps: 22 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 6),
        completionCode: 'voidsetup\\(\\)\\{BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 6),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':break;case'L':break;case'R':break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 105,
          },
        },
      ],
    },
    // Step 5.
    // 'Retroceder' Block.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Avanzar\\(255\\);break;case'L':break;case'R':break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 190,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':break;case'R':break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 220,
            yOffset: 200,
          },
        },
      ],
    },
    // 'Girar a la derecha' Block.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':break;case'R':Bhoot_Avanzar\\(255\\);break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 270,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':break;case'R':Bhoot_GirarDerecha\\(255\\);break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 220,
            yOffset: 280,
          },
        },
      ],
    },
    // 'Girar a la izquierda' Block.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':Bhoot_Avanzar\\(255\\);break;case'R':Bhoot_GirarDerecha\\(255\\);break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 360,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':Bhoot_GirarIzquierda\\(255\\);break;case'R':Bhoot_GirarDerecha\\(255\\);break;case'A':break;case'B':break;case'S':break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 220,
            yOffset: 365,
          },
        },
      ],
    },
    // 'Frenar' Block.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':Bhoot_GirarIzquierda\\(255\\);break;case'R':Bhoot_GirarDerecha\\(255\\);break;case'A':break;case'B':break;case'S':Bhoot_Avanzar\\(255\\);break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 135,
            yOffset: 440,
          },
        },
      ],
    },
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: "switch\\(command\\)\\{case'U':Bhoot_Avanzar\\(255\\);break;case'D':Bhoot_Retroceder\\(255\\);break;case'L':Bhoot_GirarIzquierda\\(255\\);break;case'R':Bhoot_GirarDerecha\\(255\\);break;case'A':break;case'B':break;case'S':Bhoot_Frenar\\(\\);break;\\}\\}voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)\\)\\{command=BT1\\.read\\(\\);commandParser2\\(command\\);\\}\\}",
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 220,
            yOffset: 445,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/recolector-de-papeles.mp4', // Change it.
      },
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
      },
    },
  ],
};
