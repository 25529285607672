/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import './ImputComponent.scss';

class InputComponent extends Component {
  constructor(props) {
    super(props);
    const { changeField, form, name, type, keyname, onEnterPress, placeholder, error } = this.props;
    this.state = {
      onEnterPress: onEnterPress || (() => {}),
      changeField,
      form,
      name,
      type,
      keyname,
      placeholder,
      error,
    };
    // const responsive = new Responsive(this); //eslint-disable-line
    // this.detectEnter = this.detectEnter.bind(this);
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      onEnterPress: newprops.onEnterPress || (() => {}),
      changeField: newprops.changeField,
      form: newprops.form,
      name: newprops.name,
      type: newprops.type,
      keyname: newprops.keyname,
      error: newprops.error || false,
      placeholder: newprops.placeholder,
    });
  }

  detectEnter(e) {
    const { onEnterPress } = this.state;

    if (e.keyCode === 13) { onEnterPress(); }
  }

  render() {
    const { changeField, form, error, name, type, keyname, placeholder } = this.state;
    return (
      <div className="form-group">
        <label htmlFor={`id-${keyname}`} className="labelForm text-left">{ name }</label>
        <input onKeyDown={(e) => { this.detectEnter(e); }} className={`form-control inputForm bg-white ${error ? 'border border-danger p-2' : 'p-2'} `} type={type} id={`id-${keyname}`} onChange={(e) => { changeField(keyname, e); }} value={form[keyname]} placeholder={placeholder || ''} />
      </div>
    );
  }
}

export default InputComponent;
