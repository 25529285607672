import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="codit_initial_block" x="50" y="50" deletable="false" movable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>

    <category name="%{BKY_LANG_CATEGORY_MOTION}" id="Movimiento" iconclass="motors" expandedclass="motors-extended">
      <block type="codit_forward" />
      <block type="codit_backward" />
      <block type="codit_left" />
      <block type="codit_right" />
      <block type="codit_forward_3" />
      <block type="codit_shake" />
    </category>

    <category id="Salidas" name="%{BKY_LANG_CATEGORY_ACTION}" iconclass="outputs" expandedclass="outputs-extended">
      <block type="codit_motor" />
      <block type="codit_light" />
      <block type="codit_color" />
      <block type="codit_music" />
      <block type="codit_talk" />
    </category>

    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <block type="codit_wait" />
      <block type="codit_wait_to" />
    </category>

  </React.Fragment>
);
/* eslint-enable */
