import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { ProviderSelectorContext } from '@modules/profileSelector/features/context';
import { useMediaQuery } from '@utils/hooks/styles';
import styles from './loadProject.mod.scss';
import StoredProjects from './storedProjects';
import LoadFromFile from './loadablesProjects/loadFromFile';
import LoadFromRemote from './loadablesProjects/loadFromRemote';

function LoadProject({
  addTab,
  changeField,
  selectActiveTab,
  hideProjectScrollbar,
  onStartRemoteProject,
  onStoredProjectClick,
}) {
  const {
    loadRemoteSession,
    collapseRemoteSession,
    toggleRemoteSession,
  } = useContext(ProviderSelectorContext);
  const intl = useIntl();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const fileButtonMediaWidth = (isMobile ? '70px' : '60%');
  useEffect(() => {
    hideProjectScrollbar();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.loadProjectContainer}>
        <section className={styles.loadContainer}>
          <div
            className={styles.loadFromFileContainer}
            style={{ width: loadRemoteSession ? fileButtonMediaWidth : '100%' }}
          >
            <LoadFromFile
              intl={intl}
              addTab={addTab}
              selectActiveTab={selectActiveTab}
            />
          </div>
          <div className={styles.loadFromRemoteContainer}>
            {(!process.env.IS_ELECTRON) && (
              <LoadFromRemote
                intl={intl}
                changeField={changeField}
                loadRemoteSession={loadRemoteSession}
                toggleRemoteSession={toggleRemoteSession}
                collapseRemoteSession={collapseRemoteSession}
                onStartRemoteProject={onStartRemoteProject}
              />
            )}
          </div>
        </section>
        <section className={styles.storedContainer}>
          <StoredProjects onStoredProjectClick={onStoredProjectClick} />
        </section>
      </div>
    </div>
  );
}

export default LoadProject;
