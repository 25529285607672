const SHOW_PROFILE_SELECTOR = 'PROJECTS/PROFILE_SELECTOR/SHOW';
const HIDE_PROFILE_SELECTOR = 'PROJECTS/PROFILE_SELECTOR/HIDE';

export const initialState = {
  display: true,
};

export default function profileSelector(state = initialState, action) {
  switch (action.type) {
    case SHOW_PROFILE_SELECTOR: {
      return {
        display: true,
      };
    }
    case HIDE_PROFILE_SELECTOR: {
      return {
        display: false,
      };
    }
    default:
      return state;
  }
}

// selectors
export const selectProfileSelector = (state) => state.profileSelector;

// actions
export const showProfileSelector = () => ({ type: SHOW_PROFILE_SELECTOR });
export const hideProfileSelector = () => ({ type: HIDE_PROFILE_SELECTOR });
