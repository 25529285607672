import React, { Component } from 'react';
import { MotorPWMElement } from '../components/motorPWM/motorPWM';
import { rotatePort, cpuMillis } from '../utils';

export class MotorPWM extends Component {
  constructor(hardware, pin, id = 'Led', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;

    this.lastTimestamp = 0;
    this.pinStatePWM = 0;
    this.pinDirection = 0;
    this.pinSpeed = 0;

    this.period = 0;
    this.periodTemp = 0;
    this.periodHigh = 0;
    this.periodHighTemp = 0;

    this.variant = variant || {};
    this.pinInfo = [
      { name: 'PWM', x: 29, y: 35, signals: [], description: 'PWM' },
    ];
    this.size = {
      width: 113,
      height: 118,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runMicrosecond = (cpuCycles) => {
    if (this.hardware && this.pin) {
      const microseconds = cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      const delta = microseconds - this.lastTimestamp;
      if (delta > 30) {
        const timeOfChanges = cpuMillis(this.hardware.runner.cpu.cycles - this.periodTemp, this.hardware.mhz);
        // console.log('=======================', timeOfChanges, this.pinDirection, this.pinStatePWM, this.period, this.periodHigh);
        if (this.pinStatePWM && timeOfChanges > 10) { // full speed
          this.pinSpeed = 100;
        } else if (!this.pinStatePWM && timeOfChanges > 10) { // brake
          this.pinSpeed = 0;
        } else { // spin with speed
          this.pinSpeed = (this.period > 0) ? Math.round((this.periodHigh / this.period) * 100) : 0;
        }

        this.lastTimestamp = microseconds;
      }
    }
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    speed: this.pinSpeed,
    direction: (this.pinDirection) ? 'left' : 'right',
  });

  update = (pinState) => {
    if (this.hardware && this.pin) {
      const pinInfo = this.hardware.getPinInfo(this.pin);
      const pins = this.pin.split(',');
      pins.map((pin, index) => {
        let startPin = 0;
        let pinNumber = parseInt(pin, 10);
        if (pin.toString().includes('A')) {
          pinNumber = parseInt(pin.toString()[1], 10);
        }
        if (pinNumber >= 8) {
          startPin = 8;
        }
        const state = (pinState >> (pinNumber - startPin)) & 0x01;
        if (pinInfo.signals?.[index][0]?.type === 'pwm') {
          if (state !== this.pinStatePWM) {
            if (state) {
              this.period = this.hardware.runner.cpu.cycles - this.periodTemp;
              this.periodTemp = this.hardware.runner.cpu.cycles;
              this.periodHighTemp = this.hardware.runner.cpu.cycles;
            } else {
              this.periodHigh = (this.period > 0) ? this.hardware.runner.cpu.cycles - this.periodHighTemp : 0;
              this.periodHighTemp = 0;
            }
          }
          this.pinStatePWM = state;
        } else {
          this.pinDirection = state;
        }
        return true;
      });
    }
  }

  reset = () => {
    this.pinDirection = 0;
    this.pinStatePWM = 0;
    this.pinSpeed = 0;
    this.lastTimestamp = 0;
  }

  render = () => (
    <MotorPWMElement
      ref={this.ref}
      id={this.id}
      rotation={this.rotation}
      direction={this.pinDirection}
      speed={this.pinSpeed}
      key={`motorPWM-${this.pin}`}
    />
  );
}
