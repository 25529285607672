import axios from 'axios';
import { installState } from './constants';


const SET_PORT = 'PORT/SET';
const SET_PORT_DELETE = 'PORT/REMOVE';
const SET_PORT_CLEAR = 'PORT/CLEAR';
const SET_PORT_PROGRESS = 'PORT/SET_PROGRESS';
const SET_PORT_ERROR = 'PORT/SET_ERROR';

const GET_MANIFEST = 'MANIFEST/GET';
const GET_MANIFEST_SUCCESS = 'MANIFEST/GET_SUCCESS';
const GET_MANIFEST_FAIL = 'MANIFEST/GET_FAIL';
const GET_MANIFEST_FILES = 'MANIFEST/GET_FILES';
const GET_MANIFEST_FILES_SUCCESS = 'MANIFEST/GET_FILES_SUCCESS';
const GET_MANIFEST_FILES_FAIL = 'MANIFEST/GET_FILES_FAIL';

const SET_INSTALL_STATE = 'INSTALL/SET_STATE';

/**
 * portList: [{
 *  button: String,
 *  port: SerialPort,
 *  progress: number,
 *  error: String,
 * }]
 */
export const initialState = {
  manifest: {},
  manifestFiles: [],
  manifestFilesTotalSize: 0,
  portList: [],
  error: '',
  installState: installState.IDLE,
  loading: false,
};


export default function firmwareReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INSTALL_STATE: {
      const { newState, error } = action.payload;
      return {
        ...state,
        installState: newState,
        error,
      };
    }
    case SET_PORT: {
      const { button, port } = action.payload;
      const ports = state.portList;
      ports.push({ button, port, progress: 0, error: '' });
      return {
        ...state,
        portList: ports,
      };
    }
    case SET_PORT_DELETE: {
      const { button } = action.payload;
      const ports = state.portList.map((p) => {
        const newPort = p;
        if (p.button === button) {
          newPort.port = null;
        }
        // let newPort = {};
        // if (p.button !== button) {
        //   newPort = p;
        // }
        return newPort;
      });
      return {
        ...state,
        portList: ports,
      };
    }
    case SET_PORT_CLEAR: {
      return {
        ...state,
        portList: [],
      };
    }
    case SET_PORT_PROGRESS: {
      const { button, progress } = action.payload;
      const ports = state.portList.map((p) => {
        const newPort = p;
        if (newPort.button === button) {
          newPort.progress = parseInt(progress, 10);
        }
        return newPort;
      });
      return {
        ...state,
        portList: ports,
      };
    }
    case SET_PORT_ERROR: {
      const { button, error } = action.payload;
      const ports = state.portList.map((p) => {
        const newPort = p;
        if (newPort.button === button) {
          newPort.error = error;
        }
        return newPort;
      });
      return {
        ...state,
        portList: ports,
      };
    }
    case GET_MANIFEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MANIFEST_SUCCESS: {
      const { manifest } = action.payload;
      return {
        ...state,
        loading: false,
        manifest,
      };
    }
    case GET_MANIFEST_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
        manifest: {},
      };
    }
    case GET_MANIFEST_FILES: {
      return {
        ...state,
        loading: true,
        manifestFiles: [],
      };
    }
    case GET_MANIFEST_FILES_SUCCESS: {
      const { manifestFile, manifestFilesTotalSize } = action.payload;
      const manifestFiles = state.manifestFiles || [];
      manifestFiles.push(manifestFile);
      return {
        ...state,
        loading: false,
        manifestFilesTotalSize,
        manifestFiles,
      };
    }
    case GET_MANIFEST_FILES_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
        manifestFiles: [],
      };
    }
    default:
      return state;
  }
}


// selectors
export const selectFirmware = (state) => state.firmware;


// actions
export const addPort = (button, port) => (dispatch) => {
  dispatch({
    type: SET_PORT,
    payload: { button, port },
  });
};

export const removePort = (button) => (dispatch) => {
  dispatch({
    type: SET_PORT_DELETE,
    payload: { button },
  });
};

export const clearPorts = () => (dispatch) => {
  dispatch({
    type: SET_PORT_CLEAR,
    payload: {},
  });
};

export const setPortProgress = (button, progress) => (dispatch) => {
  dispatch({
    type: SET_PORT_PROGRESS,
    payload: { button, progress },
  });
};

export const setPortError = (button, error) => (dispatch) => {
  dispatch({
    type: SET_PORT_ERROR,
    payload: { button, error },
  });
};

export const setInstallState = (newState, error = '') => (dispatch) => {
  dispatch({
    type: SET_INSTALL_STATE,
    payload: { newState, error },
  });
};

const downloadManifestSuccess = (dispatch) => (manifestData) => dispatch({
  type: GET_MANIFEST_SUCCESS,
  payload: {
    manifest: manifestData.data,
  },
});

const downloadManifestFail = (dispatch) => (error) => {
  dispatch({
    type: GET_MANIFEST_FAIL,
    payload: {
      error: 'No pudimos descargar el firmware',
    },
  });
};

export const downloadManifest = (url) => (dispatch) => {
  dispatch({ type: GET_MANIFEST });
  axios
    .get(url, { withCredentials: false })
    .then(downloadManifestSuccess(dispatch))
    .catch(downloadManifestFail(dispatch));
};


export const downloadManifestFiles = (parts) => async (dispatch) => {
  dispatch({ type: GET_MANIFEST_FILES });
  const filePromises = parts.map(async (part) => {
    const url = new URL(part.path, window.location.toString()).toString();
    const resp = await fetch(url);
    if (!resp.ok) {
      dispatch({
        type: GET_MANIFEST_FILES_FAIL,
        payload: {
          error: 'Falló la descarga del firmware',
        },
      });
      throw new Error(
        `Falló la descarga del archivo ${part.path}: ${resp.status}`,
      );
    }
    const reader = new FileReader();
    const blob = await resp.blob();

    return new Promise((resolve) => {
      reader.addEventListener('load', () => resolve(reader.result));
      reader.readAsBinaryString(blob);
    });
  });

  let totalSize = 0;
  filePromises.forEach(async (filePromise, part) => {
    try {
      const data = await filePromise;
      totalSize += data.length;
      dispatch({
        type: GET_MANIFEST_FILES_SUCCESS,
        payload: {
          manifestFilesTotalSize: totalSize,
          manifestFile: { data, address: parts[part].offset },
        },
      });
    } catch (err) {
      dispatch({
        type: GET_MANIFEST_FILES_FAIL,
        payload: {
          error: `Falló la descarga del archivo ${parts[part].path}: No pudimos descargar el firmware`,
        },
      });
    }
  });
};
