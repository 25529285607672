/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import './rightPanelStyle.scss';

class RightPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
      children: props.children,
      panelDimensions: props.panelDimensions,
    };
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      children: newprops.children,
      panelDimensions: newprops.panelDimensions,
      status: newprops.status,
    });
  }

  togglePanel() {
    let { status } = this.state;
    status = (status === 0) ? 1 : 0;
    this.setState({ status });
  }

  render() {
    const { status, children, panelDimensions } = this.state;
    return (
      <div className={`right-panel ${panelDimensions} ${(status === 1) ? 'is-active' : 'is-hidden'}`}>
        <div className="row">
          <div className="col-12">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default RightPanel;
