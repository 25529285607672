import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './tutorialProgress.mod.scss';

const TutorialProgress = (props) => {
  const onNextStep = props.onNextStep || null;
  const onPrevStep = props.onPrevStep || null;
  const customStyle = props.customStyle || null;
  const currentStep = props.currentStep || 0;
  const totalStep = props.totalStep || 0;
  const showStepsCount = props.showStepsCount || false;
  const showStepArrows = typeof props.showStepArrows !== 'undefined' ? props.showStepArrows : true;
  const progress = (totalStep > 0) ? (currentStep * 100) / totalStep : 0;

  const nextStep = () => {
    if (onNextStep) {
      onNextStep();
    }
  }

  const prevStep = () => {
    if (onPrevStep) {
      onPrevStep();
    }
  }

  return (
    <div className={styles.tutorialProgress}>
      {showStepArrows ? (
        <div className={`${styles.prevStep} ${(!onPrevStep) ? styles.disabled : ''}`} onClick={prevStep}>&lt;</div>
      ) : ('')}
      <LinearProgress className={(customStyle) ? customStyle : styles.progress} variant="determinate" value={progress} />
      {showStepArrows ? (
        <div className={`${styles.nextStep} ${(!onNextStep) ? styles.disabled : ''}`} onClick={nextStep}>&gt;</div>
      ) : ('')}
      {showStepsCount ? (
        <div className={styles.progressText}><strong>{currentStep}</strong>/{totalStep}</div>
      ) : ('')}
    </div>
  );
};

export default TutorialProgress;