import React, { useEffect, useState } from 'react';
import styles from './header.mod.scss';
import { useIntl } from 'react-intl';
import { getLogo } from '../../../cosmos';
import SessionSelector from '@sections/collaboration/components/sessionSelector';
import { differenceInMinutes, differenceInSeconds, addMinutes, addSeconds, parseISO, format } from 'date-fns';
import ReactTooltip from 'react-tooltip';


const Header = (props) => {
  const intl = useIntl();

  const [now, setNow] = useState(new Date());
  const [dateInterval, setDateInterval] = useState(null);
  const [levelIndicator, setLevelIndicator] = useState([]);
  const [sessionEnded, setSessionEnded] = useState(false);
  const [finishTime, setFinishTime] = useState(addMinutes(new Date(), 1));

  const remainingWarm = 5;
  const remainingHot = 1;
  const remainingMinutes = differenceInMinutes(finishTime, now);

  const onSessionEnds = () => {
    if (props.onSessionEnds) {
      props.onSessionEnds();
    }
  }

  const getElapsedTime = () => {
    let remaining = intl.formatMessage({ id: 'collaboration.rightPanel.remainingTimeEnded' });
    if (props.collabState?.collaboration?.collaborationSessionStart && props.collabState?.collaboration?.collaborationSessionDuration) {
      const difference = differenceInSeconds(finishTime, now);
      if (difference <= 0) {
        onSessionEnds();
      }
      if (finishTime >= now) {
        remaining = (<div className={styles.minutes}>{format(addSeconds(new Date(0), difference), 'mm:ss')}</div>);
      }
    }
    return remaining;
  }


  const getLevelIndicator = (level) => {
    let indicator = '';
    let currentStep = 0;
    currentStep = props.collabState?.tutorial?.tutorialCurrentStep?.step || 0;
    if (level.step < currentStep) {
      indicator = (<div className={styles.col}><div className={`${styles.unselected} ${styles.completed}`} /></div>);
    }
    if (level.step === currentStep) {
      if (level.canvasCode && level.canvasCode !== 'null') {
        indicator = (<div className={styles.col}>
          <div className={styles.selected}>{level.step}</div>
        </div>);
      } else {
        indicator = (<div className={styles.col}><div className={styles.star}><img alt="start" src="/images/collaboration/star_selected.png" /></div></div>);
      }
    }
    if (level.step > currentStep) {
      if (level.canvasCode && level.canvasCode !== 'null') {
        indicator = (<div className={styles.col}><div className={styles.unselected} /></div>);
      } else {
        indicator = (<div className={styles.col}><div className={styles.star}><img alt="start" src="/images/collaboration/star_unselected.png" /></div></div>);
      }
    }
    return indicator;
  }


  useEffect(() => {
    if (props.collabState?.collaboration?.collaborationSessionStart && props.collabState?.collaboration?.collaborationSessionDuration) {
      setFinishTime(addMinutes(new Date(parseISO(props.collabState?.collaboration?.collaborationSessionStart || '')), props.collabState?.collaboration?.collaborationSessionDuration || 0));
    }
  }, [props.collabState?.collaboration?.collaborationSessionStart]);


  useEffect(() => {
    let indicator = [];
    props.collabState?.levels.map((level) => {
      indicator.push(getLevelIndicator(level));
    });
    setLevelIndicator(indicator);
  }, [props.collabState?.tutorial?.tutorialCurrentStep]);


  useEffect(() => {
    setDateInterval(setInterval(() => setNow(new Date()), 1000));

    return function cleanup() {
      clearInterval(dateInterval);
    }
  }, []);


  useEffect(() => {
    setSessionEnded(props.sessionEnded);
  }, [props.sessionEnded]);


  return (
    <div className={styles.header}>
      <ReactTooltip backgroundColor="#000" place="bottom" type="dark" effect="solid" />
      <div className={styles.logoContainer}>
        <img alt="Educabot" src={getLogo()} />
      </div>

      {(props.sessionSelectorActive) && (
        <SessionSelector
          projectId={props.projectId || 0}
          session={props.collabSessionState?.session || []}
        />
      )}

      {(!sessionEnded && props.projectId) ? (
        <>
          {(props.collabState?.collaboration?.collaborationTutorial) ? (
            <div className={styles.tutorialProgress} data-tip={intl.formatMessage({ id: 'collaboration.tutorial.goals' })}>
              {levelIndicator.length > 0 ? `${intl.formatMessage({ id: 'collaboration.tutorial.goal' })}:` : ''}
              {levelIndicator.map(indicator => indicator)}
            </div>
          ) : (
            <div className={styles.tutorialProgress} />
          )}

          <div className={styles.remainingTime} data-tip={intl.formatMessage({ id: 'collaboration.rightPanel.remainingTime' })}>
            {(props.collabState?.collaboration?.collaborationSessionStart && props.collabState?.collaboration?.collaborationSessionDuration) && (
              <>
                {(remainingMinutes < remainingWarm && remainingMinutes >= remainingHot) && (
                  <img alt="time" className={`${styles.icon} ${styles.iconRemaining5}`} src="/images/collaboration/remainingTime_5.svg" />
                )}
                {(remainingMinutes < remainingHot) && (
                  <img alt="time" className={`${styles.icon} ${styles.iconRemaining1}`} src="/images/collaboration/remainingTime_1.svg" />
                )}
                {(remainingMinutes >= remainingWarm) && (
                  <img alt="time" className={styles.icon} src="/images/collaboration/remainingTime.svg" />
                )}
                <div className={`${styles.time} ${(remainingMinutes < remainingWarm && remainingMinutes >= remainingHot) ? `${styles.remaining5}` : ((remainingMinutes < remainingHot) ? `${styles.remaining1}` : '')}`}>
                  {getElapsedTime()}
                </div>
              </>
            )}
          </div>

          <div className={styles.toolsPlaceHolder} />
        </>
      ) : (
        <>
          <div className={styles.tutorialProgress} />
          <div className={styles.remainingTime} />
          <div className={styles.toolsPlaceHolder} />
        </>
      )}
    </div>
  );
};


export default Header;