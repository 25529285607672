import React from 'react';
import ProfileSelector from '@modules/profileSelector/components/profileSelector';
import ProfileSelectorProvider from '../features/context';

// use memo for prevent re renders emited from his HOC component
const ProfileSelectorWrapper = React.memo((props) => {
  // se crea un nuevo proyecto
  const addTab = props.addTab;
  // indica que tab debe estar seleccionado
  const selectActiveTab = props.selectActiveTab;
  // al invocarse actualiza cambios de form, esto luego es empleado para definir el tipo de proyecto app.js, crear nuevo a partir de un board
  const onBoardItemClick = props.onBoardItemClick;
  // se invoca cuando se ingresan valores en los campos de codigo o contraseña, crear un nuevo proyecto a partir de uno previamente almacenado
  const changeField = props.changeField;
  // inicializa un nuevo proyecto
  const onStoredProjectClick = props.onStoredProjectClick;

  return (
    <ProfileSelectorProvider>
      <ProfileSelector
        addTab={addTab}
        changeField={changeField}
        selectActiveTab={selectActiveTab}
        onBoardItemClick={onBoardItemClick}
        onStoredProjectClick={onStoredProjectClick}
      />
    </ProfileSelectorProvider>
  );
});

export default ProfileSelectorWrapper;
