import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        movable: false,
        type: 'codit_initial_block',
        x: 50,
        y: 50,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>

    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <block type="codit_wait" />
      <block type="codit_wait_to" />
    </category>

    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_ACTION}">
      <block type="codit_motor" />
      <block type="codit_light" />
      <block type="codit_color" />
      <block type="codit_music" />
      <block type="codit_talk" />
    </category>

    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_MOTION}">
      <block type="codit_forward" />
      <block type="codit_backward" />
      <block type="codit_left" />
      <block type="codit_right" />
      <block type="codit_forward_3" />
      <block type="codit_shake" />
    </category>

  </xml>
);
/* eslint-enable */
