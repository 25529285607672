import { toast } from 'react-toastify';
import { TOAST_TYPE } from './constants';

export const SHOW_TOAST = 'TOAST/SHOW';
export const HIDE_TOAST = 'TOAST/HIDE';
export const HIDE_ALL_TOAST = 'TOAST/HIDE_ALL';

export const showToast = ({ title, message, ref = undefined, type = TOAST_TYPE.DEFAULT, options = {} }) => {
  const position = options.position || toast.POSITION.BOTTOM_CENTER;
  const className = type ? `toast-new toast-type-${type}` : 'toast-new';
  const containerId = 'E';
  return {
    type: SHOW_TOAST,
    payload: {
      ref,
      type,
      title,
      message,
      options: {
        position,
        className,
        containerId,
        autoClose: false,
        hideProgressBar: true,
        ...options,
      },
    },
  };
};

export const hideToast = (ref) => ({
  type: HIDE_TOAST,
  payload: {
    ref,
  },
});

export const hideAllToast = () => ({
  type: HIDE_ALL_TOAST,
});
