/* eslint no-loop-func: 0 */
import * as Blockly from 'blockly';


export class BoardVersionChecker {
  workspace = null;

  port = '';

  portList = [];

  boardVersionList = {
    // butyV2: '0x2341-0x0043', // TEST: arduino uno board
    butyV2: '0x1A86-0x7523',
  }

  constructor(workspace, portList = []) {
    this.workspace = workspace;
    this.portList = portList;
  }

  setPort = (port = '') => {
    this.port = port;
    this.chageBoardVersion();
  };

  chageBoardVersion = () => {
    if (this.workspace) {
      this.workspace.currentBoardVersion = {};
      for (let i = 0; i < this.portList.length; i += 1) {
        if (this.portList[i].Name === this.port) {
          const pId = `${this.portList[i].VendorID}-${this.portList[i].ProductID}`;
          const pKey = Object.keys(this.boardVersionList).filter((key) => this.boardVersionList[key] === pId)[0];
          if (this.boardVersionList[pKey]) {
            this.workspace.currentBoardVersion = {
              [pKey]: this.boardVersionList[pKey],
            };
          }
        }
      }
      this.workspace.fireChangeListener({
        type: 'boardVersionChange',
        workspaceId: this.workspace.id,
      });
    }
  };
}
