import * as Blockly from 'blockly/core';


export const moveWorkspaceBy = (workspace, dx = 0, dy = 0) => {
  if (workspace) {
    const metrics = workspace.getMetrics();
    if (metrics && workspace.scrollbar) {
      let a = dx;
      let b = dy;
      a = Math.min(a, -metrics.contentLeft);
      b = Math.min(b, -metrics.contentTop);
      a = Math.max(a, metrics.viewWidth - metrics.contentLeft - metrics.contentWidth);
      b = Math.max(b, metrics.viewHeight - metrics.contentTop - metrics.contentHeight);
      workspace.scrollbar.set(a - metrics.contentLeft, b - metrics.contentTop)
    }
  }
};

export const resizeWorkspace = (workspace, containerId = 'blocklyContainer') => {
  const blocklyContainer = document.getElementById(containerId);
  if (blocklyContainer) {
    const blocklyArea = blocklyContainer.parentElement;
    let element = blocklyContainer.parentElement;
    let x = 0;
    let y = 0;
    do {
      x += element.offsetLeft;
      y += element.offsetTop;
      element = element.offsetParent;
    } while (element);
    blocklyContainer.style.left = `${x}px`;
    blocklyContainer.style.top = `${y}px`;
    blocklyContainer.style.width = `${blocklyArea.offsetWidth}px`;
    blocklyContainer.style.height = `${blocklyArea.offsetHeight}px`;
    Blockly.svgResize(workspace);
  }

  moveWorkspaceBy(workspace, 0, 0);
};
