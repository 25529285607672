import React, { Component } from 'react';
// import { ButtonElement } from '../components/button/button';
import '../components/button/button_lit';
import { rotatePort } from '../utils';

export class Button extends Component {
  constructor(hardware, pin, id = 'Button', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.pinState = true;
    this.variant = variant || {};
    this.pinInfo = [
      { name: '1.L', x: -3, y: 17, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 57,
      height: 34,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.runSetup = this.runSetup.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.stateHandler = this.stateHandler.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runSetup = () => {
    this.hardware.writeDigitalPin(this.pin, this.pinState);
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => (this.pinState);

  update = (newPinState) => {
    // this.pinState = Boolean(newPinState);
    // console.log('============update', this.pinState, this.ref.current);
  }

  stateHandler = (pinState) => {
    if (this.hardware && this.pin && this.pinState !== !(pinState) && this.ref.current) {
      this.pinState = !(pinState);
      this.hardware.writeDigitalPin(this.pin, this.pinState);
    }
  }

  reset = () => {
    this.pinState = true;
    this.hardware.writeDigitalPin(this.pin, this.pinState);
  }

  // render = () => <ButtonElement ref={this.ref} key={`button-${this.pin}`} stateHandler={this.stateHandler} />;
  render = () => {
    if (this.ref.current) {
      this.ref.current.addEventListener('button-press', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.stateHandler(true);
      });
      this.ref.current.addEventListener('button-release', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.stateHandler(false);
      });
    }

    return <educabot-pushbutton id={this.id} rotation={this.rotation} ref={this.ref} key={`button-${this.pin}`} />;
  };
}
