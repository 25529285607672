import React, { useState, useEffect } from 'react';
import styles from './sessionTurnsThumbnails.mod.scss';
import CanvasSVG from '@modules/blockly/components/CanvasSVG';
import { userRoles } from '../features/constants';
import UserAvatar from '@modules/users/components/userAvatar';
import { addMinutes, parseISO, differenceInSeconds } from 'date-fns';

const SessionTurnsThumbnails = (props) => {
  const [config, setConfig] = useState(props.config || {});

  useEffect(() => {
    setConfig(props.config);
  }, [props.config]);

  // if (props.session) {
  //   props.session.map(session => {
  //     console.log('==========date', session.title, session.collaborationSessionStart, format(new Date(parseISO(session.collaborationSessionStart || '')), 'HH:mm'));
  //     const now = new Date();
  //     const finishTime = addMinutes(new Date(parseISO(session.collaborationSessionStart || '')), session.collaborationSessionDuration || 0);
  //     console.log('==========finished', finishTime < now);
  //   });
  // }
  
  const isTurnFinished = (collaborationSessionStart, collaborationSessionDuration) => {
    const now = new Date();
    const finishTime = addMinutes(new Date(parseISO(collaborationSessionStart || '')), collaborationSessionDuration || 0);
    const diffSeconds = differenceInSeconds(finishTime, now);
    return (diffSeconds < 0 || diffSeconds > collaborationSessionDuration * 60);
  };

  const getNoReservationImage = (id) => {
    let image = 'no_reservation.png';
    config.scenarios.map(c => {
      if (c.noReservationImage && c.id === id) {
        image = c.noReservationImage
      }
    });
    return image;
  }

  const getSession = (id, uniqueStr) => {
    let html = '';
    props.session.map(session => {
      if (session.id === id) {
        html = (
            <div key={`divCanvas-${session.id}-${uniqueStr}`} className={styles.canvas} onKeyPress={() => { }}>
              <div className={styles.panelHeader}>
                <div className={styles.headerIcon}><img alt="time" className={styles.avatar} src="/images/collaboration/experienceIcon.svg" /></div>
                <div>
                  <div className={styles.headerTitle}>{session.title}</div>
                  <div className={styles.myTeam}>
                    <div className={styles.avatarContainer}>
                      {(!isTurnFinished(session.collaborationSessionStart, session.collaborationSessionDuration)) ? (
                        (props.teamList[session.id] && props.teamList[session.id].map((u, index) => {
                          if (u.userRole === userRoles.STUDENT || u.userRole === userRoles.LEADER) {
                            return (
                              <UserAvatar
                                key={`avatar-${u.User.id}`}
                                firstName={u.User.firstName}
                                lastName={u.User.lastName}
                                onLine={u.onLine}
                                color={u.color}
                                id={index}
                                style={styles.avatarBackground}
                                avatarUrl={u.User.avatarUrl}
                              />
                            )
                          }
                        }))
                      ) : (
                        <div className={styles.subtitle}>Experiencia Maqueta</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isTurnFinished(session.collaborationSessionStart, session.collaborationSessionDuration) ? (
                <div className={styles.noReservation}>
                  <div><img src={`/images/cosmos/createc/${getNoReservationImage(id)}`} /></div>
                  <div className={styles.message}>Actualmente este escenario está en modo demo.</div>
                </div>
              ) : (
                <CanvasSVG
                  id={`canvas-${session.id}-${uniqueStr}`}
                  content={`${session.blocksCss}${session.canvasCss}${session.jsonBody.canvas}`}
                  svgX={session.jsonBody.canvasBBoxX || 10}
                  svgY={session.jsonBody.canvasBBoxY || 10}
                  svgWidth={session.jsonBody.canvasBBoxWidth || 800}
                  svgHeight={session.jsonBody.canvasBBoxHeight || 500}
                  width="100%"
                  height="100%"
                  backgroundColor=""
                />
              )}
            </div>
          );
      }
    });
    return html;
  };

  return (
    <div className={styles.sessionTurnsThumbnails}>
      {config.scenarios && config.scenarios.map(c => getSession(c.id, c.noReservationImage))}
    </div>
  );
};

export default SessionTurnsThumbnails;