import React, { useState, useEffect } from 'react';
import { PadlockIcon, CircularCheckIcon } from '@assets/icons';
import { isElectron } from '@utils';
import LabelChip from '@components/chips/labelChip';
import { useTheme } from '@utils/hooks/theme';
import { getBoardCompanyColors } from '@utils/theme';
import Text from '@components/text';
import { useUserIsPremium } from '@utils/hooks';
import { useBoardsBySeniority } from '@modules/boards/features/hooks';
import styles from './boardItem.mod.scss';

const ELEMENT_TYPE = {
  KIT: 'kit',
  BOOK: 'book',
  ROBOT: 'robot',
  DRONE: 'drone',
  BOARD: 'board',
  MODEL: 'model',
};

const checkIfBoardIsDisabled = (board, userIsPremium) => Boolean(board.enabled === 0 || (board.premium && !userIsPremium));
const getAllBoardsWithSameGroupName = (boards, groupName) => boards.filter((board) => board.groupName === groupName);

function BoardGrid({ boards, displayPageBack, showPageBack, onBoardItemClick }) {
  const theme = useTheme();
  const boardsBySeniority = useBoardsBySeniority();
  const userIsPremium = useUserIsPremium();
  const [currentBoards, setBoards] = useState([]);
  const [boardSelected, setBoardSelected] = useState(0);
  const [boardMouseOverdId, setboardMouseOverdId] = useState(-1);

  useEffect(() => {
    if (displayPageBack) return;
    setBoards(boards);
  }, [boards]);

  useEffect(() => {
    if (displayPageBack) {
      setBoardSelected(0);
    }
  }, [displayPageBack]);

  const onBoardItemMouseOver = (itemKey, isDisabled) => {
    if (isDisabled) return;
    setboardMouseOverdId(itemKey);
  };

  const onBoardItemMouseLeave = (isDisabled) => {
    if (isDisabled) return;
    setboardMouseOverdId(-1);
  };

  const onBoardClick = (board, itemKey, isDisabled) => {
    if (isDisabled) return;
    const groupedBoards = getAllBoardsWithSameGroupName(boardsBySeniority.boards, board.groupName);
    if (groupedBoards.length > 1) {
      setBoards(groupedBoards);
      showPageBack();
    }

    onBoardItemClick({
      ...board,
      boardId: board.id,
    });

    setBoardSelected(itemKey);
  };

  return currentBoards.map((board, key) => {
    const isBook = (board.elementType === ELEMENT_TYPE.BOOK || board.elementType === ELEMENT_TYPE.MODEL);
    const isMouseOver = key === boardMouseOverdId;
    const isSelected = key === boardSelected;
    const isSelectedOrIsHover = isMouseOver || isSelected;
    const isDisabled = checkIfBoardIsDisabled(board, userIsPremium);
    const boardCompanyNameLower = board.company.toLocaleLowerCase();
    const imagesPath = `images/profiles/${board.image}`;
    const boardImageUrl = isElectron() ? `./${imagesPath}` : `/${imagesPath}`;
    return (
      <div key={board.id} className={styles.boardContainer}>
        <div
          className={styles.boardWrapper}
          onClick={() => onBoardClick(board, key, isDisabled)}
          onMouseOver={() => onBoardItemMouseOver(key, isDisabled)}
          onMouseLeave={() => onBoardItemMouseLeave(isDisabled)}
          style={{ background: isSelectedOrIsHover
            ? getBoardCompanyColors(theme, boardCompanyNameLower)
            : 'transparent' }}
        >
          <div className={isDisabled ? styles.boardWrapperDisabled : styles.boardWrapper}>
            <div className={styles.boardContentContainer}>
              <div className={styles.boardHeadContainer}>
                <div className={styles.boardHeadStart} />
                <div className={isSelected ? styles.boardHead : styles.boardHeadSelected}>
                  {isSelected && <CircularCheckIcon />}
                  {isDisabled && <PadlockIcon />}
                </div>
              </div>
              <div className={styles.boardImageContainer}>
                <img
                  className={isBook
                    ? styles.boardImageBook
                    : styles.boardImage}
                  src={boardImageUrl}
                  alt="board"
                />
              </div>
            </div>
            <div className={styles.boardInfoContainer}>
              <div className={styles.boardInfoWrapper}>
                <div className={styles.boardNameWrapper}>
                  <Text
                    className={styles.boardName}
                    style={{
                      color: isSelectedOrIsHover
                        ? 'var(--color-white)'
                        : 'var(--color-on-surface)',
                    }}
                  >
                    {/* {isBook && board.groupName} */}
                  </Text>
                  <Text className={styles.boardGroup}>
                    {(isBook && !displayPageBack) ? board.groupName : board.name}
                  </Text>
                </div>
                <div className={styles.boardCompanyWrapper}>
                  <div className={styles.boardCompany}>
                    <LabelChip
                      classNames={{
                        container: isSelectedOrIsHover ? styles.boardCompanyChipContainer : '',
                        label: isSelectedOrIsHover ? styles.boardCompanyChipLabel : '',
                      }}
                      color={getBoardCompanyColors(theme, boardCompanyNameLower)}
                      label={board.company}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
}

export default BoardGrid;
