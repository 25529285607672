import React, { useEffect, useState } from 'react';
import styles from './turns.mod.scss';
import SessionTurnsThumbnails from '@sections/collaboration/components/sessionTurnsThumbnails';
import TurnsFooter from '@sections/collaboration/components/turnsFooter';
import TurnsHeader from '@sections/collaboration/components/turnsHeader';
import {
  useGetCollaborationSession,
  useGetCollaborationSessionUsers,
  useSetSocketListeners,
  useUpdateSessionProjectState,
  useUpdateTurnsInterval,
  useRefreshOnwerSession,
  useCollaboration,
} from '@services/rest/collaboration/hooks';
import { userRoles } from '../features/constants';
import WebSocketClient from '../../../helpers/WebSocketClient';

const Turns = (props) => {
  const [projectIds, setProjectIds] = useState([]);
  const [socketClient, setSocketClient] = useState(null);
  const [teamList, setTeamList] = useState([]);

  const config = {
    userId: (process.env.NODE_ENV === 'production') ? 7560 : 12,
    scenarios: [
      {
        // id: 12331,
        id: (process.env.NODE_ENV === 'production') ? 12331 : 1,
        noReservationImage: 'no_reservation_planetario.png',
      },
      {
        // id: 12332,
        id: (process.env.NODE_ENV === 'production') ? 12332 : 2,
        noReservationImage: 'no_reservation_obelisco.png',
      },
      {
        // id: 12333,
        id: (process.env.NODE_ENV === 'production') ? 12333 : 1,
        noReservationImage: 'no_reservation_retiro.png',
      },
      {
        // id: 12334,
        id: (process.env.NODE_ENV === 'production') ? 12334 : 2,
        noReservationImage: 'no_reservation_madero.png',
      },
      {
        // id: 12335,
        id: (process.env.NODE_ENV === 'production') ? 12335 : 1,
        noReservationImage: 'no_reservation_floralis.png',
      },
    ]
  };

  const colors = ['#FF000E', '#05FF00', '#FF00E5', '#28FFBF', '#9B00AF', '#28DF99', '#FFCC29', '#C4C4C4', '#FF9DA3', '#04009A'];
  const userId = config.userId || 0;
  const collabSessionState = useGetCollaborationSession(userId);
  const collabSessionUsersState = useGetCollaborationSessionUsers(projectIds);
  const collabState = useCollaboration();
  const { updateSessionProjectState } = useUpdateSessionProjectState();
  useSetSocketListeners(socketClient?.socket);
  useUpdateTurnsInterval(userId, 1000 * 10);
  useRefreshOnwerSession(userId || null, true, 1000 * 1200);

  useEffect(() => {
    zE('webWidget', 'hide');
  }, []);

  useEffect(() => {
    setProjectIds(collabSessionState?.session?.map(s => s.id) || []);
  }, [collabSessionState?.session]);

  useEffect(() => {
    if (!socketClient && collabSessionState?.session?.length > 0) {
      const client = new WebSocketClient({ rooms: collabSessionState.session.map(s => s.id), userId, role: userRoles.TEACHER });
      setSocketClient(client);
    }
  }, [userId, collabSessionState?.session]);

  useEffect(() => {
    if (collabState.collaboration.id && collabSessionState?.session?.length > 0) {
      updateSessionProjectState({ id: collabState.collaboration.id, jsonBody: collabState.collaboration.jsonBody });
    }
  }, [collabState?.collaboration?.jsonBody]);

  useEffect(() => {
    if (collabSessionUsersState) {
      const teamArray = [];
      for (let i = 0; i < collabSessionUsersState.length; i += 1) {
        if (!teamArray[collabSessionUsersState[i].id]) {
          teamArray[collabSessionUsersState[i].id] = [];
        }
        for (let j = 0; j < collabSessionUsersState[i].ProjectsCollabUsers.length; j += 1) {
          teamArray[collabSessionUsersState[i].id].push({
            ...collabSessionUsersState[i].ProjectsCollabUsers[j],
            color: colors[j] || '#FFF200',
            onLine: true,
          })
        }
      }
      setTeamList(teamArray);
    }

  }, [collabSessionUsersState]);


  return (
    <div className={styles.turns}>
      <TurnsHeader
        sessions={collabSessionState?.session || []}
      />
      <SessionTurnsThumbnails
        config={config}
        session={collabSessionState?.session || []}
        teamList={teamList || []}
      />
      <TurnsFooter />
      <div className={styles.backgroundLeft}><img alt="time" src="/images/cosmos/createc/background_left.png" /></div>
      <div className={styles.backgroundRight}><img alt="time" src="/images/cosmos/createc/background_right.png" /></div>
    </div>
  )
};


export default Turns;