import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="BHOOT_initial_block" x="50" y="50" deletable="false" movable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category name="%{BKY_LANG_CATEGORY_LCD}" id="Pantallas" iconclass="screens" expandedclass="screens-extended">
      <block type="BHOOT_matrix_8x8_junior" />
      {/* <block type="BHOOT_matrix_8x8">
        <value name="CHOICE">
          <shadow type="BHOOT_matrix_8x8_faces">
            <field name="CHOICE">1</field>
          </shadow>
        </value>
      </block> */}
      {/* <block type="BHOOT_matrix_8x8_off" /> */}
      <block type="show_string">
        <value name="STR" type="input_value">
          <shadow type="show_string_event">
            <field name="STR">TEXTO</field>
          </shadow>
        </value>
      </block>
    </category>

    <category name="%{BKY_LANG_CATEGORY_MOTION}" id="Movimiento" iconclass="motors" expandedclass="motors-extended">
      <block type="BHOOT_motor_forward">
        <value name="NUM" type="input_value">
          <shadow type="math_whole_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_motor_backward">
        <value name="NUM" type="input_value">
          <shadow type="math_whole_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_motor_right">
        <value name="NUM" type="input_value">
          <shadow type="math_whole_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_motor_left">
        <value name="NUM" type="input_value">
          <shadow type="math_whole_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      {/* <block type="BHOOT_motor_left" />
      <block type="BHOOT_motor_right" />
      <block type="BHOOT_motor_backward" />
      <block type="BHOOT_motor_forward" /> */}

      <block type="BHOOT_line_follower_jr" />

      {/* <block type="BHOOT_obstacle_avoidance">
        <value name="CHOICE">
          <shadow type="BHOOT_dropdown_distance">
            <field name="CHOICE">5</field>
          </shadow>
        </value>
      </block> */}
    </category>

    <category id="Salidas" name="%{BKY_LANG_CATEGORY_ACTIONS}" iconclass="outputs" expandedclass="outputs-extended">
      <block type="BHOOT_play_tone">
        <value name="CHOICE">
          <shadow type="BHOOT_dropdown_tones">
            <field name="CHOICE">261</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led">
        <value name="CHOICE">
          <shadow type="BHOOT_dropdown_colours">
            <field name="CHOICE">255, 255, 0</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_off" />
    </category>

    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <block type="BHOOT_repeat_forever" />
      <block type="BHOOT_pause_millis">
        <value name="CHOICE">
          <shadow type="BHOOT_dropdown_1_9_seconds">
            <field name="CHOICE">1000</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_pause_smart">
        <value name="CHOICE">
          <shadow type="BHOOT_dropdown_smart">
            <field name="CHOICE">1000</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_control_loop_number">
        <value name="CHOICE">
          <shadow type="BHOOT_dropdown_1_9_numbers">
            <field name="CHOICE">2</field>
          </shadow>
        </value>
      </block>

    </category>


  </React.Fragment>
);
/* eslint-enable */
