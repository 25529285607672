/* eslint no-underscore-dangle: 0 */
/* eslint-disable */
import * as Blockly from 'blockly';
import ConstantProvider from './constants';
import RenderInfo from './info';
import Drawer from './drawer';


class Junior extends Blockly.blockRendering.Renderer {
  constructor() {
    super();
  }

  makeConstants_() {
    return new ConstantProvider();
  }

  makeRenderInfo_(block) {
    return new RenderInfo(this, block);
  }

  makeDrawer_(block, info) {
    return new Drawer(block, info);
  }

}

Blockly.blockRendering.register('junior', Junior);
