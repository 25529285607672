/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import './SelectBoards.scss';

class SelectBoards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      board: props.defaultBoard,
      boards: props.boards || [],
      id: props.id,
      parentPanel: props.parentPanel,
      disabled: props.disabled,
    };
    this.handleChangeBoard = this.handleChangeBoard.bind(this);
  }

  handleChangeBoard(event) {
    let { board } = this.state;
    const { parentFunctionListener } = this.props;

    board = event.currentTarget.value;
    parentFunctionListener(board);

    this.setState({ board });
  }

  render() {
    const { board, boards, id, parentPanel, disabled } = this.state;
    const { intl } = this.props;
    return (
      <React.Fragment>
        <span className="selectBoardsLabel">{`${intl.formatMessage({ id: 'blocks.blockly.boardSelected' })}:`}</span>
        <select disabled={disabled} name={id} id={id} className="selectBoards form-control" value={board} onChange={this.handleChangeBoard}>
          {parentPanel === 'bloques' && boards.map(item => (item.enabled) && <option key={`bloques-${item.id}-${item.name}`} id={item.id} value={item.id}>{item.name}</option>)}
          {parentPanel === 'code' && boards.map(item => (item.enabledForCode) && <option key={`code-${item.id}-${item.name}`} id={item.id} value={item.id}>{item.name}</option>)}
        </select>
      </React.Fragment>
    );
  }
}

export default injectIntl(SelectBoards);
