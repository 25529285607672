/* eslint-disable max-len */
import React from 'react';

function UserRights() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M 15 4 A 4 4 0 0 0 15 12 A 4 4 0 0 0 15 4 z M 33 10 C 28.589 10 25 13.589 25 18 C 25 20.959 26.619625 23.542781 29.015625 24.925781 L 29.015625 37 C 29.015625 37.277 29.124313 37.542281 29.320312 37.738281 L 32.275391 40.693359 C 32.683391 41.101359 33.344906 41.101359 33.753906 40.693359 L 36.708984 37.738281 C 37.116984 37.330281 37.116984 36.669719 36.708984 36.261719 L 35.230469 34.783203 L 36.708984 33.304688 C 37.116984 32.896687 37.116984 32.234172 36.708984 31.826172 L 35.230469 30.349609 L 36.708984 28.871094 C 37.116984 28.463094 37.116984 27.800578 36.708984 27.392578 L 35.041016 25.724609 C 38.4641 24.820989 41 21.704347 41 18 C 41 13.589 37.411 10 33 10 z M 14.5 14 C 11.480226 14 9 16.480226 9 19.5 L 9 31 A 1.50015 1.50015 0 0 0 10 32.433594 L 10 42.5 A 1.50015 1.50015 0 1 0 13 42.5 L 13 32 L 17 32 L 17 42.5 A 1.50015 1.50015 0 1 0 20 42.5 L 20 32.433594 A 1.50015 1.50015 0 0 0 21 31 L 21 19.5 C 21 16.480226 18.519774 14 15.5 14 L 14.5 14 z M 33 14 C 34.105 14 35 14.895 35 16 C 35 17.105 34.105 18 33 18 C 31.896 18 31 17.105 31 16 C 31 14.895 31.895 14 33 14 z" />
    </svg>
  );
}

export default UserRights;
