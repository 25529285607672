import React, { createContext, useState } from 'react';

const initialState = {};

export const ProviderSelectorContext = createContext(initialState);

const ProfileSelectorProvider = ({ children }) => {
  const [remoteSessionId, setRemoteSessionId] = useState('');
  const [remoteSessionPassword, setRemoteSessionPassword] = useState('');
  const [loadRemoteSession, setLoadRemoteSession] = useState(false);

  const collapseRemoteSession = () => {
    setLoadRemoteSession(false);
  };

  const toggleRemoteSession = () => {
    setLoadRemoteSession((state) => !state);
  };

  const clearRemoteSessionInputs = () => {
    setRemoteSessionId('');
    setRemoteSessionPassword('');
  };

  return (
    <ProviderSelectorContext.Provider value={{
      remoteSessionId,
      remoteSessionPassword,
      setRemoteSessionId,
      setRemoteSessionPassword,
      loadRemoteSession,
      collapseRemoteSession,
      toggleRemoteSession,
      clearRemoteSessionInputs,
    }}
    >
      {children}
    </ProviderSelectorContext.Provider>
  );
};

export default ProfileSelectorProvider;

