import types from '../actions/types';

types.GET_RIGHT_PANEL_DATA = 'GET_RIGHT_PANEL_DATA';

export const rightPaneInitialState = { rightPanelData: { tutorialCodename: '', workspaceId: null, tabId: null, tempData: null } };

export default function (state = rightPaneInitialState, action) {
  switch (action.type) {
    case types.GET_RIGHT_PANEL_DATA:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
