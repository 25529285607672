import React, { useContext, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CloseIcon, ArrowRightIcon } from '@assets/icons';
import Text from '@components/text';
import Modal from '@components/modals/modal';
import CloseButton from '@components/buttons/buttonIcon';
import { useDispatch, useSelector } from 'react-redux';
import TabBar, { TAB_NAMES, defaultTabs } from './tabBar';
import CreateProject from './createProject';
import LoadProject from './loadProject';
import styles from './profileSelector.mod.scss';
import { selectProfileSelector, hideProfileSelector } from '../features/profileSelectorSlice';
import { actionRemoteSessionLoginGet as remoteSessionLoginGet } from '../../../actions/remoteSessionAction';
import { ProviderSelectorContext } from '../features/context';

const evalWhatTabIsCurrentTab = (currentTab) => ({
  load: currentTab === TAB_NAMES.LOAD_PROJECT,
  create: currentTab === TAB_NAMES.CREATE_PROJECT,
});

function ProfileSelector({
  addTab,
  changeField,
  selectActiveTab,
  onBoardItemClick,
  onStoredProjectClick,
}) {
  const {
    remoteSessionId,
    remoteSessionPassword,
    clearRemoteSessionInputs,
    collapseRemoteSession,
  } = useContext(ProviderSelectorContext);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { display: displayProfileSelector } = useSelector(selectProfileSelector);
  const [projectScrollbar, setProjectScrollbar] = useState(false);
  const [selectedTab, setSelectedTab] = useState(defaultTabs[0]);
  const isRemote = remoteSessionId || remoteSessionPassword;
  const currentTabIs = evalWhatTabIsCurrentTab(selectedTab.name);

  const onTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const onStartProject = () => {
    addTab(false, true);
    dispatch(hideProfileSelector());
  };

  const onStartRemoteProject = () => {
    clearRemoteSessionInputs();
    dispatch(remoteSessionLoginGet(remoteSessionId, remoteSessionPassword));
    dispatch(hideProfileSelector());
  };

  const onClickCloseProfileSelection = () => {
    dispatch(hideProfileSelector());
  };

  const showProjectScrollbar = () => {
    setProjectScrollbar(false);
  };

  const hideProjectScrollbar = () => {
    setProjectScrollbar(true);
  };

  const onClickStoredProject = (...props) => {
    collapseRemoteSession();
    clearRemoteSessionInputs()
    onStoredProjectClick(...props);
  }

  useEffect(() => {
    if (currentTabIs.create) {
      clearRemoteSessionInputs();
      collapseRemoteSession();
    }
  }, [selectedTab]);

  return (
    <Modal open={displayProfileSelector}>
      <div className={styles.container}>
        <section className={styles.headerContainer}>
          <div className={styles.titleContainer}>
            <p className={styles.title}>
              {intl.formatMessage({ id: 'blocks.profileSelector.header.title' })}
            </p>
          </div>
          <div className={styles.closeContainer}>
            <span className={styles.closeIcon} onClick={onClickCloseProfileSelection}>
              <CloseIcon />
            </span>
          </div>
        </section>
        <section className={styles.tabBarContainer}>
          <TabBar selectedTab={selectedTab} onTabClick={onTabClick} />
        </section>
        <section className={projectScrollbar
          ? styles.projectContainerWithoutScroll
          : styles.projectContainer}
        >
          {currentTabIs.create && (
            <CreateProject
              onBoardItemClick={onBoardItemClick}
              showProjectScrollbar={showProjectScrollbar}
            />
          )}
          {currentTabIs.load && (
            <LoadProject
              addTab={addTab}
              changeField={changeField}
              selectActiveTab={selectActiveTab}
              hideProjectScrollbar={hideProjectScrollbar}
              onStartRemoteProject={onStartRemoteProject}
              onStoredProjectClick={onClickStoredProject}
            />
          )}
        </section>
        <section className={styles.actionsContainer}>
          <div className={styles.actionsStartWrapper}>
            <CloseButton
              icon={<ArrowRightIcon />}
              onClick={isRemote
                ? onStartRemoteProject
                : onStartProject}
            >
              <Text className={styles.startText}>
                {intl.formatMessage({ id: 'common.start' })}
              </Text>
            </CloseButton>
          </div>
        </section>
      </div>
    </Modal>
  );
}

export default ProfileSelector;
