import * as collaborationService from '@services/rest/collaboration';

const GET_COLLABORATION = 'COLLABORATIONS/GET';
const GET_COLLABORATION_FAIL = 'COLLABORATIONS/GET_FAIL';
const GET_COLLABORATION_SUCCESS = 'COLLABORATIONS/GET_SUCCESS';

const GET_COLLABORATION_LEVELS = 'COLLABORATIONS/LEVELS/GET';
const GET_COLLABORATION_LEVELS_FAIL = 'COLLABORATIONS/LEVELS/GET_FAIL';
const GET_COLLABORATION_LEVELS_SUCCESS = 'COLLABORATIONS/LEVELS/GET_SUCCESS';

const GET_COMPILATIONS_SUCCESS = 'COLLABORATIONS/GET_COMPILATIONS_SUCCESS';
const GET_COMPILATIONS_FAIL = 'COLLABORATIONS/GET_COMPILATIONS_FAIL';
const GET_COMPILATIONS = 'COLLABORATIONS/GET_COMPILATIONS';

const POST_COMPILATION = 'COLLABORATIONS/POST_COMPILATION';
const POST_COMPILATION_FAIL = 'COLLABORATIONS/POST_COMPILATION_FAIL';
const POST_COMPILATION_SUCCESS = 'COLLABORATIONS/POST_COMPILATION_SUCCESS';
const PUT_COMPILATION = 'COLLABORATIONS/PUT_COMPILATION';
const PUT_COMPILATION_FAIL = 'COLLABORATIONS/PUT_COMPILATION_FAIL';
const PUT_COMPILATION_SUCCESS = 'COLLABORATIONS/PUT_COMPILATION_SUCCESS';

const UPDATE_COLLABORATION_STATE = 'COLLABORATIONS/UPDATE_COLLABORATION_STATE';
const UPDATE_COLLABORATION_NOTIFICATIONS_STATE = 'COLLABORATIONS/UPDATE_COLLABORATION_NOTIFICATIONS_STATE';
const UPDATE_COLLABORATION_COMPILATION_STATE = 'COLLABORATIONS/UPDATE_COLLABORATION_COMPILATION_STATE';
const UPDATE_COLLABORATION_TUTORIAL_STATE = 'COLLABORATIONS/UPDATE_COLLABORATION_TUTORIAL_STATE';

export const initialState = {
  collaboration: {},
  notifications: {},
  compilation: {},
  compilationList: [],
  tutorial: {},
  levels: [],
  error: null,
  loading: false,
};


export default function collaborationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COLLABORATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLABORATION_SUCCESS: {
      const { collaboration } = action.payload;
      return {
        ...state,
        loading: false,
        collaboration,
      };
    }
    case GET_COLLABORATION_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case GET_COLLABORATION_LEVELS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLABORATION_LEVELS_SUCCESS: {
      const { levels } = action.payload;
      return {
        ...state,
        loading: false,
        levels,
      };
    }
    case GET_COLLABORATION_LEVELS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case UPDATE_COLLABORATION_STATE: {
      const { collaboration } = action.payload;
      return {
        ...state,
        loading: false,
        collaboration,
      };
    }
    case UPDATE_COLLABORATION_NOTIFICATIONS_STATE: {
      const { notifications } = action.payload;
      return {
        ...state,
        loading: false,
        notifications: { ...state.notifications, ...notifications },
      };
    }
    case GET_COMPILATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COMPILATIONS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case GET_COMPILATIONS_SUCCESS: {
      const { projectsCollabUsers } = action.payload;
      const newCompilationList = [];
      for (let i = 0; i < projectsCollabUsers.length; i += 1) {
        for (let j = 0; j < projectsCollabUsers[i].ProjectsCollabCompilations.length; j += 1) {
          newCompilationList.push(Object.assign({}, projectsCollabUsers[i].ProjectsCollabCompilations[j], { projectId: projectsCollabUsers[i].projectId }));
        }
      }
      return {
        ...state,
        loading: false,
        compilationList: newCompilationList,
      };
    }
    case POST_COMPILATION:
    case PUT_COMPILATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case POST_COMPILATION_SUCCESS:
    case PUT_COMPILATION_SUCCESS: {
      const { compilation } = action.payload;
      return {
        ...state,
        loading: false,
        compilation,
      };
    }
    case POST_COMPILATION_FAIL:
    case PUT_COMPILATION_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case UPDATE_COLLABORATION_COMPILATION_STATE: {
      const { compilation } = action.payload;
      return {
        ...state,
        loading: false,
        compilation,
      };
    }
    case UPDATE_COLLABORATION_TUTORIAL_STATE: {
      const { tutorial } = action.payload;
      return {
        ...state,
        loading: false,
        tutorial: { ...state.tutorial, ...tutorial },
      };
    }
    default:
      return state;
  }
}


// selectors
export const selectCollaboration = (state) => state.collaboration;


// actions
const getCollaborationSuccess = (dispatch) => (collaboration) => dispatch({
  type: GET_COLLABORATION_SUCCESS,
  payload: {
    collaboration,
  },
});

const getCollaborationFail = (dispatch) => (error) => dispatch({
  type: GET_COLLABORATION_FAIL,
  payload: {
    error,
  },
});

export const getCollaboration = (collaborationId) => (dispatch) => {
  dispatch({ type: GET_COLLABORATION });
  collaborationService
    .getCollaborationData(collaborationId)
    .then(getCollaborationSuccess(dispatch))
    .catch(getCollaborationFail(dispatch));
};


const getLevelsSuccess = (dispatch) => (levels) => dispatch({
  type: GET_COLLABORATION_LEVELS_SUCCESS,
  payload: {
    levels,
  },
});

const getLevelsFail = (dispatch) => (error) => dispatch({
  type: GET_COLLABORATION_LEVELS_FAIL,
  payload: {
    error,
  },
});

export const getLevels = (boardId) => (dispatch) => {
  dispatch({ type: GET_COLLABORATION_LEVELS });
  collaborationService
    .getLevelData(boardId)
    .then(getLevelsSuccess(dispatch))
    .catch(getLevelsFail(dispatch));
};


export const updateCollaborationState = (dispatch, state) => (collaboration) => {
  if (state.collaboration.id === collaboration.id || !state.collaboration.id) {
    dispatch({
      type: UPDATE_COLLABORATION_STATE,
      payload: {
        collaboration,
      },
    });
  }
};


export const updateCollaborationNotificationsState = (dispatch) => (notifications) => {
  dispatch({
    type: UPDATE_COLLABORATION_NOTIFICATIONS_STATE,
    payload: {
      notifications,
    },
  });
};



const getCompilationsSuccess = (dispatch) => (projectsCollabUsers) => dispatch({
  type: GET_COMPILATIONS_SUCCESS,
  payload: {
    projectsCollabUsers,
  },
});

const getCompilationsFail = (dispatch) => (error) => dispatch({
  type: GET_COMPILATIONS_FAIL,
  payload: {
    error,
  },
});

export const getCompilations = (userId) => (dispatch) => {
  dispatch({ type: GET_COMPILATIONS });
  collaborationService
    .getCompilationDataByUserId(userId)
    .then(getCompilationsSuccess(dispatch))
    .catch(getCompilationsFail(dispatch));
};

export const refreshCompilations = (dispatch, userId) => () => {
  dispatch({ type: GET_COMPILATIONS });
  collaborationService
    .getCompilationDataByUserId(userId)
    .then(getCompilationsSuccess(dispatch))
    .catch(getCompilationsFail(dispatch));
};


const postCompilationSuccess = (dispatch) => (compilation) => dispatch({
  type: POST_COMPILATION_SUCCESS,
  payload: {
    compilation,
  },
});

const postCompilationFail = (dispatch) => (error) => dispatch({
  type: POST_COMPILATION_FAIL,
  payload: {
    error,
  },
});

export const postCompilation = (dispatch) => (compilation) => {
  dispatch({ type: POST_COMPILATION });
  collaborationService
    .postCompilationData(compilation)
    .then(postCompilationSuccess(dispatch))
    .catch(postCompilationFail(dispatch));
};


const putCompilationSuccess = (dispatch) => (compilation) => dispatch({
  type: PUT_COMPILATION_SUCCESS,
  payload: {
    compilation,
  },
});

const putCompilationFail = (dispatch) => (error) => dispatch({
  type: PUT_COMPILATION_FAIL,
  payload: {
    error,
  },
});

export const putCompilation = (dispatch) => (id, compilation) => {
  dispatch({ type: PUT_COMPILATION });
  collaborationService
    .putCompilationData(id, compilation)
    .then(putCompilationSuccess(dispatch))
    .catch(putCompilationFail(dispatch));
};

export const updateCollaborationCompilationState = (dispatch) => (compilation) => {
  dispatch({
    type: UPDATE_COLLABORATION_COMPILATION_STATE,
    payload: {
      compilation,
    },
  });
};

export const updateCollaborationTutorialState = (dispatch) => (tutorial) => {
  dispatch({
    type: UPDATE_COLLABORATION_TUTORIAL_STATE,
    payload: {
      tutorial,
    },
  });
};
