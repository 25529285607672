export const TOAST_TYPE = {
  DEFAULT: 'default',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  CODE_SENT: 'code_sent',
  CODE_SENDING: 'code_sending',
  CODE_UPLOADED: 'code_uploaded',
  CODE_REPLACED: 'code_replaced',
  TRASFER_PERMISSION: 'trasfer_permission',
};
