import { combineReducers } from 'redux';
import firmwareReducer from '@sections/codit/features/firmwareSlice';
import collaborationReducer from '@sections/collaboration/features/collaborationSlice';
import creabotsReducer from '@sections/creabots/features/creabotsSlice';
import collaborationSessionReducer from '@sections/collaboration/features/collaborationSessionSlice';
import blocklyReducer from '@modules/blockly/features/blocklySlice';
import projectsReducer from '@modules/projects/features/projectsSlice';
import boardsReducer from '@modules/boards/features/boardsSlice';
import profileSelectorReducer from '@modules/profileSelector/features/profileSelectorSlice';
import remoteSessionsReducer from '@modules/remoteSession/features/sessionsSlice';
import reducers from '../reducers/reducer';

export default combineReducers({
  ...reducers,
  boards: boardsReducer,
  projects: projectsReducer,
  collaboration: collaborationReducer,
  creabots: creabotsReducer,
  collaborationSession: collaborationSessionReducer,
  blockly: blocklyReducer,
  profileSelector: profileSelectorReducer,
  remoteSessions: remoteSessionsReducer,
  firmware: firmwareReducer,
});
