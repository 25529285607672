import React, { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import styles from './blockly.mod.scss'
import * as Blockly from 'blockly/core';
import * as generators from '../features/generators';
import * as lang from '../features/lang/index';
import 'blockly/blocks';
import '../features/utils/theme';
import '../features/blocks/index';
import '../features/renderers/renderer';
import { PinsDB } from '../features/utils/pinsDB';
import { VariablesDynamicEducabot } from '../features/blocks/variablesDynamic/variableBlocks';
import { ProceduresDynamicEducabot } from '../features/blocks/proceduresDynamic/procedureBlocks';
import * as profiles from '../features/profiles';
import boardTypes from '@modules/boards/features/constants';
import { getCosmosItem } from '../../../cosmos';
import { useUpdateBlockly, useBlockly } from '../features/hooks';
import { resizeWorkspace } from '../features/utils/utils';
import { selectBlockly } from '@modules/blockly/features/blocklySlice';


const BlocklyContainer = (props) => {
  const blocklyDiv = useRef();
  const blocklyState = useRef();
  const primaryWorkspace = useRef();
  const [blocklyProject, setBlocklyProject] = useState({});
  blocklyState.current = useBlockly();
  useUpdateBlockly(blocklyProject);

  // console.log('=========props', props.tabInfo, blocklyState.project);
  const blocklyGenerator = generators[props.tabInfo?.board?.lang || 'JavaScript'].default;
  const blocklyLocale = lang[getCosmosItem('lang') || 'es'];
  const blocklyProfile = profiles.default[props.tabInfo?.board?.profile || ''];
  const juniorBlocksHorizontal = (props.tabInfo?.board?.type === boardTypes.JUNIOR && props.tabInfo?.board?.toolboxLayout === 'horizontal');
  const juniorBlocks = (props.tabInfo?.board?.type === boardTypes.JUNIOR);

  const blocklyOptions = {
    readOnly: false,
    trashcan: true,
    media: `${process.env.IS_ELECTRON ? '.' : ''}/images/media/`,
    move: {
      scrollbars: {
        horizontal: true,
        vertical: true,
      },
      drag: true,
      wheel: true,
    },
    zoom: {
      controls: true,
      wheel: true,
      startScale: juniorBlocks ? 1.8 : 0.8,
      maxScale: 3,
      minScale: 0.3,
      scaleSpeed: 1.2,
      pinch: true,
    },
    comments: false,
    horizontalLayout: juniorBlocksHorizontal,
    toolboxPosition: juniorBlocksHorizontal ? 'end' : 'start',
    toolbox: ReactDOMServer.renderToString(blocklyProfile.categoryXml),
    // TODO: Switch these two lines to migrate old projects to new JSON format.
    // initialXml: ReactDOMServer.renderToString(blocklyProfile.initXml),
    initialXml: blocklyProfile.initXml,
    // END TODO
  };

  const updateBlocklyProject = (e) => {
    const newCode = generateCode();
    const savedBlocklyProject = blocklyState.current.project;

    // TODO: Switch these two lines to migrate old projects to new JSON format.
    // const withWorkspace = new XMLSerializer().serializeToString(Blockly.Xml.workspaceToDom(primaryWorkspace.current));
    const withWorkspace = JSON.stringify(Blockly.serialization.workspaces.save(primaryWorkspace.current));
    // console.log('===========withWorkspace\n\n', withWorkspace);
    // END TODO

    if (primaryWorkspace.current && withWorkspace !== savedBlocklyProject.withWorkspace) {
      let canvas = primaryWorkspace.current.svgBlockCanvas_.cloneNode(true);
      canvas.removeAttribute('transform');
      canvas = new XMLSerializer().serializeToString(canvas);
      const canvasBBox = document.getElementsByClassName('blocklyBlockCanvas')[0]?.getBBox();

      const newData = {};
      newData.withWorkspace = withWorkspace;
      newData.canvas = canvas;
      newData.canvasBBoxX = canvasBBox?.x || 0;
      newData.canvasBBoxY = canvasBBox?.y || 0;
      newData.canvasBBoxWidth = canvasBBox?.width || 0;
      newData.canvasBBoxHeight = canvasBBox?.height || 0;
      newData.canvasCode = newCode;
      newData.port = props.tabInfo?.port || '';
      newData.code = newCode;
      setBlocklyProject(newData);

      if (props.setWorkspace) {
        props.setWorkspace(primaryWorkspace.current);
      }
    }
  };

  const generateCode = () => {
    const code = blocklyGenerator.workspaceToCode(primaryWorkspace.current);
    // console.log('===========CODE\n\n', code);
    return code;
  };


  useEffect(() => {
    Blockly.setLocale(blocklyLocale);
  }, []);

  useEffect(() => {
    const { initialXml, ...rest } = blocklyOptions;
    primaryWorkspace.current = Blockly.inject(blocklyDiv.current, {
      renderer: juniorBlocks ? 'junior' : 'zelos',
      theme: Blockly.Themes.Educabot,
      ...rest,
    });

    new PinsDB(primaryWorkspace.current, props.tabInfo?.board || null);

    if (initialXml) {
      primaryWorkspace.current.clear();
      const withWorkspace = props.tabInfo?.withWorkspace || null;
      // TODO: Switch these two blocks to migrate old projects to new JSON format (Profile's Toolboxes have to be refactored to json too)
      // Blockly.Xml.domToWorkspace(
      //   Blockly.utils.xml.textToDom((props.tabInfo?.withWorkspace) ? props.tabInfo.withWorkspace : initialXml),
      //   primaryWorkspace.current,
      // );
      if (!(typeof withWorkspace === 'object' && !Array.isArray(withWorkspace))) {
        Blockly.Xml.domToWorkspace(
          Blockly.utils.xml.textToDom(props.tabInfo.withWorkspace),
          primaryWorkspace.current,
        );
      } else {
        Blockly.serialization.workspaces.load(withWorkspace || initialXml, primaryWorkspace.current);
      }
      // END TODO
    }

    const onresize = () => resizeWorkspace(primaryWorkspace.current, `blocklyContainer-${props.tabInfo?.id || 'id'}`);
    window.addEventListener('resize', onresize, false);
    onresize();

    primaryWorkspace.current.addChangeListener(generateCode);
    primaryWorkspace.current.addChangeListener(updateBlocklyProject);
    primaryWorkspace.current.addChangeListener(Blockly.Events.disableOrphans);

    primaryWorkspace.current.registerToolboxCategoryCallback('VARIABLE_DYNAMIC', VariablesDynamicEducabot.flyoutCategory);
    primaryWorkspace.current.registerToolboxCategoryCallback('PROCEDURES_DYNAMIC', ProceduresDynamicEducabot.flyoutCategory);

    /**
     * Disable context menu items
     * Blockly.ContextMenuRegistry.registry.unregister('someID');
     * find the ids at: https://github.com/google/blockly/blob/0e22a7982ee99f9efd258c68826806189729d096/core/contextmenu_items.ts
     */

    return () => {
      primaryWorkspace.current.dispose();
      primaryWorkspace.current.removeChangeListener(generateCode);
      primaryWorkspace.current.removeChangeListener(updateBlocklyProject);
      primaryWorkspace.current.addChangeListener(Blockly.Events.disableOrphans);
      window.removeEventListener('resize', onresize, false);
    };

  }, [props.tabInfo?.withWorkspace, props.tabInfo?.board]);


  return (
    <div id={`blocklyContainer-${props.tabInfo?.id || 'id'}`} ref={blocklyDiv} className={styles.blockly} />
  );
}

export default BlocklyContainer;
