import React, { useState, useEffect, useContext } from 'react';
import { RemoteDesktopIcon } from '@assets/icons';
import Text from '@components/text';
import TextField from '@components/inputs/textField';
import { useOnClickOutside } from '@utils/hooks/general';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRemoteSessions, selectRemoteSessions } from '@modules/remoteSession/features/sessionsSlice';
import { ProviderSelectorContext } from '@modules/profileSelector/features/context';
import styles from './styles.mod.scss';

const checkIfRemoteSessionExist = (remoteSessions, remoteSessionId) => remoteSessions.find((session) => session.id === Number(remoteSessionId));

function LoadFromRemote({
  intl,
  changeField,
  loadRemoteSession,
  toggleRemoteSession,
  collapseRemoteSession,
  onStartRemoteProject,
}) {
  const {
    remoteSessionId,
    setRemoteSessionId,
    setRemoteSessionPassword,
  } = useContext(ProviderSelectorContext);
  const dispatch = useDispatch();
  const { sessions: remoteSessions } = useSelector(selectRemoteSessions);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [btnClick, setBtnClick] = useState(false);
  const [btnOver, setBtnOver] = useState(false);
  const remoteButtonElementRef = useOnClickOutside(() => {
    setBtnOver(false);
    setBtnClick(false);
    collapseRemoteSession();
  });

  const onClickBtn = () => {
    if (btnOver) return;
    setBtnClick((state) => !state);
    toggleRemoteSession();
  };

  const onMouseInputOver = () => {
    setBtnOver(true);
  };

  const onMouseInputLeave = () => {
    setBtnOver(false);
  };

  const onChangeInputSessionId = (event) => {
    changeField('remoteSessionId', event);
    setRemoteSessionId(event.target.value);
  };

  const onChangeInputSessionPassword = (event) => {
    changeField('remoteSessionPassword', event);
    setRemoteSessionPassword(event.target.value);
  };

  const onKeyDownInput = (event) => {
    if (event.keyCode === 13) {
      onStartRemoteProject();
    }
  };

  useEffect(() => {
    dispatch(getAllRemoteSessions());
  }, []);

  useEffect(() => {
    const session = checkIfRemoteSessionExist(remoteSessions, remoteSessionId);
    if (session && session.password) {
      setShowPasswordInput(true);
      return;
    }

    setShowPasswordInput(false);
  }, [remoteSessions, remoteSessionId]);

  return (
    <div
      ref={remoteButtonElementRef}
      onClick={onClickBtn}
      className={btnClick
        ? styles.containerPressed
        : styles.container}
    >
      <div className={styles.buttonContainer}>
        <div className={styles.iconWrapper}>
          <RemoteDesktopIcon />
        </div>
        <div className={styles.textWrapper}>
          <Text className={styles.text}>
            {intl.formatMessage({ id: 'blocks.profileSelector.project.load.fromRemoteSession' })}
          </Text>
        </div>
      </div>
      <div className={styles.inputsContainer}>
        <div
          className={styles.inputCodeContainer}
          onMouseOver={onMouseInputOver}
          onMouseLeave={onMouseInputLeave}
        >
          <TextField
            className={styles.codeInput}
            placeholder={intl.formatMessage({ id: 'blocks.profileSelector.project.load.codePlaceholder' })}
            style={{
              opacity: loadRemoteSession ? '1' : '0',
              cursor: loadRemoteSession ? 'auto' : 'pointer',
            }}
            onKeyDown={onKeyDownInput}
            onChange={onChangeInputSessionId}
          />
        </div>
        <div
          className={styles.inputPasswordContainer}
          onMouseOver={onMouseInputOver}
          onMouseLeave={onMouseInputLeave}
        >
          <TextField
            type="password"
            className={styles.passwordInput}
            style={{
              opacity: showPasswordInput && loadRemoteSession ? '1' : '0',
              cursor: showPasswordInput && loadRemoteSession ? 'auto' : 'pointer',
            }}
            placeholder={intl.formatMessage({ id: 'blocks.profileSelector.project.load.passwordPlaceholder' })}
            onChange={onChangeInputSessionPassword}
            onKeyDown={onKeyDownInput}
          />
        </div>
        <div className={styles.inputEmptyContainer} />
      </div>
    </div>
  );
}

export default LoadFromRemote;
