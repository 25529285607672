import axios from 'axios';


class Codit {
  constructor() {
    if (process.env.IS_ELECTRON) {
      this.apiURL = 'http://localhost:3001/codit';
    } else {
      this.apiURL = 'http://192.168.4.4';
    }
    this.missionRunning = false;
    this.commandQueue = [];
    this.retryCommandCount = 1;
    this.retryCount = 0;
    this.messageQueue = [];
    this.callback = null;
  }

  getStatus() {
    return new Promise((resolve, reject) => {
      const messages = {};
      messages.status = '';
      messages.logs = this.messageQueue;
      this.messageQueue = [];
      resolve(messages);
    });
  }

  getWifiList() {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/scanWifi`).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  setWifi(ssid) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/setWifi/${ssid}`).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  getConnectionStatus() {
    return new Promise((resolve, reject) => {
      axios.get(`${this.apiURL}/status`, { timeout: 2000, withCredentials: false }).then((result) => {
        resolve(result);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  parseBlocklyCommands(commandString) {
    const mission = eval(commandString); /* eslint-disable-line */
    const commands = mission.split('|');
    commands.push('end');

    for (let i = 0; i < commands.length; i += 1) {
      commands[i] = commands[i].replace(/,null/g, '').replace(/,/g, '_');
    }

    // console.log('====================mission:', commands, commandString);
    return commands;
  }

  execute(command) {
    const payload = {
      fila: 0,
      columna: 0,
      ficha: (command !== 'start' && command !== 'end') ? command : '',
      PrimerPaqueteCom: (command === 'start'),
      finalMensaje: (command === 'end'),
      checkOnline: false,
    };
    console.log('====================execute:', payload);
    return new Promise((resolve, reject) => {
      /// TODO: remove
      // resolve({
      //   termineDeEjecutar: 1,
      //   error: 0,
      // });
      /// TODO: restore
      axios.get(`${this.apiURL}/send`, {
        params: payload,
        timeout: 20000,
        withCredentials: false,
      }).then((result) => {
        console.log('====================result execution:', result.data);
        resolve(result.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  executeQueue() {
    if (this.commandQueue.length <= 0) {
      this.missionRunning = false;
      this.messageQueue.push('Ejecución terminada.');
      this.messageQueue.push('----------------------------------------------');
      if (this.callback) {
        this.callback(this.missionRunning);
      }
      return;
    }

    const command = this.commandQueue.shift();
    if (command !== '' && command !== 'start' && command !== 'end') {
      this.messageQueue.push(`Comando: ${command}`);
    }
    this.execute(command).then((resp) => {
      // console.log('====================sent:', resp, command);
      if (resp.error === '1') {
        this.messageQueue.push('Error: Robot ocupado');
        this.commandQueue = [];
      } else if (resp.error !== '0') {
        this.messageQueue.push(`Error: ${command}`);
      } else if (command !== '' && command !== 'start' && command !== 'end') {
        this.messageQueue.push(`Terminé de ejecutar: ${command}`);
      }
      this.retryCount = this.retryCommandCount;
      this.executeQueue();
    }).catch((err) => {
      this.retryCount -= 1;
      // console.log('====================sent error:', command, err, this.retryCount);
      this.messageQueue.push(`Error: ${command}`);
      if (this.retryCount > 0) {
        this.commandQueue.unshift(command);
        this.executeQueue();
      } else {
        this.retryCount = this.retryCommandCount;
        this.executeQueue();
      }
    });
  }

  executeConnectionCommand() {
    this.commandQueue = [''];
    this.executeQueue();
  }

  executeCommands(commandString, callback = null) {
    this.commandQueue = this.parseBlocklyCommands(commandString);

    this.callback = (typeof callback === 'function') ? callback : null;
    this.retryCount = this.retryCommandCount;
    this.missionRunning = true;
    this.executeQueue();
    this.messageQueue.push('----------------------------------------------');
    this.messageQueue.push('Comenzando la ejecución ...');
    console.log('====================Starting execution with: ', this.commandQueue);
  }
}

export default Codit;
