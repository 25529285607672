import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';

const CompilationProgress = (props) => {
  const intl = useIntl();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const id = props.id;
  const progress = props.progress || 0;
  const microbitMode = props.microbitMode || false;
  const userName = props.userName || '';

  const setModalVisibility = (visible, timeout = 0) => {
    if (timeout > 0) {
      setTimeout(() => {
        $(`#connectionModal-${id}`).modal((visible) ? 'show' : 'hide');
        setShowSuccessModal(false);
      }, timeout);
    } else {
      $(`#connectionModal-${id}`).modal((visible) ? 'show' : 'hide');
      setShowSuccessModal(false);
    }
  }

  useEffect(() => {
    if (progress > 0) {
      setModalVisibility(true);
    }
    if (progress >= 100) {
      setTimeout(() => {
        setShowSuccessModal(true);
        if (!microbitMode) {
          setModalVisibility(false, 3500);
        }
      }, 2000);
    } else if (progress < 0) {
      setModalVisibility(false, 4000);
    }
  }, [progress]);

  const createPercentagePoints = () => {
    const points = [];
    for (let i = 0; i < 101; i += 1) {
      points.push(
        <span key={`percpoint-${i}`}>{`${i}%`}</span>,
      );
    }
    return points;
  }

  return (
    <div className="modal fade connectionModal short-modal" data-backdrop="static" data-keyboard="false" id={`connectionModal-${id}`} tabIndex="-1" role="dialog" aria-labelledby={`connectionModal-${id}-label`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`connectionmodal-${id}-label`}>{intl.formatMessage({ id: 'blocks.compilation.compilationProgress.buildCode' })}</h5>
          </div>
          <div className="modal-body">
            {(progress >= 0 && progress <= 100 && !showSuccessModal) && (
              <div className="radial-progress" data-progress={progress}>
                <div className="circle">
                  <div className="mask full">
                    <div className="fill" />
                  </div>
                  <div className="mask half">
                    <div className="fill" />
                    <div className="fill fix" />
                  </div>
                  <div className="shadow" />
                </div>
                <div className="inset">
                  <div className="percentage">
                    <div className="numbers">
                      <span>-</span>
                      {createPercentagePoints()}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(progress < 0 && !showSuccessModal) && (
              <div className="success no-background">
                <svg id="crossAnimation" className="cross__svg" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 55 50">
                  <circle className="cross__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                  <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20" />
                </svg>
                <h4>{intl.formatMessage({ id: 'blocks.compilation.compilationProgress.error' })}</h4>
              </div>
            )}
            {(showSuccessModal) && (
              <React.Fragment>
                {(!microbitMode) ? (
                  <div className="success no-background">
                    <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                      <path id="successAnimationResult" fill="#212c4c" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                      <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                      <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27" fill="transparent" />
                    </svg>
                    <h4 className="great-job">
                      {intl.formatMessage({ id: 'blocks.compilation.compilationProgress.successTitle' }, { userName: `${(userName !== '') ? ', ' + userName : ''}` })}
                    </h4>
                    <h4 className="correctly-loaded">{intl.formatMessage({ id: 'blocks.compilation.compilationProgress.successMessage' })}</h4>
                  </div>
                ) : (
                  <div className="transfer-animation">
                    <h4>{intl.formatMessage({ id: 'blocks.compilation.compilationProgress.microbitLoadCode' })}</h4>
                    <div className="instructions-animation">
                      <div className="browser"></div>
                      <div className="file"></div>
                      <div className="explorer"></div>
                    </div>
                    <ButtonV2 onClick={() => { setModalVisibility(false); }}>
                      {intl.formatMessage({ id: 'common.agreed' })}
                    </ButtonV2>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="modal-footer" />
        </div>
      </div>
    </div>
  );
};

export default CompilationProgress;