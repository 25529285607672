import { blocksApi } from '../api';

export const getCollaborationData = (collaborationId) => blocksApi
  .get(`/collaboration/${collaborationId}`)
  .then((response) => response.data.data);

export const getCollaborationSessionsByUser = (userId) => blocksApi
  .get(`/collaboration/session/${userId}`)
  .then((response) => response.data.data);

export const getCollaborationSessionsTurns = (userId) => blocksApi
  .get(`/collaboration/turns/${userId}`)
  .then((response) => response.data.data);

export const getCollaborationSessionsUsers = (projectIds) => blocksApi
  .get(`/collaboration/users/${projectIds}`)
  .then((response) => response.data.data);

export const getLevelData = (boardId) => blocksApi
  .get(`/collaboration/levels/${boardId}`)
  .then((response) => response.data.data);

export const postCompilationData = (compilation = {}) => blocksApi
  .post('/collaboration/compilations', compilation)
  .then((response) => response.data.data);

export const putCompilationData = (id = 0, compilation = {}) => blocksApi
  .put(`/collaboration/compilations/${id}`, compilation)
  .then((response) => response.data.data);

export const getCompilationDataByUserId = (userId) => blocksApi
  .get(`/collaboration/session/${userId}/compilations`)
  .then((response) => response.data.data);

export const getOwnerFreshCokie = (token = null) => blocksApi
  .get(`${token ? `/collaboration/owner/${token}/refresh` : '/collaboration/owner/refresh'}`)
  .then((response) => response.data.data);
