import toastsMiddleware from '@modules/toasts/features/toastsMiddleware';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import LogRocket from 'logrocket';
import rootReducer from './rootReducer';

const logger = createLogger({
  diff: true,
  collapsed: true,
});

const composeEnhancers = typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk, toastsMiddleware, logger, LogRocket.reduxMiddleware()));
export const store = createStore(rootReducer, enhancer);
