/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import moment from 'moment';
import './Chronometer.scss';
import ButtonV2 from '../../resources/components/buttons/buttonV2';
import TextButton from '../../resources/components/buttons/textButton';

class StopWatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: 0,
      stopTime: 0,
      time: 0,
      running: false,
      stopRunning: props.stopRunning || false,
      laps: [],
      runningInterval: null,
      showButtonBar: props.showButtonBar || true,
      onStart: props.onStart || null,
      onStop: props.onStop || null,
      onReset: props.onReset || null,
      onLap: props.onLap || null,
    };
    this.changeStopwatch = this.changeStopwatch.bind(this);
    this.markLap = this.markLap.bind(this);
    this.reset = this.reset.bind(this);
    this.updateTime = this.updateTime.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { running, stopTime } = this.state;

    // console.log('===========newProps', newProps);
    if (newProps.showButtonBar !== null) {
      this.setState({ showButtonBar: newProps.showButtonBar });
    }
    if (newProps.stopRunning) {
      // console.log('===========stopRunning', running, newProps.stopRunning);
      if (running) {
        this.changeStopwatch();
      }
    }
    if (newProps.stopTime !== null) {
      // console.log('===========stopTime', running, newProps.stopTime, stopTime, newProps);
      this.setState({ stopTime: newProps.stopTime });
      if (running && newProps.stopTime > 0) {
        this.changeStopwatch();
      }
    }
    if (newProps.startTime !== null) {
      // console.log('===========startTime', running, newProps.startTime, stopTime, newProps);
      if (!running) {
        let timePassed = this.getPassedTime(newProps.startTime);
        if (stopTime > 0) timePassed = stopTime;
        this.setState({ time: timePassed });

        if (stopTime === 0 && newProps.startTime > 0) {
          this.changeStopwatch();
        }
      }
    }
    if (newProps.laps && newProps.laps !== null) {
      this.setState({ laps: newProps.laps });
    }
  }

  getPassedTime(startTime) {
    let secondsPassed = 0;
    if (startTime) {
      secondsPassed = moment().unix() - parseInt(startTime, 10);
    }
    return secondsPassed;
  }

  updateTime = () => {
    const { time } = this.state;

    const newTime = time + 1;
    this.setState({ time: newTime });
  }

  changeStopwatch = () => {
    const { running, runningInterval } = this.state;

    if (running) {
      clearInterval(runningInterval);
      this.setState({ running: false, runningInterval: null });
    } else {
      const runningInt = setInterval(() => {
        this.updateTime();
      }, 1000);
      this.setState({ runningInterval: runningInt, running: true });
    }
  }

  onChangeStopWatch = () => {
    const { running, time } = this.state;
    const { onStart, onStop } = this.props;

    if (running) {
      if (onStop) onStop(time);
    } else if (onStart) {
      let now = moment().unix();
      if (time > 0) {
        now -= time;
      }
      onStart(now);
    }
    this.changeStopwatch();
  }

  markLap = () => {
    const { laps, time } = this.state;
    const { onLap } = this.props;

    const newlaps = laps;
    newlaps.push(time);
    this.setState({ laps: newlaps }, () => {
      if (onLap) onLap(newlaps);
      const laptime = document.getElementById('laptime');
      laptime.scrollTop = laptime.scrollHeight;
    });
  }

  reset = () => {
    const { runningInterval } = this.state;
    const { onReset } = this.props;

    if (onReset) onReset();
    clearInterval(runningInterval);
    this.setState({ running: false, time: 0, laps: [], runningInterval: null });
  }

  render() {
    const { laps, running, time, showButtonBar } = this.state;

    const timeFormat = (now) => {
      let string = '';
      if ((now * 1000) < 3600000) {
        string = moment.utc(now * 1000).format('mm[m] ss[s]');
      } else {
        string = moment.utc(now * 1000).format('HH[h] mm[m] ss[s]');
      }
      return string;
    };

    return (
      <div className="Chronometer">
        <h1>{timeFormat(time)}</h1>
        <div className="laptime scrolleable" id="laptime">
          {laps.map((lap, index) => (
            <h2 key={`Marca ${index + 1}`}>
              {`Marca ${index + 1}: `}
              {timeFormat(lap)}
            </h2>
          ))}
        </div>
        <div className="action">
          {(showButtonBar) && (
            <React.Fragment>
              <ButtonV2 onClick={this.onChangeStopWatch}>{running ? 'Detener' : 'Comenzar'}</ButtonV2>
              <ButtonV2 onClick={this.markLap} disabled={time === 0 || !running}>Marca</ButtonV2>
              <TextButton className="reset" onClick={this.reset}>Resetear</TextButton>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default StopWatch;
