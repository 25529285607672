/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
import * as Blockly from 'blockly';
import * as JSGenerator from 'blockly/javascript';

export class JavascriptGenerator extends JSGenerator.JavascriptGenerator {
  constructor(name = 'JavaScript') {
    super(name);
  }

  init(workspace) {
    this.includes_ = Object.create(null);
    this.definitions_ = Object.create(null);
    this.setups_ = Object.create(null);
    this.loop_ = Object.create(null);
    this.loop_footer_ = Object.create(null);

    super.init(workspace);
  }

  finish(code) {
    const includes = Object.values(this.includes_);
    const definitions = Object.values(this.definitions_);
    const setups = Object.values(this.setups_);
    const loop = Object.values(this.loop_);
    const loop_footer = Object.values(this.loop_footer_);

    super.finish(code);

    return `
${includes.join(`
${this.INDENT}`)}
${definitions.join(`
${this.INDENT}`)}
${setups.join(`
${this.INDENT}`)}
${this.INDENT}${loop.join(`
${this.INDENT}`)}
${code}
${this.INDENT}${loop_footer.join(`
${this.INDENT}`)}
`;
  }
}