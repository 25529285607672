/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import styles from './InputComponent.mod.scss';

class InputComponent extends Component {
  constructor(props) {
    super(props);
    const { changeField, form, name, type, keyname, onEnterPress, placeholder, alternativeLabel } = this.props;
    this.state = {
      onEnterPress: onEnterPress || (() => {}),
      changeField,
      form,
      name,
      type,
      keyname,
      placeholder,
      alternativeLabel,
    };
    // const responsive = new Responsive(this); //eslint-disable-line
    // this.detectEnter = this.detectEnter.bind(this);
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      onEnterPress: newprops.onEnterPress || (() => {}),
      changeField: newprops.changeField,
      form: newprops.form,
      name: newprops.name,
      type: newprops.type,
      keyname: newprops.keyname,
      placeholder: newprops.placeholder,
    });
  }

  detectEnter(e) {
    const { onEnterPress } = this.state;

    if (e.keyCode === 13) { onEnterPress(); }
  }

  render() {
    const { changeField, form, name, type, keyname, placeholder, alternativeLabel } = this.state;
    return (
      <div className={`form-group bmd-form-group ${styles.inputComponent}`}>
        <span htmlFor={`id-${keyname}`} className={`input-group-text text-left ${styles.label} ${alternativeLabel ? styles.alternativeLabel : ''}`}>{ name }</span>
        <input onKeyDown={(e) => { this.detectEnter(e); }} type={type} className={`form-control inputForm p-2 ${(form[keyname] !== '' || type === 'date') ? 'is-dirty' : 'is-not-dirty'}`} id={`id-${keyname}`} onChange={(e) => { changeField(keyname, e); }} value={form[keyname] || ''} placeholder={placeholder || ''} />
      </div>
    );
  }
}

export default InputComponent;
