import React from 'react';
import clsx from 'clsx';
import styles from './text.mod.scss';

const Text = ({ className = '', clasess = {}, children, ...props }) => (
  <p {...props} className={clsx(className, styles.paragraph, clasess.paragraph)}>
    {children}
  </p>
);

export default Text;
