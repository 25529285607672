import * as routes from '@educabot/educablocks-cosmos';

const kitOptions = [
  {
    id: 1,
    name: 'creabots.tutorials.initiation.name',
    kitName: 'Creabots',
    imageUrl: `${routes.bloquesUri}/images/creabots/initiation/packaging.png`,
    isDisabled: false,
  },
  {
    id: 2,
    name: 'creabots.tutorials.automation.name',
    kitName: 'Creabots',
    imageUrl: `${routes.bloquesUri}/images/creabots/automation/packaging.png`,
    isDisabled: false,
  },
  {
    id: 3,
    name: 'creabots.tutorials.driving.name',
    kitName: 'Creabots',
    imageUrl: `${routes.bloquesUri}/images/creabots/driving/packaging.png`,
    isDisabled: false,
  },
];

export default kitOptions;
