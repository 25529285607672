import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import AceEditor from 'react-ace';
import SerialMonitor from '../../../components/SerialMonitor/SerialMonitor';
import SelectBoards from '../../../components/SelectBoards/SelectBoards';
import SelectPorts from '../../../components/SelectPorts/SelectPorts';
import Switch from 'react-switch';
import boardTypes from '@modules/boards/features/constants';
import styles from './footerPanel.mod.scss';
import { getBoardsByCosmos } from '../../../cosmos';


const FooterPanel = React.memo((props) => {
  const intl = useIntl();
  const [consoleToggle, setConsoleToggle] = useState(false);
  const [consoleEditor, setConsoleEditor] = useState(null);
  const [portOpen, setPortOpen] = useState(false);

  const createConsoleString = (array) => {
    let string = '';
    let strReplacement = '';
    for (let i = 0; i < array.length; i += 1) {
      strReplacement = array[i];
      strReplacement = strReplacement.replace('The tool is already present on the system', intl.formatMessage({ id: 'blocks.compilation.agentPluginFound' }));
      strReplacement = strReplacement.replace('Map Updated', '');
      string += `${JSON.stringify(strReplacement)} \n`;
    }
    return string;
  };

  // TODO: Refactor to get the new state.agentReducer
  const agentState = useSelector((state) => state.bloquesReducer);
  const boardType = props.boardType || boardTypes.SENIOR;
  const boards = getBoardsByCosmos(boardType);
  const id = props.id || '';
  const socket = props.socketAgent || null;
  const board = props.board || {};
  const boardId = props.board.id || 0;
  const horizontalLayout = props.horizontalLayout || false;
  const toolboxToggle = props.toolboxToggle || false;
  const consoleGenerated = createConsoleString(agentState.msgs) || '';
  const port = props.port || '';
  const ports = agentState.ports;
  const hiddenChevron = props.hiddenChevron || false;
  const telloStatus = props.telloStatus || '';
  const telloBattery = props.telloBattery || 0;
  const buildingFunction = props.buildingFunction || null;
  const portChangeAllowed = props.portChangeAllowed || false;
  let ebBuildStatus = 'GOOD';
  let arduinoAgentStatus = 'GOOD';
  if (props.board.compilerBoard !== 'microbit') {
    ebBuildStatus = agentState.state.ebBuild;
    arduinoAgentStatus = agentState.state.agent;
  }
  const buildBtnEnabled =
    (
      ((ebBuildStatus === 'ERROR' && !process.env.IS_ELECTRON) || arduinoAgentStatus === 'GOOD' || telloStatus === 'GOOD')
      && socket?.agentType === 'socket'
    )
    || (socket?.agentType === 'serial' || socket?.agentType === 'usb');
  // console.log('===============footer', socket.agentType, boardType, boards);

  const onConsoleToggle = () => {
    setConsoleToggle(!consoleToggle);
  }
  const onRefConsoleEditor = (ref) => {
    setConsoleEditor(ref);
  }
  const onPortChange = (portChanged) => {
    if (props.selectPortChange) {
      props.selectPortChange(portChanged);
    }
  }
  const onToggleSerialMonitor = () => {
    if (socket) {
      if (!portOpen) {
        // socket.openMonitor(port);
        setPortOpen(true);
      } else {
        // socket.closeMonitor(port);
        setPortOpen(false);
      }
    }
  }
  const onCloseSerialMonitor = () => {
    setPortOpen(false);
  }

  const buildBlockly = (params) => {
    if (buildingFunction) {
      buildingFunction(params);
    }
  }

  useEffect(() => {
    if (consoleEditor && consoleEditor.session) {
      consoleEditor.gotoLine(consoleEditor.session.doc.getAllLines().length);
    }
  }, [consoleGenerated]);


  return (horizontalLayout) ? (
    <div className='bottom-nav-horizontal footerPanel'>
      <button type="button" className="btn btn-primary disabled-mask w-100" disabled={!buildBtnEnabled} onClick={buildBlockly}>
        <span className="right-menu-btn icon-play3 c-white" />
      </button>
    </div>
  ) : (
    <div className={`bottom-nav ${styles.footerPanel} ${(consoleToggle) ? 'consoleBox-active' : ''} ${(ebBuildStatus === 'ERROR') ? 'consoleBox-error' : ''} footerPanel`}>
      <div className="visible-bar">
        <div className="container-fluid">
          <div className="row p-0">
            <div className={`button-col ${toolboxToggle ? 'narrow-button-col' : ''}`}>
              <button type="button" className="btn btn-primary disabled-mask w-100" disabled={!buildBtnEnabled} onClick={buildBlockly}>

                <span className="right-menu-btn icon-play3 c-white" />
              </button>
            </div>
            <div className={`progress-col ${toolboxToggle ? 'wide-progress-col' : ''}`}>
              <div className="f-bold m-2">
                <span className="fade shootMsg" />
              </div>

            </div>
            <div className="triggers-col">
              <div className="m-auto text-right w-100">
                <div className="triggers-col">
                  {(telloStatus === 'GOOD') && (
                    <div className="d-inline-block">
                      <div className="d-inline-block">
                        <span className={'batteryStatus'}>{telloBattery} %</span>
                      </div>
                      <div className="d-inline-block">
                        <button type="button" className="btn-transparent withOpacity active">
                          <span className={`right-menu-btn ${parseFloat(telloBattery) < 50 ? 'icon-battery-low' : (parseFloat(telloBattery) < 80) ? 'icon-battery-half' : 'icon-battery-full'} ${(parseFloat(telloBattery) > 20) ? 'c-green' : 'c-red'}`} />
                        </button>
                      </div>
                    </div>
                  )}
                  {(telloStatus !== '') && (
                    <div className="d-inline-block">
                      <button type="button" className="btn-transparent withOpacity active">
                        <span className={`right-menu-btn icon-connection ${(telloStatus === 'ERROR') ? 'c-red' : 'c-green'}`} />
                      </button>
                    </div>
                  )}
                  {(telloStatus === '') && (
                    <div className="d-inline-block">
                      <div className="d-inline-block">
                        <button type="button" className={`btn-transparent withOpacity ${(ebBuildStatus !== '') ? 'active' : ''}`}>
                          <span className={`right-menu-btn icon-download3 ${(ebBuildStatus === 'ERROR') ? 'c-red' : 'c-green'}`} />
                        </button>
                      </div>
                      <div className="d-inline-block">
                        <button type="button" className={`btn-transparent withOpacity ${(arduinoAgentStatus !== '') ? 'active' : ''}`}>
                          <span className={`right-menu-btn icon-usb ${(arduinoAgentStatus === 'ERROR') ? 'c-red' : 'c-green'}`} />
                        </button>
                      </div>
                    </div>
                  )}
                  {!hiddenChevron && (
                    <div className="d-inline-block">
                      <button type="button" className="btn-transparent" onClick={onConsoleToggle}>
                        <span className={`right-menu-btn f-22 ${(consoleToggle) ? 'icon-chevron-down' : 'icon-chevron-up'}`} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 p-0 consoleBox">
        <div className="content-consoleBox">
          <AceEditor
            mode="javascript"
            theme="dracula"
            value={consoleGenerated}
            name={`aceEditor-console-${id}`}
            width={window.innerWidth * 0.7}
            height="250"
            fontSize={16}
            readOnly={true}
            wrapEnabled={true}
            showPrintMargin={true}
            showGutter={false}
            highlightActiveLine={true}
            editorProps={{
              $blockScrolling: Infinity,
            }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showPrintMargin: false,
              showLineNumbers: true,
            }}
            onLoad={onRefConsoleEditor}
          />
          <div className="advanced-settings">
            <div className="row mt-3 ml-0 mr-0 text-center">
              <div className="col-12">
                <h5>{intl.formatMessage({ id: 'blocks.compilation.settings' })}</h5>
                <SelectBoards disabled={true} boards={boards} parentPanel="bloques" id={`boards-${id}`} defaultBoard={boardId} />
                {(portChangeAllowed) && (
                  <React.Fragment>
                    <br />
                    <SelectPorts parentFunctionListener={onPortChange} id={`ports-${id}`} selected={port} ports={ports} />
                  </React.Fragment>
                )}
                {!process.env.IS_ELECTRON && (socket?.agentType === 'serial' || socket?.agentType === 'usb') ? (
                  <button type="button" className="btn btn-primary main-btn newBoard" onClick={socket?.connectNewBoard}>
                    {intl.formatMessage({ id: 'blocks.blockly.newBoard' })}
                  </button>
                ) : ('')}
              </div>
              <div className="col-12">
                <div>
                  <span className="serialMonitorText">{intl.formatMessage({ id: 'blocks.compilation.showSerialMonitor' })}</span>
                  <Switch onChange={onToggleSerialMonitor} checked={portOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SerialMonitor
          id={id}
          port={port}
          socket={socket}
          onClose={onCloseSerialMonitor}
          portOpen={portOpen}
          board={board}
        />
      </div>
    </div>
  );
});

export default FooterPanel;