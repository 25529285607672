/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint-disable */
import * as Blockly from 'blockly';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';
import { config } from 'process';


Blockly.Blocks.initial_block = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendDummyInput();
    this.appendStatementInput('Start').appendField(Blockly.Msg.LANG_START_2);
    this.appendStatementInput('Repeat').appendField(Blockly.Msg.LANG_REPEAT);
    this.setPreviousStatement(false);
    this.setNextStatement(false);
    this.setInputsInline(true);
    this.setTooltip(Blockly.Msg.LANG_START_2);
  },
};

Blockly.Blocks.math_number = {
  init: function () {
    this.setOutputShape(options.SHAPES.ROUND);
    this.setColour('#ffffff');
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('0', Blockly.Blocks.math_number.validator), 'NUM');
    this.setOutput(true, Number);
    this.setTooltip(Blockly.Msg.LANG_MATH_NUMBER_TOOLTIP);
  },
};

Blockly.Blocks.base_delay = {
  init: function () {
    this.setColour(colors.COLOUR_CONTROL);
    this.appendValueInput('NUM', Number)
      .appendField(Blockly.Msg.LANG_CONTROLS_BASE_DELAY_WAIT)
      .setCheck(Number);
    this.setInputsInline(false);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_CONTROLS_BASE_DELAY_TOOLTIP);
  },
};
