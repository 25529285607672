import popups from '../popups';
import { store } from '../../state/store';
import { getRightPanelData } from '../../actions/rightPanelAction';

export default function (e) {
  if (e.element === 'click' && e.blockId) {
    const workspace = window.Blockly.Workspace.getById(e.workspaceId);
    const type = workspace.getBlockById(e.blockId).type;
    if ([
      'toggle_block',
      'microbit_toggle_block',
      // 'salidas_led2',
    ].indexOf(type) >= 0) {
      const block = workspace.getBlockById(e.blockId);
      if (block.updateShape) block.updateShape();
    }
  }
  if (e.type === 'end_drag') {
    window.cleanToolBoxSelection();
  }
  if (e.workspaceId && e.newValue === 'Create' && e.element === 'Variable') {
    popups.openCreateVariableModal(e);
  }
  if (e.workspaceId && e.newValue === 'Create' && e.element === 'Funcion') {
    popups.openCreateFuncionModal(e);
  }
  if (e.workspaceId && e.blockId) {
    const Block = window.Blockly.Workspace.getById(e.workspaceId).getBlockById(e.blockId);
    if (Block && typeof Block.listenOnClick && e.element === 'openRightPanel') {
      store.dispatch(getRightPanelData({
        tutorialCodename: `Blockly_${Block.type}`,
        workspaceId: Block.workspace.id,
        tabId: null,
      }));
    }
    if (Block && typeof Block.listenOnClick && e.element === 'click') {
      window.WordBlock = Block;
      if (Block.openModal) popups.openModal(Block);
    }
  }
}
