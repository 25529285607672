import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import MUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { getThemeVariable } from '../../../cosmos';

const useStyles = makeStyles((theme) => ({
  ButtonV2: ({ dense }) => ({
    boxSizing: 'border-box',
    borderRadius: 3,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '21px',
    textTransform: 'none',
    padding: dense ? '7.5px 16px' : '13.5px 16px',
    background: getThemeVariable('--color-primary-dark') || theme.palette.primary.dark,
    color: '#FFF',
    '&:hover': {
      background: getThemeVariable('--color-primary') || theme.palette.primary.main,
      color: '#FFF',
    },
    '&:active': {
      background: getThemeVariable('--color-primary-dark') || theme.palette.primary.dark,
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
      color: '#FFF',
    },
  }),
  disabled: {
    opacity: 0.3,
    border: 'none',
    color: '#FFF !important',
  },
  iconLeft: {
    justifyItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  iconRight: {
    justifyItems: 'center',
    display: 'flex',
    marginLeft: 10,
  },
  loadingContainer: {
    height: 21,
    display: 'flex',
    alignItems: 'center',
  },
}));

const ButtonV2 = ({ children, dense = false, disabled = false, onClick, loading, className, IconLeft, iconLeftClassname, IconRight, iconRightClassname, ...props }) => {
  const classes = useStyles({ dense });
  return (
    <MUIButton
      {...props}
      onClick={onClick}
      disabled={disabled || loading}
      disableRipple
      className={clsx(
        classes.ButtonV2,
        { [classes.disabled]: disabled },
        className,
      )}
    >
      {IconLeft && !loading && <span className={classes.iconLeft}><IconLeft className={iconLeftClassname} /></span>}
      {loading ? <div className={classes.loadingContainer}><CircularProgress color="secondary" size={18} /></div> : children}
      {IconRight && !loading && <span className={classes.iconRight}><IconRight className={iconRightClassname} /></span>}
    </MUIButton>
  );
};

export default ButtonV2;
