import * as collaborationService from '@services/rest/collaboration';


const GET_COLLABORATION_SESSION = 'COLLABORATION_SESSION/GET';
const GET_COLLABORATION_SESSION_FAIL = 'COLLABORATION_SESSION/GET_FAIL';
const GET_COLLABORATION_SESSION_SUCCESS = 'COLLABORATION_SESSION/GET_SUCCESS';

const GET_COLLABORATION_SESSION_USERS = 'COLLABORATION_SESSION_USERS/GET';
const GET_COLLABORATION_SESSION_USERS_FAIL = 'COLLABORATION_SESSION_USERS/GET_FAIL';
const GET_COLLABORATION_SESSION_USERS_SUCCESS = 'COLLABORATION_SESSION_USERS/GET_SUCCESS';

const GET_COLLABORATION_SESSION_TURNS = 'COLLABORATION_SESSION_TURNS/GET';
const GET_COLLABORATION_SESSION_TURNS_FAIL = 'COLLABORATION_SESSION_TURNS/GET_FAIL';
const GET_COLLABORATION_SESSION_TURNS_SUCCESS = 'COLLABORATION_SESSION_TURNS/GET_SUCCESS';

const GET_FRESH_COOKIE = 'COLLABORATION_SESSION/GET_FRESH_COOKIE';
const GET_FRESH_COOKIE_FAIL = 'COLLABORATION_SESSION/GET_FRESH_COOKIE_FAIL';
const GET_FRESH_COOKIE_SUCCESS = 'COLLABORATION_SESSION/GET_FRESH_COOKIE_SUCCESS';

const UPDATE_COLLABORATION_SESSION = 'COLLABORATION_SESSION/UPDATE';


export const initialState = {
  turns: [],
  users: null,
  session: null,
  error: null,
  loading: false,
};

export default function collaborationSessionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COLLABORATION_SESSION: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLABORATION_SESSION_SUCCESS: {
      const { session } = action.payload;
      return {
        ...state,
        loading: false,
        session,
      };
    }
    case GET_COLLABORATION_SESSION_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case GET_COLLABORATION_SESSION_USERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLABORATION_SESSION_USERS_SUCCESS: {
      const { users } = action.payload;
      return {
        ...state,
        loading: false,
        users,
      };
    }
    case GET_COLLABORATION_SESSION_USERS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case GET_COLLABORATION_SESSION_TURNS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_COLLABORATION_SESSION_TURNS_SUCCESS: {
      const { turnSession } = action.payload;
      const { session, turns } = turnSession;
      return {
        ...state,
        loading: false,
        turns: turns || [],
        // turns: turns || [
        //   {
        //     organization_id: 210,
        //     organization_name: 'Ministerio Educación CABA',
        //     reservation_date_time: '2022-07-06T17:43:00Z',
        //   },
        //   {
        //     organization_id: 210,
        //     organization_name: 'Ministerio de Educación Bla bla bla bla bla',
        //     reservation_date_time: '2022-07-06T19:00:00Z',
        //   },
        //   {
        //     organization_id: 210,
        //     organization_name: 'Ministerio Ble ble ble',
        //     reservation_date_time: '2022-07-06T20:15:00Z',
        //   },
        //   {
        //     organization_id: 210,
        //     organization_name: 'Ministerio Bli bli',
        //     reservation_date_time: '2022-07-06T20:00:00Z',
        //   },
        //   {
        //     organization_id: 210,
        //     organization_name: 'Ministerio Blo blo',
        //     reservation_date_time: '2022-07-06T22:00:00Z',
        //   },
        //   {
        //     organization_id: 210,
        //     organization_name: 'Ministerio Blu blu',
        //     reservation_date_time: '2022-07-06T23:00:00Z',
        //   },
        // ],
        session,
      };
    }
    case GET_COLLABORATION_SESSION_TURNS_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case UPDATE_COLLABORATION_SESSION: {
      const { sessionProject } = action.payload;
      let returnedState = state;
      if (sessionProject.id) {
        const sessionIndex = state.session.findIndex((s, i) => s.id === sessionProject.id);
        const session = state.session;
        session[sessionIndex] = {
          ...session[sessionIndex],
          ...sessionProject,
        };
        returnedState = {
          ...state,
          loading: false,
          session,
        };
      }
      return returnedState;
    }
    default:
      return state;
  }
}


// selectors
export const selectCollaborationSession = (state) => state.collaborationSession;
export const selectCollaborationSessionUsers = (state) => state.collaborationSession.users;
export const selectCollaborationSessionTurns = (state) => state.collaborationSession.turns;


// actions
const getCollaborationSessionSuccess = (dispatch) => (session) => dispatch({
  type: GET_COLLABORATION_SESSION_SUCCESS,
  payload: {
    session,
  },
});

const getCollaborationSessionFail = (dispatch) => (error) => dispatch({
  type: GET_COLLABORATION_SESSION_FAIL,
  payload: {
    error,
  },
});

export const getCollaborationSession = (userId) => (dispatch) => {
  dispatch({ type: GET_COLLABORATION_SESSION });
  collaborationService
    .getCollaborationSessionsByUser(userId)
    .then(getCollaborationSessionSuccess(dispatch))
    .catch(getCollaborationSessionFail(dispatch));
};


const getCollaborationSessionUsersSuccess = (dispatch) => (users) => dispatch({
  type: GET_COLLABORATION_SESSION_USERS_SUCCESS,
  payload: {
    users,
  },
});

const getCollaborationSessionUsersFail = (dispatch) => (error) => dispatch({
  type: GET_COLLABORATION_SESSION_USERS_FAIL,
  payload: {
    error,
  },
});

export const getCollaborationSessionUsers = (projectIds) => (dispatch) => {
  dispatch({ type: GET_COLLABORATION_SESSION_USERS });
  collaborationService
    .getCollaborationSessionsUsers(projectIds)
    .then(getCollaborationSessionUsersSuccess(dispatch))
    .catch(getCollaborationSessionUsersFail(dispatch));
};


const getCollaborationSessionTurnsSuccess = (dispatch) => (turnSession) => dispatch({
  type: GET_COLLABORATION_SESSION_TURNS_SUCCESS,
  payload: {
    turnSession,
  },
});

const getCollaborationSessionTurnsFail = (dispatch) => (error) => dispatch({
  type: GET_COLLABORATION_SESSION_TURNS_FAIL,
  payload: {
    error,
  },
});

export const getCollaborationSessionTurns = (userId) => (dispatch) => {
  dispatch({ type: GET_COLLABORATION_SESSION_TURNS });
  collaborationService
    .getCollaborationSessionsTurns(userId)
    .then(getCollaborationSessionTurnsSuccess(dispatch))
    .catch(getCollaborationSessionTurnsFail(dispatch));
};


export const updateSessionProjectState = (dispatch) => (sessionProject) => {
  dispatch({
    type: UPDATE_COLLABORATION_SESSION,
    payload: {
      sessionProject,
    },
  });
};



const getOwnerFreshCokieSuccess = (dispatch) => () => dispatch({ type: GET_FRESH_COOKIE_SUCCESS });

const getOwnerFreshCokieFail = (dispatch) => () => dispatch({ type: GET_FRESH_COOKIE_FAIL });

// export const refreshOnwerSession = (dispatch) => () => {
export const refreshOnwerSession = (token = null) => (dispatch) => {
  dispatch({ type: GET_FRESH_COOKIE });
  collaborationService
    .getOwnerFreshCokie(token)
    .then(getOwnerFreshCokieSuccess(dispatch))
    .catch(getOwnerFreshCokieFail(dispatch));
};
