import React, { Component } from 'react';
import { LedElement } from '../components/led/led';
import { rotatePort, cpuMillis } from '../utils';

export class Led extends Component {
  constructor(hardware, pin, id = 'Led', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;
    this.pinState = false;

    this.lastTimestamp = 0;
    this.pinSpeed = 0;
    this.period = 0;
    this.periodTemp = 0;
    this.periodHigh = 0;
    this.periodHighTemp = 0;

    this.variant = variant || {
      color: 'white',
    };
    this.pinInfo = [
      { name: 'A', x: -3, y: 18, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 57,
      height: 34,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runMicrosecond = (cpuCycles) => {
    if (this.hardware) {
      const microseconds = cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      const delta = microseconds - this.lastTimestamp;
      if (delta > 30) {
        const timeOfChanges = cpuMillis(this.hardware.runner.cpu.cycles - this.periodTemp, this.hardware.mhz);
        if (this.pinState && timeOfChanges > 10) { // full speed
          this.pinSpeed = 100;
        } else if (!this.pinState && timeOfChanges > 10) { // brake
          this.pinSpeed = 0;
        } else { // ON with speed
          this.pinSpeed = (this.period > 0) ? Math.round((this.periodHigh / this.period) * 100) : 0;
        }

        this.lastTimestamp = microseconds;
      }
    }
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => (this.pinSpeed > 0);

  update = (pinState) => {
    // this.pinState = Boolean(pinState);
    if (this.hardware) {
      if (pinState !== this.pinState) {
        if (pinState) {
          this.period = this.hardware.runner.cpu.cycles - this.periodTemp;
          this.periodTemp = this.hardware.runner.cpu.cycles;
          this.periodHighTemp = this.hardware.runner.cpu.cycles;
        } else {
          this.periodHigh = (this.period > 0) ? this.hardware.runner.cpu.cycles - this.periodHighTemp : 0;
          this.periodHighTemp = 0;
        }
      }
      this.pinState = pinState;
    }
  }

  reset = () => {
    this.pinState = false;
    this.pinSpeed = 0;
    this.lastTimestamp = 0;
  }

  render = () => (
    <LedElement
      ref={this.ref}
      id={this.id}
      key={`led-${this.pin}`}
      rotation={this.rotation}
      color={this.variant.color}
      value={(this.pinSpeed > 0)}
      speed={this.pinSpeed}
    />
  );
}
