import React from 'react';
import Text from '@components/text';
import {
  ExclamationIcon,
  CircularCheckIcon,
  CircularArrowUp,
  PentagonWarning,
  UserRights,
  FileSend,
  FileCheck,
  FileCopy,
} from '@assets/icons';
import styles from './toast.mod.scss';
import { TOAST_TYPE } from '../features/constants';

const ToastIcon = ({ type }) => ({
  [TOAST_TYPE.DEFAULT]: <ExclamationIcon />,
  [TOAST_TYPE.INFO]: <ExclamationIcon />,
  [TOAST_TYPE.WARNING]: <PentagonWarning />,
  [TOAST_TYPE.ERROR]: <ExclamationIcon />,
  [TOAST_TYPE.SUCCESS]: <CircularCheckIcon />,
  [TOAST_TYPE.CODE_SENT]: <CircularArrowUp />,
  [TOAST_TYPE.CODE_SENDING]: <FileSend />,
  [TOAST_TYPE.CODE_UPLOADED]: <FileCheck />,
  [TOAST_TYPE.CODE_REPLACED]: <FileCopy />,
  [TOAST_TYPE.TRASFER_PERMISSION]: <UserRights />,
})[type] || <ExclamationIcon />;

function Toast({ type, title = '', message = '' }) {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <ToastIcon type={type} />
      </div>
      <div className={styles.titleContainer}>
        {title && (
        <div className={title ? styles.titleWrapper : styles.titleWrapperOnly}>
          <Text className={styles.title}>
            {title}
          </Text>
        </div>
        )}
        {message && (
        <div className={styles.messageWrapper}>
          <Text className={styles.message}>
            {message}
          </Text>
        </div>
        )}
      </div>
    </div>
  );
}

export default Toast;
