import React, { Component } from 'react';
import Slider from '@mui/material/Slider';
import './joystick.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class JoystickElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xAxis: 512,
      yAxis: 512,
      xAxisPos: 0,
      yAxisPos: 0,
      pressed: false,
      sliderOpen: true,
      rotation: props.rotation || 0,
      id: props.id || '',
      onClickHandler: props.onClickHandler || null,
    }

    this.render = this.render.bind(this);
    this.openSlider = this.openSlider.bind(this);
    this.handlexAxisChange = this.handlexAxisChange.bind(this);
    this.handleyAxisChange = this.handleyAxisChange.bind(this);
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  handlexAxisChange = (event, newValue) => {
    const { onClickHandler } = this.state;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ xAxis: newValue, xAxisPos: (512 - newValue) / 30 });
    onClickHandler('X', newValue);
  }

  handleyAxisChange = (event, newValue) => {
    const { onClickHandler } = this.state;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ yAxis: newValue, yAxisPos: (512 - newValue) / 30 });
    onClickHandler('Y', newValue);
  }

  down = (e) => {
    const { onClickHandler, pressed } = this.state;
    e.preventDefault();
    e.stopPropagation();
    if (!pressed) {
      this.setState({ pressed: true });
      onClickHandler('button', 1023);
    } else {
      return;
    }
  }

  up = (e) => {
    const { onClickHandler, pressed } = this.state;
    e.preventDefault();
    e.stopPropagation();
    if (pressed) {
      this.setState({ pressed: false });
      onClickHandler('button', 0);
    } else {
      return;
    }
  }

  render = () => {
    const { id, sliderOpen, xAxis, yAxis, xAxisPos, yAxisPos, rotation, pressed } = this.state;
    const buttonCenter = pressed ? '#949596' : '#ece9e7';
    const buttonEdge = pressed ? '#333333' : '#4f5455';

    return (
      <div className="joystick" id={id}>
        <div className={`slider${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`}>
          <div className="xAxis">
            <div className="icon">X</div>
            <Slider
              min={0}
              max={1023}
              step={1}
              marks
              defaultValue={512}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handlexAxisChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{xAxis}</div>
          </div>
          <div className="yAxis">
            <div className="icon">Y</div>
            <Slider
              min={0}
              max={1023}
              step={1}
              marks
              defaultValue={512}
              className="bar"
              getAriaValueText={(v) => `${v}`}
              valueLabelDisplay="auto"
              onChange={this.handleyAxisChange}
              aria-labelledby="input-slider"
            />
            <div className="value">{yAxis}</div>
          </div>
        </div>

        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>

        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 171.57 113.68"
          width="27mm"
          height="18mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <defs>
            <radialGradient id="Radial_acero" data-name="Radial acero" cx="86.44" cy="81.82" fx="86.44" fy="81.82" r="37.8" gradientTransform="translate(29.62 143.3) rotate(-90)" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color={buttonCenter} />
              {/* <stop offset=".5" stop-color="#b8b8b8" /> */}
              <stop offset="1" stop-color={buttonEdge} />
            </radialGradient>
          </defs>

          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <path class="cls-1" d="m110.28,112.08v-2.78c0-2.5,2.03-4.53,4.54-4.54,2.51,0,4.54,2.03,4.54,4.54v2.81c0,.78.63,1.41,1.41,1.42h46.41c2.34.01,4.25-1.88,4.26-4.23,0,0,0-.01,0-.02V4.4c0-2.35-1.9-4.25-4.25-4.25h-46.42c-.78.01-1.41.64-1.42,1.43v2.81s0,0,0,0c0,2.5-2.03,4.53-4.54,4.53s-4.53-2.03-4.53-4.54V1.6c-.01-.8-.66-1.45-1.46-1.45h-44.75c-.78,0-1.41.64-1.42,1.42v2.81s0,0,0,0c0,2.5-2.03,4.53-4.54,4.53s-4.53-2.03-4.53-4.54V1.6c-.01-.8-.66-1.45-1.46-1.45H5.61c-2.35,0-4.25,1.9-4.24,4.25v104.89c0,2.34,1.9,4.24,4.24,4.25,0,0,0,0,.01,0h46.52c.8,0,1.45-.65,1.45-1.45v-2.78c0-2.5,2.03-4.53,4.54-4.54s4.54,2.03,4.54,4.54v2.81c0,.78.63,1.41,1.41,1.42h44.75c.8,0,1.45-.64,1.45-1.44" />
              </g>
              <g id="ARC">
                <path class="cls-2" d="m111.44,84.93c15.51,0,28.08-12.57,28.08-28.08,0-7.45-2.96-14.59-8.22-19.86" />
              </g>
              <g id="ARC-2" data-name="ARC">
                <path class="cls-2" d="m91.58,76.71c5.27,5.27,12.41,8.22,19.86,8.22" />
              </g>
              <g id="ARC-3" data-name="ARC">
                <path class="cls-2" d="m111.44,28.77c-15.51,0-28.08,12.57-28.08,28.08,0,7.45,2.96,14.59,8.22,19.86" />
              </g>
              <g id="ARC-4" data-name="ARC">
                <path class="cls-2" d="m131.29,37c-5.27-5.27-12.41-8.22-19.86-8.22" />
              </g>
              <g id="CIRCLE">
                <path class="cls-3" d="m58.06,62.51c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <rect class="cls-9" x="1.5" y="39.71" width="42.52" height="34.58" />
              </g>
              <g>
                <g id="LINE-3" data-name="LINE">
                  <path class="cls-15" d="m29.64,31.2h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04" />
                </g>
                <rect class="cls-14" x="26.23" y="31" width="2.27" height="2.67" transform="translate(-4.97 59.71) rotate(-90)" />
              </g>
              <rect class="cls-12" x="75.95" y="78.32" width="4.49" height="2.27" transform="translate(157.65 1.25) rotate(90)" />
              <rect class="cls-13" x="76.92" y="78.3" width="2.55" height="2.27" transform="translate(157.63 1.23) rotate(90)" />
              <rect class="cls-12" x="75.95" y="32.98" width="4.49" height="2.27" transform="translate(112.31 -44.09) rotate(90)" />
              <rect class="cls-13" x="76.92" y="32.96" width="2.55" height="2.27" transform="translate(112.29 -44.11) rotate(90)" />
              <polygon class="cls-10" points="54.67 0 54.67 5.28 54.67 5.62 54.81 6.29 55.07 6.91 55.46 7.47 55.71 7.71 55.94 7.94 56.48 8.29 57.08 8.53 57.72 8.66 58.05 8.66 58.1 8.66 58.44 8.66 59.1 8.53 59.73 8.27 60.29 7.9 60.53 7.67 60.76 7.44 61.13 6.9 61.39 6.3 61.52 5.67 61.52 5.34 61.52 0 68.03 0 68.03 16.14 63.73 20.32 52.4 20.32 48.09 16.14 48.09 0 54.67 0" />
              <polygon class="cls-10" points="111.67 .02 111.67 5.31 111.67 5.64 111.81 6.31 112.07 6.93 112.46 7.5 112.71 7.73 112.94 7.96 113.48 8.31 114.08 8.55 114.72 8.68 115.05 8.68 115.1 8.68 115.44 8.68 116.11 8.55 116.73 8.29 117.29 7.93 117.53 7.69 117.76 7.46 118.14 6.92 118.39 6.32 118.52 5.69 118.52 5.36 118.52 .02 125.03 .02 125.03 16.16 120.73 20.34 109.4 20.34 105.1 16.16 105.1 .02 111.67 .02" />
              <polygon class="cls-10" points="61.88 113.68 61.88 108.39 61.88 108.06 61.75 107.39 61.48 106.77 61.09 106.2 60.85 105.97 60.62 105.74 60.07 105.39 59.47 105.15 58.83 105.02 58.51 105.02 58.45 105.02 58.11 105.02 57.45 105.15 56.83 105.41 56.26 105.77 56.03 106.01 55.79 106.24 55.42 106.78 55.17 107.37 55.03 108.01 55.03 108.33 55.03 113.68 48.52 113.68 48.52 97.54 52.82 93.36 64.16 93.36 68.46 97.54 68.46 113.68 61.88 113.68" />
              <polygon class="cls-10" points="118.34 113.65 118.34 108.36 118.34 108.02 118.2 107.36 117.94 106.74 117.55 106.17 117.31 105.93 117.08 105.71 116.53 105.36 115.93 105.11 115.29 104.99 114.96 104.99 114.91 104.99 114.57 104.99 113.91 105.12 113.28 105.37 112.72 105.74 112.48 105.97 112.25 106.2 111.88 106.74 111.62 107.34 111.49 107.98 111.49 108.3 111.49 113.65 104.98 113.65 104.98 97.51 109.28 93.32 120.62 93.32 124.92 97.51 124.92 113.65 118.34 113.65" />

              <line class="cls-4" x1="29.61" y1="86.39" x2="29.61" y2="85.49" />
              <line class="cls-4" x1="29.54" y1="86.39" x2="29.54" y2="85.49" />
              <line class="cls-4" x1="29.47" y1="86.39" x2="29.47" y2="85.49" />
              <line class="cls-4" x1="29.4" y1="86.39" x2="29.4" y2="85.49" />
              <line class="cls-4" x1="29.33" y1="86.39" x2="29.33" y2="85.49" />
              <line class="cls-4" x1="29.26" y1="86.39" x2="29.26" y2="85.49" />
              <line class="cls-4" x1="29.19" y1="86.39" x2="29.19" y2="85.49" />
              <line class="cls-4" x1="29.12" y1="86.39" x2="29.12" y2="85.49" />
              <line class="cls-4" x1="29.04" y1="86.39" x2="29.04" y2="85.49" />
              <line class="cls-4" x1="28.97" y1="86.39" x2="28.97" y2="85.49" />
              <line class="cls-4" x1="28.9" y1="86.39" x2="28.9" y2="85.49" />
              <line class="cls-4" x1="28.83" y1="86.39" x2="28.83" y2="85.49" />
              <line class="cls-4" x1="28.76" y1="86.39" x2="28.76" y2="85.49" />
              <line class="cls-4" x1="28.69" y1="86.39" x2="28.69" y2="85.49" />
              <line class="cls-4" x1="28.62" y1="86.39" x2="28.62" y2="85.49" />
              <line class="cls-4" x1="28.54" y1="86.39" x2="28.54" y2="85.49" />
              <line class="cls-4" x1="28.47" y1="86.39" x2="28.47" y2="85.49" />
              <line class="cls-4" x1="28.4" y1="86.39" x2="28.4" y2="85.49" />
              <line class="cls-4" x1="28.33" y1="86.39" x2="28.33" y2="85.49" />
              <line class="cls-4" x1="28.26" y1="86.39" x2="28.26" y2="85.49" />
              <line class="cls-4" x1="28.19" y1="86.39" x2="28.19" y2="85.49" />
              <line class="cls-4" x1="28.12" y1="86.39" x2="28.12" y2="85.49" />
              <line class="cls-4" x1="28.05" y1="86.39" x2="28.05" y2="85.49" />
              <line class="cls-4" x1="27.97" y1="86.39" x2="27.97" y2="85.49" />
              <line class="cls-4" x1="27.9" y1="86.39" x2="27.9" y2="85.49" />
              <line class="cls-4" x1="30.61" y1="85.56" x2="30.61" y2="84.66" />
              <line class="cls-4" x1="31.33" y1="85.56" x2="31.33" y2="84.66" />
              <line class="cls-4" x1="31.26" y1="85.56" x2="31.26" y2="84.66" />
              <line class="cls-4" x1="31.18" y1="85.56" x2="31.18" y2="84.66" />
              <line class="cls-4" x1="31.11" y1="85.56" x2="31.11" y2="84.66" />
              <line class="cls-4" x1="31.04" y1="85.56" x2="31.04" y2="84.66" />
              <line class="cls-4" x1="30.97" y1="85.56" x2="30.97" y2="84.66" />
              <line class="cls-4" x1="30.9" y1="85.56" x2="30.9" y2="84.66" />
              <line class="cls-4" x1="30.83" y1="85.56" x2="30.83" y2="84.66" />
              <line class="cls-4" x1="30.76" y1="85.56" x2="30.76" y2="84.66" />
              <line class="cls-4" x1="30.68" y1="85.56" x2="30.68" y2="84.66" />
              <line class="cls-4" x1="30.54" y1="85.56" x2="30.54" y2="84.66" />
              <line class="cls-4" x1="30.47" y1="86.39" x2="30.47" y2="84.66" />
              <line class="cls-4" x1="30.4" y1="86.39" x2="30.4" y2="85.49" />
              <line class="cls-4" x1="30.33" y1="86.39" x2="30.33" y2="85.49" />
              <line class="cls-4" x1="30.26" y1="86.39" x2="30.26" y2="85.49" />
              <line class="cls-4" x1="30.19" y1="86.39" x2="30.19" y2="85.49" />
              <line class="cls-4" x1="30.11" y1="86.39" x2="30.11" y2="85.49" />
              <line class="cls-4" x1="30.04" y1="86.39" x2="30.04" y2="85.49" />
              <line class="cls-4" x1="29.97" y1="86.39" x2="29.97" y2="85.49" />
              <line class="cls-4" x1="29.9" y1="86.39" x2="29.9" y2="85.49" />
              <line class="cls-4" x1="29.83" y1="86.39" x2="29.83" y2="85.49" />
              <line class="cls-4" x1="29.76" y1="86.39" x2="29.76" y2="85.49" />
              <line class="cls-4" x1="29.69" y1="86.39" x2="29.69" y2="85.49" />
              <line class="cls-4" x1="29.61" y1="80.36" x2="29.61" y2="79.45" />
              <line class="cls-4" x1="29.54" y1="80.36" x2="29.54" y2="79.45" />
              <line class="cls-4" x1="29.47" y1="80.36" x2="29.47" y2="79.45" />
              <line class="cls-4" x1="29.4" y1="80.36" x2="29.4" y2="79.45" />
              <line class="cls-4" x1="29.33" y1="80.36" x2="29.33" y2="79.45" />
              <line class="cls-4" x1="29.26" y1="80.36" x2="29.26" y2="79.45" />
              <line class="cls-4" x1="29.19" y1="80.36" x2="29.19" y2="79.45" />
              <line class="cls-4" x1="29.12" y1="80.36" x2="29.12" y2="79.45" />
              <line class="cls-4" x1="29.04" y1="80.36" x2="29.04" y2="79.45" />
              <line class="cls-4" x1="28.97" y1="80.36" x2="28.97" y2="79.45" />
              <line class="cls-4" x1="28.9" y1="80.36" x2="28.9" y2="79.45" />
              <line class="cls-4" x1="28.83" y1="80.36" x2="28.83" y2="79.45" />
              <line class="cls-4" x1="28.76" y1="80.36" x2="28.76" y2="79.45" />
              <line class="cls-4" x1="28.69" y1="80.36" x2="28.69" y2="79.45" />
              <line class="cls-4" x1="28.62" y1="80.36" x2="28.62" y2="79.45" />
              <line class="cls-4" x1="28.54" y1="80.36" x2="28.54" y2="79.45" />
              <line class="cls-4" x1="28.47" y1="80.36" x2="28.47" y2="79.45" />
              <line class="cls-4" x1="28.4" y1="80.36" x2="28.4" y2="79.45" />
              <line class="cls-4" x1="28.33" y1="80.36" x2="28.33" y2="79.45" />
              <line class="cls-4" x1="28.26" y1="80.36" x2="28.26" y2="79.45" />
              <line class="cls-4" x1="28.19" y1="80.36" x2="28.19" y2="79.45" />
              <line class="cls-4" x1="28.12" y1="80.36" x2="28.12" y2="79.45" />
              <line class="cls-4" x1="28.05" y1="80.36" x2="28.05" y2="79.45" />
              <line class="cls-4" x1="27.97" y1="80.36" x2="27.97" y2="79.45" />
              <line class="cls-4" x1="27.9" y1="80.36" x2="27.9" y2="79.45" />
              <line class="cls-4" x1="30.61" y1="81.19" x2="30.61" y2="80.29" />
              <line class="cls-4" x1="31.33" y1="81.19" x2="31.33" y2="80.29" />
              <line class="cls-4" x1="31.26" y1="81.19" x2="31.26" y2="80.29" />
              <line class="cls-4" x1="31.18" y1="81.19" x2="31.18" y2="80.29" />
              <line class="cls-4" x1="31.11" y1="81.19" x2="31.11" y2="80.29" />
              <line class="cls-4" x1="31.04" y1="81.19" x2="31.04" y2="80.29" />
              <line class="cls-4" x1="30.97" y1="81.19" x2="30.97" y2="80.29" />
              <line class="cls-4" x1="30.9" y1="81.19" x2="30.9" y2="80.29" />
              <line class="cls-4" x1="30.83" y1="81.19" x2="30.83" y2="80.29" />
              <line class="cls-4" x1="30.76" y1="81.19" x2="30.76" y2="80.29" />
              <line class="cls-4" x1="30.68" y1="81.19" x2="30.68" y2="80.29" />
              <line class="cls-4" x1="30.4" y1="80.36" x2="30.4" y2="79.45" />
              <line class="cls-4" x1="30.33" y1="80.36" x2="30.33" y2="79.45" />
              <line class="cls-4" x1="30.26" y1="80.36" x2="30.26" y2="79.45" />
              <line class="cls-4" x1="30.19" y1="80.36" x2="30.19" y2="79.45" />
              <line class="cls-4" x1="30.11" y1="80.36" x2="30.11" y2="79.45" />
              <line class="cls-4" x1="30.04" y1="80.36" x2="30.04" y2="79.45" />
              <line class="cls-4" x1="29.97" y1="80.36" x2="29.97" y2="79.45" />
              <line class="cls-4" x1="29.9" y1="80.36" x2="29.9" y2="79.45" />
              <line class="cls-4" x1="29.83" y1="80.36" x2="29.83" y2="79.45" />
              <line class="cls-4" x1="29.76" y1="80.36" x2="29.76" y2="79.45" />
              <line class="cls-4" x1="30.54" y1="81.19" x2="30.54" y2="80.29" />
              <line class="cls-4" x1="30.47" y1="81.19" x2="30.47" y2="79.45" />
              <line class="cls-4" x1="29.69" y1="80.36" x2="29.69" y2="79.45" />
              <line class="cls-4" x1="32.25" y1="84.66" x2="32.25" y2="81.19" />
              <line class="cls-4" x1="32.18" y1="84.66" x2="32.18" y2="81.19" />
              <line class="cls-4" x1="32.11" y1="84.66" x2="32.11" y2="81.19" />
              <line class="cls-4" x1="32.04" y1="84.66" x2="32.04" y2="81.19" />
              <line class="cls-4" x1="31.97" y1="84.66" x2="31.97" y2="81.19" />
              <line class="cls-4" x1="31.9" y1="84.66" x2="31.9" y2="81.19" />
              <line class="cls-4" x1="31.83" y1="84.66" x2="31.83" y2="81.19" />
              <line class="cls-4" x1="31.75" y1="84.66" x2="31.75" y2="81.19" />
              <line class="cls-4" x1="31.61" y1="84.66" x2="31.61" y2="81.19" />
              <line class="cls-4" x1="31.54" y1="84.66" x2="31.54" y2="81.19" />
              <line class="cls-4" x1="31.47" y1="84.66" x2="31.47" y2="81.19" />
              <line class="cls-4" x1="31.68" y1="84.66" x2="31.68" y2="81.19" />
              <line class="cls-4" x1="23.91" y1="83.82" x2="23.91" y2="82.02" />
              <line class="cls-4" x1="23.84" y1="83.82" x2="23.84" y2="82.02" />
              <line class="cls-4" x1="23.77" y1="83.82" x2="23.77" y2="82.02" />
              <line class="cls-4" x1="23.69" y1="83.82" x2="23.69" y2="82.02" />
              <line class="cls-4" x1="23.62" y1="83.82" x2="23.62" y2="82.02" />
              <line class="cls-4" x1="23.55" y1="83.82" x2="23.55" y2="82.02" />
              <line class="cls-4" x1="23.48" y1="83.82" x2="23.48" y2="82.02" />
              <line class="cls-4" x1="23.41" y1="83.82" x2="23.41" y2="82.02" />
              <line class="cls-4" x1="23.34" y1="83.82" x2="23.34" y2="82.02" />
              <line class="cls-4" x1="23.27" y1="83.82" x2="23.27" y2="82.02" />
              <line class="cls-4" x1="23.2" y1="83.82" x2="23.2" y2="82.02" />
              <line class="cls-4" x1="23.12" y1="83.82" x2="23.12" y2="82.02" />
              <line class="cls-4" x1="23.05" y1="83.82" x2="23.05" y2="82.02" />
              <line class="cls-4" x1="22.98" y1="83.82" x2="22.98" y2="82.02" />
              <line class="cls-4" x1="22.91" y1="83.82" x2="22.91" y2="82.02" />
              <line class="cls-4" x1="22.84" y1="83.82" x2="22.84" y2="82.02" />
              <line class="cls-4" x1="22.77" y1="83.82" x2="22.77" y2="82.02" />
              <line class="cls-4" x1="22.7" y1="83.82" x2="22.7" y2="82.02" />
              <line class="cls-4" x1="22.62" y1="83.82" x2="22.62" y2="82.02" />
              <line class="cls-4" x1="22.55" y1="83.82" x2="22.55" y2="82.02" />
              <line class="cls-4" x1="22.48" y1="83.82" x2="22.48" y2="82.02" />
              <line class="cls-4" x1="24.91" y1="84.66" x2="24.91" y2="81.19" />
              <line class="cls-4" x1="25.05" y1="84.66" x2="25.05" y2="81.19" />
              <line class="cls-4" x1="24.98" y1="84.66" x2="24.98" y2="81.19" />
              <line class="cls-4" x1="24.84" y1="84.66" x2="24.84" y2="81.19" />
              <line class="cls-4" x1="24.76" y1="84.66" x2="24.76" y2="81.19" />
              <line class="cls-4" x1="24.69" y1="84.66" x2="24.69" y2="81.19" />
              <line class="cls-4" x1="24.62" y1="84.66" x2="24.62" y2="81.19" />
              <line class="cls-4" x1="24.55" y1="84.66" x2="24.55" y2="81.19" />
              <line class="cls-4" x1="24.48" y1="84.66" x2="24.48" y2="81.19" />
              <line class="cls-4" x1="24.41" y1="84.66" x2="24.41" y2="81.19" />
              <line class="cls-4" x1="24.34" y1="84.66" x2="24.34" y2="81.19" />
              <line class="cls-4" x1="24.26" y1="84.66" x2="24.26" y2="81.19" />
              <line class="cls-4" x1="24.19" y1="83.82" x2="24.19" y2="82.02" />
              <line class="cls-4" x1="24.12" y1="83.82" x2="24.12" y2="82.02" />
              <line class="cls-4" x1="24.05" y1="83.82" x2="24.05" y2="82.02" />
              <line class="cls-4" x1="23.98" y1="83.82" x2="23.98" y2="82.02" />
              <line class="cls-4" x1="27.83" y1="86.39" x2="27.83" y2="79.45" />
              <line class="cls-4" x1="27.76" y1="86.39" x2="27.76" y2="79.45" />
              <line class="cls-4" x1="27.69" y1="86.39" x2="27.69" y2="79.45" />
              <line class="cls-4" x1="27.62" y1="86.39" x2="27.62" y2="79.45" />
              <line class="cls-4" x1="27.55" y1="86.39" x2="27.55" y2="79.45" />
              <line class="cls-4" x1="27.47" y1="86.39" x2="27.47" y2="79.45" />
              <line class="cls-4" x1="27.4" y1="86.39" x2="27.4" y2="79.45" />
              <line class="cls-4" x1="27.33" y1="86.39" x2="27.33" y2="79.45" />
              <line class="cls-4" x1="27.26" y1="86.39" x2="27.26" y2="79.45" />
              <line class="cls-4" x1="27.12" y1="86.39" x2="27.12" y2="79.45" />
              <line class="cls-4" x1="27.05" y1="86.39" x2="27.05" y2="79.45" />
              <line class="cls-4" x1="26.98" y1="86.39" x2="26.98" y2="79.45" />
              <line class="cls-4" x1="27.19" y1="86.39" x2="27.19" y2="79.45" />
              <line class="cls-4" x1="31.4" y1="85.56" x2="31.4" y2="80.29" />
              <line class="cls-4" x1="26.83" y1="85.56" x2="26.83" y2="80.29" />
              <line class="cls-4" x1="26.76" y1="85.56" x2="26.76" y2="80.29" />
              <line class="cls-4" x1="26.69" y1="85.56" x2="26.69" y2="80.29" />
              <line class="cls-4" x1="26.62" y1="85.56" x2="26.62" y2="80.29" />
              <line class="cls-4" x1="26.55" y1="85.56" x2="26.55" y2="80.29" />
              <line class="cls-4" x1="26.48" y1="85.56" x2="26.48" y2="80.29" />
              <line class="cls-4" x1="26.4" y1="85.56" x2="26.4" y2="80.29" />
              <line class="cls-4" x1="26.33" y1="85.56" x2="26.33" y2="80.29" />
              <line class="cls-4" x1="26.26" y1="85.56" x2="26.26" y2="80.29" />
              <line class="cls-4" x1="26.19" y1="85.56" x2="26.19" y2="80.29" />
              <line class="cls-4" x1="26.12" y1="85.56" x2="26.12" y2="80.29" />
              <line class="cls-4" x1="26.05" y1="85.56" x2="26.05" y2="80.29" />
              <line class="cls-4" x1="25.91" y1="85.56" x2="25.91" y2="80.29" />
              <line class="cls-4" x1="25.83" y1="85.56" x2="25.83" y2="80.29" />
              <line class="cls-4" x1="25.76" y1="85.56" x2="25.76" y2="80.29" />
              <line class="cls-4" x1="25.69" y1="85.56" x2="25.69" y2="80.29" />
              <line class="cls-4" x1="25.62" y1="85.56" x2="25.62" y2="80.29" />
              <line class="cls-4" x1="25.55" y1="85.56" x2="25.55" y2="80.29" />
              <line class="cls-4" x1="25.48" y1="85.56" x2="25.48" y2="80.29" />
              <line class="cls-4" x1="25.41" y1="85.56" x2="25.41" y2="80.29" />
              <line class="cls-4" x1="25.33" y1="85.56" x2="25.33" y2="80.29" />
              <line class="cls-4" x1="25.26" y1="85.56" x2="25.26" y2="80.29" />
              <line class="cls-4" x1="25.19" y1="85.56" x2="25.19" y2="80.29" />
              <line class="cls-4" x1="25.12" y1="85.56" x2="25.12" y2="80.29" />
              <line class="cls-4" x1="25.98" y1="85.56" x2="25.98" y2="80.29" />
              <line class="cls-4" x1="26.9" y1="86.39" x2="26.9" y2="79.45" />
              <path class="cls-10" d="m107.76,49.88h113.64v9.69c0,2.34-1.9,4.25-4.25,4.25h-105.14c-2.34,0-4.25-1.9-4.25-4.25v-9.69h0Z" transform="translate(107.73 221.43) rotate(-90)" />
              <line class="cls-6" x1="91.36" y1="109.96" x2="91.36" y2="109.93" />
              <line class="cls-6" x1="81.54" y1="108.66" x2="81.54" y2="107" />
              <line class="cls-6" x1="92.67" y1="94.96" x2="91.26" y2="93.55" />
              <line class="cls-6" x1="91.36" y1="93.64" x2="91.34" y2="93.64" />
              <line class="cls-6" x1="76.35" y1="108.69" x2="74.94" y2="110.1" />
              <line class="cls-6" x1="83.2" y1="108.66" x2="83.2" y2="107.99" />
              <line class="cls-6" x1="89.81" y1="99.89" x2="88.15" y2="99.89" />
              <line class="cls-6" x1="89.81" y1="101.55" x2="89.15" y2="101.55" />
              <line class="cls-6" x1="91.53" y1="93.47" x2="90.15" y2="94.85" />
              <line class="cls-6" x1="91.61" y1="109.96" x2="75.03" y2="109.96" />
              <line class="cls-6" x1="89.81" y1="96.58" x2="84.84" y2="96.58" />
              <line class="cls-6" x1="78.23" y1="108.66" x2="78.23" y2="103.69" />
              <line class="cls-6" x1="79.89" y1="108.66" x2="79.89" y2="105.35" />
              <line class="cls-6" x1="76.45" y1="111.27" x2="75.07" y2="109.89" />
              <line class="cls-6" x1="89.81" y1="98.24" x2="86.5" y2="98.24" />
              <line class="cls-6" x1="91.36" y1="109.96" x2="91.36" y2="93.64" />
              <polygon class="cls-6" points="73.85 107.59 75.28 107.59 75.28 108.09 73.85 108.09 71.66 109.27 71.66 108.68 73.43 107.84 71.66 107 71.66 106.41 73.85 107.59" />
              <polygon class="cls-6" points="87.82 92.62 89.67 91.52 89.67 92.11 88.24 92.95 89.67 93.71 89.67 94.3 87.82 93.21 86.05 94.3 86.05 93.71 87.48 92.87 86.05 92.11 86.05 91.52 87.82 92.62" />
              <text class="cls-7" transform="translate(167.77 72.76) rotate(-90)"><tspan x="0" y="0">J</tspan><tspan class="cls-5" x="3.33" y="0">O</tspan><tspan class="cls-16" x="9.28" y="0">Y</tspan><tspan x="14.03" y="0">STICK</tspan></text>
              <text class="cls-8" transform="translate(153.32 14.89) rotate(-90)"><tspan x="0" y="0">59</tspan></text>


            </g>
          </g>
          <g
            onMouseDown={this.down}
            onMouseUp={this.up}
            id="ARC-5"
            data-name="ARC"
          >
            <circle transform={`translate(${yAxisPos} ${xAxisPos})`} class="cls-11 link" cx="111.44" cy="56.85" r="37.8" />
          </g>
        </svg>
      </div >
    );
  }
}
