import { blocksApi } from './api';

const getAll = () => blocksApi
  .get('/remote')
  .then((response) => response.data.data);

export const remoteSessionsService = {
  getAll,
};


