import { KIT_NAME, ProjectType } from '@sections/creabots/types/kits';

interface GetTutorialImagesInfoParams<K extends KIT_NAME> {
  kitName: K,
  projectName: ProjectType<K>,
  totalSteps: number,
}

export const getTutorialImagesInfo = <K extends KIT_NAME>(params: GetTutorialImagesInfoParams<K>): {text: string, src: string}[] => {
  const { kitName, projectName, totalSteps } = params;
  const intlPrefix = `creabots.tutorials.${kitName}.projects.${projectName}.build`
  const imgBaseUrl = `https://storage.googleapis.com/educabot-aec-cdn-1/labs/projects/${kitName}/${projectName}`
  const images = [];
  
  for (let i = 1; i <= totalSteps; i++) {
    images.push({
      text: `${intlPrefix}.step${i}`,
      src: `${imgBaseUrl}/${i}.png`,
    });
  }

  return images;
};