/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-useless-constructor: 0 */
/* eslint camelcase: 0 */
import * as Blockly from 'blockly';
import { FieldGridDropdown } from '@blockly/field-grid-dropdown';


export class JuniorFieldGridDropdown extends FieldGridDropdown {
  constructor(menuGenerator, validator, config) {
    super(menuGenerator, validator, config);
  }

  applyColour() {
    super.applyColour();
    // hack to update arrow disabled state
    let fill = (this.constants_) ? `url(#${this.constants_.disabledPatternId})` : '#FFAB19';
    const colours = this.getColours();
    if (colours && this.sourceBlock_.isEnabled()) {
      fill = colours.primary;
    }
    if (this.svgArrowBG) {
      this.svgArrowBG.setAttribute(
        'fill',
        `${fill}`,
      );
    }
  }

  initView() {
    this.clickTarget_ = this.sourceBlock_.getSvgRoot();
    this.createTextElement_();

    this.imageElement = Blockly.utils.dom.createSvgElement(Blockly.utils.Svg.IMAGE, {}, this.fieldGroup_);

    this.createSVGArrow_();

    if (this.menuGenerator_ && Array.isArray(this.menuGenerator_)) {
      this.menuGenerator_.map((value) => {
        if (value[0].selected) {
          this.selectedOption = value;
          this.setValue(this.selectedOption[1]);
        }
        return true;
      });
    }
  }

  createSVGArrow_() {
    let fill = '#FFAB19';
    let stroke = '#CF8B17';
    const colours = this.getColours();
    if (colours && colours.border) {
      fill = colours.primary;
      stroke = colours.border;
    }
    this.svgArrowBG = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        class: 'blocklyPath',
        stroke,
        fill,
        'fill-opacity': '1',
        d: 'm 16,0 A 16,16 0 0,0 0,16 V 16 a 16,16 0 0,0 16,16 H 32 a 16,16 0 0,0 16,-16 v 0 a 16,16 0 0,0 -16,-16 z',
      },
      this.fieldGroup_,
    );

    this.svgArrow = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.IMAGE,
      {
        height: (this.getConstants()) ? `${this.getConstants().FIELD_DROPDOWN_SVG_ARROW_SIZE}px` : '0px',
        width: (this.getConstants()) ? `${this.getConstants().FIELD_DROPDOWN_SVG_ARROW_SIZE}px` : '0px',
      },
      this.fieldGroup_,
    );
    if (this.svgArrow) {
      this.svgArrow.setAttributeNS(
        Blockly.utils.dom.XLINK_NS,
        'xlink:href',
        (this.getConstants()) ? this.getConstants().FIELD_DROPDOWN_SVG_ARROW_DATAURI : null,
      );
    }
  }

  positionSVGArrow(x, y) {
    if (!this.svgArrow) {
      return 0;
    }
    const offsetY = 20;
    const block = this.getSourceBlock();
    if (!block) {
      throw new Blockly.UnattachedFieldError();
    }
    const textPadding = this.getConstants().FIELD_DROPDOWN_SVG_ARROW_PADDING || 0;
    const svgArrowSize = this.getConstants().FIELD_DROPDOWN_SVG_ARROW_SIZE || 0;
    const xPos = (this.selectedOption[0].width / 2) - (this.getConstants().FIELD_DROPDOWN_SVG_ARROW_SIZE / 2);
    const yPos = this.selectedOption[0].height + offsetY;
    this.svgArrow.setAttribute(
      'transform',
      `translate(${xPos},${yPos})`,
    );
    this.svgArrowBG.setAttribute(
      'transform',
      `translate(${xPos - 18},${yPos - 10})`,
    );
    // return svgArrowSize + textPadding;
    return 0;
  }

  showPositionedByRect(bBox, field, opt_onHide, opt_secondaryYOffset) {
    const sourceBlock = field.getSourceBlock();
    // If we can fit it, render below the block.
    const primaryX = bBox.right;
    const primaryY = bBox.bottom - 25;

    // If we can't fit it, render above the entire parent block.
    const secondaryX = primaryX;
    let secondaryY = bBox.top;
    if (opt_secondaryYOffset) {
      secondaryY += opt_secondaryYOffset;
    }
    // Set bounds to main workspace; show the drop-down.
    let workspace = sourceBlock.workspace;
    while (workspace.options.parentWorkspace) {
      workspace = workspace.options.parentWorkspace;
    }
    Blockly.DropDownDiv.setBoundsElement(workspace.getParentSvg().parentNode);
    return Blockly.DropDownDiv.show(
      field,
      sourceBlock.RTL,
      primaryX,
      primaryY,
      secondaryX,
      secondaryY,
      opt_onHide,
    );
  }

  newGetHeightWidth(sourceBlock) {
    let height = sourceBlock.height;
    const width = sourceBlock.width;
    const nextBlock = sourceBlock.getNextBlock();
    if (nextBlock) {
      const nextHeightWidth = this.newGetHeightWidth(nextBlock);
      height = Math.max(height, nextHeightWidth.height);
      // width += nextHeightWidth.width - 2 * this.constants_.CORNER_RADIUS;
      // width -= 2 * this.constants_.CORNER_RADIUS;
    }
    return { height, width };
  }

  getScaledBboxOfField(field) {
    const sourceBlock = field.getSourceBlock();
    if (!sourceBlock) {
      throw new Blockly.UnattachedFieldError();
    }
    const bBox = this.newGetHeightWidth(field.sourceBlock_);
    const scale = sourceBlock.workspace.scale;
    const xy = Blockly.utils.style.getPageOffset(this.clickTarget_);
    const scaledWidth = (bBox.width + 1) * scale;
    const scaledHeight = (bBox.height + 1) * scale;

    if (Blockly.utils.userAgent.GECKO) {
      xy.x += 1.5 * scale;
      xy.y += 1.5 * scale;
    } else {
      xy.x -= 0.5 * scale;
      xy.y -= 0.5 * scale;
    }

    const newbBox = new Blockly.utils.Rect(xy.y, xy.y + scaledHeight, xy.x, xy.x + scaledWidth);
    const offsetX = -(41 * scale) - (sourceBlock.statementInputCount ? (5 * scale) : 0);
    const offsetY = 25 * scale;

    return new Blockly.utils.Rect(newbBox.top, newbBox.bottom + offsetY, newbBox.left, newbBox.right + offsetX);
  }

  newShowPositionedByField(field, opt_onHide, opt_secondaryYOffset) {
    Blockly.DropDownDiv.positionToField = true;
    Blockly.DropDownDiv.owner = field;
    return this.showPositionedByRect(
      this.getScaledBboxOfField(field),
      field,
      opt_onHide,
      opt_secondaryYOffset,
    );
  }

  showEditor_(e) {
    super.showEditor_(e);

    const colours = this.getColours();
    if (colours && colours.border) {
      Blockly.DropDownDiv.setColour(colours.primary, colours.border);
    }

    const menuElementGrid = (this.menu_) ? this.menu_.getElement() : null;
    if (menuElementGrid) {
      Blockly.utils.dom.addClass(menuElementGrid, 'fieldGridDropDownContainer');
    }
    this.updateColumnsStyling_();

    // Blockly.DropDownDiv.showPositionedByField(
    this.newShowPositionedByField(
      this,
      this.dropdownDispose_.bind(this),
    );

    // TODO: hack to draw dropdown widget on top of the rest of the page elements.
    // Check why is Lab's theme overwriting the z-index of the dropdown.
    if (document.querySelector('.blocklyDropDownDiv')) {
      const dropDownDiv = document.querySelector('.blocklyDropDownDiv');
      dropDownDiv.style.setProperty('z-index', '1000', 'important');
    }
  }
}