/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint no-useless-constructor: 0 */
/* eslint camelcase: 0 */
/* eslint prefer-template: 0 */
import * as Blockly from 'blockly';


export class JuniorFieldNumber extends Blockly.FieldNumber {
  constructor(value, min, max, precision, validator, config) {
    super(value, min, max, precision, validator, config);
    this.maxDisplayLength = 5;
    this.size_ = {
      height: 0,
      width: 0,
    }
    this.customSize_ = {
      xPos: -52,
      yPos: 25,
      height: 0,
      width: 0,
      padding: 10,
      maxWidth: 54,
    }
  }

  updateSize_() {
    super.updateSize_();

    const constants = this.getConstants();
    const xPadding = this.customSize_.padding;
    const totalWidth = this.customSize_.maxWidth;
    let totalHeight = constants?.FIELD_TEXT_HEIGHT;
    let contentWidth = 0;
    if (this.textElement_) {
      contentWidth = Blockly.utils.dom.getFastTextWidth(
        this.textElement_,
        constants?.FIELD_TEXT_FONTSIZE || null,
        constants?.FIELD_TEXT_FONTWEIGHT || null,
        constants?.FIELD_TEXT_FONTFAMILY || null,
      );
    }
    totalHeight = Math.max(totalHeight, constants?.FIELD_BORDER_RECT_HEIGHT || 16);

    this.customSize_.height = totalHeight;
    this.customSize_.width = totalWidth;
    this.positionTextElement_(xPadding, contentWidth);
    this.positionBorderRect_();

    this.size_.height = 0;
    this.size_.width = 0;
  }

  positionBorderRect_() {
    if (!this.borderRect_) {
      return;
    }
    this.borderRect_.setAttribute('x', this.customSize_.xPos);
    this.borderRect_.setAttribute('y', this.customSize_.yPos);
    this.borderRect_.style.fillOpacity = 1;

    this.borderRect_.setAttribute('width', String(this.customSize_.width));
    this.borderRect_.setAttribute('height', String(this.customSize_.height));
    this.borderRect_.setAttribute('rx', String(this.getConstants()?.FIELD_BORDER_RECT_RADIUS));
    this.borderRect_.setAttribute('ry', String(this.getConstants()?.FIELD_BORDER_RECT_RADIUS));
  }

  positionTextElement_(xPadding, contentWidth) {
    if (!this.textElement_) {
      return;
    }
    const constants = this.getConstants();
    const halfHeight = this.customSize_.height / 2;
    this.textElement_.setAttribute('x', this.customSize_.xPos + (this.customSize_.width - contentWidth) / 2);
    this.textElement_.setAttribute('y', this.customSize_.yPos + halfHeight);
  }

  widgetCreate_() {
    const htmlInput = super.widgetCreate_();

    const div = Blockly.WidgetDiv.getDiv();
    div.style.padding = '10px';

    return htmlInput;
  }
}
