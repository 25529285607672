/* eslint-disable */
import * as Blockly from 'blockly';
import {
  horizontalBlockMainRow,
  horizontalPreviousConnection,
  horizontalNextConnection,
  InRowSpacer,
} from './measurables/rows';
import { HorizontalStatementInputMeasurable } from './measurables/statement_input';
import { HorizontalInlineInput } from './measurables/inline_input';
import { JuniorFieldGridDropdown } from '../blocks/customBlocks/juniorFieldGridDropdown';
import { JuniorFieldNumber } from '../blocks/customBlocks/juniorFieldNumber';
import { JuniorFieldTextInput } from '../blocks/customBlocks/juniorFieldTextInput';


class RenderInfo extends Blockly.blockRendering.RenderInfo {
  constructor(renderer, block) {
    super(renderer, block);
    this.topRow = new Blockly.zelos.TopRow(this.constants_);
    this.bottomRow = new Blockly.zelos.BottomRow(this.constants_);
  }


  getRenderer() {
    return this.renderer_;
  };

  populateBottomRow_() {
    this.bottomRow.elements.push(new Blockly.blockRendering.RoundCorner(this.constants_));
    this.bottomRow.elements.push(new Blockly.blockRendering.RoundCorner(this.constants_, 'right'));
  };

  populateTopRow_() {
    this.topRow.elements.push(new Blockly.blockRendering.RoundCorner(this.constants_));
    this.topRow.elements.push(new Blockly.blockRendering.RoundCorner(this.constants_, 'right'));
  };

  createRows_() {
    this.populateTopRow_();
    this.rows.push(this.topRow);
    var activeRow = new horizontalBlockMainRow(this.constants_);

    if (this.block_.previousConnection) {
      this.previousConnection = new horizontalPreviousConnection(this.constants_, this.block_.previousConnection);
      // activeRow.elements.push(this.previousConnection);
    }

    // console.log('================createRows_', this.block_, this);
    for (var i = 0, input; (input = this.block_.inputList[i]); i++) {
      for (var j = 0, field; (field = input.fieldRow[j]); j++) {
        // console.log('================createRows_', this.block_, field, input, new Blockly.blockRendering.Field(this.constants_, field, input));
        activeRow.elements.push(new Blockly.blockRendering.Field(this.constants_, field, input));
      }
      this.addInput_(input, activeRow);
    }

    if (this.block_.nextConnection) {
      this.nextConnection = new horizontalNextConnection(this.constants_, this.block_.nextConnection);
      // activeRow.elements.push(this.nextConnection);
    }

    if (activeRow.elements.length || activeRow.hasDummyInput) {
      this.rows.push(activeRow);
    }

    this.populateBottomRow_();
    this.rows.push(this.bottomRow);
  };

  addInput_(input, activeRow) {
    if (this.isInline && input instanceof Blockly.inputs.ValueInput) {
      // console.log('================addInput_ isInline ValueInput', input);
      activeRow.elements.push(new HorizontalInlineInput(this.constants_, input));
      activeRow.hasInlineInput = true;
    } else if (input instanceof Blockly.inputs.StatementInput) {
      // console.log('================addInput_ StatementInput', input);
      activeRow.elements.push(
        new HorizontalStatementInputMeasurable(this.constants_, input, activeRow),
      );
      activeRow.hasStatement = true;
    } else if (input instanceof Blockly.inputs.ValueInput) {
      // console.log('================addInput_ ValueInput', input);
      // activeRow.elements.push(new ExternalValueInput(this.constants_, input));
      // activeRow.hasExternalInput = true;
    } else if (input instanceof Blockly.inputs.DummyInput || input instanceof Blockly.inputs.EndRowInput) {
      activeRow.minWidth = Math.max(
        activeRow.minWidth,
        input.getSourceBlock() && (input.getSourceBlock() ? input.getSourceBlock().isShadow() : false)
          ? this.constants_.DUMMY_INPUT_SHADOW_MIN_WIDTH
          : this.constants_.DUMMY_INPUT_MIN_WIDTH,
      );
      activeRow.minHeight = Math.max(
        activeRow.minHeight,
        input.getSourceBlock() && (input.getSourceBlock() ? input.getSourceBlock().isShadow() : false)
          ? this.constants_.DUMMY_INPUT_SHADOW_MIN_HEIGHT
          : this.constants_.DUMMY_INPUT_MIN_HEIGHT,
      );
      activeRow.hasDummyInput = true;
      // console.log('================addInput_ DummyInput or EndRowInput', input, activeRow);
    }
    if (activeRow.align === null) {
      activeRow.align = input.align;
    }
    // console.log('================addInput_----', activeRow);
  }

  getElemCenterline_(row, elem) {
    if (Blockly.blockRendering.Types.isSpacer(elem)) {
      return row.yPos + elem.height / 2;
    }
    if (Blockly.blockRendering.Types.isBottomRow(row)) {
      const bottomRow = row;
      const baseline = bottomRow.yPos + bottomRow.height - bottomRow.descenderHeight;
      if (Blockly.blockRendering.Types.isNextConnection(elem)) {
        return baseline + elem.height / 2;
      }
      return baseline - elem.height / 2;
    }
    if (Blockly.blockRendering.Types.isTopRow(row)) {
      const topRow = row;
      if (Blockly.blockRendering.Types.isHat(elem)) {
        return topRow.capline - elem.height / 2;
      }
      return topRow.capline + elem.height / 2;
    }
    if (row.hasStatement) {
      const connectedBlockHeight = Math.max(row.connectedBlockHeight, this.constants_.DUMMY_INPUT_MIN_HEIGHT + this.constants_.NOTCH_HEIGHT) + this.constants_.CORNER_RADIUS;
      // console.log('================getElemCenterline_', connectedBlockHeight, row, elem);
      return connectedBlockHeight - 2 * this.constants_.NOTCH_HEIGHT;
    } else {
      return row.yPos + row.height / 2;
    }
  }

  computeBounds_() {
    super.computeBounds_.call(this);

    if (this.previousConnection) {
      this.startX = this.previousConnection.width;
    }

  };

  finalize_() {
    super.finalize_.call(this);
    // console.log('================finalize_', this.block_.type, this.block_.inputList[0]?.fieldRow[0] instanceof JuniorFieldGridDropdown, this);
    if (this.previousConnection) {
      this.previousConnection.centerline = this.height / 2;
      this.previousConnection.xPos = 0;
    }

    if (this.nextConnection) {
      // console.log('================finalize_', this);
      this.nextConnection.centerline = this.height / 2;
      // this.nextConnection.xPos = this.width;
      this.nextConnection.xPos = this.widthWithChildren - (this.statementEdge ? 2 * this.constants_.CORNER_RADIUS : this.startX);
      this.widthWithChildren += (this.statementEdge) ? this.constants_.CORNER_RADIUS : 0;
      // add extra space to enlarge toolbox menu
      this.widthWithChildren += 2 * this.constants_.CORNER_RADIUS;
      if (!this.previousConnection) {
        this.nextConnection.xPos -= this.constants_.NOTCH_HEIGHT;
      }
    }
    // extra height added to enlarge toolbox menu
    let offset = 0;
    for (var i = 0, input; (input = this.block_.inputList[i]); i++) {
      for (var j = 0, field; (field = input.fieldRow[j]); j++) {
        offset = (field instanceof JuniorFieldGridDropdown || field instanceof JuniorFieldNumber || field instanceof JuniorFieldTextInput) ? this.constants_.EXTRA_HEIGHT_CUSTOM_BLOCKS : 0;
      }
    }
    this.height += offset;
  };

  addElemSpacing_() {
    let hasExternalInputs = false;
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      if (row.hasExternalInput) {
        hasExternalInputs = true;
        break;
      }
    }
    for (let i = 0; i < this.rows.length; i++) {
      const row = this.rows[i];
      const oldElems = row.elements;
      row.elements = [];
      // No spacing needed before the corner on the top row or the bottom row.
      if (row.startsWithElemSpacer()) {
        // if (row.startsWithElemSpacer() && this.previousConnection) {
        // There's a spacer before the first element in the row.
        row.elements.push(
          new InRowSpacer(
            this.constants_,
            this.getInRowSpacing_(null, oldElems[0]) + this.constants_.NOTCH_HEIGHT - 2,
          ),
        );
      }

      if (!oldElems.length) {
        continue;
      }
      for (let e = 0; e < oldElems.length - 1; e++) {
        row.elements.push(oldElems[e]);
        const spacing = this.getInRowSpacing_(oldElems[e], oldElems[e + 1]);
        // row.elements.push(new InRowSpacer(this.constants_, spacing + (this.constants_.NOTCH_WIDTH * 2)));
        row.elements.push(new InRowSpacer(this.constants_, spacing));
      }
      row.elements.push(oldElems[oldElems.length - 1]);

      if (row.endsWithElemSpacer()) {
        // if (row.endsWithElemSpacer() && this.nextConnection) {
        let spacing = this.getInRowSpacing_(
          oldElems[oldElems.length - 1],
          null,
        );
        if (hasExternalInputs && row.hasDummyInput) {
          spacing += this.constants_.TAB_WIDTH;
        }
        // There's a spacer after the last element in the row.
        row.elements.push(new InRowSpacer(this.constants_, spacing));
      }
    }
  }
}

export default RenderInfo;