import React from 'react';
import clsx from 'clsx';
import styles from './button.mod.scss';

const Button = ({ className, clasess = {}, children, onClick = () => {} }) => (
  <div
    onClick={onClick}
    className={clsx(className, styles.container, clasess.container)}
  >
    {children}
  </div>
);

export default Button;
