/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint  no-param-reassign: 0 */
export const initial_block = (block, generator) => {
  let branch = generator.statementToCode(block, 'Start');
  const loop = generator.statementToCode(block, 'Repeat');

  if (generator.INFINITE_LOOP_TRAP) {
    branch = `${generator.INFINITE_LOOP_TRAP.replace(/%1/g, `'${block.id}'`)}${branch}`;
  }

  generator.setups_['initial_block'] = `${branch}`;

  return loop;
};

export const math_number = (block, generator) => {
  const code = parseFloat(block.getField('NUM').getText());
  const order = code < 0 ? generator.ORDER_UNARY_PREFIX : generator.ORDER_ATOMIC;
  return [code, order];
};

export const base_delay = (block, generator) => {
  const num = generator.valueToCode(block, 'NUM', generator.ORDER_NONE) || '0';
  return `delay(${num});
  `;
};
