/* eslint-disable func-names */
// import { strict } from "assert";

const $ = window.$;

export default {

  normalizeBlocklyIds(id) {
    return id.replace(/[^a-zA-Z0-9]+/g, '');
  },

  buildBigBlock(id) {
    return `
    <div class="popup-modal-parent sevenSegments-modal-parent">
      <div class="popup-modal-under"></div>
      <div class="sevenSegments-modal initial-popup">
        <div class="pin-selector-digit">
          <div class="upper">
            <label>&nbsp;1
              <div class="digit-enable" id="pin-1">
                <input type="text" maxlength="2" class="pin" name="pin-digito-1-${id}" id="pin-digito-1-${id}" value="" />
                <span class="digit-button icon-minus-square"></span>
              </div>
            </label>
            <label>&nbsp;2
              <div class="digit-enable" id="pin-2">
                <input type="text" maxlength="2" class="pin" name="pin-digito-2-${id}" id="pin-digito-2-${id}" value="" />
                <span class="digit-button icon-minus-square"></span>
              </div>
            </label>
            <label>&nbsp;3
              <div class="digit-enable" id="pin-3">
                <input type="text" maxlength="2" class="pin" name="pin-digito-3-${id}" id="pin-digito-3-${id}" value="" />
                <span class="digit-button icon-minus-square"></span>
              </div>
            </label>
            <label>&nbsp;4
              <div class="digit-enable" id="pin-4">
                <input type="text" maxlength="2" class="pin" name="pin-digito-4-${id}" id="pin-digito-4-${id}" value="" />
                <span class="digit-button icon-minus-square"></span>
              </div>
            </label>
          </div>
        </div>
        <div class="pin-selector">
          <div class="upper">
            <label>a
              <input type="text" maxlength="2" class="pin" name="pin-segmento-1-${id}" id="pin-segmento-1-${id}" value="" />
            </label>
            <label>b
              <input type="text" maxlength="2" class="pin" name="pin-segmento-3-${id}" id="pin-segmento-3-${id}" value="" />
            </label>
            <label>c
              <input type="text" maxlength="2" class="pin" name="pin-segmento-6-${id}" id="pin-segmento-6-${id}" value="" />
            </label>
            <label>d
              <input type="text" maxlength="2" class="pin" name="pin-segmento-7-${id}" id="pin-segmento-7-${id}" value="" />
            </label>
            <label>e
              <input type="text" maxlength="2" class="pin" name="pin-segmento-5-${id}" id="pin-segmento-5-${id}" value="" />
            </label>
            <label>f
              <input type="text" maxlength="2" class="pin" name="pin-segmento-2-${id}" id="pin-segmento-2-${id}" value="" />
            </label>
            <label>g
              <input type="text" maxlength="2" class="pin" name="pin-segmento-4-${id}" id="pin-segmento-4-${id}" value="" />
            </label>
          </div>
        </div>
        <div class="container">
          <div class="row-horizontal">
            <div class="col-left"></div>
            <div class="col-center led">a</div>
            <div class="col-right"></div>
          </div>
          <div class="row-vertical">
            <div class="col-left led">f</div>
            <div class="col-center"></div>
            <div class="col-right led">b</div>
          </div>
          <div class="row-horizontal">
            <div class="col-left"></div>
            <div class="col-center led">g</div>
            <div class="col-right"></div>
          </div>
          <div class="row-vertical">
            <div class="col-left led">e</div>
            <div class="col-center"></div>
            <div class="col-right led">c</div>
          </div>
          <div class="row-horizontal">
            <div class="col-left"></div>
            <div class="col-center led">d</div>
            <div class="col-right"></div>
          </div>
        </div>

      </div>
      <div class="popup-modal-close sevenSegments-modal-close">
        <span class="icon-cross11"></span>
      </div>
    </div>`;
  },

  getMiniBlockHTML(block) {
    return `
    <div class="sevenSegments-mini-modal sevenSegments-mini-modal-${block.id}">
      <div class="container">
        <div class="row-horizontal">
          <div class="col-left"></div>
          <div class="col-center led ${(block.pinSegment[0].state) ? 'led-on' : ''}"></div>
          <div class="col-right"></div>
        </div>
        <div class="row-vertical">
          <div class="col-left led ${(block.pinSegment[1].state) ? 'led-on' : ''}"></div>
          <div class="col-center"></div>
          <div class="col-right led ${(block.pinSegment[2].state) ? 'led-on' : ''}"></div>
        </div>
        <div class="row-horizontal">
          <div class="col-left"></div>
          <div class="col-center led ${(block.pinSegment[3].state) ? 'led-on' : ''}"></div>
          <div class="col-right"></div>
        </div>
        <div class="row-vertical">
          <div class="col-left led ${(block.pinSegment[4].state) ? 'led-on' : ''}"></div>
          <div class="col-center"></div>
          <div class="col-right led ${(block.pinSegment[5].state) ? 'led-on' : ''}"></div>
        </div>
        <div class="row-horizontal">
          <div class="col-left"></div>
          <div class="col-center led ${(block.pinSegment[6].state) ? 'led-on' : ''}"></div>
          <div class="col-right"></div>
        </div>
      </div>
    </div>
  `;
  },

  buildMiniBlock(block, startUp = false) {
    const ele = document.querySelector(`[data-id='${block.id}']`);
    const eleText = document.querySelector(`[data-id='${block.id}'] text:nth-child(2)`);

    if (document.getElementById(`sevenSegments-${block.id}`)) {
      const foreignObject = document.getElementById(`sevenSegments-${block.id}`);
      foreignObject.innerHTML = this.getMiniBlockHTML(block);
    } else if (ele) {
      const ns = 'http://www.w3.org/2000/svg';
      const foreignObject = document.createElementNS(ns, 'foreignObject');
      foreignObject.setAttribute('class', 'sevenSegments');
      foreignObject.setAttribute('id', `sevenSegments-${block.id}`);
      foreignObject.innerHTML = this.getMiniBlockHTML(block);
      ele.insertBefore(foreignObject, eleText);
    }
    // block.workspace.fireChangeListener('resize');
    if (!startUp) {
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
    }
  },

  setSegmentInputListeners(block, id) {
    // const self = this;
    const normalizedBlockId = this.normalizeBlocklyIds(block.id);
    $('.blocklyWidgetDiv').on('keyup', `#pin-segmento-${id}-${normalizedBlockId}`, () => {
      // $(document).on('keyup', () => {
      if ($(`#pin-segmento-${id}-${normalizedBlockId}`).val() && /^[0-9]*$/.test($(`#pin-segmento-${id}-${normalizedBlockId}`).val())) {
        block.pinSegment[id - 1].pin = parseInt($(`#pin-segmento-${id}-${normalizedBlockId}`).val(), 10); // eslint-disable-line
      }
      // self.buildMiniBlock(block);
      // block.workspace.fireChangeListener('resize');
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
      // });
    });
    $('.blocklyWidgetDiv').on('blur', `#pin-segmento-${id}-${normalizedBlockId}`, () => {
      if (!$(`#pin-segmento-${id}-${normalizedBlockId}`).val() || /^[0-9]*$/.test($(`#pin-segmento-${id}-${normalizedBlockId}`).val()) === false) {
        $(`#pin-segmento-${id}-${normalizedBlockId}`).val(block.pinSegment[id - 1].pin);
        // self.buildMiniBlock(block);
        // block.workspace.fireChangeListener('resize');
        const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
        window.Blockly.Events.fire(event);
      }
    });
  },

  setPinInputListeners(block, id) {
    // const self = this;
    const normalizedBlockId = this.normalizeBlocklyIds(block.id);
    $('.blocklyWidgetDiv').on('keyup', `#pin-digito-${id}-${normalizedBlockId}`, () => {
      // $(document).on('keyup', () => {
      if ($(`#pin-digito-${id}-${normalizedBlockId}`).val() && /^[0-9]*$/.test($(`#pin-digito-${id}-${normalizedBlockId}`).val())) {
        block.pinDigit[id - 1].pin = parseInt($(`#pin-digito-${id}-${normalizedBlockId}`).val(), 10); // eslint-disable-line
      }
      // self.buildMiniBlock(block);
      // block.workspace.fireChangeListener('resize');
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
      // });
    });
    $('.blocklyWidgetDiv').on('blur', `#pin-digito-${id}-${normalizedBlockId}`, () => {
      if (!$(`#pin-digito-${id}-${normalizedBlockId}`).val() || /^[0-9]*$/.test($(`#pin-digito-${id}-${normalizedBlockId}`).val()) === false) {
        $(`#pin-digito-${id}-${normalizedBlockId}`).val(block.pinDigit[id - 1].pin);
        // self.buildMiniBlock(block);
        // block.workspace.fireChangeListener('resize');
        const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
        window.Blockly.Events.fire(event);
      }
    });
  },

  js(block) {
    const self = this;
    const normalizedBlockId = this.normalizeBlocklyIds(block.id);

    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildBigBlock(normalizedBlockId);
    $('.sevenSegments-modal div.led').each(function (index) {
      if (block.pinSegment[index].state) $(this).addClass('led-on'); // eslint-disable-line
    });
    $(`#pin-digito-1-${normalizedBlockId}`).val(block.pinDigit[0].pin);
    if (block.pinDigit[0].state) {
      $('#pin-1').addClass('selected');
      $('#pin-1 .digit-button').removeClass('icon-minus-square');
      $('#pin-1 .digit-button').addClass('icon-check-square');
    }
    $(`#pin-digito-2-${normalizedBlockId}`).val(block.pinDigit[1].pin);
    if (block.pinDigit[1].state) {
      $('#pin-2').addClass('selected');
      $('#pin-2 .digit-button').removeClass('icon-minus-square');
      $('#pin-2 .digit-button').addClass('icon-check-square');
    }
    $(`#pin-digito-3-${normalizedBlockId}`).val(block.pinDigit[2].pin);
    if (block.pinDigit[2].state) {
      $('#pin-3').addClass('selected');
      $('#pin-3 .digit-button').removeClass('icon-minus-square');
      $('#pin-3 .digit-button').addClass('icon-check-square');
    }
    $(`#pin-digito-4-${normalizedBlockId}`).val(block.pinDigit[3].pin);
    if (block.pinDigit[3].state) {
      $('#pin-4').addClass('selected');
      $('#pin-4 .digit-button').removeClass('icon-minus-square');
      $('#pin-4 .digit-button').addClass('icon-check-square');
    }

    $(`#pin-segmento-1-${normalizedBlockId}`).val(block.pinSegment[0].pin);
    $(`#pin-segmento-2-${normalizedBlockId}`).val(block.pinSegment[1].pin);
    $(`#pin-segmento-3-${normalizedBlockId}`).val(block.pinSegment[2].pin);
    $(`#pin-segmento-4-${normalizedBlockId}`).val(block.pinSegment[3].pin);
    $(`#pin-segmento-5-${normalizedBlockId}`).val(block.pinSegment[4].pin);
    $(`#pin-segmento-6-${normalizedBlockId}`).val(block.pinSegment[5].pin);
    $(`#pin-segmento-7-${normalizedBlockId}`).val(block.pinSegment[6].pin);
    window.Blockly.WidgetDiv.show(block, true);

    setTimeout(() => {
      $('.sevenSegments-modal-parent .sevenSegments-modal').removeClass('initial-popup');
    }, 600);

    // // UNBIND AND BIND
    $('.blocklyWidgetDiv').unbind('click');
    $('.blocklyWidgetDiv').unbind('mousedown mouseover');
    $('.blocklyWidgetDiv').on('mousedown mouseover', '.sevenSegments-modal div.led', function (e) {
      if (e.buttons === 1 || e.buttons === 3) {
        if ($(this).hasClass('led-on')) {
          $(this).removeClass('led-on');
        } else {
          $(this).addClass('led-on');
        }
      }

      if (e.buttons === 1) {
        $('.sevenSegments-modal div.led').each(function (index) {
          block.pinSegment[index].state = $(this).hasClass('led-on'); // eslint-disable-line
        });
        self.buildMiniBlock(block);
      }
    });

    $('.blocklyWidgetDiv').on('click', '.sevenSegments-modal-parent .sevenSegments-modal-close, .sevenSegments-modal-parent .popup-modal-under', () => {
      $('.sevenSegments-modal').addClass('popup-hide');
      $('.modal-sidebar').addClass('popup-hide');
      $('.sevenSegments-modal-close').addClass('popup-hide');
      setTimeout(() => {
        $('.blocklyWidgetDiv').unbind('click');
        window.Blockly.WidgetDiv.hide();
      }, 200);
    });


    // $('.blocklyWidgetDiv').on('click', '.digit-enable .digit-button', (e) => {
    $('.blocklyWidgetDiv').on('mousedown', '.digit-enable .digit-button', (e) => {
      if (e.buttons === 1 || e.buttons === 3) {
        if ($(e.target).hasClass('icon-minus-square')) {
          $(e.target).removeClass('icon-minus-square');
          $(e.target).addClass('icon-check-square');
          $(e.target).parent().addClass('selected');
        } else {
          $(e.target).addClass('icon-minus-square');
          $(e.target).removeClass('icon-check-square');
          $(e.target).parent().removeClass('selected');
        }
      }

      if (e.buttons === 1) {
        $('.digit-enable .digit-button').each(function (index) {
          block.pinDigit[index].state = $(this).hasClass('icon-check-square'); // eslint-disable-line
        });
        self.buildMiniBlock(block);
        // block.workspace.fireChangeListener('resize');
      }

      // console.log(e);
      // if ($(e.target).hasClass('icon-minus-square')) {
      //   $(e.target).removeClass('icon-minus-square');
      //   $(e.target).addClass('icon-check-square');
      //   block.pinDigit[index].state
      // }
    });

    // PIN INPUTS
    for (let i = 1; i < 5; i += 1) {
      this.setPinInputListeners(block, i);
    }

    for (let i = 1; i < 8; i += 1) {
      this.setSegmentInputListeners(block, i);
    }
  },
};
