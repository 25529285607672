import { useEffect, useState, useRef } from 'react'

export default function useBluetooth({ serviceID, characteristicID, namePrefix }) {
  const characteristicState = useRef(null)

  async function sendMessage(msg) {
    if (characteristicState.current) {
      const message = new TextEncoder().encode(msg)
      characteristicState.current.writeValueWithoutResponse(message)
    } else {
      console.log('not connected!')
    }
  }

  async function requestConnection() {
    const filters = []
    if (namePrefix) filters.push({ namePrefix })
    if (serviceID) filters.push({ services: [serviceID] })
    const foundDevice = await window.navigator.bluetooth.requestDevice({
      filters,
    })
    await foundDevice.gatt.connect()
    const service = await foundDevice.gatt.getPrimaryService(serviceID)
    const characteristic = await service.getCharacteristic(characteristicID)
    characteristicState.current = characteristic
  }

  return { requestConnection, sendMessage, connected: !!characteristicState.current }
}
