import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './video-react.scss';
import { useIntl } from 'react-intl';
import styles from './connectingModal.mod.scss';
import { userRoles } from '../features/constants';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import { TOAST_TYPE } from '@modules/toasts/features/constants';

const ConnectingModal = (props) => {
  const intl = useIntl();

  const [active, setActive] = useState(false);
  const [title, setTitle] = useState(intl.formatMessage({ id: 'collaboration.socketConnection.youAreDisconnectedTitle' }));
  const [message, setMessage] = useState(intl.formatMessage({ id: 'collaboration.socketConnection.youAreDisconnectedMessage' }));


  useEffect(() => {
    if (props.socket) {
      props.socket.on('disconnect', () => {
        setTitle(intl.formatMessage({ id: 'collaboration.socketConnection.youAreDisconnectedTitle' }));
        setMessage(intl.formatMessage({ id: 'collaboration.socketConnection.youAreDisconnectedMessage' }));
        setActive(true);
      });
      props.socket.on('authenticated', () => {
        setActive(false);
      });

      props.socket.on('managementIsGone', (role) => {
        if (role === userRoles.OWNER) {
          // TODO: enable a permanent toast message
          props.showToast({
            title: intl.formatMessage({ id: 'collaboration.socketConnection.ownerIsDisconnectedTitle' }),
            message: intl.formatMessage({ id: 'collaboration.socketConnection.ownerIsDisconnectedMessage' }),
            type: TOAST_TYPE.ERROR,
            options: { autoClose: 5000 }
          });
        } else {
          if (!props.userRole || props.userRole === userRoles.STUDENT) {
            setTitle(intl.formatMessage({ id: 'collaboration.socketConnection.leaderIsDisconnectedTitle' }));
            setMessage(intl.formatMessage({ id: 'collaboration.socketConnection.leaderIsDisconnectedMessage' }));
            setActive(true);
          }
        }
      });
      props.socket.on('managementIsBack', (role) => {
        if (role === userRoles.OWNER && props.userRole !== userRoles.OWNER) {
          // TODO: disable previous permanent toast message
          props.showToast({
            title: intl.formatMessage({ id: 'collaboration.socketConnection.ownerIsConnectedTitle' }),
            message: intl.formatMessage({ id: 'collaboration.socketConnection.ownerIsConnectedMessage' }),
            type: TOAST_TYPE.INFO,
            options: { autoClose: 3000 }
          });
        } else {
          setActive(false);
        }
      });
    }
  }, [props.socket]);

  return (
    <div className={`${(active) ? 'show' : 'fade'} loaderOverAll`}>
      <div className={styles.connectingModal}>
        <span className={styles.iconDesktopAccessDisabled} />
        <span className={styles.firstLine}>
          {title}
          <span className={styles.secondLine}>{message}</span>
        </span>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToastAction.showToast(params)),
  }
}

export default connect(null, mapDispatchToProps)(ConnectingModal);
// export default ConnectingModal;