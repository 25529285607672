import React from 'react'

export function LabsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={22}
      fill="none"
      {...props}
    >
      <path
        fill="#42B3FF"
        d="M5.16 21.196a.562.562 0 0 1-.795 0L.923 17.754a.563.563 0 0 1 0-.795l3.84-3.84L9 17.357l-3.84 3.84Z"
      />
      <path
        fill="#FFB800"
        d="M13.635 21.196a.562.562 0 0 1-.795 0L9 17.356l4.237-4.237 3.84 3.84c.22.22.22.576 0 .795l-3.442 3.442Z"
      />
      <path fill="#fff" d="m9 17.357-4.238-4.238L9 8.882l4.237 4.237L9 17.357Z" />
      <path
        fill="#FF6428"
        d="m9 8.882-3.84-3.84a.562.562 0 0 1 0-.796L8.602.804c.22-.22.576-.22.796 0l3.442 3.442c.22.22.22.576 0 .796L9 8.882Z"
      />
    </svg>
  )
}
