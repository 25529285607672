import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { AUTOMATION_PROJECTS_TYPE, BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getAutomationConfigForBlock, getAutomationElementForBlock } from '../config';
import { higherThan999Regex, moreThan5WordsRegex } from '@sections/creabots/features/utils/commonRegexs';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.automation.projects.smartAccess';
const imgBaseUrl = '/images/creabots/automation/projects/smartAccess';

export const smartAccess: Project = {
  id: 6,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.AUTOMATION,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/acceso-inteligente.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.AUTOMATION, projectName: AUTOMATION_PROJECTS_TYPE.SMART_ACCESS, totalSteps: 20 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        completionCode: 'voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);servo_A0\\.write\\(90\\);\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 15,
          },
        },
      ],
    },
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        completionCode: 'voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);servo_A0\\.write\\(140\\);\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 250,
            yOffset: 25,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.png`,
        completionCode: 'voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);servo_A0\\.write\\(80\\);\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 255,
            yOffset: 25,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        completionCode: 'voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("abc"\\);\\}voidloop\\(\\)\\{\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.DISPLAYS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 54,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.png`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);\\}voidloop\\(\\)\\{\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 170,
            yOffset: 73,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 4),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 47,
            yOffset: 145,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(0==0\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 1),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 57,
            yOffset: 185,
          },
        },
      ],
    },
    // Step 9.
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 2),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'A'\\)==0\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.COMUNICATION, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 108,
            yOffset: 192,
          },
        },
      ],
    },
    {
      step: {
        stepId: 8,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step9`,
        image: `${imgBaseUrl}/steps/step9.png`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==0\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 295,
            yOffset: 192,
          },
        },
      ],
    },
    // Step 10.
    {
      step: {
        stepId: 9,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step10`,
        image: `${imgBaseUrl}/steps/step10.gif`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==${higherThan999Regex}\\)\\{\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 430,
            yOffset: 192,
          },
        },
      ],
    },
    // Step 11.
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/steps/step11.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 2),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==${higherThan999Regex}\\)\\{moverServoGradualmente_A0\\(90,10\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 73,
            yOffset: 230,
          },
        },
      ],
    },
    {
      step: {
        stepId: 10,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step11`,
        image: `${imgBaseUrl}/steps/step11.png`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==${higherThan999Regex}\\)\\{moverServoGradualmente_A0\\(160,10\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 320,
            yOffset: 240,
          },
        },
      ],
    },
    // Step 12.
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/steps/step12.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==${higherThan999Regex}\\)\\{moverServoGradualmente_A0\\(160,10\\);delay\\(1000\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.CONTROL, 0),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 73,
            yOffset: 270,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/steps/step12.png`,
        ...getAutomationConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 2),
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==${higherThan999Regex}\\)\\{moverServoGradualmente_A0\\(160,10\\);delay\\(1000\\);moverServoGradualmente_A0\\(90,10\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getAutomationElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 2),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 73,
            yOffset: 305,
          },
        },
      ],
    },
    {
      step: {
        stepId: 11,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step12`,
        image: `${imgBaseUrl}/steps/step12.png`,
        completionCode: `voidsetup\\(\\)\\{servo_A0\\.attach\\(A0\\);lcd\\.init\\(\\);lcd\\.backlight\\(\\);servo_A0\\.write\\(80\\);lcd\\.setCursor\\(0,0\\);lcd\\.print\\("${moreThan5WordsRegex}"\\);BT1\\.begin\\(9600\\);\\}voidloop\\(\\)\\{if\\(BT1\\.available\\(\\)>0\\)\\{Stringmensaje=BT1\\.readString\\(\\);mensaje\\.trim\\(\\);if\\(leerNumeroConEtiqueta\\(mensaje,'C'\\)==${higherThan999Regex}\\)\\{moverServoGradualmente_A0\\(160,10\\);delay\\(1000\\);moverServoGradualmente_A0\\(80,10\\);\\}\\}\\}`,
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 320,
            yOffset: 325,
          },
        },
      ],
    },
    // Step 13.
    {
      step: {
        stepId: 12,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step13`,
        image: `${imgBaseUrl}/steps/step13.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 14.
    {
      step: {
        stepId: 13,
        modal: MODAL_TYPE.DOCKED,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/acceso-inteligente-step14.mp4',
      },
    },
    // Step 15.
    {
      step: {
        stepId: 14,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step15`,
        image: `${imgBaseUrl}/steps/step15.gif`,
      },
    },
  ],
};
