/* eslint-disable max-len */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import './ProjectSidebar.scss';

class ProjectSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      description: props.description || '',
      diagramImageUrl: props.diagramImageUrl || '',
      videoUrl: props.videoUrl || '',
      components: props.components || [],
      challenges: props.challenges || [],
      tags: props.tags || [],
      extraMaterialUrl: props.extraMaterialUrl || '',
      withObjetives: props.withObjetives || false,
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {
    const { name, description, diagramImageUrl, videoUrl, components, challenges, tags, extraMaterialUrl } = this.state;

    this.setState({
      name: newProps.name || name,
      description: newProps.description || description,
      diagramImageUrl: newProps.diagramImageUrl || diagramImageUrl,
      videoUrl: newProps.videoUrl || videoUrl,
      components: newProps.components || components,
      challenges: newProps.challenges || challenges,
      tags: newProps.tags || tags,
      extraMaterialUrl: newProps.extraMaterialUrl || extraMaterialUrl,
      withObjetives: newProps.withObjetives || false,
    });
  }

  render() {
    const { name, description, diagramImageUrl, videoUrl, components, challenges, tags, extraMaterialUrl, withObjetives } = this.state;
    const { intl } = this.props;

    return (
      <span className="sidebar-content">
        <div className="project-name">
          <h5>{intl.formatMessage({ id: 'blocks.blockly.project.details.project' })}</h5>
          <h3>{name || '...'}</h3>
        </div>
        <div className="project-info">
          <div className="project-description">
            <h4>{(withObjetives) ? intl.formatMessage({ id: 'blocks.blockly.project.details.goal' }) : intl.formatMessage({ id: 'blocks.blockly.project.details.description' })}</h4>
            <p>{description || '...'}</p>
          </div>
          {(components && components.length > 0) && (
            <div className="project-components">
              <h4>{intl.formatMessage({ id: 'blocks.blockly.project.details.components' })}</h4>
              <ul>
                {components.map(item => <li>{item}</li>)}
              </ul>
            </div>
          )}
          {(diagramImageUrl && diagramImageUrl !== '') && (
            <div className="project-connections">
              <h4>{intl.formatMessage({ id: 'blocks.blockly.project.details.conections' })}</h4>
              <img src={diagramImageUrl} alt="Diagramas" />
            </div>
          )}
          {(videoUrl && videoUrl !== '') && (
            <div className="project-video">
              <h4>{intl.formatMessage({ id: 'blocks.blockly.project.details.video' })}</h4>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe title="youtube" src={videoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              </div>
            </div>
          )}
          {(challenges && challenges.length > 0) && (
            <div className="project-challenges">
              <h4>{intl.formatMessage({ id: 'blocks.blockly.project.details.challenges' })}</h4>
              <ol>
                {challenges.map(item => (
                  <li>{item}</li>
                ))}
              </ol>
            </div>
          )}
          {(extraMaterialUrl && extraMaterialUrl !== '') && (
            <div className="project-extra-material">
              <a href={extraMaterialUrl} target="_blank" rel="noreferrer" className="btn btn-primary main-btn">{intl.formatMessage({ id: 'blocks.blockly.project.details.extraMaterial' })}</a>
            </div>
          )}
          {(tags && tags.length > 0) && (
            <div className="project-tags">
              <h4>{intl.formatMessage({ id: 'blocks.blockly.project.details.tags' })}</h4>
              <ol>
                {tags.map(item => (
                  <li>{item}</li>
                ))}
              </ol>
            </div>
          )}
        </div>
      </span>
    );
  }
}

export default injectIntl(ProjectSidebar);
