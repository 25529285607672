import React from 'react';
import { makeStyles } from '@mui/styles';
import ReactPaginate from 'react-paginate';
import { FormattedMessage } from 'react-intl';
import { getThemeVariable } from '../../cosmos';

const useStyles = makeStyles({
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 40px 40px 40px',
    listStyle: 'none',
    fontZize: '1.5em',
  },
  pageLink: {
    minWidth: '2.6em',
    display: 'block',
    borderRadius: 4,
    marginRight: '0.3em',
    padding: '0.5em',
    cursor: 'pointer',
    color: getThemeVariable('--color-on-surface'),
    '&:hover': {
      textDecoration: 'none',
      color: getThemeVariable('--color-primary-light'),
      backgroundColor: getThemeVariable('--color-surface-two'),
      borderColor: getThemeVariable('--color-surface-two'),
    },
    '&:focus': {
      borderColor: 'inherit',
      boxShadow: 'none',
    },
  },
  pageItem: {
    outline: 'none',
    boxSizing: 'border-box',
    listStyle: 'none',
    fontSize: '1.5em',
    minWidth: '2.6em',
    textAlign: 'center',
    '&.disabled a': {
      opacity: 0.5,
    },
  },
  active: {
    '& > a': {
      fontWeight: 700,
      backgroundColor: getThemeVariable('--color-background-three'),
      borderColor: getThemeVariable('--color-background-three'),
      color: getThemeVariable('--color-primary-light'),
    },
  },
});

const Paginator = (props) => {
  const { onPageChange, count, limit } = props;
  const classes = useStyles();
  const pageCount = Math.ceil(count / limit);

  return (
    pageCount > 1 && (
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FormattedMessage id="projects.pagination.next" />}
        previousLabel={<FormattedMessage id="projects.pagination.previous" />}
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName={classes.pagination}
        pageClassName={classes.pageItem}
        pageLinkClassName={classes.pageLink}
        previousClassName={classes.pageItem}
        previousLinkClassName={classes.pageLink}
        nextClassName={classes.pageItem}
        nextLinkClassName={classes.pageLink}
        breakClassName={classes.pageItem}
        breakLinkClassName={classes.pageLink}
        activeClassName={classes.active}
      />
    )
  );
};

export default Paginator;

Paginator.defaultProps = {
  onPageChange: () => {},
  count: 0,
  limit: 20,
};
