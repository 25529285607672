import React, { useEffect, useState } from 'react';
import styles from './modal.mod.scss';

function Modal({ children, open = false }) {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return open ? (
    <div className={isOpen ? styles.backdrop : styles.backdropOut}>
      <div className={isOpen ? styles.modal : styles.modalOut}>
        {children}
      </div>
    </div>
  ) : ('');
}

export default Modal;
