import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { checkAuthActionExternal } from '../../actions/authAction';

const seniorityLocations = ['bloques', 'junior', 'codigo'];

const LOCATIONS = {
  SENIOR: 'senior',
  JUNIOR: 'junior',
  CODE: 'code',
  BLOQUES: 'bloques',
};

const getLocationById = (currentLocation) => ({
  junior: LOCATIONS.JUNIOR,
  codigo: LOCATIONS.CODE,
  bloques: LOCATIONS.SENIOR,
})[currentLocation] || LOCATIONS.SENIOR;

const getCurrentLocation = (location, availableLocations) => location
  .pathname
  .split('/')
  .filter(Boolean)
  .find((path) => availableLocations.includes(path));

export const useUserLocation = () => {
  const location = useLocation();
  const currentLocation = getCurrentLocation(location, seniorityLocations);
  return getLocationById(currentLocation);
};

export const useUserSeniority = () => useUserLocation();

const matchUserRole = (userRoleNames, userTypes) => userRoleNames
  .some((roleName) => userTypes.includes(roleName));

export const useUserIsPremium = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.authReducer.userData);
  const userPremiumTypes = ['Contenidista', 'Contenidista Global', 'Profesor', 'Alumno Premium', 'Premium', 'Administrador'];
  // TODO add redux userData persistence
  useEffect(() => {
    dispatch(checkAuthActionExternal(false));
  }, []);

  return data ? matchUserRole(data.roles.names, userPremiumTypes) : false;
};

