import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="initial_block" deletable="false" />
  </xml>
);

/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Funciones" name="%{BKY_LANG_CATEGORY_PROCEDURES}" iconclass="fx" expandedclass="fx-extended" custom="PROCEDURE">
      <label text="%{BKY_LANG_CATEGORY_PROCEDURES}" />
    </category>
    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="control" expandedclass="control-extended">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_doWhile" />
    </category>
    <category id="Lógica" name="%{BKY_LANG_CATEGORY_LOGIC}" iconclass="logics" expandedclass="logics-extended">
      <label text="%{BKY_LANG_CATEGORY_LOGIC}" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
    </category>
    <category id="Matemáticas" name="%{BKY_LANG_CATEGORY_MATH}" iconclass="math" expandedclass="math-extended">
      <label text="%{BKY_LANG_CATEGORY_MATH}" />
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>

      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>

    </category>

    <category id="Variables" name="%{BKY_LANG_CATEGORY_VARIABLES}" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>

    <category name="%{BKY_LANG_CATEGORY_ENTRADAS}" iconclass="mixedInputs" expandedclass="mixedInputs-extended" expanded="true">
      <category id="Digitales" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}" iconclass="inputsDigi" expandedclass="inputsDigi-extended">
        <label text="%{BKY_LANG_SUBCATEGORY_DIGITALES}" />
        <block type="entradas_ultrasonido" />
        <block type="ENTRADAS_button" />
        <block type="ENTRADAS_button_touch" />
        <block type="SALIDAS_read_dht11" />
        <block type="BHOOT_line_follower" />
      </category>
      <category id="Analogicas" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" iconclass="inputsAnal" expandedclass="inputsAnal-extended">
        <label text="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" />
        <block type="entradas_suelo" />
        <block type="ENTRADAS_photoresistor" />
        <block type="ENTRADAS_potentiometer" />
      </category>
    </category>

    <category id="Salidas" name="%{BKY_LANG_CATEGORY_SALIDAS}" iconclass="outputs" expandedclass="outputs-extended">
      <label text="%{BKY_LANG_CATEGORY_SALIDAS}" />
      <block type="BHOOT_led_neopixel">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_neopixel_clear" />
      <block type="BHOOT_led_rgb">
        <value name="COLOUR1">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR2">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR3">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR4">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR5">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR6">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_salidas_led_rgb">
        <value name="DATOINR">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOING">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOINB">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_piezo_buzzer" />
      <block type="BHOOT_piezo_buzzerno" />
      <block type="salidas_led2" />
      <block type="salidas_led_brillo">
        <value name="DATOIN">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="Pantallas" name="%{BKY_LANG_CATEGORY_LCD}" iconclass="screens" expandedclass="screens-extended">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="matrix8x8" />
      <block type="matrix8x8_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_educabot">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_setBacklight" />
      <block type="lcd_clear" />
    </category>
    <category id="Motores" name="%{BKY_LANG_CATEGORY_SERVO}" iconclass="motors" expandedclass="motors-extended">
      <label text="%{BKY_LANG_CATEGORY_SERVO}" />
      <block type="BHOOT_servo_move">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_servo_read" />
      <block type="SALIDAS_motor" />
      <block type="BHOOT_motor_single" />
      <block type="BHOOT_motor_doble" />
      <block type="BHOOT_motor_doble_pwm">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_motor_single_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
    </category>
  </React.Fragment>
);
