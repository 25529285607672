/* eslint-disable func-names */
// import { strict } from "assert";

const $ = window.$;

export default {

  buildBigBlock(html, cols, shortBlockId) {
    return `
    <div class="matrix-modal-parent matrix-modal-parent-${cols}">
      <div class="matrix-modal-under">
      </div>
      <div class="matrix-modal matrix-modal-${cols} rotate-0 initial-popup">
        ${html}
      </div>
      <div class="matrix-modal-close">
        <span class="icon-cross11"></span>
      </div>
      <div class="modal-sidebar">
        <div class="modal-sidebar-main-panel"></div>
        <div class="modal-sidebar-buttons">
          <span class="color-picker color-picker-${shortBlockId} picker"></span>
          <span class="emotions-picker picker"></span>
          <span class="dice-picker picker"></span>
          <span class="icon-picker picker">I</span>
          <span class="rotate-button button"><span class="icon-rotate-left"></span></span>
          <span class="ruler-button button"><span class="icon-grid"></span></span>
          <span class="erase-button button"><span class="icon-delete"></span></span>
        </div>
      </div>
    </div>`;
  },

  buildMiniBlock(block, ledsPerRow, shortBlockId, startUp = false) {
    const ele = document.querySelector(`[data-id='${block.id}']`);
    const eleText = document.querySelector(`[data-id='${block.id}'] text:nth-child(2)`);

    let ledDivs = '';

    for (let i = 0; i < Object.keys(block.leds).length; i += 1) {
      const rowStart = ((i / ledsPerRow) % 1 === 0) ? '<div class="led-row">' : '';
      const rowEnd = (((i + 1) / ledsPerRow) % 1 === 0) ? '</div>' : '';
      const ledDiv = `<div class='led ${(block.leds[i]) ? `led-on led-on-${shortBlockId}` : ''}'></div>`;

      ledDivs += rowStart + ledDiv + rowEnd;
    }


    if (document.getElementById(`matrix-${block.id}`)) {
      const foreignObject = document.getElementById(`matrix-${block.id}`);
      foreignObject.innerHTML = `
        <div class="matrix-mini-modal matrix-mini-modal-${ledsPerRow}">
          ${ledDivs}
        </div>
      `;
    } else {
      const ns = 'http://www.w3.org/2000/svg';
      // const svg = document.querySelector('svg');
      const foreignObject = document.createElementNS(ns, 'foreignObject');
      foreignObject.setAttribute('class', 'matrix');
      foreignObject.setAttribute('id', `matrix-${block.id}`);
      foreignObject.innerHTML = `
        <div class="matrix-mini-modal">
          ${ledDivs}
        </div>
      `;
      ele.insertBefore(foreignObject, eleText);
    }
    // block.workspace.fireChangeListener('resize');
    if (!startUp) {
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
    }
  },

  js(block) {
    const self = this;
    const rows = block.rows;
    const ledsPerRow = block.cols;
    let ledsHtml = '';

    const shortBlockId = block.id.replace(/[^0-9a-zA-Z]/g, '');

    for (let i = 0; i < rows; i += 1) {
      ledsHtml += '<div class="led-row">';
      for (let j = 0; j < ledsPerRow; j += 1) {
        ledsHtml += '<div class="led"></div>';
      }
      ledsHtml += '</div>';
    }
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildBigBlock(ledsHtml, ledsPerRow, shortBlockId);

    $('.matrix-modal div.led').each(function (index) {
      if (block.leds[index]) {
        $(this).addClass('led-on');
        $(this).addClass(`led-on-${shortBlockId}`);
       } // eslint-disable-line
    });
    window.Blockly.WidgetDiv.show(block, true);
    setTimeout(() => {
      $('.matrix-modal-parent .modal-sidebar').toggleClass('start');
      $('.matrix-modal-parent .matrix-modal').removeClass('initial-popup');
    }, 600);

    // UNBIND AND BIND
    $('.blocklyWidgetDiv').unbind('click');
    $('.blocklyWidgetDiv').unbind('mousedown mouseover');
    $('.blocklyWidgetDiv').on('mousedown mouseover', '.matrix-modal div.led', function (e) {
      if (e.buttons === 1 || e.buttons === 3) {
        if ($(this).hasClass('led-on')) {
          $(this).removeClass('led-on');
          $(this).removeClass(`led-on-${shortBlockId}`);
        } else {
          $(this).addClass('led-on');
          $(this).addClass(`led-on-${shortBlockId}`);
        }
      }

      if (e.buttons === 1) {
        $('.matrix-modal div.led').each(function (index) {
          block.leds[index] = $(this).hasClass('led-on'); // eslint-disable-line
        });
        self.buildMiniBlock(block, ledsPerRow, shortBlockId);
      }
    });

    let currentDrawer = '';

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .modal-sidebar .modal-sidebar-buttons span.picker', function (e) { // eslint-disable-line

      function fillSidebar() {
        if ($(e.target).hasClass('color-picker')) {
          currentDrawer = 'color-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <span class="color-circle bg-white"></span>
          <span class="color-circle bg-red"></span>
          <span class="color-circle bg-pink"></span>
          <span class="color-circle bg-fuscia"></span>
          <span class="color-circle bg-orange"></span>
          <span class="color-circle bg-copper"></span>
          <span class="color-circle bg-yellow"></span>
          <span class="color-circle bg-gold"></span>
          <span class="color-circle bg-blue"></span>
          <span class="color-circle bg-dark-blue"></span>
          <span class="color-circle bg-light-blue"></span>
          <span class="color-circle bg-green"></span>
          <span class="color-circle bg-dark-green"></span>
          <span class="color-circle bg-teal"></span>
          `;
        } else if ($(e.target).hasClass('emotions-picker')) {
          currentDrawer = 'emotions-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller emotions-scroller">
            <div><span class="emotions-square happy"></span></div>
            <div><span class="emotions-square sad"></span></div>
            <div><span class="emotions-square angry"></span></div>
            <div><span class="emotions-square surprised"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('icon-picker')) {
          currentDrawer = 'icon-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller emotions-scroller">
            <div><span class="icon-square"></span></div>
            <div><span class="icon-square"></span></div>
            <div><span class="icon-square"></span></div>
            <div><span class="icon-square"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('dice-picker')) {
          currentDrawer = 'dice-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller dice-scroller">
            <div><span class="dice-square dice-1"></span></div>
            <div><span class="dice-square dice-2"></span></div>
            <div><span class="dice-square dice-3"></span></div>
            <div><span class="dice-square dice-4"></span></div>
            <div><span class="dice-square dice-5"></span></div>
            <div><span class="dice-square dice-6"></span></div>
          </div>
          `;
        }

        $('.sidebar-scroller').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        });
      }

      // is the drawer open?
      if ($('.blocklyWidgetDiv .modal-sidebar').hasClass('open')) {
        $('.blocklyWidgetDiv .modal-sidebar').removeClass('open');
        // was it not I who opened it?
        if ($(e.target).attr('class').indexOf(currentDrawer) === -1) {
          $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
          $(e.target).addClass('selected');
          setTimeout(() => {
            fillSidebar();
            $('.matrix-modal-parent .modal-sidebar').addClass('open');
          }, 300);
        } else { $(e.target).removeClass('selected'); }
      } else {
        $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
        $(e.target).addClass('selected');
        fillSidebar();
        $('.matrix-modal-parent .modal-sidebar').addClass('open');
      }
    });

    // color functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .modal-sidebar .modal-sidebar-main-panel span.color-circle', function (e) { // eslint-disable-line
      // $('.matrix-modal-parent .modal-sidebar .modal-sidebar-buttons .color-picker').css('background-color', $(e.target).css('background-color'));
      $('head').append('<style type="text/css"></style>');
      const newStyleElement = $('head').children(':last');
      newStyleElement.html(`.led-on.led-on-${shortBlockId}, .color-picker-${shortBlockId} {background-color: ${$(e.target).css('background-color')} !important;}`);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .matrix-modal-close, .matrix-modal-parent .matrix-modal-under', () => {
      $('.matrix-modal').addClass('matrix-hide');
      $('.matrix-modal-parent').addClass('matrix-hide');
      $('.modal-sidebar').addClass('matrix-hide');
      $('.matrix-modal-close').addClass('matrix-hide');
      setTimeout(() => {
        $('.blocklyWidgetDiv').unbind('click');
        window.Blockly.WidgetDiv.hide();
      }, 200);
    });

    // erase functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .erase-button', () => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      $('.matrix-modal .led-row div.led').removeClass(`led-on-${shortBlockId}`);
      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        // eslint-disable-next-line no-param-reassign
        block.leds[i] = false;
      }
      self.buildMiniBlock(block, ledsPerRow, shortBlockId);
    });

    // grid numbers functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .ruler-button', (ele) => {
      if ($(ele.target).hasClass('ruler-on')) {
        $(ele.target).removeClass('ruler-on');
        $('.matrix-modal .led-row').each((index, e) => {
          $(e).attr('data-content', '');
        });
        $('.matrix-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', '');
        });
      } else {
        $(ele.target).addClass('ruler-on');
        $('.matrix-modal .led-row').each((index, e) => {
          $(e).attr('data-content', index);
        });
        $('.matrix-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', index);
        });
      }
    });

    // rotate functionality

    let currentRotationValue = 0;
    let ledArray = [[0, 1, 2], [3, 4, 5], [6, 7, 8]];

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .rotate-button', () => {
      const matrixModal = $('.matrix-modal-parent .matrix-modal');
      currentRotationValue = parseInt(matrixModal.attr('class').replace(`matrix-modal matrix-modal-${ledsPerRow} rotate-`, ''), 10);

      $('.blocklyWidgetDiv .modal-sidebar').addClass('transitioning');

      const newRotationValue = (currentRotationValue < 270) ? currentRotationValue + 90 : 0;

      setTimeout(() => {
        matrixModal.removeClass(`rotate-${currentRotationValue}`);
        matrixModal.addClass(`rotate-${newRotationValue}`);
        setTimeout(() => {
          $('.blocklyWidgetDiv .modal-sidebar').removeClass('transitioning');
        }, (currentRotationValue === 270) ? 500 : 200);
      }, 300);
    });

    // dice functionality
    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-3 .dice-square', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      $('.matrix-modal .led-row div.led').removeClass(`led-on-${shortBlockId}`);
      const n = $(e.target).attr('class').replace('dice-square dice-', '');

      switch (n) {
        case '1':
          ledArray = [4];
          break;
        case '2':
          ledArray = [2, 6];
          break;
        case '3':
          ledArray = [2, 4, 6];
          break;
        case '4':
          ledArray = [0, 2, 6, 8];
          break;
        case '5':
          ledArray = [0, 2, 4, 6, 8];
          break;
        case '6':
          ledArray = [0, 2, 3, 5, 6, 8];
          break;
        default:
          ledArray = [];
          break;
      }

      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        if (ledArray.indexOf(i) !== -1) {
          // eslint-disable-next-line no-param-reassign
          block.leds[i] = true;

          const row = Math.ceil((i + 1) / ledsPerRow);
          const led = Math.ceil((i) % ledsPerRow) + 1;


          $(`.matrix-modal .led-row:nth-child(${row}) div.led:nth-child(${led})`).addClass('led-on');
          $(`.matrix-modal .led-row:nth-child(${row}) div.led:nth-child(${led})`).addClass(`led-on-${shortBlockId}`);
        } else {
          // eslint-disable-next-line no-param-reassign
          block.leds[i] = false;
        }
      }
      self.buildMiniBlock(block, ledsPerRow, shortBlockId);
    });
  },
};
