import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  alpha,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';

export function Carousel(props) {
  const { steps } = props;
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const maxSteps = steps.length;
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  return (
    <Box
      sx={{
        zIndex: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <img
        src={steps[activeStep].imageUrl}
        alt={`Ilustración de ${steps[activeStep].title}`}
        style={{
          objectFit: 'contain',
          ...(!isTablet && { width: '100%' }),
          ...(!isTablet && { padding: '2rem 2.25rem' }),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingX: theme.spacing(1.5),
        }}
      >
        <IconButton
          sx={{
            color: theme.palette.common.white,
            '&:disabled': {
              color: alpha(theme.palette.common.white, 0.05),
            },
          }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Typography
          sx={{
            color: theme.palette.common.white,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: { xs: '1rem', sm: '1.25rem' },
            lineHeight: '24px',
          }}
        >
          {steps[activeStep].title}{' '}
          <span
            style={{
              color: alpha(theme.palette.common.white, 0.8),
              fontFamily: theme.typography.fontFamily,
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: { xs: '1rem', sm: '1.25rem' },
              lineHeight: '24px',
            }}
          >
            {steps[activeStep].description}
          </span>
        </Typography>
        <IconButton
          sx={{
            color: theme.palette.common.white,
            '&:disabled': {
              color: alpha(theme.palette.common.white, 0.05),
            },
          }}
          disabled={activeStep === maxSteps - 1}
          onClick={handleNext}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  );
}
