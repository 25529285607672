/* eslint-disable func-names */
export const killExt = function (filesList, after) {
  const that = this;
  that.filesList = filesList;
  that.callbackFn = after;
  function killScript(scriptName) {
    document.querySelector(`script[src="${scriptName}"]`).remove();
  }
  that.checkExistenceInterval = setInterval((function (thot) {
    return () => {
      let count = 0;
      for (let i = 0; i < thot.filesList.length; i += 1) {
        if (document.querySelector(`script[src="${thot.filesList[i]}"]`)) {
          count += 1;
          killScript(thot.filesList[i]);
        }
      }
      if (count === 0) {
        clearInterval(thot.checkExistenceInterval);
        if (typeof thot.callbackFn === 'function') thot.callbackFn();
      }
    };
  }(that)), 50);
};
export const killNotExactExt = function (filesList, after) {
  const that = this || {};
  that.filesList = filesList;
  that.callbackFn = after;
  function killScript(scriptName) {
    document.querySelector(`script[src*="${scriptName}"]`).remove();
  }
  that.checkExistenceInterval = setInterval((function (thot) {
    return () => {
      let count = 0;
      for (let i = 0; i < thot.filesList.length; i += 1) {
        if (document.querySelector(`script[src*="${thot.filesList[i]}"]`)) {
          count += 1;
          killScript(thot.filesList[i]);
        }
      }
      if (count === 0) {
        clearInterval(thot.checkExistenceInterval);
        if (typeof thot.callbackFn === 'function') thot.callbackFn();
      }
    };
  }(that)), 50);
};
window.killNotExactExt = killNotExactExt;
export const loadExt = function (filesList, after) {
  const that = this || {};
  that.filesList = filesList;
  that.js = [];
  that.head = document.getElementsByTagName('head')[0];
  that.after = after || function () { };
  that.loadStyle = function (file) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = file;
    that.head.appendChild(link);
  };
  that.loadScript = function (i) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = that.js[i];
    const loadNextScript = function () {
      if (++i < that.js.length) that.loadScript(i); // eslint-disable-line
      else that.after();
    };
    script.onload = function () { loadNextScript(); };
    that.head.appendChild(script);
  };
  for (let i = 0; i < that.filesList.length; i += 1) {
    // if (/\.js$|\.js\?/.test(that.filesList[i]) && (!document.querySelector(`script[src="${that.filesList[i]}"]`) || process.env.IS_ELECTRON)) that.js.push(that.filesList[i]);
    // if (/\.css$|\.css\?/.test(that.filesList[i])) that.loadStyle(that.filesList[i]);
    if (/\.js$|\.js\?/.test(that.filesList[i]) && (!document.querySelector(`script[src="${process.env.IS_ELECTRON ? '.' : ''}${that.filesList[i]}"]`) || process.env.IS_ELECTRON)) that.js.push(`${process.env.IS_ELECTRON ? '.' : ''}${that.filesList[i]}`);
    if (/\.css$|\.css\?/.test(that.filesList[i])) that.loadStyle(`${process.env.IS_ELECTRON ? '.' : ''}${that.filesList[i]}`);
  }
  if (that.js.length > 0) that.loadScript(0);
  else that.after();
};
export const getAudioFromTag = function (selector) {
  if (document.querySelector(selector) === null) return null;
  const audio = new Audio(document.querySelector(selector).getAttribute('data-uri'));
  return audio;
};
export const loadAudioExt = function (soundsList, after) {
  const that = this || {};
  that.soundsList = soundsList;
  that.sounds = [];
  that.head = document.getElementsByTagName('body')[0];
  that.after = after || function () { };
  that.loadSound = function (i) {
    const audio = document.createElement('audio');
    const fileName = that.sounds[i].split('/')[that.sounds[i].split('/').length - 1].split('.')[0];
    audio.setAttribute('id', fileName);
    audio.setAttribute('data-uri', that.sounds[i]);
    audio.setAttribute('controls', '');
    audio.setAttribute('preload', 'auto');
    audio.setAttribute('class', 'd-none');
    const source = document.createElement('source');
    source.src = that.sounds[i];
    source.type = 'audio/ogg';
    audio.appendChild(source);
    const loadNextSound = function () {
      if (++i < that.sounds.length) that.loadSound(i); // eslint-disable-line
      else that.after();
    };
    loadNextSound();
    that.head.appendChild(audio);
  };
  for (let i = 0; i < that.soundsList.length; i += 1) {
    that.sounds.push(that.soundsList[i]);
  }
  if (that.sounds.length > 0) that.loadSound(0);
  else that.after();
};
