/**
 * LCD1602
 * Part of AVR8js Electron Playground
 *
 * Copyright (C) 2019, Uri Shaked
 * Copyright (C) 2020, Anderson Costa
 */
/* eslint lines-between-class-members: 0 */
/* eslint no-param-reassign: 0 */
/* eslint camelcase: 0 */
export const LCD1602_ADDR = 0x27;

const LCD_MODE_CMD = 0x00;
const LCD_MODE_DATA = 0x40;

const LCD_CMD_CLEAR = 0x01;
const LCD_CMD_HOME = 0x02;

const LCD_CMD_ENTRY_MODE = 0x04;
const LCD_CMD_ENTRY_MODE_INCREMENT = 0x02;
const LCD_CMD_ENTRY_MODE_DECREMENT = 0x00;
const LCD_CMD_ENTRY_MODE_SHIFT = 0x01;

const LCD_CMD_DISPLAY_CONTROL = 0x08;
const LCD_CMD_DISPLAY_ENABLE = 0x04;
const LCD_CMD_DISPLAY_CURSOR = 0x02;
const LCD_CMD_DISPLAY_CURSOR_BLINK = 0x01;

const LCD_CMD_SHIFT = 0x10;
const LCD_CMD_SHIFT_CURSOR = 0x00;
const LCD_CMD_SHIFT_DISPLAY = 0x08;
const LCD_CMD_SHIFT_LEFT = 0x00;
const LCD_CMD_SHIFT_RIGHT = 0x04;

const LCD_CMD_FUNCTION = 0x20;
const LCD_CMD_FUNCTION_LCD_1LINE = 0x00;
const LCD_CMD_FUNCTION_LCD_2LINE = 0x08;
const LCD_CMD_FUNCTION_5x10_DOTS = 0x04;

const LCD_CMD_SET_CGRAM_ADDR = 0x40;
const LCD_CMD_SET_DRAM_ADDR = 0x80;

// Extra
const LCD_CMD_SET_CONTRAST = 0x81;

// Oscillator frequency defined in datasheet is 270 kHz
const fOsc = 270000;

export class LCD1602Controller {
  // RAM settings
  cgram = new Uint8Array(64);

  ddram = new Uint8Array(128);

  // Memory and addressing settings
  addr = 0x00; // Address
  shift = 0x00; // Shift Register
  data = 0x00; // Data Register

  // Display settings
  displayOn = false;
  blinkOn = false;
  cursorOn = false;
  backlight = false;

  // Command parsing state machine
  firstByte = true;
  commandMode = false;
  cgramMode = false;
  cgramUpdated = true;
  incrementMode = true;
  shiftMode = false;
  is8bit = true;
  updated = false;

  constructor(cpuMillis) {
    this.render();
  }

  update() {
    if (this.updated) {
      this.updated = false;
      return this.render();
    }

    return false;
  }

  render() {
    const characters = new Uint8Array(32);

    if (this.displayOn) {
      const r1 = this.shift % 64;
      // const r2 = 64 + this.shift % 64;
      const r2 = 64 + (this.shift % 64);
      // Set characters
      characters.set(this.ddram.slice(r1, r1 + 16));
      characters.set(this.ddram.slice(r2, r2 + 16), 16);
    } else {
      characters.fill(32);
    }

    const result = ({
      blink: this.blinkOn,
      cursor: this.cursorOn,
      cursorX: this.addr % 64,
      cursorY: Math.floor(this.addr / 64),
      characters,
      backlight: this.backlight,
      cgram: this.cgram,
      cgramUpdated: this.cgramUpdated,
    });

    this.cgramUpdated = false;

    return result;
  }

  backlightOn(value) {
    if (this.backlight !== value) {
      this.backlight = value;
    }
  }

  i2cConnect() {
    return true;
  }

  i2cDisconnect() { }

  i2cReadByte() {
    return 0xff;
  }

  i2cWriteByte(value) {
    const data = value & 0xF0;
    const rs = (value & 0x01); // Register Select
    const bl = (value & LCD_CMD_DISPLAY_CONTROL);

    // Turn on/off backlight
    this.backlightOn(bl);

    // Check data write
    if ((value & 0x04) && !(value & 0x02)) {
      this.writeData(data, rs);
    }
    this.updated = true;

    return this.updated;
  }

  writeData(value, rs) {
    if (!this.is8bit) {
      // Check register
      if (this.firstByte) {
        this.firstByte = false;
        this.data = value;
        return false;
      }

      value = this.data | (value >> 4);

      this.firstByte = true;
    }

    if (rs) {
      this.processData(value);
    } else {
      this.processCommand(value);
    }

    this.updated = true;
    return true;
  }

  processCommand(value) {
    // Check commands
    if (value & LCD_CMD_FUNCTION) {
      this.is8bit = (value & 0x10);
    } else if (value & LCD_CMD_SET_DRAM_ADDR) {
      this.cgramMode = false;
      this.addr = value & 0x7F;
    } else if (value & LCD_CMD_SET_CGRAM_ADDR) {
      this.cgramMode = true;
      this.addr = value & 0x3F;
    } else if (value & LCD_CMD_SHIFT) {
      const shiftDisplay = (value & LCD_CMD_SHIFT_DISPLAY);
      const shiftRight = (value & LCD_CMD_SHIFT_RIGHT) ? 1 : -1;

      this.cgramMode = false;
      this.addr = (this.addr + shiftRight) % 128;

      if (shiftDisplay) {
        this.shift = (this.shift + shiftRight) % 64;
      }
    } else if (value & LCD_CMD_DISPLAY_CONTROL) {
      this.displayOn = (value & LCD_CMD_DISPLAY_ENABLE);
      this.blinkOn = (value & LCD_CMD_DISPLAY_CURSOR_BLINK);
      this.cursorOn = (value & LCD_CMD_DISPLAY_CURSOR);
    } else if (value & LCD_CMD_ENTRY_MODE) {
      this.cgramMode = false;
      this.incrementMode = (value & LCD_CMD_ENTRY_MODE_INCREMENT);
      this.shiftMode = (value & LCD_CMD_ENTRY_MODE_SHIFT);
    } else if (value & LCD_CMD_HOME) {
      this.cgramMode = false;
      this.addr = 0x00;
      this.shift = 0x00;
    } else if (value & LCD_CMD_CLEAR) {
      this.cgramMode = false;
      this.incrementMode = true;
      this.addr = 0x00;
      this.shift = 0x00;
      this.ddram.fill(32);
    } else {
      console.warn(
        'Unknown LCD1602 Command',
        value.toString(16),
      );
    }
  }

  processData(value) {
    // Check RAM type
    if (this.cgramMode) {
      // CGRAM
      const data = ((value & 0x01) << 4) | ((value & 0x02) << 2) | (value & 0x04) | ((value & 0x08) >> 2) | ((value & 0x10) >> 4);

      this.cgram[this.addr] = data;
      this.addr = (this.addr + 1) % 64;
      this.cgramUpdated = true;
    } else {
      // DRAM
      const mode = this.incrementMode ? 1 : -1;

      this.ddram[this.addr] = value;
      this.addr = (this.addr + mode) % 128;
      if (this.shiftMode) {
        this.shift = (this.shift + mode) % 40;
      }
    }
  }
}

const characterMap = [
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  '\'',
  '(',
  ')',
  '*',
  '+',
  ',',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '[',
  '',
  ']',
  '^',
  '_',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '{',
  '|',
  '}',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
];

export const getTextFromI2CCommand = (characters) => {
  const text = ['', ''];
  if (characters) {
    for (let i = 0; i < characters.length; i += 1) {
      let row = 0;
      if (i >= 16) {
        row = 1;
      }
      text[row] = `${text[row]}${characterMap[characters[i]]}`;
    }
  }
  return text;
};
