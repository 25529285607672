import React from 'react';
import { CloseIcon } from '@assets/icons';
import styles from './closeToastButton.mod.scss';

const CloseButton = ({ closeToast }) => (
  <div
    className={styles.container}
    onClick={closeToast}
  >
    <CloseIcon />
  </div>
);

export default CloseButton;
