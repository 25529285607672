import React, { useEffect } from 'react';
import { ToastContainer as ToastContainerBase, toast } from 'react-toastify';

function ToastContainer() {
  useEffect(() => {
    window.toast = toast;
  }, []);
  return (
    <>
      <ToastContainerBase enableMultiContainer containerId="A" position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainerBase enableMultiContainer containerId="B" position={toast.POSITION.BOTTOM_RIGHT} />
      <ToastContainerBase enableMultiContainer containerId="C" position={toast.POSITION.TOP_RIGHT} />
      <ToastContainerBase enableMultiContainer containerId="D" position={toast.POSITION.TOP_LEFT} />
      <ToastContainerBase enableMultiContainer containerId="E" className="toast-new" />
    </>
  );
}

export default ToastContainer;
