import React, { Component } from 'react';
import { DHT11Element } from '../components/dht11/dht11';
import { DHT11Controller } from '../libs/dht11';
import { rotatePort } from '../utils';

export class DHT11 extends Component {
  constructor(hardware, pin, id = 'Button', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.pinState = 0;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'T', x: -3, y: 17, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 57,
      height: 34,
    };
    this.rotation = rotation;
    this.dht11Controller = new DHT11Controller(pin, hardware);
    this.humidity = 20;
    this.temperature = 0;
    this.ref = React.createRef();

    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
    this.runMicrosecond = this.runMicrosecond.bind(this);
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    H: this.humidity,
    T: this.temperature,
  });

  runMicrosecond = (cpuCycles) => {
    if (this.hardware && this.pin) {
      this.dht11Controller.feedValue(
        this.pinState,
        this.humidity,
        this.temperature,
      );
    }
  }

  update = (newPinState) => {
    this.pinState = newPinState;
    this.humidity = this.ref.current?.state?.humidity || 0;
    this.temperature = this.ref.current?.state?.temperature || 0;
  }

  reset = () => {
    this.pinState = 0;
    this.humidity = 20;
    this.temperature = 0;
  }

  render = () => <DHT11Element id={this.id} rotation={this.rotation} ref={this.ref} key={`dht11-${this.pin}`} />;
}
