import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBlockly, selectBlockly } from '@modules/blockly/features/blocklySlice';
import { useFile } from '@utils/hooks';
import { v1 as uuidv1 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { projectsService } from '@services/rest/projects';
import { useIntl } from 'react-intl';
import { boards as Boards, getBoardById } from '@educabot/educablocks-boards';
import { openLabsToast } from '@sections/creabots/features/creabotsSlice';

export const useBlockly = () => useSelector(selectBlockly);

export const useUpdateBlockly = (project) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (project) {
      dispatch(updateBlockly(project));
    }
  }, [project]);
};

export const exportBlocklyProject = (project) => {
  const tabInfo = project.jsonBody || {};
  tabInfo.name = project.title;
  const body = {
    saveForm: project.jsonBody?.saveForm || {},
    tabInfo,
    tagsToSave: project.Tags || [],
  };

  const blob = new Blob([btoa(JSON.stringify(body))], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.download = `${project.title.replace(/ /g, '_')}.edu`;
  a.setAttribute('id', 'clickme');
  document.body.appendChild(a);
  a.click();
}

const getUserProjectURLByBoardId = (kitList, boardId, projectId = null) => {
  const name = kitList.find((kit) => kit.boardId === boardId)?.urlName || 'iniciacion';
  return `/kits/${name}/proyecto${projectId ? `-${projectId}` : ''}`;
};

export const useImportCreabotsProject = (kitList) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { onChange, onLoadend, onClick } = useFile({
    accept: ['.edu'],
  });

  useEffect(() => {
    onChange((file, reader) => {
      reader.readAsText(file);
    });

    onLoadend((reader) => {
      const project = JSON.parse(atob(reader.result));
      const tabInfo = project.tabInfo;
      project.tabInfo.id = uuidv1();

      if (kitList.map((kit) => kit.boardId).includes(project.tabInfo?.board?.id)) {
        const projectLoaded = {
          title: `${intl.formatMessage({ id: 'creabots.untitledCopyOf' })}: ${(tabInfo.name) ? tabInfo.name : intl.formatMessage({ id: 'creabots.untitled' })}`,
          description: tabInfo.description,
          boardType: tabInfo.boardType || 'senior',
          jsonBody: project.tabInfo,
          blocksCss: project.tabInfo.blocksCss,
          canvasCss: project.tabInfo.canvasCss,
          isCreabots: true,
        };

        projectsService.createProject(projectLoaded)
          .then((res) => {
            if (kitList && res) {
              history.push(getUserProjectURLByBoardId(kitList, tabInfo.board?.id || 0, res.id));
            }
          }).catch((err) => {
            console.log('=================ERROR', err);
          });
      } else {
        dispatch(
          openLabsToast({
            open: true,
            message: 'creabots.loadProjectNotLabs',
            severity: 'error',
          }),
        );
      }
    });
  }, [kitList]);

  return onClick;
}

export const createNewCreabotsProject = (title, boardId) => {
  const projectLoaded = {
    title,
    boardType: 'senior',
    jsonBody: {
      id: uuidv1(),
      board: getBoardById(Boards, boardId),
    },
    isCreabots: true,
  };

  return new Promise((resolve, reject) => {
    projectsService.createProject(projectLoaded)
      .then((res) => {
        if (res) {
          resolve(res.id);
        } else {
          reject(new Error('Error creating project'));
        }
      }).catch((err) => {
        reject(err);
      });
  });
}