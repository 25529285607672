import React, { Component } from 'react';
import { Matrix8x8Element } from '../components/matrix8x8/matrix8x8';
import { rotatePort, cpuMillis } from '../utils';
import { Matrix8x8Controller, MATRIX8X8_ADDR } from '../libs/matrix8x8';

export class Matrix8x8 extends Component {
  constructor(hardware, pin, id = 'Matrix8x16', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.hardware = hardware;
    this.id = id;
    this.pin = pin;
    this.pinStateSCL = false;
    this.pinStateSDA = false;
    this.variant = variant || {
      color: '#ff0000',
    };
    this.pinInfo = [
      { name: 'i2c', x: 34, y: 107, signals: [], description: 'i2c' },
    ];
    this.size = {
      width: 68,
      height: 107,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.lastTimestamp = 0;
    this.i2cBus = null;
    this.matrix8x8Controller = null;
    this.lcd = null;
    this.characters = null;

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

    runMicrosecond = (cpuCycles) => {
      if (this.hardware && this.pin) {
        const microseconds = cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz);
        const delta = microseconds - this.lastTimestamp;
        if (!this.i2cBus) {
          this.i2cBus = this.hardware.i2cBus;
          this.matrix8x8Controller = new Matrix8x8Controller(cpuMillis(this.hardware.runner.cpu.cycles, this.hardware.mhz));
          this.i2cBus.registerDevice(MATRIX8X8_ADDR, this.matrix8x8Controller);
        }
        if (delta > 50) {
          this.lcd = this.matrix8x8Controller.update();

          if (this.lcd.characters) {
            this.characters = this.lcd.characters;
          }
          this.lastTimestamp = microseconds;
        }
      }
    }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => this.characters;

  update = (pinState) => {}

  reset = () => {
    this.lcd = null;
    this.pinStateSCL = false;
    this.pinStateSDA = false;
  }

  render = () => (
    <Matrix8x8Element
      ref={this.ref}
      id={this.id}
      key={`matrix8x8-${this.pin}`}
      rotation={this.rotation}
      lcd={this.lcd}
      color={this.variant.color}
    />
  );
}
