import types from './types';

function actionGetRightPanelData(data) {
  return { type: types.GET_RIGHT_PANEL_DATA, payload: { rightPanelData: data } };
}

export function getRightPanelData(data) {
  return (dispatch) => {
    dispatch(actionGetRightPanelData(data));
  };
}

export default {
  getRightPanelData,
};
