/* eslint import/no-extraneous-dependencies: 0 */
/* eslint object-shorthand: 0 */
import * as Blockly from 'blockly';
import colors from '../utils/colors';
import options from '../utils/blocksOptions';


Blockly.Blocks.BHOOT_motor_single = {
  init: function () {
    this.setColour(colors.COLOUR_SERVO);
    this.appendDummyInput('')
      .appendField(new Blockly.FieldImage(`${options.imgBase}/blocks/Bhoot/30.png`, options.blockWidth, options.blockHeight))
      .appendField(Blockly.Msg.LANG_SALIDAS_RAYITA);
    this.appendDummyInput('PIN1')
      .appendField(new Blockly.FieldDropdown([
        [Blockly.Msg.LANG_MOTOR_TURN_RIGHT, '3'],
        [Blockly.Msg.LANG_MOTOR_TURN_LEFT, '4'],
        [Blockly.Msg.LANG_MOTOR_TURN_BRAKE, '6'],
      ]), 'DIR');

    this.appendDummyInput('PIN1')
      .appendField(Blockly.Msg.LANG_SALIDAS_MOTOR_SINGLE_PIN)
      .appendField(new Blockly.FieldDropdown(
        function () {
          return Blockly.getMainWorkspace().getHardwarePinsCombo('motorPwm', `${this.component}_NPIN`)
        },
      ), 'NPIN');

    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip(Blockly.Msg.LANG_SALIDAS_MOTOR_SINGLE_TOOLTIP);
  },
  component: 'motor',
  getPins: () => ['NPIN'],
};