import { useEffect, useState } from 'react';

const useCreabotsTheme = (): {isDarkMode: boolean, isLightMode: boolean} => {
  const [isDarkMode, setIsDarkMode] = useState(document.body.classList.contains('theme-labs-dark'));

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const hasDarkModeActive = document.body.classList.contains('theme-labs-dark');
      setIsDarkMode(hasDarkModeActive);
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  return {
    isDarkMode,
    isLightMode: !isDarkMode
  };
}

export default useCreabotsTheme