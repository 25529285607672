/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint react/prop-types: 0 */
import React, { Component } from 'react';

class BottomNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalLayout: props.horizontalLayout || false,
      consoleToggle: props.consoleToggle || false,
      ebBuildStatus: props.ebBuildStatus || '',
      arduinoAgentStatus: props.arduinoAgentStatus || '',
      toolboxToggle: props.toolboxToggle || '',
      buildingFunction: props.buildingFunction || (() => {}),
      hiddenChevron: props.hiddenChevron || false,
      agentType: props.agentType || 'socket',
    };
    this.buildBlockly = this.buildBlockly.bind(this);
    this.consoleToggleAction = this.consoleToggleAction.bind(this);
  }

  componentDidMount() {

  }

  componentWillReceiveProps(props) {
    const { horizontalLayout, consoleToggle, ebBuildStatus, arduinoAgentStatus, toolboxToggle, buildingFunction } = this.state;
    this.setState({
      horizontalLayout: props.horizontalLayout || horizontalLayout,
      consoleToggle,
      ebBuildStatus: props.ebBuildStatus || ebBuildStatus,
      arduinoAgentStatus: props.arduinoAgentStatus || arduinoAgentStatus,
      toolboxToggle: (props.toolboxToggle === null) ? toolboxToggle : props.toolboxToggle,
      buildingFunction: props.buildingFunction || buildingFunction,
      agentType: props.agentType || 'socket',
    });
  }

  consoleToggleAction() {
    const { consoleToggle } = this.state;
    this.setState({
      consoleToggle: !consoleToggle,
    });
  }

  buildBlockly() {
    const { buildingFunction } = this.state;
    buildingFunction();
  }

  render() {
    const { horizontalLayout, consoleToggle, ebBuildStatus, arduinoAgentStatus, toolboxToggle, hiddenChevron, agentType } = this.state;
    const { children } = this.props;

    const buildBtnEnabled = (((ebBuildStatus === 'ERROR' && !process.env.IS_ELECTRON) || arduinoAgentStatus === 'GOOD') && agentType === 'socket') || (agentType === 'serial' || agentType === 'usb');
    // console.log('====================', ebBuildStatus, process.env.IS_ELECTRON, arduinoAgentStatus, agentType);
    return (horizontalLayout) ? (
      <div className="bottom-nav-horizontal">
        <button type="button" className="btn btn-primary disabled-mask w-100" disabled={!buildBtnEnabled} onClick={this.buildBlockly}>
          <span className="right-menu-btn icon-play3 c-white" />
        </button>
      </div>
    ) : (
      <div className={`bottom-nav ${(consoleToggle) ? 'consoleBox-active' : ''} ${(ebBuildStatus === 'ERROR') ? 'consoleBox-error' : ''}`}>
        <div className="visible-bar">
          <div className="container-fluid">
            <div className="row p-0">
              <div className={`button-col ${toolboxToggle ? 'narrow-button-col' : ''}`}>
                <button type="button" className="btn btn-primary disabled-mask w-100" disabled={!buildBtnEnabled} onClick={this.buildBlockly}>

                  <span className="right-menu-btn icon-play3 c-white" />
                </button>
              </div>
              <div className={`progress-col ${toolboxToggle ? 'wide-progress-col' : ''}`}>
                <div className="f-bold m-2">
                  <span className="fade shootMsg" />
                </div>

              </div>
              <div className="triggers-col">
                <div className="m-auto text-right w-100">
                  <div className="triggers-col">
                    <div className="d-inline-block">
                      <div className="d-inline-block">
                        <button type="button" className={`btn-transparent withOpacity ${(ebBuildStatus !== '') ? 'active' : ''}`}>
                          <span className={`right-menu-btn icon-download3 ${(ebBuildStatus === 'ERROR') ? 'c-red' : 'c-green'}`} />
                        </button>
                      </div>
                      <div className="d-inline-block">
                        <button type="button" className={`btn-transparent withOpacity ${(arduinoAgentStatus !== '') ? 'active' : ''}`}>
                          <span className={`right-menu-btn icon-usb ${(arduinoAgentStatus === 'ERROR') ? 'c-red' : 'c-green'}`} />
                        </button>
                      </div>
                    </div>
                    {!hiddenChevron && (
                      <div className="d-inline-block">
                        <button type="button" className="btn-transparent" onClick={this.consoleToggleAction}>
                          <span className={`right-menu-btn f-22 ${(consoleToggle) ? 'icon-chevron-down' : 'icon-chevron-up'}`} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-0 consoleBox">
          { children }
        </div>
      </div>
    );
  }
}

export default BottomNav;
