import styled from 'styled-components';

export const ModalCard = styled.div`
  width: 100%;
  min-height: 50px;
  max-height: 33vh;
  overflow: auto;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.75);
  transition: .2s ease-out;
  background-color: #364480;
  ${({ openDropdown }) => {
    if (!openDropdown) {
      return `
        opacity: 1;
        display: none;
      `;
    }
    return `
        opacity: 1;
        display: flex;
      `;
  }}
`;
