/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint object-shorthand: 0 */
/* eslint dot-notation: 0 */
import * as Blockly from 'blockly';
import colors from '../utils/colors';


const mathNumberValidator = function (value) {
  const isPwm = this.getSourceBlock().parentBlock_
  let number = 0;
  if (isPwm !== null && isPwm.type === 'math_number_pwm') {
    number = parseFloat(value || 0);
    if (number > 255) {
      number = 255;
    }
    return (Number.isNaN(number) || parseInt(number, 10) < 0) ? null : String(number);
  }
  number = parseFloat(value || 0);
  return Number.isNaN(number) ? null : String(number);
};

Blockly.Blocks.math_number = {
  init: function () {
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.setColour('#ffffff');
    this.appendDummyInput()
      .appendField(new Blockly.FieldTextInput('0', mathNumberValidator), 'NUM');
    this.setOutput(true, Number);
    this.setTooltip(Blockly.Msg['LANG_MATH_NUMBER_TOOLTIP']);
  },
};

Blockly.Blocks.math_arithmetic = {
  OPERATORS: [
    ['%{BKY_MATH_ADDITION_SYMBOL}', 'ADD'],
    ['%{BKY_MATH_SUBTRACTION_SYMBOL}', 'MINUS'],
    ['%{BKY_MATH_MULTIPLICATION_SYMBOL}', 'MULTIPLY'],
    ['%{BKY_MATH_DIVISION_SYMBOL}', 'DIVIDE'],
    ['%{BKY_MATH_POWER_SYMBOL}', 'POWER'],
  ],
  TOOLTIPS: {
    ADD: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_ADD,
    MINUS: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_MINUS,
    MULTIPLY: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_MULTIPLY,
    DIVIDE: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_DIVIDE,
    POWER: Blockly.Msg.MATH_ARITHMETIC_TOOLTIP_POWER,
  },
  init: function () {
    this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
    this.setColour(colors.COLOUR_MATH);
    this.setOutput(true, Number);
    this.appendValueInput('A')
      .setCheck(Number);
    this.appendValueInput('B')
      .setCheck(Number)
      .appendField(new Blockly.FieldDropdown(this.OPERATORS), 'OP');
    this.setInputsInline(true);
    this.setTooltip(() => {
      const mode = this.getFieldValue('OP');
      return this.TOOLTIPS[mode];
    });
  },
};