import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { addKitToUser } from '@services/rest/creabots';
import { getCreabotsKits } from '../features/creabotsSlice';
import { KitCard } from './ui/kitCard';
import kitOptions from '../constants';


export const AddKitModal = ({ isOpen, handleCloseModal }) => {
  const intl = useIntl();
  const userKitList = useSelector((state) => state.creabots.kits.list);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const filteredKits = kitOptions.map((kit) => {
    const alreadyAdded = userKitList.some((k) => k.id === kit.id);
    if (alreadyAdded) {
      return {
        ...kit,
        isDisabled: true,
      };
    }
    return kit;
  });
  const [isSelected, setIsSelected] = useState(2);

  const handleSelected = (kitId) => {
    setIsSelected(kitId);
  };

  const handleAddKitToUser = async () => {
    try {
      setIsLoading(true);
      await addKitToUser({ creabotsKitId: isSelected });
      dispatch(getCreabotsKits(true));
      setError('');
      setIsSelected(2);
      handleCloseModal();
    } catch (err) {
      setError(
        err.response?.data?.message
          || intl.formatMessage({ id: 'creabots.dashboard.addKitModal.error' }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="Kit Modal"
      aria-describedby="Modal para agregar kits"
      disableEscapeKeyDown={isLoading}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '914px',
          minWidth: '914px',
          minHeight: '500px',
          height: 'fit-content',
        })}
      >
        <Box
          sx={(theme) => ({
            paddingX: theme.spacing(3),
            paddingY: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: 500,
              color: theme.palette.text.primary,
              letterSpacing: '-0.015rem',
              fontSize: '1.125rem',
              lineHeight: '24px',
              fontFamily: theme.typography.fontFamily,
            })}
          >
            {intl.formatMessage({ id: 'creabots.dashboard.sidebar.addKit' })}
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            disabled={isLoading}
            onClick={handleCloseModal}
          >
            <Close
              sx={(theme) => ({
                height: '24px',
                width: '24px',
                color: theme.palette.text.secondary,
              })}
            />
          </IconButton>
        </Box>
        <Grid
          container
          spacing={2}
          sx={(theme) => ({
            gap: theme.spacing(2.3),
            marginLeft: 0,
            width: '100%',
            height: '100%',
            padding: theme.spacing(3),
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          })}
        >
          {filteredKits.map((kit) => (
            <Grid
              key={kit.name}
              item
              xs={12}
              sx={{
                paddingLeft: '0px !important',
                paddingTop: '0px !important',
              }}
            >
              <KitCard
                kit={kit}
                isSelected={isSelected === kit.id}
                setIsSelected={handleSelected}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent={!error ? 'end' : 'space-between'}
          paddingX="1.5rem"
          paddingBottom="1.5rem"
        >
          {error && (
          <Typography
            sx={(theme) => ({
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.875rem',
              color: theme.palette.error.main,
              fontWeight: theme.typography.fontWeightRegular,
            })}
          >
            {error}
          </Typography>
          )}
          <Box display="flex" alignItems="center" gap="1.5rem" justifySelf="end">
            <Button
              size="small"
              variant="secondary"
              onClick={handleCloseModal}
              disabled={isLoading || filteredKits.length === userKitList.length}
            >
              {intl.formatMessage({ id: 'common.cancel' })}
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={handleAddKitToUser}
              disabled={isLoading || filteredKits.length === userKitList.length}
            >
              {intl.formatMessage({ id: 'common.confirm' })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};


