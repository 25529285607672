import { Transport, ESPLoader } from 'esptool-js';
import * as firmwareSlice from '@sections/codit/features/firmwareSlice';
import { installState } from '@sections/codit/features/constants';


const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

const resetTransport = async (transport) => {
  try {
    await transport.device.setSignals({
      dataTerminalReady: false,
      requestToSend: true,
    });
    await transport.device.setSignals({
      dataTerminalReady: false,
      requestToSend: false,
    });
    await transport.disconnect();
  } catch (err) {
    console.log(err);
  }
};


export const flash = (button = '1', port = {}, fileArray = [], totalSize = 0) => async (dispatch) => {
  try {
    let chipFamily = '';

    const transport = new Transport(port);
    const esploader = new ESPLoader({
      transport,
      baudrate: 115200,
      romBaudrate: 115200,
    });

    (window).esploader = esploader;

    dispatch(firmwareSlice.setInstallState(installState.INITIALIZING));

    try {
      if (port.readable) {
        await port.close();
      }
      await esploader.main_fn();
      await esploader.flash_id();
    } catch (err) {
      console.log('=============error', err);
      dispatch(firmwareSlice.setInstallState(installState.ERROR, 'Falló la inicialización. Intenta resetear el dispositivo'));
      await resetTransport(transport);
      return;
    }

    chipFamily = esploader.chip.CHIP_NAME;
    if (!esploader.chip.ROM_TEXT) {
      dispatch(firmwareSlice.setInstallState(installState.ERROR, `El chip ${chipFamily} no está soportado`));
      await resetTransport(transport);
      return;
    }

    dispatch(firmwareSlice.setInstallState(installState.ERASING));
    try {
      await esploader.erase_flash();
    } catch (err) {
      dispatch(firmwareSlice.setInstallState(installState.ERROR, `Error: ${(err.message.includes('The device has been lost')) ? 'Se perdió la conexión' : err.message}`));
      await resetTransport(transport);
      return;
    }

    dispatch(firmwareSlice.setInstallState(installState.WRITING));
    let totalWritten = 0;
    try {
      await esploader.write_flash({
        fileArray,
        flashSize: 'keep',
        flashMode: 'keep',
        flashFreq: 'keep',
        eraseAll: false,
        compress: true,
        reportProgress: (fileIndex, written, total) => {
          const uncompressedWritten = (written / total) * fileArray[fileIndex].data.length;
          const newPct = Math.floor(
            ((totalWritten + uncompressedWritten) / totalSize) * 100,
          );
          // we're done with this file
          if (written === total) {
            totalWritten += uncompressedWritten;
            return;
          }
          dispatch(firmwareSlice.setPortProgress(button, newPct));
        },
      });
    } catch (err) {
      dispatch(firmwareSlice.setInstallState(installState.ERROR, `Error: ${(err.message.includes('The device has been lost')) ? 'Se perdió la conexión' : err.message}`));
      await resetTransport(transport);
      return;
    }

    dispatch(firmwareSlice.setInstallState(installState.WRITING));
    dispatch(firmwareSlice.setPortProgress(button, 100));
    await sleep(100);
    await resetTransport(transport);
    dispatch(firmwareSlice.setInstallState(installState.FINISHED));
  } catch (err) {
    console.log(err);
  }
};
