import React, { Component } from 'react';
import './lcd.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { fontA00 } from './lcd1602-font-a00';
// import { fontA02 } from './lcd1602-font-a02';

const backgroundColors = {
  green: '#6cb201',
  blue: '#000eff',
};

export class LCDElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.charXSpacing = 4;
    this.charYSpacing =   5.95;
    this.numCols = 16;
    this.numRows = 2;

    this.lcd = null;
    this.color = 'white';
    this.background = 'blue';
    this.characters = null;
    this.font = fontA00;
    this.cursor = false;
    this.blink = false;
    this.cursorX = 0;
    this.cursorY = 0;
    this.backlight = true;

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.lcd = newProps.lcd;
    this.color = newProps.color;
    this.background = newProps.background;
    if (this.lcd) {
      this.blink = this.lcd.blink;
      this.cursor = this.lcd.cursor;
      this.cursorX = this.lcd.cursorX;
      this.cursorY = this.lcd.cursorY;
      this.characters = this.lcd.characters;
      this.backlight = this.lcd.backlight;
      // console.log('============', this.characters);

      if (this.lcd.cgramUpdated) {
        const font = this.font.slice(0);
        const cgramChars = this.lcd.cgram.slice(0, 0x40);
        // Set character
        font.set(cgramChars, 0);
        font.set(cgramChars, 0x40);

        // Get character
        this.font = font;
      }
    }
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }


  path(characters) {
    const xSpacing = 0.6;
    const ySpacing = 0.7;
    const result = [];

    if (characters) {
      for (let i = 0; i < characters.length; i += 1) {
        const charX = (i % this.numCols) * this.charXSpacing;
        const charY = Math.floor(i / this.numCols) * this.charYSpacing;
        
        for (let py = 0; py < 8; py += 1) {
          const row = this.font[characters[i] * 8 + py];
          for (let px = 0; px < 5; px += 1) {
            if (row & (1 << px)) {
              const x = (charX + px * xSpacing).toFixed(2);
              const y = (charY + py * ySpacing).toFixed(2);
              result.push(`M ${x} ${y}h0.55v0.65h-0.55Z`);
            }
          }
        }
      }
    }
    return result.join(' ');
  }

  renderCursor() {
    const { numCols, numRows, cursor, cursorX, cursorY, blink, color, charXSpacing, charYSpacing } = this;
    const xOffset = cursorX * charXSpacing;
    const yOffset = cursorY * charYSpacing;
    // const xOffset = 12.45 + cursorX * charXSpacing;
    // const yOffset = 12.55 + cursorY * charYSpacing;
    if (cursorX < 0 || cursorX >= numCols || cursorY < 0 || cursorY >= numRows) {
      return null;
    }
    const result = [];
    if (blink) {
      result.push(
        <rect x={xOffset} y={yOffset} width="2.95" height="5.55" fill={color}>
          <animate
            attributeName="opacity"
            values="0;0;0;0;1;1;0;0;0;0"
            dur="1s"
            fill="freeze"
            repeatCount="indefinite"
          />
        </rect>
      );
    }
    if (cursor) {
      const y = yOffset + 0.7 * 7;
      result.push(<rect x={xOffset} y={y} width="2.95" height="0.65" fill={color} />);
    }

    return result;
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    const darken = this.backlight ? 0 : 0.5;
    const actualBgColor = this.background in backgroundColors ? backgroundColors[this.background] : backgroundColors;

    return (
      <div className="lcd" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 314.8 104.05"
          width="54mm"
          height="18mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >

          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <path class="cls-2" d="m274.86,102.2h5.66c.37,0,.74-.15,1-.42s.42-.63.42-1v-2.81c0-.59.12-1.19.34-1.74s.56-1.05.98-1.47.92-.76,1.47-.98,1.14-.35,1.74-.35,1.19.12,1.74.35,1.05.56,1.47.98.76.92.98,1.47.35,1.14.35,1.74v2.78c0,.38.16.76.43,1.02s.65.42,1.03.42h17.96c.56,0,1.11-.11,1.63-.32s.99-.53,1.38-.92.71-.87.92-1.38.32-1.07.32-1.63V4.4c0-.56-.11-1.11-.32-1.63s-.53-.99-.92-1.38-.87-.71-1.38-.92-1.07-.32-1.63-.32H5.69c-.56,0-1.11.11-1.63.32s-.99.53-1.38.92-.71.87-.92,1.38-.32,1.07-.32,1.63v93.54c0,.56.11,1.11.32,1.63s.53.99.92,1.38.87.71,1.38.92,1.07.32,1.63.32h19.7c.37,0,.74-.15,1-.42s.42-.63.42-1v-2.81c0-.59.12-1.19.34-1.74s.56-1.05.98-1.47.92-.76,1.47-.98,1.14-.35,1.74-.35,1.19.12,1.74.35,1.05.56,1.47.98.76.92.98,1.47.35,1.14.35,1.74v2.78c0,.38.16.76.43,1.02s.65.42,1.03.42h8.8" />
              </g>
              <g>
                <path class="cls-7" d="m293.06,4.78h.51l5.21,2.35v.76l-5.06-2.34h-.02v2.64h-.64v-3.4Z" />
                <path class="cls-7" d="m293.78,2.44v.02l.54.94-.59.14-.67-1.18v-.62h5.72v.71h-5Z" />
              </g>
              <path class="cls-7" d="m256.47,44h102.31v9.97c0,2.41-1.96,4.36-4.36,4.36h-93.58c-2.41,0-4.37-1.96-4.37-4.37v-9.97h0Z" transform="translate(256.46 358.79) rotate(-90)" />
              <polygon class="cls-7" points="280.62 82.87 292.03 82.87 296.35 86.76 296.35 102.32 289.74 102.32 289.74 96.87 289.74 96.56 289.6 95.94 289.33 95.36 288.94 94.84 288.7 94.61 288.47 94.4 287.92 94.08 287.31 93.85 286.67 93.73 286.34 93.73 286.29 93.73 285.95 93.74 285.28 93.86 284.65 94.09 284.09 94.43 283.85 94.65 283.61 94.87 283.24 95.37 282.98 95.93 282.85 96.52 282.85 96.82 282.85 102.32 276.3 102.32 276.3 86.76 280.62 82.87" />
              <polygon class="cls-7" points="25.49 82.89 36.89 82.89 41.22 86.78 41.22 102.32 34.6 102.32 34.6 96.88 34.6 96.57 34.47 95.95 34.2 95.37 33.81 94.84 33.56 94.62 33.33 94.41 32.79 94.08 32.18 93.86 31.54 93.74 31.21 93.74 31.15 93.74 30.81 93.74 30.15 93.87 29.52 94.1 28.95 94.44 28.71 94.66 28.48 94.87 28.1 95.37 27.85 95.93 27.71 96.52 27.71 96.82 27.71 102.32 21.16 102.32 21.16 86.78 25.49 82.89" />
              <g>
                <path class="cls-8" d="m305.2,60v-.58h4.26v-1.92h.52v2.51h-4.78Z" />
                <path class="cls-8" d="m309.82,53.95c.11.21.23.64.23,1.19,0,1.27-.85,2.23-2.42,2.23s-2.51-.96-2.51-2.35c0-.56.13-.92.21-1.07l.5.14c-.11.22-.2.54-.2.91,0,1.06.72,1.76,1.97,1.76,1.17,0,1.92-.63,1.92-1.73,0-.35-.08-.71-.2-.95l.49-.12Z" />
                <path class="cls-8" d="m305.26,53.22c-.06-.35-.1-.78-.1-1.24,0-.84.21-1.43.6-1.83.39-.4.94-.63,1.72-.63s1.42.23,1.86.65c.45.42.69,1.12.69,1.99,0,.41-.02.76-.06,1.06h-4.7Zm4.23-.58c.03-.15.04-.36.04-.59,0-1.24-.74-1.92-2.03-1.92-1.13,0-1.84.59-1.84,1.82,0,.3.03.53.06.68h3.78Z" />
              </g>
              <g>
                <rect class="cls-7" x="13.4" y="76.75" width=".86" height=".85" />
                <rect class="cls-7" x="13.4" y="80.99" width=".86" height=".85" />
                <polygon class="cls-7" points="9.94 76.75 9.07 76.75 8.21 76.75 8.21 77.6 7.35 77.6 7.35 78.45 6.48 78.45 5.62 78.45 5.62 80.14 6.48 80.14 7.35 80.14 7.35 80.99 8.21 80.99 8.21 81.83 9.07 81.83 9.94 81.83 9.94 82.68 10.8 82.68 13.4 82.68 13.4 81.83 10.8 81.83 10.8 76.75 13.4 76.75 13.4 75.91 10.8 75.91 9.94 75.91 9.94 76.75" />
                <rect class="cls-7" x="14.26" y="77.6" width=".86" height="3.39" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <polyline class="cls-1" points="273.91 65.72 273.91 103.05 47.14 103.05 47.14 1 273.91 1 273.91 46.83 273.91 47.06 273.91 65.49" />
              </g>
              <g id="SPLINE">
                <path class="cls-6" d="m59.19,17.7v71.72c0,.56.23,1.11.62,1.5s.95.62,1.5.62h198.43c.56,0,1.11-.23,1.5-.62s.62-.95.62-1.5V17.7c0-.56-.23-1.11-.62-1.5s-.95-.62-1.5-.62H61.31c-.56,0-1.11.23-1.5.62s-.62.95-.62,1.5Z" />
              </g>
              <g id="SPLINE-2" data-name="SPLINE">
                <path class="cls-3" d="m53.19,101.26c.19,0,.37-.01.56-.04s.36-.06.54-.11.36-.11.53-.18c.26-.11.5-.24.74-.39s.45-.33.64-.53.38-.41.53-.64.29-.48.39-.73.19-.53.24-.8.08-.55.08-.83-.03-.56-.08-.83-.14-.54-.24-.8-.24-.5-.39-.74-.33-.45-.53-.64-.41-.38-.64-.53-.48-.29-.73-.39c-.1-.04-.21-.08-.32-.12s-.21-.06-.32-.09-.22-.05-.33-.06-.22-.03-.33-.04-.22-.01-.34-.01c-.19,0-.37.01-.56.04s-.36.06-.54.11-.36.11-.53.18c-.26.11-.5.24-.74.39s-.45.33-.64.53-.38.41-.53.64-.29.48-.39.73-.19.53-.24.8-.08.55-.08.83.03.56.08.83.14.54.24.8.24.5.39.74.33.45.53.64.41.38.64.53.48.29.73.39c.1.04.21.08.32.12s.21.06.32.09.22.05.33.06.22.03.33.04.22.01.34.01Z" />
              </g>
              <g id="SPLINE-3" data-name="SPLINE">
                <path class="cls-3" d="m267.73,11.46c.19,0,.37-.01.56-.04s.36-.06.54-.11.36-.11.53-.18c.26-.11.5-.24.74-.39s.45-.33.64-.53.38-.41.53-.64.29-.48.39-.73.19-.53.24-.8.08-.55.08-.83-.03-.56-.08-.83-.14-.54-.24-.8-.24-.5-.39-.74-.33-.45-.53-.64-.41-.38-.64-.53-.48-.29-.73-.39c-.1-.04-.21-.08-.32-.12s-.21-.06-.32-.09-.22-.05-.33-.06-.22-.03-.33-.04-.22-.01-.34-.01c-.19,0-.37.01-.56.04s-.36.06-.54.11-.36.11-.53.18c-.26.11-.5.24-.74.39s-.45.33-.64.53-.38.41-.53.64-.29.48-.39.73-.19.53-.24.8-.08.55-.08.83.03.56.08.83.14.54.24.8.24.5.39.74.33.45.53.64.41.38.64.53.48.29.73.39c.1.04.21.08.32.12s.21.06.32.09.22.05.33.06.22.03.33.04.22.01.34.01Z" />
              </g>
              <g id="SPLINE-4" data-name="SPLINE">
                <path class="cls-3" d="m267.69,101.25c.19,0,.37-.01.56-.04s.36-.06.54-.11.36-.11.53-.18c.26-.11.5-.24.74-.39s.45-.33.64-.53.38-.41.53-.64.29-.48.39-.73.19-.53.24-.8.08-.55.08-.83-.03-.56-.08-.83-.14-.54-.24-.8-.24-.5-.39-.74-.33-.45-.53-.64-.41-.38-.64-.53-.48-.29-.73-.39c-.1-.04-.21-.08-.32-.12s-.21-.06-.32-.09-.22-.05-.33-.06-.22-.03-.33-.04-.22-.01-.34-.01c-.19,0-.37.01-.56.04s-.36.06-.54.11-.36.11-.53.18c-.26.11-.5.24-.74.39s-.45.33-.64.53-.38.41-.53.64-.29.48-.39.73-.19.53-.24.8-.08.55-.08.83.03.56.08.83.14.54.24.8.24.5.39.74.33.45.53.64.41.38.64.53.48.29.73.39c.1.04.21.08.32.12s.21.06.32.09.22.05.33.06.22.03.33.04.22.01.34.01Z" />
              </g>
              <g id="SPLINE-5" data-name="SPLINE">
                <path class="cls-3" d="m53.19,11.26c.19,0,.37-.01.56-.04s.36-.06.54-.11.36-.11.53-.18c.26-.11.5-.24.74-.39s.45-.33.64-.53.38-.41.53-.64.29-.48.39-.73.19-.53.24-.8.08-.55.08-.83-.03-.56-.08-.83-.14-.54-.24-.8-.24-.5-.39-.74-.33-.45-.53-.64-.41-.38-.64-.53-.48-.29-.73-.39c-.1-.04-.21-.08-.32-.12s-.21-.06-.32-.09-.22-.05-.33-.06-.22-.03-.33-.04-.22-.01-.34-.01c-.19,0-.37.01-.56.04s-.36.06-.54.11-.36.11-.53.18c-.26.11-.5.24-.74.39s-.45.33-.64.53-.38.41-.53.64-.29.48-.39.73-.19.53-.24.8-.08.55-.08.83.03.56.08.83.14.54.24.8.24.5.39.74.33.45.53.64.41.38.64.53.48.29.73.39c.1.04.21.08.32.12s.21.06.32.09.22.05.33.06.22.03.33.04.22.01.34.01Z" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <rect class="cls-9" x="69.11" y="37.26" width="182.83" height="32.6" fill={actualBgColor} />
                <g transform="translate(75, 38) scale(2.7)">
                  <path d={this.path(this.characters)} fill={this.color} />
                  {this.renderCursor()}
                </g>
                <rect x="69.11" y="37.26" width="182.83" height="32.6" opacity={darken} />
              </g>
              <g id="SPLINE-6" data-name="SPLINE">
                <path class="cls-4" d="m280.66,14.32c0,.74.15,1.48.43,2.17s.7,1.31,1.23,1.84,1.15.94,1.84,1.23,1.43.43,2.17.43,1.48-.15,2.17-.43,1.31-.7,1.84-1.23.94-1.15,1.23-1.84.43-1.43.43-2.17-.15-1.48-.43-2.17-.7-1.31-1.23-1.84-1.15-.94-1.84-1.23-1.43-.43-2.17-.43-1.48.15-2.17.43-1.31.7-1.84,1.23-.94,1.15-1.23,1.84-.43,1.43-.43,2.17Z" />
              </g>
              <g id="SPLINE-7" data-name="SPLINE">
                <path class="cls-4" d="m280.66,71.02c0,.74.15,1.48.43,2.17s.7,1.31,1.23,1.84,1.15.94,1.84,1.23,1.43.43,2.17.43,1.48-.15,2.17-.43,1.31-.7,1.84-1.23.94-1.15,1.23-1.84.43-1.43.43-2.17-.15-1.48-.43-2.17-.7-1.31-1.23-1.84-1.15-.94-1.84-1.23-1.43-.43-2.17-.43-1.48.15-2.17.43-1.31.7-1.84,1.23-.94,1.15-1.23,1.84-.43,1.43-.43,2.17Z" />
              </g>
              <g id="SPLINE-8" data-name="SPLINE">
                <path class="cls-4" d="m25.54,14.32c0,.74.15,1.48.43,2.17s.7,1.31,1.23,1.84,1.15.94,1.84,1.23,1.43.43,2.17.43,1.48-.15,2.17-.43,1.31-.7,1.84-1.23.94-1.15,1.23-1.84.43-1.43.43-2.17-.15-1.48-.43-2.17-.7-1.31-1.23-1.84-1.15-.94-1.84-1.23-1.43-.43-2.17-.43-1.48.15-2.17.43-1.31.7-1.84,1.23-.94,1.15-1.23,1.84-.43,1.43-.43,2.17Z" />
              </g>
              <g>
                <g id="LINE-4" data-name="LINE">
                  <path class="cls-11" d="m29.53,24.4h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04" />
                </g>
                <rect class="cls-10" x="26.13" y="24.19" width="2.27" height="2.67" transform="translate(1.73 52.79) rotate(-90)" />
              </g>
              <g id="LINE-5" data-name="LINE">
                <rect class="cls-5" x="1.5" y="33.88" width="42.52" height="34.58" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
