import React, { Component } from 'react';
import './ledRGB.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class LedRGBElement extends Component {
  constructor(props) {
    super(props);
    this.value = props.value || false;
    this.color = props.color || '';
    this.state = {
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.value = newProps.value;
    this.color = newProps.color;
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    return (
      <div className="ledRGB" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 114.83 56.99"
          width="15mm"
          height="9mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <defs>
            <style>
              {`.cls-led-color-${id} { fill:${this.color}; opacity:0.7; }`}
              {`.cls-led-glow-${id} { ${this.value ? '' : 'display: none'} }`}
            </style>
          </defs>

          <filter id="ledFilter" x="-0.7" y="-0.7" height="2" width="3">
            <feGaussianBlur stdDeviation="8" />
          </filter>

          <g id="capa-led" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <line className="cls-led-2" x1="110.33" y1="56.86" x2="63.84" y2="56.86" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <line className="cls-led-2" x1="51.89" y1="56.86" x2="5.45" y2="56.86" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <line className="cls-led-2" x1="1.19" y1="52.61" x2="1.2" y2="4.42" />
              </g>
              <g id="LINE-4" data-name="LINE">
                <line className="cls-led-2" x1="63.84" y1=".17" x2="110.33" y2=".17" />
              </g>
              <g id="ARC">
                <path className="cls-led-10" d="m1.2,4.42s0,0,0,0c0-2.35,1.91-4.25,4.25-4.25h46.45c.8,0,1.45.64,1.45,1.44v2.78c0,2.5,2.03,4.53,4.54,4.54,2.5,0,4.53-2.03,4.54-4.53,0,0,0,0,0,0V1.58c0-.78.63-1.42,1.42-1.42h46.5c2.35,0,4.25,1.91,4.25,4.25v48.19s0,0,0,0c0,2.35-1.91,4.25-4.25,4.25h-46.49c-.78,0-1.42-.64-1.42-1.42v-2.81c0-2.51-2.03-4.54-4.54-4.54-2.5,0-4.53,2.03-4.54,4.54v2.78s0,0,0,0c0,.8-.65,1.44-1.45,1.44H5.45c-2.35,0-4.25-1.9-4.25-4.25" />
              </g>
              <g id="LINE-5" data-name="LINE">
                <line className="cls-led-2" x1="5.45" y1=".17" x2="51.89" y2=".16" />
              </g>
              <path className="cls-led-4" d="m80.14,22.25h56.87v8.26c0,2.35-1.91,4.26-4.26,4.26h-48.35c-2.35,0-4.26-1.91-4.26-4.26v-8.26h0Z" transform="translate(80.06 137.09) rotate(-90)" />
              <polygon className="cls-led-4" points="52.27 37.13 63.47 37.13 67.72 41.21 67.72 56.97 61.22 56.97 61.22 51.81 61.22 51.48 61.09 50.83 60.83 50.22 60.44 49.67 60.2 49.44 59.97 49.22 59.44 48.88 58.84 48.64 58.21 48.52 57.89 48.52 57.83 48.52 57.83 48.52 57.5 48.52 56.84 48.65 56.23 48.89 55.67 49.25 55.44 49.48 55.44 49.48 55.2 49.7 54.84 50.23 54.58 50.82 54.46 51.44 54.46 51.75 54.46 56.97 48.02 56.97 48.02 41.21 52.27 37.13" />
              <rect className="cls-led-11" x="5.47" y="7.25" width="34.58" height="42.52" transform="translate(-5.75 51.27) rotate(-90)" />
              <rect class="cls-led-3" x="5.67" y="7.32" width="34.31" height="42.39" transform="translate(-5.69 51.34) rotate(-90)"/>
              <g id="CIRCLE">
                <path className="cls-led-12" d="m57.88,34.13c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <rect className="cls-led-7" x="72.49" y="25.91" width="2.27" height="4.54" transform="translate(147.25 56.36) rotate(180)" />
              <rect className="cls-led-5" x="72.49" y="26.76" width="2.27" height="2.83" transform="translate(147.25 56.36) rotate(180)" />
              <polygon className="cls-led-4" points="54.13 0 54.13 5.16 54.13 5.49 54.26 6.14 54.52 6.75 54.9 7.3 55.15 7.53 55.38 7.75 55.91 8.09 56.51 8.33 57.14 8.45 57.46 8.45 57.52 8.45 57.85 8.45 58.51 8.32 59.12 8.08 59.68 7.72 59.91 7.49 60.15 7.27 60.51 6.74 60.76 6.16 60.89 5.53 60.89 5.22 60.89 0 67.33 0 67.33 15.76 63.08 19.84 51.88 19.84 47.63 15.76 47.63 0 54.13 0" />

              {/* LED */}
              <g id="ARC-2" data-name="ARC">
                <path className={`cls-led-13 cls-led-color-${id}`} d="m91.02,21.09h-4.7c-2.38,1.58-3.8,4.24-3.8,7.09,0,4.7,3.81,8.5,8.5,8.5,4.7,0,8.5-3.81,8.5-8.5,0-2.85-1.43-5.51-3.8-7.09h-4.7Z" />
                <circle className={`cls-led-glow-${id}`} cx="91" cy="27" r="20" fill={this.color} filter="url(#ledFilter)" />
              </g>

              <g>
                <rect className="cls-led-4" x="29.83" y="49.63" width=".85" height=".85" />
                <rect className="cls-led-4" x="29.83" y="53.86" width=".85" height=".85" />
                <polygon className="cls-led-4" points="26.44 49.63 25.59 49.63 24.74 49.63 24.74 50.47 23.89 50.47 23.89 51.32 23.05 51.32 22.2 51.32 22.2 53.01 23.05 53.01 23.89 53.01 23.89 53.86 24.74 53.86 24.74 54.7 25.59 54.7 26.44 54.7 26.44 55.55 27.29 55.55 29.83 55.55 29.83 54.7 27.29 54.7 27.29 49.63 29.83 49.63 29.83 48.78 27.29 48.78 26.44 48.78 26.44 49.63" />
                <rect className="cls-led-4" x="30.68" y="50.47" width=".85" height="3.38" />
              </g>
              <g id="LINE-6" data-name="LINE">
                <line className="cls-led-1" x1="26.58" y1="3.71" x2="28.84" y2="3.71" />
              </g>
              <g id="LINE-7" data-name="LINE">
                <line className="cls-led-1" x1="28.84" y1="5.98" x2="26.58" y2="5.98" />
              </g>
              <g id="LINE-8" data-name="LINE">
                <path className="cls-led-8" d="m28.84,5.98h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04v-.57h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13" />
              </g>
              <rect className="cls-led-9" x="26.58" y="3.5" width="2.27" height="2.67" transform="translate(22.87 32.55) rotate(-90)" />
              <g>
                <path className="cls-led-4" d="m95.65,7.9v.02l.52.93-.57.14-.65-1.16v-.61h5.53v.7h-4.84Z" />
                <path className="cls-led-4" d="m95.65,3.67v.02l.52.93-.57.14-.65-1.16v-.61h5.53v.7h-4.84Z" />
              </g>
            </g>
          </g>
          <text class="cls-led-6" transform="translate(110.34 42.67) rotate(-90) scale(.70 .70)"><tspan x="0" y="0">LED RGB</tspan></text>
        </svg>
      </div>
    );
  }
}
