import React, { Component } from 'react';
import { LedRGBElement } from '../components/ledRGB/ledRGB';
import { rotatePort, cpuNanos } from '../utils';
import { WS2812Controller } from '../libs/ws2812';

export class LedRGB extends Component {
  constructor(hardware, pin, id = 'Led', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;
    this.pinState = false;
    this.pinColor = '';
    this.pinValue = '';
    this.newPixelController = new WS2812Controller(1);

    this.variant = variant || {};
    this.pinInfo = [
      { name: 'A', x: -3, y: 18, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 57,
      height: 34,
    };
    this.rotation = rotation;
    this.ref = React.createRef();

    this.redrawNeoPixelLeds = this.redrawNeoPixelLeds.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    value: this.pinState,
    color: this.pinValue,
  });

  redrawNeoPixelLeds = (cpuNanoseconds) => {
    const pixels = new Uint32Array(this.newPixelController.update(cpuNanoseconds));
    if (pixels) {
      const value = pixels[0];
      if (typeof value !== 'undefined') {
        if (value) {
          this.pinState = true;
          this.pinColor = this.newPixelController.getRGBValue(value);
          this.pinValue = this.newPixelController.getRGBPlainValue(value);
        } else {
          this.pinState = false;
          this.pinValue = '';
        }
      }
    }
  }

  update = (pinState) => {
    if (this.hardware) {
      const cpuNanoseconds = cpuNanos(this.hardware.runner.cpu.cycles, this.hardware.mhz);
      this.newPixelController.feedValue(pinState, cpuNanoseconds);
      this.redrawNeoPixelLeds(cpuNanoseconds);
    }
  }

  reset = () => {
    this.pinState = false;
    this.pinColor = '';
    this.pinValue = '';
  }

  render = () => (
    <LedRGBElement
      ref={this.ref}
      id={this.id}
      key={`ledRGB-${this.pin}`}
      rotation={this.rotation}
      color={this.pinColor}
      value={this.pinState}
    />
  );
}
