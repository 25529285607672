import types from '../actions/types';

export const assignmentSessionsInitialState = {
  statusAssignmentSessionGet: '',
  dataAssignmentSessionGet: {},
  statusAssignmentSessionLoginGet: '',
  dataAssignmentSessionLoginGet: {},
  statusAssignmentSessionRefresh: '',
  dataAssignmentSessionRefresh: {},
  statusAssignmentSessionProjectPost: '',
  dataAssignmentSessionProjectPost: {},
  statusAssignmentSessionProjectUpdate: '',
  dataAssignmentSessionProjectUpdate: {},
  statusAssignmentSessionProjectDelete: '',
  dataAssignmentSessionProjectDelete: {},
  statusAssignmentSessionProjectDeleteOne: '',
  dataAssignmentSessionProjectDeleteOne: {},
  statusPutAssignmentSessionProjectBulk: '',
  dataPutAssignmentSessionProjectBulk: {},
  statusPostAssignmentSessionCompilation: '',
  dataPostAssignmentSessionCompilation: {},
  statusPutBulkAssignmentSessionCompilation: '',
  dataPutBulkAssignmentSessionCompilation: {},
  statusPutAssignmentSession: '',
  dataPutAssignmentSession: {},
  statusAssignmentSessionGetList: '',
  dataAssignmentSessionGetList: {},
  assignmentSessionDeliverableState: true,
};

export default function (state = assignmentSessionsInitialState, action) {
  switch (action.type) {
    case types.ASSIGNMENT_SESSION_GET_FETCH:
      const dataAssignmentSessionGet = action.payload.dataAssignmentSessionGet; // eslint-disable-line
      return Object.assign({}, state, action.payload, dataAssignmentSessionGet);
    case types.ASSIGNMENT_SESSION_REFRESH_FETCH:
      const dataAssignmentSessionRefresh = action.payload.dataAssignmentSessionRefresh; // eslint-disable-line
      return Object.assign({}, state, action.payload, dataAssignmentSessionRefresh);

    case types.ASSIGNMENT_SESSION_GET_FETCHING:
    case types.ASSIGNMENT_SESSION_GET_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_GET_LIST_FETCHING:
    case types.ASSIGNMENT_SESSION_GET_LIST_FETCH:
    case types.ASSIGNMENT_SESSION_GET_LIST_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_LOGIN_GET_FETCHING:
    case types.ASSIGNMENT_SESSION_LOGIN_GET_FETCH:
    case types.ASSIGNMENT_SESSION_LOGIN_GET_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_REFRESH_FETCHING:
    case types.ASSIGNMENT_SESSION_REFRESH_FETCH_ERROR:
    case types.ASSIGNMENT_SESSIONS_RESET_STATUS:
    case types.ASSIGNMENT_SESSION_PROJECTS_POST_FETCHING:
    case types.ASSIGNMENT_SESSION_PROJECTS_POST_FETCH:
    case types.ASSIGNMENT_SESSION_PROJECTS_POST_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCHING:
    case types.ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH:
    case types.ASSIGNMENT_SESSION_PROJECTS_UPDATE_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCHING:
    case types.ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH:
    case types.ASSIGNMENT_SESSION_PROJECTS_DELETE_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCHING:
    case types.ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH:
    case types.ASSIGNMENT_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCHING:
    case types.ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH:
    case types.ASSIGNMENT_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_UPDATE_FETCHING:
    case types.ASSIGNMENT_SESSION_UPDATE_FETCH:
    case types.ASSIGNMENT_SESSION_UPDATE_FETCH_ERROR:
    case types.ASSIGNMENT_SESSION_DELIVERABLE:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
