/* eslint no-underscore-dangle: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint no-param-reassign: 0 */
/* eslint react/destructuring-assignment: 0 */
export const BHOOT_initial_block = (block, generator) => {
  let addCode = '';
  const children = block.getChildren();
  if (children[0]) {
    addCode = generator.blockToCode(children[0]);
  }

  generator.setups_['main_setup'] = `
${addCode}
`;

  return null;
};


export const pause_millis = (block, generator) => {
  const number = block.getFieldValue('CHOICE');

  return `
  delay(${number});
`;
};


export const BHOOT_pause_smart = (block, generator) => {
  const number = block.getFieldValue('CHOICE');

  return `
  delay(${number});
`;
};


export const BHOOT_repeat_forever = (block, generator) => {
  let branch = generator.statementToCode(block, 'SUBSTACK');
  branch = branch.replace(/&quot;/g, '"');

  generator.loop_['loop'] = `
${branch}
`;

  return '';
};


export const BHOOT_control_loop_number = (block, generator) => {
  const loopNumber = block.getFieldValue('CHOICE');
  let branch = generator.statementToCode(block, 'SUBSTACK');
  branch = branch.replace(/&quot;/g, '"');

  const code = `
  for (int i = 0; i < ${loopNumber}; i++) {
    ${branch}
  }
`;

  return code;
};


export const show_string = (block, generator) => {
  const text = block.getFieldValue('NUM');

  generator.definitions_['define_text_8x16'] = `
#include<Matriz8x16.h>
MatrizLED8x16 Matriz(A5, A4);
`;

  generator.setups_['setup_text_8x16'] = 'Matriz.limpiarPantalla();';

  return `
  Matriz.escribirMensaje("${text}",0);
`;
};


export const BHOOT_led_off = (block, generator) => {
  const text = block.getFieldValue('NUM');

  generator.definitions_['led'] = `
pixels.begin();
pixels.setBrightness(30);
`;

  generator.setups_['setup_led'] = `
  pixels.begin();
  pixels.setBrightness(30);
`;

  return `
  pixels.clear();
  pixels.setPixelColor(0, pixels.Color(0,0,0));
  pixels.setPixelColor(1, pixels.Color(0,0,0));
  pixels.show();
  delay(300);
`;
};


export const BHOOT_play_tone = (block, generator) => {
  const tone = block.getFieldValue('CHOICE');

  return `
  tone(12, ${tone}, 1000);
  delay(1000);
`;
};


export const BHOOT_led = (block, generator) => {
  const color = block.getFieldValue('CHOICE');

  generator.definitions_['led'] = `
#include <Adafruit_NeoPixel.h>
#define PIN 13
#define NUMPIXELS 2

Adafruit_NeoPixel pixels(NUMPIXELS, PIN, NEO_GRB + NEO_KHZ800);    
`;

  generator.setups_['setup_led'] = `
  pixels.begin();
  pixels.setBrightness(30);
`;


  const code = `
  pixels.clear();
  pixels.setPixelColor(0, pixels.Color(${color}));
  pixels.setPixelColor(1, pixels.Color(${color}));
  pixels.show();
  delay(300);
`;

  return code;
};


export const BHOOT_motor_forward = (block, generator) => {
  const time = block.getFieldValue('NUM');

  generator.definitions_['motors'] = `
const int Bhoot_MI = 7;
const int Bhoot_MD = 4;
const int Bhoot_PWM1 = 5;
const int Bhoot_PWM2 = 6;

void Bhoot_Avanzar() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Retroceder() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
}

void Bhoot_GirarDerecha() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
}

void Bhoot_GirarIzquierda() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Frenar() {
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MI, LOW);' : ''}
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MD, LOW);' : ''}
  digitalWrite(Bhoot_PWM1, LOW);
  digitalWrite(Bhoot_PWM2, LOW);
}
`;


  generator.setups_['setup_motors'] = `
  pinMode(Bhoot_MI, OUTPUT);
  pinMode(Bhoot_PWM1, OUTPUT);
  pinMode(Bhoot_MD, OUTPUT);
  pinMode(Bhoot_PWM2, OUTPUT);  
`;

  const code = `
  Bhoot_Avanzar();
  delay(${time * 400});
  Bhoot_Frenar();
  delay(300);
`;

  return code;
};


export const BHOOT_motor_backward = (block, generator) => {
  const time = block.getFieldValue('NUM');

  generator.definitions_['motors'] = `
const int Bhoot_MI = 7;
const int Bhoot_MD = 4;
const int Bhoot_PWM1 = 5;
const int Bhoot_PWM2 = 6;

void Bhoot_Avanzar() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Retroceder() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
}

void Bhoot_GirarDerecha() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
}

void Bhoot_GirarIzquierda() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Frenar() {
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MI, LOW);' : ''}
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MD, LOW);' : ''}
  digitalWrite(Bhoot_PWM1, LOW);
  digitalWrite(Bhoot_PWM2, LOW);
}
`;


  generator.setups_['setup_motors'] = `
  pinMode(Bhoot_MI, OUTPUT);
  pinMode(Bhoot_PWM1, OUTPUT);
  pinMode(Bhoot_MD, OUTPUT);
  pinMode(Bhoot_PWM2, OUTPUT);  
`;

  const code = `
  Bhoot_Retroceder();
  delay(${time * 400});
  Bhoot_Frenar();
  delay(300);
`;

  return code;
};


export const BHOOT_motor_right = (block, generator) => {
  const time = block.getFieldValue('NUM');

  generator.definitions_['motors'] = `
const int Bhoot_MI = 7;
const int Bhoot_MD = 4;
const int Bhoot_PWM1 = 5;
const int Bhoot_PWM2 = 6;

void Bhoot_Avanzar() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Retroceder() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
}

void Bhoot_GirarDerecha() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
}

void Bhoot_GirarIzquierda() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Frenar() {
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MI, LOW);' : ''}
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MD, LOW);' : ''}
  digitalWrite(Bhoot_PWM1, LOW);
  digitalWrite(Bhoot_PWM2, LOW);
}
`;


  generator.setups_['setup_motors'] = `
  pinMode(Bhoot_MI, OUTPUT);
  pinMode(Bhoot_PWM1, OUTPUT);
  pinMode(Bhoot_MD, OUTPUT);
  pinMode(Bhoot_PWM2, OUTPUT);  
`;

  const code = `
  Bhoot_GirarDerecha();
  delay(${time * 300});
  Bhoot_Frenar();
  delay(300);
`;

  return code;
};


export const BHOOT_motor_left = (block, generator) => {
  const time = block.getFieldValue('NUM');

  generator.definitions_['motors'] = `
const int Bhoot_MI = 7;
const int Bhoot_MD = 4;
const int Bhoot_PWM1 = 5;
const int Bhoot_PWM2 = 6;

void Bhoot_Avanzar() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Retroceder() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
}

void Bhoot_GirarDerecha() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
}

void Bhoot_GirarIzquierda() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Frenar() {
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MI, LOW);' : ''}
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MD, LOW);' : ''}
  digitalWrite(Bhoot_PWM1, LOW);
  digitalWrite(Bhoot_PWM2, LOW);
}
`;


  generator.setups_['setup_motors'] = `
  pinMode(Bhoot_MI, OUTPUT);
  pinMode(Bhoot_PWM1, OUTPUT);
  pinMode(Bhoot_MD, OUTPUT);
  pinMode(Bhoot_PWM2, OUTPUT);  
`;

  const code = `
  Bhoot_GirarIzquierda();
  delay(${time * 385});
  Bhoot_Frenar();
  delay(300);
`;

  return code;
};


export const BHOOT_line_follower_jr = (block, generator) => {
  generator.definitions_['motors'] = `
const int Bhoot_MI = 7;
const int Bhoot_MD = 4;
const int Bhoot_PWM1 = 5;
const int Bhoot_PWM2 = 6;

void Bhoot_Avanzar() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Retroceder() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? '0' : 'HIGH'});
}

void Bhoot_GirarDerecha() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'HIGH' : 'LOW'});
  digitalWrite(Bhoot_MI, LOW);
  digitalWrite(Bhoot_PWM2, HIGH);
  digitalWrite(Bhoot_PWM1, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
}

void Bhoot_GirarIzquierda() {
  digitalWrite(Bhoot_MD, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_MI, HIGH);
  digitalWrite(Bhoot_PWM2, ${(block.workspace?.currentBoardVersion?.butyV2) ? 'LOW' : 'HIGH'});
  digitalWrite(Bhoot_PWM1, HIGH);
}

void Bhoot_Frenar() {
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MI, LOW);' : ''}
  ${(block.workspace?.currentBoardVersion?.butyV2) ? 'digitalWrite(Bhoot_MD, LOW);' : ''}
  digitalWrite(Bhoot_PWM1, LOW);
  digitalWrite(Bhoot_PWM2, LOW);
}
`;

  generator.definitions_['motors_line_follower'] = `
const int Bhoot_pin_SeguidorDerecha = A0;
const int Bhoot_pin_SeguidorCentro = A1;
const int Bhoot_pin_SeguidorIzquierda = A7;    
`;

  generator.setups_['setup_motors'] = `
  pinMode(Bhoot_MI, OUTPUT);
  pinMode(Bhoot_PWM1, OUTPUT);
  pinMode(Bhoot_MD, OUTPUT);
  pinMode(Bhoot_PWM2, OUTPUT);  
`;

  generator.loop_['loop_line_follower'] = `
  int Bhoot_SeguidorDerecha = analogRead(Bhoot_pin_SeguidorDerecha);
  int Bhoot_SeguidorIzquierda = analogRead(Bhoot_pin_SeguidorIzquierda);
  int Bhoot_SeguidorCentro = analogRead(Bhoot_pin_SeguidorCentro);

  if (Bhoot_SeguidorDerecha > 30 && Bhoot_SeguidorCentro > 30 && Bhoot_SeguidorIzquierda > 30) {
    Bhoot_Avanzar();
  } else if (Bhoot_SeguidorDerecha <= 30 && Bhoot_SeguidorCentro > 30 && Bhoot_SeguidorIzquierda > 30) {
    Bhoot_GirarIzquierda();
  } else if (Bhoot_SeguidorDerecha > 30 && Bhoot_SeguidorCentro > 30 && Bhoot_SeguidorIzquierda <= 30) {
    Bhoot_GirarDerecha();
  } else if (Bhoot_SeguidorDerecha <= 30 && Bhoot_SeguidorCentro <= 30 && Bhoot_SeguidorIzquierda <= 30) {
    Bhoot_Frenar();
  }
`;

  return '';
};


// TODO: do the matrix modal functionality
export const BHOOT_matrix_8x8_junior = (block, generator) => {
  return 'TODO: do the matrix modal functionality'
}