/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Switch from 'react-switch';
import InputComponent from '../../FormElements/InputComponent';

class SaveAssignmentProjectFormComponent extends Component {
  constructor(props) {
    super(props);
    const { changeField, form } = this.props;
    this.state = {
      changeField,
      form,
    };
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      changeField: newprops.changeField,
      form: newprops.form,
    });
  }

  render() {
    const { changeField, form } = this.state;
    const { intl } = this.props;
    return (
      <React.Fragment>
        {(form.assignmentProject) && (
          <React.Fragment>
            <div className="remote-session">
              {/* <div className="without-password">Actividad pública</div> */}
              <Switch className="pp-switch" onChange={() => changeField('assignmentProjectPasswordToggle', true)} checked={form.assignmentProjectPasswordToggle} />
              <div className={`with-password ${form.assignmentProjectPasswordToggle ? 'green' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.privateActivity' })}</div>
            </div>
            <div className="explainer">
              <div className={`with-password-explainer ${!form.assignmentProjectPasswordToggle ? 'hidden' : ''}`}>
                <InputComponent form={form} changeField={changeField} name={intl.formatMessage({ id: 'blocks.blockly.project.save.password' })} type="text" keyname="assignmentProjectPassword" />
              </div>
              <div className={`without-password-explainer ${form.assignmentProjectPasswordToggle ? 'hidden' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.withoutPasswordActivity' })}</div>
            </div>
            <div className="delivery-date-div field-div mt-5">
              <InputComponent form={form} changeField={changeField} name={intl.formatMessage({ id: 'blocks.blockly.project.save.activityDeliveryDate' })} type="date" keyname="assignmentProjectDeliveryDate" />
            </div>
          </React.Fragment>
        )}
        <div className="remote-session">
          <Switch className="pp-switch" onChange={() => changeField('assignmentProject', true)} checked={form.assignmentProject} />
          <div className={`with-remote-session ${form.assignmentProject ? 'green' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.activity' })}</div>
        </div>
        <div className="explainer">
          <div className={`without-remote-session-explainer ${form.assignmentProject ? 'hidden' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.activityNo' })}</div>
          <div className={`with-remote-session-explainer ${!form.assignmentProject ? 'hidden' : ''}`}>{intl.formatMessage({ id: 'blocks.blockly.project.save.activityYes' })}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SaveAssignmentProjectFormComponent);
