class Responsive {
  constructor(component) {
    this.component = component;
    this.responsive = this.checkSizes();
    const self = this;

    window.addEventListener('resize', () => {
      const { state } = self.component;
      self.component.setState(Object.assign(state, { responsive: self.checkSizes() }));
    });
    return self.responsive;
  }

  checkSizes() {
    return {
      isMobile: (window.innerWidth < 768),
      isTablet: ((window.innerWidth > 768) && (window.innerWidth < 992)),
      isDesktop: (window.innerWidth > 992),
    };
  }
}

export default Responsive;
