/* eslint react/no-this-in-sfc: 0 */
import React from 'react';
import styled from 'styled-components';
import ArduinoFlow from './components/flow/ArduinoFlow';
import { getComponentClass, deepEqual } from './utils';
import { FlowLabelPrefix } from './constants';

export class FlowChartClass {
  constructor(props) {
    this.hardware = null;
    this.type = 'buty';
    if (typeof props.boardId !== 'undefined') {
      // if (props.boardId === 0) this.type = 'zonda';
      if (props.boardId === 1) this.type = 'buty';
      this.hardware = getComponentClass(this.type);
    }
    this.changeState = props.changeState || null;

    this.circuit = {};
    this.circuitCustom = {};
    this.pinConnections = [];
    this.circuitSettings = {};
    this.circuitSettingsCustom = {};

    this.setDefaultCircuitSettings = this.setDefaultCircuitSettings.bind(this);
    this.emptyComponents = this.emptyComponents.bind(this);
    this.addComponents = this.addComponents.bind(this);
    this.handleUpdateFlowChart = this.handleUpdateFlowChart.bind(this);
    this.render = this.render.bind(this);
    this.refreshConnections = this.refreshConnections.bind(this);
  }

  setDefaultCircuitSettings = (settings) => {
    if (!this.hardware.runner) {
      this.circuitSettings = {
        ...settings,
        ...this.circuitSettingsCustom,
      };
      const defaultSettings = [...Object.keys(this.circuitSettings)];
      for (let i = 0; i < defaultSettings.length; i += 1) {
        if (typeof this.circuitSettingsCustom[defaultSettings[i]] === 'undefined') {
          this.circuitSettingsCustom[defaultSettings[i]] = this.circuitSettings[defaultSettings[i]];
        }
      }
    }
  }

  emptyComponents = () => {
    if (!this.hardware.runner) {
      this.pinConnections = [];
      this.circuit = {};
    }
  }

  refreshConnections = (e) => {
    const nodes = [...Object.values(this.circuit.nodes)];
    nodes.map((node) => {
      const newNode = node;
      if (e.detail.component === newNode.id) {
        // console.log('=====================e', e.detail);
        this.circuitSettingsCustom = {
          ...this.circuitSettings,
          [e.detail.component]: {
            ...(this.circuitSettings[e.detail.component]) ? this.circuitSettings[e.detail.component] : {},
            rotation: e.detail.rotation,
          },
        };
        let component = null;
        if (newNode.type === 'output') {
          component = this.hardware;
        } else {
          component = this.pinConnections.find((s) => s.id === newNode.id).component || null;
        }
        if (component) {
          const ports = [...Object.values(newNode.ports)];
          ports.map((port) => {
            const newPort = port;
            const pinInfo = component.getPinInfo(newPort.id, e.detail.rotation);
            newPort.position = {
              x: (pinInfo) ? pinInfo.x : 20,
              y: (pinInfo) ? pinInfo.y : 20,
            };
            newNode.ports = { ...newNode.ports, [newPort.pin]: newPort };
            return newPort;
          });
        }
      }
      this.circuit.nodes = { ...this.circuit.nodes, [newNode.id]: newNode };
      return newNode;
    });
    this.circuitCustom = { ...this.circuit };
    // console.log('=================', this.circuit.nodes);
  }

  addComponents = (newCircuit = {}) => {
    if (!this.hardware.runner && newCircuit.nodes) {
      if (Object.keys(newCircuit.nodes).length) {
        this.circuit = newCircuit;
        const nodes = Object.keys(this.circuit.nodes);
        let connectionBoardPin = {};
        for (let n = 0; n < nodes.length; n += 1) {
          const node = { ...this.circuit.nodes[nodes[n]] };
          const [componentType, index] = node.component.split(':');
          const settings = this.circuitSettings[node.id] || {};
          const defaultRotation = parseInt(settings.rotation || '0', 10) || 0;
          node.ports = {};

          let component = null;
          const links = Object.keys(this.circuit.links);

          if (componentType === this.type && !links.length) {
            component = this.hardware;
            component.id = node.id;
            component.rotation = defaultRotation;
          }

          for (let l = 0; l < links.length; l += 1) {
            let port = {};
            let type = 'input';
            let pinInfo = null;

            const link = this.circuit.links[links[l]];
            if (componentType === this.type) { // if its this board
              if (link.from.nodeId === node.id) {
                port = link.from;
                type = 'output';
                component = this.hardware;
                component.id = node.id;
                component.rotation = defaultRotation;
                pinInfo = component.getPinInfo(port.portId, component.rotation);
                connectionBoardPin = {
                  ...connectionBoardPin,
                  [link.to.nodeId]: port.portId,
                };
              }
            } else if (link.to.nodeId === node.id) {
              port = link.to;
              component = getComponentClass(componentType, this.hardware, link.from.portId, node.id, settings.variant || null, defaultRotation);
              if (component) {
                pinInfo = component.getPinInfo(port.portId, defaultRotation);
              }
              // Make sure component's portId doesn't came empty from circuit object
              if (pinInfo) {
                this.circuit.links[links[l]].to.portId = pinInfo.name;
              }
            }

            if (pinInfo) {
              node.ports = {
                ...node.ports,
                [pinInfo.name]: {
                  id: pinInfo.name,
                  type,
                  position: {
                    x: (pinInfo) ? pinInfo.x : 20,
                    y: (pinInfo) ? pinInfo.y : 20,
                  },
                },
              };
            }
          }

          if (component) {
            if (componentType === this.type) { // if its this board
              node.component = `${componentType}:${componentType}`;
              // console.log('====================board', componentType, index, node, connectionBoardPin, this.pinConnections);
            } else { // External components
              if (component) {
                const boardPin = connectionBoardPin[node.id] || '';
                this.pinConnections.push({ id: component.id, pin: boardPin, label: componentType, component, value: null });
              }
              node.component = `${componentType}:${this.pinConnections.length - 1}`;
              // console.log('====================component', componentType, node, connectionBoardPin, this.pinConnections);
            }
            if (this.circuitCustom?.nodes && this.circuitCustom?.nodes[nodes[n]]) {
              node.position = this.circuitCustom.nodes[nodes[n]].position;
            }
            this.circuit.nodes[nodes[n]] = node;
          } else {
            // If component doesn't exists, delete node and link
            delete this.circuit.nodes[nodes[n]];
            delete this.circuit.links[`${FlowLabelPrefix.LINK}${nodes[n]}`];
          }
        }

        // Some components can be ordered before board in the json, so we need to check again if port is set
        const connections = Object.keys(connectionBoardPin);
        for (let c = 0; c < connections.length; c += 1) {
          const boardPin = connectionBoardPin[connections[c]] || '';
          this.pinConnections = this.pinConnections.map((p) => {
            const newP = p;
            if (newP.id === connections[c]) {
              newP.pin = boardPin;
            }
            return newP;
          });
        }
        // console.log('====================pinConnections', this.pinConnections);

        this.circuit = {
          ...this.circuit,
          offset: this.circuitCustom?.offset || this.circuit.offset || {
            x: 20,
            y: 0,
          },
          scale: this.circuitCustom?.scale || this.circuit.scale || 0.8,
          selected: {},
          hovered: {},
        };
        // console.log('====================circuit', this.circuit, this.circuitCustom);
      } else {
        this.circuitSettings = {};
        this.circuitSettingsCustom = {};
      }
      this.hardware.setPinConnections(this.pinConnections);
    }
  }

  NodeInnerBox = styled.div`
    position: absolute;
    // pointer-events: none;
  `;

  NodeInnerCustom = React.forwardRef(({ node, children, ...otherProps }, ref) => (
    <this.NodeInnerBox {...otherProps} ref={ref}>
      {(typeof node.component !== 'undefined' && node.component.split(':')[0] === this.type) ? this.hardware.render() : ''}
      {(typeof node.component !== 'undefined' && node.component.split(':')[0] !== this.type && this.pinConnections[parseInt(node.component.split(':')[1], 10)])
        ? this.pinConnections[parseInt(node.component.split(':')[1], 10)].component.render()
        : ''
      }
      {/* {children} */}
    </this.NodeInnerBox>
  ));

  handleUpdateFlowChart = (chartState = null) => {
    if (chartState) {
      this.circuitCustom = chartState;
      this.circuit.scale = chartState.scale;
      this.circuit.offset = chartState.offset;
      if (!deepEqual(this.circuit, chartState)) {
        // console.log('==================chartState', chartState);
        this.changeState({ circuit: this.circuitCustom, circuitSettings: this.circuitSettingsCustom });
      }
    }
  }

  render = () => (
    this.circuit.nodes ? (
      <ArduinoFlow flowChart={this.circuit} handleUpdate={this.handleUpdateFlowChart} customNode={this.NodeInnerCustom} />
    ) : (
      ''
    )
  );
}
