/* eslint import/no-extraneous-dependencies: 0 */
import { JavascriptGenerator } from './javascriptGenerator';
import * as control from './controlBlocks';
import * as controlJunior from './controlJuniorBlocks';

const javascriptGenerator = new JavascriptGenerator();

const generators = {
  ...control,
  ...controlJunior,
};

Object.keys(generators).forEach(name => {
  javascriptGenerator.forBlock[name] = generators[name];
});

export default javascriptGenerator;