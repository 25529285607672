/* eslint-disable max-len */
import React from 'react';

function LoadFileIcon({ className = '' }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <path d="M 32 6 C 24.8 6 19 11.8 19 19 L 19 109 C 19 116.2 24.8 122 32 122 L 83 122 C 84.7 122 86 120.7 86 119 C 86 117.3 84.7 116 83 116 L 32 116 C 28.1 116 25 112.9 25 109 L 25 19 C 25 15.1 28.1 12 32 12 L 83 12 L 83 19 C 83 26.2 88.8 32 96 32 L 103 32 L 103 57 C 103 58.7 104.3 60 106 60 C 107.7 60 109 58.7 109 57 L 109 29 C 109 22.9 106.60078 17.099219 102.30078 12.699219 C 97.900781 8.3992188 92.1 6 86 6 L 32 6 z M 89 12.300781 C 92.4 12.900781 95.5 14.5 98 17 C 100.5 19.5 102.09922 22.6 102.69922 26 L 96 26 C 92.1 26 89 22.9 89 19 L 89 12.300781 z M 106 76 C 105.25 76 104.50039 76.300391 103.90039 76.900391 L 88.900391 91.900391 C 87.700391 93.100391 87.700391 94.999609 88.900391 96.099609 C 89.500391 96.699609 90.3 97 91 97 C 91.7 97 92.499609 96.699609 93.099609 96.099609 L 103 86.199219 L 103 119 C 103 120.7 104.3 122 106 122 C 107.7 122 109 120.7 109 119 L 109 86.199219 L 118.90039 96.099609 C 120.10039 97.299609 121.99961 97.299609 123.09961 96.099609 C 124.29961 94.899609 124.29961 93.000391 123.09961 91.900391 L 108.09961 76.900391 C 107.49961 76.300391 106.75 76 106 76 z" />
    </svg>
  );
}

export default LoadFileIcon;
