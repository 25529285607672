import authReducer from './authReducer';
import bloquesReducer from './bloquesReducer';
import projectsReducer from './projectsReducer';
import tagsReducer from './tagsReducer';
import rightPanelReducer from './rightPanelReducer';
import remoteSessionReducer from './remoteSessionReducer';
import assignmentSessionReducer from './assignmentSessionReducer';

export default {
  authReducer,
  bloquesReducer,
  projectsReducer,
  tagsReducer,
  rightPanelReducer,
  remoteSessionReducer,
  assignmentSessionReducer,
};
