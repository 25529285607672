/* eslint react/prop-types: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Login from '../../containers/Login';
import './ModalLogin.scss';
import { checkAuthActionExternal } from '../../actions/authAction';

class ModalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.testMethod = this.testMethod.bind(this);
  }

  testMethod() {
    console.log(this.state);
  }

  render() {
    const { checkAuth } = this.props;
    return (
      <div className="ModalLogin">
        <Login stopRedirect={true} embebLogin={true} checkAuth={checkAuth} />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  auth: state.authReducer,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    checkAuth: checkAuthActionExternal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);
