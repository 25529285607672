import React from 'react';
import ArrowRightIcon from '@assets/icons/arrowRight';
import { useDispatch } from 'react-redux';
import { getAllBoards } from '@modules/boards/features/boardsSlice';
import styles from './boardNav.mod.scss';

function BoardNav({ hidePageBack, displayPageBack }) {
  const dispatch = useDispatch();

  const onBackPageClick = () => {
    dispatch(getAllBoards());
    hidePageBack();
  };

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.emptyContainer} />
      <div className={styles.backPageContainer}>
        {displayPageBack && (
          <div className={styles.backArrowWrapper} onClick={onBackPageClick}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
    </div>
  );
}

export default BoardNav;
