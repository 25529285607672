import React, { useState } from 'react';
import { Box, Button, IconButton, Link, Modal, Stack } from '@mui/material';
import {
  TextBodySmallRegular,
  TextHeadlineEmphasized,
} from './ui/typographies';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CloseRounded } from '@mui/icons-material';
import { useIntl } from 'react-intl';

export const HelpModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpOutlineIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={theme => ({
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '600px',
            width: '100%',
            height: 'fit-content',
            maxHeight: '450px',
            paddingX: theme.spacing(3),
            paddingY: theme.spacing(1),
          })}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <TextHeadlineEmphasized
              id="modal-modal-title"
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <HelpOutlineIcon />
              {intl.formatMessage({ id: 'common.help' })}
            </TextHeadlineEmphasized>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Stack>
          <TextBodySmallRegular id="modal-modal-description" sx={{ mt: 2 }}>
            {intl.formatMessage({
              id: 'creabots.dashboard.helperModal.description',
            })}
          </TextBodySmallRegular>
          <Button
            LinkComponent={Link}
            href="https://educabot.zendesk.com/hc/es-419/sections/29426059965325-Tutoriales"
            target="_blank"
            variant="primary"
            size="small"
            sx={{
              mt: 2,
              mb: 2,
              width: 'fit-content',
              '&:hover': {
                color: 'white',
              },
              textTransform: 'none',
            }}
          >
            {intl.formatMessage({
              id: 'creabots.dashboard.helperModal.accessToHelpCenter',
            })}
          </Button>
        </Box>
      </Modal>
    </>
  );
};
