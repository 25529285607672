import { alpha, styled, TextField } from '@mui/material';

export const LabsInput = styled(TextField)(({ theme, error }) => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: error
        ? alpha(theme.palette.error.main, 0.5)
        : alpha(theme.palette.common.black, 0.23),
    },
    '&.Mui-focused fieldset': {
      borderColor: error
        ? alpha(theme.palette.error.main, 0.5)
        : alpha(theme.palette.primary.main, 0.5),
    },
    color: error ? theme.palette.error.main : theme.palette.common.black,
  },
  fontWeight: theme.typography.fontWeightMedium,
}));
