export const getUserAgent = () => new Promise((resolve, reject) => {
  if (window.navigator.userAgentData) {
    window.navigator.userAgentData.getHighEntropyValues(
      ['architecture', 'model', 'bitness', 'platformVersion', 'fullVersionList'],
    ).then(ua => {
      resolve(ua);
    }).catch((error) => {
      reject(error);
    });
  } else if (window.navigator.userAgent) {
    const ua = {
      platform: '',
      bitness: '',
      platformVersion: '',
    };
    if (window.navigator.userAgent.indexOf('CrOS') !== -1) {
      ua.platform = 'ChromeOS';
    } else if (window.navigator.userAgent.indexOf('Windows') !== -1) {
      ua.platform = 'Windows';
    } else if (window.navigator.userAgent.indexOf('Linux') !== -1 || window.navigator.userAgent.indexOf('Ubuntu') !== -1 || window.navigator.userAgent.indexOf('Unix') !== -1) {
      ua.platform = 'Linux';
    }
    ua.bitness = '32';
    if (window.navigator.userAgent.indexOf('x86_64') !== -1 || window.navigator.userAgent.indexOf('x64') !== -1) {
      ua.bitness = '64';
    }
    resolve(ua);
  } else {
    reject(new Error('Browser could not be detected'));
  }
});
