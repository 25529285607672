import React, { Component } from 'react';
import { BuzzerElement } from '../components/buzzer/buzzer';
import { rotatePort, cpuMicros } from '../utils';
import { BuzzerController } from '../libs/buzzer';

export class Buzzer extends Component {
  constructor(hardware, pin, id = 'Led', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;
    this.pinState = false;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'A', x: -3, y: 18, signals: [], description: 'Anode' },
    ];
    this.size = {
      width: 57,
      height: 34,
    };
    this.rotation = rotation;
    this.ref = React.createRef();
    this.BuzzerController = new BuzzerController();
    this.frequency = 0;

    this.runMicrosecond = this.runMicrosecond.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runMicrosecond = (cpuCycles) => {
    this.frequency = this.BuzzerController.play(cpuMicros(cpuCycles, this.hardware.mhz));
    this.pinState = this.BuzzerController.isPlaying();
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => (this.frequency);

  update = (pinState) => {
    this.BuzzerController.feedValue(pinState, cpuMicros(this.hardware.runner.cpu.cycles, this.hardware.mhz));
  }

  reset = () => {
    this.BuzzerController.stop();
    this.pinState = false;
    if (this.ref.current) {
      this.ref.current.buzzerOn = false;
    }
  }

  render = () => (
    <BuzzerElement
      id={this.id}
      ref={this.ref}
      key={`buzzer-${this.pin}`}
      rotation={this.rotation}
      buzzerOn={this.pinState}
    />
  );
}
