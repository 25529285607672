import React from 'react';
import AceEditor from 'react-ace';
import styles from './codePanel.mod.scss';

const CodePanel = React.memo((props) => {

  const onToggleChange = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  }
  return (
    <React.Fragment>
      <div className={`col-12 p-0 codeBox ${(props.active) ? 'codeBox-active' : ''} ${styles.codePanel}`}>
        <div className="content-codeBox">
          <AceEditor
            mode="c_cpp"
            theme={props.darktheme ? 'dracula' : 'chrome'}
            value={props.content}
            name={`aceEditor-${props.id}`}
            width={(window.innerWidth / 10) * 2.6}
            height={window.innerHeight - 100}
            fontSize={14}
            readOnly={true}
            wrapEnabled={true}
            showGutter={false}
            showPrintMargin={true}
            highlightActiveLine={true}
            editorProps={{
              $blockScrolling: Infinity,
            }}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: false,
              enableSnippets: false,
              showPrintMargin: false,
              showLineNumbers: true,
            }}
          />
        </div>
        <div className="toggle-codeBox" onKeyPress={() => { }} role="button" tabIndex="0" onClick={onToggleChange}>
          <span className="curly-braces">{'{ }'}</span>
          <span className={`icon-chevron-left ${(props.active) ? 'rotate-arrow' : ''}`} />
        </div>
      </div>
    </React.Fragment>
  );
});

export default CodePanel;