import React from 'react';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  toolTip: {
    background: 'rgba(26, 26, 26, 0.9)',
    fontSize: 14,
    maxWidth: 220,
    fontWeight: 'normal',
    padding: '8px 12px',
    textAlign: 'center',
    boxSizing: 'border-box',
    borderRadius: 10,
    lineHeight: '18px',
  },
  arrow: {
    color: 'rgba(26, 26, 26, 0.9)',
  },
  icon: {
    verticalAlign: 'bottom',
  },
  popper: ({ zIndex }) => ({
    zIndex,
  }),
});

const CustomTooltip = (props) => {
  const { zIndex, children } = props;
  const classes = useStyles({ zIndex });
  return (
    <Tooltip classes={{ tooltip: classes.toolTip, arrow: classes.arrow, popper: classes.popper }} {...props} arrow>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;

CustomTooltip.defaultProps = {
  zIndex: 1500,
};
