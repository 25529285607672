import types from '../actions/types';

export const remoteSessionsInitialState = {
  statusRemoteSessionGet: '',
  dataRemoteSessionGet: {},
  statusRemoteSessionLoginGet: '',
  dataRemoteSessionLoginGet: {},
  statusRemoteSessionRefresh: '',
  dataRemoteSessionRefresh: {},
  statusRemoteSessionProjectPost: '',
  dataRemoteSessionProjectPost: {},
  statusRemoteSessionProjectUpdate: '',
  dataRemoteSessionProjectUpdate: {},
  statusRemoteSessionProjectDelete: '',
  dataRemoteSessionProjectDelete: {},
  statusRemoteSessionProjectDeleteOne: '',
  dataRemoteSessionProjectDeleteOne: {},
  statusPutRemoteSessionProjectBulk: '',
  dataPutRemoteSessionProjectBulk: {},
  statusPostRemoteSessionCompilation: '',
  dataPostRemoteSessionCompilation: {},
  statusPutBulkRemoteSessionCompilation: '',
  dataPutBulkRemoteSessionCompilation: {},
  statusPutRemoteSession: '',
  dataPutRemoteSession: {},
  statusRemoteSessionGetList: '',
  dataRemoteSessionGetList: {},
  statusRemoteSessionStreamGet: '',
  dataRemoteSessionStreamGet: {},
};

export default function (state = remoteSessionsInitialState, action) {
  switch (action.type) {
    case types.REMOTE_SESSION_GET_FETCH:
      const dataRemoteSessionGet = action.payload.dataRemoteSessionGet; // eslint-disable-line
      return Object.assign({}, state, action.payload, dataRemoteSessionGet);
    case types.REMOTE_SESSION_REFRESH_FETCH:
      const dataRemoteSessionRefresh = action.payload.dataRemoteSessionRefresh; // eslint-disable-line
      return Object.assign({}, state, action.payload, dataRemoteSessionRefresh);
    case types.REMOTE_SESSION_STREAM_GET_FETCH:
      const dataRemoteSessionStreamGet = action.payload.dataRemoteSessionStreamGet; // eslint-disable-line
      return Object.assign({}, state, action.payload, dataRemoteSessionStreamGet);

    case types.REMOTE_SESSION_STREAM_GET_FETCHING:
    case types.REMOTE_SESSION_STREAM_GET_FETCH_ERROR:
    case types.REMOTE_SESSION_GET_FETCHING:
    case types.REMOTE_SESSION_GET_FETCH_ERROR:
    case types.REMOTE_SESSION_GET_LIST_FETCHING:
    case types.REMOTE_SESSION_GET_LIST_FETCH:
    case types.REMOTE_SESSION_GET_LIST_FETCH_ERROR:
    case types.REMOTE_SESSION_LOGIN_GET_FETCHING:
    case types.REMOTE_SESSION_LOGIN_GET_FETCH:
    case types.REMOTE_SESSION_LOGIN_GET_FETCH_ERROR:
    case types.REMOTE_SESSION_REFRESH_FETCHING:
    case types.REMOTE_SESSION_REFRESH_FETCH_ERROR:
    case types.REMOTE_SESSIONS_RESET_STATUS:
    case types.REMOTE_SESSION_PROJECTS_POST_FETCHING:
    case types.REMOTE_SESSION_PROJECTS_POST_FETCH:
    case types.REMOTE_SESSION_PROJECTS_POST_FETCH_ERROR:
    case types.REMOTE_SESSION_PROJECTS_UPDATE_FETCHING:
    case types.REMOTE_SESSION_PROJECTS_UPDATE_FETCH:
    case types.REMOTE_SESSION_PROJECTS_UPDATE_FETCH_ERROR:
    case types.REMOTE_SESSION_PROJECTS_DELETE_FETCHING:
    case types.REMOTE_SESSION_PROJECTS_DELETE_FETCH:
    case types.REMOTE_SESSION_PROJECTS_DELETE_FETCH_ERROR:
    case types.REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCHING:
    case types.REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH:
    case types.REMOTE_SESSION_PROJECTS_DELETE_ONE_FETCH_ERROR:
    case types.REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCHING:
    case types.REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH:
    case types.REMOTE_SESSION_PROJECTS_BULK_UPDATE_FETCH_ERROR:
    case types.REMOTE_SESSION_UPDATE_FETCHING:
    case types.REMOTE_SESSION_UPDATE_FETCH:
    case types.REMOTE_SESSION_UPDATE_FETCH_ERROR:
    case types.POST_REMOTE_SESSION_COMPILATION_FETCHING:
    case types.POST_REMOTE_SESSION_COMPILATION_FETCH:
    case types.POST_REMOTE_SESSION_COMPILATION_FETCH_ERROR:
    case types.PUT_REMOTE_SESSION_COMPILATION_FETCHING:
    case types.PUT_REMOTE_SESSION_COMPILATION_FETCH:
    case types.PUT_REMOTE_SESSION_COMPILATION_FETCH_ERROR:
    case types.PUT_BULK_REMOTE_SESSION_COMPILATION_FETCHING:
    case types.PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH:
    case types.PUT_BULK_REMOTE_SESSION_COMPILATION_FETCH_ERROR:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
