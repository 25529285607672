import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        type: 'initial_block',
        x: 10,
        y: 10,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="logic" categorystyle="logic_category" name="%{BKY_LANG_CATEGORY_LOGIC}">
      <label text="%{BKY_LANG_CATEGORY_LOGIC}" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
    </category>
    <category id="math" categorystyle="math_category" name="%{BKY_LANG_CATEGORY_MATH}">
      <label text="%{BKY_LANG_CATEGORY_MATH}" />
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="variables" categorystyle="variable_dynamic_category" name="%{BKY_LANG_CATEGORY_VARIABLES}" custom="VARIABLE_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>
    <category id="text" categorystyle="text_category" name="%{BKY_LANG_CATEGORY_TEXT}">
      <label text="%{BKY_LANG_CATEGORY_TEXT}" />
      <block type="text" />
      <block type="text_unir" />
      <block type="text_equalsIgnoreCase">
        <value name="STRING2">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="communication" categorystyle="communication_category" name="%{BKY_LANG_CATEGORY_COMMUNICATION}">
      <label text="%{BKY_LANG_CATEGORY_COMMUNICATION}" />
      <block type="serial_println">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="serial_print">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_blue_read_tag" />
      <block type="BHOOT_serial_blue_tag">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_bt_serial_available" />
      <block type="BHOOT_bluetooth_name">
        <value name="name">
          <shadow type="text">
            <field name="TEXT">Educabot</field>
          </shadow>
        </value>
      </block>
      <block type="SALIDAS_buttons" />
    </category>
    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_ENTRADAS}">
      <category id="inputs_digital" categorystyle="digital_inputs_category" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}">
        <label text="%{BKY_LANG_SUBCATEGORY_DIGITALES}" />
        <block type="ENTRADAS_button" />
        <block type="entradas_ultrasonido" />
        <block type="SALIDAS_read_dht11" />
        <block type="BHOOT_light_intensity" />
        <block type="BHOOT_light_color">
          <value name="COLOR">
            <shadow type="colour_bhoot">
              <field name="COLOR">#ff0000</field>
            </shadow>
          </value>
        </block>
        <block type="BHOOT_line_follower" />
      </category>
      <category id="inputs_analogue" categorystyle="analogue_inputs_category" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}">
        <label text="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" />
        <block type="entradas_suelo" />
        <block type="ENTRADAS_photoresistor" />
      </category>
    </category>
    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_SALIDAS}">
      <label text="%{BKY_LANG_CATEGORY_SALIDAS}" />
      <block type="BHOOT_led_neopixel">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_neopixel_clear" />
      <block type="BHOOT_led_rgb">
        <value name="COLOUR1">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR2">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR3">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR4">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR5">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR6">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_salidas_led_rgb">
        <value name="DATOINR">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOING">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOINB">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_piezo_buzzer" />
      <block type="BHOOT_piezo_buzzerno" />
      <block type="BHOOT_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_LCD}">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="matrix8x8" />
      <block type="matrix8x8_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_educabot">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_setBacklight" />
      <block type="lcd_clear" />
    </category>
    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_SERVO}">
      <label text="%{BKY_LANG_CATEGORY_SERVO}" />
      <block type="SALIDAS_motor" />
      <block type="BHOOT_servo_move">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_servo_move_millis">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
        <value name="millis">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_servo_read" />
      <block type="BHOOT_motor_doble" />
      <block type="BHOOT_motor_doble_pwm">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_motor_single" />
      <block type="BHOOT_motor_single_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
    </category>
  </xml>
);
/* eslint-enable */
