import React from 'react';
import BlocklyComponent from '@modules/blockly/components/blockly';
import CompilationProgress from '@modules/blockly/components/compilationProgress';

const Blockly = React.memo((props) => {
  return (
    <React.Fragment>
      <BlocklyComponent
        userData={props.userData || {}}
        tabInfo={props.tabInfo}
        socket={props.socket}
        socketAgent={props.socketAgent}
        buildingFunction={props.buildingFunction}
        buildAllowed={props.buildAllowed}
        portChangeAllowed={props.portChangeAllowed}
        teamList={props.teamList}
        setWorkspace={(props.setWorkspace) ? props.setWorkspace : null}
        noCodePanel={props.noCodePanel || false}
        isCreabots={props.isCreabots}
        showMLPanel={props.showMLPanel}
      />
      <CompilationProgress
        id={`blockly-instance-${props.tabInfo.id}`}
        progress={props.buildProgress || 0}
        microbitMode={(props.tabInfo?.className === 'microbit')}
        userName={props.userData?.name || ''}
      />
    </React.Fragment>
  );
});

export default Blockly;