/**
 * WS2812Controller
 * Part of AVR8js
 *
 * Copyright (C) 2019, Uri Shaked
 */
import { PinState } from 'avr8js';
import { cpuMicros } from '../utils';

const ZERO_18 = 18000; // ±18ms
const ONE_20 = 20;
const ONE_40 = 45;
const SIGNAL_LOW = 80;
const SIGNAL_HIGH = 80;
const SIGNAL_END_LOW = 40;
const SIGNAL_END_HIGH = 0;
const BIT_ZERO_LOW = 50;
const BIT_ZERO_HIGH = 10;
const BIT_ONE_LOW = 50;
const BIT_ONE_HIGH = 70;

export class DHT11Controller {
  constructor(pin, hardware) {
    this.pin = pin;
    this.hardware = hardware;
    this.lastState = PinState.Input;
    this.lastTimestamp = 0;
    this.detectZero = false;
    this.detectOne = false;
    this.temperature = 0;
    this.humidity = 20;
    this.sending = false;
    this.sendingLastTimestamp = 0;
    this.sendingLastBit = false;
    this.sendingFirstBit = false;
    this.bitSent = false;
    this.sendingBit = 0;
    this.checkSum = [0, 0, 0];
    this.bitOrder = 0;

    this.feedValue = this.feedValue.bind(this);
    this.sendData = this.sendData.bind(this);
    this.sendBit = this.sendBit.bind(this);
  }

  feedValue = (pinState, humidity = 0, temperature = 0) => {
    const microseconds = cpuMicros(this.hardware.runner.cpu.cycles, this.hardware.mhz);
    this.humidity = humidity;
    this.temperature = temperature;

    if (pinState !== this.lastState && !this.sending) {
      const delta = microseconds - this.lastTimestamp;
      if (this.lastState === PinState.Low) {
        // console.log('=================feedValue goes high', delta, delta >= ZERO_18);
        if (delta >= ZERO_18) {
          this.detectZero = true;
        }
      }

      if (this.lastState === PinState.High || this.lastState === PinState.InputPullUp) {
        // console.log('=================feedValue goes low', delta, delta >= ONE_20 && delta <= ONE_40);
        if (delta >= ONE_20 && delta <= ONE_40) {
          this.detectOne = true;
          if (this.detectZero) {
            this.sending = true;
            this.sendingLastTimestamp = microseconds;
          }
          this.detectZero = false;
          this.detectOne = false;
        }
      }
      // console.log('=================feedValue', delta, this.lastState, pinState);

      this.lastState = pinState;
      this.lastTimestamp = microseconds;
      this.sendingFirstBit = false;
      this.sendingLastBit = false;
    }
    if (this.sending) {
      this.sendData();
    }
  }

  sendBit = (lowTime, highTime) => {
    const microseconds = cpuMicros(this.hardware.runner.cpu.cycles, this.hardware.mhz);
    const delta = microseconds - this.sendingLastTimestamp;

    // console.log('=================SENDING DATA ...bit', this.bitSent, delta, lowTime, highTime);
    if (delta < lowTime) {
      if (!this.bitSent) {
        // console.log('=================SENDING DATA ...bit 0', delta);
        this.bitSent = true;
        this.hardware.writeDigitalPin(this.pin, 0);
      }
    } else if (delta === lowTime) {
      this.bitSent = false;
    } else if (delta > lowTime && delta <= lowTime + highTime) {
      if (!this.bitSent) {
        // console.log('=================SENDING DATA ...bit 1', delta);
        this.bitSent = true;
        this.hardware.writeDigitalPin(this.pin, 1);
      }
    } else {
      // console.log('=================SENDING DATA ...bit end', delta);
      this.bitSent = false;
      this.sendingLastTimestamp = microseconds;
      return true;
    }

    return false;
  }

  sendData = () => {
    const numberLength = 16;
    // const delta = cpuMicros(this.hardware.runner.cpu.cycles, this.hardware.mhz) - this.sendingLastTimestamp;

    if (!this.sendingFirstBit) {
      const sent = this.sendBit(SIGNAL_LOW, SIGNAL_HIGH);
      if (sent) {
        this.sendingFirstBit = true;
        this.bitOrder = (numberLength / 2) - 1;
        // console.log('=================SENDING DATA ...SSS', delta);
      }
    } else if (!this.sendingLastBit) {
      if (this.sendingBit < numberLength) {
        const bit = (this.humidity >> this.bitOrder) & ((1 << this.bitOrder) >> this.bitOrder);
        const sent = this.sendBit(bit ? BIT_ONE_LOW : BIT_ZERO_LOW, bit ? BIT_ONE_HIGH : BIT_ZERO_HIGH);
        if (sent) {
          // console.log('=================SENDING DATA ...HHH', bit, this.bitOrder);
          if (bit) {
            this.checkSum[0] |= (1 << this.bitOrder);
          }
          this.sendingBit += 1;
          this.bitOrder -= 1;
          if (this.bitOrder < 0) {
            this.bitOrder = (numberLength / 2) - 1;
          }
        }
      } else if (this.sendingBit < numberLength * 2) {
        const bit = (this.temperature >> this.bitOrder) & ((1 << this.bitOrder) >> this.bitOrder);
        const sent = this.sendBit(bit ? BIT_ONE_LOW : BIT_ZERO_LOW, bit ? BIT_ONE_HIGH : BIT_ZERO_HIGH);
        if (sent) {
          // console.log('=================SENDING DATA ...TTT', bit, this.bitOrder);
          if (bit) {
            this.checkSum[1] |= (1 << this.bitOrder);
          }
          this.sendingBit += 1;
          this.bitOrder -= 1;
          if (this.bitOrder < 0) {
            this.bitOrder = (numberLength / 2) - 1;
          }
        }
      } else if (this.sendingBit < (numberLength * 2) + 8) {
        // this.checkSum &= 0xFF;
        this.checkSum[2] = this.checkSum[0] + this.checkSum[1];
        const bit = (this.checkSum[2] >> this.bitOrder) & ((1 << this.bitOrder) >> this.bitOrder);
        const sent = this.sendBit(bit ? BIT_ONE_LOW : BIT_ZERO_LOW, bit ? BIT_ONE_HIGH : BIT_ZERO_HIGH);
        if (sent) {
          // console.log('=================SENDING DATA ...CCC', bit, this.bitOrder);
          this.sendingBit += 1;
          this.bitOrder -= 1;
        }
      } else {
        const sent = this.sendBit(SIGNAL_END_LOW, SIGNAL_END_HIGH);
        if (sent) {
          // console.log('=================SENDING DATA ...END', this.checkSum[1].toString(2), this.temperature.toString(2), this.checkSum[0].toString(2), this.humidity.toString(2), this.checkSum.toString(2), this.checkSum[2].toString(2));
          // console.log('=================SENDING DATA ...END', this.sendingBit, this.temperature, this.humidity, this.temperature.toString(2), this.humidity.toString(2), this.checkSum.toString(2));
          this.sendingLastBit = true;
          this.checkSum = [0, 0, 0];
          this.sendingBit = 0;
          this.bitOrder = 0;
        }
      }
    } else {
      this.sendingBit = 0;
      this.checkSum = [0, 0, 0];
      this.sending = false;
      this.bitOrder = 0;
    }
  }
}
