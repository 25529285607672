import React from 'react';
import styled from 'styled-components';
import BottomArrowIcon from './bottom-arrow.svg';

const RoundedButton = styled.div`
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  background-color: #fff;
  border: none;
  box-shadow: 0px -3px 2px 0px rgba(0,0,0,0.35);
  position: fixed;
  z-index: 1998 !important;
  right: 2vw;
  bottom: 2vw;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  transform: rotate(180deg);
`;

const funcScroll = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export default function RoundedButtonForScrollToInit() {
  return <RoundedButton onClick={funcScroll}><BottomArrowIcon fill="#4f36d6" width="1.3vw" height="1.3vw" /></RoundedButton>;
}
