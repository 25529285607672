import React from 'react';
import clsx from 'clsx';
import styles from './labelChip.mod.scss';

const LabelChip = ({ className, classNames = { }, color, label }) => (
  <div className={clsx(className, styles.container, classNames.container)} style={{ background: color }}>
    <p className={clsx(styles.label, classNames.label)}>
      {label}
    </p>
  </div>
);

export default LabelChip;
