export const userRoles = {
  OWNER: 'OWNER',
  TEACHER: 'TEACHER',
  LEADER: 'LEADER',
  STUDENT: 'STUDENT',
};

export const tutorialEvaluationTypes = {
  START: 'START',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  FINISHED: 'FINISHED',
};

export const compilationStates = {
  PENDING: 'PENDING',
  COMPILING: 'COMPILING',
  COMPILED: 'COMPILED',
  REJECTED: 'REJECTED',
  ERROR: 'ERROR',
};

export const sessionKeys = {
  INTRO_VIDEO_HIDDEN: 'INTRO_VIDEO_HIDDEN',
};

export const jitsiConfig = {
  domain: 'conectados.educabot.com',
  ownerDisplayName: 'Maqueta',
  configOverwrite: {
    disableAudioLevels: true,
    enableNoAudioDetection: false,
    enableNoisyMicDetection: false,
    disableInitialGUM: true,

    disableJoinLeaveSounds: true,
    hideConferenceSubject: true,
    hideConferenceTimer: true,
    hideParticipantsStats: true,
    disableResponsiveTiles: true,
    maxFullResolutionParticipants: -1,
    disableSelfView: true,
    disableSelfViewSettings: true,
    disableTileView: true,
    startWithVideoMuted: true,
    startWithAudioMuted: true,
    enableLayerSuspension: true,
    // disableSimulcast: true,
    // resolution: 180,
    // maxFps: 25,
    // constraints: {
    //   video: {
    //     frameRate: {
    //       max: 25,
    //       min: 12,
    //     },
    //     height: {
    //       ideal: 180,
    //       max: 180,
    //       min: 180,
    //     },
    //   },
    // },
    p2p: {
      enabled: false,
    },
    toolbarButtons: [],
    connectionIndicators: {
      autoHide: true,
      autoHideTimeout: 5,
      disabled: true,
      disableDetails: true,
      inactiveDisabled: true,
    },
  },

  interfaceConfigOverwrite: {
    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    DISABLE_PRESENCE_STATUS: true,
    ENABLE_DIAL_OUT: false,
    FILM_STRIP_MAX_HEIGHT: 1,
    HIDE_INVITE_MORE_HEADER: true,
    TILE_VIEW_MAX_COLUMNS: 1,
    SETTINGS_SECTIONS: [],
  },
};

export const jitsiConfigHD = {
  ...jitsiConfig,
  configOverwrite: { ...jitsiConfig.configOverwrite, toolbarButtons: ['fullscreen'] },
  resolution: 1080,
  maxFps: 25,
  constraints: {
    video: {
      frameRate: {
        max: 25,
        min: 12,
      },
      height: {
        ideal: 1080,
        max: 1080,
        min: 180,
      },
    },
  },
};

export default userRoles;
