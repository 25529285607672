import React, { Component } from 'react';
import './led.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

const lightColors = {
  red: '#ff8080',
  green: '#80ff80',
  blue: '#8080ff',
  yellow: '#ffff80',
  orange: '#ffcf80',
  white: '#ffffff',
  purple: '#ff80ff',
};

export class LedElement extends Component {
  constructor(props) {
    super(props);
    this.value = props.value || false;
    this.speed = props.speed || 0;
    this.color = lightColors[props.color] || lightColors.white;
    this.state = {
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.value = newProps.value;
    this.speed = newProps.speed;
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    const opacity = this.speed ? 0.3 + (this.speed / 100) * 0.7 : 0;
    const lightOn = this.value && (this.speed / 100) > Number.EPSILON;

    return (
      <div className="led" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 114.83 56.99"
          width="15mm"
          height="9mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >
          <defs>
            <style>
              {`.cls-led-color-${id} { fill:${this.color}; opacity:0.7; }`}
              {`.cls-led-glow-${id} { ${lightOn ? '' : 'display: none'} }`}
            </style>
          </defs>

          <filter id="ledFilter" x="-0.7" y="-0.7" height="2" width="3">
            <feGaussianBlur stdDeviation="8" />
          </filter>

          <g id="capa-led" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <line className="cls-led-2" x1="110.33" y1="56.86" x2="63.84" y2="56.86" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <line className="cls-led-2" x1="51.89" y1="56.86" x2="5.45" y2="56.86" />
              </g>
              <g id="LINE-3" data-name="LINE">
                <line className="cls-led-2" x1="1.19" y1="52.61" x2="1.2" y2="4.42" />
              </g>
              <g id="LINE-4" data-name="LINE">
                <line className="cls-led-2" x1="63.84" y1=".17" x2="110.33" y2=".17" />
              </g>
              <g id="ARC">
                <path className="cls-led-10" d="m1.2,4.42s0,0,0,0c0-2.35,1.91-4.25,4.25-4.25h46.45c.8,0,1.45.64,1.45,1.44v2.78c0,2.5,2.03,4.53,4.54,4.54,2.5,0,4.53-2.03,4.54-4.53,0,0,0,0,0,0V1.58c0-.78.63-1.42,1.42-1.42h46.5c2.35,0,4.25,1.91,4.25,4.25v48.19s0,0,0,0c0,2.35-1.91,4.25-4.25,4.25h-46.49c-.78,0-1.42-.64-1.42-1.42v-2.81c0-2.51-2.03-4.54-4.54-4.54-2.5,0-4.53,2.03-4.54,4.54v2.78s0,0,0,0c0,.8-.65,1.44-1.45,1.44H5.45c-2.35,0-4.25-1.9-4.25-4.25" />
              </g>
              <g id="LINE-5" data-name="LINE">
                <line className="cls-led-2" x1="5.45" y1=".17" x2="51.89" y2=".16" />
              </g>
              <path className="cls-led-4" d="m80.14,22.25h56.87v8.26c0,2.35-1.91,4.26-4.26,4.26h-48.35c-2.35,0-4.26-1.91-4.26-4.26v-8.26h0Z" transform="translate(80.06 137.09) rotate(-90)" />
              <polygon className="cls-led-4" points="52.27 37.13 63.47 37.13 67.72 41.21 67.72 56.97 61.22 56.97 61.22 51.81 61.22 51.48 61.09 50.83 60.83 50.22 60.44 49.67 60.2 49.44 59.97 49.22 59.44 48.88 58.84 48.64 58.21 48.52 57.89 48.52 57.83 48.52 57.83 48.52 57.5 48.52 56.84 48.65 56.23 48.89 55.67 49.25 55.44 49.48 55.44 49.48 55.2 49.7 54.84 50.23 54.58 50.82 54.46 51.44 54.46 51.75 54.46 56.97 48.02 56.97 48.02 41.21 52.27 37.13" />
              <rect className="cls-led-11" x="5.47" y="7.25" width="34.58" height="42.52" transform="translate(-5.75 51.27) rotate(-90)" />
              <rect className="cls-led-3" x="-4.96" y="17.95" width="34.31" height="21.13" transform="translate(-16.32 40.7) rotate(-90)" />
              <rect className="cls-led-9" x="16.18" y="17.94" width="34.31" height="21.15" transform="translate(4.82 61.84) rotate(-90)" />
              <g>
                <path className="cls-led-6" d="m106.38,44.6v-.54h3.91v-1.8h.47v2.34h-4.38Z" />
                <path className="cls-led-6" d="m108.71,39.48v1.64h1.58v-1.82h.47v2.37h-4.38v-2.27h.47v1.73h1.38v-1.64h.47Z" />
                <path className="cls-led-6" d="m106.44,38.58c-.05-.33-.09-.72-.09-1.15,0-.78.19-1.34.55-1.7.36-.37.86-.59,1.57-.59s1.3.21,1.7.6c.41.39.63,1.04.63,1.86,0,.39-.02.71-.05.99h-4.31Zm3.87-.54c.03-.14.03-.34.03-.55,0-1.16-.68-1.79-1.86-1.79-1.03,0-1.69.56-1.69,1.7,0,.28.03.49.06.64h3.46Z" />
                <path className="cls-led-6" d="m106.44,33.1c-.05-.24-.09-.61-.09-.99,0-.54.1-.89.32-1.15.17-.22.43-.35.77-.35.42,0,.79.27.96.71h.01c.1-.4.45-.87,1.1-.87.38,0,.66.14.88.36.28.29.41.77.41,1.45,0,.37-.03.66-.05.84h-4.31Zm1.79-.54v-.49c0-.57-.31-.91-.73-.91-.51,0-.72.37-.72.92,0,.25.02.39.04.48h1.41Zm2.1,0c.02-.11.03-.26.03-.46,0-.56-.21-1.08-.85-1.08-.6,0-.84.49-.84,1.09v.45h1.67Z" />
                <path className="cls-led-6" d="m106.38,29.72v-.54h3.91v-1.8h.47v2.34h-4.38Z" />
                <path className="cls-led-6" d="m109.39,26.09l1.38.44v.56l-4.38-1.43v-.66l4.38-1.44v.58l-1.38.45v1.49Zm-.44-1.38l-1.26.41c-.29.09-.55.16-.8.22h0c.26.07.53.14.79.22l1.27.41v-1.27Z" />
                <path className="cls-led-6" d="m110.77,22.95h-4.38v-.59l2.22-1.35c.51-.31.98-.56,1.42-.76h0c-.59.04-1.12.05-1.8.05h-1.83v-.51h4.38v.55l-2.22,1.34c-.49.29-.99.57-1.46.79v.02c.56-.03,1.09-.04,1.81-.04h1.87v.51Z" />
                <path className="cls-led-6" d="m110.62,15.91c.1.2.21.6.21,1.11,0,1.19-.78,2.08-2.22,2.08s-2.3-.89-2.3-2.2c0-.52.12-.85.2-1l.46.13c-.1.21-.18.5-.18.85,0,.99.66,1.64,1.81,1.64,1.07,0,1.76-.59,1.76-1.62,0-.33-.07-.67-.18-.89l.45-.11Z" />
                <path className="cls-led-6" d="m108.53,11.76c1.51,0,2.31.88,2.31,1.95s-.9,1.89-2.22,1.89c-1.39,0-2.3-.83-2.3-1.95s.92-1.89,2.22-1.89Zm.07,3.26c.94,0,1.78-.49,1.78-1.34s-.83-1.35-1.82-1.35c-.87,0-1.78.44-1.78,1.34s.86,1.35,1.83,1.35Z" />
              </g>
              <g id="CIRCLE">
                <path className="cls-led-12" d="m57.88,34.13c-3.13,0-5.67-2.54-5.67-5.67s2.54-5.67,5.67-5.67,5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67h0Z" />
              </g>
              <rect className="cls-led-7" x="72.49" y="25.91" width="2.27" height="4.54" transform="translate(147.25 56.36) rotate(180)" />
              <rect className="cls-led-5" x="72.49" y="26.76" width="2.27" height="2.83" transform="translate(147.25 56.36) rotate(180)" />
              <polygon className="cls-led-4" points="54.13 0 54.13 5.16 54.13 5.49 54.26 6.14 54.52 6.75 54.9 7.3 55.15 7.53 55.38 7.75 55.91 8.09 56.51 8.33 57.14 8.45 57.46 8.45 57.52 8.45 57.85 8.45 58.51 8.32 59.12 8.08 59.68 7.72 59.91 7.49 60.15 7.27 60.51 6.74 60.76 6.16 60.89 5.53 60.89 5.22 60.89 0 67.33 0 67.33 15.76 63.08 19.84 51.88 19.84 47.63 15.76 47.63 0 54.13 0" />

              {/* LED */}
              <g id="ARC-2" data-name="ARC">
                <path className={`cls-led-13 cls-led-color-${id}`} d="m91.02,21.09h-4.7c-2.38,1.58-3.8,4.24-3.8,7.09,0,4.7,3.81,8.5,8.5,8.5,4.7,0,8.5-3.81,8.5-8.5,0-2.85-1.43-5.51-3.8-7.09h-4.7Z" />
                <circle style={{opacity}} className={`cls-led-glow-${id}`} cx="91" cy="27" r="20" fill={this.color} filter="url(#ledFilter)" />
              </g>

              <g>
                <rect className="cls-led-4" x="29.83" y="49.63" width=".85" height=".85" />
                <rect className="cls-led-4" x="29.83" y="53.86" width=".85" height=".85" />
                <polygon className="cls-led-4" points="26.44 49.63 25.59 49.63 24.74 49.63 24.74 50.47 23.89 50.47 23.89 51.32 23.05 51.32 22.2 51.32 22.2 53.01 23.05 53.01 23.89 53.01 23.89 53.86 24.74 53.86 24.74 54.7 25.59 54.7 26.44 54.7 26.44 55.55 27.29 55.55 29.83 55.55 29.83 54.7 27.29 54.7 27.29 49.63 29.83 49.63 29.83 48.78 27.29 48.78 26.44 48.78 26.44 49.63" />
                <rect className="cls-led-4" x="30.68" y="50.47" width=".85" height="3.38" />
              </g>
              <g id="LINE-6" data-name="LINE">
                <line className="cls-led-1" x1="26.58" y1="3.71" x2="28.84" y2="3.71" />
              </g>
              <g id="LINE-7" data-name="LINE">
                <line className="cls-led-1" x1="28.84" y1="5.98" x2="26.58" y2="5.98" />
              </g>
              <g id="LINE-8" data-name="LINE">
                <path className="cls-led-8" d="m28.84,5.98h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04v-.57h-1.13s-2.27,0-2.27,0h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13" />
              </g>
              <rect className="cls-led-9" x="26.58" y="3.5" width="2.27" height="2.67" transform="translate(22.87 32.55) rotate(-90)" />
              <g>
                <path className="cls-led-4" d="m95.65,7.9v.02l.52.93-.57.14-.65-1.16v-.61h5.53v.7h-4.84Z" />
                <path className="cls-led-4" d="m95.65,3.67v.02l.52.93-.57.14-.65-1.16v-.61h5.53v.7h-4.84Z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
