/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-underscore-dangle: 0 */
/* eslint operator-linebreak: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-multi-spaces: 0 */
/* eslint no-continue: 0 */
/* eslint camelcase: 0 */
/* eslint dot-notation: 0 */
/* eslint prefer-template: 0 */

import * as Blockly from 'blockly';

const CodeGenerator = Blockly.CodeGenerator;
const Names = Blockly.Names;
const NameType = Blockly.Names.NameType;
const stringUtils = Blockly.utils.string;
const Variables = Blockly.Variables;
const inputTypes = Blockly.inputs.inputTypes;

// prettier-ignore
export const Order = {
  ATOMIC: 0,            // 0 "" ...
  NEW: 1.1,             // new
  MEMBER: 1.2,          // . []
  FUNCTION_CALL: 2,     // ()
  INCREMENT: 3,         // ++
  DECREMENT: 3,         // --
  BITWISE_NOT: 4.1,     // ~
  UNARY_PLUS: 4.2,      // +
  UNARY_NEGATION: 4.3,  // -
  LOGICAL_NOT: 4.4,     // !
  TYPEOF: 4.5,          // typeof
  VOID: 4.6,            // void
  DELETE: 4.7,          // delete
  AWAIT: 4.8,           // await
  EXPONENTIATION: 5.0,  // **
  MULTIPLICATION: 5.1,  // *
  DIVISION: 5.2,        // /
  MODULUS: 5.3,         // %
  SUBTRACTION: 6.1,     // -
  ADDITION: 6.2,        // +
  BITWISE_SHIFT: 7,     // << >> >>>
  RELATIONAL: 8,        // < <= > >=
  IN: 8,                // in
  INSTANCEOF: 8,        // instanceof
  EQUALITY: 9,          // == != === !==
  BITWISE_AND: 10,      // &
  BITWISE_XOR: 11,      // ^
  BITWISE_OR: 12,       // |
  LOGICAL_AND: 13,      // &&
  LOGICAL_OR: 14,       // ||
  CONDITIONAL: 15,      // ?:
  ASSIGNMENT: 16,       // = += -= **= *= /= %= <<= >>= ...
  YIELD: 17,            // yield
  COMMA: 18,            // ,
  NONE: 99,             // (...)

  UNARY_POSTFIX: 1,     // expr++ expr-- () [] .
  UNARY_PREFIX: 2,      // -expr !expr ~expr ++expr --expr
  MULTIPLICATIVE: 3,    // * / % ~/
  ADDITIVE: 4,          // + -
  SHIFT: 5,             // << >>
}

/**
 * JavaScript code generator class.
 */
export class ArduinoGenerator extends CodeGenerator {
  /** List of outer-inner pairings that do NOT require parentheses. */
  ORDER_OVERRIDES = [
    // (foo()).bar -> foo().bar
    // (foo())[0] -> foo()[0]
    [Order.FUNCTION_CALL, Order.MEMBER],
    // (foo())() -> foo()()
    [Order.FUNCTION_CALL, Order.FUNCTION_CALL],
    // (foo.bar).baz -> foo.bar.baz
    // (foo.bar)[0] -> foo.bar[0]
    // (foo[0]).bar -> foo[0].bar
    // (foo[0])[1] -> foo[0][1]
    [Order.MEMBER, Order.MEMBER],
    // (foo.bar)() -> foo.bar()
    // (foo[0])() -> foo[0]()
    [Order.MEMBER, Order.FUNCTION_CALL],

    // !(!foo) -> !!foo
    [Order.LOGICAL_NOT, Order.LOGICAL_NOT],
    // a * (b * c) -> a * b * c
    [Order.MULTIPLICATION, Order.MULTIPLICATION],
    // a + (b + c) -> a + b + c
    [Order.ADDITION, Order.ADDITION],
    // a && (b && c) -> a && b && c
    [Order.LOGICAL_AND, Order.LOGICAL_AND],
    // a || (b || c) -> a || b || c
    [Order.LOGICAL_OR, Order.LOGICAL_OR],
  ];

  /** @param name Name of the language the generator is for. */
  constructor(name = 'Arduino') {
    super(name);
    this.isInitialized = false;

    // Copy Order values onto instance for backwards compatibility
    // while ensuring they are not part of the publically-advertised
    // API.
    //
    // TODO(#7085): deprecate these in due course.  (Could initially
    // replace data properties with get accessors that call
    // deprecate.warn().)
    Object.keys(Order).forEach(key => {
      // Must assign Order[key] to a temporary to get the type guard to work;
      // see https://github.com/microsoft/TypeScript/issues/10530.
      const value = Order[key];
      this['ORDER_' + key] = value;
    });

    // List of illegal variable names.  This is not intended to be a
    // security feature.  Blockly is 100% client-side, so bypassing
    // this list is trivial.  This is intended to prevent users from
    // accidentally clobbering a built-in object or function.
    //
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Lexical_grammar#Keywords
    this.addReservedWords(
      'Blockly,' +  // In case JS is evaled in the current window.
      'setup,loop,if,else,for,switch,case,while,do,break,continue,return,goto,' +
      'define,include,HIGH,LOW,INPUT,OUTPUT,INPUT_PULLUP,true,false,integer,' +
      'constants,floating,point,void,boolean,char,unsigned,byte,int,word,long,' +
      'float,double,string,String,array,static,volatile,const,sizeof,pinMode,' +
      'digitalWrite,digitalRead,analogReference,analogRead,analogWrite,tone,' +
      'noTone,shiftOut,shitIn,pulseIn,millis,micros,delay,delayMicroseconds,' +
      'min,max,abs,constrain,map,pow,sqrt,sin,cos,tan,randomSeed,random,' +
      'lowByte,highByte,bitRead,bitWrite,bitSet,bitClear,bit,attachInterrupt,' +
      'detachInterrupt,interrupts,noInterrupts',
    );
  }

  /**
   * Initialise the database of variable names.
   *
   * @param workspace Workspace to generate code from.
   */
  init(workspace) {
    super.init(workspace);

    this.includes_ = Object.create(null);
    this.definitions_ = Object.create(null);
    this.setups_ = Object.create(null);
    this.loop_ = Object.create(null);
    this.loop_footer_ = Object.create(null);

    if (!this.nameDB_) {
      this.nameDB_ = new Names(this.RESERVED_WORDS_);
    } else {
      this.nameDB_.reset();
    }

    this.nameDB_.setVariableMap(workspace.getVariableMap());
    this.nameDB_.populateVariables(workspace);
    this.nameDB_.populateProcedures(workspace);

    const defvars = [];
    // Add developer variables (not created or named by the user).
    const devVarList = Variables.allDeveloperVariables(workspace);
    for (let i = 0; i < devVarList.length; i += 1) {
      defvars.push(
        this.nameDB_.getName(devVarList[i], NameType.DEVELOPER_VARIABLE),
      );
    }

    // Add user variables, but only ones that are being used.
    const variables = Variables.allUsedVarModels(workspace);
    for (let i = 0; i < variables.length; i += 1) {
      defvars.push(
        this.nameDB_.getName(variables[i].getId(), NameType.VARIABLE),
      );
    }

    // Declare all of the variables.
    // if (defvars.length) {
    //   this.definitions_['variables'] = 'int ' + defvars.join(', ') + ';';
    // }
    this.isInitialized = true;
  }

  /**
   * Prepend the generated code with the variable definitions.
   *
   * @param code Generated code.
   * @returns Completed code.
   */
  finish(code) {
    const includes = Object.values(this.includes_);
    const definitions = Object.values(this.definitions_);
    const setups = Object.values(this.setups_);
    const loop = Object.values(this.loop_);
    const loop_footer = Object.values(this.loop_footer_);

    super.finish(code);
    this.isInitialized = false;

    if (this.nameDB_) {
      this.nameDB_.reset();
    }

    return `
${includes.join(`
${this.INDENT}`)}
${definitions.join(`
${this.INDENT}`)}
void setup() {
${this.INDENT}${setups.join(`
${this.INDENT}`)}
}

void loop() {
${this.INDENT}${loop.join(`
${this.INDENT}`)}
${code}
${this.INDENT}${loop_footer.join(`
${this.INDENT}`)}
}
`;
  }

  /**
   * Naked values are top-level blocks with outputs that aren't plugged into
   * anything.  A trailing semicolon is needed to make this legal.
   *
   * @param line Line of generated code.
   * @returns Legal line of code.
   */
  scrubNakedValue(line) {
    return line + ';\n';
  }

  /**
   * Encode a string as a properly escaped JavaScript string, complete with
   * quotes.
   *
   * @param string Text to encode.
   * @returns JavaScript string.
   */
  quote_(string) {
    // Can't use goog.string.quote since Google's style guide recommends
    // JS string literals use single quotes.
    string = string
      .replace(/\\/g, '\\\\')
      .replace(/\n/g, '\\\n')
      .replace(/'/g, "\\'");
    return "'" + string + "'";
  }

  /**
   * Encode a string as a properly escaped multiline JavaScript string, complete
   * with quotes.
   * @param string Text to encode.
   * @returns JavaScript string.
   */
  multiline_quote_(string) {
    // Can't use goog.string.quote since Google's style guide recommends
    // JS string literals use single quotes.
    const lines = string.split(/\n/g).map(this.quote_);
    return lines.join(" + '\\n' +\n");
  }

  /**
   * Common tasks for generating JavaScript from blocks.
   * Handles comments for the specified block and any connected value blocks.
   * Calls any statements following this block.
   *
   * @param block The current block.
   * @param code The JavaScript code created for this block.
   * @param thisOnly True to generate code for only this statement.
   * @returns JavaScript code with comments and subsequent blocks added.
   */
  scrub_(block, code, thisOnly = false) {
    let commentCode = '';
    // Only collect comments for blocks that aren't inline.
    if (!block.outputConnection || !block.outputConnection.targetConnection) {
      // Collect comment for this block.
      let comment = block.getCommentText();
      if (comment) {
        comment = stringUtils.wrap(comment, this.COMMENT_WRAP - 3);
        commentCode += this.prefixLines(`${comment}\n`, '// ');
      }
      // Collect comments for all value arguments.
      // Don't collect comments for nested statements.
      for (let i = 0; i < block.inputList.length; i += 1) {
        if (block.inputList[i].type === inputTypes.VALUE) {
          let childBlock = null;
          if (block.inputList[i].connection) {
            childBlock = block.inputList[i].connection.targetBlock();
          }
          if (childBlock) {
            comment = this.allNestedComments(childBlock);
            if (comment) {
              commentCode += this.prefixLines(comment, '// ');
            }
          }
        }
      }
    }
    const nextBlock =
      block.nextConnection && block.nextConnection.targetBlock();
    const nextCode = thisOnly ? '' : this.blockToCode(nextBlock);
    return commentCode + code + nextCode;
  }

  /**
   * Generate code representing the specified value input, adjusted to take into
   * account indexing (zero- or one-based) and optionally by a specified delta
   * and/or by negation.
   *
   * @param block The block.
   * @param atId The ID of the input block to get (and adjust) the value of.
   * @param delta Value to add.
   * @param negate Whether to negate the value.
   * @param order The highest order acting on this value.
   * @returns The adjusted value or code that evaluates to it.
   */
  getAdjusted(
    block,
    atId,
    delta = 0,
    negate = false,
    order = Order.NONE,
  ) {
    if (block.workspace.options.oneBasedIndex) {
      delta -= 1;
    }
    const defaultAtIndex = block.workspace.options.oneBasedIndex ? '1' : '0';

    let orderForInput = order;
    if (delta > 0) {
      orderForInput = Order.ADDITION;
    } else if (delta < 0) {
      orderForInput = Order.SUBTRACTION;
    } else if (negate) {
      orderForInput = Order.UNARY_NEGATION;
    }

    let at = this.valueToCode(block, atId, orderForInput) || defaultAtIndex;

    // Easy case: no adjustments.
    if (delta === 0 && !negate) {
      return at;
    }
    // If the index is a naked number, adjust it right now.
    if (stringUtils.isNumber(at)) {
      at = String(Number(at) + delta);
      if (negate) {
        at = String(-Number(at));
      }
      return at;
    }
    // If the index is dynamic, adjust it in code.
    if (delta > 0) {
      at = `${at} + ${delta}`;
    } else if (delta < 0) {
      at = `${at} - ${-delta}`;
    }
    if (negate) {
      at = delta ? `-(${at})` : `-${at}`;
    }
    if (Math.floor(order) >= Math.floor(orderForInput)) {
      at = `(${at})`;
    }
    return at;
  }
}