import styled from 'styled-components';

export const CanvasOuterDefault = styled.div`
  position: relative;
  background-color: #121723;
  // background-size: 20px 20px;
  // background-image:
  //   linear-gradient(90deg,hsla(0,0%,100%,.2) 1px,transparent 0),
  //   linear-gradient(180deg,hsla(0,0%,100%,.2) 1px,transparent 0);
  overflow: hidden;
  cursor: not-allowed;
  width: 100%;
  height: 100%;
`;
