/* eslint-disable max-len */
import React from 'react';

function FileCheck() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M 7 2 C 5.895 2 5 2.895 5 4 L 5 26 C 5 27.105 5.895 28 7 28 L 15.517578 28 C 14.559578 26.57 14 24.851 14 23 C 14 18.029 18.029 14 23 14 C 23.688 14 24.356 14.083469 25 14.230469 L 25 9.5 C 25 9.235 24.895031 8.9799688 24.707031 8.7929688 L 18.207031 2.2929688 C 18.019031 2.1049688 17.765 2 17.5 2 L 7 2 z M 17 3.9042969 L 23.095703 10 L 18 10 C 17.448 10 17 9.552 17 9 L 17 3.9042969 z M 23 16 C 19.134 16 16 19.134 16 23 C 16 26.866 19.134 30 23 30 C 26.866 30 30 26.866 30 23 C 30 19.134 26.866 16 23 16 z M 26 20 C 26.25575 20 26.511531 20.097469 26.707031 20.292969 C 27.098031 20.683969 27.098031 21.316031 26.707031 21.707031 L 22.707031 25.707031 C 22.512031 25.902031 22.256 26 22 26 C 21.744 26 21.487969 25.902031 21.292969 25.707031 L 19.292969 23.707031 C 18.901969 23.316031 18.901969 22.683969 19.292969 22.292969 C 19.683969 21.901969 20.316031 21.901969 20.707031 22.292969 L 22 23.585938 L 25.292969 20.292969 C 25.488469 20.097469 25.74425 20 26 20 z" />
    </svg>
  );
}

export default FileCheck;
