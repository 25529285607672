import React, { useState } from 'react';
import { LabsLogo } from '@assets/icons/labsLogo';
import { useLocation } from 'react-router-dom';
import { EmailStep } from '../components/recoverPassword/emailStep';
import { CodeStep } from '../components/recoverPassword/codeStep';
import styles from './signIn.mod.scss';
import { PasswordStep } from '../components/recoverPassword/passwordStep';


const initialErrorsValues = {
  email: '',
  newPassword: '',
  repeatPassword: '',
  code: '',
};

export const CreabotsRecoverPassword = () => {
  const { search, pathname } = useLocation();
  const queryEmail = new URLSearchParams(search).get('email');
  const queryCode = new URLSearchParams(search).get('code');
  const [fields, setFields] = useState({
    email: queryEmail || '',
    code: queryCode || '',
    newPassword: '',
    repeatPassword: '',
  });
  const [errors, setErrors] = useState(initialErrorsValues);

  return (
    <section className={styles.container}>
      <header className={styles.signInHeader}>
        <h2 className={styles.creabotsLabel}>
          <LabsLogo />
        </h2>
      </header>

      {pathname === '/kits/recuperar-contraseña' && (
      <EmailStep
        fields={fields}
        errors={errors}
        setFields={setFields}
        setErrors={setErrors}

      />
      )}
      {pathname.includes('/kits/recuperar-contraseña/verificar') && (
      <CodeStep
        fields={fields}
        errors={errors}
        setFields={setFields}
        setErrors={setErrors}

      />
      )}
      {pathname.includes('/kits/recuperar-contraseña/crear') && (
      <PasswordStep
        fields={fields}
        errors={errors}
        setFields={setFields}
        setErrors={setErrors}

      />
      )}
    </section>
  );
};
