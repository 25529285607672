import Tutorial1Step1 from '@sections/creabots/components/steps/Tutorial1Step1';
import { BLOCK_CATEGORY_TYPE, BOARD_ID_TYPE, CODE_CHECK_TYPE, COMMON_CLASSES, DRIVING_PROJECTS_TYPE, KIT_NAME, MODAL_TYPE, Project } from '@sections/creabots/types/kits';
import * as routes from '@educabot/educablocks-cosmos';
import { getDrivingConfigForBlock, getDrivingElementForBlock } from '../config';
import TutorialSlider from '@sections/creabots/components/steps/TutorialSlider';
import { getTutorialImagesInfo } from '@sections/creabots/features/utils/getTutorialImagesInfo';

const intlPrefix = 'creabots.tutorials.driving.projects.automaticHandling';
const imgBaseUrl = '/images/creabots/driving/projects/automaticHandling';

export const automaticHandling: Project = {
  id: 4,
  name: `${intlPrefix}.name`,
  description: `${intlPrefix}.description`,
  image: `${routes.bloquesUri}${imgBaseUrl}/project_image.png`,
  boardId: BOARD_ID_TYPE.MOVEMENT,
  tutorial: [
    // Step 1.
    {
      step: {
        stepId: 0,
        modal: MODAL_TYPE.FULL,
        component: Tutorial1Step1,
        videoUrl: 'https://storage.googleapis.com/educabot-aec-cdn-1/labs/manejo-automatico.mp4',
      },
    },
    // Step 2.
    {
      step: {
        stepId: 1,
        modal: MODAL_TYPE.FULL,
        component: TutorialSlider,
        images: getTutorialImagesInfo({ kitName: KIT_NAME.DRIVING, projectName: DRIVING_PROJECTS_TYPE.AUTOMATIC_HANDLING, totalSteps: 24 }),
      },
    },
    // Step 3.
    {
      step: {
        stepId: 2,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step3`,
        image: `${imgBaseUrl}/steps/step3.png`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 5),
        completionCode: 'voidsetup\\(\\)\\{\\}voidloop\\(\\)\\{intBhoot_SeguidorDerecha=analogRead\\(A0\\);intBhoot_SeguidorIzquierda=analogRead\\(A7\\);intBhoot_SeguidorCentro=analogRead\\(A1\\);\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.DIGITAL, 5),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 45,
            yOffset: 70,
          },
        },
      ],
    },
    // Step 4.
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{intBhoot_SeguidorDerecha=analogRead\\(A0\\);intBhoot_SeguidorIzquierda=analogRead\\(A7\\);intBhoot_SeguidorCentro=analogRead\\(A1\\);if\\(Bhoot_SeguidorDerecha<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_Avanzar\\(255\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 115,
            yOffset: 115,
          },
        },
      ],
    },
    {
      step: {
        stepId: 3,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step4`,
        image: `${imgBaseUrl}/steps/step4.gif`,
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{intBhoot_SeguidorDerecha=analogRead\\(A0\\);intBhoot_SeguidorIzquierda=analogRead\\(A7\\);intBhoot_SeguidorCentro=analogRead\\(A1\\);if\\(Bhoot_SeguidorDerecha<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_GirarIzquierda\\(255\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 205,
            yOffset: 125,
          },
        },
      ],
    },
    // Step 5.
    {
      step: {
        stepId: 4,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step5`,
        image: `${imgBaseUrl}/steps/step5.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{intBhoot_SeguidorDerecha=analogRead\\(A0\\);intBhoot_SeguidorIzquierda=analogRead\\(A7\\);intBhoot_SeguidorCentro=analogRead\\(A1\\);if\\(Bhoot_SeguidorDerecha<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_GirarIzquierda\\(255\\);\\}if\\(Bhoot_SeguidorDerecha>30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_Avanzar\\(255\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 115,
            yOffset: 200,
          },
        },
      ],
    },
    // Step 6.
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        ...getDrivingConfigForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{intBhoot_SeguidorDerecha=analogRead\\(A0\\);intBhoot_SeguidorIzquierda=analogRead\\(A7\\);intBhoot_SeguidorCentro=analogRead\\(A1\\);if\\(Bhoot_SeguidorDerecha<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_GirarIzquierda\\(255\\);\\}if\\(Bhoot_SeguidorDerecha>30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_Avanzar\\(255\\);\\}if\\(Bhoot_SeguidorIzquierda<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorDerecha>30\\)\\{Bhoot_Avanzar\\(255\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: getDrivingElementForBlock(BLOCK_CATEGORY_TYPE.MOTORS, 4),
        },
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 115,
            yOffset: 280,
          },
        },
      ],
    },
    {
      step: {
        stepId: 5,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step6`,
        image: `${imgBaseUrl}/steps/step6.gif`,
        completionCode: 'voidsetup\\(\\)\\{pinMode\\(Bhoot_MI,OUTPUT\\);pinMode\\(Bhoot_PWM1,OUTPUT\\);pinMode\\(Bhoot_MD,OUTPUT\\);pinMode\\(Bhoot_PWM2,OUTPUT\\);\\}voidloop\\(\\)\\{intBhoot_SeguidorDerecha=analogRead\\(A0\\);intBhoot_SeguidorIzquierda=analogRead\\(A7\\);intBhoot_SeguidorCentro=analogRead\\(A1\\);if\\(Bhoot_SeguidorDerecha<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_GirarIzquierda\\(255\\);\\}if\\(Bhoot_SeguidorDerecha>30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorIzquierda>30\\)\\{Bhoot_Avanzar\\(255\\);\\}if\\(Bhoot_SeguidorIzquierda<=30&&Bhoot_SeguidorCentro>30&&Bhoot_SeguidorDerecha>30\\)\\{Bhoot_GirarDerecha\\(255\\);\\}\\}',
        completionCodeCheck: CODE_CHECK_TYPE.REGEX,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.INITIAL_BLOCK_CLASS,
            xOffset: 205,
            yOffset: 290,
          },
        },
      ],
    },
    // Step 7.
    {
      step: {
        stepId: 6,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step7`,
        image: `${imgBaseUrl}/steps/step7.gif`,
        completionCodeCheck: CODE_CHECK_TYPE.BUILT,
      },
      hints: [
        {
          element: {
            class: COMMON_CLASSES.BUILD_BLOCKLY_BTN_CLASS,
            xOffset: -10,
            yOffset: -10,
          },
        },
      ],
    },
    // Step 8.
    {
      step: {
        stepId: 7,
        modal: MODAL_TYPE.DOCKED,
        description: `${intlPrefix}.step8`,
        image: `${imgBaseUrl}/steps/step8.gif`,
      },
    },
  ],
};
