import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import escapeString from 'escape-string-regexp';
import { LoadFileIcon } from '@assets/icons';
import Text from '@components/text';
import { useFile } from '@utils/hooks';
import styles from './styles.mod.scss';
import { hideProfileSelector } from '../../../features/profileSelectorSlice';

function LoadFromFile({
  intl,
  addTab,
  selectActiveTab,
}) {
  const dispatch = useDispatch();
  const { onChange, onLoadend, onClick } = useFile({
    accept: ['.edu'],
  });

  useEffect(() => {
    onChange((file, reader) => {
      reader.readAsText(file);
    });

    onLoadend((reader) => {
      const project = JSON.parse(atob(reader.result));
      const tabInfo = project.tabInfo;
      project.tabInfo.id = uuidv1();

      // TODO extract this logic outside of that function
      // It changes all blockly IDs in order to not conflict with a duplicated tab
      const ids = (project.tabInfo.withWorkspace) ? project.tabInfo.withWorkspace.match(/id="(.*?)"/g) : project.tabInfo.id;
      for (let j = 0; j < ids.length; j += 1) {
        const re = new RegExp(escapeString(ids[j]), 'g');
        const preId = Math.floor(Math.random() * 100) + 1;
        project.tabInfo.withWorkspace = (project.tabInfo.withWorkspace) ? project.tabInfo.withWorkspace.replace(re, `id="${preId}${ids[j].substring(6, ids[j].length - 1)}"`) : null;
      }

      const projectLoaded = {
        id: null,
        name: tabInfo.name,
        description: tabInfo.description,
        boardType: tabInfo.boardType || 'senior',
        tabInfo: project.tabInfo,
        tags: project.tagsToSave,
        workspace: project.tabInfo.withWorkspace,
      };

      selectActiveTab(0, () => {
        dispatch(hideProfileSelector());
        addTab(projectLoaded);
      });
    });
  }, []);

  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.buttonContainer}>
        <div className={styles.iconWrapper}>
          <LoadFileIcon className={styles.fileIcon} />
        </div>
        <div className={styles.textWrapper}>
          <Text className={styles.text}>
            {intl.formatMessage({ id: 'blocks.profileSelector.project.load.fromFile' })}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default LoadFromFile;
