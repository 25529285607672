import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="takeoff" x="50" y="50" deletable="false" movable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>

    <category id="Control" name="%{BKY_LANG_CATEGORY_CONTROLS}" iconclass="drone-control" expandedclass="control-extended">
      <block type="land" />
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">3</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_for">
        <value name="FROM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="TO">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_doWhile" />
      
    </category>

    {/* <category id="Variables" name="Variables" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="Variables" />
    </category> */}
    <category id="Variables" name="%{BKY_LANG_CATEGORY_VARIABLES}" iconclass="vars" expandedclass="vars-extended">
      <block type="variables_get" />
      <block type="variables_set" />
    </category>
    
    <category id="Matematica" name="%{BKY_LANG_CATEGORY_MATH}" iconclass="math" expandedclass="math-extended">
      <block type="math_number">
        <field name="NUM">20</field>
      </block>
      <block type="math_arithmetic" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="math_distancia_min_max" />


    </category>

    <category id="Logica" name="%{BKY_LANG_CATEGORY_LOGIC}" iconclass="logics" expandedclass="logics-extended">
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>

    <category id="Movimiento" name="%{BKY_LANG_CATEGORY_MOTION}" iconclass="drone-movimiento" expandedclass="movimiento-extended">

      <block type="set_speed">
        <value name="speed">
          <shadow type="math_speed_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_forward">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_backward">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_left">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_right">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_up">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_down">
        <value name="distance">
          <shadow type="math_distancia_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="fly_xyz">
        <value name="xdistance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">20</field>
          </shadow>
        </value>
        <value name="ydistance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">25</field>
          </shadow>
        </value>
        <value name="zdistance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
        <value name="speed">
          <shadow type="math_speed_min_max">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="curve">
        <value name="x1distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">20</field>
          </shadow>
        </value>
        <value name="y1distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">25</field>
          </shadow>
        </value>
        <value name="z1distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
        <value name="x2distance">
          <shadow type="math_distancia_500_500">
            <field name="SIDER">20</field>
          </shadow>
        </value>
        <value name="y2distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">25</field>
          </shadow>
        </value>
        <value name="z2distance">
          <shadow type="math_distancia_500_500">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
        <value name="speed">
          <shadow type="math_speed_10_60">
            <field name="SLIDER">30</field>
          </shadow>
        </value>
      </block>
      <block type="yaw_left">
        <value name="angle">
          <shadow type="slider_angle_360">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="yaw_right">
        <value name="angle">
          <shadow type="slider_angle_360">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>


    </category>


    <category id="Piruetas" name="%{BKY_LANG_CATEGORY_PIROUETTES}" iconclass="drone-piruetas" expandedclass="piruetas-extended">
      <block type="flip_backward" />
      <block type="flip_forward" />
      <block type="flip_left" />
      <block type="flip_right" />
    </category>
    
    
  </React.Fragment>
);
/* eslint-enable */
