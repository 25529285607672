import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    <category id="Funciones" name="Funciones" iconclass="fx" expandedclass="fx-extended" custom="PROCEDURE">
      <label text="Funciones" />
    </category>
    <category id="Control" name="Control" iconclass="control" expandedclass="control-extended">
      <label text="Control" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="controls_ciclo">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="Lógica" name="Lógica" iconclass="logics" expandedclass="logics-extended">
      <label text="Lógica" />
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
      <block type="logic_boolean" />
      <block type="logic_null" />
    </category>

    <category id="Variables" name="Variables" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="Variables" />
    </category>

    <category id="Matemáticas" name="Matemáticas" iconclass="math" expandedclass="math-extended">
      <label text="Matemáticas" />
      <block type="math_counter">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">20</field>
          </shadow>
        </value>
      </block>
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_single">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_array" />
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>

        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_pwm">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_number_minmax" />
    </category>

    <category id="Salidas" name="Salidas" iconclass="outputs" expandedclass="outputs-extended">
      <label text="Salidas" />
      <block type="semaforo" />
      <block type="semaforo_giro" />
      <block type="luz_peaton">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#33cc00</field>
          </shadow>
        </value>
      </block>
      <block type="luz_peaton_off" />
      <block type="display_7_segments">
        <value name="DATOIN">
          <shadow type="math_number_minmax_7segments">
            <field name="SLIDER">20</field>
          </shadow>
        </value>
      </block>
      <block type="display_7_segments_off" />

    </category>
  </React.Fragment>
);
/* eslint-enable */
