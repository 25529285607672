/* eslint-disable func-names */
// import { strict } from "assert";

const $ = window.$;

export default {

  buildBigBlock(html, cols) {
    // <span class="rotate-button button"><span class="icon-rotate-left"></span></span> NO BORRAR
    return `
    <div class="popup-modal-parent matrix-modal-parent matrix-modal-parent-${cols}">
      <div class="popup-modal-under matrix-modal-under">
      </div>
      <div class="matrix-modal matrix-modal-${cols} rotate-0 initial-popup">
        ${html}
      </div>
      <div class="popup-modal-close matrix-modal-close">
        <span class="icon-cross11"></span>
      </div>
      <div class="modal-sidebar">
        <div class="modal-sidebar-main-panel"></div>
        <div class="modal-sidebar-buttons">
          <span class="emotions-picker picker"></span>
          <span class="dice-picker picker"></span>
          <span class="icon-picker picker"></span>
          <span class="ruler-button button"><span class="icon-grid"></span></span>
          <span class="erase-button button"><span class="icon-delete"></span></span>
        </div>
      </div>
    </div>`;
  },

  buildMiniBlock(block, ledsPerRow, startUp = false) {
    const ele = document.querySelector(`[data-id='${block.id}']`);
    const eleText = document.querySelector(`[data-id='${block.id}'] text:nth-child(2)`);
    let ledDivs = '';

    for (let i = 0; i < Object.keys(block.leds).length; i += 1) {
      const rowStart = ((i / ledsPerRow) % 1 === 0) ? '<div class="led-row">' : '';
      const rowEnd = (((i + 1) / ledsPerRow) % 1 === 0) ? '</div>' : '';
      const ledDiv = `<div class='led ${(block.leds[i]) ? 'led-on' : ''}'></div>`;

      ledDivs += rowStart + ledDiv + rowEnd;
    }


    if (document.getElementById(`matrix-${block.id}`)) {
      const foreignObject = document.getElementById(`matrix-${block.id}`);
      foreignObject.innerHTML = `
        <div class="matrix-mini-modal matrix-mini-modal-${ledsPerRow} matrix-mini-modal-${block.id}">
          <div class="mini-modal-leds-container">
            ${ledDivs}
          </div>
        </div>
      `;
    } else {
      const ns = 'http://www.w3.org/2000/svg';
      // const svg = document.querySelector('svg');
      const foreignObject = document.createElementNS(ns, 'foreignObject');
      foreignObject.setAttribute('class', 'matrix');
      foreignObject.setAttribute('id', `matrix-${block.id}`);
      foreignObject.innerHTML = `
        <div class="matrix-mini-modal matrix-mini-modal-${ledsPerRow} matrix-mini-modal-${block.id}">
          <div class="mini-modal-leds-container">
            ${ledDivs}
          </div>
        </div>
      `;
      ele.insertBefore(foreignObject, eleText);
    }
    // block.workspace.fireChangeListener('resize');
    if (!startUp) {
      const event = new window.Blockly.Events.Change(block, 'mutation', 'data-custom', '', window.Blockly.Xml.domToText(block.mutationToDom()));
      window.Blockly.Events.fire(event);
    }
  },

  js(block) {
    const self = this;
    const rows = block.rows;
    const ledsPerRow = block.cols;
    let ledsHtml = '';

    for (let i = 0; i < rows; i += 1) {
      ledsHtml += '<div class="led-row">';
      for (let j = 0; j < ledsPerRow; j += 1) {
        ledsHtml += '<div class="led"></div>';
      }
      ledsHtml += '</div>';
    }
    window.Blockly.WidgetDiv.DIV.innerHTML = this.buildBigBlock(ledsHtml, ledsPerRow);

    $('.matrix-modal div.led').each(function (index) {
      // ROTATE CLOCKWISE
      const multiple = block.rows;
      const rotatedI = multiple * ((index % multiple) + 1) - Math.floor(index / multiple) - 1;

      if (block.leds[rotatedI]) $(this).addClass('led-on'); // eslint-disable-line
    });
    window.Blockly.WidgetDiv.show(block, true);
    setTimeout(() => {
      $('.matrix-modal-parent .modal-sidebar').toggleClass('start');
      $('.matrix-modal-parent .matrix-modal').removeClass('initial-popup');
    }, 600);

    // UNBIND AND BIND
    $('.blocklyWidgetDiv').unbind('click');
    $('.blocklyWidgetDiv').unbind('mousedown mouseover');
    $('.blocklyWidgetDiv').on('mousedown mouseover', '.matrix-modal div.led', function (e) {
      if (e.buttons === 1 || e.buttons === 3) {
        if ($(this).hasClass('led-on')) {
          $(this).removeClass('led-on');
        } else {
          $(this).addClass('led-on');
        }
      }

      if (e.buttons === 1) {
        $('.matrix-modal div.led').each(function (index) {
          // ROTATE CLOCKWISE
          const multiple = block.rows;
          const rotatedI = multiple * ((index % multiple) + 1) - Math.floor(index / multiple) - 1;
          block.leds[rotatedI] = $(this).hasClass('led-on'); // eslint-disable-line
        });
        self.buildMiniBlock(block, ledsPerRow);
      }
      // block.workspace.fireChangeListener('resize');
    });

    let currentDrawer = '';

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .modal-sidebar .modal-sidebar-buttons span.picker', function (e) { // eslint-disable-line

      function fillSidebar() {
        if ($(e.target).hasClass('color-picker')) {
          currentDrawer = 'color-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <span class="color-circle bg-white"></span>
          <span class="color-circle bg-red"></span>
          <span class="color-circle bg-pink"></span>
          <span class="color-circle bg-fuscia"></span>
          <span class="color-circle bg-orange"></span>
          <span class="color-circle bg-copper"></span>
          <span class="color-circle bg-yellow"></span>
          <span class="color-circle bg-gold"></span>
          <span class="color-circle bg-blue"></span>
          <span class="color-circle bg-dark-blue"></span>
          <span class="color-circle bg-light-blue"></span>
          <span class="color-circle bg-green"></span>
          <span class="color-circle bg-dark-green"></span>
          <span class="color-circle bg-teal"></span>
          `;
        } else if ($(e.target).hasClass('emotions-picker')) {
          currentDrawer = 'emotions-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller emotions-scroller">
            <div><span class="emotions-square emotion1"></span></div>
            <div><span class="emotions-square emotion2"></span></div>
            <div><span class="emotions-square emotion3"></span></div>
            <div><span class="emotions-square emotion4"></span></div>
            <div><span class="emotions-square emotion5"></span></div>
            <div><span class="emotions-square emotion6"></span></div>
            <div><span class="emotions-square emotion7"></span></div>
            <div><span class="emotions-square emotion8"></span></div>
            <div><span class="emotions-square emotion9"></span></div>
            <div><span class="emotions-square emotion10"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('icon-picker')) {
          currentDrawer = 'icon-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller icon-scroller">
            <div><span class="icon-square icon1"></span></div>
            <div><span class="icon-square icon2"></span></div>
            <div><span class="icon-square icon3"></span></div>
            <div><span class="icon-square icon4"></span></div>
            <div><span class="icon-square icon5"></span></div>
            <div><span class="icon-square icon6"></span></div>
            <div><span class="icon-square icon7"></span></div>
            <div><span class="icon-square icon8"></span></div>
            <div><span class="icon-square icon9"></span></div>
            <div><span class="icon-square icon10"></span></div>
            <div><span class="icon-square icon11"></span></div>
            <div><span class="icon-square icon12"></span></div>
          </div>
          `;
        } else if ($(e.target).hasClass('dice-picker')) {
          currentDrawer = 'dice-picker';
          $('.modal-sidebar .modal-sidebar-main-panel')[0].innerHTML = `
          <div class="sidebar-scroller dice-scroller">
            <div><span class="dice-square dice-1"></span></div>
            <div><span class="dice-square dice-2"></span></div>
            <div><span class="dice-square dice-3"></span></div>
            <div><span class="dice-square dice-4"></span></div>
            <div><span class="dice-square dice-5"></span></div>
            <div><span class="dice-square dice-6"></span></div>
          </div>
          `;
        }

        $('.sidebar-scroller').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        });
      }

      // is the drawer open?
      if ($('.blocklyWidgetDiv .modal-sidebar').hasClass('open')) {
        $('.blocklyWidgetDiv .modal-sidebar').removeClass('open');
        // was it not I who opened it?
        if ($(e.target).attr('class').indexOf(currentDrawer) === -1) {
          $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
          $(e.target).addClass('selected');
          setTimeout(() => {
            fillSidebar();
            $('.matrix-modal-parent .modal-sidebar').addClass('open');
          }, 300);
        } else { $(e.target).removeClass('selected'); }
      } else {
        $('.blocklyWidgetDiv .modal-sidebar .modal-sidebar-buttons span').removeClass('selected');
        $(e.target).addClass('selected');
        fillSidebar();
        $('.matrix-modal-parent .modal-sidebar').addClass('open');
      }
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .modal-sidebar .modal-sidebar-main-panel span.color-circle', function (e) { // eslint-disable-line
      $('.matrix-modal-parent .modal-sidebar .modal-sidebar-buttons .color-picker').css('background-color', $(e.target).css('background-color'));
      $('head').append('<style type="text/css"></style>');
      const newStyleElement = $('head').children(':last');
      newStyleElement.html(`.matrix-mini-modal-${block.id} .led-on, .color-picker {background-color: ${$(e.target).css('background-color')} !important;}`);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .matrix-modal-close, .matrix-modal-parent .popup-modal-under', () => {
      $('.matrix-modal').addClass('popup-hide');
      // $('.matrix-modal-parent').addClass('popup-hide');
      $('.modal-sidebar').addClass('popup-hide');
      $('.matrix-modal-close').addClass('popup-hide');
      setTimeout(() => {
        $('.blocklyWidgetDiv').unbind('click');
        window.Blockly.WidgetDiv.hide();
      }, 200);
    });

    // erase functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .erase-button', () => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        // eslint-disable-next-line no-param-reassign
        block.leds[i] = false;
      }
      self.buildMiniBlock(block, ledsPerRow);
    });

    // grid numbers functionality

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .ruler-button', (ele) => {
      if ($(ele.target).hasClass('ruler-on')) {
        $(ele.target).removeClass('ruler-on');
        $('.matrix-modal .led-row').each((index, e) => {
          $(e).attr('data-content', '');
        });
        $('.matrix-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', '');
        });
      } else {
        $(ele.target).addClass('ruler-on');
        $('.matrix-modal .led-row').each((index, e) => {
          $(e).attr('data-content', index);
        });
        $('.matrix-modal .led-row:first-child div.led').each((index, e) => {
          $(e).attr('data-content', index);
        });
      }
    });

    // rotate functionality

    let currentRotationValue = 0;

    function turnValuesIntoLeds(unrotatedLedArray) {
      const ledArray = [];

      // rotate ledArray
      unrotatedLedArray.forEach((i) => {
        const multiple = 8;

        // ROTATE COUNTERCLOCKWISE
        // const totalLeds = 64;
        // const rotatedI = totalLeds - ((i % multiple) + 1) * multiple + 1 * Math.floor(i / multiple);

        // ROTATE CLOCKWISE
        const rotatedI = multiple * ((i % multiple) + 1) - Math.floor(i / multiple) - 1;

        ledArray.push(rotatedI);
      });

      for (let i = 0; i < (rows * ledsPerRow); i += 1) {
        if (unrotatedLedArray.indexOf(i) !== -1) {
          const row = Math.ceil((i + 1) / ledsPerRow);
          const led = Math.ceil((i) % ledsPerRow) + 1;
          $(`.matrix-modal .led-row:nth-child(${row}) div.led:nth-child(${led})`).addClass('led-on');
        }

        if (ledArray.indexOf(i) !== -1) {
          // eslint-disable-next-line no-param-reassign
          block.leds[i] = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          block.leds[i] = false;
        }
      }
      self.buildMiniBlock(block, ledsPerRow);
    }

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent .rotate-button', () => {
      const matrixModal = $('.matrix-modal-parent .matrix-modal');
      currentRotationValue = parseInt(matrixModal.attr('class').replace(`matrix-modal matrix-modal-${ledsPerRow} rotate-`, ''), 10);

      $('.blocklyWidgetDiv .modal-sidebar').addClass('transitioning');

      const newRotationValue = (currentRotationValue < 270) ? currentRotationValue + 90 : 0;

      setTimeout(() => {
        matrixModal.removeClass(`rotate-${currentRotationValue}`);
        matrixModal.addClass(`rotate-${newRotationValue}`);
        setTimeout(() => {
          $('.blocklyWidgetDiv .modal-sidebar').removeClass('transitioning');
        }, (currentRotationValue === 270) ? 500 : 200);
      }, 300);
    });

    // dice functionality
    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-8 .dice-square', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      const n = $(e.target).attr('class').replace('dice-square dice-', '');

      let ledArray = [];

      switch (n) {
        case '1':
          ledArray = [27, 28, 35, 36];
          break;
        case '2':
          ledArray = [6, 7, 14, 15, 48, 49, 56, 57];
          break;
        case '3':
          ledArray = [6, 7, 14, 15, 27, 28, 35, 36, 48, 49, 56, 57];
          break;
        case '4':
          ledArray = [0, 1, 6, 7, 8, 9, 14, 15, 48, 49, 54, 55, 56, 57, 62, 63];
          break;
        case '5':
          ledArray = [0, 1, 6, 7, 8, 9, 14, 15, 27, 28, 35, 36, 48, 49, 54, 55, 56, 57, 62, 63];
          break;
        case '6':
          ledArray = [0, 1, 6, 7, 8, 9, 14, 15, 24, 25, 30, 31, 32, 33, 38, 39, 48, 49, 54, 55, 56, 57, 62, 63];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-8 .emotions-square', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      const n = $(e.target).attr('class').replace('emotions-square emotion', '');

      let ledArray = [];

      switch (n) {
        case '1':
          ledArray = [18, 21, 41, 46, 50, 51, 52, 53];
          break;
        case '2':
          ledArray = [18, 21, 41, 42, 43, 44, 45, 46, 49, 50, 51, 52, 53, 54];
          break;
        case '3':
          ledArray = [18, 21, 42, 43, 44, 45, 49, 54];
          break;
        case '4':
          ledArray = [18, 21, 33, 38, 42, 43, 44, 45, 51, 52, 59, 60];
          break;
        case '5':
          ledArray = [9, 14, 18, 21, 42, 43, 44, 45, 50, 51, 52, 53];
          break;
        case '6':
          ledArray = [9, 10, 13, 14, 17, 18, 21, 22, 35, 36, 42, 45, 50, 53, 59, 60];
          break;
        case '7':
          ledArray = [17, 18, 21, 22, 42, 43, 44, 45, 50, 51, 52, 53];
          break;
        case '8':
          ledArray = [9, 10, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 29, 30, 41, 46, 50, 51, 52, 53];
          break;
        case '9':
          ledArray = [9, 14, 16, 18, 21, 23, 41, 46, 50, 51, 52, 53];
          break;
        case '10':
          ledArray = [10, 18, 21, 22, 41, 46, 50, 51, 52, 53];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray);
    });

    $('.blocklyWidgetDiv').on('click', '.matrix-modal-parent-8 .icon-square', (e) => {
      $('.matrix-modal .led-row div.led').removeClass('led-on');
      const n = $(e.target).attr('class').replace('icon-square icon', '');

      let ledArray = [];

      switch (n) {
        case '1':
          ledArray = [0, 7, 9, 14, 18, 21, 27, 28, 35, 36, 42, 45, 49, 54, 56, 63];
          break;
        case '2':
          ledArray = [15, 22, 23, 29, 30, 36, 37, 40, 41, 43, 44, 49, 50, 51, 58];
          break;
        case '3':
          ledArray = [18, 21, 25, 26, 27, 28, 29, 30, 33, 34, 35, 36, 37, 38, 42, 43, 44, 45, 51, 52];
          break;
        case '4':
          ledArray = [9, 10, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 59, 60];
          break;
        case '5':
          ledArray = [3, 4, 10, 11, 12, 13, 17, 18, 19, 20, 21, 22, 24, 25, 26, 27, 28, 29, 30, 31, 33, 34, 35, 36, 37, 38, 41, 42, 45, 46, 49, 50, 53, 54, 57, 58, 61, 62];
          break;
        case '6':
          ledArray = [2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 16, 17, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 51, 52, 55, 56, 59, 60, 63];
          break;
        case '7':
          ledArray = [4, 5, 12, 13, 14, 20, 21, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 49, 50, 51, 52, 53, 54, 58, 59, 60, 61];
          break;
        case '8':
          ledArray = [3, 4, 11, 12, 18, 19, 20, 21, 25, 26, 27, 28, 29, 30, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 59, 60];
          break;
        case '9':
          ledArray = [0, 1, 2, 8, 9, 10, 18, 26, 30, 34, 35, 36, 37, 42, 45, 50, 53, 58, 61];
          break;
        case '10':
          ledArray = [0, 1, 2, 8, 9, 10, 15, 18, 19, 22, 27, 28, 29, 35, 36, 42, 43, 45, 48, 49, 50, 54, 56, 57, 58, 63];
          break;
        case '11':
          ledArray = [2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 16, 17, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 42, 43, 44, 45, 50, 51, 52, 53, 58, 59, 60, 61];
          break;
        case '12':
          ledArray = [0, 8, 9, 16, 18, 24, 27, 32, 36, 40, 45, 48, 54, 56, 57, 58, 59, 60, 61, 62, 63];
          break;
        default:
          ledArray = [];
          break;
      }

      turnValuesIntoLeds(ledArray);
    });
  },
};
