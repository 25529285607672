import React, { Component } from 'react';
import { LineFollowerElement } from '../components/lineFollower/lineFollower';
import { rotatePort, cpuMicros } from '../utils';

export class LineFollower extends Component {
  constructor(hardware, pin, id = 'LineFollower', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'T', x: -3, y: 52, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 115,
      height: 102,
    };
    this.rotation = rotation;

    this.pinLeft = '';
    this.pinRight = '';
    this.pinCenter = '';
    this.pinLeftState = 0;
    this.pinRightState = 0;
    this.pinCenterState = 0;
    this.position = 0;
    this.ref = React.createRef();

    this.mapPins();

    this.runSetup = this.runSetup.bind(this);
    this.mapPins = this.mapPins.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
    this.stateHandler = this.stateHandler.bind(this);
    this.render = this.render.bind(this);
  }

  runSetup = () => {
    this.position = this.ref.current?.state?.position || 0;
    this.stateHandler(this.position);
  }

  mapPins = () => {
    const pinInfo = this.hardware.getPinInfo(this.pin);
    const pins = this.pin.split(',');
    pins.map((pin, index) => {
      if (pinInfo.signals?.[index][0]?.type === 'X') {
        this.pinCenter = pin;
      } else if (pinInfo.signals?.[index][0]?.type === 'Y') {
        this.pinRight = pin;
      } else if (pinInfo.signals?.[index][0]?.type === 'button') {
        this.pinLeft = pin;
      }
      return true;
    });
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    Left: this.pinLeftState,
    Right: this.pinRightState,
    Center: this.pinCenterState,
  });

  update = (newPinState) => {
    // console.log('================update', newPinState.toString(2));
    this.pinState = newPinState;
    this.position = this.ref.current?.state?.position || 0;
    if (this.position < 20) {
      this.pinLeftState = 25;
      this.pinRightState = 35;
      this.pinCenterState = 35;
    } else if (this.position >= 20 && this.position < 60) {
      this.pinLeftState = 35;
      this.pinRightState = 35;
      this.pinCenterState = 35;
    } else {
      this.pinLeftState = 35;
      this.pinRightState = 35;
      this.pinCenterState = 25;
    }
  }

  stateHandler = (pinState) => {
    this.position = pinState;
    if (this.hardware && this.pinLeft && this.pinCenter && this.pinRight) {
      if (pinState < 20) {
        // Left
        this.hardware.writeAnalogPin(this.pinLeft, 35);
        this.hardware.writeAnalogPin(this.pinCenter, 35);
        this.hardware.writeAnalogPin(this.pinRight, 25);
      } else if (pinState >= 20 && pinState < 60) {
        // Center
        this.hardware.writeAnalogPin(this.pinLeft, 35);
        this.hardware.writeAnalogPin(this.pinCenter, 35);
        this.hardware.writeAnalogPin(this.pinRight, 35);
      } else {
        // Right
        this.hardware.writeAnalogPin(this.pinLeft, 25);
        this.hardware.writeAnalogPin(this.pinCenter, 35);
        this.hardware.writeAnalogPin(this.pinRight, 35);
      }
    }
  }

  reset = () => {
    this.position = 0;
    this.pinLeftState = 0;
    this.pinRightState = 0;
    this.pinCenterState = 0;
  }

  render = () => (
    <LineFollowerElement
      id={this.id}
      key={`lineFollower-${this.pin}`}
      ref={this.ref}
      rotation={this.rotation}
      onClickHandler={this.stateHandler}
    />
  );
}
