import React from 'react';

export const initXml = (
  <xml xmlns="https://developers.google.com/blockly/xml">
    <block type="microbit_initial_block" deletable="false" />
  </xml>
);
/* eslint-disable */
export const categoryXml = (
  <React.Fragment>
    {/* <category id="Funciones" name="Funciones" iconclass="fx" expandedclass="fx-extended" custom="PROCEDURE_JAVASCRIPT">
      <label text="Funciones" />
    </category> */}


    <category name="%{BKY_LANG_CATEGORY_CONTROLS}" id="basico" iconclass="control" expandedclass="control-extended">
      <block type="microbit_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
          <block type="logic_compare">
            <value name="B">
              <block type="math_number">
                <field name="NUM">0</field>
              </block>
            </value>
          </block>
        </value>
      </block>
      <block type="microbit_repeat">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_button_pressed_2" />
      <block type="microbit_gesture_2" />
      <block type="microbit_chronometer" />
      <block type="microbit_chronometer_state" />
    </category>


    <category id="Lógica" name="%{BKY_LANG_CATEGORY_LOGIC}" iconclass="logics" expandedclass="logics-extended">
      <block type="logic_compare">
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="logic_operation" />
      <block type="logic_negate" />
    </category>


    <category id="Matemáticas" name="%{BKY_LANG_CATEGORY_MATH}" iconclass="math" expandedclass="math-extended">
      <block type="math_number" />
      <block type="math_arithmetic">
        <value name="A">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="B">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
      </block>
      <block type="advanced_map">
        <value name="FROM_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="FROM_MAX">
          <shadow type="math_number">
            <field name="NUM">1023</field>
          </shadow>
        </value>
        <value name="TO_MIN">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
        <value name="TO_MAX">
          <shadow type="math_number">
            <field name="NUM">255</field>
          </shadow>
        </value>
      </block>
      <block type="math_random">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DMAX">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      {/* <block type="math_modulo">
        <value name="DIVIDEND">
          <shadow type="math_number">
            <field name="NUM">1</field>
          </shadow>
        </value>
        <value name="DIVISOR">
          <shadow type="math_number">
            <field name="NUM">2</field>
          </shadow>
        </value>
      </block> */}
      {/* <block type="math_array" /> */}
    </category>


    {/* <category id="Variables" name="Variables ori" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_DYNAMIC">
      <label text="Variables" />
    </category> */}
    <category id="Variables" name="%{BKY_LANG_CATEGORY_VARIABLES}" iconclass="vars" expandedclass="vars-extended" custom="VARIABLE_JAVASCRIPT_DYNAMIC">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
    </category>


    <category name="%{BKY_LANG_CATEGORY_TEXT}" id="texto" iconclass="txt" expandedclass="txt-extended">
      <block type="text" />
      <block type="text_unir" />
      <block type="microbit_compare_strings">
        <value name="B">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
    </category>

    <category id="Comunicación" name="%{BKY_LANG_CATEGORY_COMMUNICATION}" iconclass="comm" expandedclass="comm-extended">
      <label text="%{BKY_LANG_CATEGORY_COMMUNICATION}" />
      <block type="serial_println">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="serial_print">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_bluetooth_read" />
      <block type="microbit_bluetooth_send">
        <value name="CONTENT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_bluetooth_if" />
      <block type="microbit_bluetooth_control" />
    </category>

    <category name="%{BKY_LANG_CATEGORY_ENTRADAS}" id="entrada" iconclass="mixedInputs" expandedclass="mixedInputs-extended" expanded="true">
      <category id="Digitales" name="%{BKY_LANG_SUBCATEGORY_DIGITALES}" iconclass="inputsDigi" expandedclass="inputsDigi-extended">
        
        <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
        <block type="microbit_button_logic" />
        <block type="microbit_logo" />

        <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
        <block type="ENTRADAS_button" />
        <block type="ENTRADAS_button_touch" />
        <block type="microbit_temperature_humidity_read" />
        <block type="microbit_ultrasonic_2" />
        <block type="ENTRADAS_obstaculo" />
        <block type="microbit_color_i2c" />
        <block type="microbit_color_i2c_get">
          <value name="COLOR">
            <shadow type="colour_bhoot">
              <field name="VAL">#ff0000</field>
            </shadow>
          </value>
        </block>
        <block type="microbit_line_follower_3" />
      </category>

      <category id="Analogicas" name="%{BKY_LANG_SUBCATEGORY_ANALOGICAS}" iconclass="inputsAnal" expandedclass="inputsAnal-extended">
        <label text="%{BKY_LANG_CATEGORY_MICROBIT}" />
        <block type="microbit_internal_light_level" />
        <block type="microbit_internal_temperature" />
        <block type="microbit_internal_acceleration" />
        <block type="microbit_hall_effect_3" />
        <block type="microbit_internal_sound" />
        <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
        <block type="ENTRADAS_photoresistor" />
        <block type="microbit_soil_read_2" />
        <block type="ENTRADAS_potentiometer" />
        <block type="microbit_joystick" />
      </category>
    </category>


    <category name="%{BKY_LANG_CATEGORY_SALIDAS}" id="salida" iconclass="outputs" expandedclass="outputs-extended">
      {/* <label text="%{BKY_LANG_CATEGORY_MICROBIT}" /> */}
      <label text="%{BKY_LANG_CATEGORY_EXTERNALS}" />
      <block type="microbit_led_2" />
      <block type="microbit_led_brillo_2">
        <value name="DATOIN">
          <shadow type="math_number_minmax_analog">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_rgb">
        <value name="COLOUR1">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR2">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR3">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR4">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR5">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
        <value name="COLOUR6">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_salidas_led_rgb">
        <value name="DATOINR">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOING">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
        <value name="DATOINB">
          <shadow type="math_number_minmax">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>

      <block type="BHOOT_piezo_buzzer" />
      <block type="BHOOT_piezo_buzzer_variable">
        <value name="DURATION">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_play_melody_2" />
      <block type="BHOOT_piezo_buzzerno" />
    </category>


    <category name="%{BKY_LANG_CATEGORY_LCD}" id="pantallas" iconclass="screens" expandedclass="screens-extended">
      <block type="microbit_lcd_i2c_2">
        <value name="TEXT">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_clear" />
      {/* <block type="microbit_lcd_i2c_clear_2" /> */}
      {/* <block type="microbit_lcd_i2c_setBacklight" /> */}
      <block type="microbit_matrix8x8" />
      <block type="microbit_matrix8x8_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_matrix8x8_draw">
        <value name="POSX1">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
        <value name="POSY1">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
        <value name="POSX2">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">2</field>
          </shadow>
        </value>
        <value name="POSY2">
          <shadow type="math_number_minmax_matrix8x8">
            <field name="POSITION">0</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_matrix8x8_rotate" />
      <block type="microbit_matrix8x8_invert" />
      <block type="microbit_matrix8x8_clear" />
    </category>


    <category id="Motores" name="%{BKY_LANG_CATEGORY_MOTORS}" iconclass="motors" expandedclass="motors-extended">
      <block type="microbit_servo_180_2">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_servo_move_millis">
        <value name="angulo">
          <shadow type="slider_angle">
            <field name="angulo">90</field>
          </shadow>
        </value>
        <value name="millis">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_motor" />
      <block type="microbit_motor_doble" />
      <block type="microbit_motor_doble_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed_1023">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
      <block type="microbit_motor_single" />
      <block type="microbit_motor_single_speed">
        <value name="PWM">
          <shadow type="math_number_minmax_speed_1023">
            <field name="SLIDER">1023</field>
          </shadow>
        </value>
      </block>
    </category>

  </React.Fragment>
);
/* eslint-enable */
