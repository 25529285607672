import React, { Component } from 'react';
import './matrix8x8.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class Matrix8x8Element extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.numCols = 8;
    this.numRows = 8;

    this.lcd = null;
    this.color = '#ff3636';
    this.characters = null;

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.lcd = newProps.lcd;
    this.color = newProps.color;
    if (this.lcd) {
      this.characters = this.lcd.characters;
    }
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  getMatrixLeds = () => {
    const result = [];
    const yOffset = 11.34;
    const xOffset = 11.34;
    const itemYOffset = 12.89;
    const itemXOffset = 32.27;
    for (let row = 0; row < this.numRows; row += 1) {
      for (let col = 0; col < this.numCols; col += 1) {
        const itemX = itemXOffset + (xOffset * col)
        const itemY = itemYOffset + (yOffset * row)
        let ledOn = false;
        if (this.characters) {
          const rowChar = this.characters[row].toString(2).padStart(this.numCols, '0');
          ledOn = Boolean(parseInt(rowChar[col], 10));
        }
        result.push((
          <g id={`SPLINE-${row}-${col}`}>
            <path fill={`${ledOn ? this.color : '#e4e4e4'}`} class="cls-10" d={`m${itemY},${itemX}c.02.28.03.56.08.83s.14.54.24.8.24.5.39.74.33.45.53.64.41.38.64.53.48.29.73.39.53.19.8.24.55.08.83.08.56-.03.83-.08.54-.14.8-.24.5-.24.74-.39.45-.33.64-.53.38-.41.53-.64.28-.48.39-.73.19-.52.24-.8.07-.55.08-.83c-.02-.28-.03-.56-.08-.83s-.14-.54-.24-.8-.24-.5-.39-.74-.33-.45-.53-.64-.41-.38-.64-.53-.48-.29-.73-.39-.53-.19-.8-.24-.55-.08-.83-.08-.56.03-.83.08-.54.14-.8.24-.5.24-.74.39-.45.33-.64.53-.38.41-.53.64-.28.48-.39.73-.19.52-.24.8-.07.55-.08.83Z`} />
            <circle cx={itemY + 0.5} cy={itemX + 4} r="4" fill={this.color} filter="url(#ledFilter)" style={(!ledOn) ? {display: 'none'} : {}} />
          </g>
        ));
      }
    }
    return result;
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    return (
      <div className="matrix8x8" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 113.64 170.37"
          width="18mm"
          height="27mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >

          <filter id="ledFilter" x="-0.8" y="-0.8" height="2.5" width="2.5">
            <feGaussianBlur stdDeviation="4" />
          </filter>

          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="LINE">
                <line class="cls-2" x1="113.52" y1="165.96" x2="113.52" y2="4.38" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <path class="cls-2" d="m113.52,4.38c0-2.35-1.9-4.25-4.25-4.25,0,0,0,0,0,0H4.39C2.04.13.13,2.03.13,4.38v161.58c0,2.35,1.9,4.25,4.24,4.25,0,0,0,0,0,0h4.05c.78-.02,1.41-.65,1.42-1.43v-2.81c0-2.5,2.03-4.53,4.53-4.54s4.54,2.03,4.54,4.54v2.78c0,.8.65,1.45,1.45,1.45h73.03c.78,0,1.42-.64,1.42-1.43v-2.81c0-2.5,2.03-4.53,4.53-4.54s4.54,2.03,4.54,4.54v2.78c0,.8.65,1.45,1.45,1.45h3.93c2.34,0,4.24-1.9,4.25-4.24" />
              </g>
              <g>
                <g id="LINE-3" data-name="LINE">
                  <path class="cls-10" d="m34.53,135.42h-.57c0,.07-.02.15-.04.22s-.07.13-.12.18-.12.1-.18.12-.14.03-.22.04c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22h-.57v1.13s0,2.27,0,2.27v1.13h.57c0-.31.25-.57.57-.57s.57.25.57.57h.57v-1.13s0-2.27,0-2.27v-1.13Z" />
                </g>
                <rect class="cls-9" x="32.28" y="136.35" width="2.25" height="2.68" />
              </g>
              <rect class="cls-1" x="11.47" y="26.6" width="90.71" height="90.71" />
              {this.getMatrixLeds()}
              <g id="LINE-4" data-name="LINE">
                <rect class="cls-4" x="39.54" y="121.68" width="34.58" height="42.52" />
              </g>
              <g id="CIRCLE">
                <path class="cls-3" d="m8.64,141.85c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-2" data-name="CIRCLE">
                <path class="cls-3" d="m93.68,141.85c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <polygon class="cls-7" points="24.2 155.22 24.2 170.35 17.69 170.35 17.69 165.4 17.69 165.08 17.55 164.46 17.29 163.87 16.91 163.34 16.66 163.12 16.43 162.91 15.9 162.58 15.3 162.35 14.67 162.24 14.34 162.24 14.29 162.24 13.95 162.24 13.3 162.36 12.68 162.6 12.12 162.94 11.89 163.16 11.65 163.37 11.29 163.88 11.03 164.44 10.91 165.04 10.91 165.34 10.91 170.35 4.46 170.35 4.46 155.22 8.72 151.3 19.94 151.3 24.2 155.22" />
              <polygon class="cls-7" points="109.22 155.22 109.22 170.37 102.71 170.37 102.71 165.41 102.71 165.1 102.58 164.47 102.31 163.89 101.93 163.36 101.69 163.13 101.46 162.92 100.92 162.59 100.32 162.37 99.69 162.25 99.37 162.25 99.31 162.25 98.98 162.25 98.32 162.37 97.7 162.61 97.15 162.95 96.91 163.17 96.68 163.39 96.31 163.9 96.06 164.46 95.93 165.05 95.93 165.36 95.93 170.37 89.48 170.37 89.48 155.22 93.74 151.3 104.97 151.3 109.22 155.22" />
              <g>
                <line class="cls-5" x1="81.22" y1="142.3" x2="84.75" y2="142.3" />
                <line class="cls-5" x1="81.22" y1="142.37" x2="84.75" y2="142.37" />
                <line class="cls-5" x1="81.22" y1="142.44" x2="84.75" y2="142.44" />
                <line class="cls-5" x1="81.22" y1="142.51" x2="84.75" y2="142.51" />
                <line class="cls-5" x1="81.22" y1="142.59" x2="84.75" y2="142.59" />
                <line class="cls-5" x1="81.22" y1="142.66" x2="84.75" y2="142.66" />
                <line class="cls-5" x1="81.22" y1="142.73" x2="84.75" y2="142.73" />
                <line class="cls-5" x1="81.22" y1="142.8" x2="84.75" y2="142.8" />
                <line class="cls-5" x1="81.22" y1="142.87" x2="84.75" y2="142.87" />
                <line class="cls-5" x1="81.22" y1="142.94" x2="84.75" y2="142.94" />
                <line class="cls-5" x1="81.22" y1="143.01" x2="84.75" y2="143.01" />
                <line class="cls-5" x1="81.22" y1="143.08" x2="84.75" y2="143.08" />
                <line class="cls-5" x1="79.45" y1="140.67" x2="80.37" y2="140.67" />
                <line class="cls-5" x1="79.45" y1="140.74" x2="80.37" y2="140.74" />
                <line class="cls-5" x1="79.45" y1="140.81" x2="80.37" y2="140.81" />
                <line class="cls-5" x1="79.45" y1="140.88" x2="80.37" y2="140.88" />
                <line class="cls-5" x1="79.45" y1="140.95" x2="80.37" y2="140.95" />
                <line class="cls-5" x1="79.45" y1="141.02" x2="80.37" y2="141.02" />
                <line class="cls-5" x1="79.45" y1="141.09" x2="80.37" y2="141.09" />
                <line class="cls-5" x1="79.45" y1="141.16" x2="80.37" y2="141.16" />
                <line class="cls-5" x1="79.45" y1="141.23" x2="80.37" y2="141.23" />
                <line class="cls-5" x1="79.45" y1="141.31" x2="81.22" y2="141.31" />
                <line class="cls-5" x1="80.3" y1="141.38" x2="81.22" y2="141.38" />
                <line class="cls-5" x1="80.3" y1="141.52" x2="81.22" y2="141.52" />
                <line class="cls-5" x1="80.3" y1="141.59" x2="81.22" y2="141.59" />
                <line class="cls-5" x1="80.3" y1="141.66" x2="81.22" y2="141.66" />
                <line class="cls-5" x1="80.3" y1="141.73" x2="81.22" y2="141.73" />
                <line class="cls-5" x1="80.3" y1="141.8" x2="81.22" y2="141.8" />
                <line class="cls-5" x1="80.3" y1="141.87" x2="81.22" y2="141.87" />
                <line class="cls-5" x1="80.3" y1="141.95" x2="81.22" y2="141.95" />
                <line class="cls-5" x1="80.3" y1="142.02" x2="81.22" y2="142.02" />
                <line class="cls-5" x1="80.3" y1="142.09" x2="81.22" y2="142.09" />
                <line class="cls-5" x1="80.3" y1="142.16" x2="81.22" y2="142.16" />
                <line class="cls-5" x1="80.3" y1="141.45" x2="81.22" y2="141.45" />
                <line class="cls-5" x1="79.45" y1="140.52" x2="80.37" y2="140.52" />
                <line class="cls-5" x1="82.06" y1="134.98" x2="83.9" y2="134.98" />
                <line class="cls-5" x1="82.06" y1="135.05" x2="83.9" y2="135.05" />
                <line class="cls-5" x1="81.22" y1="135.12" x2="84.75" y2="135.12" />
                <line class="cls-5" x1="81.22" y1="135.19" x2="84.75" y2="135.19" />
                <line class="cls-5" x1="81.22" y1="135.26" x2="84.75" y2="135.26" />
                <line class="cls-5" x1="81.22" y1="135.33" x2="84.75" y2="135.33" />
                <line class="cls-5" x1="81.22" y1="135.4" x2="84.75" y2="135.4" />
                <line class="cls-5" x1="81.22" y1="135.47" x2="84.75" y2="135.47" />
                <line class="cls-5" x1="81.22" y1="135.55" x2="84.75" y2="135.55" />
                <line class="cls-5" x1="81.22" y1="135.62" x2="84.75" y2="135.62" />
                <line class="cls-5" x1="81.22" y1="135.69" x2="84.75" y2="135.69" />
                <line class="cls-5" x1="81.22" y1="135.83" x2="84.75" y2="135.83" />
                <line class="cls-5" x1="81.22" y1="135.9" x2="84.75" y2="135.9" />
                <line class="cls-5" x1="81.22" y1="135.76" x2="84.75" y2="135.76" />
                <line class="cls-5" x1="79.45" y1="138.75" x2="80.37" y2="138.75" />
                <line class="cls-5" x1="79.45" y1="138.82" x2="80.37" y2="138.82" />
                <line class="cls-5" x1="79.45" y1="138.89" x2="80.37" y2="138.89" />
                <line class="cls-5" x1="79.45" y1="138.96" x2="80.37" y2="138.96" />
                <line class="cls-5" x1="79.45" y1="139.03" x2="80.37" y2="139.03" />
                <line class="cls-5" x1="79.45" y1="139.1" x2="80.37" y2="139.1" />
                <line class="cls-5" x1="79.45" y1="139.17" x2="80.37" y2="139.17" />
                <line class="cls-5" x1="79.45" y1="139.24" x2="80.37" y2="139.24" />
                <line class="cls-5" x1="79.45" y1="139.31" x2="80.37" y2="139.31" />
                <line class="cls-5" x1="79.45" y1="139.39" x2="80.37" y2="139.39" />
                <line class="cls-5" x1="79.45" y1="139.46" x2="80.37" y2="139.46" />
                <line class="cls-5" x1="79.45" y1="139.53" x2="80.37" y2="139.53" />
                <line class="cls-5" x1="79.45" y1="139.6" x2="80.37" y2="139.6" />
                <line class="cls-5" x1="79.45" y1="139.67" x2="80.37" y2="139.67" />
                <line class="cls-5" x1="79.45" y1="139.74" x2="80.37" y2="139.74" />
                <line class="cls-5" x1="79.45" y1="139.81" x2="80.37" y2="139.81" />
                <line class="cls-5" x1="79.45" y1="139.88" x2="80.37" y2="139.88" />
                <line class="cls-5" x1="79.45" y1="139.95" x2="80.37" y2="139.95" />
                <line class="cls-5" x1="79.45" y1="140.03" x2="80.37" y2="140.03" />
                <line class="cls-5" x1="79.45" y1="140.1" x2="80.37" y2="140.1" />
                <line class="cls-5" x1="79.45" y1="140.17" x2="80.37" y2="140.17" />
                <line class="cls-5" x1="79.45" y1="140.24" x2="80.37" y2="140.24" />
                <line class="cls-5" x1="79.45" y1="140.31" x2="80.37" y2="140.31" />
                <line class="cls-5" x1="79.45" y1="140.38" x2="80.37" y2="140.38" />
                <line class="cls-5" x1="79.45" y1="140.45" x2="80.37" y2="140.45" />
                <line class="cls-5" x1="82.06" y1="134.83" x2="83.9" y2="134.83" />
                <line class="cls-5" x1="82.06" y1="133.34" x2="83.9" y2="133.34" />
                <line class="cls-5" x1="82.06" y1="133.41" x2="83.9" y2="133.41" />
                <line class="cls-5" x1="82.06" y1="133.48" x2="83.9" y2="133.48" />
                <line class="cls-5" x1="82.06" y1="133.56" x2="83.9" y2="133.56" />
                <line class="cls-5" x1="82.06" y1="133.63" x2="83.9" y2="133.63" />
                <line class="cls-5" x1="82.06" y1="133.7" x2="83.9" y2="133.7" />
                <line class="cls-5" x1="82.06" y1="133.77" x2="83.9" y2="133.77" />
                <line class="cls-5" x1="82.06" y1="133.84" x2="83.9" y2="133.84" />
                <line class="cls-5" x1="82.06" y1="133.91" x2="83.9" y2="133.91" />
                <line class="cls-5" x1="82.06" y1="133.98" x2="83.9" y2="133.98" />
                <line class="cls-5" x1="82.06" y1="134.05" x2="83.9" y2="134.05" />
                <line class="cls-5" x1="82.06" y1="134.12" x2="83.9" y2="134.12" />
                <line class="cls-5" x1="82.06" y1="134.2" x2="83.9" y2="134.2" />
                <line class="cls-5" x1="82.06" y1="134.27" x2="83.9" y2="134.27" />
                <line class="cls-5" x1="82.06" y1="134.34" x2="83.9" y2="134.34" />
                <line class="cls-5" x1="82.06" y1="134.41" x2="83.9" y2="134.41" />
                <line class="cls-5" x1="82.06" y1="134.48" x2="83.9" y2="134.48" />
                <line class="cls-5" x1="82.06" y1="134.55" x2="83.9" y2="134.55" />
                <line class="cls-5" x1="82.06" y1="134.62" x2="83.9" y2="134.62" />
                <line class="cls-5" x1="82.06" y1="134.69" x2="83.9" y2="134.69" />
                <line class="cls-5" x1="82.06" y1="134.76" x2="83.9" y2="134.76" />
                <line class="cls-5" x1="85.6" y1="140.67" x2="86.51" y2="140.67" />
                <line class="cls-5" x1="85.6" y1="140.74" x2="86.51" y2="140.74" />
                <line class="cls-5" x1="85.6" y1="140.81" x2="86.51" y2="140.81" />
                <line class="cls-5" x1="85.6" y1="140.88" x2="86.51" y2="140.88" />
                <line class="cls-5" x1="85.6" y1="140.95" x2="86.51" y2="140.95" />
                <line class="cls-5" x1="85.6" y1="141.02" x2="86.51" y2="141.02" />
                <line class="cls-5" x1="85.6" y1="141.09" x2="86.51" y2="141.09" />
                <line class="cls-5" x1="85.6" y1="141.16" x2="86.51" y2="141.16" />
                <line class="cls-5" x1="85.6" y1="141.23" x2="86.51" y2="141.23" />
                <line class="cls-5" x1="85.6" y1="140.52" x2="86.51" y2="140.52" />
                <line class="cls-5" x1="85.6" y1="138.75" x2="86.51" y2="138.75" />
                <line class="cls-5" x1="85.6" y1="138.82" x2="86.51" y2="138.82" />
                <line class="cls-5" x1="85.6" y1="138.89" x2="86.51" y2="138.89" />
                <line class="cls-5" x1="85.6" y1="138.96" x2="86.51" y2="138.96" />
                <line class="cls-5" x1="85.6" y1="139.03" x2="86.51" y2="139.03" />
                <line class="cls-5" x1="85.6" y1="139.1" x2="86.51" y2="139.1" />
                <line class="cls-5" x1="85.6" y1="139.17" x2="86.51" y2="139.17" />
                <line class="cls-5" x1="85.6" y1="139.24" x2="86.51" y2="139.24" />
                <line class="cls-5" x1="85.6" y1="139.31" x2="86.51" y2="139.31" />
                <line class="cls-5" x1="85.6" y1="139.39" x2="86.51" y2="139.39" />
                <line class="cls-5" x1="85.6" y1="139.46" x2="86.51" y2="139.46" />
                <line class="cls-5" x1="85.6" y1="139.53" x2="86.51" y2="139.53" />
                <line class="cls-5" x1="85.6" y1="139.6" x2="86.51" y2="139.6" />
                <line class="cls-5" x1="85.6" y1="139.67" x2="86.51" y2="139.67" />
                <line class="cls-5" x1="85.6" y1="139.74" x2="86.51" y2="139.74" />
                <line class="cls-5" x1="85.6" y1="139.81" x2="86.51" y2="139.81" />
                <line class="cls-5" x1="85.6" y1="139.88" x2="86.51" y2="139.88" />
                <line class="cls-5" x1="85.6" y1="139.95" x2="86.51" y2="139.95" />
                <line class="cls-5" x1="85.6" y1="140.03" x2="86.51" y2="140.03" />
                <line class="cls-5" x1="85.6" y1="140.1" x2="86.51" y2="140.1" />
                <line class="cls-5" x1="85.6" y1="140.17" x2="86.51" y2="140.17" />
                <line class="cls-5" x1="85.6" y1="140.24" x2="86.51" y2="140.24" />
                <line class="cls-5" x1="85.6" y1="140.31" x2="86.51" y2="140.31" />
                <line class="cls-5" x1="85.6" y1="140.38" x2="86.51" y2="140.38" />
                <line class="cls-5" x1="85.6" y1="140.45" x2="86.51" y2="140.45" />
                <line class="cls-5" x1="82.06" y1="134.91" x2="83.9" y2="134.91" />
                <line class="cls-5" x1="79.45" y1="140.59" x2="80.37" y2="140.59" />
                <line class="cls-5" x1="85.6" y1="140.59" x2="86.51" y2="140.59" />
                <line class="cls-5" x1="84.75" y1="141.31" x2="86.51" y2="141.31" />
                <line class="cls-5" x1="84.75" y1="141.38" x2="85.67" y2="141.38" />
                <line class="cls-5" x1="84.75" y1="141.52" x2="85.67" y2="141.52" />
                <line class="cls-5" x1="84.75" y1="141.59" x2="85.67" y2="141.59" />
                <line class="cls-5" x1="84.75" y1="141.66" x2="85.67" y2="141.66" />
                <line class="cls-5" x1="84.75" y1="141.73" x2="85.67" y2="141.73" />
                <line class="cls-5" x1="84.75" y1="141.8" x2="85.67" y2="141.8" />
                <line class="cls-5" x1="84.75" y1="141.87" x2="85.67" y2="141.87" />
                <line class="cls-5" x1="84.75" y1="141.95" x2="85.67" y2="141.95" />
                <line class="cls-5" x1="84.75" y1="142.02" x2="85.67" y2="142.02" />
                <line class="cls-5" x1="84.75" y1="142.09" x2="85.67" y2="142.09" />
                <line class="cls-5" x1="84.75" y1="142.16" x2="85.67" y2="142.16" />
                <line class="cls-5" x1="84.75" y1="141.45" x2="85.67" y2="141.45" />
                <line class="cls-5" x1="80.3" y1="142.23" x2="85.67" y2="142.23" />
                <line class="cls-5" x1="80.3" y1="135.97" x2="85.67" y2="135.97" />
                <line class="cls-5" x1="80.3" y1="136.04" x2="85.67" y2="136.04" />
                <line class="cls-5" x1="80.3" y1="136.11" x2="85.67" y2="136.11" />
                <line class="cls-5" x1="80.3" y1="136.19" x2="85.67" y2="136.19" />
                <line class="cls-5" x1="80.3" y1="136.26" x2="85.67" y2="136.26" />
                <line class="cls-5" x1="80.3" y1="136.33" x2="85.67" y2="136.33" />
                <line class="cls-5" x1="80.3" y1="136.4" x2="85.67" y2="136.4" />
                <line class="cls-5" x1="80.3" y1="136.47" x2="85.67" y2="136.47" />
                <line class="cls-5" x1="80.3" y1="136.54" x2="85.67" y2="136.54" />
                <line class="cls-5" x1="80.3" y1="136.61" x2="85.67" y2="136.61" />
                <line class="cls-5" x1="80.3" y1="136.68" x2="85.67" y2="136.68" />
                <line class="cls-5" x1="80.3" y1="136.75" x2="85.67" y2="136.75" />
                <line class="cls-5" x1="80.3" y1="136.83" x2="85.67" y2="136.83" />
                <line class="cls-5" x1="80.3" y1="136.9" x2="85.67" y2="136.9" />
                <line class="cls-5" x1="80.3" y1="136.97" x2="85.67" y2="136.97" />
                <line class="cls-5" x1="80.3" y1="137.04" x2="85.67" y2="137.04" />
                <line class="cls-5" x1="80.3" y1="137.11" x2="85.67" y2="137.11" />
                <line class="cls-5" x1="80.3" y1="137.18" x2="85.67" y2="137.18" />
                <line class="cls-5" x1="80.3" y1="137.25" x2="85.67" y2="137.25" />
                <line class="cls-5" x1="80.3" y1="137.32" x2="85.67" y2="137.32" />
                <line class="cls-5" x1="80.3" y1="137.39" x2="85.67" y2="137.39" />
                <line class="cls-5" x1="80.3" y1="137.47" x2="85.67" y2="137.47" />
                <line class="cls-5" x1="80.3" y1="137.54" x2="85.67" y2="137.54" />
                <line class="cls-5" x1="80.3" y1="137.61" x2="85.67" y2="137.61" />
                <line class="cls-5" x1="80.3" y1="137.68" x2="85.67" y2="137.68" />
                <line class="cls-5" x1="79.45" y1="138.03" x2="86.51" y2="138.03" />
                <line class="cls-5" x1="79.45" y1="137.75" x2="86.51" y2="137.75" />
                <line class="cls-5" x1="79.45" y1="137.82" x2="86.51" y2="137.82" />
                <line class="cls-5" x1="79.45" y1="137.89" x2="86.51" y2="137.89" />
                <line class="cls-5" x1="79.45" y1="137.96" x2="86.51" y2="137.96" />
                <line class="cls-5" x1="79.45" y1="138.11" x2="86.51" y2="138.11" />
                <line class="cls-5" x1="79.45" y1="138.18" x2="86.51" y2="138.18" />
                <line class="cls-5" x1="79.45" y1="138.25" x2="86.51" y2="138.25" />
                <line class="cls-5" x1="79.45" y1="138.32" x2="86.51" y2="138.32" />
                <line class="cls-5" x1="79.45" y1="138.39" x2="86.51" y2="138.39" />
                <line class="cls-5" x1="79.45" y1="138.46" x2="86.51" y2="138.46" />
                <line class="cls-5" x1="79.45" y1="138.53" x2="86.51" y2="138.53" />
                <line class="cls-5" x1="79.45" y1="138.6" x2="86.51" y2="138.6" />
                <line class="cls-5" x1="79.45" y1="138.67" x2="86.51" y2="138.67" />
              </g>
              <text class="cls-6" transform="translate(103.52 23.22) scale(.95 1)"><tspan class="cls-11" x="0" y="0">7</tspan><tspan x="4.18" y="0">2</tspan></text>
              <path class="cls-7" d="m4.39,0h104.99c2.35,0,4.26,1.91,4.26,4.26v10.79H0V4.39C0,1.97,1.97,0,4.39,0Z" />
              <g>
                <path class="cls-8" d="m46.23,7.78c-.03-.65-.07-1.43-.07-2.01h-.02c-.15.55-.34,1.13-.56,1.77l-.79,2.26h-.44l-.72-2.22c-.21-.66-.39-1.26-.52-1.81h-.01c-.01.58-.05,1.36-.09,2.06l-.12,2h-.55l.31-4.66h.73l.76,2.25c.19.57.34,1.08.45,1.57h.02c.11-.47.27-.98.47-1.57l.79-2.25h.73l.28,4.66h-.56l-.11-2.05Z" />
                <path class="cls-8" d="m48.44,8.37l-.46,1.47h-.59l1.51-4.66h.69l1.52,4.66h-.61l-.48-1.47h-1.58Zm1.46-.47l-.44-1.34c-.1-.3-.17-.58-.23-.85h-.01c-.07.28-.14.56-.22.84l-.44,1.35h1.34Z" />
                <path class="cls-8" d="m52.1,5.68h-1.35v-.51h3.3v.51h-1.36v4.15h-.58v-4.15Z" />
                <path class="cls-8" d="m54.53,5.23c.29-.06.71-.1,1.1-.1.61,0,1.01.12,1.29.38.22.21.35.53.35.88,0,.62-.37,1.02-.84,1.19v.02c.34.12.55.46.65.94.15.65.25,1.1.34,1.28h-.59c-.07-.13-.17-.53-.3-1.11-.13-.64-.37-.88-.89-.91h-.54v2.02h-.58v-4.6Zm.58,2.12h.59c.61,0,1-.35,1-.88,0-.6-.42-.86-1.02-.87-.28,0-.48.03-.57.06v1.7Z" />
                <path class="cls-8" d="m58.66,5.17v4.66h-.58v-4.66h.58Z" />
                <path class="cls-8" d="m59.36,9.48l2.47-3.78v-.02h-2.26v-.5h3.01v.37l-2.46,3.77v.02h2.49v.5h-3.25v-.35Z" />
                <path class="cls-8" d="m64.46,8.69c0-.57.32-.97.85-1.2v-.02c-.48-.23-.69-.62-.69-1.01,0-.71.58-1.2,1.33-1.2.83,0,1.25.55,1.25,1.11,0,.38-.18.79-.71,1.05v.02c.54.22.87.62.87,1.16,0,.78-.64,1.31-1.46,1.31-.9,0-1.44-.56-1.44-1.22Zm2.31-.03c0-.55-.36-.81-.94-.98-.5.15-.77.5-.77.93-.02.46.31.86.86.86s.86-.34.86-.8Zm-1.6-2.25c0,.45.32.69.82.83.37-.13.65-.41.65-.82,0-.36-.21-.73-.73-.73-.48,0-.75.33-.75.72Z" />
                <path class="cls-8" d="m70.55,9.83l-.57-1.03c-.23-.39-.38-.65-.52-.92h-.01c-.13.27-.25.52-.48.93l-.54,1.02h-.66l1.36-2.36-1.31-2.3h.67l.59,1.09c.17.3.29.54.41.79h.02c.12-.28.24-.49.4-.79l.61-1.09h.66l-1.35,2.27,1.39,2.39h-.67Z" />
                <path class="cls-8" d="m71.62,8.69c0-.57.32-.97.85-1.2v-.02c-.48-.23-.69-.62-.69-1.01,0-.71.58-1.2,1.33-1.2.83,0,1.25.55,1.25,1.11,0,.38-.18.79-.71,1.05v.02c.54.22.87.62.87,1.16,0,.78-.64,1.31-1.46,1.31-.9,0-1.44-.56-1.44-1.22Zm2.31-.03c0-.55-.36-.81-.94-.98-.5.15-.77.5-.77.93-.02.46.31.86.86.86s.86-.34.86-.8Zm-1.6-2.25c0,.45.32.69.82.83.37-.13.65-.41.65-.82,0-.36-.21-.73-.73-.73-.48,0-.75.33-.75.72Z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
