import React from 'react';

function CloseIcon() {
  return (
    <svg viewBox="0 0 32 32">
      <path style={{ stroke: 'white' }} d="M 8.71875 7.28125 L 7.28125 8.71875 L 14.5625 16 L 7.28125 23.28125 L 8.71875 24.71875 L 16 17.4375 L 23.28125 24.71875 L 24.71875 23.28125 L 17.4375 16 L 24.71875 8.71875 L 23.28125 7.28125 L 16 14.5625 Z" />
    </svg>
  );
}

export default CloseIcon;
