import React, { Component } from 'react';
import { getMicroSeconds, rotatePort } from '../utils';
import { ServoElement } from '../components/servo/servo';

export class Servo extends Component {
  constructor(hardware, pin, id = 'Servo', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.pin = pin;
    this.hardware = hardware;
    this.pinState = false;
    this.variant = variant || {
      horn: 'single', // "single" "double" "cross"
      hornColor: '#CCCCCC',
    };
    this.cpuCycles = -1;
    this.pinInfo = [
      { name: 'PWM', x: -3, y: 61, signals: [{ type: 'pwm' }] },
      { name: 'GND', x: -3, y: 68, signals: [{ type: 'power', signal: 'GND' }] },
      { name: 'V+', x: -3, y: 68, signals: [{ type: 'power', signal: 'VCC' }] },
    ];
    this.value = 90;
    this.size = {
      width: 121,
      height: 124,
    };
    this.rotation = rotation;

    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.reset = this.reset.bind(this);
  }

  getPinInfo = (name = 'PWM', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => this.value;

  update = (pinState) => {
    if (pinState) {
      if (!this.pinState) {
        this.cpuCycles = this.hardware.runner.cpu.cycles;
      }
    } else if (this.pinState) {
      const widthOfPulse = getMicroSeconds((this.hardware.runner.cpu.cycles - this.cpuCycles) / this.hardware.mhz) / 1000;
      this.value = Math.floor((widthOfPulse - 0.54) * (180 / 1.85));
    }
    this.pinState = (pinState);
  }

  reset = () => {
    this.pinState = false;
    this.cpuCycles = -1;
    this.value = 90;
  }

  render() {
    return (
      <ServoElement
        id={this.id}
        key={`servo-${this.pin}`}
        rotation={this.rotation}
        angle={this.value}
        horn={this.variant.horn}
        hornColor={this.variant.hornColor}
      />
    );
  }
}
