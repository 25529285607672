import React, { Component } from 'react';
import './matrix8x16.scss';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

export class Matrix8x16Element extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotation: props.rotation || 0,
      id: props.id || '',
      sliderOpen: true,
    }

    this.numCols = 16;
    this.numRows = 8;

    this.lcd = null;
    this.color = '#0f6efb';
    this.characters = null;

    this.render = this.render.bind(this);
  }

  componentDidUpdate = (newProps) => {
    this.lcd = newProps.lcd;
    this.color = newProps.color;
    if (this.lcd) {
      this.characters = this.lcd.characters;
    }
  }

  handleRotation = (e) => {
    const { rotation, id } = this.state;
    const newRotation = ((rotation - 90) < -270) ? 0 : rotation - 90;
    this.setState({ rotation: newRotation });
    const rotationEvent = new CustomEvent('component-rotation', { detail: { rotation: newRotation, component: id } });
    document.dispatchEvent(rotationEvent);
  }

  openSlider = (e) => {
    const { id, sliderOpen } = this.state;
    setTimeout(() => {
      if (!document.querySelector(`#${id}`).closest(".react-draggable").classList.contains("react-draggable-dragging")) {
        this.setState({ sliderOpen: !sliderOpen });
      }
    }, 100);
  }

  getMatrixLeds = () => {
    const result = [];
    const yOffset = 11.34;
    const xOffset = 11.34;
    const itemXOffset = 15.09;
    const itemYOffset = 56.2;
    for (let row = 0; row < this.numRows; row += 1) {
      for (let col = 0; col < this.numCols; col += 1) {
        const itemX = itemXOffset + (xOffset * col)
        const itemY = itemYOffset + (yOffset * row)
        let ledOn = false;
        if (this.characters) {
          const char = (this.characters[col] >> (this.numRows - row - 1)) & 0x01;
          ledOn = Boolean(parseInt(char, 10));
        }
        result.push((
          <g>
            <g id={`SPLINE-${row}-${col}`} data-name="LINE">
              <line class="cls-7" x1={itemX} y1={itemY} x2={itemX} y2={itemY - 0.57} />
            </g>
            <path class="cls-6" d={`m${itemX + 1.13},${itemY - 2.27}h-1.13v.57c.31,0,.57.25.57.57s-.25.57-.57.57v.57h1.13s2.27,0,2.27,0h1.13v-.57c-.07,0-.15-.02-.22-.04s-.13-.07-.18-.12-.1-.12-.12-.18-.03-.14-.04-.22c0-.07.02-.15.04-.22s.07-.13.12-.18.12-.1.18-.12.14-.03.22-.04v-.57h-1.13`} />
            <line class="cls-7" x1={itemX + 1.13} y1={itemY - 2.27} x2={itemX + 3.4} y2={itemY - 2.27} />
            <rect fill={`${ledOn ? this.color : '#b0b0b0'}`} class="cls-10" x={itemX + 0.93} y={itemY - 2.27} width="2.67" height="2.27" />
            <circle cy={itemY - 1} cx={itemX + 2} r="3" fill={this.color} filter="url(#ledFilter)" style={(!ledOn) ? { display: 'none' } : {}} />
          </g>
        ));
      }
    }
    return result;
  }

  render() {
    const { id, sliderOpen, rotation } = this.state;
    return (
      <div className="matrix8x16" id={id}>
        <div className={`rotation${sliderOpen ? ' open' : ''}${(rotation === -90 || rotation === -270) ? ' rotate_90' : ''}`} onClick={this.handleRotation}><ScreenRotationIcon fontSize="inherit" htmlColor="white" /></div>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 204.35 201.12"
          width="54mm"
          height="53mm"
          transform={`translate(0 0) rotate(${rotation})`}
        >

          <filter id="ledFilter" x="-0.8" y="-0.8" height="2.5" width="2.5">
            <feGaussianBlur stdDeviation="6" />
          </filter>

          <g id="Capa_1-2" data-name="Capa 1" onMouseDown={this.openSlider}>
            <g>
              <g id="ARC">
                <path class="cls-4" d="m154.43,199.52v-2.78s0,0,0,0c0-2.5,2.03-4.53,4.54-4.53s4.53,2.03,4.53,4.54v2.81c0,.79.65,1.42,1.43,1.42,0,0,0,0,0,0h8.1v-19.57c0-2.35,1.91-4.25,4.25-4.25h22.69c2.33,0,4.22-1.87,4.23-4.2,0-.02,0-.04,0-.05V28.05c0-2.35-1.9-4.25-4.25-4.25h-70.11c-1.99,0-3.6-1.61-3.6-3.6V3.73c0-1.99-1.62-3.6-3.61-3.6h-40.97c-1.99,0-3.6,1.61-3.6,3.6v16.47c-.01,1.99-1.62,3.6-3.61,3.6H4.38c-2.35,0-4.25,1.9-4.25,4.25,0,0,0,0,0,0v144.84c0,2.35,1.9,4.25,4.25,4.25,0,0,0,0,0,0h22.69c2.35,0,4.25,1.9,4.25,4.25v19.57h8.12c.78,0,1.42-.64,1.42-1.42v-2.81c0-2.5,2.03-4.53,4.53-4.54s4.54,2.03,4.54,4.54v2.78c0,.8.65,1.45,1.45,1.45h101.61c.8,0,1.45-.65,1.45-1.45" />
              </g>
              <g id="LINE">
                <line class="cls-2" x1="122" y1="21.16" x2="122" y2="21.16" />
              </g>
              <g id="LINE-2" data-name="LINE">
                <line class="cls-2" x1="112.3" y1="21.16" x2="112.3" y2="21.16" />
              </g>
              <polygon class="cls-8" points="54.5 185.34 54.5 201.1 48.26 201.1 48.26 195.94 48.26 195.61 48.14 194.96 47.88 194.35 47.52 193.8 47.28 193.57 47.06 193.35 46.55 193.01 45.98 192.77 45.37 192.65 45.06 192.65 45.01 192.65 44.69 192.65 44.06 192.78 43.47 193.03 42.93 193.38 42.71 193.61 42.48 193.84 42.13 194.36 41.89 194.95 41.77 195.57 41.77 195.88 41.77 201.1 35.59 201.1 35.59 185.34 39.67 181.26 50.42 181.26 54.5 185.34" />
              <polygon class="cls-8" points="168.39 185.37 168.39 201.12 162.16 201.12 162.16 195.97 162.16 195.63 162.03 194.99 161.77 194.38 161.41 193.83 161.17 193.59 160.96 193.38 160.44 193.03 159.87 192.79 159.26 192.67 158.95 192.67 158.9 192.67 158.58 192.67 157.95 192.8 157.36 193.05 156.83 193.41 156.6 193.63 156.38 193.86 156.02 194.39 155.78 194.97 155.66 195.59 155.66 195.91 155.66 201.12 149.48 201.12 149.48 185.37 153.56 181.28 164.31 181.28 168.39 185.37" />
              <path class="cls-8" d="m4.55,177.25h196.08c2.05,0,3.72-1.35,3.72-3.01v-11.17H.01v10.5c0,2.03,2.03,3.68,4.54,3.68Z" />
              <g>
                <path class="cls-9" d="m88.44,170.41c-.03-.61-.07-1.35-.06-1.89h-.02c-.14.51-.32,1.06-.53,1.66l-.74,2.12h-.41l-.68-2.09c-.2-.62-.37-1.18-.49-1.7h-.01c-.01.55-.04,1.28-.08,1.94l-.11,1.88h-.52l.29-4.38h.69l.72,2.12c.17.54.32,1.02.42,1.48h.02c.11-.44.26-.92.44-1.48l.75-2.12h.69l.26,4.38h-.53l-.11-1.92Z" />
                <path class="cls-9" d="m90.53,170.96l-.44,1.38h-.56l1.43-4.38h.66l1.44,4.38h-.58l-.45-1.38h-1.49Zm1.38-.44l-.41-1.26c-.09-.29-.16-.55-.22-.8h-.01c-.06.26-.13.53-.21.79l-.41,1.27h1.27Z" />
                <path class="cls-9" d="m93.99,168.43h-1.28v-.48h3.11v.48h-1.29v3.9h-.55v-3.9Z" />
                <path class="cls-9" d="m96.29,168.01c.27-.06.67-.09,1.04-.09.58,0,.96.11,1.22.36.21.2.33.49.33.83,0,.58-.35.96-.79,1.12v.02c.32.12.52.43.62.88.14.61.24,1.03.32,1.2h-.56c-.07-.12-.16-.5-.28-1.05-.12-.6-.35-.83-.84-.85h-.51v1.9h-.54v-4.32Zm.54,2h.55c.58,0,.95-.33.95-.83,0-.57-.39-.81-.97-.82-.26,0-.45.03-.54.05v1.6Z" />
                <path class="cls-9" d="m100.19,167.95v4.38h-.54v-4.38h.54Z" />
                <path class="cls-9" d="m100.85,172l2.33-3.56v-.02h-2.13v-.47h2.84v.34l-2.32,3.54v.02h2.35v.47h-3.07v-.33Z" />
                <path class="cls-9" d="m105.67,171.26c0-.53.31-.91.81-1.13v-.02c-.46-.22-.65-.59-.65-.95,0-.67.54-1.12,1.25-1.12.79,0,1.18.51,1.18,1.04,0,.36-.17.74-.67.99v.02c.5.21.82.58.82,1.09,0,.73-.61,1.23-1.38,1.23-.85,0-1.36-.53-1.36-1.14Zm2.18-.03c0-.51-.34-.76-.89-.92-.47.14-.73.47-.73.87-.02.43.29.81.81.81s.81-.32.81-.75Zm-1.51-2.11c0,.42.31.65.77.78.35-.12.62-.38.62-.77,0-.34-.19-.69-.69-.69-.46,0-.71.31-.71.68Z" />
                <path class="cls-9" d="m111.42,172.33l-.54-.97c-.22-.37-.36-.61-.49-.86h-.01c-.12.25-.24.49-.46.87l-.5.96h-.62l1.29-2.22-1.24-2.16h.63l.56,1.03c.16.29.27.51.39.74h.02c.12-.26.22-.46.38-.74l.57-1.03h.62l-1.28,2.13,1.31,2.25h-.63Z" />
                <path class="cls-9" d="m113.68,168.64h-.01l-.71.4-.11-.44.89-.49h.47v4.23h-.53v-3.69Z" />
                <path class="cls-9" d="m118.01,168.51c-.11,0-.26,0-.41.03-.86.15-1.32.81-1.41,1.5h.02c.19-.27.53-.49.98-.49.72,0,1.22.54,1.22,1.37,0,.77-.51,1.49-1.35,1.49s-1.44-.7-1.44-1.8c0-.83.29-1.49.69-1.9.34-.34.79-.56,1.3-.62.16-.03.3-.03.4-.03v.47Zm-.16,2.44c0-.6-.33-.97-.84-.97-.33,0-.64.21-.79.52-.04.07-.06.15-.06.25.01.7.32,1.21.89,1.21.47,0,.79-.41.79-1.01Z" />
              </g>
              <g>
                <path class="cls-8" d="m198.63,156.06v.5l-2.47,5.11h-.79l2.46-4.97v-.02h-2.77v-.63h3.57Z" />
                <path class="cls-8" d="m199.61,160.8c.22.14.72.35,1.25.35.98,0,1.29-.62,1.28-1.09,0-.79-.72-1.12-1.46-1.12h-.43v-.57h.43c.56,0,1.26-.29,1.26-.95,0-.45-.29-.85-.99-.85-.45,0-.89.2-1.13.37l-.2-.55c.3-.22.87-.43,1.48-.43,1.12,0,1.62.66,1.62,1.34,0,.58-.35,1.07-1.05,1.32v.02c.7.14,1.26.66,1.26,1.44,0,.9-.71,1.68-2.06,1.68-.64,0-1.19-.2-1.47-.38l.21-.59Z" />
              </g>
              <g>
                <rect class="cls-8" x="69.86" y="40.94" width=".85" height=".85" />
                <rect class="cls-8" x="65.63" y="40.94" width=".85" height=".85" />
                <polygon class="cls-8" points="70.7 37.55 70.7 36.7 70.7 35.85 69.86 35.85 69.86 35.01 69.01 35.01 69.01 34.16 69.01 33.31 67.32 33.31 67.32 34.16 67.32 35.01 66.47 35.01 66.47 35.85 65.63 35.85 65.63 36.7 65.63 37.55 64.78 37.55 64.78 38.4 64.78 40.94 65.63 40.94 65.63 38.4 70.7 38.4 70.7 40.94 71.55 40.94 71.55 38.4 71.55 37.55 70.7 37.55" />
                <rect class="cls-8" x="66.47" y="41.79" width="3.38" height=".85" />
              </g>

              {this.getMatrixLeds()}


              <path class="cls-3" d="m88.35,17.47c2.26,0,4.24,1.51,4.85,3.69,0,0,0,0,0,0,0,0-9.7,0-9.7,0,0,0,0,0,0,0,.6-2.18,2.59-3.69,4.85-3.69Z" />
              <path class="cls-3" d="m83.5,23.85h0c.6,2.18,2.59,3.69,4.85,3.69,2.26,0,4.24-1.51,4.85-3.69h-9.7c.6,2.18,2.59,3.69,4.85,3.69,0,0,0,0,0,0,2.26,0,4.24-1.51,4.85-3.69h0" />
              <path class="cls-1" d="m117.15,17.47c2.26,0,4.24,1.51,4.85,3.69h-9.7c.6-2.18,2.59-3.69,4.85-3.69Z" />
              <g id="LINE-131" data-name="LINE">
                <line class="cls-2" x1="112.29" y1="23.85" x2="112.29" y2="23.85" />
              </g>
              <g id="LINE-132" data-name="LINE">
                <line class="cls-2" x1="121.99" y1="23.85" x2="121.99" y2="23.85" />
              </g>
              <path class="cls-1" d="m117.14,27.54c-2.26,0-4.24-1.51-4.85-3.69h9.7c-.6,2.18-2.59,3.69-4.85,3.69Z" />
              <g id="CIRCLE">
                <path class="cls-5" d="m11.45,37.97c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-2" data-name="CIRCLE">
                <path class="cls-5" d="m181.62,37.97c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-3" data-name="CIRCLE">
                <path class="cls-5" d="m68.17,189.63c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-4" data-name="CIRCLE">
                <path class="cls-5" d="m11.45,151.36c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-5" data-name="CIRCLE">
                <path class="cls-5" d="m124.86,189.63c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
              <g id="CIRCLE-6" data-name="CIRCLE">
                <path class="cls-5" d="m181.62,151.36c0-3.13,2.54-5.67,5.67-5.67s5.67,2.54,5.67,5.67-2.54,5.67-5.67,5.67-5.67-2.54-5.67-5.67h0Z" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
