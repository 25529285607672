import React from 'react';

export const initXml = {
  blocks: {
    blocks: [
      {
        deletable: false,
        type: 'initial_block',
        x: 10,
        y: 10,
      },
    ],
  },
};

/* eslint-disable */
export const categoryXml = (
  <xml>
    <category id="Control" categorystyle="control_category" name="%{BKY_LANG_CATEGORY_CONTROLS}">
      <label text="%{BKY_LANG_CATEGORY_CONTROLS}" />
      <block type="base_delay">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">1000</field>
          </shadow>
        </value>
      </block>
      <block type="controls_if">
        <value name="IF0">
        </value>
      </block>
      <block type="controls_repeat_educabot">
        <value name="TIMES">
          <shadow type="math_number">
            <field name="NUM">10</field>
          </shadow>
        </value>
      </block>
    </category>
    <category id="variables" categorystyle="variable_dynamic_category" name="%{BKY_LANG_CATEGORY_VARIABLES}">
      <label text="%{BKY_LANG_CATEGORY_VARIABLES}" />
      <block type="variables_set_value">
        <value name="NUM">
          <shadow type="math_number">
            <field name="NUM">0</field>
          </shadow>
        </value>
      </block>
      <block type="variables_get_value" />
      <block type="variables_add_one" />
      <block type="variables_substract_one" />
    </category>
    <category id="inputs" categorystyle="inputs_category" name="%{BKY_LANG_CATEGORY_ENTRADAS}">
      <label text="%{BKY_LANG_CATEGORY_ENTRADAS}" />
      <block type="logic_compare_ultrasonic">
        <value name="A">
          <block type="BHOOT_ultrasonido" />
        </value>
      </block>
      <block type="logic_compare">
        <value name="A">
          <block type="ENTRADAS_button" />
        </value>
        <value name="B">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>
      <block type="logic_compare">
        <value name="A">
          <block type="ENTRADAS_button_touch" />
        </value>
        <value name="B">
          <block type="math_number">
            <field name="NUM">1</field>
          </block>
        </value>
      </block>
      <block type="logic_compare_obstacle">
        <value name="A">
          <block type="ENTRADAS_obstaculo" />
        </value>
      </block>
      <block type="logic_compare_humidity">
        <value name="A">
          <block type="entradas_suelo" />
        </value>
      </block>
      <block type="logic_compare_noise">
        <value name="A">
          <block type="entradas_sonido" />
        </value>
      </block>
    </category>
    <category id="outputs" categorystyle="outputs_category" name="%{BKY_LANG_CATEGORY_SALIDAS}">
      <label text="%{BKY_LANG_CATEGORY_SALIDAS}" />
      <block type="BHOOT_led_neopixel">
        <value name="COLOUR">
          <shadow type="colour_educabot">
            <field name="COLOUR">#333399</field>
          </shadow>
        </value>
      </block>
      <block type="BHOOT_led_neopixel_clear" />
      <block type="BHOOT_piezo_buzzer_no_ruration" />
      <block type="BHOOT_piezo_buzzerno" />
    </category>
    <category id="screens" categorystyle="screens_category" name="%{BKY_LANG_CATEGORY_LCD}">
      <label text="%{BKY_LANG_CATEGORY_LCD}" />
      <block type="BHOOT_matrix8x16" />
      <block type="matrix8x16_text">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_educabot">
        <value name="NAME">
          <shadow type="text">
            <field name="TEXT">abc</field>
          </shadow>
        </value>
      </block>
      <block type="lcd_clear" />
    </category>
    <category id="motors" categorystyle="motors_category" name="%{BKY_LANG_CATEGORY_SERVO}">
      <label text="%{BKY_LANG_CATEGORY_SERVO}" />
      <block type="BHOOT_motor_doble" />
      <block type="BHOOT_motor_doble_pwm">
        <value name="PWM">
          <shadow type="math_number_minmax_speed">
            <field name="SLIDER">255</field>
          </shadow>
        </value>
      </block>
    </category>
  </xml>
);
