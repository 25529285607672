/* eslint-disable */
var AVR109 = require('./chip.avr.avr109');
var colors = require('colors');
var fs = require('graceful-fs');
// var Serialport = require('serialport');
var Serialport = require('./browser-serialport');
var async = require('async');
var Protocol = require('./protocol');
var util = require('util');
var tools = require('./tools');

const FIX_BRICKED_BOARD = false;
var Avr109 = function (options) {
  options.protocol = function () { return AVR109; };

  Protocol.call(this, options);
};

util.inherits(Avr109, Protocol);

/**
 * Uploads the provided hex file to the board, via the AVR109 protocol
 *
 * @param {string, Buffer} hex - path of hex file for uploading, or Buffer of the hex data
 * @param {function} callback - function to run upon completion/error
 */
Avr109.prototype._upload = function (port, file, callback) {
  var _this = this;
  var data;

  try {
    if (typeof file === 'string') {
      data = fs.readFileSync(file, {
        encoding: 'utf8'
      });
    } else {
      // data = file;
      data = Buffer.from(file);
    }
  } catch (error) {
    return callback(error);
  }

  _this._reset(port, function (error) {
    if (error) { return callback(error); }

    _this.debug('reset complete.');

    _this.connection._pollForOpen(function (error) {
      if (error) { return callback(error); }

      _this.debug('connected');

      _this._write(data, function (error) {
        var color = (error ? colors.red : colors.green);
        _this.debug(color('flash complete.'));
        // this is a workaround, please see https://github.com/noopkat/avrgirl-arduino/issues/193 
        // _this.connection.serialPort.close();

        return callback(error);
      });
    });
  });
};

/**
 * Performs the writing part of uploading to an AVR109 bootloaded chip
 *
 * @param {buffer} data - hex buffer to write to the chip
 * @param {function} callback - function to run upon completion/error
 */
Avr109.prototype._write = function (data, callback) {
  var _this = this;

  var options = {
    signature: _this.board.signature.toString(),
    debug: this.megaDebug
  };

  try {
    _this.chip.init(_this.connection.serialPort, options, function (error, flasher) {
      if (error) { return callback(error); }

      _this.debug('flashing, please wait...', flasher);

      async.series([
        (!FIX_BRICKED_BOARD) ? flasher.erase.bind(flasher) : (done) => done(),
        flasher.program.bind(flasher, data.toString()),
        function verify(done) {
          if (_this.board.disableVerify === true) {
            done();
          } else {
            flasher.verify(done);
          }
        },
        flasher.fuseCheck.bind(flasher)
      ],
        function (error) {
          return callback(error);
        });
    });
  } catch (error) {
    return callback(error);
  }
};

/**
 * Software resets an Arduino AVR109 bootloaded chip into bootloader mode
 *
 * @param {function} callback - function to run upon completion/error
 */
Avr109.prototype._reset = function (port, callback) {
  var _this = this;
  var conn;

  if (_this.board.manualReset) {
    return callback(null);
  }

  // creating a temporary connection for resetting only
  var tempSerialPort = new Serialport('', {
    // var tempSerialPort = new Serialport(_this.connection.options.port, {
    baudRate: 1200,
    autoOpen: false
  });

  _this.connection.serialPort = tempSerialPort;
  conn = _this.connection;

  _this.debug('resetting board...');

  async.series([
    // tempSerialPort.open.bind(tempSerialPort),
    (!FIX_BRICKED_BOARD) ? tempSerialPort.open.bind(tempSerialPort, port) : (done) => done(),
    (!FIX_BRICKED_BOARD) ? conn._setDTR.bind(conn, false, 250) : (done) => done(),
    function(done) {
      // Fix bricked board
      done();
    }
  ],
    function (error) {
      if (error) {
        return callback(error);
      }
      async.series([
        conn._pollForPort.bind(conn)
      ],
        function (error) {
          if (error) {
            conn.serialPort.connectNewBoard((error, port) => {
              _this.debug(error);
              if (error) { return callback(error); }
              conn._pollForPort((error) => {
                return callback(error);
              });
            });
          } else {
            return callback(null);
          }
        });
    });
};

module.exports = Avr109;
