export const getAgentDownloadLink = (userAgent) => {
  let downloadLink = '';

  if (userAgent?.platform) {
    if (userAgent.platform === 'Windows') {
      if (userAgent.bitness === '64') {
        downloadLink = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/agent/Agente%20Educabot-Win-amd64.exe';
      } else {
        downloadLink = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/agent/Agente%20Educabot-Win-386.exe';
      }
    } else if (userAgent.platform === 'Linux' || userAgent.platform === 'Ubuntu') {
      downloadLink = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/agent/Agente%20Educabot-Linux.zip';
    } else if (userAgent.platform === 'Mac OS' || userAgent.platform === 'macOS') {
      if (userAgent.platformVersion < '10.15.0') {
        downloadLink = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/agent/AgenteEducabot-MacOS-Mojave.dmg';
      } else {
        downloadLink = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/agent/AgenteEducabot-MacOS.dmg';
      }
    }
  }
  return downloadLink;
};
