/* eslint-disable */
import * as Blockly from 'blockly';
import { HorizontalInputConnection } from './inline_input';

class HorizontalStatementInputMeasurable extends HorizontalInputConnection {
  constructor(constants, input) {
    super(constants, input);
    this.type = Blockly.blockRendering.Types.getType('STATEMENT_INPUT');

    if (!this.connectedBlock) {
      this.width = this.constants_.EMPTY_STATEMENT_INPUT_WIDTH;
      this.connectedBlockWidth = this.width;
    } else {
      this.width = this.connectedBlockWidth;
      // this.width = this.connectedBlockWidth + (this.constants_.STATEMENT_INPUT_NOTCH_OFFSET + this.shape.width);
    }
    this.height = this.connectedBlockHeight + this.constants_.STATEMENT_BOTTOM_SPACER;
    // console.log('================HorizontalStatementInputMeasurable', this);
  }
}


export { HorizontalStatementInputMeasurable };
