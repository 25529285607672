import { getProfilesByParentId } from '@services/rest/creabots';
import { useCheckLogged, useIsAuthenticated } from '@utils/hooks';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfiles } from '../creabotsSlice';

export const useGetLabsProfiles = () => {
  const { message, data, status } = useCheckLogged(false)
  const [isLoading, setIsLoading] = useState(false);
  const profiles = useSelector((state) => state.creabots.profiles);
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();
  const parentId = data?.user.parentId ? data?.user.parentId : data?.userId

  useEffect(() => {
    if (isAuthenticated && parentId) {
      const req = async () => {
        try {
          setIsLoading(true);
          const { data: resp } = await getProfilesByParentId(
            parentId,
          );
          dispatch(setUserProfiles(resp.profiles));
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
      };
      req();
    }
  }, [parentId, message]);


  useEffect(() => {
    if (status === 'OK' && data.user.profiles) {
      setIsLoading(true);
      dispatch(setUserProfiles(data.user.profiles));
      setIsLoading(false);
    }
  }, [status]);

  async function refetch() {
    try {
      setIsLoading(true);
      const { data: resp } = await getProfilesByParentId(
        parentId,
      );
      dispatch(setUserProfiles(resp.profiles));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }


  return { isLoading, profiles, isAuthenticated, refetch };
}
