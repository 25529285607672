import { toast as Toast } from 'react-toastify';
import ToastComponent from '@modules/toasts/components/toast';
import CloseButton from '@modules/toasts/components/closeToastButton';

const create = (toasts) => ({ ref, type, title, message, options }) => {
  const toastInstance = Toast(ToastComponent({ type, title, message }), {
    closeButton: CloseButton,
    onClose: () => {
      toasts.delete(toastInstance);
    },
    ...options,
  });

  if (ref) {
    toasts.set(ref, toastInstance);
    return toastInstance;
  }

  toasts.set(toastInstance, toastInstance);
  return toastInstance;
};

const remove = (toasts) => (ref) => {
  const toastInstance = toasts.get(ref);
  if (toastInstance) {
    Toast.dismiss(toastInstance);
    toasts.delete(ref);
    return;
  }

  // TODO send to global logger
  throw Error('Toast the reference don\'t exist');
};

const removeAll = (toasts) => () => {
  toasts.clear();
  Toast.dismiss();
};

const createToasts = () => {
  const toastInstances = new Map();
  return {
    create: create(toastInstances),
    remove: remove(toastInstances),
    removeAll: removeAll(toastInstances),
    getAllInstances: () => new Map(toastInstances),
  };
};

export default createToasts();
