import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import styles from './buildProgressModal.mod.scss';
import { useBlockly } from '@modules/blockly/features/hooks';
import { useIntl } from 'react-intl';
import { actionPostRemoteSessionCompilation } from '../../../actions/remoteSessionAction';
import { setBuildProgress, stopBuild, closeBuildProgressModal } from '@modules/blockly/features/blocklySlice';
import { getNewState } from '../../../actions/bloquesAction';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';


const BuildProgressModal = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const blocklyState = useBlockly();
  const [microbitModalBuildFinished, setMicrobitModalBuildFinished] = useState(false);

  const id = props.id || '';
  const userData = props.userData || {};
  const isMicrobit = props.isMicrobit || false;
  const isLogged = (userData.userId);
  const bloquesState = useSelector((state) => state.bloquesReducer);

  useEffect(() => {
    setMicrobitModalBuildFinished(false);
    if (blocklyState?.build?.modalOpen) {
      $(`#connectionModal-${id}`).modal('show');
    } else {
      $(`#connectionModal-${id}`).modal('hide');
    }
  }, [blocklyState?.build?.modalOpen]);

  useEffect(() => {
    const flagMessage = bloquesState.state?.msgs[bloquesState.state?.msgs.length - 1] || '';
    // console.log('==============', flagMessage, bloquesState);
    switch (flagMessage) {
      case '#### UPLOAD TO PORT':
        dispatch(setBuildProgress(0));
        break;
      case '#### UPLOAD TO EB_BUILD':
        dispatch(setBuildProgress(25));
        break;
      case '#### FINISH EB_BUILD':
        dispatch(setBuildProgress(50));
        break;
      case '#### UPLOAD TO AGENT':
        dispatch(setBuildProgress(75));
        break;
      case '#### UPLOAD PROGRESS':
        dispatch(setBuildProgress(bloquesState.state?.progress || 0));
        break;
      case '#### FINISH SHOW':
        dispatch(setBuildProgress(101));
        dispatch(stopBuild());
        if (!isMicrobit || blocklyState.build.port !== '') {
          setTimeout(() => {
            dispatch(closeBuildProgressModal());
          }, 2000);
        } else {
          setTimeout(() => {
            setMicrobitModalBuildFinished(true);
          }, 2500);
        }
        break;
      case '#### FINISH AGENT':
        dispatch(setBuildProgress(100));
        setTimeout(() => {
          dispatch(getNewState('#### FINISH SHOW'));
        }, 2000);
        break;
      case '#### ERROR EB_BUILD':
        dispatch(setBuildProgress(-2));
        dispatch(stopBuild());
        if (!isMicrobit) {
          setTimeout(() => { dispatch(closeBuildProgressModal()); }, 4000);
        } else {
          setTimeout(() => { setMicrobitModalBuildFinished(true); }, 3000);
        }
        break;

      default:
        break;
    }
  }, [bloquesState]);

  const closeModal = () => {
    dispatch(closeBuildProgressModal());
  }

  const createPercentagePoints = () => {
    const points = [];

    for (let i = 0; i < 101; i += 1) {
      points.push(
        <span key={`number-${i}`}>{`${i}%`}</span>,
      );
    }

    return points;
  }

  return (
    <div className={`modal fade short-modal ${styles.buildProgressModal}`} data-backdrop="static" data-keyboard="false" id={`connectionModal-${id}`} tabIndex="-1" role="dialog" aria-labelledby={`connectionModal-${id}-label`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={styles.modalContent}>
          <div className={styles.header}>
            <h5 className={styles.title} id={`connectionmodal-${id}-label`}>
              {intl.formatMessage({ id: 'blocks.blockly.connectionModal.title' })}
            </h5>
          </div>
          <div className={styles.modalBody}>
            {/* {(blocklyState.build?.progress >= 0 && blocklyState.build?.progress <= 100) && (
              <div className={styles.loading}>
                <img src="/images/web_serial/linking.png" width="80" alt="" />
                <div>{intl.formatMessage({ id: 'creabots.connectionModal.loading' })}</div>
              </div>
            )} */}
            {(blocklyState.build?.progress >= 0 && blocklyState.build?.progress <= 100) && (
              <div className="radial-progress" data-progress={blocklyState.build?.progress || 0}>
                <div className="circle">
                  <div className="mask full">
                    <div className="fill" />
                  </div>
                  <div className="mask half">
                    <div className="fill" />
                    <div className="fill fix" />
                  </div>
                  <div className="shadow" />
                </div>
                <div className="inset">
                  <div className="percentage">
                    <div className="numbers">
                      <span>-</span>
                      {createPercentagePoints()}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(blocklyState.build?.progress < 0) && (
              <div className="success no-background">
                <svg id="crossAnimation" className="cross__svg" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 55 50">
                  <circle className="cross__circle" cx="26" cy="26" r="25" fill="none" />
                  <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                  <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20" />
                </svg>
                <h4>{intl.formatMessage({ id: 'creabots.connectionModal.error' })}</h4>
              </div>
            )}
            {(blocklyState.build?.progress > 100) && (
              <React.Fragment>
                {!isMicrobit ? (
                  <div className="success no-background">
                    <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                      <path id="successAnimationResult" fill="#212c4c" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                      <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                      <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27" fill="transparent" />
                    </svg>
                    <h4 className={styles.greatJob}>
                      {isLogged ? intl.formatMessage({ id: 'creabots.connectionModal.successUser' }, { user: userData.name }) : intl.formatMessage({ id: 'blocks.blockly.connectionModal.success' })}
                    </h4>
                    <h4 className={styles.correctlyLoaded}>{intl.formatMessage({ id: 'creabots.connectionModal.successMessage' })}</h4>
                  </div>
                ) : (
                  <React.Fragment>
                    {!microbitModalBuildFinished ? (
                      <div className="success no-background">
                        <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                          <path id="successAnimationResult" fill="#212c4c" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                          <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                          <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27" fill="transparent" />
                        </svg>
                        <h4 className={styles.greatJob}>
                          {isLogged ? intl.formatMessage({ id: 'creabots.connectionModal.successUser' }, { user: userData.name }) : intl.formatMessage({ id: 'blocks.blockly.connectionModal.success' })}
                        </h4>
                        <h4 className={styles.correctlyLoaded}>{intl.formatMessage({ id: 'creabots.connectionModal.successMessage' })}</h4>
                      </div>
                    ) : (
                      <div className="transfer-animation">
                        <h4>{intl.formatMessage({ id: 'creabots.connectionModal.copyCode' })}</h4>
                        <div className="instructions-animation">
                          <div className="browser"></div>
                          <div className="file"></div>
                          <div className="explorer"></div>
                        </div>
                        <ButtonV2 onClick={() => { $(`#connectionModal-${id}`).modal('hide'); setTimeout(() => closeModal(), 2000); }}>
                          {intl.formatMessage({ id: 'creabots.connectionModal.ok' })}
                        </ButtonV2>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postRemoteSessionCompilation: (params) => actionPostRemoteSessionCompilation(params),
  }
}

export default connect(null, mapDispatchToProps)(BuildProgressModal);