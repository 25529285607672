import types from '../actions/types';

export const tagsInitialState = { statusGet: '', dataGet: { data: [] }, statusPost: '', dataPost: {} };

export default function (state = tagsInitialState, action) {
  switch (action.type) {
    case types.TAGS_GET_FETCHING:
    case types.TAGS_GET_FETCH:
    case types.TAGS_GET_FETCH_ERROR:
    case types.TAGS_POST_FETCHING:
    case types.TAGS_POST_FETCH:
    case types.TAGS_POST_FETCH_ERROR:
    case types.TAGS_RESET_STATUS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
