import Position from './position';

export default class WorkSpaceEvents {
  constructor(socket, blockly, workspace, workspaceId, projectId) {
    this.blockly = blockly;
    this.workspace = workspace;
    this.workspaceId = workspaceId;
    this.socket = socket;
    this.projectId = projectId;
  }

  /**
    * Query the database a snapshot of the current workspace.
    * @return {!Promise} Promise object that represents the latest snapshot of the
    * workspace.
    * @public
    */
  async getSnapshot() {
    return new Promise((resolve) => {
      this.socket.emit('getSnapshot', this.projectId, (snapshot) => {
        resolve(snapshot);
      });
    });
  }

  /**
   * Query the database for entries since the given server id.
   * @param {number} serverId serverId for the lower bound of the query.
   * @return {!Promise} Promise object that represents the entries of events since
   * the given serverId.
   * @public
   */
  getEvents(serverId) {
    return new Promise((resolve) => {
      this.socket.emit('getEvents', this.projectId, serverId, (entries) => {
        entries.forEach((item) => {
          const entry = item;
          // entry.events = entry.events.map(eItem => this.blockly.Events.fromJson(eItem, this.workspace));
          entry.events = entry.events.map((eItem) => {
            const event = this.blockly.Events.fromJson(eItem, this.workspace);
            event.position = {
              position: Position.fromEvent(eItem, false),
              id: entry.userId,
            };
            return event;
          });
          entry.serverId = entry.id;
          entry.workspaceId = entry.userId;
        });
        resolve(entries);
      });
    });
  }

  /**
   * Add an entry to the database.
   * @param {!LocalEntry} entry The entry to be added to the database.
   * @return {!Promise} Promise object that represents the success of the write.
   * @public
   */
  async writeEvents(entry) {
    const entryJson = {
      workspaceId: entry.workspaceId,
      entryNumber: entry.entryNumber,
      events: entry.events.map(event => event.toJson()),
    };
    // console.log('==============addEvents to DB', entryJson);
    return new Promise((resolve, reject) => {
      this.socket.emit('addEvents', this.projectId, entryJson, (res) => {
        if (!res || !res.error) {
          resolve();
        } else {
          reject(res.error);
        }
      });
    });
  }

  /**
   * Listen for events broadcast by the server.
   * @param {!Function} callback The callback handler that passes the events to
   * the workspace.
   * @return {!Promise} Promise object that represents the success of the write.
   * @public
   */
  getBroadcast(callback) {
    this.socket.on('broadcastEvents', (projectId, entries) => {
      if (projectId === this.projectId) {
        entries.forEach((item) => {
          const entry = item;
          // entry.events = entry.events.map(eItem => this.blockly.Events.fromJson(eItem, this.workspace));
          entry.events = entry.events.map((eItem) => {
            const event = this.blockly.Events.fromJson(eItem, this.workspace);
            event.position = {
              position: Position.fromEvent(eItem, false),
              id: entry.workspaceId,
            };
            return event;
          });
          // console.log('==============broadcastEvents', entry.workspaceId, this.workspaceId, entry.events);
        });
        callback(entries);
      }
    });
  }
}
