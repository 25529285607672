/* eslint import/no-extraneous-dependencies: 0 */
/* eslint indent: 0 */
import * as Blockly from 'blockly';


export default {

  dataTypes: [
    { value: 'String', name: 'VARIABLE_TYPE_STRING' },
    { value: 'int', name: 'VARIABLE_TYPE_INT' },
    { value: 'long', name: 'VARIABLE_TYPE_LONG' },
    { value: 'byte', name: 'VARIABLE_TYPE_BYTE' },
    { value: 'float', name: 'VARIABLE_TYPE_FLOAT' },
    { value: 'char', name: 'VARIABLE_TYPE_CHAR' },
  ],

  dynamicContent(id, number, parameters, hideParametersType) {
    const dynamicContent = document.getElementById('dynamic-content');
    dynamicContent.innerHTML = '';
    for (let i = 0; i < number; i += 1) {
      let string = '';
      this.dataTypes.map((item) => {
        string += `<div class="dynamic-select-box" value="${item.value}" valuename="${item.name}">${Blockly.Msg[item.name]}</div>`;
        return item;
      });
      dynamicContent.innerHTML += `
        <div class="d-block" style="display: flex !important;">
          <div>
            <input type="text" value="${(parameters[i]) ? parameters[i].name : `var${i}`}" placeholder="${Blockly.Msg.PROCEDURES_NAME}">
          </div>
          ${(!hideParametersType)
          ? `<div class="select-type-dynamic" value="${(parameters[i]) ? parameters[i].type : 'String'}" style="height: 25px;">
              <span class="content-to-show-label">${(parameters[i]) ? Blockly.Msg[this.dataTypes.filter(dataI => dataI.value === parameters[i].type)[0].name] : Blockly.Msg[this.dataTypes[0].name]}</span>
              <div class="content-to-show">
                ${string}
              </div>
            </div>`
          : ''
        }
        </div>
      `;
    }
  },

  drawItems(returnType) {
    let string = '';
    this.dataTypes.map((item) => {
      string += `<div class="box ${(returnType === item.value) ? 'active' : ''}" value="${item.value}">${Blockly.Msg[item.name]}</div>`;
      return item;
    });
    return string;
  },

  buildModal(id, blockType, returnType, number, oldText) {
    return `
    <div id="widget-${id}" class="popup-modal-parent create-variable-modal-parent create-function ${(number === 0) ? 'without-variables' : ''}" style="position: fixed; top: 0; left: 0;">
      <div class="popup-modal-under direction-ltr">
        <div class="middle-container">
          <div class="white-back">
            <div class="input-custom">
              <input type="text" value="${oldText}" placeholder="${Blockly.Msg.PROCEDURES_NAME}" id="create-variable-field-${id}">
              <span id="error-div-${id}" class="c-red" style="display: none">${Blockly.Msg.PROCEDURES_ALREADY_EXISTS}</span>
            </div>
            <div class="new-buttons new-attr-selector">
              <label>${Blockly.Msg.PROCEDURES_PARAMETERS}</label>
              <div id="btn-removeone-${id}" class="remove-one">-</div>
              <div id="number-${id}" class="number">${number}</div>
              <div id="btn-addone-${id}" class="add-one">+</div>
            </div>
            ${(returnType && blockType === 'procedures_defreturn') ? (
        `
              <div class="new-selector">
                <div class="box-content">
                  ${this.drawItems(returnType)}
                </div>
              </div>
              `
      ) : ''}
            <div class="new-buttons">
              <div id="btn-create-${id}" class="create-new disabled">${(oldText) ? Blockly.Msg.IOS_EDIT : Blockly.Msg.IOS_OK}</div>
              <div id="btn-cancel-${id}" class="desist-new">${Blockly.Msg.IOS_CANCEL}</div>
          </div>
        </div>
        <div class="white-back box-shadow-none" id="dynamic-content"></div>
      </div>
    </div>

    `;
  },

  toggleTypesOptions() {
    const selectTypeDynamic = Array.from(document.querySelectorAll('.select-type-dynamic'));
    selectTypeDynamic.map((item) => {
      item.addEventListener('click', (et) => {
        if (!et.currentTarget.classList.contains('active')) {
          et.currentTarget.classList.add('active');
        }
        return true;
      });
      return true;
    });
  },

  hideTypesBox() {
    const dynamicSelectBox = Array.from(document.querySelectorAll('.dynamic-select-box'));
    dynamicSelectBox.map((item) => {
      item.addEventListener('click', (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.currentTarget.parentElement.parentElement.setAttribute('value', e.currentTarget.getAttribute('value'));
        const span = e.currentTarget.parentElement.parentElement.querySelector('span');
        if (span) {
          span.innerHTML = Blockly.Msg[e.currentTarget.getAttribute('valuename')];
        }
        e.currentTarget.parentElement.parentElement.classList.remove('active');
      });
      return true;
    });
  },

  js(workspace, oldText, oldType, callback) {
    const id = workspace.id;

    let functionName = oldText || '';
    let number = 0;
    const blockType = oldType;
    let returnType = 'String';
    let parameters = [];
    const hideParametersType = false;

    Blockly.WidgetDiv.getDiv().innerHTML = this.buildModal(id, blockType, returnType, number, oldText);
    Blockly.WidgetDiv.show(workspace.getParentSvg());

    const widget = document.getElementById(`widget-${id}`);
    const input = document.getElementById(`create-variable-field-${id}`);
    const errorDiv = document.getElementById(`error-div-${id}`);
    const btnCreate = document.getElementById(`btn-create-${id}`);
    const btnCancel = document.getElementById(`btn-cancel-${id}`);
    const btnRemoveOne = document.getElementById(`btn-removeone-${id}`);
    const btnAddOne = document.getElementById(`btn-addone-${id}`);
    const numberDiv = document.getElementById(`number-${id}`);

    input.addEventListener('keyup', (e) => {
      if (e.target.value.length > 0) {
        functionName = e.target.value;
        btnCreate.classList.remove('disabled');
      } else {
        btnCreate.classList.add('disabled');
      }
    });

    if (blockType === 'procedures_defreturn') {
      const box = Array.from(document.querySelectorAll('.box'));
      box.map((item) => {
        item.addEventListener('click', (e) => {
          box.map((b) => b.classList.remove('active'));
          e.target.classList.add('active');
          returnType = e.target.getAttribute('value');
          return true;
        });
        return true;
      });
    }


    btnCreate.addEventListener('click', (e) => {
      const dBlock = Array.from(document.querySelectorAll('.d-block'));
      parameters = (number > 0) ? dBlock.map((item) => {
        const newName = item.querySelector('input').value;
        const newType = item.querySelector('.select-type-dynamic').getAttribute('value');
        return { name: newName, type: newType };
      }) : [];
      // console.log('=============parameters', functionName, number, blockType, returnType, parameters);
      let resp = null;
      if (callback) {
        resp = callback(functionName, number, blockType, returnType, parameters);
      }
      if (!resp.error) {
        Blockly.WidgetDiv.hide();
      } else {
        if (Blockly.Msg[resp.error]) {
          errorDiv.innerHTML = Blockly.Msg[resp.error];
        }
        errorDiv.style.display = 'block';
      }
    });

    btnCancel.addEventListener('click', (e) => {
      Blockly.WidgetDiv.hide();
    });

    btnAddOne.addEventListener('click', (e) => {
      const dBlock = Array.from(document.querySelectorAll('.d-block'));
      if (number < 6) number += 1;
      parameters = (number > 0) ? dBlock.map((item) => {
        const newName = item.querySelector('input').value;
        const newType = item.querySelector('.select-type-dynamic').getAttribute('value');
        return { name: newName, type: newType };
      }) : [];
      numberDiv.innerHTML = number;
      this.dynamicContent(id, number, parameters, hideParametersType);
      widget.classList.remove('without-variables');
      this.toggleTypesOptions();
      this.hideTypesBox();
    });

    btnRemoveOne.addEventListener('click', (e) => {
      const dBlock = Array.from(document.querySelectorAll('.d-block'));
      if (number > 0) number -= 1;
      parameters = (number > 0) ? dBlock.map((item) => {
        const newName = item.querySelector('input').value;
        const newType = item.querySelector('.select-type-dynamic').getAttribute('value');
        return { name: newName, type: newType };
      }) : [];
      numberDiv.innerHTML = number;
      this.dynamicContent(id, number, parameters, hideParametersType);
      if (number === 0) {
        widget.classList.add('without-variables');
      }
      this.toggleTypesOptions();
      this.hideTypesBox();
    });

    this.dynamicContent(id, number, parameters, hideParametersType);
  },
};
