import React, { Component } from 'react';
import { rotatePort } from '../utils';
import { JoystickElement } from '../components/joystick/joystick';

export class Joystick extends Component {
  constructor(hardware, pin, id = 'Joystick', variant = null, rotation = 0) {
    super(hardware, pin, id);
    this.id = id;
    this.hardware = hardware;
    this.pin = pin;
    this.variant = variant || {};
    this.pinInfo = [
      { name: 'T', x: -3, y: 37, signals: [], description: 'Signal' },
    ];
    this.size = {
      width: 102,
      height: 75,
    };
    this.rotation = rotation;

    this.pinX = '';
    this.pinY = '';
    this.pinButton = '';
    this.pinXState = 512;
    this.pinYState = 512;
    this.pinButtonState = 0;

    this.ref = React.createRef();

    this.mapPins();

    this.runSetup = this.runSetup.bind(this);
    this.mapPins = this.mapPins.bind(this);
    this.getPinInfo = this.getPinInfo.bind(this);
    this.getValue = this.getValue.bind(this);
    this.update = this.update.bind(this);
    this.stateHandler = this.stateHandler.bind(this);
    this.reset = this.reset.bind(this);
    this.render = this.render.bind(this);
  }

  runSetup = () => {
    this.stateHandler('X', this.pinXState);
    this.stateHandler('Y', this.pinYState);
    this.stateHandler('button', this.pinButtonState);
  }

  mapPins = () => {
    const pinInfo = this.hardware.getPinInfo(this.pin);
    const pins = this.pin.split(',');
    pins.map((pin, index) => {
      if (pinInfo.signals?.[index][0]?.type === 'X') {
        this.pinX = pin;
      } else if (pinInfo.signals?.[index][0]?.type === 'Y') {
        this.pinY = pin;
      } else if (pinInfo.signals?.[index][0]?.type === 'button') {
        this.pinButton = pin;
      }
      return true;
    });
  }

  getPinInfo = (name = '', angle = 0) => {
    const port = { ...this.pinInfo.find((p) => p.name === name) || this.pinInfo[0] };
    const position = rotatePort(angle, port, this.size.width, this.size.height);
    port.x = position.x;
    port.y = position.y;
    return port;
  }

  getValue = () => ({
    X: this.pinXState,
    Y: this.pinYState,
    button: this.pinButtonState,
  })

  update = (newPinState) => {
    // console.log('===================newPinState', newPinState.toString(2));
    this.pinXState = this.ref.current?.state?.xAxis || 512;
    this.pinYState = this.ref.current?.state?.yAxis || 512;
    this.pinButtonState = this.ref.current?.state?.pressed ? 1023 : 0;
  }

  stateHandler = (pin, pinState) => {
    // console.log('===================', pin, pinState);
    if (this.hardware && this.pinButton) {
      let targetPin = '';
      if (pin === 'X') {
        targetPin = this.pinX;
      } else if (pin === 'Y') {
        targetPin = this.pinY;
      } else {
        targetPin = this.pinButton;
      }
      this.hardware.writeAnalogPin(targetPin, pinState);
    }
  }

  reset = () => {
    this.pinXState = 512;
    this.pinYState = 512;
    this.pinButtonState = 0;
  }

  render = () => (
    <JoystickElement
      id={this.id}
      rotation={this.rotation}
      ref={this.ref}
      key={`joystick-${this.pinButton}`}
      onClickHandler={this.stateHandler}
    />
  );
}
